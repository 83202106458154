/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../contributionsItem/contributionsItem.css'
import {WingBlank, WhiteSpace} from 'antd-mobile';


export default class News extends Component {
    static defaultProps = {
        title: '姚小妍',//传入标题
        content: '姚小妍bfsdfsfsdf的方式的开发速度放缓士大夫大师傅的发顺丰门槛较高的法国公司的归属感',//任务内容
        type: '任务来源',//任务来源

        name: '姚小妍',//姓名
        createTime: '2020-01-32 12:12:12',
        manuscriptDetails: () => {
            alert(1)
        }
    };
    static propTypes = {
        title: PropTypes.string.isRequired,//传入标题
        content: PropTypes.string.isRequired,//任务内容
        type: PropTypes.string.isRequired,//任务来源
        name: PropTypes.string.isRequired,//姓名
        createTime: PropTypes.string.isRequired,

        manuscriptDetails: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        const WingBlankExample = () => {
            return (
                <div style={{background:'#ffffff',width:'95vw',margin:'0 auto',marginTop:'1vw'}} >
                    <div style={{padding:'5vw', lineHeight: '5vw',display:'flex'}} className={'twoText'}>
                    <div style={{width: '15vw',height:'16px',background:'#5073d5',fontSize:'10px',lineHeight:'16px',borderRadius: '3vw',color:'white',textAlign: 'center'}}>稿件</div>
                    <div style={{marginLeft:'3vw',fontSize:'4vw'}}>清华大学与华盛顿大学GIX签约仪式清华大
学与华盛顿大学GIX签约仪式</div>
                  </div>
                    <div className={'flex'} style={{padding:'3vw 5vw 5vw 5vw',fontSize:'3vw'}}>
                        <div className={'gery'}>姚小妍</div>
                        <div className={'gery'}>提交至审核人</div>
                    </div>
                </div>
            );
        }
        return (
            <WingBlankExample/>
        )
    }
}