import React, {Component} from 'react'
import {Icon, NavBar} from 'antd-mobile';
import AddressBook from "../../components/AddressBook";
import {getEditorList} from "../../action";
import {goupByFirstName, uuid} from "../../utils";
import Toast from "antd-mobile/lib/toast";
import {connect} from "react-redux";
import {ACT_CHOOSE} from "../../store/actions/action-type/action-types";
import store from "../../store";
import {setToListPage} from "../../store/actions";

class ChoseEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: "选择审批人",
            userList: [],                 //选择的用户的数组集合，提交至编辑，
            history: require("history").createHashHistory,
        }
    }

    /**
     * 返回按钮事件
     */
    goBack() {
        this.state.history().goBack();
    }


    /**
     * 选择用编辑后直接走流程
     */
    saveEditorToProcess() {
        let type = this.props.actChoose.type;
        let callback = this.props.actChoose.callback;
        let userList = this.state.userList;
        if (userList.length < 1) {
            Toast.info('请至少选择一个编辑', 2, null, false);
            return;
        } else {
            var callbackValue = {};
            if (type === "activitiUsers" || type === "users") {
                callbackValue.activitiUsers = userList;
            } else if (type === "") {
                callbackValue.remarks = userList;
            }

            callbackValue.type = type;
            callback(callbackValue);
        }


        //选择编辑之后回到列表页面。
    }

    componentDidMount() {

        //获取编辑的数据信息
        let param = {'account': ''};
        // getEditorList(param, this.updateUsersList.bind(this));
        let actChoose = this.props.actChoose;

        //把获取的数据渲染到页面。
        this.updateUsersList(actChoose.data);
        actChoose.show = false;

        let action = {
            actChoose,
            type: ACT_CHOOSE
        }

        //派发action到writeStory组件监听
        store.dispatch(action);
    }

    /**
     * 监听跳到列表页面
     * @param nextProps
     * @param nextContext
     */
    componentWillReceiveProps(nextProps, nextContext) {
        // let path = "/listTabPage/listTabPage"
        // if (nextProps.toListPage) {
        //     if (this.props.contributeRoleFlag) {
        //         path = "/index/storyTab"
        //     }
        //     this.state.history().push(path);
        // }
        if(nextProps.toListPage){
            let path = "/listTabPage/listTabPage";
            if (this.props.processDeployKey == "weibo") {
                path = "/weiBoListView/weiBoListView";
            }
            this.state.history().push(path);
        }
    }

    /**
     * 更新页面展示的用户信息
     * @param data
     */
    updateUsersList(data) {
        //对编辑的数据进行分组
        let firstName2UserList = goupByFirstName(data);
        this.setState({
            users: firstName2UserList,
        })
    }

    /**
     * 获取选择的用户account集合
     */
    getUserAccountList(account) {
        let index = this.state.userList.indexOf(account);
        if (index > -1) {
            this.state.userList.splice(index, 1);
        } else {
            this.state.userList.push(account);
        }
    }

    componentWillUnmount() {
        this.props.setToListPage(false);
    }

    render() {
        return <div>
            <NavBar style={{position: 'fixed', width: '100%', zIndex: '5'}}
                    mode={'light'}
                    key={uuid()}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                    ]}
                    rightContent={[
                        <div key={uuid()} style={{color: '#2d2d2d'}} onClick={
                            this.saveEditorToProcess.bind(this)}>确定
                        </div>
                    ]}
            >{this.state.pageName}</NavBar>
            . <div>
            <AddressBook data={this.state.users} getUserAccountList={this.getUserAccountList.bind(this)}/>
        </div>
        </div>
    }
}

const stateToProps = (state) => {
    return {
        actChoose: state.storyReducer.actChoose,
        story: state.storyReducer.story,
        toListPage: state.storyReducer.toListPage,
        processDeployKey:state.storyReducer.processDeployKey,

    }
}

const dispatchToProps = (dispatch) => {
    return {

        setToListPage: function (toListPage) {
            dispatch(setToListPage(toListPage));
        }
    }
}

export default connect(stateToProps, dispatchToProps)(ChoseEditor);
