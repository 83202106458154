import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import {uuid} from "./utils";


ReactDOM.render(<Provider key={uuid()} store={store}> <App/></Provider>,document.getElementById('root'));
serviceWorker.unregister();


