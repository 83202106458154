import SelectTreeDemo from "../testComponent/SelectTreeDemo";

/**
 * created by lei lei on 2020/5/9
 */
export  const  TestDemoRouter =[
    {
        path: "/testComponent/SelectTreeDemo",
        component: SelectTreeDemo
    },
]