import React, {Component} from 'react'
import {Tabs, WingBlank} from 'antd-mobile';
import {baseUrl, getInfoFromLocalStorage, getMyTabdata} from "../../../action";
import InterviewListView from "./InterviewListView";
import {uuid} from "../../../utils";

class InterViewTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabsTitle: [],
            currentTabIndex: 0,
            allDataUrl: "",     //全部
            unGetDataUrl: "",   //未领取
            havingDataUrl: "",  //进行中
            completedDataUrl: "",  //已完成
            overDataDataUrl: ""   //已超期
        }
    }

    componentDidMount() {
        getMyTabdata({}, this.updateTabsTittle.bind(this));
    }

    updateTabsTittle(data) {

        let interViewTabs = [];
        //判断是否是contribute端的角色，采访任务展示的tabs不一样
        if (this.props.isContributeRoleFlag === "true") {
            interViewTabs = data.contribute.interviewTab;
        } else {
            interViewTabs = data.pms.interviewTab;
        }
        this.setState({
            tabsTitle: interViewTabs,
        });
    }

    /**
     * tab页面切换，重新加载数据
     * @param tab
     * @param index
     */
    onTabClickTo(tab, index) {

        //重新复制一个，否则点击同一个tab页签子组件监听不到属性的变化，
        var tab1 = {...tab};
        this.setState({
            currentTab: tab1,
            currentTabIndex: index
        })
    }

    /**
     * 构建 tabs的内容列表
     * @param listData
     * @returns {Array}
     */
    getTablesContentList(listData) {
        const tabsListview = [];
        var tableContent;
        for (var i = 0; i < listData.length; i++) {
            tableContent = <div key={uuid()} style={{height: '100%', marginTop: '8px'}}>
                <WingBlank size="lg"  style={{margin:'5px'}}>
                    {this.state.currentTabIndex === i ? <InterviewListView currentTab={this.state.currentTab}
                                                                           dataUrl={baseUrl + listData[i].dataUrl}
                                                                           isContributeRoleFlag={this.props.isContributeRoleFlag}/> :
                        <div></div>}
                </WingBlank>
            </div>
            tabsListview.push(tableContent);
        }
        return tabsListview;
    }

    render() {
        return <div className={'myTabs'}>
            <Tabs tabs={this.state.tabsTitle}
                  initialPage={0}
                  onChange={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
                  swipeable={true}
                  distanceToChangeTab={0.3}
                  onTabClick={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}


            >
                {this.getTablesContentList(this.state.tabsTitle)}
            </Tabs>

        </div>
    }

}

export default InterViewTabs;

