import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ColumnSetting from "./ColumnSetting";

//子栏目发布设置组件
class SubcolumnspublishSettings extends  Component{
    static defaultProps = {
        defaultData:[],
        dataSource:[],
    };

    //isRequired 必传字段，没有的是非必传字段
    static propTypes = {
        dataSource: PropTypes.array.isRequired, //数据源
        defaultData: PropTypes.array,//默认数据源
    }

    dataList=[];

    /**
     * 获取最新的数据信息
     * @param data
     */
    getData(data) {
        // let dataSource = this.dataList;
        // for (var i = 0; i < dataSource.length; i++) {
        //     if(dataSource[i].treeId==data.treeId){
        //         dataSource[i]=data;
        //         break;
        //     }
        // }
        // this.dataList=dataSource;
        // console.info("getData +++++++++",this.dataList)
    }

    constructor(props){
       super(props);
    }

    /**
     * 两个数组对象合并
     * @param oldData
     * @param newData
     */
    combine(oldData,newData){
       for(var i=0;i<newData.length;i++){
           // newData[i].istop='off';
           // newData[i].wbweightstr='';
           // newData[i].deliveryWay='0';
           if(!newData[i].wbtop){
               newData[i].wbtop='0';
           }

           if(!newData[i].wbweight){
               newData[i].wbweight=10;
           }

           // if(!newData[i].deliveryWay||newData[i].deliveryWay!=0){
           //     newData[i].deliveryWay=1;
           // }

           for(var j=0;j<oldData.length;j++){
               if(newData[i].treeId==oldData[j].treeId){
                   newData[i]=oldData[j];
                   break;
               }
           }
       }
       return newData;
    }

    render() {
        //重新选择的栏目数据回显。
        let  {dataSource}=this.props;
        let dataListTemp=this.dataList;
        this.dataList=this.combine(dataListTemp,dataSource);
        dataSource=this.dataList;

        if( this.props.dataSource&& this.props.dataSource.length>0){

            // this.props.dataSource[0].mediaCode="789798798";
        }

        return (<div>
                {dataSource.map((item, index) => {
                   return (<ColumnSetting key={index} index={index} dataSource={item} getData={this.getData.bind(this)} />);
                })}
            </div>);
    }
}
export  default  SubcolumnspublishSettings;