import store from "../../store";
import {ACT_CHOOSE} from "../../store/actions/action-type/action-types";


function ActChoose(){
}

ActChoose.prototype.getChoose = function (data,type,callback) {
    //跳转到人员选择列表中，修改 actChoose状态，writeStory监听这个事件，决定跳转到用户列表中，
    //批注弹出框。
    let actChoose={
        data,type,callback,show:true,
    }

    let action={
        actChoose,
        type:ACT_CHOOSE
    }


    //派发action到writeStory组件监听
    store.dispatch(action);

}
export  default   ActChoose;
