import React, {Component} from 'react';
import MyHead from "./MyHead";
import {getInfoFromLocalStorage, getUserAllAuth, getUserInfoByAccount, setInfoToLocalStorage} from "../../action";

import HomeMessage from "./HomeMessage";
import HomeList from "./HomeList";
import Toast from "antd-mobile/lib/toast";
import config from "../../config";
import {connect} from "react-redux";
import {setContributeRoleFlag} from "../../store/actions";


/**
 * 首页页面
 */
class HomePageIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: '',
            userAccount:'',
            permissions:[],
            // goBack: getInfoFromLocalStorage('goBack'),
            isContributeRoleFlag:getInfoFromLocalStorage('isContributeRoleFlag'),
        }
    }

    componentWillMount() {
        getUserAllAuth({}, this.userAllAuthBack.bind(this));
    }

    componentWillUnmount() {
        this.setState({
            permissions:[],
        })
    }

    /**
     * 获取用户权限回调函数；
     * @param data
     */
    userAllAuthBack(data) {
        if (data) {
            let permissions = [];
            for (var i = 0; i < data.length; i++) {
                if (!data[i]) {
                    continue;
                }
                permissions.push(data[i].code);
            }
            setInfoToLocalStorage("permissions", permissions);

            this.setState({
                permissions:permissions
            });
            //登录结束后，需要获取用户的信息
            getUserInfoByAccount({}, this.saveUserInfoToLocalStorage.bind(this));
        } else {
            Toast.fail('当前用户没有配置任何权限');
        }
    }

    /**
     * 将用户的信息保存到redux的userName中
     * @param data
     */
    saveUserInfoToLocalStorage(data) {
        let contributeRoleCodes = config.contributeRoleCodes;
        let isContributeflag = false;
        let userRoleCodes = data.roleCodes;
        for (let i = 0, lenth = userRoleCodes.length; i < lenth; i++) {
            if (contributeRoleCodes.indexOf(userRoleCodes[i]) > -1) {
                isContributeflag = true;
                break;
            }
        }

        this.props.setContributeRoleFlag(isContributeflag);

        //投稿端角色标识
        setInfoToLocalStorage('isContributeRoleFlag', isContributeflag);

        //投稿端角色标识
        setInfoToLocalStorage('userName', data.account);

        //用户的信息
        setInfoToLocalStorage('userInfo', data);
        this.setState({
            isContributeRoleFlag:isContributeflag,
            userAccount: data.account
        })
    }

    render() {
        return <div style={{width:'100%',margin:0}}>
            <div style={{height: '100vh', overflowY: 'auto'}}>
                <MyHead></MyHead>
                {this.state.permissions.length==0?"":<HomeList isContributeRoleFlag={this.state.isContributeRoleFlag}/>}
                {this.state.userAccount?<HomeMessage isContributeRoleFlag={this.state.isContributeRoleFlag}/>:""}
            </div>
        </div>
    }
}


const dispatchToProps = (dispatch) => {
    return {
        setContributeRoleFlag: function (contributeRoleFlag) {
            dispatch(setContributeRoleFlag(contributeRoleFlag));
        }
    }
}

export default connect(null, dispatchToProps)(HomePageIndex);