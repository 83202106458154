import React, {Component} from "react";
import {InputItem, TextareaItem} from "antd-mobile";
import {formatDate} from "../../utils";
import {createForm} from "rc-form";
import './dynamic_render_formData.css';
import DateSelects from "./DateSelect";
import $ from 'jquery';

let moneyKeyboardWrapProps;
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

/**
 * 动态渲染表单,以及数据回现；
 */
class DynamicrenderFormData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props.dataSource,
            extenData:{},
        };

        this.getSelectDate=this.getSelectDate.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }


    getSelectDate(data){
        //获取日期数据；
        console.info('++++++++++++++++++++++',data);
        if(this.state.extenData){
            this.state.extenData=$.extend(this.state.extenData,data);
        }else{
            this.state.extenData=data;
        }
    }

    /**
     * 按照数据类型渲染，内容
     */
    renderItemByDataType(item) {
        const {getFieldProps} = this.props.form;
        if (item.dataType == 'text') {

            let input_placeholder = "请输入" + item.fieldName;
            return <InputItem key={item.fieldCode}
                {...getFieldProps(item.fieldCode, {initialValue: item.initialValue})}
                type={'text'}
                clear
                placeholder={input_placeholder}
                moneyKeyboardAlign="left"
                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                required

            >
                {/*<div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>*/}
                <div className={'form_item_tittle'}>{item.fieldName}</div>
                <div className={'form_item_colon'}>:</div>
            </InputItem>
        } else if (item.dataType == 'date') {
            let dataValue = formatDate(item.initialValue);
            return <div key={item.fieldCode}><DateSelects getSelectDate={this.getSelectDate}  initialValue={dataValue} fieldCode={item.fieldCode} fieldName={item.fieldName}/></div>
        } else if (item.dataType == 'textarea') {
            let textarea_title = <div>
                <div style={{color: 'red', width: '6px'}} className={'interview_tittle'}>*</div>
                <div
                    style={{fontSize: '14px'}} className={'interview_tittle'}>采访内容
                </div>
                <div className={'interview_colon'}>:</div>
            </div>;

            let textarea_placeholder = "请输入" + item.fieldName;
            let fildCode = item.fieldCode;
            return <TextareaItem style={{fontSize: '15px'}} key={item.fieldCode}
                                                   {...getFieldProps({fildCode}, {
                                                       initialValue: item.initialValue
                                                   })}
                                                   rows={5}
                                                   maxLength={1000}
                                                   placeholder={textarea_placeholder}
                                                   title={textarea_title}
            />
        }
    }

    dynamRenderFormData(dataSource) {
        let formItems= dataSource.map(item => {
            return this.renderItemByDataType(item);
        });
        return formItems;
    }

    render() {
        return (this.props.dataSource&&this.props.dataSource.length>0)?<div style={{marginTop:'10px'}}>
            <div style={{marginBottom:'10px'}}>{this.props.formTitle}</div>
            {this.dynamRenderFormData(this.props.dataSource)}
        </div>:""
    }
}

export default createForm()(DynamicrenderFormData);
