/**
 * created by yxy
 *
 */
import { request } from '../utils';
let host = '/cm-sites-app-h5/';

const Apis = {
    topicList: host + 'mobileTopicselection/list',//获取选题列表
    topicDetails:host +'mobileTopicselection/getById',//获取选题列表详情
    takeRemark:host + 'mobileTopicselection/takeRemark',//查看备注
    deleteList:host+'mobileTopicselection/deleteById',//删除
    getEnclosure:host+'mobileInterview/getAttachmentFiles',//获取附件resourid
    getAssignUser:host+'mobileTopicselection/assignedWriting',//指派写作
    editStatus:host+'mobileTopicselection/editStatus',//修改状态
    add:host+'mobileTopicselection/add',//添加
    getAuth:host+'mobileTopicselection/getAuthById',//根据id获取按钮
    edit:host+'mobileTopicselection/edit',//修改选题
    saveStoryFilePicture:host+'storyNew/saveStoryFilePicture',//上传附件图片
    saveStoryFileVideo:host+'storyNew/saveStoryFileVideo',//上传附件视频
    saveStoryFileOtherfile:host+'storyNew/saveStoryFileOtherfile',//上传其他问题
    loadAttachement:host+'storyNew/loadAttachment',//根据id 获取附件
    saveStoryFileAudio:host+'storyNew/saveStoryFileAudio'
};

export const loadAttachement = (params,callBack) => {  //获取选题列表
    request(Apis.loadAttachement,'get',params, callBack)
};
export const topicList = (params,callBack) => {  //获取选题列表
    request(Apis.topicList,'get',params, callBack)
};

export const topicDetails = (params, callBack) => {  //获取媒体管理的列表详情
    request(Apis.topicDetails,'get', params, callBack)
};
export const takeRemark = (params, callBack) => {  //查看备注
    request(Apis.takeRemark,'get', params, callBack)
};

export const deleteList = (params, callBack) => {  //删除列表
    request(Apis.deleteList,'post', params, callBack)
};
export const getEnclosure = (params, callBack) => {  //
    request(Apis.getEnclosure,'get', params, callBack)
};
export const getAssignUser = (params, callBack) => {  //
    request(Apis.getAssignUser,'post', params, callBack)
};
export const editStatus = (params, callBack) => {  //
    request(Apis.editStatus,'post', params, callBack)
};
export const add = (params, callBack) => {  //
    request(Apis.add,'post', params, callBack)
};
export const getAuth = (params, callBack) => {  //
    request(Apis.getAuth,'get', params, callBack)
};
export const edit = (params, callBack) => {  //
    request(Apis.edit,'post', params, callBack)
};
export const saveStoryFilePicture = (params, callBack) => {  //
    request(Apis.saveStoryFilePicture,'post', params, callBack)
};
export const saveStoryFileVideo = (params, callBack) => {  //
    request(Apis.saveStoryFileVideo,'post', params, callBack)
};
export const saveStoryFileOtherfile = (params, callBack) => {  //
    request(Apis.saveStoryFileOtherfile,'post', params, callBack)
};
export const saveStoryFileAudio = (params, callBack) => {  //
    request(Apis.saveStoryFileAudio,'post', params, callBack)
};