/**
 * created by yxy and mgg
 *
 */
import React, {Component} from 'react';
import {Icon, ListView, NavBar, Toast, Modal, ImagePicker,} from 'antd-mobile';
import {uuid} from "../../utils";
import {getCurrentUser, getDeptListByIds, getRoleListByCodes, uploadPic} from "../../action/mine";
import {logout, uploadimage} from "../../action";
import { Scrollbars } from 'react-custom-scrollbars';
import config from "../../config";


const alert = Modal.alert;

export default class TaskManagement extends Component {
    static defaultProps = {};
    static propTypes = {

    };

    constructor(props) {
        super(props);
        this.state = {
            user:{},
            deptList: [],
            roleList: [],
            attachemntFiles:[],
            type: '',

        }
    }


    componentWillMount() {
    }

    componentDidMount() {
       this.getCurrentUser();
    }

    // 获取用户所有数据
    getCurrentUser = ()=> {
        getCurrentUser((res)=>{
            // console.log(res)
            this.state.user=res;
            this.setState({user:res},()=>{
                this.getDeptListByIds();
                this.getRoleListByCodes();
            })
        });
    }

    // 获取部门列表
    getDeptListByIds (){
        let params = {deptIds:this.state.user.deptIds}
        getDeptListByIds(params,(res)=>{
            let deptList=[];
            if(res&&res.length){
                deptList=res
            }
            this.setState({deptList:deptList})
        })
    }

    // 获取角色列表
    getRoleListByCodes (){
        let params = {roleCodes:this.state.user.roleCodes}
        getRoleListByCodes(params,(res)=>{
            let roleList=[];
            if(res&&res.length){
                roleList=res
            }
            this.setState({roleList:roleList})
        })
    }

    // 上传头像
    onChange = (files) => {
        window.loading.show();
        for (let i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                    data.append('account', this.state.user.account);
                    uploadPic(data, res => {
                        window.loading.hide();
                        this.state.user.photoUrl=res.pic;
                        this.setState({})
                        // this.getCurrentUser()
                    })
                }
            }
        }
    };


    // 退出登录
    loginOut = ()=> {
        alert('注销', '确定要退出当前用户吗?', [
            { text: '否', onPress: () => console.log('cancel')},
            { text: '是', onPress: () => logout({}, this.logoutBack.bind(this)) },
        ]);
    }

    logoutBack() {
        clearInterval(window.authTimer);
        // let path = '/login/login';
        window.location.href=config.loginUrl;
        window.location.search = "";
        //this.props.history.push(path)
    }


    render() {
        //获取当前部门
        let deptName='';
        // console.log( this.state.deptList)
        this.state.deptList.forEach((item,index)=>{
           // console.log(item)
           if(index==this.state.deptList.length-1){
               deptName=deptName+item.name
           }else{
               deptName=deptName+item.name+'、'
           }
       });

        //获取当前角色
        let roleUname='';
        // console.log( this.state.roleList)
        this.state.roleList.forEach((item,index)=>{
            if(index==this.state.roleList.length-1){
                roleUname=roleUname+(item?item.roleName:'');
            }else{
                roleUname=roleUname+(item?item.roleName:'')+'、'
            }
        })

        //根据不同性别判断头像
        let photoUrl='';
        if(this.state.user){
            if(this.state.user.photoUrl){
                photoUrl=this.state.user.photoUrl;
            }else{
                if(this.state.user.sex === 1){
                    photoUrl='images/header_boys.png'
                }else if(this.state.user.sex === 0){
                    photoUrl='images/header_girls.png'
                }
            }
        }

        let ScrollHeight=window.innerHeight-45-80

        return (
            <div >
                <NavBar
                  icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                  leftContent={[
                        <div className={'nav_goback'}  onClick={()=>this.props.history.goBack()} key={uuid()}>返回</div>
                    ]}
                ><span  className={'nav_title'}>我的</span></NavBar>
                <div style={{
                    textAlign: 'center',
                    height: '150px',
                    width: '100%',
                    position: 'relative',
                    background: 'url("images/backgroung_icon.png") no-repeat center',
                    backgroundSize:'100%',
                }}>
                    <Scrollbars style={{width:'100%',height: ScrollHeight, overflow: 'auto',}}>
                    <div style={{
                        width: '96vw',
                        backgroundColor: '#ffffff',
                        boxShadow:'0vw 0vw 7vw 0vw rgba(118, 31, 131, 0.15)',
                        borderRadius: '3vw',
                        top:'28vw',
                        left:'50%',
                        marginLeft:'-48vw',
                        position: 'absolute',
                        // borderRight:'3px solid #999'
                    }}>
                        <div style={{width: '20vw',
                            height: '20vw',
                            backgroundColor: '#f2f2f2',
                            position: 'relative',
                            top:'-10vw',
                            borderRadius:'50%',
                            left:'50%',
                            marginLeft:'-10vw',
                            border: 'solid 1vw #ffffff'}}>
                            <span>
                                 <img
                                    src={photoUrl}
                                    style={{height:'20vw',width:'20vw',objectFit:'cover', borderRadius:'50%'}}
                                >
                                </img>
                                <ImagePicker
                                    style={{position:"absolute",left:10, top:10,width:'200px', height:'300px',opacity:0,}}
                                    accept
                                    onAddImageClick={()=>{}}
                                    files={this.state.attachemntFiles}
                                    onChange={this.onChange}
                                />
                                <span style={{color: '#999999',whiteSpace:'nowrap'}}>
                                    点击修改头像
                                    <ImagePicker
                                        style={{position:"absolute",left:15, top:70,width:'100px', height:'300px',opacity:0,}}
                                        accept
                                        onAddImageClick={()=>{}}
                                        files={this.state.attachemntFiles}
                                        onChange={this.onChange}
                                    />
                                </span>
                            </span>
                        </div>
                       <div style={{marginTop:'-10vw',fontSize:'15px',minHeight:'60vh'}}>
                           <div style={{display:'flex',margin:'2vw 4vw',lineHeight:'6vh',borderBottom: '1px solid  #e3e3e7'}}>
                               <div style={{width:'20%',textAlign:'left'}}>账号</div>
                               <div style={{width:'80%',textAlign:'right',color: '#999999',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{this.state.user.account}</div>
                           </div>
                           <div style={{display:'flex',margin:'2vw 4vw',lineHeight:'6vh',borderBottom: '1px solid  #e3e3e7'}}>
                               <div style={{width:'20%',textAlign:'left'}}>姓名</div>
                               <div style={{width:'80%',textAlign:'right',color: '#999999',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{this.state.user.name}</div>
                           </div>
                           <div style={{display:'flex',margin:'2vw 4vw',lineHeight:'6vh',borderBottom: '1px solid  #e3e3e7'}}>
                               <div style={{width:'20%',textAlign:'left'}}>性别</div>
                               <div style={{width:'80%',textAlign:'right',color: '#999999',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{this.state.user.sex === 1 ? "男" : "女"}</div>
                           </div>
                           <div style={{display:'flex',margin:'2vw 4vw',lineHeight:'6vh',borderBottom: '1px solid  #e3e3e7'}}>
                               <div style={{width:'40%',textAlign:'left',whiteSpace:'nowrap',}}>联系方式</div>
                               <div style={{width:'80%',textAlign:'right',color: '#999999',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{this.state.user.phone}</div>
                           </div>
                           <div style={{display:'flex',margin:'2vw 4vw',lineHeight:'6vh',borderBottom: '1px solid  #e3e3e7'}}>
                               <div style={{width:'20%',textAlign:'left'}}>部门</div>
                               <div style={{width:'80%',textAlign:'right',color: '#999999'}}>
                                   <span>{deptName}</span>
                               </div>
                           </div>
                           <div style={{display:'flex',margin:'2vw 4vw',lineHeight:'6vh',borderBottom: '1px solid  #e3e3e7'}}>
                               <div style={{width:'20%',textAlign:'left'}}>角色</div>
                               <div style={{width:'80%',textAlign:'right',color: '#999999'}}>
                                   <span>{roleUname}</span>
                               </div>
                           </div>
                       </div>
                    </div>
                    </Scrollbars>
                    <div style={{position:'relative'}}>
                        <div style={{position:'fixed',width:'100%', height:'60px',color:'#1890fe',fontSize:'4vw', paddingTop:'10vw',paddingBottom:'10vw',textAlign:'center'}}  onClick={this.loginOut}>退出登录</div>
                    </div>
                </div>
            </div>
        )
    }
}

