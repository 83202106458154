import React, {Component} from 'react'
import {Icon, Modal, NavBar, TextareaItem} from "antd-mobile/lib/index";
import {formatDate, uuid} from "../../../utils";
import './add_intramuralMedia_Page.css';
import IntramuralmediaCardForm from "../../../components/IntramuralmediaComp/IntramuralmediaCard";
import {getIntramuralMediaInfoByid, internalArrangeAudit} from "../../../action/IntramuraImediaAction";
import Button from "antd-mobile/es/button";
import {getInfoFromLocalStorage} from "../../../action";
import { Toast} from "antd-mobile";
import RemarkInfoShow from "../../../components/RemarkInfoShow";
import AddnewsTaskPage from "../newsTask/AddnewsTaskPage";
import InterviewCardForm from "../../../components/InterviewCard";

/**
 * 新增校内媒体预约页面
 */
class AddintramuralMediaPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            intramuraMediaInfo: {},
            id:props.match.params.id,
            currentUser:getInfoFromLocalStorage("userInfo"),
            title:'',
            toShowRemark:false,
            operateType:1,   //1,通过，2，不通过，
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            toNewsTaskFlag:false,
            interviewInfo:{},

        }
    }
    child

    componentDidMount() {

        //通过id,获取校外媒体预约信息
        if(this.state.id>0){
            var param=new FormData();
            param.append("id",this.state.id);
            //保存新闻线索到数据库
            let path="Pms";
            if(this.state.isContributeRoleFlag){
                path="Contri";
            }
            let url="/cm-sites-app-h5/internalArrange"+path+"/getById"
            getIntramuralMediaInfoByid(param,url,(data)=>{
                this.setState({
                    intramuraMediaInfo:data,
                })
            });
        }
    }

    goBack() {
        this.goBackTimeout = setTimeout(() => {
            var url = '/intramuralMediaPage/intramuralMediaPage';
            this.state.history.push(url);
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.goBackTimeout);
        this.setState = (state, callback) => {
            return;
        };
    }

    //提交审核人
    submitAudit=()=> {
        this.child.showAccountModal();
    }

    //暂存
    staging=()=>{
        this.child.submitData();
    }

    /**
     * 通过
     */
    auditPass=()=>{
        this.setState({
            toShowRemark:true,
            title:'通过原因',
            operateType:1,
        });
    }

    /**
     * 不通过
     */
    auditRefuse=()=>{
        this.setState({
            toShowRemark:true,
            title:'不通过原因',
            operateType:2,
        });
    }

    /**
     * 审核校内媒体预约
     */
    auditIntramuraImeaid = (isPass) => {
        let formData = new FormData();
        formData.append("internalArrangeId", this.state.id);
        formData.append("isPass", isPass);
        formData.append("reviewContent", this.remarkValue);

        let path = "Pms";
        if (this.state.isContributeRoleFlag) {
            path = "Contri";
        }
        let url = "/cm-sites-app-h5/internalArrange" + path + "/audit"
        internalArrangeAudit(formData, url, () => {
            Toast.success("审核成功", 1,()=>{
                this.goBack();
            })
        });
    }

    //备注填写完后的，确定按钮
    remarkOkFunction=()=>{
        let isPass=false;
        if(this.state.operateType==1){
            isPass=true;
        }
        this.auditIntramuraImeaid(isPass)
    }

    //备注弹出框的取消按钮
    remarkCancelFunction=()=>{
        this.setState({
            toShowRemark:false
        })
    }

    /**
     * 采用
     */
    use=()=>{

    }

    /**
     * 拒绝
     */
    unuse=()=>{
        this.setState({
            toShowRemark:true,
            title:'拒绝原因',
            operateType:2,
        });
    }

    /**
     * 删除
     */
    del=()=>{

    }

    /**
     * 转为新闻任务
     */
    toInterview=()=>{
       this.setState({
           toNewsTaskFlag:true,
       })
    }


    //新闻任务的件页面；
    newTaskhild;

    render() {
        const {intramuraMediaInfo,currentUser,id,title}=this.state;
        //处理页面数据还么有回来，页面闪现按钮问题。
        if(id>0&&!intramuraMediaInfo.id){
            return "";
        }
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}
                rightContent={[]}
            ><span className={'nav_title'}>校内媒体预约</span></NavBar>
            <IntramuralmediaCardForm  intramuraMediaInfo={intramuraMediaInfo} onRef={(child) => {
                this.child = child
            }}/>

            <Modal
                visible={this.state.toShowRemark}
                transparent
                maskClosable={false}
                footer={[{
                    text: '确定', onPress: () => {
                        this.remarkOkFunction()
                    }
                },{
                    text: '取消', onPress: () => {
                        this.remarkCancelFunction()
                    }
                }]}
                wrapProps={{onTouchStart: this.onWrapTouchStart}}
            >
                <div >
                    <div>{title}</div>
                    <div>
                        <TextareaItem style={{fontSize:'15px'}}
                                      title=""
                                      placeholder="请填写备注信息"
                            // data-seed="logId"
                            // autoHeight
                                      rows={5}
                                      onChange={(value=>{this.remarkValue=value})}
                                      maxLength={100}
                        />
                    </div>
                </div>
            </Modal>

            <Modal
                popup
                visible={this.state.toNewsTaskFlag}
                onClose={() => {
                }}
                animationType={"fade"}
            >
                <div style={{textAlign:'left',height:'100vh'}}>
                    <InterviewCardForm onRef={(child) => {
                        this.newTaskhild = child
                    }}  videoFiles={this.state.videoFiles} picFiles={this.state.picFiles}
                                       interviewInfo={this.state.interviewInfo}></InterviewCardForm>
                </div>
            </Modal>
            <div style={{textAlign:'center'}}>
                {(!intramuraMediaInfo.status)&&this.state.isContributeRoleFlag?
                    <div><Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.submitAudit}>提交审核</Button>
                        <Button type="primary"   inline  size="small" style={{ marginRight: '4px',background:'#00CA00',width:'40%'}}  onClick={this.staging}>暂存</Button></div>
                    :''}

                   {(!intramuraMediaInfo.status)&&!this.state.isContributeRoleFlag?
                        <Button type="primary"   inline  size="small" style={{ marginRight: '4px',background:'#00CA00',width:'40%'}}  onClick={this.staging}>保存</Button>
                    :''}


                {(intramuraMediaInfo.status== -2 && currentUser.roleCodes.indexOf("shr")>-1 && currentUser.account != intramuraMediaInfo.submitter
                    && (intramuraMediaInfo.accounts==null|| intramuraMediaInfo.accounts.indexOf(currentUser.account)>-1))?
                        <div><Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.auditPass}>通过</Button>
                            <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.auditRefuse}>不通过</Button>
                        </div>:''
                }

                {(intramuraMediaInfo.status== -1 && currentUser.roleCodes.indexOf("bj")>-1)?
                    <div><Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.toInterview}>采用</Button>
                        <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.unuse}>拒绝</Button>
                    </div>:''
                }

                {((intramuraMediaInfo.status == -999 && currentUser.account == intramuraMediaInfo.submitter)
                    ||(intramuraMediaInfo.status == -2 && (currentUser.roleCodes.indexOf("shr") > -1
                    || currentUser.roleCodes.indexOf("xsjz") > -1) && currentUser.account == intramuraMediaInfo.submitter))&&!this.state.isContributeRoleFlag?
                    <div>
                        <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'30%' }} onClick={this.staging}>修改</Button>
                        <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'30%' }} onClick={this.del}>删除</Button>
                        <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'30%' }} onClick={this.toInterview}>转新闻任务</Button>
                    </div>
                :""}

                {
                    ((intramuraMediaInfo.status== -999 && currentUser.account == intramuraMediaInfo.submitter)
                     ||(intramuraMediaInfo.status== -2 &&
                        (intramuraMediaInfo.accounts==null || intramuraMediaInfo.accounts.indexOf(currentUser.account)>-1)
                        && (currentUser.roleCodes.indexOf("shr")>-1 || currentUser.roleCodes.indexOf("xsjz")>-1)
                        && currentUser.account == intramuraMediaInfo.submitter))&&this.state.isContributeRoleFlag?
                    <div>
                    <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'30%' }} onClick={this.staging}>修改</Button>
                    <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'30%' }} onClick={this.del}>删除</Button>
                    <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'30%' }} onClick={this.submitAudit}>提交审核</Button>
                    </div>:''
               }
            </div>
        </div>
    }
}
export default AddintramuralMediaPage;

