import $ from 'jquery'
import {getInfoFromLocalStorage, localLogin} from "../../action";
import config from "../../config";
import store from "../../store";


var chooseFilterObjectUrl = config['baseUrl'] + "/cm-sites-app-h5/media/mediaCheckForAct";




function ActData() {

    this.serverUrl = config['baseUrl'] + "/cm-sites-activiti/activitiPublic/";

    this.allGroupUrl = this.serverUrl + "getAllGroup";
    this.allRole = this.serverUrl + "getAllRole";
    this.getUsers = this.serverUrl + "getUsers";


}

ActData.prototype.chooseUserArray_G=[];
ActData.prototype.post = function (url, pram) {
    var requestData;
    var contentType = 'application/x-www-form-urlencoded';
    if (typeof pram == 'string') {
        contentType = 'application/json; charset=UTF-8';
    }
    $.ajax({
        url: url,
        data: pram,
        async: false,
        contentType: contentType,
        dataType: "JSON",
        type: "POST",
        success: function (result) {
            if (typeof result == 'string') {
                requestData = JSON.parse(result);
            } else {
                requestData = result;
            }
        },
        error: function (e) {
            console.log(e);
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader("userName", getInfoFromLocalStorage("userName"));
        }
    });
    return requestData;
}

/**
 * 获取所有的部门
 */
ActData.prototype.getAllGroup = function () {
    return this.post(this.allGroupUrl, null);
}
ActData.prototype.getAllRole = function () {
    return this.post(this.allRole, null);
}

ActData.prototype.getGroupByIds = function (ids) {
    var json = new Array();
    var data = this.getAllGroup();
    $.each(data, function (index, item) {
        if (ids.indexOf(item.id) > -1) {
            json.push(item);
        }
    })
    return json;
}

ActData.prototype.getRoleByIds = function (ids) {
    var json = new Array();
    var data = this.getAllRole();
    $.each(data, function (index, item) {
        if (ids.indexOf(item.id) > -1) {
            json.push({
                id: item.id,
                name: item.roleName
            });
        }
    })
    return json;
}

ActData.prototype.getUser = function (pram,actData) {

    /**
     * 过滤掉没有当前媒体权限的编辑
     * @type {{queryMap: {mediaCode: *}, url: *}}
     */
    // var chooseFilterObject = {
    //     url: chooseFilterObjectUrl,
    //     queryMap: {
    //         mediaCode: getInfoFromLocalStorage('currentMediaCode'),
    //     }
    //
    // };

    var jsonArray = this.post(this.getUsers, JSON.stringify(pram));
    //如果有媒体值，才过滤。
    if(window.chooseFilterObject){
        if (getInfoFromLocalStorage('currentMediaCode')) {
            window.chooseFilterObject.data = jsonArray;
            jsonArray = this.post(window.chooseFilterObject.url, JSON.stringify(window.chooseFilterObject));
        }
    }
    if (!jsonArray) {
       return  [];
    }else{
        actData.chooseUserArray_G=this.chooseUserArray_G.concat(jsonArray);
    }
    return actData.chooseUserArray_G;
}

export default ActData;


