import  React,{Component} from 'react'
import {
    InputItem,Checkbox,Card
} from "antd-mobile";

/**
 * 栏目设置
 */
const CheckboxItem = Checkbox.CheckboxItem;
class ColumnSetting extends  Component{
    // static defaultProps = {
    //     dataSource: {istop:'on',deliveryWay:1},
    // };

    //isRequired 必传字段，没有的是非必传字段
    // static propTypes = {
    //     // defaultData: PropTypes.array,//默认数据源
    // }

      constructor(props){
          super(props);
          this.state={
              data:props.dataSource
          }
      }

     changeData(propName,Value){
        const {data}=this.state;
         data[propName]=Value;

         //返回新数据到，到父组件。
         // this.props.getData(data);

         this.setState({
             data
         })
     }


    render() {
          const index=this.props.index;
          const {data}=this.state;
          if(index==0){
             data.deliveryWay=1;
          }
          return <div>
              <Card>
              <div style={{padding:'10px'}}>{data.treeName}发布设置</div>
              <div>
                      <div style={{float:'left',width:'65px',marginLeft:'15px',lineHeight:'44px',textAlign:'center'}}>置顶</div>
                      <div style={{float:'left',lineHeight:'44px'}}>:</div>
                      <div style={{width: '100px', float: 'left'}}><CheckboxItem key={true}
                                                                                 checked={data.wbtop == "1"}
                                                                                 onChange={() => {
                                                                                     this.changeData('wbtop', '1')
                                                                                 }}>是</CheckboxItem>
                      </div>
                      <div style={{width: '100px', float: 'left'}}><CheckboxItem key={false}
                                                                                 checked={(data.wbtop?data.wbtop:'0') == "0"}
                                                                                 onChange={() => {
                                                                                     this.changeData('wbtop', '0')
                                                                                 }}>否</CheckboxItem>
                      </div>
              </div>

              <InputItem
                  value={data.wbweight}
                  onChange={(e) => {
                      let num = e<0?0:e>999?999:e;
                      this.changeData('wbweight', num)
                  }}
                  onBlur={(e) => {
                      let num = e<1?1:e>999?999:e;
                      this.changeData('wbweight', num)
                  }}
                  type={'number'}
                  clear
                  placeholder="请输入重要性"
                  moneyKeyboardAlign="left"
              >
                  <div style={{marginLeft:5,marginRight:17,fontSize:14,display:'inline-block'}}>
                      重要性
                  </div>
                  <div style={{display:'inline-block'}}>:</div>
              </InputItem>
              {index==0?<div></div>:
              <div style={{paddingLeft: '15px', overflow: 'hidden'}}>
                  <div className={'attribute'} style={{marginTop: '12px'}}>副栏目发布形式:</div>
                  <div>
                      <div style={{width: '200px', float: 'left'}}><CheckboxItem key={0}
                                                                                 checked={data.deliveryWay == 0}
                                                                                 onChange={() => {
                                                                                     this.changeData('deliveryWay', 0)
                                                                                 }}
                      >以正文形式</CheckboxItem>
                      </div>
                      <div style={{width: '200px', float: 'left'}}><CheckboxItem key={1}
                                                                                 checked={data.deliveryWay != 0}
                                                                                 onChange={() => {
                                                                                     this.changeData('deliveryWay', 1)
                                                                                 }}>以链接形式</CheckboxItem>
                      </div>
                  </div>
              </div>}
              </Card>
          </div>
      }
}

export  default  ColumnSetting;