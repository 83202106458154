import React, {Component} from 'react'
import {Icon, NavBar} from "antd-mobile/lib/index";
import {uuid} from "../../../utils";
import {getInfoFromLocalStorage} from "../../../action";
import NewClueCardForm from "../../../components/NewClueCard";
import './add_NewClue_Page.css';

/**
 * 线索详情页面
 */
class AddNewCluePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            newClue: {},
            goBack: getInfoFromLocalStorage('goBack')
        }
    }

    componentWillMount() {
    }

    updateNewClueInfo(data) {
        if (data) {
            this.setState({
                newClue: data
            })
        }
    }

    goBackTimeout;

    goBack() {
        this.goBackTimeout = setTimeout(() => {
            var isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
            var url = '/newsCluePage/newsCluePage/' + isContributeRoleFlag + '/' + this.state.goBack;
            this.state.history.push(url);
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.goBackTimeout);
    }

    //提交线索
    submitData() {
        this.child.submitData();
    }

    render() {
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}
                rightContent={[<div key={uuid()} onClick={() => {
                    this.submitData()
                }} className={'nav_goback'} style={{marginRight:10}}>提交</div>]}
            ><span className={'nav_title'}>线索</span></NavBar>
            <NewClueCardForm onRef={(child) => {
                this.child = child
            }} newClue={this.state.newClue}></NewClueCardForm>
        </div>
    }
}


export default AddNewCluePage;
