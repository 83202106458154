import React from "react";
import {ListView, Toast} from "antd-mobile";
import { getOrigionalResource} from "../action";
import {Link} from "react-router-dom";
import {formatDate, isStringEmpty} from "../utils";
import {NoDataView} from "./NoDataView";
import {connect} from "react-redux";
import config from "../config";
import {setStoryId} from "../store/actions";
import WingBlank from "antd-mobile/es/wing-blank";

/**
 * 原创资源路径
 */
class OriginalResourceDataList extends React.Component {


    NUM_ROWS = 10;
    pageIndex = 1;
    rData = [];

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 7 / 8,
            dataStatus: '加载中...',
            type:props.type,
            statusName:props.statusName,
        };
    }


    updateStoryData(data) {
        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }
        ;

        //根据返回的数据量确定渲染的行数；
        this.NUM_ROWS = data.data.length;
        //根据返回的数据量确定渲染的行数；
        // setTimeout(() => {
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            dataStatus: data.count > 0 ? '加载完成' : '暂无数据'
        })

        //自增起始位置
        this.pageIndex++;
        // }, 1000);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        this.rData = [];
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        data.append('mediaCode', nextProps.mediaCode);
        if(this.props.type){
            data.append('type', this.props.type);
        }
        if(this.props.statusName){
            data.append('statusName', this.props.statusName);
        }
        getOrigionalResource(data, this.updateStoryData.bind(this));
    }

    componentWillMount() {
        this.getResourcePoolData();
    }

    getResourcePoolData() {
        this.rData = [];
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        data.append('mediaCode', this.props.mediaCode);
        if(this.props.type){
            data.append('type', this.props.type);
        }
        if(this.props.statusName){
            data.append('statusName', this.props.statusName);
        }
        getOrigionalResource(data, this.updateStoryData.bind(this));

    }

    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        this.setState({isLoading: true});
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
                isLoading: false
            })
            return;
        }

        Toast.loading('加载中...', 0, () => {
        });
        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        data.append('mediaCode', this.props.mediaCode);
        if(this.props.type){
            data.append('type', this.props.type);
        }
        if(this.props.statusName){
            data.append('statusName', this.props.statusName);
        }
        getOrigionalResource(data, this.updateStoryData.bind(this));

    }

    render() {

        const row = (rowData, sectionID, rowID) => {
            const obj = rowData;

            let configElement = config['dataSource2ActButtonId'];
            let actbuttonId = configElement[rowData.dataSource];
            return obj ? (
                <div>
                    <Link to={'/writeStory/' + obj.id + '/0' + '/' + actbuttonId + '/6'}>
                        <div key={rowID} style={{padding: '20px'}}>
                            <div
                                style={{
                                    fontSize: 17,
                                    color: 'black',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.title}}></div>
                            <div
                                style={{
                                    lineHeight: '30px',
                                    color: '#888',
                                    fontSize: 12,
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.summary) ? '' : obj.summary}}></div>
                            <div>
                                <div className={'cardBootm'}>
                                    <div style={{
                                        textAlign: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                        paddingBottom: '10px'
                                    }}>{formatDate(obj.submitDate ? (obj.submitDate.time ? obj.submitDate.time : '') : '')}</div>
                                    <div
                                        key={`${sectionID}-${rowID}`}
                                        style={{
                                            backgroundColor: '#F5F5F9',
                                            height: 0.5,
                                            // borderTop: '1px solid #ECECED',
                                            borderBottom: '1px solid #ECECED',
                                        }}
                                    />
                                    <div style={{paddingTop: '10px', color: '#888', fontSize: '12px',}}>
                                        <span>{obj.submitUser?obj.submitUser.name:obj.submitter}</span><span style={{
                                        float: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>状态: {obj.statusName}</span></div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>) : <div></div>;
        };

        return this.state.count > 0 ? (
            <div>
                <WingBlank style={{margin: '5px'}}>
                    <ListView
                        initialListSize={0}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                            {this.state.isLoading ? '加载中...' : '加载完成'}
                        </div>)}
                        renderRow={row}
                        style={{
                            height: this.state.height,
                            overflow: 'auto',
                        }}
                        pageSize={5}
                        onScroll={() => {
                        }}
                        scrollRenderAheadDistance={500}
                        onEndReached={this.onEndReached}
                        onEndReachedThreshold={10}
                    /></WingBlank>
            </div>
        ) : <NoDataView content={this.state.dataStatus}/>;
    }
}

/**
 * 将state属性值映射到props
 * @param state
 * @returns {{storyId: *}}
 */
const stateToProps = (state) => {
    return {
        storyId: state.storyReducer.storyId,
        currentMediaCode: state.storyReducer.currentMediaCode
    }

}

const dispatchToProps = (dispatch) => {
    return {
        setStoryId: (storyId) => {
            dispatch(setStoryId(storyId))
        }
    }

}

export default connect(stateToProps, dispatchToProps)(OriginalResourceDataList);
