import {jpost, post} from "./index";
import {baseUrl} from "../components/activity/ActButton";
/**
 * 新增 校内媒体预约
 * @param param
 * @param callBack
 */
export const addIntramuraImedia =(url, param, callBack) =>{
    jpost(baseUrl + url, param, callBack);
}


/**
 * 通过id,获取校内媒体预约信息
 * @param param
 * @param callBack
 */
export const getIntramuralMediaInfoByid=(param,url,callBack)=>{
    post(baseUrl +url , param, callBack);
}


/**
 * 校内媒体预约审核
 * @param param
 * @param callBack
 */
export const internalArrangeAudit=(param,url,callBack)=>{
    post(baseUrl + url, param, callBack);
}