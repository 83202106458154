import store from "../../store";
import {loadEditorConfig,loadFormat} from "../../action/storyAction";
import {renderAudio,renderVideo,renderImage,transformMedia} from './Editor.render';
import { Modal,TextareaItem } from 'antd-mobile';
import config from "../../config";
import React from "react";
import {
    ADD_RESOURCE_AUDIO,
    ADD_RESOURCE_PICTURE,
    ADD_RESOURCE_VIDEO
} from "../../store/actions/action-type/action-types";
import $ from "jquery";
import eventProxy from"../../utils/eventProxy"
const prompt = Modal.prompt;
const alert = Modal.alert;
const baseUrl = config.baseUrl;

const editorConfig = function(config,editorViewComp){
    return {
        // eslint-disable-next-line
        el: '.media-eleditor',
        /*初始化完成钩子*/
        mounted: function () {
        },
        changer: function () {
            if(editorViewComp.state.editor){
				let transformContent = transformMedia(editorViewComp.state.editor.getContent());
                editorViewComp.props.setContent(transformContent);
                editorViewComp.props.setStoryChanged(true);
            }
        },
        /*自定义按钮的例子*/
        toolbars: [
            'insertText',
            'editText',
            {
                id: 'insertImage',
                name:'插入照片',
                inUpload:true,
                options:{
                    uploadConfig:{
                        server: baseUrl+'/cm-sites-app-h5/ueditor/control?action='+(config.imageActionName||'uploadimage'),//后台接收地址
                        fileSizeLimit: config.imageMaxSize||5*1024*1024,//限制文件上传大小
                        fileNumLimit:config.imageManagerListSize||20,
                        formName: config.imageFieldName||'file',//设置文件name,
                        // 自动上传。
                        auto: true,
                        duplicate:true,
                        accept: {//accept一般不用设置
                            title: '照片',
                            extensions: config.imageAllowFiles||'png,jpg,gif,jpeg,bmp,webp,webp2',
                            mimeTypes: 'image/*'
                        }
                    },
                    success:function(_file, _call,callBack){
                        console.log(_file,_call)
                        if(_call.success){
                            renderImage(_file, _call,editorViewComp.state.editor);
                            saveUploadParam(_file, _call,editorViewComp,ADD_RESOURCE_PICTURE);
                        }
                        //照片调用 原始方法
                        //if(typeof callBack == "function")callBack(_file, _call);
                    }
                }
            }, {
                id: 'insertImgMore',
                name: '插入照片',
                handle: function(_select, _controll){
                   //扩展编辑器功能，不懂源码勿动
                    alert('操作按钮', <div></div>, [
                        { text: '插入任务图片', onPress: () => {
                            console.log('插入任务图片');
                                setTimeout(() => {
                                    eventProxy.trigger('insertTaskImg','2')
                                },0)
                            } },
                        { text: '插入资源库图片', onPress: () =>{
                                console.log('插入资源库图片');
                                setTimeout(() => {
                                    eventProxy.trigger('insertZykImg','2')
                                },0)
                        } },
                        { text: '插入本机图片', onPress: () =>{
                            //获取原来插入图片的dmo 结构
                                console.log($($('.Eleditor-insertImage input')[0]).click());
                                //调用原来的上传图片方法插入
                                $($('.Eleditor-insertImage input')[0]).click()
                            }},
                        { text: '取消', onPress: () => console.log('点击了插入图片取消') },
                    ])
                },
                //是否显示
                isControllerShow:function(_select, _li){
                    return true;
                }
            },
            'insertLink',
            'insertHr',
            'delete',
            {
                id: 'insertLinkStory',
                name: '链接稿件',
                handle: function(_select, _controll){
                    if(editorViewComp.state.story && editorViewComp.state.story.content){
                        alert('警告', '转化为链接稿件将清空稿件内容，是否继续？', [
                            { text: '否'},
                            { text: '确定', onPress: () => {editorViewComp.setState({isLinkStory:true});editorViewComp.props.setContent('');}}
                        ])
                    }else{
                        editorViewComp.setState({isLinkStory:true});
                        editorViewComp.props.setContent('');
                    }
                },
                //是否显示
                isControllerShow:function(_select, _li){
                    return true;
                }
            },
            //自定义一个视频按钮
            {
                id: 'insertVideo',
                // tag: 'p,img', //指定P标签操作，可不填
                name: '插入视频',
                inUpload:true,
                options:{
                        uploadConfig:{
                            // 自动上传。
                            auto: true,
                            duplicate:true,
                            server: baseUrl+'/cm-sites-app-h5/ueditor/control?action='+(config.videoActionName||'uploadVideo'),//后台接收地址
                            fileSizeLimit: config.videoMaxSize||50*1024*1024,//限制文件上传大小
                            fileNumLimit:config.videoManagerListSize||20,
                            formName: config.videoFieldName||'file',//设置文件name,
                            accept: {//accept一般不用设置
                                title: '视频',
                                extensions: config.videoAllowFiles||'mkv, flv, avi, rm, rmvb, mpeg, mpg, ogv, mov, wmv, mp4, webm',
                                mimeTypes: 'video/*'
                            }
                        },
                        success:function(_file, _call,callBack){
                            if(_call.success){
                                renderVideo(_file, _call,editorViewComp.state.editor);
                                saveUploadParam(_file, _call,editorViewComp,ADD_RESOURCE_VIDEO);
                            }
                            //if(typeof callBack == "function")callBack(_file, _call);
                        }
                }
            },
            {
                id: 'insertVideoMore',
                name: '插入视频',
                handle: function(_select, _controll){
                    //扩展编辑器功能，不懂源码勿动
                    alert('操作按钮', <div></div>, [
                        { text: '插入任务视频', onPress: () => {
                                console.log('插入任务视频');
                                setTimeout(() => {
                                    eventProxy.trigger('insertVideo','3')
                                },0)
                            } },
                        { text: '插入资源库视频', onPress: () =>{
                                console.log('插入资源库视频');
                                setTimeout(() => {
                                    eventProxy.trigger('insertZykVideo','3')
                                },0)
                            } },
                        { text: '插入本机视频', onPress: () =>{
                                //获取原来插入图片的dmo 结构
                                console.log($($('.Eleditor-insertVideo input')[0]).click());
                                //调用原来的上传图片方法插入
                                $($('.Eleditor-insertVideo input')[0]).click()
                            }},
                        { text: '取消', onPress: () => console.log('点击了插入视频取消') },
                    ])
                },
                //是否显示
                isControllerShow:function(_select, _li){
                    return true;
                }
            },
            {
                id: 'insertAudio',
                // tag: 'p,img', //指定P标签操作，可不填
                name: '插入音频',
                inUpload:true,
                options:{
                    uploadConfig:{
                        // 自动上传。
                        auto: true,
                        duplicate:true,
                        server: baseUrl+'/cm-sites-app-h5/ueditor/control?action='+(config.audioActionName||'uploadVideo'),//后台接收地址
                        fileSizeLimit: config.audioFileMaxSize||50*1024*1024,//限制文件上传大小
                        fileNumLimit:config.audioManagerListSize||20,
                        formName: config.audioFileFieldName||'file',//设置文件name,
                        accept: {//accept一般不用设置
                            title: '音频',
                            extensions: config.audioFileAllowFiles||'mp3, mid, wav, ogg',
                            mimeTypes: 'audio/*'
                        }
                    },
                    success:function(_file, _call,callBack){
                        if(_call.success){
                            renderAudio(_file, _call,editorViewComp.state.editor);
                            saveUploadParam(_file, _call,editorViewComp,ADD_RESOURCE_AUDIO);
                        }
                    }
                }
            },
            {
                id: 'insertAnnotation',//不能重复，此id可用于定义按钮样式，例如创建后按钮类为Eleditor-insertShop
                //tag: 'img', //指定标签显示按钮，逗号分隔，默认不指定
                name: '插入批注',
                //指定 展示 元素
                tag:'P',
                handle: function(_select, _controll){
                    let pTag = _select.closest('p');
                    if(pTag){
                        let preValue = pTag.find('pizhu').text();
                        alert('插入批注',<TextareaItem
                            placeholder="请输入批注内容"
                            type={'text'}
                            id={'editor-pizhu-textarea'}
                            defaultValue ={preValue}
                            style ={{maxHeight:'100px',overflowY:'auto'}}
                            autoHeight
                        />, [
                            { text: '取消'},
                            { text: '确定', onPress: () => {
                                    var value = document.getElementById('editor-pizhu-textarea').value;
                                        if(value){
                                            pTag.find('badge').remove();
                                            pTag.find('pizhu').remove();
                                            pTag.addClass('pizhu');
                                            pTag.css('background-color','#f2ccd1');
                                            pTag.prepend(`<pizhu style="display:none;">${value}</pizhu>`);
                                            //pTag.prepend(`<badge style="position: absolute;right: 0;z-index: 1;"/><pizhu style="display:none;">${value}</pizhu>`);
                                            //ReactDOM.render(<Badge dot={true}></Badge>,pTag.find('Badge')[0]);
                                            editorViewComp.state.editor.change();
                                        }
                                    }
                                },
                        ]);
                    }
                },
                //是否显示
                isControllerShow:function(_select, _li){
                    if(_select.hasClass("Eleditor-placeholder")){
                        return false;
                    }
                    return true;
                }
            },
            {
                id: 'lookAnnotation',
                //tag: 'img', //指定标签显示按钮，逗号分隔，默认不指定
                name: '查看批注',
                //指定 展示 元素
                tag:'P',
                handle: function(_select, _controll){
                    let pTag = _select.closest('p');
                    if(pTag){
                        let preValue = pTag.find('pizhu').text();
                        alert('查看批注',<div class='lookAnnotation'>{preValue}</div>,[{ text: '取消'}]);
                    }
                },
                //是否显示
                isControllerShow:function(_select, _li){
                    if(_select.find('pizhu').length >0) return true;
                    return false;
                }
            },
            'undo',
            'cancel',
        ]
    }
}

/**
 *
 * @param _file
 * @param _call
 * @param editorViewComp
 * @param resource_type
 */
const saveUploadParam  =(_file,_call,editorViewComp,resource_type) =>{
    if(_file && _call){
        editorViewComp.props.addStoryResource({
            name:_file.name,
            ext:_file.ext,
            url:_call.url,
            size:_file.size
        },resource_type);
    }
}

/**
 * 加载后台 config
 */
export const initLoadConfig =(callback,editorViewComp) =>{
      loadEditorConfig({},function(config){
          config = eval('('+config+')');
         callback(editorConfig(config,editorViewComp));
     })
}

/**
 * 一键排版
 * @param _file
 * @param _call
 * @param editor
 */
export const setStoryFormat = function(comp,format,callback){
	 let story = store.getState().storyReducer.story;
	 if(story && story.content){
		 loadFormat({content: story.content,formatType:format},function(formatContent){
             callback&&callback()
		     let whnewContent=whdxNewsFormat(formatContent);
		     comp.props.setContent(whnewContent);
			 comp.props.setFormat(format);
		 });
	 }
}


function whdxNewsFormat(data){

    data = data.replace(/(新闻网讯)(\(|（)(.+?)(\)|）)/,"$1$2<span style=\"font-family: 楷体_GB2312;\">$3</span>$4");

    data = data.replace(/(.*<p class="vsbcontent_end)(">)(。*[\(（].*[摄影|编辑].*[\)）].*)(<\/p>)/,"$1\"  style=\"text-align: right;$2<span style=\"font-family: 楷体_GB2312;\">$3</span>$4")

    return data;
}