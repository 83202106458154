import React from "react";
import {Flex, ListView, Toast, WhiteSpace, Badge, WingBlank} from "antd-mobile";
import {getInfoFromLocalStorage, getMessageListByType, getUnReadMsgCount} from "../action";
import {dealElement, formatDate, isStringEmpty, subString, uuid} from "../utils";
import {Link} from "react-router-dom";
import {NoDataView} from "./NoDataView";
import './contributionsItem/contributionsItem.css'
import './message_ListView.css'


/**
 * 消息类型对应的消息列表数据
 */
class MessageListView extends React.Component {


    pageIndex = 1;
    rData=[];

    constructor(props) {
        super(props);

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            userName: getInfoFromLocalStorage("userName"),
            height: window.innerHeight -39,
            dataStatus: '加载中...',
        };
        this.updateMessageList = this.updateMessageList.bind(this);
    }

    componentWillMount() {
        var messageType = this.props.messageType;
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        //获取消息分类的列表
        getMessageListByType(data, this.updateMessageList)
    }

    updateMessageList(data) {

        Toast.hide();
        console.log('updateMessageList', data);
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }
        ;

        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            dataStatus: data.count > 0 ? '加载完成' : '暂无数据'
        })

        //自增起始位置
        this.pageIndex++;
    }

    getPageListData() {
        Toast.loading('加载中...', 0, () => {
            console.log('Load complete !!!');
        });
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        getMessageListByType(data, this.updateMessageList.bind(this));
    }

    onEndReached = (event) => {

        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }

        Toast.loading('加载中...', 0, () => {
            console.log('Load complete !!!');
        });
        this.setState({isLoading: true});
        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        getMessageListByType(data, this.updateMessageList.bind(this));

    }

    render() {
        let index = 0;
        const row = (rowData, sectionID, rowID) => {
            const obj = rowData;
            var icon_type_element = null;
            var messageType = null;
            var backcolor = null;
            switch (obj.type) {
                case 1:
                    icon_type_element = <img style={{height: '40px'}} src={"images/icon-story.png"}/>;
                    messageType = '稿件';
                    backcolor = '#00BA85';
                    break;
                case 2:
                    icon_type_element = <img style={{height: '40px'}} src={"images/icon-story.png"}/>;
                    messageType = '约稿';
                    backcolor = '#FF7953';
                    break;
                case 3:
                    icon_type_element = <img style={{height: '40px'}} src={"images/icon-newclue.png"}/>;
                    messageType = '线索';
                    backcolor = '#A652E8';
                    break;
                case 4:
                    icon_type_element = <img style={{height: '40px'}} src={"images/icon-intervier.png"}/>;
                    messageType = '任务';
                    backcolor = '#4677F3';
                    break;
                case 5:
                    icon_type_element = <img style={{height: '40px'}} src={"images/icon-notice.png"}/>;
                    messageType = '公告';
                    backcolor = '#FF7953';
                    break;
                default:
                    icon_type_element = <img style={{height: '40px'}} src={"images/icon-message.png"}/>;
                    messageType = '消息';
                    backcolor = '#00BA85';
            }

            var isUnReadFlag = false;
            if (obj && (obj.isReadstatus == 1 || this.props.allRead)) {
                isUnReadFlag = false;
            }

            const unreadFlagBadge = <div dot={isUnReadFlag}></div>;
            return obj ? <div key={uuid()} style={{backgroundColor: '#F5F5F9'}}>
                <WhiteSpace size="lg" style={{backgroundColor: '#F5F5F9',height:'2px'}} />
                    <Link style={{width:'100%'}}  to={'/messageDetailView/' + obj.id + '/' + obj.type}>
                        <div style={{background:'#ffffff',width:'95vw',margin:'0 auto',marginTop:'1vw'}} >
                            <div style={{padding:'5vw', lineHeight: '5vw',display:'flex'}} className={'twoText'}>
                                {/*<div style={{width: '15vw',height:'16px',background:backcolor,fontSize:'10px',lineHeight:'16px',borderRadius: '3vw',color:'white',textAlign: 'center'}}>{unreadFlagBadge}{messageType}</div>*/}
                                <div style={{
                                    width: '10vw',
                                    height:'15px',
                                    background:backcolor,
                                    fontSize:'10px',
                                    borderRadius: '3vw',
                                    color:'white',
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'center',
                                    textAlign:'center',
                                    lineHeight:'15px',
                                    marginTop:'3px'
                                }}>
                                    {unreadFlagBadge}{messageType}
                                </div>
                                <div className={'twoText'} style={{marginLeft:'3vw',fontSize:'4vw',width:'65vw',color:'#000000',}}>{obj.title}</div>
                            </div>
                            <div className={'flex'} style={{padding:'3vw 5vw 5vw 5vw',fontSize:'3vw'}}>
                                <div className={'gery'}>{obj.senderInfo?obj.senderInfo.name:obj.sender}</div>
                                <div className={'gery'}>{formatDate(obj.createTime.time)}</div>
                            </div>
                        </div>
                    </Link>
            </div>:<div></div>
        };

        return this.state.count > 0 ? (
            <div>
                <WingBlank style={{margin:'5px'}}>
                    <ListView
                        initialListSize={10}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                            {this.state.isLoading ? '加载中...' : '加载完成'}
                        </div>)}
                        renderRow={row}
                        style={{
                            height: this.state.height,
                            overflow: 'auto',
                        }}
                        pageSize={10}
                        onEndReached={this.onEndReached}
                        onEndReachedThreshold={10}
                    />
                </WingBlank>
            </div>
        ) : <NoDataView content={this.state.dataStatus}/>;
    }
}


export default MessageListView;
