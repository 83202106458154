import React, {Component} from 'react';
import {
    getDepInfoByDepId,
    getInfoFromLocalStorage,
    getRoleInfoByRoleId,
    getUserInfoByAccount, logout,
    setInfoToLocalStorage
} from "../../action";
import {Toast,Modal} from "antd-mobile";
import './my_head.css';
import config from "../../config";

/**
 * 我的头像页面
 */

const alert = Modal.alert;
class MyHead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            history: require("history").createHashHistory,
        }
    }

    componentWillMount() {
        let param = {"account": getInfoFromLocalStorage("userName")}
        getUserInfoByAccount(param, this.updateUserInfo.bind(this));
    }

    /**
     * 把用户的信息回填到页面
     */
    updateUserInfo(data) {
        if (data) {

            this.setState({
                userInfo: data,
                userDeptName: "",
                userRoleName: "",
            })

            //获取部门信息，角色信息
            let param = new FormData();
            param.append('deptIds[]', data.deptIds);
            getDepInfoByDepId(param, this.updateDepInfo.bind(this));

            let param1 = new FormData();
            param1.append('roleCodes[]', data.roleCodes);
            // getRoleInfoByRoleId(param1, this.updateRoleInfo.bind(this));
        } else {
            Toast.fail("获取用户信息失败");
        }
    }

    updateDepInfo(data) {
        if (data && data.length > 0) {

            var deptName = "";
            for (var i = 0; i < data.length; i++) {
                deptName += data[i].name + "  ";
            }

            this.setState({
                userDeptName: deptName,
            })
        } else {
            Toast.fail("查询部门信息失败")
        }
    }

    updateRoleInfo(data) {
        if (data && data.length > 0) {
            var userRoleName = "";
            for (var i = 0; i < data.length; i++) {
                userRoleName += data[i].roleName + "  ";
            }
            this.setState({
                userRoleName: userRoleName,
            })
        } else {
            Toast.fail("查询部门信息失败")
        }

    }

    loginOut() {
        //调用后台退出；
        alert('注销', '确定要退出当前用户吗', [
            { text: '否', onPress: () => console.log('cancel') },
            { text: '是', onPress: () => logout({}, this.logoutBack.bind(this)) },
        ])
    }

    logoutBack(data) {
        if (data) {
            clearInterval(window.authTimer);
            let path = '/login/login';
            window.location.href=config.loginUrl;
            window.location.search = "";
            //  window.location = config.loginUrl;
            //this.state.history().push(path);
        }
    }

    render() {
        let header_url = "images/header_girls.png";
        if (this.state.userInfo.sex === 1) {
            header_url = 'images/header_boys.png'
        }
        if (this.state.userInfo.photoUrl) {
            header_url = this.state.userInfo.photoUrl;
        }


        return <div style={{
            textAlign: 'center',
            height: '150px',
            width: '100%',
            background: 'url("images/backgroung_icon.png") no-repeat center',
           backgroundSize:'100%',
        }}>
            {/*<div className={'userinfo'}>*/}
            {/*    <img id="headPortrait" src={header_url} width={'100%'} height={'100%'}/>*/}
            {/*</div>*/}
            {/*<div className={'cancellation'} onClick={()=>{this.loginOut()}} style={{top:'5px',right:'10px',position:'absolute'}}>*/}
            {/*    <img id="cancellation" src={'images/cancellation_01.png'} width={'30px'}/>*/}
            {/*</div>*/}

            {/*<div style={{textAlign: 'center', color: '#ffffff', marginTop: '10px'}}>*/}
            {/*    <span><img className="name_img" src="images/icon_name.png"/>姓名:{this.state.userInfo.name}</span></div>*/}
            {/*<div style={{textAlign: 'center', color: '#ffffff', marginTop: '10px'}}>*/}
            {/*    /!*<span>角色:{this.state.userRoleName}</span>*!/*/}
            {/*    <span>部门:{this.state.userDeptName}</span>*/}
            {/*</div>*/}
            <div className="search_add">
                {/*<ul>*/}
                {/*    <li className="add_pic">*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </div>
        </div>
    }
}

export default MyHead