import React, {Component} from 'react';

/**
 * 业务导航页
 */
class BusinessPage extends Component
{
    constructor(props){
        super(props);
    }

    render(){
        return <div>业务</div>
    }
}

export default BusinessPage;
