const config = {
    actionSheetButtons: [
        // {url: 'images/submit_to_ys.png', title: '提交至一审', buskey: 'submit_to_ys'},
        // {url: 'images/submit_to_shr.png', title: '提交至部门审核人', buskey: 'submit_to_shr'},
        // {url: 'images/submit_to_bj.png', title: '提交至编辑', buskey: 'submit_to_bj'},
        // {url: 'images/fuzhshhe.png', title: '辅助审核', buskey: 'submit_to_fzsh'},
        // {url: 'images/submit_to_bj.png', title: '返回至编辑', buskey: 'submit_to_goback_bj'},
        // {url: 'images/submit_to_bj.png', title: '返回给编辑', buskey: 'submit_to_goback_bj'},
        // {url: 'images/submit_to_zb.png', title: '提交至总编', buskey: 'submit_to_zb'},
        // {url: 'images/submit_to_bz.png', title: '提交至部长', buskey: 'submit_to_bz'},
        // {url: 'images/submit_to_fb.png', title: '提交至副部长', buskey: 'submit_to_fbz'},
        // {url: 'images/submit_to_bj.png', title: '提交至负责人', buskey: 'submit_to_fzr'},
        // {url: 'images/goback_update.png', title: '返回至写稿人', buskey: 'goback_to_update'},
        // {url: 'images/goback_update.png', title: '返回至提交人', buskey: 'goback_to_update'},
        // {url: 'images/goback_update.png', title: '返回修改', buskey: 'goback_to_update'},
        // // {url: 'images/submit_to_fb.png', title: '发布', buskey: 'publish'},
        // {url: 'images/submit_to_fb.png', title: '提交至二审', buskey: 'publish'},
        // {url: 'images/submit_to_fb.png', title: '选栏目', buskey: 'select_clumn'},
        // {url: 'images/cross.png', title: '通过', buskey: 'goback_to_update'},
        // {url: 'images/delete.png', title: '弃用', buskey: 'delete'},
        // {url: 'images/save.png', title: '保存', buskey: 'save'},
        // {url: 'images/goback_update.png', title: '撤回', buskey: 'cancel'},

        //根据流程style渲染按钮；
        {url: 'images/goback_1.png', title: '返回', style: 'act_goback',styleCss:"background:white"},
        {url: 'images/submit_1.png', title: '提交', style: 'act_submit'},
        {url: 'images/cross_1.png', title: '通过', style: 'act_through'},
        {url: 'images/delete_1.png', title: '弃用', style: 'act_deprecated'},
        {url: 'images/transfer_1.png', title: '转交', style: 'act_transfer'},
        {url: 'images/publish_1.png', title: '发布', style: 'act_publish',backgroundColor:"#35A9FF"},
        {url: 'images/assist_1.png', title: '辅助', style: 'act_assist'},//
        {url: 'images/save_1.png', title: '保存', style: 'save'},
        {url: 'images/preview_1.png', title: '预览', style: 'preview'},  //最后一个是默认按钮， 不可以修改这个顺序的；
        {url: 'images/akey_layout_1.png', title: '一键排版', style: 'akey_layout'},
        {url: 'images/access_1.png', title: '访问', style: 'visitor_button'},


        {url: 'images/default_1.png', title: '默认', style: 'default'},
    ],
    style2ButtonImgUrl:{
        act_goback:'images/goback_1.png',  //返回
        act_submit:'images/submit_1.png',  //提交
        act_through:'images/cross_1.png',   //通过，
        act_deprecated:'images/delete_1.png',  //弃用
        act_transfer:'images/transfer_1.png',  //转交
        act_publish:'images/publish_1.png',   //发布
        act_assist:'images/assist_1.png',  //辅助
        save:'images/save_1.png',     //保存
        preview:'images/preview_1.png',  //预览
        akey_layout:'images/akey_layout_1.png',  //一键排版
        visitor_button:'images/access_1.png',  //访问
        default:'images/default_1.png',  //默认
    },
    contributeRoleCodes: ['txy', 'shr', 'xsjz','cs'],
    baseUrl:'',
    deploymentKey2ActButtonId:{
        "story":2,
        "weibo":3,
        "unfzr":5,
        "fzr":6,
        "weixin":7,
        "douyin":8,
        "yuegao":4,
    },

    //数据院和 actButtonId的关系
    dataSource2ActButtonId:{
        1:2,   //写稿，  对应的 story
        2:4, //约稿，  对应的 yuegao
        4:5,//采访，  对应的  unfzr，fzr
        7:2,//投稿，  对应的 story
    },

     videoAllowFiles : ["flv", "mkv", "avi", "rm", "rmvb", "mpeg", "mpg", "ogv", "mov", "wmv", "mp4", "webm"],
     audioAllowFiles : ["mp3", "mid", "wav", "ogg"],
     pictureAllowFiles : ["gif", "jpeg", "png", "bmp","jpg"],

    //baseUrl: 'http://192.168.120.50:86',
    appUploadFlag: false,    //调用app的接口上传文件。
   loginUrl:"/cm-sites/app-h5/index.html#/login/login",
}

export default config;
