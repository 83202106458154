import React, {Component} from 'react';
import {ListView, Tabs, Toast, WingBlank} from 'antd-mobile'
import './my_head.css';
import ContributionsItem from "../../components/contributionsItem/contributionsItem";
import * as Actions from "../../action/HomeMessage";
import {getInfoFromLocalStorage} from "../../action";
import {uuid} from "../../utils";
/**
 * 首页的 消息列表
 */
class HomeMessage extends Component {
    rData=[];
    pageIndex = 1;
    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            history: require("history").createHashHistory(),
            tabList:[
                { title: '待我处理', key: '1' },
                { title: '我已处理', key: '2' }
            ],
            currentTabIndex:0,
            list:[],
            dataSource:dataSource,
            page:1,
            pageSize:10,
            count:0,
            hasMore: true,
            isContributeRoleFlag:false,
            height: document.documentElement.clientHeight-226,
            key:'',
        }
    }
    componentWillMount() {
        let isContributeRoleFlag = getInfoFromLocalStorage("isContributeRoleFlag");
        this.setState({isContributeRoleFlag:isContributeRoleFlag},()=>{
            this.getList()
        })
    }

    /*下拉刷新公共方法*/
    publish(data){
        Toast.hide();
        //请求后台异常。
        if (!data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            });
            return;
        }
        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            pageSize: 10,
            dataStatus: data.data.length > 0 ? '加载完成' : '暂无数据'
        });
        ++this.pageIndex;

    }

    //下拉刷新按钮
    onEndReached = (event) => {
        if(this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }
        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        this.getList()
    };
    // 下拉刷新切换tab
    refresh(){
        this.pageIndex = 1;
        this.rData =[];
        if(this.state.currentTabIndex === 0){
            this.getList()
        }else{
            this.getComplete()
        }
    }


    /*待我处理*/
    getList(){
        if(this.state.isContributeRoleFlag){
            Actions.handleBymeForContribute({
                start:this.pageIndex,
                size:this.state.pageSize,
            },(data) => {
                this.publish(data)
                this.setState({list:data.data,});
            })
        }else{
            Actions.handleBymeForPms({
                start:this.pageIndex,
                size:this.state.pageSize,
            },(data) => {
                this.publish(data)
                this.setState({list:data.data,})
            })
        }
    }

    /*我已处理*/
    getComplete(){
        if(this.state.isContributeRoleFlag){
            Actions.finishBymeForContribute({
                start:this.pageIndex,
                size:this.state.pageSize,
            },(data) => {
                this.publish(data)
                this.setState({list:data.data,});
            })
        }else{
            Actions.finishBymeForPms({
                start:this.pageIndex,
                size:this.state.pageSize,
            },(data) => {
                this.publish(data)
                this.setState({list:data.data,});
            })
        }
    }

    //待我处理和我已处理tab切换
    tabChange(index, tab){
        this.pageIndex = 1;
        this.state.pageSize =10;
        this.rData =[];
        this.state.key = uuid();
        if(tab.key ==='1'){
            this.getList()
        }else{
            this.getComplete()
        }
    }

    //列表不同类型跳转至详情页
    manuscriptDetails(record){
        let type=record.type;
        let id=record.id;
        let appointmentId=record.appointmentId;
       // let actButtonId=record.actButtonId;
        if(type === 1){
            this.state.history.push('/taskDetails/taskDetails/'+id)
        }else if(type === 2){
            this.state.history.push('/topicDetails/topicDetails/'+id)
        }else if(type === 3){
            this.state.history.push('/newClueDetailPage/newClueDetailPage/'+id)
        }else if(type === 4){
            this.state.history.push('/manuscriptDetailPage/manuscriptDetailPage/'+appointmentId+'/'+id)
        }else{
            if(record.interviewId&&record.interviewId>0){
                this.state.history.push('/taskInterview/writeStory/'+id+'/'+record.interviewId+'/5')
            }else{
                this.state.history.push('/writeStory/'+id+'/0'+'/0/1')
            }
        }
    }

    render() {
        const row = (rowData,sectionID, rowID) => {
            let name,backcolor;
            switch (rowData.type) {
                case 1:
                    name = '任务';
                    backcolor = '#4ec78a';
                    break;
                case 2:
                    name = '选题';
                    backcolor = '#ff6868';
                    break;
                case 3:
                    name = '线索';
                    backcolor = '#7c53c9';
                    break;
                case 4:
                    name = '约稿';
                    backcolor = '#ff7953';
                    break;
                case 5:
                    name = '稿件';
                    backcolor = '#5073d5';
                    break;
            }
            const obj = rowData;
            return obj ? (
                <div key={uuid()}>
                    <ContributionsItem
                        refresh={()=>{this.refresh()}}
                        content={rowData.title}
                        color={backcolor}
                        createTime={rowData.date}
                        name={rowData.statusName}
                         type={name}
                        manuscriptDetails={()=>{this.manuscriptDetails(rowData)}}
                    >
                    </ContributionsItem>
                </div>):<div></div>;
        };

        return <div style={{width:'100%',marginTop:'-65px'}}>
            <Tabs tabs={this.state.tabList}
                  className="paper_list"
                  initialPage={this.state.currentTabIndex}
                  tabBarInactiveTextColor={{color:' #666666'}}
                  onChange={(tab, index) => {this.tabChange(index, tab) }}
            >
            </Tabs>
            {this.state.count >0 ?
            <WingBlank style={{margin:'2px'}}>
                <ListView
                    key={this.state.key}
                    initialListSize={this.state.count}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{padding:30,textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '加载完成'}
                    </div>)}
                    renderRow={row}
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    pageSize={this.state.pageSize}
                    onScroll={() => {
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </WingBlank>
            :<div style={{textAlign:'center',marginTop:100,color:'#999999'}}>暂无数据</div>}
        </div>
    }
}

export default HomeMessage