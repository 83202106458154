/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './TopicDetails.css'
import {Icon, WhiteSpace, TextareaItem, Modal, Popover, NavBar} from 'antd-mobile';
import * as Actions from "../../action/topiclist";
import {TOPICSTATUS} from "../../utils/Enumeration";
import {PIC_TYPE} from "../../constants";
import {getInfoFromLocalStorage} from "../../action";

const Item = Popover.Item;


export default class TaskDetails extends Component {
    static defaultProps = {};
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            PopoverVisible: false,
            showList: false,
            viewPicture: false,
            list: {},
            tsFileIds: [],
            img: '',
            tsFileList: [],
            getAuthBtn: [],
            tsCreateByName: [],//
            tsAppointee: [],
            id: '',
            remarks: false,
            tsAppointeeName: []
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        let id = this.props.match.params.storyId;
        this.setState({id: id}, () => {

            this.getTopicDetails()
        });
    }

    getTopicDetails() {
        Actions.topicDetails({id: this.state.id}, (data) => {
        let usrInfo=getInfoFromLocalStorage("userInfo");
        console.log(usrInfo.name)
            let tsAppointeeName = [];
            let tsCreateByName = [];
            if (data.tsAppointeeName) {
                tsAppointeeName = data.tsAppointeeName;
            }
            if (data.tsCreateByName) {
                data.tsCreateByName.forEach((item,index)=>{
                    if(item === usrInfo.name){
                        data.tsCreateByName.splice(index,1)
                        data.tsCreateByName.unshift(usrInfo.name)
                    }
                })
                tsCreateByName =  data.tsCreateByName
            }
            this.setState({
                list: data,
                tsFileIds: data.tsFileIds,
                tsCreateByName: tsCreateByName,
                tsAppointee: data.tsAppointee,
                tsAppointeeName: tsAppointeeName
            });
            this.getEnclosure();
            this.getAuth();
        });
    }

    goBack() {

        this.props.history.goBack();
        // let path = '/taskManagement/taskManagement';
        //this.state.history().push(path);
    }

    render() {
        let bgColor, statusName;
        switch (this.state.list && this.state.list.tsStatus) {
            case TOPICSTATUS.draft.statusType :
                statusName = TOPICSTATUS.draft.statusName;
                bgColor = TOPICSTATUS.draft.bgColor;
                break;
            case TOPICSTATUS.submit.statusType :
                statusName = TOPICSTATUS.submit.statusName;
                bgColor = TOPICSTATUS.submit.bgColor;
                break;
            case TOPICSTATUS.withdraw.statusType :
                statusName = TOPICSTATUS.withdraw.statusName;
                bgColor = TOPICSTATUS.withdraw.bgColor;
                break;
            case TOPICSTATUS.retention.statusType :
                statusName = TOPICSTATUS.retention.statusName;
                bgColor = TOPICSTATUS.retention.bgColor;
                break;
            case TOPICSTATUS.disuse.statusType :
                statusName = TOPICSTATUS.disuse.statusName;
                bgColor = TOPICSTATUS.disuse.bgColor;
                break;
            case TOPICSTATUS.use.statusType :
                statusName = TOPICSTATUS.use.statusName;
                bgColor = TOPICSTATUS.use.bgColor;
                break;
            case TOPICSTATUS.task.statusType :
                statusName = TOPICSTATUS.task.statusName;
                bgColor = TOPICSTATUS.task.bgColor;
                break;
            case TOPICSTATUS.collaboration.statusType :
                statusName = TOPICSTATUS.collaboration.statusName;
                bgColor = TOPICSTATUS.collaboration.bgColor;
                break;
        }
        return (
            <div className={'TaskDetailsBox'}>
                <NavBar
                    mode={'light'}
                    style={{position: 'fixed', width: '100%', lineHeight: '45px',zIndex:'999'}}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'} onClick={() => this.props.history.goBack()}>返回</div>
                    ]}

                ><span className={'nav_title'}>选题详情</span></NavBar>

                <WhiteSpace size="lg"/>
                <div className={'TaskDetailsNormalBox '} style={{marginTop: 45}}>
                    <div className={'oneText'} style={{width: '75%'}}>{this.state.list && this.state.list.tsName}</div>
                    <div style={{
                        padding: '1px 10px',
                        backgroundColor: bgColor,
                        borderRadius: '25px',
                        lineHeight: '20px',
                        height: '20px',
                        fontSize: '4vw',
                        marginTop: 15, color: 'white'
                    }}>{statusName}</div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>涉及单位</div>
                        <div>{this.state.list && this.state.list.tsCompany}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>联系人</div>
                        <div>{this.state.list && this.state.list.tsContactsName}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>联系方式</div>
                        <div>{this.state.list && this.state.list.tsContactsPhone}</div>
                    </div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>提交时间</div>
                        <div>{this.state.list && this.state.list.tsCreateTime}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>创建人</div>
                        <div style={{textAlign: 'right'}} className={'oneText'}>
                            {this.state.tsCreateByName.toString().replace(/,/g,'、')}
                        </div>
                    </div>
                    {

                        this.state.list && this.state.list.tsStatus === '8' ? <div className={'TaskDetailsNormalBox '}>
                            <div>指派给</div>
                            <div style={{textAlign: 'right'}} className={'oneText'}>
                                {this.state.tsAppointeeName.length ? this.state.tsAppointeeName.toString().replace(/,/g,'、') : null}

                            </div>
                        </div> : <div className={'TaskDetailsNormalBox '}>
                            <div>预指派</div>
                            {console.log(this.state.tsAppointeeName)}
                            <div> {this.state.tsAppointeeName.length ?
                                <div>{this.state.tsAppointeeName.toString().replace(/,/g,'、')}</div> : null}</div>
                        </div>
                    }
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox'}>
                        <div>选题内容</div>
                    </div>
                    <div className={'inputBox'}>
                        <style>{`.inputBox .am-textarea-control textarea{font-size:4vw}`}</style>
                        <TextareaItem
                            rows={3}
                            value={this.state.list && this.state.list.tsContent}
                            placeholder="任务内容描述"
                        />
                    </div>
                </div>
                <div style={{marginBottom: '15vw'}}>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox border'}>
                        <div>附件</div>
                    </div>
                    <div style={{
                        background: 'white',
                        padding: '0vw 4vw 0vw 4vw',
                        lineHeight: '6vw',
                        color: '#999999',
                        fontSize: '3vw'
                    }}>
                        {
                            this.state.tsFileList && this.state.tsFileList.map((item, index) => {

                                return (
                                    <div style={{display: 'flex', lineHeight: '12vw'}} className={'border'} key={index}>
                                        <div style={{
                                            width: '70%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>{item.name}</div>
                                        {
                                            item.type ? <div style={{width: '15%'}} onClick={() => this.watch(item.url)}
                                                             className={'color'}>查看</div> :
                                                <div style={{width: '15%'}}></div>
                                        }

                                        <div style={{width: '15%'}} className={'color'}
                                             onClick={() => this.download(item.url)}>下载
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {this.renderBottomBtn()}
                <Modal
                    visible={this.state.viewPicture}
                    transparent
                    style={{width: '87vw'}}
                    maskClosable={false}
                    onClose={this.onClose('modal1')}
                    footer={[{
                        text: '确定', onPress: () => {
                            console.log('ok');
                            this.onClose('modal1')();
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}
                >
                    <div>
                        <img src={this.state.img} style={{width: '80vw', height: '80vw'}}/>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.remarks}
                    transparent
                    maskClosable={false}
                    onClose={() => {
                        this.setState({remarks: false})
                    }}
                    footer={[{
                        text: 'Ok', onPress: () => {
                            console.log('ok');
                            this.setState({remarks: false})
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}

                >
                    <div style={{padding:'10px',wordWrap:'break-word',maxHeight:'80px',lineHight:'15px'}}>
                        {this.state.remark === 'null' ? '' : this.state.remark}
                    </div>
                </Modal>
            </div>
        )
    }

    //渲染底部按钮
    renderBottomBtn() {
        let firstBtn = this.state.getAuthBtn[0];
        let firstName;
        switch (firstBtn) {
            case 3:
                firstName = '撤回';
                break;
            case 4:
                firstName = '留用';
                break;
            case 5:
                firstName = '弃用';
                break;
            case 6:
                firstName = '采用';
                break;
            case 7:
                firstName = '查看备注';
                break;
            case 8:
                firstName = '指派写作';
                break;
        }
        return (
            <div>
                {
                    this.state.getAuthBtn && this.state.getAuthBtn.length === 1 ?
                        <div onClick={() => this.btnClick(firstBtn)} style={{
                            lineHeight: '12vw',
                            backgroundColor: '#1890fe',
                            textAlign: 'center',
                            color: 'white',
                            position: 'fixed',
                            width: '100%',
                            bottom: '0'
                        }}>{firstName}
                        </div> : null
                }
                {
                    this.state.getAuthBtn && this.state.getAuthBtn.length > 1 ? <div style={{
                        display: 'flex',
                        lineHeight: '12vw',
                        textAlign: 'center',
                        color: 'white',
                        position: 'fixed',
                        width: '100%',
                        bottom: '0'
                    }}>
                        <div style={{width: '85%', backgroundColor: '#1890fe'}}
                             onClick={() => this.btnClick(firstBtn)}>{firstName}
                        </div>
                        <div style={{width: '15%', backgroundColor: '#1890fe', marginLeft: 5}}>
                            <Popover
                                placement={'topRight'}
                                visible={this.state.visible}
                                overlay={[
                                    <div className={'footer'} style={{
                                        width: '50vw',
                                        position: 'fixed',
                                        bottom: '50px',
                                        right: 10,
                                        background: 'white',
                                        zIndex: '2',
                                        boxShadow: ' 0vw 2vw 4vw 0vw rgba(124, 83, 201, 0.09)'
                                    }}>
                                        <style>{` .am-popover-arrow{display:none} 
                                             .am-popover-item-content{display: inline-block;width: 100%;text-align: center;}`}</style>
                                        {this.state.getAuthBtn.map((item, index) => {
                                            if (index === 0) {
                                                return;
                                            }
                                            let name, key;
                                            //根据返回的数字判断角色
                                            switch (item) {
                                                case 3:
                                                    name = '撤回';
                                                    key = '1';
                                                    break;
                                                case 6:
                                                    name = '采用';
                                                    key = '3';
                                                    break;
                                                case 4:
                                                    name = '留用';
                                                    key = '2';
                                                    break;
                                                case 5:
                                                    name = '弃用';
                                                    key = '6';
                                                    break;
                                                case 7:
                                                    name = '查看备注';
                                                    key = '4';
                                                    break;
                                                case 8:
                                                    name = '指派写作';
                                                    key = '5';
                                                    break;
                                            }
                                            return (<Item key={item} data-seed="logId">{name}</Item>)
                                        })}
                                    </div>
                                ]}
                                onSelect={this.onSelect}
                            >
                                <div style={{
                                    height: '100%',
                                    padding: '0 15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                >
                                    <Icon type="ellipsis"/>
                                </div>
                            </Popover>
                        </div>
                    </div> : null
                }
            </div>
        )
    }

    btnClick(i) {
        if (i === 3 || i === 4 || i === 6 || i === 5) {
            Actions.editStatus({id: this.state.id, tsStatus: i}, (data) => {
                this.getTopicDetails()
            });
        } else if (i === 7) {
            Actions.takeRemark({id: this.state.id}, (data) => {
                this.setState({remark: data.tsRemark, remarks: true})
            });
        } else if (i === 8) {
            window.location.hash = '/assignment/assignment/' + this.state.id
        }
        this.setState({visible: false})
    }

    //底部按钮的操作
    onSelect = (opt) => {
        if (opt.key === '3' || opt.key === '4' || opt.key === '6' || opt.key === '5' ) {
            Actions.editStatus({id: this.state.id, tsStatus: opt.key}, (data) => {
                this.getTopicDetails()
            });
        } else if (opt.key === '7') {
            Actions.takeRemark({id: this.state.id}, (data) => {
                this.setState({remark: data.tsRemark, remarks: true})
            });
        } else if (opt.key === '8') {
            window.location.hash = '/assignment/assignment/' + this.state.id
        }
        this.setState({visible: false})
    };

    //根据按钮获取按钮
    getAuth() {
        Actions.getAuth({id: this.state.id}, (data) => {
            this.setState({getAuthBtn: data});
        });
    }

    //获取附件
    getEnclosure() {
        let imgAccept = [...PIC_TYPE,].join(",");
        Actions.getEnclosure({attachmentIds: this.state.tsFileIds}, (data) => {
            data.forEach((item, index) => {
                let nameLength=item.name.split(".").length;
                if (imgAccept.indexOf(item.name.split(".")[nameLength-1]) > -1) {
                    item.type = 1
                }
            });
            this.setState({tsFileList: data});
        });
    }

    //下载
    download(data) {
        /*  console.log(data)
          let a = document.createElement('a');
          //需要下载的数据内容,我这里放的就是BLOB，如果你有下载链接就不需要了
          let url = data;
          console.log(url)
          let filename =url;
          a.href = url;
          a.download = filename;
          a.click();*/
        // window.URL.revokeObjectURL(url);
        window.location.href = data + "?download=1";
    }

    //查看图片
    watch(url) {
        this.setState({viewPicture: true, img: url})
    }

    //关闭查看图片的弹框
    onClose = key => () => {
        this.setState({viewPicture: false,});
    };

}