/**
 * created by lei lei
 * 下拉选择
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Button} from 'antd-mobile';

export default class DropDownList extends Component {
    static defaultProps = {
        title: '服务进展',
        list: [
            {title: '123213', id: 'dsadasd',},
            {title: '123213', id: 'dsadasd'},
            {title: '123213', id: 'dsadasd'},
        ],
        titleKey: 'title',
        idKey: 'title',
        selectClick: (e) => {
        }
    };
    static propTypes = {
        title: PropTypes.string.isRequired,//上面的大标题
        list: PropTypes.array.isRequired,//下拉数据必传项
        titleKey: PropTypes.string.isRequired,//下拉列表的展示文字的键值
        idKey: PropTypes.string.isRequired,//选自的标识值,
        selectClick: PropTypes.func.isRequired,//选中点击
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selected: ''
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div style={{position: "relative"}}>
                <Button style={{fontSize: '4vw'}} onClick={() => {
                    this.setState({visible: !this.state.visible})
                }}>{this.props.title}<Icon style={{position: 'absolute', top: 10}}
                                           type={this.state.visible ? 'up' : 'down'}/></Button>
                <div style={{
                    background: 'white',
                    position: 'absolute',
                    width: '100%',
                    display: this.state.visible ? 'block' : 'none',
                    zIndex:9999
                }}>
                    {this.props.list.map((item, index) => {
                        return (
                            <div style={{
                                width: '100%',
                                height: 44,
                                fontSize: '3vw',
                                color:'#999999',
                                lineHeight: '44px',
                                textAlign: 'center',
                                borderBottom: '1px solid #e5e5e5',
                            }} key={index} onClick={() => {
                                this.itemClick(item)
                            }}>{item[this.props.titleKey]}</div>
                        )
                    })}
                </div>

            </div>
        )
    }

    itemClick(item) {
        this.setState({visible: false});
        this.props.selectClick && this.props.selectClick(item[this.props.idKey])
    }

}