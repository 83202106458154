export const STORY_ID = "STORY_ID";//写稿的稿件id;

Object.defineProperty(Array.prototype, "toUpperCase", {
    value: function(){
        if(this){
            return this.map(item=>item.toUpperCase());
        }
    },
    enumberable : false
}); 

export const VIDEO_TYPE_ORIGINAL = ["avi","mp4","mov","mkv","rmvb"];//视频类型
export const VIDEO_TYPE = [...VIDEO_TYPE_ORIGINAL, ...VIDEO_TYPE_ORIGINAL.toUpperCase()];//视频类型
export const VIDEO_TYPE_STR = VIDEO_TYPE.join(",");//视频类型

export const AUDIO_TYPE_ORIGINAL =["mp3","mpeg","webm","wav","mid"];
export const AUDIO_TYPE = [...AUDIO_TYPE_ORIGINAL, ...AUDIO_TYPE_ORIGINAL.toUpperCase()];//音频类型
export const AUDIO_TYPE_STR = AUDIO_TYPE.join(",");//音频类型

export const ATTCHMENT_TYPE_ORIGINAL = ["doc","docx","ppt","pptx","xls","xlsx","psd","txt","rar","zip","pdf"];
export const ATTCHMENT_TYPE = [...ATTCHMENT_TYPE_ORIGINAL, ...ATTCHMENT_TYPE_ORIGINAL.toUpperCase()];//附件类型
export const ATTCHMENT_TYPE_STR = ATTCHMENT_TYPE.join(",");//附件类型

export const PIC_TYPE_ORIGINAL = ["jpg","jpeg","gif","png","bmp"];
export const PIC_TYPE = [...PIC_TYPE_ORIGINAL, ...PIC_TYPE_ORIGINAL.toUpperCase()];//图片类型
export const PIC_TYPE_STR = PIC_TYPE.join(",");//图片类型

export const FILE_TYPE = [...VIDEO_TYPE, ...AUDIO_TYPE, ...PIC_TYPE, ...ATTCHMENT_TYPE];//所有文件类型
export const FILE_TYPE_STR = FILE_TYPE.join(",");//所有文件类型
