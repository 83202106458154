import React, {Component} from "react";
import {StickyContainer, Sticky} from 'react-sticky';
import {ListView, List, Checkbox} from 'antd-mobile';
import {uuid} from "../../utils";

const {Item} = List;
const CheckboxItem = Checkbox.CheckboxItem;

class SelectListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: this.props.dataSource ? this.props.dataSource : [],
            defaultCheckedDatas: this.props.defaultCheckedDatas ? this.props.defaultCheckedDatas : [],
            selectIds: [],
        };
    }

    componentWillMount(){
        let selectIds = this.state.defaultCheckedDatas.map((item) => item.id);
        this.setState({
            selectIds:selectIds
        });
    }


    onChange = (val) => {
        let index = this.state.selectIds.indexOf(val.id);
        if (index >= 0) {
            this.state.selectIds.splice(index, 1);
            this.state.defaultCheckedDatas.splice(index, 1);
        } else {
            this.state.selectIds.push(val.id);
            this.state.defaultCheckedDatas.push(val);
        }
        this.props.getCheckedData(this.state.defaultCheckedDatas);
    }


    componentWillUnmount() {
        this.state.selectIds = [];
    }

    render() {
        let selectIds = this.state.selectIds ? this.state.selectIds : [];
        const row = (rowData) => {
            return <div key={uuid()} style={{borderBottom: '1px solid #f0f0f0'}}>
                <CheckboxItem key={rowData.id} defaultChecked={selectIds.includes(rowData.id)}
                              onChange={() => this.onChange(rowData)}><span style={{fontSize:'14px'}}>  {rowData.name}</span>

                </CheckboxItem>

            </div>

        };

        const dataList = this.state.dataSource.map((item) => {
            return row(item)
        });

        return <div>
            {dataList}
        </div>;
    }
}

export default SelectListView;
