import React, {Component} from 'react'
import {Card, Icon, InputItem, List, Modal, NavBar, TextareaItem, Toast, WhiteSpace} from "antd-mobile/lib/index";
import {formatDate, uuid} from "../../../utils";
import {
    backAppointment, editAppointment,
    getAppointmentInfo,
    getAppointmentInfoPms, getAppointmentReceiverByReceiverId, getAppointmentReceiverTableInfoById,
    getInfoFromLocalStorage,
    getInterviewInfoById, recivewAppointMentTask, refuseAppointMentTask
} from "../../../action";
import WingBlank from "antd-mobile/es/wing-blank/index";
import ManuscriptCardForm from "../../../components/ManuscriptCard";
import InterviewCardForm from "../../../components/InterviewCard";
import ManuscriptDetailCardForm from "../../../components/ManuscriptDetailCard";
import Button from "antd-mobile/es/button";
import {createForm} from "rc-form";
import {setNoRefresh, setStoryAttribute} from "../../../store/actions";
import {
    setAddStory, setCurrentTempStoryId,
    setHasShowHistoryStory,
    setStory,
    setStoryId,
    setStoryResource
} from "../../../store/actions/storyAction";
import {connect} from "react-redux";
import './manuscript_Detail_Page.css';

/**
 * 约稿页面详情页面
 */
class ManuscriptDetailPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            appointmentInfo: {},
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            id: this.props.match.params.id,
            receiverId: this.props.match.params.receiverId,
            receiveData: {},
            toShowRemark: false,
            storyId: 0,
            receiverTable: [],
            canCancleData: false,
            canEdite: false
        }
    }

    goBack() {
        var goBack = getInfoFromLocalStorage('goBack');
        this.props.history.goBack();
        // var url = "/manuscriptPage/manuscriptPage/" + this.state.isContributeRoleFlag + '/' + goBack;
        // this.state.history.push(url);
    }

    componentWillMount() {
        let param = {'appointmentid': this.state.id};
        if (this.state.isContributeRoleFlag) {
            getAppointmentInfo(param, this.getAppointmentInfoBack.bind(this))
        } else {
            let paramRecive = {'appointmentId': this.state.id, "page": 1, 'limit': 10};
            getAppointmentReceiverTableInfoById(paramRecive, this.getAppointmentReceiverTableIBack.bind(this));
        }

        //进入写稿页面，需要刷新页面
        this.props.setNoRefresh(false);
        this.props.setStory({});
        this.props.setStoryId(0);
        this.props.setStoryAttribute({});
        this.props.setStoryResource({picture: [], video: [], audio: []});
        this.props.setAddStory(false);
        this.props.setHasShowHistoryStory(true);
        this.props.setCurrentTempStoryId(0);
    }

    /**
     * 回显接受任务者的信息；
     * @param data
     */
    getAppointmentReceiverTableIBack(data) {
        if (!data) {
            return;
        }
        let reciveData = data.data
        this.setState({
            receiverTable: reciveData
        })
        let canCancleData = true;
        for (var i = 0; i < reciveData.length; i++) {
            if (reciveData[i].status !== 1) {
                canCancleData = false;
                break;
            }
        }

        this.setState({
            receiverTable: reciveData,
            canCancleData: canCancleData
        })
        let paramAppoint = {'appointmentid': this.state.id};
        getAppointmentInfoPms(paramAppoint, this.getAppointmentInfoPmsBack.bind(this))
    }

    //获取pms端的数据回填
    getAppointmentInfoPmsBack(data) {
        if (data) {
            let canEdit = false;
            if (data.status === 4) {
                canEdit = true;
            }
            this.setState({
                appointmentInfo: data,
                canEdite: canEdit
            })
        }
    }

    //contribute端的数据回填
    getAppointmentInfoBack(data) {
        if (data) {
            this.setState({
                appointmentInfo: data
            })
        }
        let param = new FormData();
        param.append('appointmentReceiverId', this.state.receiverId);
        getAppointmentReceiverByReceiverId(param, this.appointmentReceiverBack.bind(this));
    }

    /**约稿接受人 回调
     *
     * @param data
     */
    appointmentReceiverBack(data) {
        if (data) {
            this.setState({
                receiveData: data
            })
        }
    }

    updateInterViewInfo(data) {
        if (!data) {
            Toast.fail("数据异常");
        }
        this.setState({
            interviewInfo: data
        })
    }

//提交数据
    submitData() {
        this.child.submitData();
    }

    /**
     * 投稿端，采用，和 未用 信息
     */
    useAndUnuseInfo(data) {

        if (this.state.isContributeRoleFlag) {
            if (data.status === 6) {
                return <div>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        type={'text'}
                        value={formatDate(data.refuseDate.time)}
                        style={{color: 'blue', fontSize: '15px'}}
                    ><div style={{color: 'blue', fontSize: '15px'}}>拒绝时间:</div></InputItem>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        type={'text'}
                        style={{color: 'blue', fontSize: '15px'}}
                        value={data.refuseReason}
                    ><div style={{color: 'blue', fontSize: '15px'}}>拒绝原因:</div></InputItem>
                </div>

            } else if (data.status === 2) {
                return <div>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        type={'text'}
                        value={formatDate(data.receiveDate.time)}
                        style={{ fontSize: '15px'}}
                    ><span style={{fontSize: '15px'}}>领取时间:</span></InputItem>
                </div>
            }


        } else {
            return "";
        }

    }

    /**
     * 写稿
     */
    writeStory() {
        var processDefinitionName = "activity_appointment";
        let path = '/appointment/writeStory/' + this.state.storyId + '/' + processDefinitionName + '/' + this.state.receiverId
            + '/' + this.state.id + '/4'+'/3';
        this.state.history.push(path);
    }

    /**
     * 撤回
     */
    recallBack() {
        let param = new FormData();
        param.append('appointmentId', this.state.id);
        backAppointment(param, this.backAppointmentBack.bind(this));
    }

    backAppointmentBack(data) {
        if (data) {
            Toast.success("撤回成功");
            this.goBack();
        }
    }

    /**
     * 编辑不发布 约稿
     */
    publishAppoint() {
        this.child.submitData();
    }

    /**
     * 领取约稿任务；
     */
    recivewTask() {
        let param = new FormData();
        param.append("appointmentId", this.state.id);
        recivewAppointMentTask(param, this.recivewAppointBack.bind(this));
    }

    /**
     * 领取约稿任务回调
     * @param data
     */
    recivewAppointBack(data) {
        if (data) {
            Toast.success('领取成功！');
            this.componentWillMount()
            // this.goBack();
        } else {
            Toast.fail('领取失败！');
            this.goBack();
        }
    }

    /**
     * 拒绝 约稿任务
     */
    refuseTask(refuseReason) {
        let param = new FormData();
        param.append("appointmentReceiverId", this.state.receiverId);
        param.append("refuseReason", refuseReason);
        refuseAppointMentTask(param, this.refuseAppointMentTaskBack.bind(this));
    }

    /**
     * 拒绝 回调
     * @param data
     */
    refuseAppointMentTaskBack(data) {
        if (data) {
            Toast.success('约稿已拒绝！');
            this.setState({
                toShowRemark: false,
            })
            this.goBack();
        }
    }

    //取消按钮
    useCancelButton() {
        this.setState({
            toShowRemark: false,
        })
    }

    /**
     * 确定按钮
     */
    useOkButton() {
        let refuseReason = this.props.form.getFieldValue('refuseReason');
        this.refuseTask(refuseReason);
    }

    /**
     *打开拒绝弹出框
     */
    openRefuseDialog() {
        this.setState({
            toShowRemark: true
        })

    }

    /**
     * 加载操作按钮
     * @param data
     */
    loadButtons(data) {
        if (!data) {
            return;
        }
        let buttonS = [];
        let unseButton = <Button type="primary" inline   key={uuid()}
                                 style={{margin: '4px',width:'47%', backgroundColor: '#BEDCF5'}} onClick={() => {
            this.openRefuseDialog()
        }}>拒绝</Button>;

        let useButton = <Button type="primary" inline  key={uuid()}
                                style={{margin: '4px',width:'47%' }} onClick={() => {
            this.recivewTask()
        }}>领取</Button>;

        let recallButton = <Button type="primary"   key={uuid()}
                                   style={{margin: '4px'}} onClick={() => {
            this.recallBack()
        }}>撤回</Button>;

        let writeStoryButton = <Button type="primary"  key={uuid()}
                                       style={{margin: '4px'}} onClick={() => {
            this.viewStory(data)
        }}>写稿</Button>;

        let publishButton = <Button type="primary"   key={uuid()}
                                    style={{margin: '4px'}} onClick={() => {
            this.publishAppoint()
        }}>发布</Button>;

        var vivewButton = <Button type={'primary'} key={uuid()} onClick={() => {
            this.viewStory(data)
        }}>查看稿件</Button>

        if (this.state.isContributeRoleFlag) {
            console.info('data.status ===++++++++++++ 1', data)
            if (data.status === 1) {
                buttonS.push(useButton);
                buttonS.push(unseButton);
            } else if (data.status === 2) {
                buttonS.push(writeStoryButton);
            } else if (data.status === 3) {
                buttonS.push(vivewButton);
            }
        } else {
            if (data.status === 1) {
                if (this.state.canCancleData) {
                    buttonS.push(recallButton);
                }
            } else if (data.status === 4) {
                buttonS.push(publishButton);

            }
        }
        return buttonS;
    }

    /**
     * 查看稿件
     */
    viewStory(revicerInfo) {
      let storyId=  (revicerInfo && revicerInfo.storyId) || 0;
        var processDefinitionName = "activity_appointment";
        let path = '/appointment/writeStory/' + storyId + '/' + processDefinitionName + '/' + this.state.receiverId
            + '/' + this.state.id + '/4'+'/3';
        this.state.history.push(path);
    }

    /**
     * 加载约稿接受者的状态；
     */
    recivewersAppointInfo(reciversInfo) {
        if (!this.state.appointmentInfo.id || this.state.appointmentInfo.status === 4) {
            return "";
        }
        if (this.state.isContributeRoleFlag) {
            return "";
        }
        console.info('reciversInfo', reciversInfo);
        if (!reciversInfo || reciversInfo.length === 0) {
            return "";
        }
        var reciverInfoViews = [];

       /* for (var i = 0; i < reciversInfo.length; i++) {*/
        return (
        reciversInfo.length&&reciversInfo.map((item,index)=>{
            var revicerInfo = {};
            var reciverInfoView = "";
            revicerInfo = item;
            if (revicerInfo.status === 6) {
               return( <div key={uuid()}>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        type={'text'}
                        value='拒回稿:'
                        style={{fontSize: '15px', marginLeft: '30px'}}
                    ></InputItem>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        style={{fontSize: '15px', marginLeft: '30px'}}
                        type={'text'}
                        value={revicerInfo.userName + '(' + revicerInfo.userDept + ')'}
                    ></InputItem>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        style={{fontSize: '15px', marginLeft: '30px'}}
                        type={'text'}
                        value={'原因：' + revicerInfo.refuseReason}
                    ></InputItem>
                </div>)
            } else if (revicerInfo.status === 2 || revicerInfo.status === 1 || revicerInfo.status === 0) {
                let statusName = "";
                if (revicerInfo.status === 2) {
                    statusName = '已领取';
                } else if (revicerInfo.status === 0) {
                    statusName = '暂存';
                } else if (revicerInfo.status === 1) {
                    statusName = '未领取';
                }
                return( <div key={uuid()}>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        style={{fontSize: '15px', marginLeft: '30px'}}
                        type={'text'}
                        value={statusName}
                    ></InputItem>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        style={{fontSize: '15px', marginLeft: '30px'}}
                        type={'text'}
                        value={revicerInfo.userName + '(' + revicerInfo.userDept + ')'}
                    ></InputItem>
                </div>)

            } else if (revicerInfo.status === 3 || revicerInfo.status === 4 || revicerInfo.status === 5) {
                let statusName = "";
                if (revicerInfo.status === 3) {
                    statusName = '已回稿';
                } else if (revicerInfo.status === 4) {
                    statusName = '已采用';
                } else if (revicerInfo.status === 5) {
                    statusName = '已弃用';
                }
                return( <div key={uuid()}>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        style={{fontSize: '15px', marginLeft: '30px'}}
                        type={'text'}
                        value={statusName}
                    ></InputItem>
                    <InputItem
                        clear
                        moneyKeyboardAlign="left"
                        style={{fontSize: '15px', marginLeft: '30px'}}
                        type={'text'}
                        value={revicerInfo.userName + '(' + revicerInfo.userDept + ')'}
                    ></InputItem>
                    <Button type={'primary'} onClick={() => {
                       // let storyId=revicerInfo.storyId;
                        this.viewStory(revicerInfo)
                    }}>查看稿件</Button>
                </div>)
            }
            /*reciverInfoViews.push(reciverInfoView);*/
        })
        )
        /* return reciverInfoViews;*/
    }

    render() {
        var buttonData;
        if (this.state.isContributeRoleFlag) {
            buttonData = this.state.receiveData;
        } else {
            buttonData = this.state.appointmentInfo;
        }
        const {getFieldProps} = this.props.form;
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'}  key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}
            ><span  className={'nav_title'}>约稿</span></NavBar>
            <WingBlank style={{margin:'5px'}}>
                <Modal
                    visible={this.state.toShowRemark}
                    transparent
                    title={'拒绝原因'}
                    maskClosable={false}
                    // title="备注"
                    footer={[{
                        text: '确定', onPress: () => {
                            this.useOkButton()
                        }
                    }, {
                        text: '取消', onPress: () => {
                            this.useCancelButton()
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}
                >
                    <div style={{height: '150px'}}>
                        <div>{this.state.title}</div>
                        <div style={{height: '120px', overflow: 'auto',}}>
                            <TextareaItem style={{fontSize: '15px'}}
                                          {...getFieldProps('refuseReason')}
                                          placeholder="请填写拒绝原因"
                                          data-seed="logId"
                                          autoHeight
                                          rows={2}
                            />
                        </div>
                    </div>
                </Modal>
                <WhiteSpace size="lg"/>
                {this.loadButtons(buttonData)}
                <WhiteSpace size="lg"/>
                {this.recivewersAppointInfo(this.state.receiverTable)}
                {this.useAndUnuseInfo(this.state.receiveData)}
                <WhiteSpace size="lg"/>
                {this.state.isContributeRoleFlag ? <ManuscriptDetailCardForm onRef={(child) => {
                        this.child = child
                    }} appointmentInfo={this.state.appointmentInfo}
                                                                             goBack={this.goBack.bind(this)}></ManuscriptDetailCardForm> :
                    (this.state.appointmentInfo.id ? <ManuscriptCardForm onRef={(child) => {
                            this.child = child
                        }} appointmentInfo={this.state.appointmentInfo}
                                                                         canEdite={this.state.canEdite}
                                                                         goBack={this.goBack.bind(this)}></ManuscriptCardForm> :
                        <div></div>)}
            </WingBlank>
        </div>
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setNoRefresh: function (noRefresh) {
            dispatch(setNoRefresh(noRefresh));
        },
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttribute) {
            dispatch(setStoryAttribute(storyAttribute));
        },
        setStoryResource: function (resource) {
            dispatch(setStoryResource(resource));
        },
        setAddStory: function (addStory) {
            dispatch(setAddStory(addStory));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setHasShowHistoryStory(hasShowHistoryStory) {
            dispatch(setHasShowHistoryStory(hasShowHistoryStory));
        },
        setCurrentTempStoryId: function (tempStoryId) {
            dispatch(setCurrentTempStoryId(tempStoryId));
        }
    }
}

const ManuscriptDetailPages = createForm()(ManuscriptDetailPage);
export default connect(null, dispatchToProps)(ManuscriptDetailPages);
