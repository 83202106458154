import {baseUrl, get, jpost,post,jsonPost} from './index';

/**
 * 加载 story info
 * @param storyId
 * @param callBack
 */
export  const getStoryById=(params,callBack)=>{
    get(baseUrl+"/cm-sites-app-h5/story/getStoryById",params,callBack);
}



/**
 * 加载 editor config
 * @param param
 * @param callBack
 */
export const loadEditorConfig =(param, callBack) =>{
    post(baseUrl + "/cm-sites-app-h5/ueditor/control?action=config", param, callBack);
}

/**
 * 加载 保存sotry
 * @param storyId
 * @param callBack
 */
export const saveStoryData=(params,callBack)=>{
    ///cm-sites-app-h5/story/save
    get("data/myTabs.Data.json",params,callBack);
}


export const getTempStoryData =(params,callBack)=>{
    post(baseUrl+"/cm-sites-app-h5/story/getUserTempStorys",params,callBack);
}

export const checkUserTempStory =(params,callBack)=>{
    post(baseUrl+"/cm-sites-app-h5/story/checkUserTempStory",params,callBack);
}

export const deleteUserTempStory =(params,callBack)=>{
    post(baseUrl+"/cm-sites-app-h5/story/clearUserAllTempStory",params,callBack);
}

export const autoSaveTempStory =(params,callBack)=>{
    jsonPost(baseUrl+"/cm-sites-app-h5/story/autoSave",params,callBack);
}

export const loadFormat =(params,callBack)=>{
    jpost(baseUrl+"/cm-sites-app-h5/story/format",params,callBack);
}