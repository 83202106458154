import {SELECTED_TAB} from "../actions/action-type/action-types";

let initState =
    {
        selectedTab: 'home'
    };

export default function navigateReducer(state = initState, action) {

    switch (action.type) {
        case SELECTED_TAB :
            return {
                ...state,
                selectedTab: action.selectedTab,
            }
            break;
        default:
            return state;
    }
}
