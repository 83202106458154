import React from "react";
import WriteStoryNew from "../containers/storyPage/WriteStoryNew";
import StoryAtrributeS from "../containers/storyPage/StoryAtrribute";
import AssistAudits from "../containers/storyPage/AssistAudit";
import ChoseEditor from "../containers/storyPage/ChoseEditor";
import MediaSelect from "../containers/storyPage/MediaSelect";
import ListTabPage from "../containers/storyPage/ListTabPage";
import LoginViews from "../containers/loginPage/LoginView";
import NavigationPage from "../containers/navigatorPage/NavigationPage";
import PrivateLetterSet from "../containers/messagePage/PrivateLetterSettPage";
import MessageDetailList from "../containers/messagePage/MessageDetailList";
import MessageDetailView from "../containers/messagePage/MessageDetailView";
import AddInterviewPage from "../containers/businessPage/interviewTask/AddInterviewPage";
import AddNewCluePage from "../containers/businessPage/newsClue/AddNewCluePage";
import KnockOut from "../containers/KnockOut";
import InterviewTask from "../containers/businessPage/interviewTask/InterviewTask";
import ManuscriptPage from "../containers/businessPage/manuscript/ManuscriptPage";
import NewsCluePage from "../containers/businessPage/newsClue/NewsCluePage";
import AddManuscriptPage from "../containers/businessPage/manuscript/AddManuscriptPage";
import InterviewDetailPage from "../containers/businessPage/interviewTask/InterviewDetailPage";
import RedirectBuinessPage from "../containers/navigatorPage/RedirectBuinessPage";
import NewClueDetailPages from "../containers/businessPage/newsClue/NewClueDetailPage";
import ManuscriptDetailPages from "../containers/businessPage/manuscript/ManuscriptDetailPage";
import LinkToStoryPage from "../containers/navigatorPage/LinkToStoryPage";
import AuditStoryTabs from "../components/AuditStoryTabs";
import CasLogin from "../containers/loginPage/CasLogin";
import PreviewAge from "../components/PreviewAge";
import ColumnSelectPage from "../containers/storyPage/ColumnSelectPage";
import {TestDemoRouter} from'./TestDemoRouter'
import ResoucePoolPage from "../containers/storyPage/ResoucePoolPage";
import OriginalResourcePage from "../containers/storyPage/OriginalResourcePage";
import IntramuralMediaPage from "../containers/businessPage/intramuralMediaBooking/IntramuralMediaPage";
import OffCampusMediaPage from "../containers/businessPage/offCampusMediaBooking/OffCampusMediaPage";
import AddintramuralMediaPage from "../containers/businessPage/intramuralMediaBooking/AddintramuralMediaPage";
import AddoffCampusMediaPage from "../containers/businessPage/offCampusMediaBooking/AddoffCampusMediaPage";
 import TaskListItem from '../components/taskListItem/TaskListItem';
import TaskManagement from '../containers/taskManagement/TaskManagement';
import ManuscriptList from '../components/manuscriptList/ManuscriptList';
import SubjectSubmit from '../components/subjectSubmit/subjectSubmit';
import Contributions from '../containers/contributions/Contributions';
import TaskDetails from '../containers/taskManagement/TaskDetails';
import TopicList from '../containers/topicList/TopicList';
import TopicDetails from '../containers/topicList/TopicDetails'
import AddTopicList from '../containers/topicList/AddTopicList';
import Assignment from '../containers/topicList/Assignment';
import News from '../containers/news/News';
import Mine from '../containers/mine/mine'
//路由配置型信息
const Routes = [
    ...TestDemoRouter,

    {
        path: "/mediaSelect/:userName",
        component: MediaSelect
    }, {
        path: "/redirectBuinessPage/:type/:userAccount/:isContributeFlag",
        component: RedirectBuinessPage
    },
    {
        path: "/index/:selectedTab",
        component: NavigationPage
    },
    {
        path: "/login/login",
        component: LoginViews
    },
    {
        path: "/listTabPage/listTabPage",
        component: ListTabPage
    },
    {
        path: "/writeStory/:id/:processInstanceId/:actButtonId/:fromPage", //稿件的写稿页面   fromPage  {1：稿件列表 过去的，2：采访页面过去的，3：约稿页面过去的,4:资源池过去}
        component: WriteStoryNew
    },
    {
        path: "/linkToStoryPage/linkToStoryPage", //稿件的写稿页面
        component: LinkToStoryPage
    },
    {
        path: "/interview/writeStory/:id/:processInstanceId/:interviewId/:actButtonId/:fromPage",//采访任务的 写稿页面
        component: WriteStoryNew
    },
    {
        path: "/appointment/writeStory/:id/:processDefinitionName/:appointmentReceiverId/:appointmentId/:actButtonId/:fromPage",  //约稿的写稿页面
        component: WriteStoryNew
    }
    , {
        path: "/storyAtrribute/storyAtrribute",
        component: StoryAtrributeS
    }, {
        path: "/assistAudits/assistAudits",
        component: AssistAudits
    }, {
        path: "/choseEditor/choseEditor",
        component: ChoseEditor
    }, {
        path: "/privateLetterSet/privateLetterSet",
        component: PrivateLetterSet
    }, {
        path: "/messageDetailList/:messageType",
        component: MessageDetailList
    }, {
        path: "/messageDetailView/:messageId/:messageType",
        component: MessageDetailView
    }, {
        path: "/interviewPage/interviewPage/:interviewId",
        component: AddInterviewPage
    }, {
        path: "/newCluePage/:newclueId",
        component: AddNewCluePage
    }, {
        path: "/knockOut/knockOut",
        component: KnockOut
    }, {
        path: "/interviewTask/interviewTask/:isContributeRoleFlag/:goBack",  //采访任务列表页面
        component: InterviewTask
    }, {
        path: "/newsTask/newsTask",  //新闻任务列表页面
        component: InterviewTask
    }, {
        path: "/manuscriptPage/manuscriptPage/:isContributeRoleFlag/:goBack",   //约稿列表页面
        component: ManuscriptPage
    }, {
        path: "/newsCluePage/newsCluePage/:isContributeRoleFlag/:goBack",   //线索列表页面
        component: NewsCluePage
    }, {
        path: "/addInterviewPage/addInterviewPage/:newClueId",   //新增采访任务
        component: AddInterviewPage
    }, {
        path: "/addNewCluePage/addNewCluePage",   //新增线索页面
        component: AddNewCluePage
    }, {
        path: "/addManuscriptPage/addManuscriptPage",   //新增约稿
        component: AddManuscriptPage
    }, {
        path: "/manuscriptDetailPage/manuscriptDetailPage/:id/:receiverId",   //查看约稿
        component: ManuscriptDetailPages
    }, {
        path: "/InterviewDetailPage/InterviewDetailPage/:interviewId",   //采访任务详情页面
        component: InterviewDetailPage
    }, {
        path: "/newClueDetailPage/newClueDetailPage/:id",   //采访任务详情页面
        component: NewClueDetailPages
    }, {
        path: "/auditStoryTabs/auditStoryTabs",
        component: AuditStoryTabs
    }, {
        path: "/casLogin/casLogin",
        component: CasLogin
    },{
        path: "/previewAge/previewAge",
        component: PreviewAge
    },{
        path: "/columnSelectPage/columnSelectPage/:storyId",
        component: ColumnSelectPage
    },{
        path: "/resoucePoolPage/resoucePoolPage",
        component: ResoucePoolPage
    },{
        path: "/originalResourcePage/originalResourcePage",
        component: OriginalResourcePage
    },{
        path: "/intramuralMediaPage/intramuralMediaPage",  //校内媒体首页
        component: IntramuralMediaPage
    },{
        path: "/addintramuralMediaPage/addintramuralMediaPage",  //校内媒体新增页面
        component: AddintramuralMediaPage
    },{
        path: "/addintramuralMediaPage/addintramuralMediaPage/:id",  //校内媒体编辑页面
        component: AddintramuralMediaPage
    },{
        path: "/offCampusMediaPage/offCampusMediaPage",  //校外媒体预约
        component: OffCampusMediaPage
    },{
        path: "/addoffCampusMediaPage/addoffCampusMediaPage",  //校外媒体预约新增页面
        component: AddoffCampusMediaPage
    },{
        path: "/addoffCampusMediaPage/addoffCampusMediaPage/:id",  //校外媒体预约编辑查看页面
        component: AddoffCampusMediaPage
    },
    {
        path: "/taskListItem/taskListItem",  // 任务列表
        component: TaskListItem
    },
    {
        path: "/taskManagement/taskManagement",  // 任务列表
        component: TaskManagement
    },{
        path: "/manuscriptList/manuscriptList", //稿件列表组件
        component: ManuscriptList
    },{
        path: "/contributions/contributions/:id/:status", //稿件列表页面
        component: Contributions
    },{
        path: "/taskDetails/taskDetails/:storyId", //任务列表详情
        component: TaskDetails
    },{
        path: "/topicList/topicList", //选题列表
        component: TopicList
    },{
        path: "/addTopicList/addTopicList/:id", //添加选题列表
        component: AddTopicList
    },
    {
        path: "/topicDetails/topicDetails/:storyId", //选题列表详情
        component: TopicDetails
    },
    {
        path: "/news/news",//消息
        component:News
    },
    {
        path: "/mine/mine",//消息
        component:Mine
    },
    {
        path: '/assignment/assignment/:id', //指派写作
        component: Assignment
    },
    {
        path: '/subjectSubmit/subjectSubmit', //
        component: SubjectSubmit
    },
    {
        path: "/taskInterview/writeStory/:id/:interviewId/:fromPage",//任务写稿页面 id 稿件id 新增 为0，interviewId 任务id
        component: WriteStoryNew
    },

];
export default Routes;
