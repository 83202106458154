import React, {Component} from 'react';
import MediaSelect from "../storyPage/MediaSelect";

/**
 * 稿件导航页
 */
class StoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: this.props.userName,
        };
    }

    render() {
        return <MediaSelect userName={this.props.userName} isContributeRoleFlag={this.props.isContributeRoleFlag} pageLogin={true}></MediaSelect>
    }
}

export default StoryPage;
