import  React,{Component} from 'react'
import {Icon, List, NavBar, Picker,TextareaItem } from 'antd-mobile';
import {Link} from "react-router-dom";
import {createForm} from "rc-form";

class AssistAudit extends  Component{

    district=[
        {
            value: 'zj-nb',
            label: '宁波',
        }, {
            value: 'zj-hz',
            label: '杭州',
        }
    ]
    constructor(props){
        super(props);
        this.state={
            pageName:"辅助审核",
            district:this.district
        }
    }

    /**
     * 返回按钮事件
     */
    goBack(){
        this.state.history().goBack();
    }

    render() {
        const { getFieldProps } = this.props.form;
        return <div>
            <NavBar
                mode="dark"
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                onLeftClick={() => this.goBack()}
                rightContent={[
                    <Link to={"/writeStory"}><div onClick={()=>{}}>确定</div></Link>
                ]}
            >{this.state.pageName}</NavBar>
            <p>选择辅助审核部门并填写审核说明</p>
            <Picker data={this.district} cols={1} {...getFieldProps('district3')} className="forss">
                <List.Item arrow="horizontal">部门</List.Item>
            </Picker>
            <TextareaItem
                title="标题"
                placeholder="click the button below to focus"
                data-seed="logId"
                autoHeight
                ref={el => this.customFocusInst = el}
            />
        </div>
    }
}

const AssistAudits = createForm()(AssistAudit);
export  default AssistAudits;