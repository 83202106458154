/**
 * created by yxy
 *
 */
import { request } from '../utils';
let host = '/cm-sites-app-h5/';

const Apis = {
    handleBymeForPms: host + 'mobileAgency/handleBymeForPms',//待我处理
    finishBymeForPms: host + 'mobileAgency/finishBymeForPms',//我已处理
    handleBymeForContribute:host+ 'mobileAgency/handleBymeForContribute',//待我处理投稿端
    finishBymeForContribute:host+ 'mobileAgency/finishBymeForContribute',//待我处理投稿端
};
export const handleBymeForPms = (params,callBack) => {  //待我处理
    request(Apis.handleBymeForPms,'get',params, callBack)
};
export const finishBymeForPms = (params,callBack) => {  //我已处理
    request(Apis.finishBymeForPms,'get',params, callBack)
};
export const handleBymeForContribute = (params,callBack) => {  //待我处理
    request(Apis.handleBymeForContribute,'get',params, callBack)
};
export const finishBymeForContribute = (params,callBack) => {  //我已处理
    request(Apis.finishBymeForContribute,'get',params, callBack)
};

