import {
    STORY_ID,
    STORY,
    STORY_CONTENT,
    STORY_TITLE,
    STORY_STATUS,
	STORY_FORMAT,
    ADD_RESOURCE,
    SET_RESOURCE,
    AUDIT_OPINIONS,
    ADD_STORY,
    ADD_TEMP_STORY_ID,
    HAS_SHOW_HISTORY_STORY,
    STORY_CONTENT_CHANGED,
    STORY_LINKURL
} from "./action-type/action-types";
//设置稿件id
export const setStoryId = function (storyId) {
    return {type: STORY_ID, storyId};
};
//设置稿件 标题
export const setTitle = function (title) {
    return {type: STORY_TITLE, title};
};
//设置 稿件一键排版
export const setFormat = function (format) {
    return {type: STORY_FORMAT, format};
};
//设置 稿件内容
export const setContent = function (content) {
    return {type: STORY_CONTENT, content};
};
//设置 稿件
export const setStory = function (story) {
    return {type: STORY, story};
};

export const setStatus = function (status) {
    return {type: STORY_STATUS, status};
};

export const addStoryResource =function(resource,type){
    return {type: ADD_RESOURCE, resource,resourceType:type};
}

export const setStoryResource =function(resource){
    return {type: SET_RESOURCE, resource};
}

export  const  setAuditOpinions =function (auditOpinions) {
    return {type:AUDIT_OPINIONS,auditOpinions};
}

export  const  setAddStory =function (addStory) {
    return {type:ADD_STORY,addStory};
}

export  const  setCurrentTempStoryId =function (tempStoryId) {
    return {type:ADD_TEMP_STORY_ID,tempStoryId};
}

export  const  setHasShowHistoryStory =function (hasShowHistoryStory) {
    return {type:HAS_SHOW_HISTORY_STORY,hasShowHistoryStory};
}
export  const  setStoryChanged =function (storyChanged) {
    return {type:STORY_CONTENT_CHANGED,storyChanged};
}

export  const  setLinkUrl =function (linkurl) {
    return {type:STORY_LINKURL,linkurl};
}
