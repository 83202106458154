import $ from 'jquery';

var optionsCopy = {}
var activiti;
var layer;
var layui;

var defaultOptions = {
    id: "#xxx", //渲染选择器
    uuid:"",   //主键id
    userAccount: "",
    againFlag: false,
    activitiConf: {    //流程查询配置
        treeContentId: "",
        activitiTypeEnum: "",
        mediaCode: "",
    },
    showBtnFlag:true,
    showAfterFuns: {   //按钮加载前置js
        callBackBtnShow : function(htmlTemp, call){
            call({code:0,msg:"未重写callBackBtnShow（撤回按钮显示js）"});
        }
    },
    success : function(activiti){   //对象创建成功回调
    },
    isStart : false,
    otherJs : {     //前置js 方法集合

    },
    closeServiceTemp:[],
    againFunction :function (call){
        call();
    }
}

function Activiti(options){
    //初始化配置
    this.options = options;
    //将初始化配置存储 以待后续使用
    optionsCopy = Activiti.util.deepClone(this.options);
    this.buttonList = []
    this.actForm = { //表单信息
        values:{},
        users:{}
    }
    this.actTask = {

    }
    this.activitiData = {//流程信息
        deploymentKey : "",
    }
}

var urlConf = {
    loadButtonListUrl : "/cm-sites-activiti/activiti/getButton",
    actSubmitUrl : "/cm-sites-activiti/activiti/submitForm",
    actButtonConfig : "/cm-sites-activiti/activitiButtonConfig/getActButtonConfig",
    actStartFromUrl : "/cm-sites-activiti/activiti/getStartFrom",
    actFromUrl : "/cm-sites-activiti/activiti/getFormUrlById",
    actGetTaskIdUrl : "/cm-sites-activiti/activitiAdmin/getTaskIdByProcessInstanceId",
    actTodoListUrl : "/cm-sites-activiti/activiti/getNeedProcessed",
    actTodoAllListUrl : "/cm-sites-activiti/activiti/getNeedProcessedAll",
    getActServiceDataByIdUrl : "/cm-sites-activiti/activiti/getActServiceDataByIdUrl",
    actExaminedUrl : "/cm-sites-activiti/activiti/examined",
    actHistoryUrl : "/cm-sites-activiti/activiti/getActHistory",
    actTaskFormUrl : "/cm-sites-activiti/activiti/getTaskForm",
    terminateUrl : "/cm-sites-activiti/activitiAdmin/terminateByProcessInstanceId",
    dropProcessInstanceUrl : "/cm-sites-activiti/activitiAdmin/dropProcessInstance",
    getActserviceDataByUuidUrl : "/cm-sites-activiti/activiti/getActserviceDataByUuid",
    serverUrl : "/cm-sites-activiti/activitiPublic/",
    allGroupUrl : "/cm-sites-activiti/activitiPublic/getAllGroup",
    allRole : "/cm-sites-activiti/activitiPublic/getAllRole",
    getUsers : "/cm-sites-activiti/activitiPublic/getUsers",
}

/**
 *
 * @param options
 id: "#xxx", //渲染选择器
 uuid:"",   //主键id
 activitiConf: {    //流程查询配置
                treeContentId: "",
                activitiTypeEnum: "",
                mediaCode: "",
            },
 showAfterFuns: {   //按钮加载前置js

        }
 * @param call
 * @returns {{}}
 */
Activiti.createActivit = function(options,call){
    //拷贝默认配置
    var copyOption = Activiti.util.deepClone(defaultOptions);
    //合并配置
    $.extend(copyOption,options);
    //初始化
    activiti = new Activiti(copyOption);
    //是不是只查询，如果是 则不需要加载按钮  直接返回
    if(activiti.options.queryOnly == true){
        call.call(activiti);
        return;
    }
    //调用对象创建成功的方法
    activiti.options.success(activiti);
    activiti.init(function(){
        activiti.getRemarks();
        call.call(activiti);
    });
}


Activiti.prototype.load = {
    layerLoadIndexArray : [],
    start : function(){
        activiti.load.end();
        this.layerLoadIndexArray.push(layer.load());
    },
    end : function(){
        $.each(this.layerLoadIndexArray,function(index,object){
            layer.close(object);
        })
    }
}

Activiti.prototype.buttonShow = function(btn) {
    $(btn).show();
}

Activiti.prototype.bindClick = function(btn, activiti, item,fun) {
    btn.addEventListener("click", function () {
        activiti.options.item = item;
        fun.call(activiti)
    });
}

Activiti.prototype.render = function(buttons = [],call){

    activiti.buttonList = [];
    //排序
    buttons.sort(function(bt1,bt2){
        return bt1.sort - bt2.sort;
    })

    //如果不需要展示按钮则按钮数组为空
    if(activiti.options.showBtnFlag == false){
        buttons = [];
    }

    //渲染按钮
    activiti.renderButton(buttons, call);
    call.call(activiti);

}

Activiti.prototype.renderButton = function (buttons = [], call) {
    if(activiti.options.showBtnFlag == false){
        return;
    }
    activiti.cleanButton(function(){
        //创建 button
        $.each(buttons,function (index,item) {
            var args = [];
            var btn = document.createElement("button");
            $(btn).attr("class",activiti.options.class);
            $(btn).attr("type","button");
            $(btn).html(item.name);
            $("" + activiti.options.id).append(btn);
            activiti.buttonList.push(btn);
            //前置js
            if(item.document && item.document.lineJavaScript && item.document.lineJavaScript.afterButtonShow && activiti.options.showAfterFuns[item.document.lineJavaScript.afterButtonShow]){
                $(btn).hide();
                var showFunction = activiti.options.showAfterFuns[item.document.lineJavaScript.afterButtonShow];
                showFunction(item,function(res){
                    if(res.log){
                        activiti.log(res.log);
                    }
                    if(res.code >0){
                        activiti.buttonShow(btn);
                        console.log("按钮显示" + index);
                    }else if(res.code == 0){
                        activiti.log("状态：0 ，" +  showFunction.name + ":" +res.log || res.msg);
                    }else{
                        activiti.error(showFunction.name + ":" + res.log)
                    }
                });
            }
            if(item.document.lineJavaScript.otherJs && item.document.lineJavaScript.otherJs.split(",").length > 0){
                var funArray = item.document.lineJavaScript.otherJs.split(",");
                $.each(funArray,function(i,it){
                    var funtemp = function(){
                        var callback = Activiti.util.getCallbackFn.call(arguments.callee);
                        var functionTemp= activiti.options.otherJs[funArray[i]];
                        if(functionTemp){
                            functionTemp.call(activiti,activiti,item,function(res){
                                if(res.log){
                                    activiti.log(res.log);
                                }
                                if(res.code >0){
                                    callback.call(activiti,item)
                                }else if(res.code == 0){
                                    console.log( "状态：0 ，" +  funArray[i] + ":" + res.log || res.msg);
                                }else{
                                    activiti.error(funArray[i] + ":" + res.msg || res.log || "执行出错！");
                                }
                            })
                        } else {
                            activiti.error("未找到前置js：" + funArray[i] + ",请清除浏览器缓存重试。如清理缓存后问题依然存在，请联系管理员。")
                        }
                    }
                    args.push(funtemp);
                })
                /*  }
                  args.push(function(){
                      activiti.buttonClick.call(activiti,item)
                  });
                  activiti.bindClick(btn,that,item,function(){
                      Activiti.util.simpleChainedFn.apply(that, args);
                  })*/
                args.push(activiti.buttonClick);
                btn.addEventListener("click",function(){
                    activiti.load.start();
                    activiti.options.item = JSON.parse(JSON.stringify(item));
                    //清空辅助参数
                    activiti.auditorMapTemp = {};
                    activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [];
                    Activiti.util.simpleChainedFn.apply(activiti, args,item);
                });
            }else{
                btn.addEventListener("click",function(){
                    activiti.load.start();
                    activiti.options.item = JSON.parse(JSON.stringify(item));
                    //清空辅助参数
                    activiti.auditorMapTemp = {};
                    activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [];
                    activiti.buttonClick.call(activiti)
                });
            }
        });
    });
}


Activiti.prototype.error = function(res) {
    if(typeof res === "string"){
        layui.layer.alert(res, {icon: 2,closeBtn: 0 });
    }else if(res.msg){
        layui.layer.alert(res.msg, {icon: 2,closeBtn: 0 });
    }
}

Activiti.prototype.log = function(res) {
    if(typeof res === "string"){
        console.log(res)
    }
}

Activiti.prototype.successMsg = function(res,call) {
    if(typeof res === "string"){
        layui.layer.alert(res, {icon: 1,closeBtn: 0 }, function () {
            call()
        });
    }else if(res.msg){
        layui.layer.alert(res.msg, {icon: 1,closeBtn: 0 }, function () {
            call()
        });
    }
}


/**
 * 按钮点击事件
 * @param index
 */
Activiti.prototype.buttonClick = function() {
    var item = activiti.options.item;
    //设置表单数据中选择数据为当前按钮名称
    this.actForm.values.choose = item.btnName || item.name;
    this.options.statusNameTemp = item.name;
    if(item &&  item.document  && item.document.lineJavaScript ){
        var lineJavaScript = item.document.lineJavaScript;
        item.auditorMap = lineJavaScript.auditorMap;

        this.actForm.users = item.auditorMap;
        //是否需要调用外部接口
        if(lineJavaScript.url != undefined && lineJavaScript.url.length > 0){
            this.actForm.values.url = lineJavaScript.url;
        }
        //需要选人 && 需要审核意见
        if(lineJavaScript.needAuditor == true && lineJavaScript.needRemarks == true){
            this.needAuditor = lineJavaScript.needAuditor;
            this.chooseAuditor(lineJavaScript.auditorMap,function(choose){
                if (choose.type == "activitiUsers" || choose.type == "users") {
                    activiti.actForm.users = $.extend(activiti.actForm.users, choose);
                }
                this.needRemarks = lineJavaScript.needRemarks;
                this.getText({title:"备注"},"remarks",this.chooseDone);
            });
        }else if(lineJavaScript.needAuditor == true){// 只需要选人
            this.needAuditor = lineJavaScript.needAuditor;
            this.chooseAuditor(lineJavaScript.auditorMap);
        }else if(lineJavaScript.needRemarks == true){// 只需要审核意见
            //判断是否有前置js增加的候选人
            if(activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS && activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS.length > 0 ){
                item.auditorMap.activitiUsers = [];
                $.each(activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS,function(index,it){
                    item.auditorMap.activitiUsers.push(it.id);
                })
            }
            this.needRemarks = lineJavaScript.needRemarks;
            this.getText({title:"备注"},"remarks",this.chooseDone);
        }else if(lineJavaScript.auditorMap != undefined){
            if(activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS && activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS.length > 0 ){
                item.auditorMap.activitiUsers = [];
                $.each(activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS,function(index,it){
                    item.auditorMap.activitiUsers.push(it.id);
                })
            }
            this.chooseDone(lineJavaScript.auditorMap);
        }else{
            //什么都不选
            this.onlySubmit();
        }
    }
}


Activiti.prototype.doSubimt = function() {

    if (activiti.activitiData.processInstanceId != null) {
        //获取taskId
        activiti.getTaskList(function (taskList) {
            //设置节点id
            activiti.actTask.lastId = taskList[taskList.length - 1].id;
            //设置表单id
            activiti.actForm.id = taskList[taskList.length - 1].id;
            //根据最后一个节点id 获取当前表单
            activiti.getFrom(activiti.actTask.lastId, function (requestData) {
                //设置表单id
                activiti.actForm.formId = requestData.formId;
                //设置节点id
                activiti.actTask.lastId = requestData.id;
                activiti.submit();
            });
        });
    }
}

Activiti.prototype.onlySubmit = function(){
    activiti.load.start();
    if(!activiti.options.isStart){
        activiti.afterSave(activiti, function(res){
            if(res){
                res.statusName = activiti.options.statusNameTemp;
                activiti.actForm.values.serviceData = {};
                activiti.actForm.values.serviceData.uuid = res.uuid;
                activiti.actForm.values.serviceData.data = Activiti.util.encodeBase64(JSON.stringify(res));
                activiti.doSubimt.call(activiti);
            }
        });
    }else{
        activiti.doSubimt.call(activiti);
    }

}

Activiti.prototype.getFrom = function(taskId,call){
    activiti.post(urlConf.actFromUrl,{id : taskId},call);
}

Activiti.prototype.getTaskList = function(call){
    activiti.post(urlConf.actGetTaskIdUrl, {
        processInstanceId: activiti.activitiData.processInstanceId
    },function(requestData){
        call(requestData.data)
    })
}

/**
 * 初始化 解析配置
 * @param options
 * @param call
 */
Activiti.prototype.init = function(call){

    //获取流程定义
    if(activiti.options.uuid && !activiti.options.againFlag){
        activiti.getActivtiByUUID(activiti.options.uuid,function(res){
            //旧流程
            if(res.code == 200 && res.data) {
                activiti.activitiData = res.data;
                activiti.actForm.processInstanceId = res.data.processInstanceId;
                activiti.loadButtonList(call);
            }else{  //新流程
                activiti.initNew(call);
            }
        })
    }else{  //新流程
        activiti.initNew(call);
    }
}

Activiti.prototype.initByConf = function(call) {

    //获取流程定义
    if (activiti.options.activitiConf) {
        if (Activiti.util.isFunction(activiti.getActivitiKey)) {
            activiti.getActivitiKey(activiti.options.activitiConf, function (res) {
                activiti.activitiData.deploymentKey = res;
                activiti.loadButtonList(call);
            })
        }
    }
}

Activiti.prototype.initNew = function(call){

    if(activiti.options.againFlag && activiti.options.oldUuid){
        activiti.actForm.oldUuid = activiti.options.uuid;
        activiti.options.againFunction(activiti,function(){
            activiti.initByConf.call(activiti,call);
        })
    }else{
        activiti.initByConf.call(activiti,call);
    }
}

Activiti.prototype.cleanButton = function(call){
    if(activiti.buttonList.length > 0){
        $.each(activiti.buttonList,function(index,item){
            $(item).remove();
        })
    }
    if(Activiti.util.isFunction(call)){
        call();
    }
}

Activiti.prototype.reloadButton = function(call){
    function clear() {
        activiti.load.end();
        activiti.options.closeServiceTemp = [];
        activiti.auditorMapTemp = {};
        activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [];
        call();
    }
    //执行清空临时变量程序
    if(activiti.options.closeServiceTemp && activiti.options.closeServiceTemp.length > 0){
        Activiti.util.executeFunctionChain(activiti.options.closeServiceTemp,activiti,{},function(){
            activiti.load.start();
            activiti.init.call(activiti,function(){
                clear();
            })
        })
    }else{
        activiti.load.start();
        activiti.init.call(activiti,function(){
            clear();
        })
    }
}

Activiti.prototype.showRemarks = function(remarks){
    console.log("备注：" + remarks );
}
Activiti.prototype.getRemarks = function(){
    if(activiti.activitiData.processInstanceId){
        activiti.getTaskList(function(taskList){
            if(taskList && taskList instanceof Array && taskList.length > 1 ){
                var remarksId = taskList[taskList.length - 2].id;
                if(taskList[taskList.length-1].endTime){
                    remarksId = taskList[taskList.length - 1].id
                }
                activiti.getFrom(remarksId,function(thisForm){
                    if(thisForm == undefined || thisForm.fields == undefined || thisForm.fields.length == 0){
                        return;
                    }
                    thisForm.fields.forEach(function (item,index) {
                        if(item.id == "otherData" && item.value && item.value.length > 0){
                            var otherData = JSON.parse(item.value);
                            if(otherData != null &&  otherData.hasOwnProperty("remarks")){
                                activiti.showRemarks(Activiti.util.escape(otherData.remarks));
                            }
                        }
                    })
                });
            }
        });
    }
}

//选择完成后执行,组装参数
Activiti.prototype.chooseDone = function(choose) {

    activiti.load.start();
    if (choose.type == "activitiUsers" || choose.type == "users") {
        activiti.actForm.users = $.extend(activiti.actForm.users, choose);
    } else if (choose.type == "remarks") {
        activiti.actForm.values.otherData = {};
        activiti.actForm.values.otherData = {"remarks": choose.remarks};
    }
    //新流程，先自推一次
    if (activiti.activitiData.processInstanceId == undefined || activiti.activitiData.processInstanceId == null) {
        //提交开始表单 获取流程id
        activiti.submitStart(function () {
            activiti.actForm.deploymentKey = activiti.activitiData.deploymentKey;
            activiti.onlySubmit();
        });
    } else {
        activiti.onlySubmit();
    }
}

Activiti.prototype.submitStart = function(call){

    activiti.options.isStart = true;
    activiti.afterSave(activiti,function(res){
        if(res){
            activiti.actForm.values.serviceData = {};
            activiti.actForm.values.serviceData.uuid = res.uuid;
            activiti.actForm.values.serviceData.data = Activiti.util.encodeBase64(JSON.stringify(res));
            //获取开始表单
            activiti.post(urlConf.actStartFromUrl,{
                deploymentKey: activiti.activitiData.deploymentKey
            },function(startData){
                activiti.activitiData.processDefinitionId = startData.processDefinitionId;
                //提交开始表单
                activiti.post(urlConf.actSubmitUrl,JSON.stringify({
                    processDefinitionId : activiti.activitiData.processDefinitionId,
                    deploymentKey : activiti.activitiData.deploymentKey,
                }),function(submitData){
                    //流程实例id
                    activiti.activitiData.processInstanceId = submitData.id;
                    if(typeof call == 'function'){
                        call(submitData.id);
                    }
                });
            });
        }
    });
}

/**
 * 需要页面重写 结束的方法
 * @param call
 */
Activiti.prototype.submitOK = function(call){

}

/**
 * 需要页面重写 推进成功后回调
 * @param call
 */
Activiti.prototype.endFunction = function(activiti,call){

}

/**
 * 需要页面重写 点击后的保存操作
 * @param call
 */
Activiti.prototype.afterSave = function(){

}

Activiti.prototype.submit = function(call){

    activiti.actForm.deploymentKey = activiti.activitiData.deploymentKey;
    activiti.actForm.processInstanceId = activiti.activitiData.processInstanceId;
    activiti.actForm.pojoName = activiti.options.pojoName;
    if(activiti.actForm.users.activitiUsers && activiti.actForm.users.activitiUsers.length > 0 ){
        activiti.actForm.users.FORM_SERVICE_USERS = [];
    }
    activiti.post(urlConf.actSubmitUrl,JSON.stringify(activiti.actForm),function(requestData){
        if(requestData.status != undefined && requestData.status == 200){
            activiti.endFunction(activiti,function(res){
                if(res && res.status === true){
                    if(activiti.actForm.values.url){
                        activiti.load.end();
                        activiti.successMsg("正在" + activiti.options.statusNameTemp + "中，可以在列表页查看发布进度。",function(){
                            activiti.submitOK.call(activiti);
                        })
                    }else if(activiti.options.statusNameTemp){
                        activiti.load.end();
                        var layerMsg = activiti.options.statusNameTemp + '成功';
                        activiti.successMsg(layerMsg,function(){
                            activiti.submitOK.call(activiti);
                        })
                    }else{
                        activiti.load.end();
                        activiti.successMsg(requestData.msg,function(){
                            activiti.submitOK.call(activiti);
                        })
                    }
                }else if(res && res.msg){
                    activiti.error(res.msg);
                }else{
                    console.log("执行后置接口错误");
                }
            })
        }else if(requestData && requestData.status != undefined && requestData.status == 500){
            activiti.load.end();
            activiti.error({status:false,msg:requestData.msg})
        }else if(requestData == undefined){
            activiti.load.end();
            activiti.error("请求超时！内容已保存，请刷新重试");
        }else{
            activiti.load.end();
            activiti.error("流程推进失败!"+requestData.msg);
        }
    });

}


//加载按钮配置
Activiti.prototype.loadButtonList = function(call){

    activiti.post(urlConf.loadButtonListUrl, {
        processInstanceId : activiti.activitiData.processInstanceId || "",
        deploymentKey : activiti.activitiData.deploymentKey || ""
    },function(data){
        if(data && data.length > 0 ){
            activiti.render(data,call);
        }else{
            activiti.render([],call);
        }
    });

}

/**
 * 获取流程信息
 * @param uuid
 * @param call
 */
Activiti.prototype.getActivtiByUUID = function(uuid,call){
    activiti.post(urlConf.getActserviceDataByUuidUrl,{uuid:uuid},function(res){
        if(Activiti.util.isFunction(call)){
            call(res);
        }
    })
}

/**
 * 获取历史记录
 * @returns {*[]|*}
 */
Activiti.prototype.getHistory = function(call,inputUuid){
    var uuid,processInstanceId ;
    //外部指定，优先度最高
    if(inputUuid){
        uuid = inputUuid;
    }else if(activiti.options.oldUuid){ //历史重启
        uuid = activiti.options.oldUuid;
    }else if(activiti.activitiData.processInstanceId || activiti.options.uuid){
        processInstanceId = activiti.activitiData.processInstanceId ? activiti.activitiData.processInstanceId : "";
        uuid = activiti.options.uuid || "";
    }else{
        call.call(activiti,[]);
        return;
    }

    var params = {}
    if(processInstanceId){
        params.processInstanceId = processInstanceId
    }
    if(uuid){
        params.uuid = uuid
    }
    activiti.post(urlConf.actHistoryUrl,params,function(requestData){
        call.call(activiti,requestData);
    })
}

Activiti.prototype.post = function(url,pram,call){
    var requestData ;
    var contentType =  'application/x-www-form-urlencoded';
    if(typeof pram == 'string'){
        contentType =  'application/json; charset=UTF-8';
    }

    $.ajax({
        url: url,
        data: pram,
        contentType: contentType,
        dataType:"JSON",
        traditional:true,
        type:"POST",
        success: function(result){
            if(typeof result == 'string'){
                if(Activiti.util.isJsonFormat(result)){
                    requestData = JSON.parse(result);
                }else{
                    requestData = result;
                }
            }else{
                requestData = result;
            }
            if(typeof call == "function"){
                call(requestData);
            }
        },
        error:function(e){
            call(false);
            console.log(e);
        },
        beforeSend: function(xhr) {
            xhr.setRequestHeader("userName", optionsCopy.userAccount);
        }
    });
}

/**
 * 工具箱
 * @type {{post: post, isFunction: isFunction, timeFormat: timeFormat, escape: (function(*=): string), isJsonFormat: isJsonFormat}}
 */
Activiti.util = {
    timeFormat : function (time) {
        if (time == null || undefined)
            return "";
        var d = new Date(time);
        var year = d.getFullYear();       //年
        var month = d.getMonth() + 1;     //月
        var day = d.getDate();            //日

        var hh = d.getHours();            //时
        var mm = d.getMinutes();          //分
        var ss = d.getSeconds();           //秒
        var clock = year + "-";
        if (month < 10)
            clock += "0";
        clock += month + "-";
        if (day < 10)
            clock += "0";
        clock += day + " ";
        if (hh < 10)
            clock += "0";
        clock += hh + ":";
        if (mm < 10) clock += '0';
        clock += mm + ":";
        if (ss < 10) clock += '0';
        clock += ss;
        return (clock);
    },
    escape : function (t) {
        return String(t || "").replace(/&([a-zA-Z0-9]+;)/g, "&amp;$1").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/'/g, "&#39;").replace(/"/g, "&quot;")
    },
    isFunction : function(funName){
        if(funName == undefined || funName == "")
            return false;
        if($.isFunction(funName))
            return true;
        if(typeof funName == "string"){
            try{
                if(typeof(eval(funName)) == "function")
                    return true;
            }catch(e){
                return false;
            }
        }
        return false;
    },
    isJsonFormat : function(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
    deepClone : function(source){
        var targetObj = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
        for(let keys in source){ // 遍历目标
            if(source.hasOwnProperty(keys)){
                if(source[keys] && typeof source[keys] === 'object'){ // 如果值是对象，就递归一下
                    targetObj[keys] = source[keys].constructor === Array ? [] : {};
                    targetObj[keys] = this.deepClone(source[keys]);
                }else{ // 如果不是，就直接赋值
                    targetObj[keys] = source[keys];
                }
            }
        }
        return targetObj;
    },
    simpleChainedFn: function () {
        var localParam = arguments; //当前入参
        var firstFnArguments; //首节点的入参(数组格式)
        var chainLength = localParam.length; //除掉首节点入参，所有链条长度

        // 入参数据校验...
        for(i=0;i<localParam.length;i++){
            if(!localParam[i] || typeof localParam[i] !== "function"){
                // 如果当前是倒是第二个，则认为是首节点的入参；链条长度减1...
                if(i === localParam.length - 1){
                    firstFnArguments = localParam[i];
                    chainLength -= 1;
                }else{
                    console.log("【error】链条参数有误！");
                    return;
                }

            }
        }
        // 组合链条...
        var firstFn = (function combinationFn(index){
            var curFnIndex = index || 0; //当前函数索引
            var callBack; //当前函数参数回调

            // 如果存在下一条，则将下一条绑定为当前的回调...
            if(curFnIndex + 1 <  chainLength){
                callBack = combinationFn(curFnIndex + 1);
            }

            var curFn = localParam[curFnIndex];
            if(curFn){
                if(callBack){
                    curFn.callback = callBack;
                }
                return curFn;
            }else{
                return false;
            }

        })();
        // 启动链条 ...
        if(typeof firstFn === "function"){
            var suctnParam = "";
            if(firstFnArguments){
                for(var i = 0 ; i < firstFnArguments.length; i ++)
                {
                    suctnParam += "firstFnArguments[" + i + "]" + (i === firstFnArguments.length - 1 ? "" : ",");
                }
            }
            eval("firstFn(" + suctnParam + ")");

        }
    },
    getCallbackFn : function(){
        return this.callback;
    },
    syncPost : function(url,pram,call){
        var requestData ;
        var contentType =  'application/x-www-form-urlencoded';
        if(typeof pram == 'string'){
            contentType =  'application/json; charset=UTF-8';
        }
        $.ajax({
            url: url,
            data: pram,
            async:false,
            contentType: contentType,
            dataType:"JSON",
            type:"POST",
            success: function(result){
                if(typeof result == 'string'){
                    requestData = JSON.parse(result);
                }else{
                    requestData = result;
                }
            },
            error:function(e){
                console.log(e);
            },
            beforeSend: function(xhr) {
                xhr.setRequestHeader("userName", optionsCopy.userAccount);
            }
        });
        if(typeof call == "function"){
            call(requestData);
        }else{
            return requestData;
        }
    },
    post: function(url,pram,call){
        var requestData ;
        var contentType =  'application/x-www-form-urlencoded';
        if(typeof pram == 'string'){
            contentType =  'application/json; charset=UTF-8';
        }
        $.ajax({
            url: url,
            data: pram,
            contentType: contentType,
            dataType:"JSON",
            type:"POST",
            success: function(result){
                if(typeof result == 'string'){
                    call(JSON.parse(result));
                }else{
                    call(result);
                }
            },
            error:function(e){
                console.log(e);
            },
            beforeSend: function(xhr) {
                xhr.setRequestHeader("userName", optionsCopy.userAccount);
            }
        });
    },
    executeFunctionChain : function(functionArray,that,param,call){
        if(!functionArray || functionArray.length == 0 ){
            call(param);
            return;
        }
        var args = [];
        $.each(functionArray,function(i,it){
            var funtemp = function(){
                var callback = Activiti.util.getCallbackFn.call(arguments.callee);
                if(it){
                    it.call(that,that,param,function(res){
                        if(res && res.log){
                            that.log(res.log);
                        }
                        if(res && res.code >0){
                            callback.call(that, param)
                        }else if(res.code == 0){
                            console.log( "状态：0 ，" +  it + ":" + res.log || res.msg);
                        }else{
                            that.error(it + ":" + res.msg || res.log || "执行出错！");
                        }
                    })
                } else {
                    that.error("未找到前置js：" + it + ",请清除浏览器缓存重试。如清理缓存后问题依然存在，请联系管理员。")

                }
            }
            args.push(funtemp);
        })

        args.push(function(){
            call()
        });
    },
    encodeBase64 : function (str){
        // 对字符串进行编码
        var encode = encodeURIComponent(str);
        // 对编码的字符串转化base64
        var base64 = btoa(encode);
        return base64;
    }

}



Activiti.prototype.getText = function (data,type,callback) {

    activiti.callback = callback;
    if(Activiti.util.isFunction(activiti.options.getChoose)){
        activiti.options.getText.call(activiti,data,type,callback);
    }else{
        var content = "<div class=\"layui-card\">\n" +
            "          <div class=\"layui-card-header\">备注</div>\n" +
            "          <div class=\"layui-card-body layui-row\">\n" +
            "            <div class=\"layui-col-md12\">\n" +
            "              <textarea name=\"\" placeholder=\"请输入备注\" id=\"actText\" name=\"actText\" class=\"layui-textarea\"></textarea>\n" +
            "            </div>\n" +
            "          </div>\n" +
            "        </div>"

        layer.open({
            content : content,
            type : 1,
            btn: ['确定','取消']
            ,area: '500px'
            ,cancel: function(index, layero){
                layer.close(index);
                activiti.load.end();
                return false;
            },end: function(){
                activiti.load.end();
                return false;
            }
            ,yes : function(index){
                var value = $("#actText").val();
                var valueLength = value.replace(/\ +/g, ""); //去掉空格
                valueLength = valueLength.replace(/[ ]/g, "");    //去掉空格
                valueLength = valueLength.replace(/[\r\n]/g, ""); //去掉回车换行
                if(valueLength.length < 1){
                    layer.msg("请填写备注!",{icon: 2})
                    return false;
                }else if(value.length > 100){
                    layer.msg("备注请勿超过100字!",{icon: 2})
                    return false;
                }else{
                    var callbackValue ={};
                    if(type == "activitiUsers" || type == "users"){
                        callbackValue.activitiUsers =value;
                    }else if(type == "remarks"){
                        callbackValue.remarks =value;
                    }
                    callbackValue.type = type;
                    callback.call(activiti,callbackValue);
                    layer.close(index);
                }
            },btn2 : function(index, layero){
                layer.close(index);
                activiti.load.end();
                return false;
            }
        })
    }
}

/**
 * 撤回功能
 * @param call
 */
Activiti.prototype.terminate = function(call){

    if(!activiti.activitiData.processInstanceId){
        activiti.error("流程信息获取失败：请刷新后重试！", {icon: 2,closeBtn: 0 });
        call(false);
    }
    activiti.post(urlConf.terminateUrl,{processInstanceId:activiti.activitiData.processInstanceId},function(data){
        if(typeof call == 'function'){
            if(data.code == 200){
                call(true);
            }else{
                activiti.error("流程提前结束失败：" + data.msg, {icon: 2,closeBtn: 0 });
                call(false);
            }
        }
    });
}

Activiti.prototype.chooseAuditor = function(auditorMap,call) {
    if(!call){
        call = activiti.chooseDone;
    }
    //如果是业务指定的人，则优先业务的人
    if(typeof activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS != "undefind" && $.isArray(activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS) && this.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS.length >0)
        activiti.getChoose.call(activiti,activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS,"activitiUsers",call);
    //如果是指定人中选一个的，则列出人的列表
    else if(auditorMap.FORM_SERVICE_USERS.length > 0){
        $.each(auditorMap.FORM_SERVICE_USERS,function(index,item){
            if(item && item.id == activiti.options.userAccount){
                auditorMap.FORM_SERVICE_USERS.splice(index,1)
                return;
            }
        })
        activiti.getChoose.call(activiti,auditorMap.FORM_SERVICE_USERS,"users",call);
    }else if(auditorMap.FORM_SERVICE_GROUP.length > 0  && auditorMap.FORM_SERVICE_ROLE.length < 1){    //只选择部门
        var temp = {
            group:auditorMap.FORM_SERVICE_GROUP
        }
        if(activiti.auditorMapTemp.FORM_SERVICE_ROLE){
            temp.role = activiti.auditorMapTemp.FORM_SERVICE_ROLE;
        }
        activiti.getUser(temp,activiti,function(users){
            activiti.getChoose.call(activiti,users,"activitiUsers",call)
        })
    }else if(auditorMap.FORM_SERVICE_ROLE.length > 0 && auditorMap.FORM_SERVICE_GROUP.length < 1){     //只选择角色
        var temp = {
            role :auditorMap.FORM_SERVICE_ROLE
        }
        if(activiti.auditorMapTemp.FORM_SERVICE_GROUP){
            temp.group = activiti.auditorMapTemp.FORM_SERVICE_GROUP;
        }
        activiti.getUser(temp,activiti,function(users){
            activiti.getChoose.call(activiti,users,"activitiUsers",call);
        })
    }else if(auditorMap.FORM_SERVICE_GROUP && auditorMap.FORM_SERVICE_ROLE){      //选择角色和部门
        var temp = {
            role :auditorMap.FORM_SERVICE_ROLE,
            group :auditorMap.FORM_SERVICE_GROUP
        }
        activiti.getUser(temp,activiti,function(users){
            activiti.getChoose.call(activiti,users,"activitiUsers",call);
        })
    }
};


Activiti.prototype.todoList = function(query,call){
    activiti.post(urlConf.actTodoListUrl,JSON.stringify(query),function (todoList) {
        if(typeof call == 'function'){
            call(todoList);
        }
    });
}

Activiti.prototype.examinedList = function(query,call){
    activiti.post(urlConf.actExaminedUrl,JSON.stringify(query),function (examinedList) {
        if(typeof call == 'function'){
            call(examinedList);
        }
    });
}



/**
 * 获取所有的部门
 */
Activiti.prototype.getAllGroup = function () {
    return activiti.post(urlConf.allGroupUrl,null);
}
Activiti.prototype.getAllRole = function () {
    return activiti.post(urlConf.allRole,null);
}

Activiti.prototype.getGroupByIds = function (ids) {
    var json = new Array();
    var data = activiti.getAllGroup();
    $.each(data, function (index, item) {
        if (ids.indexOf(item.id) > -1) {
            json.push(item);
        }
    })
    return json;
}

Activiti.prototype.getRoleByIds = function (ids) {
    var json = new Array();
    var data = activiti.getAllRole();
    $.each(data, function (index, item) {
        if (ids.indexOf(item.id) > -1) {
            json.push({
                id: item.id,
                name: item.roleName
            });
        }
    })
    return json;
}

Activiti.prototype.getUser = function (pram, activiti, call) {
    activiti.post(urlConf.getUsers, JSON.stringify(pram), function (users) {
        //判断选人是否外部过滤
        if (Activiti.util.isFunction.call(activiti, activiti.chooseFilterObject)) {
            activiti.chooseFilterObject(users, function (res) {
                call(res)
            })
        } else {
            call(users);
        }
    })
}





Activiti.prototype.getChoose = function (data,type,callback) {

    activiti.callback = callback;

    if(Activiti.util.isFunction(activiti.options.getChoose)){
        activiti.options.getChoose.call(activiti,data,type,callback);
    }else{
        var content = "<!DOCTYPE html>\n" +
            "<html lang=\"zh-CN\">\n" +
            "<head>\n" +
            "<meta charset=\"utf-8\">\n" +
            "<title>选择</title>\n" +
            "<link rel=\"stylesheet\" href=\"/cm-sites-activiti/css/jquery-labelauty.css\">\n" +
            "<style>\n" +
            "ul { list-style-type: none;padding-top: 20px;}\n" +
            "li { display: inline-block;}\n" +
            "li { padding: 0px 10px;}" +
            ".dowebok{height: 300px;overflow-y: auto; }\n" +
            "input.labelauty + label { font: 12px \"Microsoft Yahei\";}\n" +
            "</style>\n" +
            "</head>\n" +
            "\n" +
            "<body><ul class=\"dowebok\" style='padding: 15px;'>";

        $.each(data,function(index,item){
            content += "<li><input value='" + item.id + "' id='ACT_CHOOSE"+ item.id +"' class='actChooseCheckbox' type=\"checkbox\" name=\"choose\" data-labelauty=\"" + Activiti.util.escape(item.first || item.name) + "\"></li>";
        });
        content += "</ul>" +
            "</body>\n" +
            "</html>";

        layer.open({
            content: content,
            type: 1,
            btn: ['确定', '取消']
            , area: '500px'
            ,cancel: function(index, layero){
                layer.close(index);
                activiti.load.end();
                return false;
            },end: function(){
                activiti.load.end();
                return false;
            },success: function(){
                $.getScript("/cm-sites-activiti/js/jquery-labelauty.js",function () {
                    $('.actChooseCheckbox').labelauty();
                })
            },yes : function(index){
                var value = [];
                $("input[name='choose']:checkbox").each(function (index,item) {
                    if ($(this).attr("checked")|| this.checked ) {
                        value[value.length] = $(this).val();
                    }
                });
                if(value.length < 1){
                    alert("请至少选择一项");
                    return;
                }else{
                    var callbackValue ={};
                    if(type == "activitiUsers" || type == "users"){
                        callbackValue.activitiUsers =value;
                    }else if(type == ""){
                        callbackValue.remarks =value;
                    }else if(type == null){
                        callbackValue.data = value;
                    }
                    callbackValue.type = type;
                    callback.call(activiti,callbackValue);
                }
            },btn2: function(index, layero){
                layer.close(index);
                activiti.load.end();
            }
        });
    }
}

export default Activiti;
