import React, {Component} from 'react'
import {NavBar, Icon, Button, List, Modal, Toast, TextareaItem} from "antd-mobile";
import {connect} from "react-redux";
import config from "../../config";
import EditorView from "../../components/editor/EditorView";
import {getStoryById} from "../../action/storyAction";
import {setCurrentMedia, setDataSource, setNoRefresh} from "../../store/actions";
import {setStory, setAuditOpinions, setStoryId, setFormat, setContent} from "../../store/actions/storyAction";
import {setActChoose, setStoryAttribute, setToListPage} from "../../store/actions";
import Remarks from "../../components/Remark";
import {cancelDraft, getInfoFromLocalStorage, publishDraft, visitorDraft, publishDraftNews} from "../../action";
import {uuid} from "../../utils";
import {checkStory, saveStory} from "../../components/activity/StorySave";
import {setStoryFormat} from "../../components/editor/Editor.config"
import './storyAtrribute.css';
import Activiti from "../../components/activityNew/ActivityFlow";
import $ from 'jquery';
import ActText from "../../components/activity/ActRemark";
import store from "../../store";
import ActChoose from "../../components/activity/ActChoose";
import {baseUrl} from "../../components/activity/ActButton";
import Upload from "../../components/upload/Upload"
import eventProxy from "../../utils/eventProxy";
import SubjectSubmit from "../../components/subjectSubmit/subjectSubmit"

window.isPublish = false;
var saveStoryUrl = baseUrl + "/cm-sites-app-h5/story/save";

const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
const alert = Modal.alert;
let wrapProps;
if (isIPhone) {
    wrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

let fromZyk_G = false;
let activitiType_G = 'DELIVER';

const operation = Modal.operation;
let mediaRoleColum = {};
let allThat;
class WriteStoryNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: "稿件",
            auditOpinions: false, //审核意见
            story: props.story,
            history: require("history").createHashHistory,
            noRefresh: props.noRefresh,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            goBack: getInfoFromLocalStorage('goBack'),
            storyId: parseInt(props.match.params.id),
            showEllipsisButton: true,
            actButtonId: parseInt(props.match.params.actButtonId),
            fromPage: parseInt(props.match.params.fromPage),
            interviewId: parseInt(props.match.params.interviewId ? props.match.params.interviewId : 0),
            appointmentReceiverId: parseInt(props.match.params.appointmentReceiverId ? props.match.params.appointmentReceiverId : 0),
            processInstanceId: parseInt(props.match.params.processInstanceId),
            appointmentId: parseInt(props.match.params.appointmentId),
            processDefinitionName: parseInt(props.match.params.processDefinitionName),
            columnSelect: false,
            actButtons: [],
            showSaveBtn: true,
            currentMedia: getInfoFromLocalStorage("currentMediaCode"),
            isLinkStoryFlag: props.match.params.isLinkStoryFlag,     //链接稿件标识
            currentStory: {}, //当前稿件。
            currentCustomInsert: '',//自定义扩展插入状态枚举，不清楚原理勿动
            fcolumnStoryAlert:false,  //如果是副栏目进入这篇稿件需要提示，跳转栏目下编辑此文章。
            fcolumnStoryAlertContent:"",
        };

        //约稿回稿功能修改。
        let dataSource = 0;
        if (this.state.appointmentReceiverId && this.state.appointmentReceiverId !== 0) {
            this.state.story.appointmentReceiverId = this.state.appointmentReceiverId;
            dataSource = 2;
        } else if (this.state.interviewId && this.state.interviewId != 0) {
            dataSource = 4;
        } else {
            dataSource = 7;
        }

        //初始化，一下，稿件的数据源
        this.props.setStoryId(parseInt(props.match.params.id));
        this.props.setDataSource(dataSource);
        this.gotoOtherAttributePage = this.gotoOtherAttributePage.bind(this);
        window.checkColumnFlag=true;
    }

    /***
     * 监听，打开批注，调到用户选择列表
     * @param nextProps
     * @param nextContext
     */
    componentWillReceiveProps(nextProps, nextContext) {

        //代表要选择编辑
        if (nextProps.actChoose && nextProps.actChoose.show) {
            this.state.history().push('/choseEditor/choseEditor')
        }

        if (nextProps.toListPage) {
            let path = '/listTabPage/listTabPage';

            if (this.state.fromPage == 2) {
                //跳到采访任务页面；
                path = "/InterviewDetailPage/InterviewDetailPage/" + this.state.interviewId;
            } else if (this.state.fromPage == 3) {
                //跳到采访任务页面；
                path = "/manuscriptDetailPage/manuscriptDetailPage/" + this.state.appointmentId + '/' + this.state.appointmentReceiverId;
            }

            this.state.history().push(path)
        }
    }

    /**
     * 更具稿件的id和用户角色，获取面板需要显示的流程按钮
     * @param storyId
     */
    getNeedShowButtonStoryId(story) {

        activitiType_G = "DELIVER";
        if (this.state.fromPage === 4) {
            fromZyk_G = true;
        } else {
            fromZyk_G = false;
        }

        if (!this.state.isContributeRoleFlag) {
            activitiType_G = "DEFAULT";
        }

        if (this.state.fromPage == '3') {
            activitiType_G = 'APPOINTMENT';
        }

        if (this.state.interviewId && this.state.interviewId != 0) {
            activitiType_G = 'ACTIVITY';
        }


        //初始化稿件的流程；
        //勾选的栏目；
        let chooseColumn = this.props.story.chooseColumn;
        let activitiConfs = [];

        if (chooseColumn && chooseColumn.length > 0) {
            chooseColumn.map((item, index) => {
                let column = {
                    mediaTreeId: item.treeContentId,
                    mediaCode: item.mediaCode,
                    activitiTypeEnum: activitiType_G,
                    type: "WEBSITE",
                };
                activitiConfs.push(column);
                return column;
            })
        } else {
            let baselConf = {
                activitiTypeEnum: activitiType_G,
                type: "WEBSITE",
                mediaCode: this.state.currentMedia ? this.state.currentMedia : ''
            }
            activitiConfs.push(baselConf);
        }
        // Activiti.util.post('/cm-sites-app-h5/mediaTreeActiviti/getMediaRoleByColum',JSON.stringify(activitiConfs),(data)=> {
        //     if (!data) {
        //         return "没有权限";
        //     }

        // mediaRoleColum=data;
        mediaRoleColum = {
            edit: true,
            revert: true,
            publish: true,
        }
        //获取手机登录页的下方的版权文字，
        $.post("/cm-sites-app-h5/config/getByConfigKey",{key:"pms.zyc.notCopyNotEnd"},(res)=>{
            if(res==1) {
                fromZyk_G=false;
            }
            this.actInit(story);
        });

        // });
    }

    /**
     * 配置文件中，所有按钮的列表
     * @type {{icon: *, title: *, buskey: *}[]}
     */
    style2ButtonImgUrl = config['style2ButtonImgUrl'];

    /**
     * 渲染流程的操作按钮
     * @param actButonsdata
     */

    renderButtons = (actButonsdata = [], call, activiti, activitiConfs) => {
        if (!actButonsdata || actButonsdata.length == 0) {
            actButonsdata = [];
        }

        //如果不是资源库的，需要移除掉返回至写稿人按钮
        // var goBackIndex = -1;
        // for (var i = 0; i < actButonsdata.length; i++) {
        //     if (actButonsdata[i].name == "返回至投稿人") {
        //         goBackIndex = i;
        //         break;
        //     }
        // }

        // if (!fromZyk_G) {
        //     if (goBackIndex >= 0) {
        //         actButonsdata.splice(goBackIndex, 1);
        //     }
        // }


        let that = activiti;
        let style2ButtonImgUrl = this.style2ButtonImgUrl;
        var actButtons = actButonsdata.map((item, index) => {
            let btnImgurl = style2ButtonImgUrl['default'];
            if (item.document.style && style2ButtonImgUrl[item.document.style]) {
                btnImgurl = style2ButtonImgUrl[item.document.style];
            }

            //前置js， 控制按钮的显隐
            let hideStyle = {
                display: 'block'
            }
            if (item.document && item.document.lineJavaScript && item.document.lineJavaScript.afterButtonShow && that.options.showAfterFuns[item.document.lineJavaScript.afterButtonShow]) {
                hideStyle = {
                    display: 'none'
                }
                var func = eval(that.options.showAfterFuns[item.document.lineJavaScript.afterButtonShow]);
                new func(item, function (res) {
                    if (res.code > 0) {
                        // that.buttonShow(btn);
                        hideStyle = {
                            display: 'block'
                        }
                    } else if (res.code == 0) {
                        activiti.log("状态：0 ，" + item.document.lineJavaScript.afterButtonShow + ":" + res.log || res.msg);
                    } else {
                        activiti.error(item.document.lineJavaScript.afterButtonShow + ":" + res.log)
                    }
                });
            }

            //流程需要执行的其他js
            var args = [];
            if (item.document.lineJavaScript.otherJs && item.document.lineJavaScript.otherJs.split(",").length > 0) {
                var funArray = item.document.lineJavaScript.otherJs.split(",");
                $.each(funArray, function fun(i, it) {
                    var funtemp = function () {
                        var callback = Activiti.util.getCallbackFn.call(funtemp);
                        var func = that.options.otherJs[funArray[i]];
                        if (func) {
                            func.call(that, that, item, function (res) {
                                if (res) {
                                    callback.call(that, item)
                                } else {
                                    that.error(res || "执行出错！");
                                }
                            })
                        } else {
                            that.error("未找到前置js：" + funArray[i] + ",请清除浏览器缓存重试。如清理缓存后问题依然存在，请联系管理员。")
                        }
                    }
                    args.push(funtemp);
                })
            }
            args.push(function () {
                that.buttonClick.call(that, item)
            });
            const hideStyleOk = hideStyle;
            return <div key={uuid()} className="button_view" style={{...hideStyleOk}}>
                <div className="button_div" onClick={() => {
                    that.options.item = item;

                    //清空辅助参数
                    activiti.auditorMapTemp = {};
                    activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [];
                    Activiti.util.simpleChainedFn.apply(that, args);
                }}><img src={btnImgurl} alt={item.name}
                        style={{width: '24px'}}/></div>
                <div className="button_title">{this.breakWorld(item.name)}</div>
            </div>
        });

        //加入保存按钮和预览按钮；
        var saveButton = <div key={uuid()} className="button_view">
            <div className="button_div" onClick={() => {
                this.saveStory()
            }}><img src={'images/save_1.png'} alt={'保存'}
                    style={{width: '24px'}}/></div>
            <div className="button_title">{this.breakWorld('保存')}</div>
        </div>;

        var previewButton = <div key={uuid()} className="button_view">
            <div className="button_div" onClick={() => {
                this.previewStory();
            }}><img src={"images/preview_1.png"} alt={'预览'}
                    style={{width: '24px'}}/></div>
            <div className="button_title">{this.breakWorld('预览')}</div>
        </div>;

        //一键排版按钮
        var akeyLayout = <div key={uuid()} className="button_view">
            <div className="button_div" onClick={() => {
                this.akeyLayout();
            }}><img src={"images/akey_layout_1.png"} alt={'一键排版'}
                    style={{width: '24px'}}/></div>
            <div className="button_title">{this.breakWorld('一键排版')}</div>
        </div>;
        if (actButtons && actButtons.length > 0 && actButonsdata[0].name !== '撤回') {
            if(!this.state.story||!this.state.story.linkurl){
                actButtons.push(akeyLayout);
            }
            // actButtons.push(previewButton);
            if(this.state.showSaveBtn){
                if(this.state.currentStory.statusName != '撤稿'){
                    actButtons.push(saveButton);
                }
            }
        }

        if (!this.state.isContributeRoleFlag && (this.state.currentStory.statusName == '发布' || this.state.currentStory.statusName == '撤稿') && mediaRoleColum.edit) {
            var visitorButton = <div key={uuid()} className="button_view">
                <div className="button_div" onClick={() => {
                    this.publisAndRevertAction("访问");
                }}><img src={"images/access_1.png"} alt={'访问'}
                        style={{width: '24px'}}/></div>
                <div className="button_title">{this.breakWorld('访问')}</div>
            </div>;

            //撤回按钮
            var revertButton = <div key={uuid()} className="button_view">
                <div className="button_div" onClick={() => {
                    this.publisAndRevertAction('撤稿');
                }}><img src={"images/goback_1.png"} alt={'撤稿'}
                        style={{width: '24px'}}/></div>
                <div className="button_title">{this.breakWorld('撤稿')}</div>
            </div>;

            //发布按钮
            var publishButton = <div key={uuid()} className="button_view">
                <div className="button_div" onClick={() => {
                    this.publisAndRevertAction('发布');
                }}><img src={"images/publish_1.png"} alt={'发布'}
                        style={{width: '24px'}}/></div>
                <div className="button_title">{this.breakWorld('发布')}</div>
            </div>;
            if (this.state.currentStory.statusName == '发布') {
                actButtons.push(visitorButton);
                if (mediaRoleColum.revert) {
                    actButtons.push(revertButton);
                }
                if (mediaRoleColum.publish) {
                    actButtons.push(publishButton);
                }
                // actButtons.push(previewButton);
                if(!this.state.currentStory.linkurl){
                    actButtons.push(akeyLayout);
                }

            } else if (this.state.currentStory.statusName == '审核通过') {
                if (mediaRoleColum.publish) {
                    actButtons.push(publishButton);
                }
                // actButtons.push(previewButton);
                // actButtons.push(previewButton);
                if(!this.state.currentStory.linkurl){
                    actButtons.push(akeyLayout);
                }
            } else {
                if (mediaRoleColum.publish) {
                    actButtons.push(publishButton);
                }

                if(this.state.currentStory.statusName != '撤稿'){
                    actButtons.push(saveButton);
                }

                // actButtons.push(previewButton);
                // actButtons.push(previewButton);
                if(!this.state.currentStory.linkurl){
                    actButtons.push(akeyLayout);
                }
            }
        }
        this.setState({
            actButtons: actButtons,
        })
    }

    //添加备注信息
    getText = (data, type, callback, activiti) => {
        activiti.callback = callback;
        var actText = new ActText();

        if (Activiti.util.isFunction(activiti.options.getChoose)) {
            actText.getText({title: "备注"}, "remarks", callback);
            // this.options.getText.call(this, data, type, callback);
        } else {
            actText.getText({title: "备注"}, "remarks", callback);
        }
    }

    //按钮的字数换行展示
    breakWorld(data) {
        if (data) {
            if (data.length > 5) {
                return <div>{data.substr(0, 4)}<br/>{data.substr(4)}</div>
            } else {
                return <div>{data}</div>
            }
        }
    }

    /**
     * 一键排版
     */
    akeyLayout() {
        setStoryFormat(this, 500,()=>{
            this.setAkeyLayout(500)
        });
    }

    setAkeyLayout(format){
        $('#csspath').attr('href','/cm-sites/style/typeset/format'+format+'.css');
        $('.w-e-text-container').attr('id','vsb_content_'+format);
    }

    componentDidMount() {
        this.initLoadStory();
        let currentMediaCode = getInfoFromLocalStorage('currentMediaCode');
        this.props.setCurrentMedia(currentMediaCode);
        allThat=this;
        //按钮列表的显示
        // let storyId = this.state.storyId;
        // this.getNeedShowButtonStoryId(storyId);

        //任务稿件无需选择栏目。
        if(this.state.fromPage==5){
            window.checkColumnFlag=false;
        }

        //监听插入任务图片
        eventProxy.on('insertTaskImg', res => {
            this.state.currentCustomInsert = 'insertTaskImg';
            this.setState({});
            console.log('insertTaskImg');
            /* let that=this;测试平台的
             this.SubjectSubmit.show(function(data){
                 let mediaCodes=[];
                 if(that.state.fromPage == 5&&data.length){
                     data.forEach(item=>{
                         mediaCodes.push(item.code)
                     });
                     console.log(mediaCodes.toString())

                 }
             })*/
            this.Upload.show(2)
        });
        //监听插入资源库图片
        eventProxy.on('insertZykImg', res => {
            this.state.currentCustomInsert = 'insertZykImg';
            this.setState({});
            console.log('insertZykImg');
            this.Upload.show(12)
        });
        //监听插入任务视频
        eventProxy.on('insertVideo', res => {
            this.state.currentCustomInsert = 'insertVideo';
            this.setState({});
            this.Upload.show(3);
            console.log('insertVideo')
        });
        //监听插入资源库视频
        eventProxy.on('insertZykVideo', res => {
            this.state.currentCustomInsert = 'insertZykVideo';
            this.setState({});
            console.log('insertZykVideo');
            this.Upload.show(13)
        })
    }

    uploadCallBack(data) {
        let currentCustomInsert = this.state.currentCustomInsert;
        switch (currentCustomInsert) {
            case "insertTaskImg":
                console.log("insertTaskImg", data);
                this.insertImg(data);
                break;
            case "insertZykImg":
                console.log("insertZykImg", data);
                this.insertImg(data);
                break;
            case "insertVideo":
                console.log("insertVideo", data);
                this.insertVideo(data);
                break;
            case "insertZykVideo":
                console.log("insertZykVideo", data);
                this.insertVideo(data);
                break;

        }
    }

    //需要理解原理后修改，不然勿动
    insertImg(data) {
        data.forEach(item => {
            $('.media-eleditor').append("<p style='text-align: center' class='Eleditor-image-area'><img style=\"max-width:65vw;\" src='" + item.path + "'/></p>");
        });
    }

    insertVideo(data) {
        data.forEach((item, index) => {
            $('.media-eleditor').append(`<p style="text-align: center;">
			<embed class="edui-upload-video" 
				src="/system/resource/swf/flowplayer.commercial.swf" 
				vsburl="${item.path}"
				vsbmp4video="true" 
				ue_src="${item.path}" 
				title="${item.name}" 
				width="420" 
				height="280" 
				align="none" 
				style="display: none" 
				autostart="false" 
				vsbautoplay="false" 
				controls="IMAGEWINDOW,ControlPanel,StatusBar" 
				console="Clip1">
        </p>`);
            $('.media-eleditor').append(
                '<div id="story_info_video_0" class="Eleditor-video-area" style="margin: auto;"><div playsinline="" max-height="200px" style="z-index: 1; position: relative; margin: auto; text-indent: 0px; width: 65vw; height: auto;">' +
                '<video src="' + item.path + '" preload="auto" controls="" style="width: 100%; height: auto;"></video></div></div>'
            )
        })
    }


    initLoadStory() {
        let that = this;
        if (this.state.storyId && (!this.state.noRefresh||!this.props.story.title)) {
            getStoryById({storyId: this.state.storyId}, function (story) {
                that.updateStory(story);
                if(story.format){
                    that.setAkeyLayout(story.format)
                }
            });
        } else {
            this.getNeedShowButtonStoryId(this.props.story)
        }
        if (this.state.noRefresh) {
            this.setState({noRefresh: false});
            this.props.setNoRefresh(false);
        }
    }

    updateStory(story) {
        this.setState({
            story: story
        });

        //赋值给  栏目属性；
        if (story.news) {
            story.chooseColumn = story.news;
        }
        this.props.setStory(story);
        // //如果是编辑稿件，需要将稿件的属性初始化到稿件属性中。
        let storyAttribute = {
            author: story.author,
            editer: story.editer,
            authorTel: story.authorTel,
            source: story.source,
            deptId: story.deptId,
            photography: story.photography,
            titlePic: story.titlePic,
            linkurl: story.linkurl,
        }
        this.props.setStoryAttribute(storyAttribute);

        //是否要弹出跳转至原文编辑弹出框
        if(this.checkIsNeedCopy(story.news)){
            this.setState({
                fcolumnStoryAlert:true,
            })
        }

        //稿件流程按钮,
        this.getNeedShowButtonStoryId(story)

    }

    /**
     * 稿件的，发布，撤回事件
     * @param buttonInfo
     */
    publisAndRevertAction(action) {

        if (action === '撤稿') {

            var formData = new FormData();
            formData.append("mediaCode", getInfoFromLocalStorage('currentMediaCode'));
            formData.append("businessIds", this.state.story.id);
            cancelDraft(formData, this.callCancelDraft.bind(this));

        } else if (action === '发布') {
            window.isPublish = true;

            //需要选栏目
            window.checkColumnFlag = true;
            //先保存，后发布
            this.saveStory(null, 'afterJS', this.publishStory.bind(this));

        } else if (action === '访问') {

            var formData = new FormData();
            formData.append("storyId", this.state.story.id);
            visitorDraft(formData, this.callvisitorDraft.bind(this));

        } else if (action === '预览') {
            this.previewStory();
        } else {
            Toast.fail("此功能，还在拼命开发中...");
        }
    }

    /**
     * 发布稿件
     */
    publishStory() {
        //稿件发布校验；
        if (!this.publishCheck()) {
            window.loading.hide();
            return;
        }
        //首先保存稿件。
        var param = {
            "mediaCode": getInfoFromLocalStorage('currentMediaCode'),
            "serviceId": this.state.story.id,
            "columns": JSON.stringify(this.state.story.chooseColumn),
            "userAccount": getInfoFromLocalStorage('userName')
        }
        var formData = new FormData();
        formData.append("reqDto", JSON.stringify(param));
        // publishDraft(formData, this.callpublishDraft.bind(this));
        publishDraftNews(formData, this.callpublishDraft.bind(this));

    }


    /**
     * 稿件发布校验；
     */
    publishCheck() {

        let message = null;
        let story = this.props.story;
        var rest = Activiti.util.syncPost("/cm-sites-app-h5/story/validateBindAdmin", {
            chooseColumns: JSON.stringify(story.chooseColumn)
        });

        console.info("chooseColumns++++++++", rest);
        if (!rest || rest == undefined) {
            Toast.fail("验证发布权限出错！");
            return false;
        }
        if (rest.code == undefined || rest.code < 0) {
            message = rest.msg;
            Toast.fail(message);
            return false;
        }

        //判断是否选择了当前的媒体
        // var checkStatus = this.checkChooseThisMedia(story.chooseColumn);
        // if (!checkStatus) {
        //     Toast.fail("请至少选择一个当前媒体的栏目！");
        //     return false;
        // }

        if (this.checkChooseAllJustUrlPublsh()) {
            Toast.fail("请至少选择一个非\"仅链接\"发布的网站！");
            return false;
        }
        return true;
    }

    /**
     * 发布的媒体是否选择了当前  媒体
     */
    checkChooseThisMedia(chooseColumn) {
        if (!chooseColumn || chooseColumn.length === 0) {
            return false;
        }

        var rest = Activiti.util.syncPost("/cm-sites-app-h5/website/loadCurrentMedia", {
            type: 1
        });

        if (!rest || rest.length === 0) {
            return false;
        }

        let mediaCodes = [];
        for (var j = 0; j < rest.length; j++) {
            for (var i = 0; i < chooseColumn.length; i++) {
                if (chooseColumn[i].owner == rest[j].owner) {   //不可以修改成成 == 数据后台返回的数据类型不一样
                    mediaCodes.push(rest[j].code);
                }
            }
        }

        if (mediaCodes.indexOf(this.state.currentMedia) >= 0) {
            return true;
        } else {
            return false;
        }

    }

    /**
     * 非连接的网站
     */
    checkChooseAllJustUrlPublsh() {

    }

    /**
     * 选择候选人；
     */
    getChoose = (data, type, callback, activiti) => {
        var actChoose = new ActChoose();
        return actChoose.getChoose(data, "users", callback);
    }

    /**
     * 撤稿成功
     */
    callCancelDraft(data) {
        if (data) {
            Toast.success("稿件撤稿成功");
        }
        this.goBack();
    }

    /**
     * 稿件发布成功
     * @param data
     */
    callpublishDraft(data) {
        window.loading.hide();
        if (data) {
            Toast.success("稿件发布成功");
        }
        this.goBack();
    }

    linkToUrl(url) {
        window.location.href = url;
    }

    /**
     * 保存稿件内容
     */
    saveStory(activiti, statusName, call) {
        let statusNameTemp = null;
        if (!checkStory('')) {
            window.loading.hide();
            return;
        }
        let params = saveStory();

        //如果是任务稿件，它的attribute就是原创稿件，
        if(this.state.fromPage==5){
            params.attribute='original';
        }

        //如果是资源库的
        if (fromZyk_G && this.state.storyId > 0) {
            let newStory = Activiti.util.syncPost("/cm-sites-app-h5/copyStoryController/copyStoryByStoryId", {
                storyId: this.state.storyId,
                usingMediaCode: this.state.currentMedia
            });
            if (newStory) {
                fromZyk_G = false;
                this.state.storyId = newStory.id;
                if (activiti) {
                    activiti.options.uuid = newStory.uuid;
                }
                params.id = newStory.id;
                params.uuid = newStory.uuid;
                params.resourceId = newStory.resourceId;
                params.statusName = "草稿";
            }
        }

        //开始转圈圈
        window.loading.show();


        // 先保存原状态名，将新的状态名称保存到js中
        // 先暂存原状态名，将新的状态名称暂存到js中
        params.statusName = this.state.currentStory.statusName || "草稿";
        params.statusUpdate = "";
        if (statusName == 'act') {
            statusNameTemp = activiti.options.statusNameTemp;
            if (statusNameTemp.indexOf("返回") > -1) {
                params.statusUpdate = "show";
            }
        } else {
            statusNameTemp = this.state.currentStory.statusName || "草稿";
        }

        params.dataSource = 1;

        params.interviewId = this.state.interviewId ? this.state.interviewId : 0;
        let that = this;
        $.ajax({
            type: 'post',
            url: saveStoryUrl,
            contentType: 'application/json; charset=UTF-8',
            dataType: 'json',
            //防止深度序列化
            traditional: true,
            data: JSON.stringify(params),
            success: function (data) {
                //关闭转圈
                that.state.storyId = data.data.id;
                //如果是保存按钮就无需走流程了
                if(!statusName){
                    if (!activiti && !window.isPublish) {
                        //关闭转圈
                        window.loading.hide();
                        that.goBack();
                    }
                }

                $.extend(true, params, data.data);
                params.statusName = statusNameTemp;
                that.state.story = data.data;
                that.props.setStory(data.data);
                if (typeof call == 'function') call(params);
            },
            error: function () {
                Toast.offline('操作失败', 1.5);
                // actButton.closeAll();
                if (typeof call == 'function') call(false);
            }
        });
    }

    /**
     * 稿件访问
     * @param data
     */
    callvisitorDraft(data) {
        // data=[{column:{treeName:'123'},url:'baidu.com'},{column:{treeName:'4563'},url:'baidu.com'}]

        if (data) {
            let column = data.map((item) => {
                return {text: item.column, onPress: () => this.linkToUrl(item.url)}
            });
            operation(
                column
            )
        } else {
            Toast.fail("无法加载文章访问地址，请联系管理员或尝试重新发布。");
        }
    }

    /**
     * 预览稿件内容
     */
    previewStory() {
        let path = "/previewAge/previewAge";
        var storyInfo = saveStory();
        sessionStorage.setItem("storyInfo", JSON.stringify(storyInfo));
        this.state.history().push(path);
    }


    /**
     * 返回按钮事件
     */
    goBack() {
        this.props.setStory({});
        //清空稿件属性
        this.props.setStoryAttribute({});
        this.props.setNoRefresh(true);
        this.props.history.goBack()
       /* let path = '/listTabPage/listTabPage'
        if (this.state.fromPage == 2) {
            //跳到采访任务页面；
            path = "/InterviewDetailPage/InterviewDetailPage/" + this.state.interviewId;
        } else if (this.state.fromPage == 3) {
            //跳到采访任务页面；
            path = "/manuscriptDetailPage/manuscriptDetailPage/" + this.state.appointmentId + '/' + this.state.appointmentReceiverId;
        } else if (this.state.fromPage == 4) {
            //跳到采访任务页面；
            path = "/resoucePoolPage/resoucePoolPage";
        } else if (this.state.fromPage == 5||this.state.fromPage == 6) {
            this.props.history.goBack();
        }
        this.state.history().push(path);*/
    }

    componentWillUnmount() {
        this.props.setToListPage(false);
        let actChooseAction = {
            data: [],
            type: "",
            show: false,
            callback: null,
        }
        this.props.setActChoose(actChooseAction);
        //卸载监听
        eventProxy.off('insertTaskImg', '');
        eventProxy.off('insertZykImg', '');
        eventProxy.off('insertVideo', '');
        eventProxy.off('insertZykVideo', '');
    }

    /**
     * 返回至写稿人
     */
    callBackSubmiiter = (activiti, buttonConf, endcall) => {
        let that = this;
        if (fromZyk_G) {
            if (this.state.currentStory.submitter) {
                $.post("/cm-sites-app-h5/user/getUserForActiviti", {account: this.state.currentStory.submitter}, function (res) {
                    if (res) {
                        activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [res];
                        endcall({code: 1, log: "资源池稿件 获取到的投稿人为：" + that.state.currentStory.submitter});
                        return;
                    } else {
                        endcall({code: -1, log: "资源池稿件 获取用户信息出错：" + that.state.currentStory.submitter});
                        return;
                    }
                }, "json")
            } else {
                // layer.alert("未获取到投稿人，返回失败！");
                endcall({status: false, msg: "未获取到投稿人，返回失败！"});
            }
        } else if ((this.state.currentStory.isCopyFlag == true || this.state.currentStory.isCopyFlag == "true") && this.state.currentStory.statusName == "草稿") {
            $.post("/cm-sites-pms/storyNew/getOldStoryId", {storyId: this.state.currentStory.id}, function (oldStoryId) {
                if (parseInt(oldStoryId) > 0) {
                    $.post("/cm-sites-pms/storyNew/show", {storyId: oldStoryId}, function (oldStory) {
                        if (oldStory && oldStory.statusName == "提交至宣传部") {
                            activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [oldStory.submitter];
                            endcall({code: 1, log: "提交至宣传部" + activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS});
                        }
                    }, "json")
                } else {

                }
            })
        }
    }

    //关闭审核意见
    closeAuditOpinions = key => () => {
        this.setState({
            [key]: null,
        });
        this.props.setAuditOpinions(null);
    }

    /**
     * 跳到其他属性页面
     */
    gotoOtherAttributePage() {
        if ($(".Eleditor-scrollLocked").length == 1) {
            return;
        }
        let path = "/storyAtrribute/storyAtrribute";
        this.state.history().push(path);
    }

    /**
     * 更新约稿人的状态
     */
    callBackTxyStatus() {
        // if(this.state.fromPage==3){
        //     var param={
        //         appointId:this.state.appointId,
        //         account:this.state.account,
        //         storyId:this.state.id,
        //     }
        // }
    }

    actInit(currentStory) {
        var canDo = true;
        let that = this;
        console.log('---------------',this)
        this.state.currentStory = currentStory;

        //勾选的栏目；
        let chooseColumn = this.props.story.chooseColumn;
        let activitiConfs = [];

        if (chooseColumn && chooseColumn.length > 0) {
            chooseColumn.map((item, index) => {
                let column = {
                    mediaTreeId: item.treeContentId,
                    activitiTypeEnum: activitiType_G,
                    mediaCode: item.mediaCode,
                    type: "WEBSITE",
                };
                activitiConfs.push(column);
                return column;

            })
        } else {
            let baselConf = {
                activitiTypeEnum: activitiType_G,
                type: "WEBSITE",
                mediaCode: this.state.currentMedia ? this.state.currentMedia : ''
            }
            activitiConfs.push(baselConf);
        }
       let againFlag=fromZyk_G|| (currentStory && currentStory.statusName == "草稿") || (currentStory && currentStory.attribute == "copyFromOriginal" && currentStory.statusName == "提交至平台");
        Activiti.createActivit({
                uuid: currentStory && currentStory.uuid ? currentStory.uuid : "",   //主键id
                pojoName: "story",
                userAccount: getInfoFromLocalStorage("userName"),
                againFlag: againFlag,
                showBtnFlag: mediaRoleColum.edit,
                activitiConf: activitiConfs,
                showAfterFuns: {   //按钮加载前置js
                    actCheckSJW: function (item, call) {
                        if (that.state.currentMediaCode == "" || that.state.currentMediaCode == "sjw")
                            call({code: 1, log: "有权限"});
                        else
                            call({code: 0, log: "没有权限"})
                    },

                    //撤回按钮显示前置js
                    callBackBtnShow: function (item, call) {
                        //不允许编辑
                        canDo = false;
                        call({code: 1, log: "有权限"});
                    },
                    doNotShowSaveBtn: function (item, call) {
                        that.state.showSaveBtn = false;
                        call({code: 1, log: "有权限"});
                    },
                    actFromZyk: function (item, call) {
                        if (fromZyk_G) {
                            call({code: 1, log: "资源池稿件"});
                        } else if ((currentStory.isCopyFlag == true || currentStory.isCopyFlag == "true") && currentStory.statusName == "草稿") {
                            Activiti.util.syncPost("/cm-sites-app-h5/storyNew/getOldStoryId", {storyId: currentStory.id}, function (oldStoryId) {
                                if (parseInt(oldStoryId) > 0) {
                                    Activiti.util.syncPost("/cm-sites-app-h5/storyNew/show", {storyId: oldStoryId}, function (oldStory) {
                                        if (oldStory && oldStory.statusName == "提交至宣传部") {
                                            call({code: 1, log: "提交至宣传部 oldStory.statusName"});
                                        }
                                    })
                                }
                            })
                        } else {
                            call({code: 0, log: "非资源池进入、非资源池保存的草稿！"});
                        }
                    },
                    /**
                     * 如果不是采访任务负责人才显示
                     * @param item
                     * @param call
                     */
                    notManager: function (item, call) {
                        var interviewId = that.state.interviewId;
                        if (!interviewId || interviewId == "0") {
                            call({code: -1, log: "未获取到采访任务信息，请重新打开再试！"})
                            return;
                        }
                        var url = "/cm-sites-app-h5/interview/getAllManager";
                        var params = {
                            "interviewId": interviewId
                        };
                        $.post(url, params, function (res) {
                            if (res) {
                                for (var i = 0; i < res.length; i++) {
                                    if (that.state.account == (res[i].account)) {
                                        if (typeof call == "function") {
                                            call({code: 0, log: "匹配到当前用户是采访任务负责人!"});
                                            return;
                                        }
                                    }
                                }
                                call({code: 1, log: "当前用户非采访任务负责人!"})
                                return;
                            }
                            call({code: -1, log: "获取当前用户在采访任务中的角色失败!"})
                            return;
                        }, "json");
                    },
                    publishCheck: function (item, call) {
                        if (mediaRoleColum.publish) {
                            window.loading.hide();
                            call({code: 1, log: "拥有发布权限"});
                        } else {
                            window.loading.hide();
                            call({code: 0, log: "未拥有发布权限"})
                        }
                    }
                },
                render: function () {

                },
                otherJs: {
                    //提交至刊发媒体
                    callSubmitActivity: function (activiti, buttonConf, call) {
                        //选择的媒体
                        var finishInterview = function (currentStory) {
                            //选媒体逻辑
                            //只有是任务写稿的稿件的时候才会调用，其余的不做操作；
                            if (that.state.fromPage == 5) {
                               // let that = this;
                                allThat.SubjectSubmit.show(function (data) {
                                    let mediaCodes = [];
                                    if (that.state.fromPage == 5 && data.length) {
                                        data.forEach(item => {
                                            mediaCodes.push(item.code)
                                        });
                                        window.loading.show();
                                        $.post("/cm-sites-app-h5/mobileInterview/finishInterview", {
                                            interviewId: this.state.interviewId || currentStory.interviewId,
                                            mediaCodes: mediaCodes.toString(),
                                            storyId: currentStory.id
                                        }, function (res) {
                                            if(mediaCodes &&  mediaCodes.length > 0 ){
                                                let story = saveStory();
                                                story.usingMediaCodes = mediaCodes;
                                                that.props.setStory(story);

                                            }
                                            window.loading.hide();
                                            if (res && (res == true || res == "true")) {
                                                call({
                                                    code: 1,
                                                    log: "执行finishInterview 成功！interviewId：" + (that.state.interviewId || currentStory.interviewId) + "，提交媒体：" + mediaCodes
                                                });
                                            } else {
                                                window.loading.hide();
                                                call({
                                                    code: -1,
                                                    log: "活动任务结束遇到问题，未能正确结束！interviewId：" + (that.state.interviewId || currentStory.interviewId)
                                                });
                                            }
                                        })

                                    }
                                })

                            }
                        }
                        //如果没暂存先暂存
                        if (!currentStory || !currentStory.interviewId) {
                            that.saveStory(null, "afterJS", function (data) {
                                finishInterview(data)
                            });
                        } else {
                            finishInterview(currentStory);
                        }
                    },
                    callSetDeptId: function (activiti, buttonConf, call) {
                        if (store.getState().storyReducer.story.deptId) {
                            activiti.auditorMapTemp.FORM_SERVICE_GROUP = [store.getState().storyReducer.story.deptId.toString()];
                            if (activiti.auditorMapTemp.FORM_SERVICE_GROUP) {
                                call({code: 1, log: "部门选择获取成功，获取到的为：" + activiti.auditorMapTemp.FORM_SERVICE_GROUP});
                            }
                        } else {
                            call({code: -1, msg: "请选择部门，如果没有选项，请联系管理员绑定部门信息。"})
                        }
                    },
                    callActRevert: function (activiti, buttonConf, call) {
                        activiti.terminate.call(activiti, function (res) {
                            if (res == true) {
                                activiti.options.statusNameTemp = "草稿";
                                // //如果是约稿的撤稿，需要修改 约稿人的 状态；
                                // that.callBackTxyStatus();

                                activiti.endFunction(activiti, function (res) {
                                    if (res.status == true) {
                                        Toast.success("流程已撤回，稿件变为草稿状态", 1, function () {
                                            activiti.submitOK();
                                        });
                                    }
                                })
                            }
                        });
                    },
                    setDeptId: function (activiti, buttonConf, call) {
                        activiti.actForm.users.FORM_SERVICE_GROUP == undefined ?
                            activiti.actForm.users.FORM_SERVICE_GROUP = [store.getState().storyReducer.story.deptId]
                            : activiti.actForm.users.FORM_SERVICE_GROUP.push(store.getState().storyReducer.story.deptId);
                        call({code: 1, log: "部门信息" + activiti.actForm.users.FORM_SERVICE_GROUP});
                    },
                    setInterviewFzr: function (activiti, buttonConf, call) {
                        var interviewId = that.state.interviewId
                        if (!interviewId || interviewId == "0") {
                            call({code: -1, msg: "未获取到采访任务信息，请重新打开再试！"})
                            return;
                        }
                        $.post("/cm-sites-app-h5/interview/getAllManager", {
                            interviewId: interviewId
                        }, function (resultData) {
                            if (!resultData) {
                                call({code: -1, msg: "获取任务负责人失败！请联系负责人"});
                                return;
                            }
                            var managerData = [];
                            resultData.forEach(function (element) {
                                managerData.push({
                                    id: element.account,
                                    name: element.name,
                                    englishName: element.englishName
                                });
                            });
                            if (managerData.length == 0) {
                                call({code: -1, msg: "未获取到采访任务负责人信息"})
                                return;
                            }
                            activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = managerData;
                            call({code: 1, log: "获取到的负责人为" + managerData});
                            return;
                        }, "json");
                    },
                    setAppointmentBJ: function (activiti, buttonConf, call) {
                        $.post("/cm-sites-app-h5/appointment/getPublisherByAppointmentReceiverId", {
                            appointmentReceiverId: that.state.appointmentReceiverId,
                            storyId: that.state.storyId
                        }, function (resultData) {
                            if (resultData && resultData.selectuser) {
                                activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [{id:resultData.selectuser,name:resultData.selectuser}];
                                call({code:1,log:"查询到约稿发布人为" + JSON.stringify(resultData)});
                            } else {
                                call({code:-1,msg:"未查询到约稿负责人信息！稿件id:" + that.state.storyId + "约稿id appointmentReceiverId：" + that.state.appointmentReceiverId})
                            }
                        }, "json");
                    },
                    /**
                     * 选择我自己
                     * @param activiti
                     * @param buttonConf
                     * @param call
                     */
                    chooseMe: function (activiti, buttonConf, call) {
                        let userInfo = getInfoFromLocalStorage("userInfo");
                        activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = [{
                            id: userInfo.account,
                            name: userInfo.name
                        }];
                        call({code: 1, log: "chooseMe：下一流程 流转至当前用户！"});
                    },
                    doNotCheckColum: function (activiti, buttonConf, call) {
                        activiti.options.closeServiceTemp.push(function () {
                            window.checkColumnFlag = true;
                        });
                        window.checkColumnFlag = false;
                        call({code: 1, log: "设置checkColumnFlag=true 成功！"});
                    },
                    callOtherWriter: function (activiti, buttonConf, call) {
                        $.post("/cm-sites-app-h5/interview/getAccountsByInterviewIdAndInterviewRole",{
                            interviewId:that.state.interviewId || that.state.currentStory.interviewId,
                            interviewRole:1
                        },function(res){
                            if(res && res.length > 0){
                                activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS = []
                                $.each(res,function(index,item){
                                    activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS.push({
                                        id : item
                                    })
                                })
                                call({code: 1, log: "获取到的其他写稿人：" +  JSON.stringify(activiti.options._ACT_AUDIOTR_MAP_FORM_SERVICE_USERS)});
                            }else{
                                call({code: -1, log: "未查询到此活动文字写稿人，转交失败！"});
                            }
                        },"json")
                    },
                    callBackSubmiiter: function (activiti, buttonConf, call) {
                        that.callBackSubmiiter.call(that, activiti, buttonConf, call);
                        call({code: 1, log: "callBackSubmiiter"});
                    },
                    /**
                     * 提交采访任务稿件  推进任务
                     */
                    callSubmitInterviewStory: function (activiti, buttonConf, call) {
                        let currentStory = that.state.currentStory;
                        let interviewId = that.state.interviewId;
                        that.saveStory(null, "afterJS", function (data) {
                            if ((currentStory && currentStory.interviewId) || interviewId) {
                                var interviewIdTemp = interviewId || currentStory.interviewId;
                                $.post("/cm-sites-app-h5/interview/submitInterviewStory", {interviewId: interviewIdTemp}, function (res) {
                                    if (res && res.data == true) {
                                        call({code: 1, log: "任务状态更新成功：interviewId:" + interviewIdTemp});
                                    } else {
                                        alert("任务状态更新失败" + (res.msg ? ("(" + res.msg + ")") : "") + "，是否继续提交稿件？", [
                                            {
                                                text: '取消', onPress: () => {
                                                }
                                            },
                                            {
                                                text: '确实', onPress: () => {
                                                    call({
                                                        code: 1,
                                                        log: "任务状态更新失败，但用户任然选择继续提交稿件：interviewId:" + interviewIdTemp
                                                    })
                                                }
                                            },
                                        ])
                                    }
                                }, "json");
                            } else {
                                call({code: 0, log: "非任务稿件，直接跳过:storyId:" + currentStory.id});
                            }
                        });
                    },
                },
                success: function (activiti) {   //对象创建成功回调
                    //重写推进前保存方法
                    activiti.afterSave = function (activiti, call) {
                        // saveDraft(activiti,"act",call);
                        that.saveStory.call(that, activiti, "act", call);
                    }
                    activiti.getActivitiKey = function (activitiConf, call) {
                        activiti.post("/cm-sites-app-h5/mediaTreeActiviti/getByconf", JSON.stringify(activitiConf), function (res) {
                            if (res && res.activitiApp && res.activitiApp.key) {
                                call(res.activitiApp.key);
                            } else {
                                call(false);
                            }
                        }, "json")
                    }

                    //重写显批注信息
                    activiti.showRemarks = function (remarks) {
                        console.info("+++++++++++++++", remarks);
                        that.setState({
                            auditOpinions: true,
                            auditOpinionInfo: remarks
                        })
                    }
                    //重写创建按钮的方法
                    activiti.renderButton = function (buttons = [], call) {
                        that.renderButtons(buttons, call, activiti, activitiConfs)
                    }

                    //填写备注信息
                    activiti.getText = function (data, type, callback) {
                        that.getText(data, type, callback, activiti)
                    }
                    activiti.load = {
                        start: function () {
                            window.loading.show();
                        },
                        end: function () {
                            window.loading.hide();
                        }
                    }
                    activiti.getChoose = function (data, type, callback) {
                        that.getChoose(data, type, callback, activiti);

                    }
                    activiti.endFunction = function (activiti, call) {  //流程推进后的回调一般用作推进后修改状态
                        //保存状态
                        var url = "/cm-sites-app-h5/storyNew/saveStatusName";
                        $.post(url, {
                            id: that.state.story.id,
                            statusName: activiti.options.statusNameTemp,
                            appointmentReceiverId: that.state.appointmentReceiverId,
                            interviewId: that.state.interviewId
                        }, function (data) {
                            if (data.code == 200) {
                                call({status: true, msg: data.msg});
                            } else {
                                call({status: false, msg: "流程推进后状态修改失败。"});
                            }
                        }, "json");
                    }
                    activiti.error = function (res) {     //流程推进成功后的提示点击确定的回调，一般用来关闭窗口或刷新
                        Toast.fail(res, 1);
                    }
                    activiti.successMsg = function (res, call) {
                        Toast.success(res, 1, call);
                    }
                    activiti.submitOK = function (call) {
                        that.goBack();
                    }
                },
            }
            , function () {
                //流程初始化完成之后的回调
                if (this.buttonList && this.buttonList.length > 0 && canDo) {
                    // $("#btnArea").append("<button  id='saveButton' onclick='saveButtonClick()' type=\"button\" class=\"funButton\">保存</a>")
                    // that.showButtonListActionSheet.call(that,this.buttonList);
                }
                if (currentStory.statusName == "草稿" && currentStory.isCopyFlag == true) {
                    $.post("/cm-sites-app-h5/storyNew/getOldStory", {storyId: currentStory.id}, function (oldStory) {
                        if (oldStory && oldStory.uuid) {
                            this.getHistory(function (history) {
                                // loadHistory(history)
                            }, oldStory.uuid);
                        }
                    })
                } else {
                    this.getHistory(function (history) {
                        // loadHistory(history)
                    })
                }

            })
    }

    /**检测如果不是主栏目，是否需要复制该文章*/
    checkIsNeedCopy(syncColumns) {
        let currentColumns=getInfoFromLocalStorage("currentMediaColumn");
        // 这里应该是漏掉了未设置栏目的判断
        if (!syncColumns || syncColumns.length == 0||!currentColumns||currentColumns.length==0) {
            return false;
        }
        let currentColumn=currentColumns[0];
        let isMain = true;
        let mainColumn;
        for (let i = 0; syncColumns && i < syncColumns.length; i++) {
            let tempNews = syncColumns[i];
            if (tempNews.mainColumn == 1) {
                mainColumn = tempNews;
            }
            if (tempNews.mediaCode == currentColumn._mediaCode && tempNews.treeContentId == currentColumn.treeContentId) {
                currentColumn = tempNews;
                if (tempNews.mainColumn != 1) {
                    isMain = false;
                }
            }
        }

        let fcolumnStoryAlertContent="检测到该文章是在/"+mainColumn.mediaName+"/"+mainColumn.treeName+"栏目下发布并同时发布至当前栏目中的，是否要跳转至此栏目下进行编辑？";
        this.setState({
            fcolumnStoryAlertContent
        });

        if (!isMain && (currentColumn.deliveryWay == 0||currentColumn.deliveryWay == 1)){//从主栏目链接形式发布过来的)
            return true;
        }else{
            return false;
        }
    }

    render() {
        return (<div style={{ flexDirection: 'column', height: '100vh'}}>
                <NavBar icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                        mode="light"
                        leftContent={[
                            <span key={uuid()} className={'nav_goback'} onClick={() => this.goBack()}>返回</span>
                        ]}
                        rightContent={[
                            <div key={uuid()} className={'nav_goback'} style={{color: '#1890ff'}}
                                 onClick={this.gotoOtherAttributePage}>更多信息></div>,
                            // ellipsisButton,

                        ]}
                >
                    <span className={'nav_title'}>{this.state.pageName}</span>
                </NavBar>            
                <EditorView storyId={this.state.storyId} bindId={this.state.interviewId} story={this.state.story}></EditorView>
                <Remarks/>
                {/*<div className={'am-list-item'} style={{textAlign: "right"}}>*/}
                {/*    <div style={{width: '100%', marginRight: '20px'}}>*/}
                {/*        /!*<div style={{color: 'blue'}} onClick={this.gotoOtherAttributePage}>稿件其他属性></div>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}
                {
                    (this.state.story&&(this.state.story.storyType === 1 || this.state.story.storyTypeStr === 'PIC'))?'':
                    <div className={'button_panel'}>
                        <div style={{
                            width: this.state.actButtons.length * 100 + 'px',
                            display: 'flex',
                            padding: '0px 12px'
                        }}>
                            {this.state.actButtons}
                        </div>
                    </div>
                }

                <Modal
                    popup
                    visible={this.state.auditOpinions}
                    onClose={this.closeAuditOpinions('auditOpinions')}
                    animationType="slide-up"
                    afterClose={() => {
                    }}>
                    <List renderHeader={() => <div>审核信息</div>} className="popup-list">
                        <List.Item wrap={true}>{'意见:' + this.state.auditOpinionInfo}</List.Item>
                        <List.Item>
                            <Button type="primary" onClick={this.closeAuditOpinions('auditOpinions')}>确定</Button>
                        </List.Item>
                    </List>
                </Modal>

                <Modal
                    visible={this.state.fcolumnStoryAlert}
                    transparent
                    maskClosable={false}
                    title=""
                    footer={[{
                        text: '确定', onPress: () => {
                            this.setState({
                                fcolumnStoryAlert:false
                            })
                        }
                    },
                        {
                            text: '取消', onPress: () => {
                               this.goBack();
                            }
                        }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}
                >
                    <div >
                        <div>{this.state.fcolumnStoryAlertContent}</div>
                    </div>
                </Modal>
               {/* <Upload ref={Upload => {
                    this.Upload = Upload
                }} isUoLoad={false} bindId={this.state.interviewId} choose={'more'} onOkClick={(data) => {
                    this.uploadCallBack(data)
                }}/>*/}
                <SubjectSubmit ref={SubjectSubmit => this.SubjectSubmit = SubjectSubmit}
                               bindId={this.state.interviewId}/>
            </div>
        );
    }
}


const stateToProps = (state) => {
    return {
        story: state.storyReducer.story,
        storyId: state.storyReducer.storyId,
        noRefresh: state.storyReducer.noRefresh,//是否不刷新
        auditOpinions: state.storyReducer.auditOpinions, //是否弹出审核意见
        userInfo: state.storyReducer.userInfo,
        currentMediaCode: state.storyReducer.currentMediaCode,
        storyAttribute: state.storyReducer.storyAttribute,
        actChoose: state.storyReducer.actChoose,
        toListPage: state.storyReducer.toListPage,
        contributeRoleFlag: state.storyReducer.contributeRoleFlag,
    }
}


const dispatchToProps = (dispatch) => {
    return {
        setFormat: function (format) {
            dispatch(setFormat(format));
        },
        setContent: function (content) {
            dispatch(setContent(content));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttribute) {
            dispatch(setStoryAttribute(storyAttribute));
        },
        setNoRefresh: function (noRefresh) {
            dispatch(setNoRefresh(noRefresh));
        },
        setToListPage: function (toListPage) {
            dispatch(setToListPage(toListPage));
        },
        setActChoose: function (actChoose) {
            dispatch(setActChoose(actChoose));
        },
        setAuditOpinions: function (auditOpinions) {
            dispatch(setAuditOpinions(auditOpinions));
        },
        setCurrentMedia: function (mediaCode) {
            dispatch(setCurrentMedia(mediaCode));
        },
        setDataSource: function (mediaCode) {
            dispatch(setDataSource(mediaCode));
        },
    }
}
export default connect(stateToProps, dispatchToProps)(WriteStoryNew);
