import React from "react";
import {ListView, Toast} from "antd-mobile";
import {getAllData, getContributeAllData, getColumnData, getContributeData} from "../action";
import {Link} from "react-router-dom";
import {formatDate, isStringEmpty} from "../utils";
import {NoDataView} from "./NoDataView";
import {connect} from "react-redux";
import config from "../config";
import {setStoryId} from "../store/actions";
import SearchBar from "antd-mobile/es/search-bar";


class DraftListViewData extends React.Component {


    NUM_ROWS = 10;
    pageIndex = 1;
    rData=[];

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            dataStatus: '加载中...'
        };
    }


    updateStoryData(data) {
        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }
        ;

        //根据返回的数据量确定渲染的行数；
        this.NUM_ROWS = data.data.length;
        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            dataStatus: data.count > 0 ? '加载完成' : '暂无数据'
        })
        //自增起始位置
        this.pageIndex++;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.isContributeRoleFlag) {
        } else {

            this.rData=[];
            let data = new FormData();
            data.append('start', 1);
            data.append('size', this.state.pageSize);
            // data.append('currentMedia', nextProps.mediaCode);
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'zancun');
            data.append('treeId', this.props.treeId);
            data.append('name', '草稿箱');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }
    }

    componentWillMount() {
        this.getPageListData();
    }

    getPageListData() {
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);

        if (this.props.isContributeRoleFlag) {
            data.append('statusName', '草稿');
            getContributeData(data, this.updateStoryData.bind(this));
        } else {
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'zancun');
            data.append('treeId', this.props.treeId);
            data.append('name', '草稿箱');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    onEndReached = (event) => {
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        this.setState({isLoading: true});
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
                isLoading: false
            })
            return;
        }

        Toast.loading('加载中...', 0, () => {
        });
        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        if (this.props.isContributeRoleFlag) {
            data.append('statusName', '草稿');
            getContributeData(data, this.updateStoryData.bind(this));
        } else {
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'zancun');
            data.append('treeId', this.props.treeId);
            data.append('name', '草稿箱');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }
        ;
    }


    onChange=(value)=>{
        this.setState({ search:value });
    };
    onCancel=()=>{
        this.setState({ search:'' },()=>{
            this.submitSearch('')
        });
    }

    submitSearch=(value)=>{
        //重新请求后台获取数据
        this.rData =[];
        let data = new FormData();
        data.append('start', 1);
        data.append('size', 10);
        data.append('queryStr',value);
        if (this.props.isContributeRoleFlag) {
            data.append('columnType', 'my');
            getContributeAllData(data, this.updateStoryData.bind(this));
        } else {
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'zancun');
            data.append('treeId', this.props.treeId);
            data.append('name', '草稿箱');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }
    }

    render() {

        const row = (rowData, sectionID, rowID) => {
            const obj = rowData;

            let configElement = config['dataSource2ActButtonId'];
            let  actbuttonId=configElement[rowData.dataSource];
            return obj ? (
                <div>
                    <Link to={'/writeStory/' + obj.id + '/0' + '/'+actbuttonId+'/1'}>
                        <div key={rowID} style={{padding: '15px'}}>
                            <div
                                style={{
                                    fontSize: '4vw',
                                    // fontWeight:700,
                                    color: 'black',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.title}}></div>
                            <div
                                style={{
                                    lineHeight: '20px',
                                    color: '#888',
                                    fontSize: '3.5vw',
                                    wordBreak: 'break-all',
                                    marginTop: 8
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.summary) ? '' : obj.summary}}></div>
                            <div>
                                 <div style={{
                                     textAlign: 'right',
                                     color: '#888',
                                     fontSize: '12px',
                                    marginBottom: '10px'
                                 }}>{formatDate(obj.changeDate ? (obj.changeDate.time ? obj.changeDate.time : '') : '')}</div>
                                <div>
                                    <div className={'cardBootm'}>
                                        <div
                                            key={`${sectionID}-${rowID}`}
                                            style={{
                                                backgroundColor: '#F5F5F9',
                                                height: 0.5,
                                                borderBottom: '1px solid #ECECED',
                                                marginTop: 15,
                                                marginBottom: 11,
                                                // borderTop: '1px solid #ECECED',
                                            }}
                                        />
                                        <div>
                                            <div style={{paddingTop: '10px', color: '#888', fontSize: '12px',paddingBottom:10}}>
                                                <span style={{float:'left'}}>{obj.submitterName?obj.submitterName:obj.submitter}</span>
                                                <span style={{
                                                    float: 'right',
                                                    color: '#888',
                                                    fontSize: '12px',
                                                    marginLeft:'27vw'
                                                }}>状态: {obj.statusName}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>) : <div></div>;
        };

        return <div><SearchBar
            value={this.state.search}
            placeholder="输入稿件名称"
            onSubmit={this.submitSearch}
            onClear={value => console.log(value, 'onClear')}
            onFocus={() => console.log('onFocus')}
            onBlur={() => console.log('onBlur')}
            onCancel={this.onCancel}
            onChange={this.onChange}
        />{ this.state.count > 0 ? (
            <div>
                <ListView
                    initialListSize={0}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '加载完成'}
                    </div>)}
                    // renderBodyComponent={() => <MyBody />}
                    renderRow={row}
                    // renderSeparator={separator}
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    pageSize={5}
                    onScroll={() => {
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </div>
        ) : <NoDataView content={this.state.dataStatus}/>}</div>;
    }
}

/**
 * 将state属性值映射到props
 * @param state
 * @returns {{storyId: *}}
 */
const stateToProps = (state) => {
    return {
        storyId: state.storyReducer.storyId,
        currentMediaCode: state.storyReducer.currentMediaCode
    }

}

const dispatchToProps = (dispatch) => {
    return {
        setStoryId: (storyId) => {
            dispatch(setStoryId(storyId))
        }
    }

}

export default connect(stateToProps, dispatchToProps)(DraftListViewData);
