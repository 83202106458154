/**
 * created by mgg
 *
 */
import { request } from '../utils';
import {baseUrl, post} from "./index";
let host = '/cm-sites-app-h5/';

const Apis = {
    getCurrentUser: host + 'mobileUser/getCurrentUser', //获取用户
    getDeptListByIds  : host + 'mobileUser/getDeptListByIds', //获取部门
    getRoleListByCodes: host + 'mobileUser/getRoleListByCodes', //获取角色列表
    uploadPic: host + 'mobileUser/uploadPic', //上传用户头像
};



export const getCurrentUser = ( callBack) => {  //获取用户
    request(Apis.getCurrentUser,'get',{}, callBack)
};

export const getDeptListByIds = (params, callBack) => {   //获取部门
    request(Apis.getDeptListByIds,'get', params, callBack)
};

export const getRoleListByCodes = (params, callBack) => {  //获取角色列表
    request(Apis.getRoleListByCodes,'get', params, callBack)
};

export const uploadPic = (params, callBack) => {  //上传用户头像
    post(Apis.uploadPic, params, callBack);
};
