/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TaskListItem from '../../components/taskListItem/TaskListItem';
import {Icon, ListView, NavBar, Toast, WingBlank} from 'antd-mobile';

import *as Actions from "../../action/taskManagement"
import DropDownList from '../../components/dropDownList/DropDownList'
import SubjectSubmit from '../../components/subjectSubmit/subjectSubmit';
import {uuid} from "../../utils";




export default class TaskManagement extends Component {
    rData=[];
    pageIndex = 1;
    static defaultProps = {};
    static propTypes = {

    };

    constructor(props) {
        super(props);

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            dataSource:dataSource,
            height: document.documentElement.clientHeight-95,
            serviceList: [{title: '全部类型'},{title: '新闻报道'}, {title: '选题策划'}],
            taskList:[{title: '全部状态',status: 6},{title: '未领取',status:1}, {title: '进行中',status: 2}, {title: '已提交',status: 3}, {title: '已完成',status: 4}, {title: '已超期',status: 5}],
            page:0,
            pageSize:10,
            count:0,
            type:'',
            status:'',
            hasMore: true,
            taskType:'全部类型',
            taskStatus:'全部状态',
            key:''
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.getTaskList();
    }
    refresh(){
        this.pageIndex = 1;
        this.rData =[];
        this.getTaskList()
    }
    render() {
        const row = (rowData,sectionID, rowID) => {
            const obj = rowData;
            return obj ? (
                <div style={{background:'#f2f2f2'}} key={uuid()}>
                     <TaskListItem refresh={()=>{this.refresh()}}
                                   goToPage={()=>{this.goToPage(rowData)}}
                                   key={rowID}  title={rowData.title}
                                   type={rowData.leixing}
                                   state={rowData.status}
                                   content={rowData.content}></TaskListItem>
                </div>):<div></div>;
        };
        return (
             <div>
                 <NavBar
                     key={'任务管理'}
                     mode={'light'}
                     icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                     leftContent={[
                         <div className={'nav_goback'}  onClick={()=>this.props.history.goBack()} key={uuid()}>返回</div>
                     ]}

                 ><span  className={'nav_title'}>任务管理</span></NavBar>
                <div style={{display:'flex',height:'12vw'}} >
                    <div style={{width:'50%'}}>
                        <DropDownList list={this.state.serviceList} title={this.state.taskType} selectClick={(e)=>this.selectService(e)} ></DropDownList>
                    </div>
                    <div style={{width:'50%'}}>
                        <DropDownList list={this.state.taskList} title={this.state.taskStatus} selectClick={(e)=>this.selectStatus(e)} idKey={'status'}></DropDownList>
                    </div>
                </div>

                    {this.state.count >0?
                        <WingBlank style={{margin:'5px'}}>
                        <ListView key={this.state.key}
                        initialListSize={this.state.count}
                        ref={el => this.lv = el}
                        dataSource={this.state.dataSource}
                        renderFooter={() => (<div style={{padding:5,lineHeight:0, textAlign: 'center'}}>
                            {this.state.isLoading ? '加载中...' : '加载完成'}
                        </div>)}
                        renderRow={row}
                        style={{
                            height: this.state.height,
                            overflow: 'auto',
                        }}
                        pageSize={this.state.pageSize}
                        onScroll={() => {
                        }}
                        scrollRenderAheadDistance={500}
                        onEndReached={this.onEndReached}
                        onEndReachedThreshold={10}
                    />
                        </WingBlank>
                    :<div style={{textAlign:'center',marginTop:100,color:'#999999'}}>暂无数据</div>}
                 {/*<SubjectSubmit  ref={SubjectSubmit=>this.subjectSubmit=SubjectSubmit} bindId={'267'}/>*/}
            </div>
        )
    }

    onChange = (key) => {
    };

    goToPage(item){
        // this.subjectSubmit.show()
       this.props.history.push('/taskDetails/taskDetails/'+item.id)
    }
    //获取列表
    getTaskList(){
        let params={
            page:this.pageIndex,
            pageSize:this.state.pageSize,
            leixing:this.state.type,
            status:this.state.status
        };
        Actions.taskList( params,(data) => {
            //根据返回的数据量确定渲染的行数；
            Toast.hide();
            //请求后台异常。
            if (!data) {
                this.setState({
                    isLoading: false,
                    count: 0,
                    dataStatus: "服务异常"
                });
                return;
            }
            //根据返回的数据量确定渲染的行数；

            this.rData = [...this.rData, ...data.data];
            this.setState({
                data: data.data,
                count: data.count,
                dataSource: this.state.dataSource.cloneWithRows(this.rData),
                isLoading: false,
                hasMore: true,
                pageSize:10,
                dataStatus: data.data.length > 0 ? '加载完成' : '暂无数据'
            });
            ++this.pageIndex;
        })
    }
    //更改服务类型
    selectService(e){
        this.state.serviceList.forEach((item=>{
            if(item.title === e){
                this.state.taskType = item.title;
                this.setState({})
            }
        }));
        if(e !=='全部类型'){
            this.state.type=e;
        }else{
            this.state.type='';
        }
        this.state.key = uuid();
        this.setState({})
        this.pageIndex = 1;
        this.pageSize=10;
        this.rData =[];
        this.getTaskList()
    }
    //更改服务状态
    selectStatus(e){
        this.state.taskList.forEach((item=>{
            if(item.status === e){
                this.state.taskStatus = item.title;
                this.setState({})
            }
        }));
        if(e !== 6){
            this.state.status=e;
        }else{
            this.state.status='';
        }

        this.state.key = uuid();
        this.setState({})
        this.pageIndex = 1;
        this.pageSize=10;
        this.rData =[];
        this.getTaskList()
    }
    onEndReached = (event) => {
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {

            this.setState({
                hasMore: false,
            });
            return;
        }

        this.setState({isLoading: true});

        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        this.getTaskList()
    }
}