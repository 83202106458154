import React, {Component} from 'react'
import {Icon, NavBar, Toast, WhiteSpace} from "antd-mobile/lib/index";
import {uuid} from "../../../utils";
import {
    getInfoFromLocalStorage, getInterviewInfoById,
    getNewClueInfoById,
    getNewCluePicFilesById,
    getNewClueVideoFilesById, interviewHaveOperation
} from "../../../action";
import WingBlank from "antd-mobile/es/wing-blank/index";
import InterviewCardForm from "../../../components/InterviewCard";
import NewClueDetailCardForm from "../../../components/NewClueDetailCard";

/**
 * 新增采访任务页面
 */
class AddInterviewPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            interviewInfo: {title: '', contacts: '', contactsWay: ''},
            newClueId: this.props.match.params.newClueId,
            interviewId: this.props.match.params.interviewId
        }
    }

    goBackTimeout;

    goBack() {
      this.goBackTimeout=  setTimeout(()=> {
            var isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
            var goBack = getInfoFromLocalStorage('goBack');
            var url = '/interviewTask/interviewTask/' + isContributeRoleFlag +
                '/' + goBack;
            if (this.state.newClueId&&this.state.newClueId !== "0") {
                url = '/newsCluePage/newsCluePage/' + isContributeRoleFlag +
                    '/true';
            }
            this.state.history.push(url);
        },100);

    }

    componentWillUnmount() {
        clearTimeout(this.goBackTimeout.clear);
    }

    componentWillMount() {

        //线索转采访任务需要用到的
        if(this.state.newClueId&&this.state.newClueId!=0){

            var param = {"id": this.state.newClueId};
            getNewClueInfoById(param, this.updateNewClueInfo.bind(this));

            getNewCluePicFilesById(param, this.updateNewCluePicFile.bind(this));

            getNewClueVideoFilesById(param, this.updateNewVideoInfo.bind(this));
        }

        //撤回二次发布
        if(this.state.interviewId&&this.state.interviewId!=0){
            var param = {"interviewId": this.state.interviewId};
            getInterviewInfoById(param, this.updateInterViewInfo.bind(this));
        }
    }


    updateInterViewInfo(data) {
        if (!data) {
            Toast.fail("数据异常");
        }
        this.setState({
            interviewInfo: data
        })
    }

    updateNewCluePicFile(data) {
        if (!data) {
            return
        }
        for (var i = 0; i < data.length; i++) {
            data[i].url = data[i].path;
        }

        this.setState({
            picFiles: data
        })
    }


    updateNewVideoInfo(data) {
        if (!data) {
            return
        }
        for (var i = 0; i < data.length; i++) {
            data[i].url = data[i].path + '/thumbnails/1';
        }

        this.setState({
            videoFiles: data
        })

    }

    updateNewClueInfo(data) {
        if (!data) {
            return;
        }

        let interVidewInfo = {
            contactsWay: data.contactsWay,
            contacts: data.contacts,
            title: data.title,

        };
        this.setState({
            interviewInfo: interVidewInfo
        })
    }

    //提交采访任务数据
    submitData() {
        this.child.submitData();
    }

    render() {
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'}  onClick={() => this.goBack()} key={uuid()}>返回</div>
                ]}

                rightContent={[
                    <div style={{color: '#2d2d2d'}}  key={uuid()} onClick={() => {
                        this.submitData()
                    }}>提交</div>
                ]}
            ><span  className={'nav_title'}>采访</span></NavBar>
            {/*<WheelPlanting></WheelPlanting>*/}
            <WingBlank style={{margin:'5px'}}>
                <InterviewCardForm onRef={(child) => {
                    this.child = child
                }} goBack={this.goBack.bind(this)} videoFiles={this.state.videoFiles} picFiles={this.state.picFiles}
                                   newClueId={this.state.newClueId}
                                   interviewInfo={this.state.interviewInfo}></InterviewCardForm>
            </WingBlank>
        </div>
    }
}

export default AddInterviewPage;
