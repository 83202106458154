import React, {Component} from 'react';
import ActButton from "../../../components/activity/ActButton";
import {ListView, Toast} from "antd-mobile";
import {getInfoFromLocalStorage, post} from "../../../action";
import {Link} from "react-router-dom";
import {formatDate, isStringEmpty} from "../../../utils";
import {NoDataView} from "../../../components/NoDataView";

/**
 * 约稿列表页面
 */

class ManuscriptList extends Component {
    pageIndex = 1;

    actButton = new ActButton();
    rData=[];
    constructor(props) {
        super(props);
        this.initListData();
    }

    initListData = () => {
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            dataStatus: '加载中...',
            statusCode2Name: {1: "未领取", 2: "进行中", 3: '已完成', 4: '暂存', 6: '已拒绝', 99: '已拒绝'},

        };
    };


    updateInterviewData(data) {
        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            });
            return;
        }

        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData,...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            pageSize: 10,
            dataStatus: data.data.length > 0 ? '加载完成' : '暂无数据'
        });
        ++this.pageIndex;
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    componentWillMount() {
        this.getPageListData();
    }

    componentWillUnmount() {
    }

    getPageListData() {
        Toast.loading('加载中...', 0, () => {
        });

        let data = this.getFormData(1);
        post(this.props.dataUrl, data, this.updateInterviewData.bind(this));
    }

    onEndReached = (event) => {
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }

        Toast.loading('加载中...', 0, () => {
        });
        this.setState({isLoading: true});

        let data = this.getFormData(this.pageIndex);
        post(this.props.dataUrl, data, this.updateInterviewData.bind(this));
    };

    /**
     * 封装请求参数
     * @param start
     * @param pageSize
     * @returns {FormData}
     */
    getFormData(start) {
        //重新请求后台获取数据
        let data = new FormData();
        switch (this.props.currentTabIndex) {
            case 0:
                data.append('status', -1);
                break;
            case 1:
                data.append('status', 1);
                break;
            case 2:
                data.append('status', 2);
                break;
            case 3:
                data.append('status', 3);
                break;
            default:
                data.append('status', -1);

        }
        data.append('start', start);
        data.append('size', this.state.pageSize);
        return data;

    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            const obj = rowData;
            let appointmentId = 0;
            let reviceId = 0;
            if (this.state.isContributeRoleFlag) {
                appointmentId = obj.appointmentId;
                reviceId = obj.id;
            } else {
                appointmentId = obj.id;
            }
            return obj ? (
                <div>
                    <Link to={'/manuscriptDetailPage/manuscriptDetailPage/' + appointmentId + '/' + reviceId}>
                        <div key={rowID} style={{padding: '15px'}}>
                            <div style={{
                                fontSize: '4vw',
                                color: '#000000'
                            }}>回稿期限:{formatDate(obj.replyDate ? obj.replyDate.time : '')}</div>
                            <div style={{
                                fontSize: '4vw',
                                color: '#000000',
                                marginTop: '10px'
                            }}>回稿要求:{obj.requireContent}</div>
                            <div
                                style={{
                                    fontSize: '4vw',
                                    color: 'black',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.title}}></div>
                            <div
                                style={{
                                    lineHeight: '30px',
                                    color: '#888',
                                    fontSize:'4vw',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.wbsummary) ? '' : obj.wbsummary}}></div>
                            <div>
                                <div className={'cardBootm'}>
                                    <div style={{
                                        textAlign: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                        paddingBottom: '10px'
                                    }}>{formatDate(obj._createDate)}</div>
                                    <div
                                        key={`${sectionID}-${rowID}`}
                                        style={{
                                            backgroundColor: '#F5F5F9',
                                            borderBottom: '1px solid #ECECED',
                                        }}
                                    />
                                    <div style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>
                                        <span>{obj.storWritter}</span><span style={{
                                        float: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>状态: {this.state.statusCode2Name[obj.status]}</span></div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>) : <div></div>;
        };

        return this.state.count > 0 ? (
            <ListView
                initialListSize={this.state.count}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                    {this.state.isLoading ? '加载中...' : '加载完成'}
                </div>)}
                renderRow={row}
                style={{
                    height: this.state.height,
                    overflow: 'auto',
                }}
                pageSize={this.state.pageSize}
                onScroll={() => {
                }}
                scrollRenderAheadDistance={500}
                onEndReached={this.onEndReached}
                onEndReachedThreshold={10}
            />
        ) : <NoDataView content={this.state.dataStatus}/>;
    }
}

export default ManuscriptList;
