import React, {Component} from 'react';
import $ from 'jquery';
import {Calendar, Card, Flex, Toast, ImagePicker, InputItem, List, Picker, TextareaItem, WingBlank} from "antd-mobile";
import './interview_card.css'
import {checkContainsEmoji, formatDate, goupByFirstName, removeArrayByIndex, uuid} from "../utils";
import {createForm} from "rc-form";
import config from "../config";
import Modal from "antd-mobile/es/modal";
import TxySelectList from "./TxySelectList";
import {
    addInterview,
    getReporterList, getxcbDepts, saveInterviewDetailsAudio,
    saveInterviewDetailsVideo,
    uploadAudio,
    uploadvideo
} from "../action";
import {Player} from "video-react";
import SelectListView from "./SelectListView/SelectListView";


// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}


const extra = {
    '2017/07/15': {info: 'Disable', disable: true},
};

const now = new Date();
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 5)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 6)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8)] = {info: 'Disable', disable: true};

Object.keys(extra).forEach((key) => {
    const info = extra[key];
    const date = new Date(key);
    if (!Number.isNaN(+date) && !extra[+date]) {
        extra[+date] = info;
    }
});

const data = [
    {
        src: 'http://audio-cdn/MIKA - Lollipop.mp3',
        artist: 'Mika',
        name: 'Lollipop',
        img: 'http://audio-avatar-cdn/mika.jpg',
        id: '66575568425354321',
    },
]

/**
 * 采访详情页面的，采访内容卡片
 */
class InterviewCard extends Component {


    constructor(props) {
        super(props);
        console.info("this.props.interviewInfo.endDate", this.props.interviewInfo.createDate);
        this.state = {
            en: false,
            interviewDateshow: false,
            endDateShow: false,
            interviewDate: undefined,
            endDate: undefined,
            interviewDataconfig: {pickTime: true,},
            startTime: '',
            chooseAccount: [],
            chooseMedia: [],
            multiple: true,
            status: 0,
            chooseAuthor: [],
            chooseAccountModal: false,
            chooseMediaModel: false,
            users: {},
            selectIds: [],
            selectUsers: [],
            selectedIds: [],
            videoIds: [],
            audioIds: [],
            picFiles: this.props.picFiles,
            videoFiles: this.props.videoFiles,
            attachVideo: [],    //附加视频
            attachAudio: [],   //附加音频
            picResourceId: [],
            videoResourceId: [],
            isVideo: false,
            chooseDeptModal: false,
            checkedDeptData: [],
            dataSource: [],
            depIds: [],
        }
        this.showModal = this.showModal.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    //图片，视频数据回显
    componentWillReceiveProps(nextProps, nextContext) {
        let picFiles = nextProps.picFiles;
        if (picFiles && picFiles.length > 0) {

            let picResourceIds = [];
            for (var i = 0; i < picFiles.length; i++) {
                picResourceIds.push(picFiles[i].resourceId);
            }
            this.setState({
                picFiles: picFiles,
                picResourceId: picResourceIds
            })
        }

        let videoFiles = nextProps.videoFiles
        if (videoFiles && videoFiles.length > 0) {
            let videoResourceIds = [];
            for (var i = 0; i < videoFiles.length; i++) {
                videoResourceIds.push(videoFiles[i].resourceId);
            }

            this.setState({
                videoFiles: videoFiles,
                videoResourceId: videoResourceIds
            })
        }

        this.buildJoinnerData(nextProps.interviewInfo);

        //处理附件中的  视频，音频回显
        this.attachmentFileShow(nextProps.interviewInfo);

        //修改 时间
        if (nextProps.interviewInfo.createDate && nextProps.interviewInfo.createDate.time) {
            this.setState({
                interviewDate: formatDate(nextProps.interviewInfo.createDate.time),
                endDate: formatDate(nextProps.interviewInfo.endDate.time)
            })
        }
    }

    callGetAccountDepts(data) {
        if (data) {
            //需要给转成一个数组；
            let deptInfos = [];
            deptInfos.push(data.root);
            if (data.sons && data.sons.length > 0) {
                deptInfos = deptInfos.concat(data.sons);
            }

            let checkedData = JSON.parse(JSON.stringify(deptInfos));
            this.setState({
                dataSource: deptInfos,
                checkedDeptData: checkedData,
            })
        }

    }

    /**
     * 处理附件的回显
     * @param interviewInfo
     */
    attachmentFileShow(interviewInfo) {
        var resourceTypeVideo = 3;
        var resourceTypeAudio = 5;

        if (interviewInfo && interviewInfo.interviewAttachments && interviewInfo.interviewAttachments.length > 0) {
            let interviewAttachments = interviewInfo.interviewAttachments;
            let videoAttachments = [];
            let audioAttachments = [];
            let attachmentFile = null;
            let videoIds = [];
            let audioIds = [];

            for (var i = 0, length = interviewAttachments.length; i < length; i++) {
                attachmentFile = interviewAttachments[i];
                if (!attachmentFile) {
                    continue;
                }
                if (resourceTypeVideo == attachmentFile.resourceType) {
                    attachmentFile.url = attachmentFile.path + '/thumbnails/1';
                    videoAttachments.push(attachmentFile);
                    videoIds.push(attachmentFile.id);
                } else if (resourceTypeAudio == attachmentFile.resourceType) {
                    attachmentFile.url = 'images/audio_1.png'
                    audioAttachments.push(attachmentFile);
                    audioIds.push(attachmentFile.id);
                }
            }

            this.setState({
                attachVideo: videoAttachments,    //附加视频
                attachAudio: audioAttachments,   //附件音频
                videoIds: videoIds,
                audioIds: audioIds,
            })
        }
    }

    componentWillMount() {
        //获取记者list
        let param = new FormData();
        this.props.onRef(this);
        getReporterList(param, this.updateUsersList.bind(this))

        //获取部门信息；
        getxcbDepts({}, this.callGetAccountDepts.bind(this))
    }

    hasManager = false;

    hasWriter = false;

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        let selectids = this.state.selectIds
        this.setState({
            selectedIds: selectids,
            [key]: true,
        });
    }

    onConfirm = (startTime, endTime) => {
        startTime = formatDate(startTime);
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            interviewDateshow: false,
            interviewDate: startTime,
        });
    }

    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            interviewDateshow: false,
            endDateShow: false,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    //获取选中的用户信息
    getUserAccountList(val) {
        var index = this.state.selectIds.indexOf(val.id);
        if (index < 0) {
            this.state.selectIds.push(val.id);
            this.state.selectUsers.push(val);
        } else {
            this.state.selectIds.splice(index, 1);
            this.state.selectUsers.splice(index, 1);
        }
    }

    /**
     * 回稿时间的确认按钮
     */
    onConfirmEndData = (startTime, endTime) => {
        startTime = formatDate(startTime);
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            endDateShow: false,
            endDate: startTime,
        });
    }

    /**
     * 表单数据校验
     */
    validateFormData() {
        let manuscriptData = this.props.form.getFieldsValue();
        if (!manuscriptData.title || manuscriptData.title.length == 0) {
            Toast.fail('请填写采访任务名称!');
            return false;
        }

        if (checkContainsEmoji(manuscriptData.title)) {
            Toast.fail('采访任务名称中不可以包含表情图!');
            return false;
        }

        if (!manuscriptData.place || manuscriptData.place.length == 0) {
            Toast.fail('请填写采访地点!');
            return false;
        }

        if (checkContainsEmoji(manuscriptData.place)) {
            Toast.fail('采访地点中不可以包含表情图!');
            return false;
        }

        if (!manuscriptData._interviewDate || manuscriptData._interviewDate.length == 0) {
            Toast.fail('请选择采访时间!');
            return false;
        }

        if (!manuscriptData._endDate || manuscriptData._endDate.length == 0) {
            Toast.fail('请选择回稿时间!');
            return false;
        }

        if (manuscriptData._interviewDate >= manuscriptData._endDate) {
            Toast.fail(<div>采访时间应小<br/>于回稿时间!</div>);
            return false;
        }

        if (!manuscriptData.contacts || manuscriptData.contacts.length == 0) {
            Toast.fail('请填写联系人!');
            return false;
        }

        if (checkContainsEmoji(manuscriptData.contacts)) {
            Toast.fail('联系人中不可以包含表情图!');
            return false;
        }

        if (!manuscriptData.contactsWay || manuscriptData.contactsWay.length == 0) {
            Toast.fail('请填写联系方式!');
            return false;
        }

        var contactsWay = manuscriptData.contactsWay;
        contactsWay = contactsWay.replace(/\s*/g, "");
        var guhuaregex = /^([0-9]{3,4}-)?[0-9]{7,8}$/;
        if (!(/^1(3|4|5|6|7|8|9)\d{9}$/).test(contactsWay) && !guhuaregex.test(contactsWay)) {
            Toast.fail('联系方式格式不正确!');
            return false;
        }

        if (!manuscriptData.content || manuscriptData.content.length == 0) {
            Toast.fail('请填写采访内容!');
            return false;
        }

        if (checkContainsEmoji(manuscriptData.content)) {
            Toast.fail('采访内容中不可以包含表情图!');
            return false;
        }

        if (!manuscriptData.interviewRequire || manuscriptData.interviewRequire.length == 0) {
            Toast.fail('请填写采访要求!');
            return false;
        }

        if (checkContainsEmoji(manuscriptData.interviewRequire)) {
            Toast.fail('采访要求中不可以包含表情图!');
            return false;
        }

        if (!this.hasWriter) {
            Toast.fail('请指定文字记者!');
            return false;
        }

        if (!this.hasManager) {
            Toast.fail('请设置负责人!');
            return false;
        }

        let checkReporter = this.state.selectUsers;
        for (var i = 0; i < checkReporter.length; i++) {
            if ((!checkReporter[i].reporterRole || checkReporter[i].reporterRole.length == 0) && (!checkReporter[i].reporterIsManager)) {
                Toast.fail('请设置其他记者所属职责!');
                return false;
            }
        }

        return true;
    }


    /**
     * 提交表单数据
     */
    submitData() {
        //数据校验
        var checkResult = this.validateFormData();
        if (!checkResult) {
            return;
        }

        let manuscriptData = this.props.form.getFieldsValue();
        var contactsWay = manuscriptData.contactsWay;
        contactsWay = contactsWay.replace(/\s*/g, "");
        manuscriptData.contactsWay = contactsWay;
        let checkReporter = this.state.selectUsers;
        var params = manuscriptData;
        var length = checkReporter.length;
        for (var i = 0; i < length; i++) {
            params["reporterAccount" + i] = checkReporter[i].account;
            params["reporterRole" + i] = checkReporter[i].reporterRole;
            params["reporterIsManager" + i] = checkReporter[i].reporterIsManager;
        }
        params.reporterSize = length;
        let resoucreId = [];
        if (this.state.picResourceId && this.state.picResourceId.length > 0) {
            resoucreId = resoucreId.concat(this.state.picResourceId);
        }

        if (this.state.videoResourceId && this.state.videoResourceId.length > 0) {
            resoucreId = resoucreId.concat(this.state.videoResourceId);
        }
        if (resoucreId && resoucreId.length > 0) {
            params.resourceIds = resoucreId.join(',');
        }

        params.clueId = this.props.newClueId ? this.props.newClueId : 0;
        params.videoIds = this.state.videoIds.join(",");
        params.audioIds = this.state.audioIds.join(',');
        params.id = this.props.interviewInfo.id;
        addInterview(params, this.addBack.bind(this))
    }

    addBack(data) {
        Toast.success('提交成功')

        //成功后，返回到列表页面
        this.props.goBack();
    }

    /**
     * 设置记者
     * @param data
     */
    /**
     * 设置记者为负责人
     * @param reporterIndex  为全局对象checkReporter的索引
     * @param objDom
     */
    setReporterManager(reporter) {
        let selectReporter = this.state.selectUsers;
        let currentUser;

        //获取当前选中的记者
        for (var i = 0; i < selectReporter.length; i++) {
            if (selectReporter[i].id == reporter.id) {
                currentUser = selectReporter[i]
                break;
            }
        }

        //只能有一个负责人
        let needUpdateShow = true;
        if (this.hasManager) {
            if (currentUser.reporterIsManager == 1) {
                currentUser.reporterIsManager = 0;
                this.hasManager = false;
            } else {
                needUpdateShow = false;
                Toast.info('只可设置一位负责人！')
            }
        } else {
            this.hasManager = true;
            currentUser.reporterIsManager = 1
        }
        if (needUpdateShow) {
            this.setState({
                selectUsers: selectReporter
            })
        }
    }

    /**
     * 设置记者角色
     * @param reporterIndex  为全局对象checkReporter的索引
     * @param objDom
     */
    setReporterRole(reporter, rolevalue) {
        let selectReporter = this.state.selectUsers;
        let currentUser;
        let needUpdateShow = true;
        //获取当前选中的记者
        for (var i = 0; i < selectReporter.length; i++) {
            if (selectReporter[i].id == reporter.id) {
                currentUser = selectReporter[i]
                break;
            }
        }

        //文字记者 只能有一个
        if (rolevalue == '1,') {
            if (this.hasWriter) {
                if (currentUser.reporterRole.includes('1,')) {
                    currentUser.reporterRole = currentUser.reporterRole.replace(rolevalue, '');
                    this.hasWriter = false;
                } else {
                    needUpdateShow = false;
                    Toast.fail('只可设置一位文字记者');
                }

            } else {
                currentUser.reporterRole += rolevalue;
                this.hasWriter = true;
            }
        } else {
            if (currentUser.reporterRole.includes(rolevalue)) {
                currentUser.reporterRole = currentUser.reporterRole.replace(rolevalue, '');
            } else {
                currentUser.reporterRole += rolevalue;
            }
        }

        //重新渲染页面
        if (needUpdateShow) {
            this.setState({
                selectUsers: selectReporter
            })
        }

    }

    /**
     * 回显记者数据
     * @returns {*}
     */
    showCheckReporter() {
        let selectUsers = this.state.selectUsers;
        this.setState({
            selectUsers: selectUsers
        })
    }

    /**
     * 构建 参与者数据
     * @param data
     */
    buildJoinnerData(interview) {

        var reporters = {};
        for (var i = 0; interview.interviewJointers && i < interview.interviewJointers.length; i++) {
            var jointer = interview.interviewJointers[i].info;
            var reporter = reporters["account_" + jointer.account];
            if (!reporter) {
                reporter = jointer;
                reporter.reporterRole = "";
            }
            reporter.reporterRole += interview.interviewJointers[i].interviewRole + ",";
            if (interview.interviewJointers[i].manager == 1) {
                reporter.reporterIsManager = 1;
            }
            reporters["account_" + jointer.account] = reporter;
        }

        var reporters_ = [];
        let selectIds = [];
        for (var account in reporters) {
            reporters_.push(reporters[account]);
            selectIds.push(reporters[account].id);
        }
        this.setState({
            selectUsers: reporters_,
            selectIds: selectIds
        })
    }

    /**
     * 更新页面展示的用户信息
     * @param data
     */
    updateUsersList(data) {
        //对编辑的数据进行分组
        let firstName2UserList = goupByFirstName(data);

        this.setState({
            users: firstName2UserList,
        })
    }

    /**
     * 回显选中的记者信息
     * @param data
     */
    showReporter(data) {
        let addButon = <div key={uuid()} className={'add_txy'} style={{width: '120px', height: '120px'}}
                            onClick={
                                this.showModal('chooseAccountModal')
                            }>
            <Flex>
                <Flex.Item>
                </Flex.Item>
            </Flex>
        </div>

        if (!data) {
            return addButon;
        }
        let listView = data.map(i => {
            return this.showUserInfoView(i)
        });

        listView.push(addButon);
        return listView;
    }


    /**
     * 展示线索 图片
     * @returns {*}
     */
    showPicsView(pictures) {
        return (pictures && pictures.length > 0) ? <div>
            <p>线索图片</p>
            <Card>
                <ImagePicker
                    // onAddImageClick={this.addImage}
                    files={this.state.picFiles}
                    // onChange={this.onChangePic}
                    selectable={false}
                    multiple={this.state.multiple}
                    disableDelete={true}
                />
            </Card>
        </div> : <div></div>
    }

    /**
     *变更视频
     * @param files
     * @param type
     * @param index
     */
    onChangeVideo = (files, type, index) => {
        if (type === 'add') {
            this.state.videoUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file && files[i].orientation === 1) {
                    data.append('file', files[i].file);

                    //调用后台接口上传文件，
                    uploadvideo(data, this.uploadInterviewVideback.bind(this));
                }
            }
        }

        if (type === 'remove') {
            let attachVideos = this.state.attachVideo;
            let videoIds = this.state.videoIds;
            let videoIdstemp = removeArrayByIndex(index, videoIds);
            let attachVideofiles = removeArrayByIndex(index, attachVideos);
            this.setState({
                videoIds: videoIdstemp,
                attachVideo: attachVideofiles
            })
        }
    }


    /**
     * 视频文件的回调
     * @param data
     */
    uploadInterviewVideback(data) {
        if (!data) {
            Toast.fail('上传失败');
        }
        let storageFile = data.storageFile;
        let param = {
            'name': storageFile.name,
            'ext': storageFile.suffix,
            'path': data.url,
            'size': storageFile.size,
            'allTime': storageFile.time
        };
        saveInterviewDetailsVideo(param, this.callsaveInterviewDetailsVideo.bind(this));
    }

    /**
     * 采访音频附件
     */
    callsaveInterviewDetailsVideo(data) {
        if (data) {
            let attachVideos = this.state.attachVideo;
            let videoIds = this.state.videoIds;
            data.url = data.path + '/thumbnails/1';
            attachVideos.push(data);
            videoIds.push(data.id);
            this.setState({
                attachVideo: attachVideos,
                videoIds: videoIds
            })
        }
    }


    /**
     *变更音频
     * @param files
     * @param type
     * @param index
     */
    onChangeAudio = (files, type, index) => {
        if (type === 'add') {
            this.state.audioUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file && files[i].orientation === 1) {
                    data.append('file', files[i].file);

                    //调用后台接口上传文件，
                    uploadAudio(data, this.uploadInterviewAudioback.bind(this));
                }
            }
        }

        if (type === 'remove') {
            let audios = this.state.attachAudio;
            let audioids = this.state.audioIds;
            let audioFiles = removeArrayByIndex(index, audios);
            let audioidstemp = removeArrayByIndex(index, audioids);
            this.setState({
                audioIds: audioidstemp,
                attachAudio: audioFiles
            })
        }
    }


    /**
     * 音频文件的回调
     * @param data
     */
    uploadInterviewAudioback(data) {
        if (!data) {
            Toast.fail('上传失败');
        }

        let storageFile = data.storageFile;
        let param = {
            'name': storageFile.name,
            'ext': storageFile.suffix,
            'path': data.url,
            'size': storageFile.size,
            'allTime': storageFile.time
        };
        saveInterviewDetailsAudio(param, this.callsaveInterviewDetailsAudio.bind(this));
    }

    /**
     * 采访音频附件
     */
    callsaveInterviewDetailsAudio(data) {
        if (data) {
            let audios = this.state.attachAudio;
            let audioids = this.state.audioIds;
            data.url = 'images/audio_1.png';
            data.src = data.path;
            audios.push(data);
            audioids.push(data.id);
            this.setState({
                attachAudio: audios,
                audioIds: audioids
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.showAudioName();
    }

    //添加音频文件的文字；
    showAudioName() {
        let attachAudio = this.state.attachAudio;

        var showElement = null;
        $('.audioPicker .am-image-picker-item-content').empty();
        if (attachAudio && attachAudio.length > 0) {
            for (var i = 0, length = attachAudio.length; i < length; i++) {
                $('.audioPicker .am-image-picker-item-content').eq(i).append(attachAudio[i].name);
            }
        }
    }

    /**
     * 显示线索视频
     */
    showVideosView(videos) {
        return (videos && videos.length > 0) ? <div><p>线索视频</p>
            <Card>
                <div className={"video_pincker"}>
                    <ImagePicker
                        accept={"video/*"}
                        // onAddImageClick={this.addImage}
                        files={this.state.videoFiles}
                        // onChange={this.onChangeVideo}
                        selectable={false}
                        multiple={this.state.multiple}
                        disableDelete={true}
                    />
                </div>
            </Card>
        </div> : <div></div>
    }

    /**
     * 回显单个用户的头像
     * @param i
     */
    showUserInfoView(reporter) {

        /**是否是教师记者，只有是教师记者才能担任负责人*/
        var isJsjz = false;
        for (var j in reporter.roleCodes) {
            if (reporter.roleCodes[j] == "jsjz") {
                isJsjz = true;
            }
        }

        var bChecked0 = false, bChecked1 = false, bChecked2 = false, bChecked3 = false;
        if (reporter.reporterRole == undefined) {
            /**初始化选择该记者的角色*/
            reporter.reporterRole = "";
        } else {
            /**回显选择该记者的角色*/
            var roles = reporter.reporterRole.split(",");
            for (var j in roles) {
                if (roles[j] == 1) {
                    bChecked1 = true;
                    this.hasWriter = true;
                } else if (roles[j] == 2) {
                    bChecked2 = true;
                } else if (roles[j] == 3) {
                    bChecked3 = true;
                }
            }
        }

        if (reporter.reporterIsManager == undefined) {
            /**初始化该记者是否为负责人*/
            reporter.reporterIsManager = 0;
        } else {
            /**回显该记者是否为负责人*/
            if (reporter.reporterIsManager == 1) {
                bChecked0 = true;
                this.hasManager = true;
            }
        }

        /** 设置部门 **/
        var departmentNames = "";
        for (var j in reporter.allDept) {
            departmentNames += reporter.allDept[j].name + ",";
        }
        //剔除掉最后一个 ，
        var lastSpiltSign = departmentNames.lastIndexOf(',');
        departmentNames = departmentNames.substr(0, lastSpiltSign);

        var photoUrl = "";
        if (reporter.photoUrl) {
            photoUrl = reporter.photoUrl;
        } else {
            if (reporter.sex == 1) {
                photoUrl = "images/header_boys.png";
            } else {
                photoUrl = "images/header_girls.png";
            }
        }

        return <div key={uuid()} style={{width: '45%', marginLeft: '10px'}}>
            <Flex justify={'column'}>
                <Flex.Item style={{marginTop: '10px'}}><img src={photoUrl}/>
                    <div>{isJsjz ? (<a title='负责人' onClick={() => {
                        this.setReporterManager(reporter)
                    }}>{bChecked0 ? <img
                            src={'images/fzr1.png'} className={'setTaskIcon'}/> :
                        <img src={'images/fzr.png'} className={'setTaskIcon'}/>}</a>) : ""}
                        <a title='文字记者' onClick={() => {
                            this.setReporterRole(reporter, '1,')
                        }}>{bChecked1 ?
                            <img src={'images/jz1.png'} className={'setTaskIcon'}/> :
                            <img src={'images/jz.png'} className={'setTaskIcon'}/>}</a>
                        <a title='摄影记者' onClick={() => {
                            this.setReporterRole(reporter, '2,')
                        }}>{bChecked2 ?
                            <img src={'images/sy1.png'} className={'setTaskIcon'}/> :
                            <img src={'images/sy.png'} className={'setTaskIcon'}/>}</a>
                        <a title='摄像记者' onClick={() => {
                            this.setReporterRole(reporter, '3,')
                        }}>{bChecked3 ?
                            <img src={'images/sx1.png'} className={'setTaskIcon'}/> :
                            <img src={'images/sx.png'} className={'setTaskIcon'}/>}</a>
                    </div>
                    <div className={'interview_userInfo'}>
                        <div style={{marginTop: '2px'}}> 部门:{departmentNames}</div>
                        <div style={{marginTop: '2px'}}> 姓名:{reporter.name}</div>
                        <div style={{marginTop: '2px'}}> 电话:{reporter.phone}</div>
                    </div>
                </Flex.Item>
            </Flex>
        </div>
    }

    /**
     * 视频预览
     */
    openVideoView(index, files) {
        this.setState({
            videoSources: files[index].path,
            showVideoView: true,
            isVideo: true,
        })
    }

    /**
     * 音频预览
     */
    openAudioView(index, files) {
        this.setState({
            audioSource: files[index].path,
            showVideoView: true,
            isVideo: false,
        })
    }

    /**
     * 设置过滤部门信息；
     */
    setDetpId() {
        let checkedDeptInfos = this.state.checkedDeptData;
        let depIds = [];
        if (checkedDeptInfos && checkedDeptInfos.length > 0) {
            for (var i = 0; i < checkedDeptInfos.length; i++) {
                depIds.push(checkedDeptInfos[i].id);
            }
        }
        this.setState({depIds: depIds});
        //重新渲染，通讯员列表；
        let param=new FormData();
        param.append("deptIds", depIds);
        getReporterList(param, this.updateUsersList.bind(this))
    }

    //关闭视频
    closeVideo() {
        this.setState({
            videoSources: {},
            showVideoView: false,
            isVideo: false,
        })
    }

    /**
     * 获取选中的部门信息
     */
    getCheckedData(datas) {
        this.state.checkedDeptData = datas;
        console.info("checkedDeptData", datas);
    }

    //点击确定按钮重新渲染数据；

    render() {
        console.info("this.pros.interviewInfo", this.props.interviewInfo);
        var interviewContent_titles = <div>
            <div style={{color: 'red', width: '6px'}} className={'interview_tittle'}>*</div>
            <div
                style={{fontSize: '14px'}} className={'interview_tittle'}>采访内容
            </div>
            <div className={'interview_colon'}>:</div>
        </div>;
        var interviewRequire_titles = <div>
            <div style={{color: 'red', width: '6px'}} className={'interview_tittle'}>*</div>
            <div
                style={{fontSize: '14px'}} className={'interview_tittle'}>采访要求
            </div>
            <div className={'interview_colon'}>:</div>
        </div>;
        const {getFieldProps, getFieldDecorator} = this.props.form;
        return <div style={{overflowY: 'auto', height: '88vh'}}>
            <div>
                <WingBlank size="lg" style={{margin: '0px'}}>
                    <div className={'storyAttribute'}>
                        <div>
                            <List>
                                <InputItem
                                    {...getFieldProps('title', {
                                        rules: [{required: true}],
                                        initialValue: this.props.interviewInfo.title
                                    })}
                                    placeholder="请输入任务名称"
                                    moneyKeyboardAlign="left"
                                    maxLength={255}
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    type={'text'}
                                >
                                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                                    <div className={'interview_tittle'}>任务名称</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <InputItem
                                    clear
                                    {...getFieldProps('place', {initialValue: this.props.interviewInfo.place})}
                                    placeholder="请输入采访地点"
                                    maxLength={255}
                                    moneyKeyboardAlign="left"
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    type={'text'}
                                >
                                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                                    <div className={'interview_tittle'}>采访地点</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <InputItem placeholder={'采访时间'}
                                           {...getFieldProps('_interviewDate', {initialValue: this.state.interviewDate})}
                                           onClick={() => {
                                               document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                                               this.setState({
                                                   interviewDateshow: true,
                                               });
                                           }}
                                           editable={false}
                                >
                                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                                    <div className={'interview_tittle'}>采访时间</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>
                                <Calendar
                                    {...this.state.interviewDataconfig}
                                    visible={this.state.interviewDateshow}
                                    onCancel={this.onCancel}
                                    onConfirm={this.onConfirm}
                                    minDate={now}
                                    defaultDate={now}
                                    type={'one'}
                                />

                                <InputItem placeholder={'回稿时间'}
                                           {...getFieldProps('_endDate', {initialValue: this.state.endDate})}
                                           onClick={() => {
                                               document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                                               this.setState({
                                                   endDateShow: true,
                                               });
                                           }}
                                           editable={false}
                                >
                                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                                    <div className={'interview_tittle'}>回稿时间</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>
                                <Calendar
                                    {...this.state.interviewDataconfig}
                                    visible={this.state.endDateShow}
                                    onCancel={this.onCancel}
                                    onConfirm={this.onConfirmEndData}
                                    defaultDate={now}
                                    minDate={now}
                                    type={'one'}
                                />

                                <InputItem
                                    clear
                                    {...getFieldProps('contacts', {initialValue: this.props.interviewInfo.contacts})}
                                    placeholder="请输入联系人"
                                    moneyKeyboardAlign="left"
                                    maxLength={10}
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    type={'text'}
                                >
                                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                                    <div className={'interview_tittle'}>联系人</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <InputItem
                                    {...getFieldProps('contactsWay', {initialValue: this.props.interviewInfo.contactsWay})}
                                    type={'phone'}
                                    clear
                                    placeholder="请输入联系方式"
                                    moneyKeyboardAlign="left"
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    required

                                >
                                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                                    <div className={'interview_tittle'}>联系方式</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <TextareaItem style={{fontSize: '15px'}}
                                              {...getFieldProps('content', {
                                                  initialValue: this.props.interviewInfo.content
                                              })}
                                              rows={5}
                                              maxLength={1000}
                                              placeholder="请输入采访内容"
                                              title={interviewContent_titles}
                                />

                                <TextareaItem style={{fontSize: '15px'}}
                                              {...getFieldProps('interviewRequire', {
                                                  initialValue: this.props.interviewInfo.interviewRequire
                                              })}
                                              maxLength={1000}
                                              rows={5}
                                              placeholder="请输入采访要求"
                                              title={interviewRequire_titles}
                                />

                                <div className={'intervidew_detail_video'}>附件音频</div>
                                <div className={"audioPicker"}>
                                    {<ImagePicker
                                        accept={"audio/*"}
                                        onAddImageClick={this.addImage}
                                        onImageClick={this.openAudioView.bind(this)}
                                        files={this.state.attachAudio}
                                        onChange={this.onChangeAudio}
                                        // selectable={(this.state.disableDelete && this.state.isTaken)}
                                        multiple={this.state.multiple}
                                        // disableDelete={!this.state.disableDelete}
                                    />}</div>

                                <div className={'intervidew_detail_video'}>附件视频</div>
                                {<ImagePicker
                                    accept={"video/*"}
                                    onAddImageClick={this.addImage}
                                    onImageClick={this.openVideoView.bind(this)}
                                    files={this.state.attachVideo}
                                    onChange={this.onChangeVideo}
                                    // selectable={(this.state.disableDelete && this.state.isTaken)}
                                    multiple={this.state.multiple}
                                    // disableDelete={!this.state.disableDelete}
                                />}

                                <div style={{border: '0'}}><span style={{color: 'red', paddingLeft: '15px'}}>*</span>记者选择:
                                    <div className={'userAdd'}>
                                        <div className="reportlist" style={{border: '0px'}}>
                                            <Flex wrap="wrap" justify={'start'}>
                                                {this.showReporter(this.state.selectUsers)}
                                            </Flex>
                                        </div>
                                    </div>
                                </div>
                                <Modal
                                    popup
                                    visible={this.state.chooseAccountModal}
                                    title={<div>
                                        <div>选择记者</div>
                                        <div style={{
                                            marginRight: '2px',
                                            fontSize: '12px',
                                            color: '#108ee9',
                                            textAlign: 'right'
                                        }} onClick={this.showModal('chooseDeptModal')}>部门
                                        </div>
                                    </div>}
                                    onClose={this.onClose('chooseAccountModal')}
                                    animationType="slide-up"
                                    afterClose={() => {
                                    }}
                                    footer={[{
                                        text: '确定', onPress: () => {
                                            this.showCheckReporter();
                                            this.onClose('chooseAccountModal')();
                                        }
                                    }]}
                                >
                                    <div>
                                        <div style={{height: '350px'}}>
                                            <TxySelectList showSearch={'none'} data={this.state.users}
                                                           selectedIds={this.state.selectedIds}
                                                           getUserAccountList={this.getUserAccountList.bind(this)}/>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    popup
                                    visible={this.state.chooseDeptModal}
                                    title={'部门'}
                                    onClose={this.onClose('chooseDeptModal')}
                                    animationType="slide-up"
                                    afterClose={() => {
                                    }}
                                    footer={[{
                                        text: '确定', onPress: () => {
                                            // this.showCheckReporter();
                                            this.setDetpId();

                                            this.onClose('chooseDeptModal')();
                                        }
                                    }]}
                                >
                                    <div>
                                        <div style={{height: '350px'}}>
                                            <SelectListView defaultCheckedDatas={this.state.checkedDeptData}
                                                            dataSource={this.state.dataSource}
                                                            getCheckedData={this.getCheckedData.bind(this)}/>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal
                                    popup
                                    visible={this.state.showVideoView}
                                    animationType="slide-up"
                                    afterClose={() => {
                                    }}
                                >
                                    <div style={{height: '100vh'}}>
                                        <div onClick={() => {
                                            this.closeVideo()
                                        }} style={{backgroundColor: "#000000", color: '#ffffff'}}>关闭
                                        </div>
                                        {this.state.isVideo ? <div style={{height: '94vh'}}>
                                            <Player autoPlay={true} fluid={false} width={'100%'} height={'100%'}>
                                                <source src={this.state.videoSources}/>
                                            </Player>
                                        </div> : <div className={'wrapper'}>
                                            <audio src={this.state.audioSource} controls="controls">
                                            </audio>
                                        </div>}
                                    </div>
                                </Modal>
                            </List>
                        </div>
                        {this.showPicsView(this.state.picFiles)}
                        {this.showVideosView(this.state.videoFiles)}
                    </div>
                </WingBlank>
            </div>
        </div>
    }
}

const InterviewCardForm = createForm()(InterviewCard);
export default InterviewCardForm
