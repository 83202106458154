import {
    ACT_REMARK
} from "../../store/actions/action-type/action-types";
import store from "../../store";


function ActText(){
}

ActText.prototype.getText = function (data,type,callback) {
    //批注弹出框。
    let actRemark={
        show:true,
        data,type,callback
    }

    let action={
        actRemark,
        type:ACT_REMARK
    }

    //派发action到writeStory组件监听
    store.dispatch(action);



    // this.callback = callback;
    // var content = "<div class=\"layui-card\">\n" +
    //     "          <div class=\"layui-card-header\">备注</div>\n" +
    //     "          <div class=\"layui-card-body layui-row\">\n" +
    //     "            <div class=\"layui-col-md12\">\n" +
    //     "              <textarea name=\"\" placeholder=\"请输入备注\" id=\"actText\" name=\"actText\" class=\"layui-textarea\"></textarea>\n" +
    //     "            </div>\n" +
    //     "          </div>\n" +
    //     "        </div>"
    //
    // layer.open({
    //     content : content,
    //     type : 1,
    //     btn: ['确定','取消']
    //     ,area: '500px'
    //     ,cancel: function(index, layero){
    //         layer.close(index);
    //         return false;
    //     }
    //     ,yes : function(index){
    //         var value = $("#actText").val();
    //         var valueLength = value.replace(/\ +/g, ""); //去掉空格
    //         valueLength = valueLength.replace(/[ ]/g, "");    //去掉空格
    //         valueLength = valueLength.replace(/[\r\n]/g, ""); //去掉回车换行
    //         if(valueLength.length < 1){
    //             layer.msg("请填写备注!",{icon: 2})
    //             return false;
    //         }else if(value.length > 100){
    //             layer.msg("备注请勿超过100字!",{icon: 2})
    //             return false;
    //         }else{
    //             var callbackValue ={};
    //             if(type == "activitiUsers" || type == "users"){
    //                 callbackValue.activitiUsers =value;
    //             }else if(type == "remarks"){
    //                 callbackValue.remarks =value;
    //             }
    //             callbackValue.type = type;
    //             callback(callbackValue);
    //             layer.close(index);
    //         }
    //     },btn2 : function(index, layero){
    //         layer.close(index);
    //         actButton.closeAll();
    //         return false;
    //     }
    // })

}

export  default  ActText;
