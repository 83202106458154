import React from "react";
import {ListView, Toast} from "antd-mobile";
import {getAllData, getColumnData, getContributeAllData} from "../action";
import {formatDate, isStringEmpty} from "../utils";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {setStoryId} from "../store/actions";
import {NoDataView} from "./NoDataView";
import config from "../config";
import SearchBar from "antd-mobile/es/search-bar";


class AllListViewData extends React.Component {
    pageIndex = 1;
    rData=[];
    constructor(props) {
        super(props);

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            dataStatus: '加载中...',
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.isContributeRoleFlag) {
        } else {
            this.rData=[];
            let data = new FormData();
            data.append('start', 1);
            data.append('size', this.state.pageSize);
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'all');
            data.append('treeId', this.props.treeId);
            data.append('name', '全部');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }
    }

    updateStoryData(data) {

        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }
        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData,...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            dataStatus: data.count > 0 ? '加载完成' : '暂无数据'
        })

        //自增起始位置
        this.pageIndex++;
    }

    componentWillMount() {
        this.pageIndex = 1;
        this.getPageListData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    getPageListData() {
        Toast.loading('加载中...', 0, () => {
        });
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        //投稿端和管理调用的接口不一样
        if (this.props.isContributeRoleFlag) {
            data.append('columnType', 'my');
            getContributeAllData(data, this.updateStoryData.bind(this));
        } else {
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'all');
            data.append('treeId', this.props.treeId);
            data.append('name', '全部');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }
    }

    onEndReached = (event) => {

        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }

        Toast.loading('加载中...', 0, () => {
            console.log('Load complete !!!');
        });
        this.setState({isLoading: true});
        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);

        if (this.props.isContributeRoleFlag) {
            data.append('columnType', 'my');
            getContributeAllData(data, this.updateStoryData.bind(this));
        } else {
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'all');
            data.append('treeId', this.props.treeId);
            data.append('name', '全部');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));
        }

    }

    onChange=(value)=>{
        this.setState({ search:value });
    };
    onCancel=()=>{
        this.setState({ search:'' },()=>{
            this.submitSearch('')
        });
    }

    submitSearch=(value)=>{
        //重新请求后台获取数据
        this.rData =[];
        let data = new FormData();
        data.append('start', 1);
        data.append('size', 10);
        data.append('queryStr',value);
        if (this.props.isContributeRoleFlag) {
            data.append('columnType', 'my');
            getContributeAllData(data, this.updateStoryData.bind(this));
        } else {
            data.append('currentMedia', this.props._mediaCode);
            data.append('type', 'all');
            data.append('treeId', this.props.treeId);
            data.append('name', '全部');
            data.append('treeContentId', this.props.treeContentId);
            getColumnData(data, this.updateStoryData.bind(this));;
        }
    }


    render() {
        const row = (rowData, sectionID, rowID) => {
            let configElement = config['dataSource2ActButtonId'];
            let  actbuttonId=configElement[rowData.dataSource];
            const obj = rowData;
            return obj ? (
                <div>
                    <Link to={'/writeStory/' + obj.id + '/0' + '/'+actbuttonId+'/1'}>
                        <div key={rowID} style={{padding: '15px'}}>
                            <div
                                style={{
                                    fontSize: '4vw',
                                    // fontWeight:700,
                                    color: 'black',
                                    wordBreak: 'break-all',
                                    marginBottom:'2px'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.title}}></div>
                            <div
                                style={{
                                    lineHeight: '20px',
                                    color: '#888',
                                    fontSize: '3.5vw',
                                    wordBreak: 'break-all',
                                    marginTop: 8
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.summary) ? '' : obj.summary}}></div>
                            <div style={{
                                textAlign: 'right',
                                color: '#888',
                                fontSize: '12px',
                                marginBottom: '10px'
                            }}>{formatDate(obj.changeDate.time)}</div>
                            <div>
                                <div className={'cardBootm'}>
                                    <div
                                        key={`${sectionID}-${rowID}`}
                                        style={{
                                            backgroundColor: '#F5F5F9',
                                            height: 0.5,
                                            borderBottom: '1px solid #ECECED',
                                            marginTop: 15,
                                            marginBottom: 11,
                                            // borderTop: '0.5px solid #ECECED',
                                        }}
                                    />
                                   <div style={{color: '#888', fontSize: '12px',paddingBottom:10}}>
                                           <span style={{float:'left'}}>{obj.submitterName?obj.submitterName:obj.submitter}</span>
                                           <span style={{
                                           float: 'right',
                                           color: '#888',
                                           fontSize: '12px',
                                       }}>状态: {obj.statusName}</span>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>
            ) : <div></div>;
        };

        return <div>
        <SearchBar
            value={this.state.search}
            placeholder="输入稿件名称"
            onSubmit={this.submitSearch}
            onClear={value => console.log(value, 'onClear')}
            onFocus={() => console.log('onFocus')}
            onBlur={() => console.log('onBlur')}
            onCancel={this.onCancel}
            onChange={this.onChange}
        />
         {this.state.count > 0 ? (
            <div>
                <ListView
                    initialListSize={10}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '加载完成'}
                    </div>)}
                    renderRow={row}
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    pageSize={10}
                    // onScroll={() => {
                    // }}
                    // scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </div>
            ) : <NoDataView content={this.state.dataStatus}/>}</div>;
    }
}

/**
 * 将state属性值映射到props
 * @param state
 * @returns {{storyId: *}}
 */
const stateToProps = (state) => {
    return {
        storyId: state.storyReducer.storyId,
        currentMediaCode: state.storyReducer.currentMediaCode
    }

}


export default connect(stateToProps, {setStoryId})(AllListViewData);
