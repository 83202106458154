import React, {Component} from 'react';
import Icon from "antd-mobile/es/icon";

/***
 * 稿件预览功能
 * @param props
 * @returns {*}
 * @constructor
 */
class PreviewAge extends  Component{
    constructor(props) {
        super(props);
        this.state=({
            storyId:props.match.params.storyId,
            history: require("history").createHashHistory,
        })
    }

    goBack(){
        this.state.history().goBack();
    }

    render() {
       return <div>
           <div style={{position:'absolute'}} onClick={()=>{this.goBack()}}><Icon type="left" size={'md'} color='rgba(255,255,255,0.4)' /></div>
           <iframe style={{height: "100vh", width:"100vw",border:'0px'}} src={'./previewPages/previewPage.htm'}/>
       </div>
    }
}

export  default PreviewAge;