import React, {Component} from 'react';
import {Calendar, Card, Flex, Toast, ImagePicker, InputItem, List, Picker, TextareaItem, WingBlank} from "antd-mobile";
import './interviewDetail_card.css'
import {formatDate, uuid} from "../utils";
import config from "../config";
import createForm from "rc-form/es/createForm";
import Modal from "antd-mobile/es/modal";
import {Player} from "video-react";
import $ from "jquery";


// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

/**
 * 采访详情页面的，信息展示
 */
class InterviewDetailCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectUsers: [],
            attachVideo: [],    //附加视频
            attachAudio: [],   //附加音频
        }
    }

    componentWillMount() {
        //构建记者显示数据格式数据
        this.buildJoinnerData(this.props.interviewInfo);

        //附件的回显
        this.attachmentFileShow(this.props.interviewInfo);
    }

    componentDidMount() {
        this.showAudioName();
    }

    //添加音频文件的文字；
    showAudioName() {
        let attachAudio = this.state.attachAudio;
        $('.audioPicker .am-image-picker-item-content').empty();
        if (attachAudio && attachAudio.length > 0) {
            for (var i = 0, length = attachAudio.length; i < length; i++) {
                $('.audioPicker .am-image-picker-item-content').eq(i).append(attachAudio[i].name);
            }
        }
    }

    /**
     * 处理附件的回显
     * @param interviewInfo
     */
    attachmentFileShow(interviewInfo) {
        var resourceTypeVideo = 3;
        var resourceTypeAudio = 5;

        if (interviewInfo && interviewInfo.interviewAttachments && interviewInfo.interviewAttachments.length > 0) {
            let interviewAttachments = interviewInfo.interviewAttachments;
            let videoAttachments = [];
            let audioAttachments = [];
            let attachmentFile = null;

            for (var i = 0, length = interviewAttachments.length; i < length; i++) {
                attachmentFile = interviewAttachments[i];
                if (!attachmentFile) {
                    continue;
                }
                if (resourceTypeVideo == attachmentFile.resourceType) {
                    attachmentFile.url = attachmentFile.path + '/thumbnails/1';
                    videoAttachments.push(attachmentFile);
                } else if (resourceTypeAudio == attachmentFile.resourceType) {
                    attachmentFile.url='images/audio_1.png'
                    audioAttachments.push(attachmentFile);
                }
            }

            this.setState({
                attachVideo: videoAttachments,    //附加视频
                attachAudio: audioAttachments,   //附件音频
            })
        }
    }

    /**
     * 音频预览
     */
    openAudioView(index, files) {
        this.setState({
            audioSource: files[index].path,
            showVideoView: true,
            isVideo:false,
        })
    }

    /**
     * 视频预览
     */
    openVideoView(index, files) {
        this.setState({
            videoSources: files[index].path,
            showVideoView: true,
            isVideo:true,
        })
    }

    //关闭视频
    closeVideo() {
        this.setState({
            videoSources: {},
            showVideoView: false,
            isVideo:false
        })
    }

    buildJoinnerData(data) {

        let joinAccounts = data.interviewJointerAccounts;
        let interviewJointers = data.interviewJointers;
        if ((!joinAccounts || joinAccounts.length == 0) || (!interviewJointers || interviewJointers.length == 0)) {
            return;
        }
        var joinerInfos = [];
        for (var i = 0; i < joinAccounts.length; i++) {
            var joinerInfo = {};
            joinerInfo.account = joinAccounts[i];
            joinerInfo.interviewRole = '';
            for (var j = 0; j < interviewJointers.length; j++) {
                if (interviewJointers[j].account == joinAccounts[i]) {
                    joinerInfo.departmentNames = interviewJointers[j].departmentNames;
                    joinerInfo.interviewRole += (interviewJointers[j].interviewRole) + ',';
                    joinerInfo.jointerName = interviewJointers[j].jointerName;
                    joinerInfo.manager = interviewJointers[j].manager;
                    joinerInfo.phone = interviewJointers[j].phone;
                    joinerInfo.jointerName = interviewJointers[j].jointerName;
                    joinerInfo.sex = interviewJointers[j].sex;
                    joinerInfo.status = interviewJointers[j].status;
                    joinerInfo.photoUrl = interviewJointers[j].photoUrl;
                }
            }
            joinerInfos.push(joinerInfo);
        }
        this.setState({
            selectUsers: joinerInfos
        })
    }

    /**
     * 回显选中的记者信息
     * @param data
     */
    showReporter(data) {
        if (!data) {
            return "";
        }
        let listView = data.map(i => {
            return this.showUserInfoView(i)
        });

        return listView;
    }

    /**
     * 回显单个用户的头像
     * @param i
     */
    showUserInfoView(reporter) {

        /**是否是教师记者，只有是教师记者才能担任负责人*/
        var isJsjz = false;
        if (reporter.manager == 1) {
            isJsjz = true;
        }

        var bChecked0 = false, bChecked1 = false, bChecked2 = false, bChecked3 = false;
        if (reporter.interviewRole == undefined) {
            /**初始化选择该记者的角色*/
            reporter.interviewRole = "";
        } else {
            /**回显选择该记者的角色*/
            var roles = reporter.interviewRole.split(",");
            for (var j in roles) {
                if (roles[j] == 1) {
                    bChecked1 = true;
                } else if (roles[j] == 2) {
                    bChecked2 = true;
                } else if (roles[j] == 3) {
                    bChecked3 = true;
                }
            }
        }

        if (reporter.manager == undefined) {
            /**初始化该记者是否为负责人*/
            reporter.manager = 0;
        } else {
            /**回显该记者是否为负责人*/
            if (reporter.manager == 1) {
                bChecked0 = true;
            }
        }

        /** 设置部门 **/
        var departmentNames = reporter.departmentNames;
        var photoUrl = "";
        if (reporter.photoUrl) {
            photoUrl = reporter.photoUrl;
        } else {
            if (reporter.sex == 1) {
                photoUrl = "images/header_boys.png";
            } else {
                photoUrl = "images/header_girls.png";
            }
        }

        return <div key={uuid()} style={{width: '130px', marginLeft: '10px'}}>
            <Flex justify={'column'}>
                <Flex.Item><img src={photoUrl}/>
                    <div>{isJsjz ? (<a title='负责人'>{bChecked0 ? <img
                            src={'images/fzr1.png'} className={'setTaskIcon'}/> :
                        <img src={'images/fzr.png'} className={'setTaskIcon'}/>}</a>) : ""}
                        <a title='文字记者'>{bChecked1 ?
                            <img src={'images/jz1.png'} className={'setTaskIcon'}/> :
                            <img src={'images/jz.png'} className={'setTaskIcon'}/>}</a>
                        <a title='摄影记者'>{bChecked2 ?
                            <img src={'images/sy1.png'} className={'setTaskIcon'}/> :
                            <img src={'images/sy.png'} className={'setTaskIcon'}/>}</a>
                        <a title='摄像记者'>{bChecked3 ?
                            <img src={'images/sx1.png'} className={'setTaskIcon'}/> :
                            <img src={'images/sx.png'} className={'setTaskIcon'}/>}</a>
                    </div>
                    <div style={{marginTop: '2px'}}> 部门:{departmentNames}</div>
                    <div style={{marginTop: '2px'}}> 姓名:{reporter.jointerName}</div>
                    <div style={{marginTop: '2px'}}> 电话:{reporter.phone}</div>
                    <div style={{marginTop: '2px'}}> 状态:{reporter.status == 1 ? "未领取" : "已领取"}</div>
                </Flex.Item>
            </Flex>
        </div>
    }

    render() {
        var interviewContent_titles = <div>
            <div
                style={{fontSize: '14px'}} className={'clue_tittle'}>采访内容
            </div>
            <div className={'interview_colon'}>:</div>
        </div>;
        var interviewRequire_titles = <div>
            <div
                style={{fontSize: '14px'}} className={'clue_tittle'}>采访要求
            </div>
            <div className={'interview_colon'}>:</div>
        </div>;
        const {getFieldProps} = this.props.form;
        return <div>
            <Card style={{paddingTop: '20px', paddingLeft: '19px'}}>
                <div>采访信息</div>
                <WingBlank size="lg" style={{margin:'5px'}}>
                    <div className={'storyAttribute'}>
                        <Card>
                            <List>
                                <InputItem
                                    {...getFieldProps('title', {
                                        rules: [{required: true}],
                                        initialValue: this.props.interviewInfo.title
                                    })}
                                    placeholder="请输入任务名称"
                                    moneyKeyboardAlign="left"
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    type={'text'}
                                    editable={false}
                                >
                                    <div className={'interview_tittle'}>任务名称</div>
                                    <div className={'interview_colon'}>:</div>
                                    </InputItem>

                                <InputItem
                                    clear
                                    {...getFieldProps('place', {initialValue: this.props.interviewInfo.place})}
                                    placeholder="请输入采访地点"
                                    moneyKeyboardAlign="left"
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    type={'text'}
                                    editable={false}
                                >
                                    <div className={'interview_tittle'}>采访地点</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <InputItem placeholder={'采访时间'}
                                           {...getFieldProps('_interviewDate', {initialValue: formatDate(this.props.interviewInfo.interviewDate.time)})}
                                           editable={false}
                                >
                                    <div className={'interview_tittle'}>采访时间</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <InputItem placeholder={'回稿时间'}
                                           {...getFieldProps('_endDate', {initialValue: formatDate(this.props.interviewInfo.endDate.time)})}
                                           editable={false}
                                >
                                    <div className={'interview_tittle'}>回稿时间</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>
                                <InputItem
                                    clear
                                    {...getFieldProps('contacts', {initialValue: this.props.interviewInfo.contacts})}
                                    placeholder="请输入联系人"
                                    moneyKeyboardAlign="left"
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    type={'text'}
                                    editable={false}
                                >
                                    <div className={'interview_tittle'}>联系人</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <InputItem
                                    {...getFieldProps('contactsWay', {initialValue: this.props.interviewInfo.contactsWay})}
                                    type={'phone'}
                                    clear
                                    placeholder="请输入联系方式"
                                    moneyKeyboardAlign="left"
                                    moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                    required
                                    editable={false}
                                >
                                    <div className={'interview_tittle'}>联系方式</div>
                                    <div className={'interview_colon'}>:</div>
                                </InputItem>

                                <TextareaItem style={{fontSize: '15px'}}
                                              {...getFieldProps('content', {
                                                  initialValue: this.props.interviewInfo.content
                                              })}
                                              rows={5}
                                              editable={false}
                                              title={interviewContent_titles}
                                />

                                <TextareaItem style={{fontSize: '15px'}}
                                              {...getFieldProps('interviewRequire', {
                                                  initialValue: this.props.interviewInfo.interviewRequire
                                              })}
                                              rows={5}
                                              editable={false}
                                              title={interviewRequire_titles}
                                />

                                <div className={'intervidew_detail_video'}>附件音频</div>
                                {<div className={"audioPicker"}><ImagePicker
                                    accept={"audio/*"}
                                    onAddImageClick={this.addImage}
                                    onImageClick={this.openAudioView.bind(this)}
                                    files={this.state.attachAudio}
                                    onChange={this.onChangeAudio}
                                    selectable={false}
                                    multiple={this.state.multiple}
                                    disableDelete={true}
                                /></div>}

                                <div className={'intervidew_detail_video'}>附件视频</div>
                                {<ImagePicker
                                    accept={"video/*"}
                                    onAddImageClick={this.addImage}
                                    onImageClick={this.openVideoView.bind(this)}
                                    files={this.state.attachVideo}
                                    onChange={this.onChangeVideo}
                                    selectable={false}
                                    multiple={this.state.multiple}
                                    disableDelete={true}
                                />}

                                <div>参与记者:
                                    <div className={'userAdd'}>
                                        <div className="reportlist">
                                            <Flex wrap="wrap" align={'start'}>
                                                {this.showReporter(this.state.selectUsers)}
                                            </Flex>
                                        </div>
                                    </div>
                                </div>

                                <Modal
                                    popup
                                    visible={this.state.showVideoView}
                                    animationType="slide-up"
                                    afterClose={() => {
                                    }}
                                >
                                    <div style={{height: '100vh'}}>
                                        <div onClick={() => {
                                            this.closeVideo()
                                        }} style={{backgroundColor: "#000000", color: '#ffffff'}}>关闭
                                        </div>
                                        {this.state.isVideo?<div style={{height: '94vh'}}>
                                            <Player autoPlay={true} fluid={false} width={'100%'} height={'100%'}>
                                                <source src={this.state.videoSources}/>
                                            </Player>
                                        </div>:<div className={'wrapper'}>
                                            <audio src={this.state.audioSource} controls="controls">
                                            </audio>
                                        </div>}
                                    </div>
                                </Modal>
                            </List>
                        </Card>
                    </div>
                </WingBlank>
            </Card>
        </div>
    }
}

const
    InterviewDetailCardForm = createForm()(InterviewDetailCard);
export default InterviewDetailCardForm
