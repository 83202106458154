import React, {Component} from 'react'
import {
    Icon,
    NavBar,
    Picker,
    List,
    InputItem,
    Checkbox,
    TextareaItem,
    Button,
    ImagePicker,  WingBlank,  Toast,
} from "antd-mobile";
import {createForm} from 'rc-form';
import {setStoryAttribute, setNoRefresh, setWbextends} from "../../store/actions";
import {connect} from "react-redux";
import {
    getExtendDatasByColumn,
    getInfoFromLocalStorage,
    getUserDeptList,
    uploadAttachmentFile,
    uploadimage
} from "../../action";
import {ArrayDuplication, checkContainsEmoji, getPhoneFile, permissionCheck, uuid} from "../../utils";
import './storyAtrribute.css';
import config from "../../config";
import {PhotoSlider} from "react-photo-view";
import DynamicrenderFormData from "../../components/DynamicRenderForm/DynamicrenderFormData";
import SelectPicListview from "../../components/selectPicListView/SelectPicListview";
import {saveStory} from "../../components/activity/StorySave";
import $ from 'jquery';
import SubcolumnspublishSettings from "../../components/subcolumnsPublishSettings/SubcolumnspublishSettings";
import {ATTCHMENT_TYPE, PIC_TYPE, VIDEO_TYPE,FILE_TYPE,AUDIO_TYPE} from "../../constants";
import * as Actions from "../../action/topiclist";
import Modal from "antd-mobile/es/modal";
import {setStory} from "../../store/actions/storyAction";
import MyImgPicker from "../../components/MyImgPicker";



// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

const CheckboxItem = Checkbox.CheckboxItem;
class StoryAtrribute extends Component {
    constructor(props) {
        super(props);
        //标题图回填；
        let files = [];
        if (this.props.storyAttribute.titlePic) {

            let file = {url: this.props.storyAttribute.titlePic}
            files.push(file)
        }
        this.state = {
            pageName: "稿件属性",
            multiple: false,
            history: require("history").createHashHistory,
            files: files,
            userDeptList: [],
            author: '',
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            editer: props.storyAttribute.editer,
            authorTel: props.storyAttribute.authorTel,
            source: props.storyAttribute.source,
            deptId: props.storyAttribute.deptId,
            photography: props.storyAttribute.photography,
            titlePic: props.storyAttribute.titlePic,
            linkurl:props.storyAttribute.linkurl,
            type: 'money',
            showPicView: false,
            picIndex: 0,
            picFiles: files,
            chooseColumn: '',
            extendFieldData: [],
            showContentPic: false,
            contenttPicInfos: [],
            tsFileIds:[],
            storyFileids:[]
        }
    }

    child;

    selectPicChild;

    /**
     * 返回按钮事件
     */
    goBack() {
       
        this.props.setNoRefresh(true);
        this.state.history().goBack();
    }

    componentDidMount() {
        let chooseColumn = this.props.story.chooseColumn;
        let chooseColumnStr = "";
        var columndata = null;
        let treeIds = "";
        let subColumn=[];
        if (chooseColumn && chooseColumn.length > 0) {

            for (var i = 0; i < chooseColumn.length; i++) {
                columndata = chooseColumn[i];
                if (columndata) {
                    subColumn.push(columndata);
                    chooseColumnStr += columndata.treeName + ",";
                    treeIds += columndata.treeId + ",";
                }
            }
        }
        console.info("componentDidMount    subColumn",subColumn);

        this.setState({
            chooseColumnStr: chooseColumnStr,
            subColumn,

        })
        let param = {};
        this.getEnclosure()
        getUserDeptList(param, this.updateuserDeptList.bind(this));

        //更具栏目获取扩展字段的信息；
        let params = new FormData();
        params.append("mediaCode", getInfoFromLocalStorage("currentMediaCode"));
        params.append("storyId", this.props.story.id ? this.props.story.id : 0);
        params.append("treeId", treeIds);
        getExtendDatasByColumn(params, this.callgetExtendDatasByColumn.bind(this));

        //获取稿件内容图片
        this.getStoryContentImg();
    }

    /**
     * 获取稿件内容图片
     */
    getStoryContentImg() {
        let story = saveStory(false);
        let content = story.content;
        let doc = $(content);
        let images = doc.find("img")
        let imageElements = [];
        if (images && images.length > 0) {
            for (var i = 0; i < images.length; i++) {
                var img={src:images[i].getAttribute("src")};
                imageElements.push(img);
            }
        }

        //解析稿件内容中的图片；
        this.setState({
            contenttPicInfos: imageElements
        })

    }

    /**
     * 获取扩展字段的信息
     * @param data
     */
    callgetExtendDatasByColumn(data) {
        if (data) {
            //返回的扩展字段，和稿件的扩展字段的值对比，替换成前台 store中的最新数据；
            let storyExtendDatas = this.props.story.wbextends;
            let fieldCode;

            //替换成前台缓存的数据；
            for (var i = 0; i < data.length; i++) {
                fieldCode = data[i].fieldCode;
                if (storyExtendDatas[fieldCode]) {
                    data[i].initialValue = storyExtendDatas[fieldCode];
                }
            }

            this.setState({
                extendFieldData: data,
            })
        }
    }

    updateuserDeptList(data) {
        let userDeptList = data.map((item) => {
            return {
                'label': item.name,
                'value': item.id
            }
        })

        //默认让选中第一个部门
        let depid = this.props.storyAttribute.deptId ? this.props.storyAttribute.deptId : userDeptList[0].value

        this.setState({
            userDeptList: userDeptList,
            defaulteDepId: [depid],
        })
    }


    /**
     * 选择图片
     * @param files
     * @param type
     * @param index
     */
    onChange = (files, type, index) => {
        if (type === 'add') {

            let data = new FormData();
            data.append('file', files[0].file);

            //调用后台接口上传文件，
            Toast.loading("上传中");
            uploadimage(data, this.setImageUrlToStoryAttribut.bind(this));
            this.setState({
                files: files,
                picFiles: files
            });
        }

        if (type === 'remove') {
            this.setState({
                titlePic: "",
                files: [],
                picFiles: [],
            })
        }
    }

    /**
     * 添加图片
     */
    addImage = () => {
      
        if (appUploadFlag) {
            //获取，相册中的图片，
            getPhoneFile(this.appUploadFile.bind(this));
        }
    }

    /**
     * 从app中上传图片
     * @param files
     */
    appUploadFile(files) {
        this.onChange(files, 'add', 0);
    }

    /**
     *
     */

    /**
     * 把封面图片的url放到redux中的storyAttribute中
     * @param data
     */
    setImageUrlToStoryAttribut(data) {
        Toast.hide();
        this.setState({
            titlePic: data.url
        })
    }

    validateFormData() {
        let storyAttribute = this.props.form.getFieldsValue();
        if (storyAttribute.author && storyAttribute.author.trim().length > 0) {
            if (checkContainsEmoji(storyAttribute.author)) {
                Toast.fail('作者中不可以包含表情图!');
                return false;
            }
        }
        return true;
    }

    /**
     * 暂时保存稿件属性到store
     */
    saveAttributeToStore() {

        let extendData = this.child.props.form.getFieldsValue();
        let extendDatas=this.child.state.extenData;
        extendData=$.extend(extendData,extendDatas);
        this.props.setWbextends(extendData);

        let checkResult = this.validateFormData();
        if (!checkResult) {
            return false;
        }
        //获取稿件的属性数据

        let storyAttribute = this.props.form.getFieldsValue();
        storyAttribute.titlePic = this.state.titlePic
        storyAttribute.deptId = this.props.form.getFieldValue('deptId')[0]
        let phone = this.props.form.getFieldValue('authorTel');
        if (phone) {
            phone = phone.replace(/\s*/g, "");
        }
      
        storyAttribute.authorTel = phone;

        //手机号码校验
        // 同时验证手机号和座机号， 手机号码格式无需校验。
        // var regex = new RegExp("^((0\\d{2,3}-\\d{7,8})|(1[3584]\\d{9}))$");
        // var regex = new RegExp("");
        // if (phone && !regex.test(phone)) {
        //     Toast.info('联系方式格式错误', 2, null, false);
        //     return;
        // }

        this.props.setStoryAttribute(storyAttribute);
        this.props.setNoRefresh(true);
        this.state.history().goBack();
    }

    closePic() {
        this.setState({
            showPicView: false
        })
    }

    openPicView(index) {
        this.setState({
            showPicView: true,
            picIndex: index
        })
    }

    setPicIndex(index) {
        this.setState({
            picIndex: index,
        })
    }

    goToChooseCloumn() {
        let extendData = this.child.props.form.getFieldsValue();
        this.props.setWbextends(extendData);
        let checkResult = this.validateFormData();
        if (!checkResult) {
            return false;
        }
        //获取稿件的属性数据
        let storyAttribute = this.props.form.getFieldsValue();
        storyAttribute.titlePic = this.state.titlePic
        storyAttribute.deptId = this.props.form.getFieldValue('deptId')[0]
        let phone = this.props.form.getFieldValue('authorTel');
        if (phone) {
            phone = phone.replace(/\s*/g, "");
        }
      
        storyAttribute.authorTel = phone;

        //手机号码校验
        // var regex = new RegExp("^((0\\d{2,3}-\\d{7,8})|(1[3584]\\d{9}))$");
        // if (phone && !regex.test(phone)) {
        //     Toast.info('联系方式格式错误', 2, null, false);
        //     return;
        // }
        // if(getInfoFromLocalStorage('isContributeRoleFlag')){
        //     if(!phone||phone==""){
        //         Toast.info('请输入联系方式', 2, null, false);
        //     }
        // }

        this.props.setStoryAttribute(storyAttribute);
        this.props.setNoRefresh(true);

        var url = '/columnSelectPage/columnSelectPage/' + (this.props.story.id ? this.props.story.id : 0);
        this.state.history().push(url);

    }

    //从正文中选择图片；
    selectTitlePicFromContent() {
        this.selectPicChild.openPicShow();
    }

    //选择图片确定事件；
    picListOk(data) {
        if(data){
            let files=[{url:data}]
            this.setState({
                files:files,
                picFiles:files,
                titlePic:data,
            })
        }
    }


   //查看图片
    watch(url){
        this.setState({viewPicture:true,img:url})
    }
    //关闭查看图片的弹框
    onClose = key => () => {
        this.setState({viewPicture: false,});
    };
    //下载附件
    download(data){
        window.location.href = data + "?download=1";
    }

    automatic(){
            //获取内容  有内容显示内容  没有的话显示 标题
            let story = saveStory(false);
            let content = story.content;
            let oDiv = document.createElement('div');
            oDiv.innerHTML = content;
            $(oDiv).find('style').text('')
            let summary = oDiv.innerText.replace(/<[^>]*>/g, "").replace(/&[a-z]*;/g,"").replace(/\s/g,"").replace(/(.{240}).*/, "$1...");
            if(summary){
                this.props.story.summary = summary;
            }else{
                this.props.story.summary = story.pureTitle;
            }
            this.setState({})

    }
    //渲染添加按钮
    renderAddBtn() {
        let acceptAll;
        let imgAccept = "." + [...PIC_TYPE,].join(",.");
        let videoAccept = "." + [...VIDEO_TYPE].join(",.");
        let attchmentAccept = "." + [...ATTCHMENT_TYPE].join(",.");
        acceptAll="*";

        return (
            <div style={{width:'25vw',border:'1px solid #1890fe',lineHeight:'8vw',textAlign:'center',marginLeft:'70%',color:'#1890fe',position:'relative',marginBottom:25,marginTop:15}}>
                上传附件文件
                <div style={{width:'100vw',height:'8vw', opacity:0,position: 'absolute',top:0,left:0,overflow: 'hidden'}}>
                    <MyImgPicker
                        accept={acceptAll}
                        onAddImageClick={this.addImage}
                        files={this.state.videoFiles}
                        onChange={this.upload}
                        selectable={true}
                        multiple={true}
                        disableDelete={true}

                    />
                </div>
                <style>{`.am-toast-text-info{word-wrap: break-word};
               
                }`}</style>
            </div>
        )
    }
  
    //获取附件
    getEnclosure() {
        let imgAccept = [...PIC_TYPE,].join(",");
        let data =  this.props.story.storyFileids;

        if(!data){
            data = []

            this.setState({storyFileids:data})
        }
        Actions.loadAttachement({oldstoryFileIds:data}, (data) => {
            data.forEach((item, index) => {
                let nameLength=item.name.split(".").length;
                if (imgAccept.indexOf(item.name.split(".")[nameLength-1]) > -1) {
                    item.watch = 1
                }
            });
            this.setState({tsFileIds: data});
        });
    }

    //上传
    upload = (files) => {
        window.loading.show();
        let file = [...FILE_TYPE].join(",");
        let array =[];
        for (let i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                    uploadAttachmentFile(data, res => {
                        window.loading.hide();
                        let imgAccept = "." + [...PIC_TYPE,].join(",.");
                        let videoAccept = "." + [...VIDEO_TYPE].join(",.");
                        let attchmentAccept = "." + [...ATTCHMENT_TYPE].join(",.");
                        let audioAccept =  "." + [...AUDIO_TYPE].join(",.");
                        if(imgAccept.indexOf(res.storageFile.suffix)>-1){
                            let params ={
                                "pictures": [
                                    {
                                        "dataSource": 4,
                                        "name": res.storageFile.name,
                                        "ext": res.storageFile.suffix,
                                        "path": res.url,
                                        "size": res.storageFile.size,
                                        "storyId": 0,
                                        "imageHeight": res.storageFile.height,
                                        "imageWidth": res.storageFile.width
                                    }
                                ]
                            };
                            Actions.saveStoryFilePicture( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                newData.watch=true;
                                array.push(newData);
                                let dataArray = this.state.tsFileIds.concat(newData)
                                let storyFileids=this.state.storyFileids.concat(newData.id);
                                this.props.story.storyFileids=storyFileids
                                this.setState({tsFileIds:dataArray,storyFileids:storyFileids})
                            })
                        }else if(audioAccept.indexOf(res.storageFile.suffix)>-1){
                          let params ={
                              "audios": [
                                  {
                                      "dataSource": 4,
                                      "name": res.storageFile.name,
                                      "ext": res.storageFile.suffix,
                                      "path": res.url,
                                      "size": res.storageFile.size,
                                      "storyId": 0,
                                      "allTime":res.storageFile.duration,
                                       'md5':res.storageFile.md5
                                  }
                              ]
                          };
                            Actions.saveStoryFileAudio( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                array.push(newData);
                                let dataArray =this.state.tsFileIds.concat(newData)
                                let storyFileids=this.state.storyFileids.concat(newData.id);
                                this.props.story.storyFileids=storyFileids
                                this.setState({tsFileIds:dataArray,storyFileids:storyFileids})
                            })
                        }
                         else  if(videoAccept.indexOf(res.storageFile.suffix)>-1){
                            let params ={
                                "videos": [
                                    {
                                        "dataSource": 4,
                                        "name": res.storageFile.name,
                                        "ext": res.storageFile.suffix,
                                        "path": res.url,
                                        "size": res.storageFile.size,
                                        "storyId": 0,
                                        "frameHeight":res.storageFile.height,
                                        "frameWidth":res.storageFile.width,
                                        "allTime":res.storageFile.duration,
                                    }
                                ]
                            };

                            Actions.saveStoryFileVideo( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                array.push(newData);
                                let dataArray =this.state.tsFileIds.concat(newData)
                                let storyFileids=this.state.storyFileids.concat(newData.id);
                                this.props.story.storyFileids=storyFileids
                                this.setState({tsFileIds:dataArray,storyFileids:storyFileids})

                            })
                        }
                       else  if(attchmentAccept.indexOf(res.storageFile.suffix)>-1){
                            let params ={
                                "otherFiles": [
                                    {
                                        "dataSource": 4,
                                        "name": res.storageFile.name,
                                        "ext": res.storageFile.suffix,
                                        "path": res.url,
                                        "size": res.storageFile.size,
                                        "storyId": 0
                                    }
                                ]
                            };
                            Actions.saveStoryFileOtherfile( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                array.push(newData);
                                let dataArray =this.state.tsFileIds.concat(newData)
                                let storyFileids=this.state.storyFileids.concat(newData.id);
                                this.props.story.storyFileids=storyFileids;
                                this.setState({tsFileIds:dataArray,storyFileids:storyFileids})
                            })
                        }else{
                            Toast.fail('只支持'+file +'格式',);

                        }

                    });
                }
            }
        }
    };
    //删除上传的附件
        deleteFileIds(index){
        let array = [];
            this.state.tsFileIds.splice(index,1);
            this.state.tsFileIds.forEach((item,index)=>{
                array.push(item.id)
            });
            this.props.story.storyFileids=array;
            this.setState({storyFileids:array})
            this.setState({});
        }

    render() {
         //提取摘要
        let  summary =  this.props.story &&this.props.story.summary;
        let summaryCopy;
        if(summary){
            //创建div
            let oDiv = document.createElement('div');
            oDiv.innerHTML = summary;
            //去除样式
            $(oDiv).find('style').text('');
            //去除里面的空格以及转义字符
           summaryCopy = oDiv.innerText.replace(/<[^>]*>/g, "").replace(/&[a-z]*;/g,"").replace(/\s/g,"").replace(/(.{240}).*/, "$1...");
        }else{
            summaryCopy =''
        }

        const {getFieldProps} = this.props.form;
        var hasMediaCode=false;
        if(getInfoFromLocalStorage("currentMediaCode")){
            hasMediaCode=true;
        }
        return <div >
            <NavBar icon={<Icon type="left" color={'#2d2d2d'}/>}
                    mode={'light'}
                    leftContent={[<span key={uuid()} className={'nav_goback'} onClick={() => this.goBack()}>返回</span>]}
                    rightContent={[
                        <div key={uuid()} className={'nav_goback'} style={{color: '#1890ff',marginRight:15}}
                             onClick={this.saveAttributeToStore.bind(this)}>确定</div>
                    ]}
            ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>
            {/*<WhiteSpace size="lg"/>*/}
            <WingBlank size="lg" style={{margin: '5px'}}>
                <div className={'storyAttribute'}>
                    <List>
                        {(!this.state.isContributeRoleFlag)&&window.checkColumnFlag?
                            <InputItem placeholder={'选择栏目'}
                                       {...getFieldProps('_endDate', {initialValue: this.state.chooseColumnStr})}
                                       onClick={() => {
                                           this.goToChooseCloumn();
                                       }}
                                       editable={false}>
                            {/*{<div className={'attribute_colon'} style={{color: 'red', width: '2px'}}>*</div>}*/}
                            <div style={{marginLeft:30,fontSize:'14px'}}>
                                <span><span style={{color:'red'}}>*</span>栏目：</span>
                            </div>
                            {/*<div className={'attribute_colon'}>:</div>*/}
                        </InputItem> : ""}
                        <InputItem
                            clear
                            {...getFieldProps('author', {initialValue: this.props.storyAttribute.author})}
                            placeholder="请输入作者姓名"
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                        >
                            <div className={'attribute_tittle'}>
                               {/* {this.state.isContributeRoleFlag?<span style={{color:'red'}}>*</span>:""}作者：</div>*/}
                               作者：</div>
                            {/*<div className={'attribute_colon'}>:</div>*/}
                        </InputItem>

                        <InputItem
                            {...getFieldProps('authorTel', {initialValue: this.props.storyAttribute.authorTel})}
                            clear
                            placeholder="请输入电话号码"
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                        >
                            <div style={{
                                fontSize:14,
                                display:'inline-block',
                                marginLeft:8
                            }}>联系方式：</div>
                                {/*{this.state.isContributeRoleFlag?<span style={{color:'red'}}>*</span>:""}联系方式：</div>*/}
                               {/* <span style={{color:'red'}}>*</span>联系方式：</div>*/}
                            {/*<div className={'attribute_colon'}>:</div>*/}
                        </InputItem>
                        <InputItem
                            {...getFieldProps('editer', {initialValue: this.props.storyAttribute.editer})}
                            placeholder="请输入编辑姓名"
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                        >
                            <div className={'attribute_tittle'}>编辑：</div>
                            {/*<div className={'attribute_colon'}>:</div>*/}
                        </InputItem>
                        <InputItem
                            {...getFieldProps('source', {initialValue: this.props.storyAttribute.source})}
                            placeholder="请输入来源"
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                        >
                            <div className={'attribute_tittle'}>来源：</div>
                            {/*<div className={'attribute_colon'}>:</div>*/}
                        </InputItem>
                        <Picker data={this.state.userDeptList}
                                {...getFieldProps('deptId', {initialValue: this.state.defaulteDepId})}
                                cols={1}>
                            <List.Item arrow="horizontal">
                                <div style={{marginLeft:38.5,fontSize:'14px'}}>
                                    {/*{this.state.isContributeRoleFlag?<span style={{color:'red'}}>*</span>:""}部门：</div>*/}
                                    <span style={{color:'red'}}>*</span>部门：</div>
                                {/*<div className={'attribute_colon'}>:</div>*/}
                            </List.Item>
                        </Picker>

                        <div style={{marginTop:20}}>
                            <div style={{lineHeight:'30px',marginLeft:20}}>跳转链接：
                                <TextareaItem
                                    prefixListCls={'link-list'}
                                    clear
                                    rows ={3}
                                    type={'text'}
                                    placeholder="请输入链接地址"
                                    style={{height:'100%'}}
                                    {...getFieldProps('linkurl',{initialValue: this.props.storyAttribute.linkurl})}
                                    onBlur={this.onLinkInputBlur}
                                />
                            </div>
                        </div>
                        {permissionCheck(":story:summary")?
                        <div style={{marginTop:20}}>
                            <div style={{lineHeight:'30px',marginLeft:20}}>内容摘要：
                                <TextareaItem
                                    rows ={3}
                                    value = {summaryCopy}
                                    labelNumber={5}
                                    onChange={(e) => {
                                        this.props.story.summary = e;
                                        this.setState({})
                                    }}
                                />
                                <List.Item>
                                    <div style={{display:'flex',justifyContent:'flex-end',marginTop:10}}>
                                            <div style={{width:'22vw',textAlign:'center',border:'1px solid #1890fe',lineHeight:'6vw',color:'#1890fe',marginRight:10,marginBottom:10}} onClick={()=>{this.automatic()}}>自动提取</div>
                                    </div>
                                </List.Item>

                            </div>
                            <style>{`.am-list-item {padding-left:5px}
                                 .am-textarea-control textarea {font-size:14px}
                        }`}</style>
                        </div>:''}
                        {/*上传图片*/}
                        <div>
                            <div style={{marginLeft:25,marginTop:10}}>标题图：</div>
                            <div style={{background: "#fff", margin: 'auto', paddingTop: '5px', paddingBottom: '0.5px',marginLeft:10}}>
                                <ImagePicker
                                    onAddImageClick={this.addImage}
                                    files={this.state.files}
                                    onImageClick={this.openPicView.bind(this)}
                                    // selectable={false}
                                    onChange={this.onChange}
                                    // onImageClick={(index, fs) => console.log(index, fs)}
                                    selectable={this.state.files.length < 1}
                                    multiple={this.state.multiple}
                                />

                                 <div onClick={() => {
                                    this.selectTitlePicFromContent()
                                }} style={{
                                     width:'18vw',
                                     textAlign:'center',
                                     border:'1px solid #1890fe',
                                     color:'#1890fe',
                                     fontSize: '12px',
                                     lineHeight: '5vw',
                                     marginLeft:10,
                                     marginBottom:15
                                }}>正文选择
                                </div>
                            </div>
                        </div>
                        {/*附件查看和下载*/}
                        <div style={{background:'white',lineHeight:'6vw',color:'#999999',fontSize:'3vw'}}>
                            {
                                this.state.tsFileIds.length>0&&this.state.tsFileIds.map((item,index)=>{
                                    return(
                                        <div style={{display:'flex',lineHeight:'12vw'}} >
                                            <div style={{width:'70%', whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',marginLeft:10}}>{item.name}</div>
                                            {
                                                item.watch? <div style={{width:'10%',color:'#1890fe',textAlign:'center'}} onClick={() => this.watch(item.url)}>查看</div>:<div style={{width:'10%',color:'#1890fe'}}></div>
                                            }
                                            <div style={{width:'10%',color:'#1890fe',textAlign:'center'}}  onClick={() => this.download(item.url)}>下载</div>
                                            <div style={{width:'10%'}} onClick={()=>this.deleteFileIds(index)}><Icon type='cross-circle' size={'xxs'} color={'#1890fe'}/></div>
                                            {/*附件查看图片弹框*/}
                                            <Modal
                                                visible={this.state.viewPicture}
                                                transparent
                                                style={{width:'87vw'}}
                                                maskClosable={false}
                                                onClose={this.onClose('modal')}
                                                footer={[{ text: '确定', onPress: () => { this.onClose('modal')(); } }]}
                                                wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                                            >
                                                <div>
                                                    <img src={this.state.img} style={{width:'80vw',height:'80vw',objectFit:'contain'}}/>
                                                </div>
                                            </Modal>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*渲染上传附件*/}
                        {this.renderAddBtn()}
                        <div style={{ overflow: 'hidden',marginTop:10}}>
                            <SubcolumnspublishSettings dataSource={this.state.subColumn}></SubcolumnspublishSettings>
                        </div>

                        <div>
                            <div></div>
                            <DynamicrenderFormData formTitle={'扩展字段'} dataSource={this.state.extendFieldData}
                                                   onRef={(ref) => {
                                                       this.child = ref
                                                   }}/></div>

                        {/*<div style={{padding:'0vw 4vw 25vw 4vw',lineHeight:'6vw',color:'#999999',fontSize:'3vw'}}>
                            {
                                this.state.tsFileIds.length>0&&this.state.tsFileIds.map((item,index)=>{
                                    return(
                                        <div style={{display:'flex',lineHeight:'10vw'}} key={index}>
                                            <div style={{width:'95%', whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.name}</div>
                                            <div style={{width:'5%'}} onClick={()=>this.deleteFileIds(index)}><Icon type='cross-circle' size={'xxs'} color={'#1890fe'}/></div>
                                        </div>
                                    )
                                })
                            }
                        </div>*/}
                        <SelectPicListview dataSource={this.state.contenttPicInfos} clickOk={this.picListOk.bind(this)}
                                           onRef={(child) => {
                                               this.selectPicChild = child
                                           }}/>
                        <PhotoSlider
                            images={this.state.picFiles.map(item => ({src: item.url}))}
                            visible={this.state.showPicView}
                            onClose={() => (this.closePic())}
                            index={this.state.picIndex}
                            onIndexChange={this.setPicIndex.bind(this)}
                        />

                    </List>

                </div>
            </WingBlank>
        </div>
    }
}



const stateToProps = (state) => {
    return {
        storyAttribute: state.storyReducer.storyAttribute,
        story: state.storyReducer.story,
    }
}

/**
 * 将store的派发方法映射到props中
 * @param dispatch
 * @returns {{setStoryAttribute: setStoryAttribute}}
 */
const dispatchToProps = (dispatch) => {
    return {
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttributeData) {
            dispatch(setStoryAttribute(storyAttributeData))
        },
        setNoRefresh: function (noRefresh) {
            dispatch(setNoRefresh(noRefresh));
        },
        setWbextends: function (wbextends) {
            dispatch(setWbextends(wbextends))
        }
    }
}


/**
 * 组件绑定form表单
 */
const StoryAtrributeS = createForm()(StoryAtrribute);

/**
 * 组件链接 react-redux
 */
export default connect(stateToProps, dispatchToProps)(StoryAtrributeS);
