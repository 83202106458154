import {getInfoFromLocalStorage} from "../action";
import { Toast } from 'antd-mobile';
import axios from "axios";
export function uuid() {
    let s = [];
    let hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    let uuid = s.join("");
    return uuid;
}

/**
 * 时间戳转成日期时间
 * @param m
 * @returns {string}
 */
export function add0(m) {
    return m < 10 ? '0' + m : m
}

export function formatDate(date_) {
    if (!date_) {
        return "";
    }
    /* if(date_&&date_.indexOf('-')>-1){
         date_=Date.parse(date_.replace(/-/g,'/'));
     }*/
    //shijianchuo是整数，否则要parseInt转换
    var time = new Date(date_);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}


/**
 * 判断字符串为空的方法
 * @param value
 * @returns {boolean}
 */
export function isStringEmpty(value) {
    if (!value || value == 'undefined') {
        return true;
    } else {
        return false;
    }
}


/**
 * 数组按照拼音字母的分组；
 * @param data
 */
export function goupByFirstName(data) {
    var transData = {};
    data.forEach(function (item) {
        var qf = (item.englishName)[0].toUpperCase();
        transData[qf] = transData[qf] || [];
        transData[qf].push(item);
    });
    return transData;
}

/**
 * 是JSON字符串的判断
 * @param str
 * @returns {boolean}
 */
export function isJsonFormat(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


/**
 * 通过手机app的接口获取app的资源
 * @param callBack
 */
export function getPhoneFile(callBack) {
    //传给app的获取路径的方法，
}

/**
 * 处理字符串中的 标签
 * @param str
 */
export function dealElement(str) {
    return str.replace(/<[^>]*>/ig, "");
}

export function subString(str, length) {
    if (!str) {
        return "";
    }
    if (str.length < length) {
        return str;
    } else {
        return str.substr(0, length);
    }
}


export function removeArrayByIndex(index, array) {
    var resutl = [];
    for (var i = 0; i < array.length; i++) {
        if (i != index) {
            resutl.push(array[i]);
        }
    }
    return resutl;
}

/**
 * 用户权限控制；
 * @param permissionCode
 */
export function permissionCheck(permissionCode) {
    let permissioins = getInfoFromLocalStorage("permissions");
    if (!permissioins) {
        return false;
    }
    var hasPermission = false;
    let h5_permissionCode = "h5" + permissionCode;
    // let contribute_permissionCode = "contribute" + permissionCode;
    if (permissioins.indexOf(h5_permissionCode) > -1) {
        hasPermission = true;
    }
    return hasPermission;
}

/**
 * 校验输入框包含 表情图
 */
export function checkContainsEmoji(value){
    // let iconRule2 = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ig
    // return iconRule2.test(value);
    return false;
}


const ua = typeof window === 'object' ? window.navigator.userAgent : '';

let _isIOS     = -1;
let _isAndroid = -1;

export function isIOS() {
    if (_isIOS === -1) {
        _isIOS = /iPhone|iPod|iPad/i.test(ua) ? 1 : 0;
    }
    return _isIOS === 1;
}

export function isAndroid() {
    if (_isAndroid === -1) {
        _isAndroid = /Android/i.test(ua) ? 1 : 0;
    }
    return _isAndroid === 1;
}

export const headers = {
    "Pragma": 'no-cache',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded',
};

export const headerJson = {
    "Pragma": 'no-cache',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json;charset=UTF-8',
};
//所有共通的请求方法 （url,method,body,callback）

export function request(url, method, body, callback) {
    window.loading&&window.loading.show();
    if (method === 'post') {
        post(url, body, (data) => {
            window.loading && window.loading.hide();
            if(data.status){
                if(data.status===200){
                    callback(data.data)
                }else{
                    Toast.info(data.msg, 1);
                }
            } else {
                callback(data)
            }
        });
    }
    if (method === 'get') {
        get(url, body, (data) => {
            window.loading&&window.loading.hide();
            if(data.status){
                if(data.status===200){
                    callback(data.data)
                }else{
                    Toast.info(data.msg, 1);
                }
            }else {
                callback(data)
            }
        });
    }

}

export function get(url, params, callBack) {
    if (params) {
        let paramsArray = [];
        //拼接参数
        Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
        if (url.search(/\?/) === -1) {
            url += '?' + paramsArray.join('&')
        } else {
            url += '&' + paramsArray.join('&')
        }
    }
    url=url.includes('?')?url+'&time='+ new Date().getTime():url+'?time='+ new Date().getTime();
    //fetch请求
    fetch(url, {
        method: 'GET',
        cache: 'no-cache',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
    }) .then(responseHandle).then(response => response.json()).then(data => {
        callBack(data);
    }).catch((error) => {
        window.loading.hide();
        console.log(error)
    })
}

export function post(url, params, callBack) {
    //fetch请求
    url = url + '?time=' + new Date().getTime();
    fetch(url, {
        method: 'post',
        cache: 'no-cache',
        body: JSON.stringify(params),
        headers: headerJson,
        credentials: 'include',
        redirect: 'follow'
    }) .then(responseHandle).then(response => response.json()).then(data => {
        callBack(data);
    }).catch((error) => {
        window.loading.hide();
        console.log(error)
    })
}

const responseHandle = (respons) => {
    if (respons.data && respons.data == 'sys.002') {
        window.location = "index.html#/login/login";
        throw new Error("长时间未操作，身份已过期，请重新登录！");
    }else if(respons.redirected){
        window.location = "index.html#/login/login";
        throw new Error("长时间未操作，身份已过期，请重新登录！");
    }else {
        return respons;
    }
};
// 数组去重每个字段 数组, 键值；
export function ArrayDuplication(oldArr,key){
    let arr = oldArr;
    let result = {};
    for (let i = 0; i < arr.length; i++) {
        result[arr[i][key]] = arr[i]
    }

    let finalResult = [];
    for (let item in result) {
        finalResult.push(result[item])
    }

    return finalResult

}
