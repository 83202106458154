/**
 * created by lei lei
 * 通讯录组件
 *  <AddressBook ref={AddressBook=>{this.AddressBook=AddressBook}} />  加属性
 *  this.AddressBook.show('user')//选人
 *  this.AddressBook.show('depart')//选单位
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InputItem, Checkbox, Modal, List, Button, Toast} from "antd-mobile";
import {request} from "../../utils";

const CheckboxItem = Checkbox.CheckboxItem;

export default class AddressBook extends Component {
    static defaultProps = {
        title: '请选择',
        itemTitleKey: 'title',
        iteSonsKey: 'list',
        sonChNameKey: 'chName',
        sonEnNameKey: 'account',
        songCover: 'Cover',
        onOkClick: (e) => {
            console.log(e)
        },
        choose: 'one',//one 单选，more 多选
        // type:'user',//user 选人，'depart',//单位,
    };
    static propTypes = {
        title: PropTypes.string.isRequired,//头部标题,
        onOkClick: PropTypes.func.isRequired,//点击确定后的返回选中的数据
        choose: PropTypes.string.isRequired,//单选还是多选

    };

    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            visible: false,
            userChoose: [],
            name: '',
            type: '',
            defaultData:[],
            defaultKey:'',
        }
    }

    componentWillMount() {
    }

    componentDidMount() {

    }

    show(type = '',defaultData=[],defaultKey='') {
        console.log(defaultData,defaultKey)
        this.state.userChoose=[];
        this.state.visible = true;
        this.state.type = type;
        if (type === 'user') {
            let list = [];
            getUsrList({}, res => {
                if (res && res.length > 0) {
                    list = res;
                }

                list.forEach((parent,Index)=>{
                    parent[this.props.iteSonsKey].forEach((child,index)=>{
                        defaultData.forEach(current=>{
                            if(child[defaultKey]===current[defaultKey]){
                            child.checked=true;}
                        })
                    })
                });

                this.setState({userList: list,defaultData:defaultData,defaultKey:defaultKey})
            })
        } else if (type === 'depart') {
            let list = [];
            this.state.userChoose=[];
            getDept({}, res => {
                console.log(res);
                if (res && res.length > 0) {
                    list = res;
                }
                list.forEach((parent,Index)=>{
                    parent[this.props.iteSonsKey].forEach((child,index)=>{
                        defaultData.forEach(current=>{
                            if(child[defaultKey]===current[defaultKey]){
                                child.checked=true;}
                        })
                    })
                });


                this.setState({userList: list})
            })
        }
    }

    //获取人员列表
    getUserList() {
        let list = [];
        let params = {};
        if (this.state.name && this.state.name.trim().length > 0) {
            params.name = this.state.name;
        }
        getUsrList(params, res => {
            console.log(res);
            if (res && res.length > 0) {
                list = res;
            }
            list.forEach((parent,Index)=>{
                parent[this.props.iteSonsKey].forEach((child,index)=>{
                    this.state.defaultData.forEach(current=>{
                        if(child[this.state.defaultKey]===current[this.state.defaultKey]){
                            child.checked=true;}
                    })
                })
            });
            this.setState({userList: list})
        })
    }

    //获取涉及单位列表
    getDept() {
        let list = [];
        let params = {};
        if (this.state.name && this.state.name.trim().length > 0) {
            params.name = this.state.name;
        }
        getDept(params, res => {
            console.log(res);
            if (res && res.length > 0) {
                list = res;
            }
            list.forEach((parent,Index)=>{
                parent[this.props.iteSonsKey].forEach((child,index)=>{
                    this.state.defaultData.forEach(current=>{
                        if(child[this.state.defaultKey]===current[this.state.defaultKey]){
                            child.checked=true;}
                    })
                })
            });
            this.setState({userList: list})
        })
    }


    render() {
        let contentWidth = document.body.clientWidth;
        return (
            <div style={{}}>
                <Modal
                    visible={this.state.visible}
                    onClose={() => {
                        this.setState({visible: false})
                    }}
                    animationType="slide-up"
                >
                    <div>
                        <div style={{display: 'flex', background: "white"}}>
                            <div style={{
                                width: 100,
                                height: 45,
                                lineHeight: '45px',
                                textAlign: "center",
                                fontSize: 13,
                            }} onClick={() => {
                                this.setState({visible: false})
                            }}>取消
                            </div>
                            <div style={{
                                width: contentWidth - 100,
                                height: 45,
                                lineHeight: '45px',
                                textAlign: "center",
                                fontSize: 13,
                            }}>{this.props.title}</div>
                            <div
                                style={{width: 100, height: 45, lineHeight: '45px', textAlign: "center", fontSize: 13,}}
                                onClick={() => {
                                    this.onOkClick()
                                }}>确定
                            </div>
                        </div>

                        <div style={{}}>

                            <div style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                paddingTop: 20,
                                paddingBottom: 20,
                                background: '#f5f5f9'
                            }}>
                                <InputItem
                                    clear
                                    placeholder="搜索"
                                    moneyKeyboardAlign="left"
                                    type={'text'}
                                    style={{fontSize: '14px'}}
                                    onChange={(e) => {
                                        this.onSearch(e)
                                    }}
                                />
                            </div>

                        </div>
                        <div style={{display: 'flex', background: "white"}}>
                            <div
                                style={{
                                    width: contentWidth - 30,
                                    height: document.documentElement.clientHeight - 130,
                                    overflow: 'auto',
                                }}>
                                {this.state.userList &&this.state.userList.length?this.state.userList.map((item, index) => {

                                    return (<div key={index + '0' + index} id={item[this.props.itemTitleKey]}>
                                        <div style={{
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                            color: '#999999',
                                            height: 40,
                                            lineHeight: '40px',
                                            borderBottom: '1px solid #e5e5e5', textAlign: 'left'
                                        }}>
                                            <div style={{paddingLeft: 20}}>{item[this.props.itemTitleKey]}</div>
                                        </div>
                                        {
                                            item[this.props.iteSonsKey].map((itemUser, userIndex) => {
                                                return (
                                                    <div key={index + '1' + userIndex} style={{
                                                        height: 45,
                                                        display: 'flex',
                                                        borderBottom: '1px solid #e5e5e5',
                                                        marginTop: 5
                                                    }}>
                                                        <div>
                                                            <img style={{width: 40, height: 40, marginLeft: 20}}
                                                                 src={itemUser[this.props.songCover] ? itemUser[this.props.songCover] : 'images/header_boys.png'}
                                                                 alt=""/>
                                                        </div>
                                                        <div style={{marginLeft: 20, width: 300, whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                                                            <div style={{
                                                                height: 20,
                                                                lineHeight: '20px',
                                                                color: '#999999',
                                                                whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',
                                                                fontSize: 14, textAlign: 'left', fontWeight: 400
                                                            }}>{itemUser[this.props.sonChNameKey]}</div>
                                                            <div style={{
                                                                height: 20,
                                                                lineHeight: '20px',
                                                                whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',
                                                                color: '#cccccc',
                                                                fontSize: 12, textAlign: 'left', fontWeight: 400,
                                                            }}>{itemUser[this.props.sonEnNameKey]}</div>
                                                        </div>
                                                        <CheckboxItem key={index + '' + userIndex}
                                                                      checked={itemUser.checked} onChange={() => {
                                                            this.userClick(itemUser, index, userIndex)
                                                        }}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>)
                                }):<div style={{marginTop:'200px',marginLeft:'12%'}}>暂无数据</div>}
                            </div>
                            <div style={{width: 20, position: 'fixed', right: 0, overflow: 'auto', height: document.documentElement.clientHeight - 130,}}>
                                {this.state.userList && this.state.userList.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div style={{
                                                width: 8,
                                                height: 20,
                                                color: '#999999',
                                                cursor: 'pointer',
                                                lineHeight: '20px',
                                            }} onClick={() => {
                                                this.rightClick(item[this.props.itemTitleKey])
                                            }}>
                                                {item[this.props.itemTitleKey]}
                                            </div>

                                        </div>)
                                })}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
    onSearch(e) {
        this.state.name = e;
        let type = this.state.type;
        if (type === 'user') {
            this.getUserList();
        } else if (type === 'depart') {
            this.getDept()
        }
    }
    //右侧菜单点击跳转
    rightClick(key) {
        let anchorElement = document.getElementById(key);
        // 如果对应id的锚点存在，就跳转到锚点
        anchorElement.scrollIntoView();
    }

    //用户每一项点击
    userClick(record, parentIndex, childIndex) {
        if (this.props.choose === 'one') {
            this.state.userChoose=[];
            this.state.userList.forEach((parent,Index)=>{
                parent[this.props.iteSonsKey].forEach((child,index)=>{
                    child.checked=false;
                })
            });
            if (this.state.userChoose.length === 1) {
                    Toast.info('最多只能选择一个', 1);
                    return;
            } else {
                this.state.userList[parentIndex][this.props.iteSonsKey][childIndex].checked = !record.checked;
            }
        }

        if (this.props.choose === 'more') {
            this.state.userList[parentIndex][this.props.iteSonsKey][childIndex].checked = !record.checked;
        }

        let userChoose = [];
        this.state.userList && this.state.userList.forEach(item => {
            item[this.props.iteSonsKey].forEach(itemChild => {
                if (itemChild.checked) {
                    userChoose.push(itemChild)
                }
            })
        });
        this.setState({userChoose})
    }

    //点击确定返回
    onOkClick() {
        let userChoose =[];
       /* if(this.state.userChoose&&this.state.userChoose.length){
            userChoose=this.state.userChoose;
        }*/
        this.state.userList && this.state.userList.forEach(item => {
            item[this.props.iteSonsKey].forEach(itemChild => {
                if (itemChild.checked) {
                    userChoose.push(itemChild)
                }
            })
        });
        this.props.onOkClick && this.props.onOkClick({type:this.state.type,list:userChoose});
        this.setState({visible: false});
    }

}

const host = '/cm-sites-app-h5/';
export const getUsrList = (params, callBack) => {  //获取联系人列表
    request(host + 'mobileTopicselection/userList', 'get', params, callBack)
};
export const getDept = (params, callBack) => {  //获取涉及单位
    request(host + 'mobileTopicselection/getDept', 'get', params, callBack)
};