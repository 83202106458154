import React, {Component} from 'react';
import {TabBar, WingBlank} from 'antd-mobile';
import HomePage from "./HomePage";
import MessagePage from "./MessagePage";
import BusinessPage from "./BusinessPage";
import MyPage from "./MyPage";
import {connect} from "react-redux";
import {setSelectTab} from "../../store/actions";
import StoryPage from "./StoryPage";
import {getInfoFromLocalStorage, getUnReadMsgCount, setInfoToLocalStorage} from "../../action";

class NavigationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.props.match.params.selectedTab,
            hidden: false,
            fullScreen: false,
            allUnReadCount: 0,
            userName: getInfoFromLocalStorage("userName")
        };
    }


    //通过路由让他跳转
    renderContent(pageText) {
        if (pageText !== this.state.selectedTab) {
            return;
        }
        let content = "";
        switch (pageText) {
            case 'homeTab':
                content = <WingBlank style={{marginLeft: '5px', marginRight: '5px'
                   }}><HomePage
                    isContributeRoleFlag={getInfoFromLocalStorage("isContributeRoleFlag")}></HomePage></WingBlank>;
                break;
            case 'storyTab':
                content = <StoryPage isContributeRoleFlag={getInfoFromLocalStorage("isContributeRoleFlag")}
                                     userName={getInfoFromLocalStorage("userName")}></StoryPage>;
                break;
            case 'messageTab':
                content = <MessagePage></MessagePage>;
                break;
            case 'businessTab':
                content = <BusinessPage></BusinessPage>;
                break;
            case 'myTab':
                content = <MyPage></MyPage>;
                break;
        }
        return content;
    }

    componentWillMount() {
        var param = {};
        getUnReadMsgCount(param, this.updateMessageCount.bind(this));
    }

    updateMessageCount(data) {
        var allUnReadCount = 0;
        if (data) {
            for (let key in data) {
                allUnReadCount += data[key];
            }
        }
        this.setState({
            allUnReadCount: allUnReadCount
        })
    }

    render() {
        return (<div style={this.state.fullScreen ? {
            position: 'fixed',
            height: '100vh',
            width: '100%',
            top: 0
        } : {height: '100vh'}}>
            {/*<TabBar*/}
            {/*    unselectedTintColor="#949494"*/}
            {/*    tintColor="#33A3F4"*/}
            {/*    barTintColor="white"*/}
            {/*    hidden={this.state.hidden}*/}
            {/*>*/}
            {/*<TabBar.Item*/}
            {/*    title="首页"*/}
            {/*    key="home"*/}
            {/*    icon={<div style={{*/}
            {/*        width: '22px',*/}
            {/*        height: '22px',*/}
            {/*        background: 'url("images/shouye.png") center center /  21px 21px no-repeat'*/}
            {/*    }}*/}
            {/*    />*/}
            {/*    }*/}
            {/*    selectedIcon={<div style={{*/}
            {/*        width: '22px',*/}
            {/*        height: '22px',*/}
            {/*        background: 'url("images/shouye_select.png") center center /  21px 21px no-repeat'*/}
            {/*    }}*/}
            {/*    />*/}
            {/*    }*/}
            {/*    selected={this.state.selectedTab === 'homeTab'}*/}
            {/*    onPress={() => {*/}
            {/*        this.props.setSelectTab('homeTab');*/}
            {/*        this.setState({*/}
            {/*            selectedTab: 'homeTab',*/}
            {/*        });*/}
            {/*    }}*/}
            {/*    data-seed="logId"*/}
            {/*>*/}
            {this.renderContent('homeTab')}
            {/*</TabBar.Item>*/}
            {/*<TabBar.Item*/}
            {/*    title="稿件"*/}
            {/*    key="story"*/}
            {/*    icon={<div style={{*/}
            {/*        width: '22px',*/}
            {/*        height: '22px',*/}
            {/*        background: 'url("images/gaojian.png") center center /  21px 21px no-repeat'*/}
            {/*    }}*/}
            {/*    />*/}
            {/*    }*/}
            {/*    selectedIcon={<div style={{*/}
            {/*        width: '22px',*/}
            {/*        height: '22px',*/}
            {/*        background: 'url("images/gaojian_select.png") center center /  21px 21px no-repeat'*/}
            {/*    }}*/}
            {/*    />*/}
            {/*    }*/}
            {/*    selected={this.state.selectedTab === 'storyTab'}*/}
            {/*    // badge={1}*/}
            {/*    onPress={() => {*/}
            {/*        this.props.setSelectTab('storyTab');*/}
            {/*        this.setState({*/}
            {/*            selectedTab: 'storyTab',*/}
            {/*        });*/}
            {/*    }}*/}
            {/*    data-seed="logId"*/}
            {/*>*/}
            {/*    {this.renderContent('storyTab')}*/}
            {/*</TabBar.Item>*/}
            {/*<TabBar.Item*/}
            {/*    icon={*/}
            {/*        <div style={{*/}
            {/*            width: '22px',*/}
            {/*            height: '22px',*/}
            {/*            background: 'url("images/yewu.png") center center /  21px 21px no-repeat'*/}
            {/*        }}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    selectedIcon={*/}
            {/*        <div style={{*/}
            {/*            width: '22px',*/}
            {/*            height: '22px',*/}
            {/*            background: 'url("images/yewu_select.png") center center /  21px 21px no-repeat'*/}
            {/*        }}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    title="业务"*/}
            {/*    key="business"*/}
            {/*    selected={this.state.selectedTab === 'businessTab'}*/}
            {/*    onPress={() => {*/}
            {/*        this.props.setSelectTab('businessTab');*/}
            {/*        this.setState({*/}
            {/*            selectedTab: 'businessTab',*/}
            {/*        });*/}
            {/*    }}*/}
            {/*    data-seed="logId1"*/}
            {/*>*/}
            {/*    {this.renderContent('businessTab')}*/}
            {/*</TabBar.Item>*/}
            {/*<TabBar.Item*/}
            {/*    icon={*/}
            {/*        <div style={{*/}
            {/*            width: '22px',*/}
            {/*            height: '22px',*/}
            {/*            background: 'url("images/xiaoxi.png") center center /  21px 21px no-repeat'*/}
            {/*        }}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    selectedIcon={*/}
            {/*        <div style={{*/}
            {/*            width: '22px',*/}
            {/*            height: '22px',*/}
            {/*            background: 'url("images/xiaoxi_select.png") center center /  21px 21px no-repeat'*/}
            {/*        }}*/}
            {/*        />*/}
            {/*    }*/}
            {/*    badge={this.state.allUnReadCount}*/}
            {/*    title="消息"*/}
            {/*    key="message"*/}
            {/*    dot={this.state.allUnReadCount > 0}*/}
            {/*    selected={this.state.selectedTab === 'messageTab'}*/}
            {/*    onPress={() => {*/}
            {/*        this.props.setSelectTab('messageTab');*/}
            {/*        this.setState({*/}
            {/*            selectedTab: 'messageTab',*/}
            {/*        });*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {this.renderContent('messageTab')}*/}
            {/*</TabBar.Item>*/}
            {/*<TabBar.Item*/}
            {/*    icon={{uri: 'images/wode.png'}}*/}
            {/*    selectedIcon={{uri: 'images/wode_select.png'}}*/}
            {/*    title="我的"*/}
            {/*    key="my"*/}
            {/*    selected={this.state.selectedTab === 'myTab'}*/}
            {/*    onPress={() => {*/}
            {/*        this.props.setSelectTab('myTab');*/}
            {/*        this.setState({*/}
            {/*            selectedTab: 'myTab',*/}
            {/*        });*/}
            {/*    }}*/}
            {/*>*/}
            {/*    {this.renderContent('myTab')}*/}
            {/*</TabBar.Item>*/}
            {/*</TabBar>*/}
        </div>);
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setSelectTab: function (selectedTab) {
            dispatch(setSelectTab(selectedTab));
        }
    }
}

export default connect(null, dispatchToProps)(NavigationPage);
