import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Modal,Toast} from "antd-mobile";
import * as Actions from "../../action/taskManagement";

class subjectSubmit extends Component {
    static defaultProps = {
        callbackDate: (date) => {
            console.log(date)
        }
    };
    static propTypes = {
        bindId: PropTypes.string,//绑定的id上传的时候必须填写
    };
    componentDidMount() {
    }
    show(call) {
        this.call=call;//流程回调不懂请勿修改；必须要存在
        this.state.visible = true;
        this.getDetsils()
        //this.setState({})
    }
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            mediaCategoryList:[]
        }
    }
    getDetsils(){
        Actions.taskListDetails({interviewId:this.props.bindId}, (data) => {
            console.log(data.mediaNameList);
           let mediaCategoryList=[];
           if(data.mediaNameList&&data.mediaNameList.length>0){
               data.mediaNameList.forEach(item=>{
                   let title='';
                   switch (item.platform) {
                       case "1":
                           title='传统媒体';
                           break;
                       case "2":
                           title='中文资讯综合类';
                           break;
                       case "3":
                           title='短视频类平台';
                           break;
                       case "4":
                           title='英文媒体类平台';
                           break;
                   }
                   if(mediaCategoryList.length===0){
                       mediaCategoryList.push({
                           platform:item.platform,
                           title:title,
                           list:[{
                               ...item,
                               code:item.code,
                               name:item.name,
                               Checked:true,
                           }]
                       })
                   }else{
                       let numMax=0;
                       mediaCategoryList.forEach((Item,index)=>{
                           if(Item.platform===item.platform){
                               Item.list.push({
                                   ...item,
                                   code:item.code,
                                   name:item.name,
                                   Checked:true,
                               })
                           }else{
                               numMax++
                           }
                       });
                       if(numMax===mediaCategoryList.length){
                           mediaCategoryList.push({
                               platform:item.platform,
                               title:title,
                               list:[{
                                   ...item,
                                   code:item.code,
                                   name:item.name,
                                   Checked:true,
                               }]
                           })
                       }
                   }
               });
           }
           this.setState({mediaCategoryList})

        });
    }

    //确认选择
    sure() {
        let array = [];
        this.state.mediaCategoryList.forEach((item, index) => {
            item.list.forEach((items, index) => {
                if (items.Checked === true) {
                    array.push(items)
                }
            })

        });
      if(!array.length){
          Toast.info('至少选择一项', 2);
          return
      }
        this.call&&this.call(array);
        this.props.callbackDate && this.props.callbackDate(array)
        this.setState({visible: false})
    }
    //默认选择
    defaultSure() {
        let array = [];
        this.state.mediaCategoryList.forEach((item, index) => {
            item.list.forEach((items, index) => {
                    array.push(items)
            })
        });
       this.call&&this.call(array);
        this.props.callbackDate && this.props.callbackDate(array);
        this.setState({visible: false})
    }
    clickItem(item) {
        item.Checked = !item.Checked;
        this.setState({})
    }

    render() {
        return (
            <Modal
                visible={this.state.visible}
                transparent
                maskClosable={true}
                closable={true}
                onClose={() => {
                    this.setState({visible: false})
                }}
                style={{width: '90%', height: '100vw'}}
                title="选择提交平台"
                footer={[
                    {text: '确认选择', onPress: () => this.sure(), style: 'default'},
                    {text: '默认提交', onPress: () => this.defaultSure()},
                ]}
                wrapClassName={'chooseModal'}
                wrapProps={{onTouchStart: this.onWrapTouchStart}}
                // afterClose={() => { alert('afterClose'); }}
            >
                <style>{`.chooseModal .am-modal-body{height:73vw}`}</style>
                <div style={{backgroundColor: "#fff"}}>
                    <div >
                        {this.state.mediaCategoryList.map((item1, index1) => {
                            return <div key={index1}>
                                <div style={{
                                    fontSize: '4vw',
                                    textAlign: 'left',
                                    padding: '2vw'
                                }}>{item1.title}</div>
                                <div style={{display: "flex", flexWrap: "wrap", cursor: "pointer"}}>
                                    {item1.list.map((item2, index2) => {
                                        let bgColor;
                                        switch (item2.type) {
                                            case 1:
                                                bgColor = '#3399ff';
                                                break;
                                            case 2:
                                                bgColor = '#00cc66';
                                                break;
                                            case 3:
                                                bgColor = '#ff5918';
                                                break;
                                            case 4:
                                                bgColor = '#ff0050';
                                                break;
                                            case 5:
                                                bgColor ='#fb6dd5';
                                                break;
                                            case 6:
                                                bgColor ='#fbce02';
                                                break;
                                        }
                                        return <div key={index2} onClick={() => this.clickItem(item2)} style={{
                                            backgroundColor: "#eee",
                                            borderRadius: "24px",
                                            padding: "2vw 3vw",
                                            textAlign: "center",
                                            margin: "2vw",
                                            background: !item2.Checked ?'#eee':bgColor ,
                                            color: !item2.Checked ? "#000":"#fff" ,
                                            overflow: "hidden"
                                        }}>{item2.name}</div>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default subjectSubmit;