import {Calendar, InputItem, List} from "antd-mobile";
import React, {Component} from "react";
import {formatDate} from "../../utils";
import {createForm} from "rc-form";


const extra = {
    '2017/07/15': {info: 'Disable', disable: true},
};

const now = new Date();
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 5)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 6)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8)] = {info: 'Disable', disable: true};

Object.keys(extra).forEach((key) => {
    const info = extra[key];
    const date = new Date(key);
    if (!Number.isNaN(+date) && !extra[+date]) {
        extra[+date] = info;
    }
});

class DateSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateShowPanel: false,
            initialValue: this.props.initialValue,
            interviewDataconfig: {pickTime: true,},
        }
    }

    componentWillMount() {
        this.showModal = this.showModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onConfirmEndData = this.onConfirmEndData.bind(this);
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    /**
     * 回稿时间的确认按钮
     */
    onConfirmEndData = (startTime, endTime) => {
        let fieldCodes = this.props.fieldCode;
        startTime = formatDate(startTime);
        this.setState({
            dateShowPanel: false,
            initialValue: startTime,
        });
        let dateValue={};
        dateValue[fieldCodes] =  startTime;
        this.props.getSelectDate(dateValue);

    }

    render() {
        const {getFieldProps} = this.props.form;
        return <div>
            <InputItem placeholder={this.props.placeholder}
                       {...getFieldProps(this.props.fieldCode, {initialValue: this.state.initialValue})}
                       onClick={() => {
                           this.setState({
                               dateShowPanel: true,
                           });
                       }}
                       editable={this.props.editable ? this.props.editable : true}
            >
                {this.props.required ?
                    <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div> : ''}
                <div className={'interview_tittle'}>{this.props.fieldName}</div>
                <div className={'interview_colon'}>:</div>
            </InputItem>
            <Calendar
                {...this.state.interviewDataconfig}
                visible={this.state.dateShowPanel}
                onCancel={this.onCancel}
                onConfirm={this.onConfirmEndData}
                defaultDate={now}
                minDate={now}
                type={'one'}
            />
        </div>
    }
}

const DateSelects=createForm()(DateSelect);
export default DateSelects;