import React, {Component} from 'react';
import {Card, Calendar, InputItem, List, TextareaItem, WingBlank, Toast, Picker} from "antd-mobile";
import './interview_card.css'
import {checkContainsEmoji, formatDate, goupByFirstName} from "../utils";
import {createForm} from "rc-form";
import config from "../config";
import Modal from "antd-mobile/es/modal";
import TxySelectList from "./TxySelectList";
import {addAppointment, editAppointment, getAllTxy, loadWebSiteMedia} from "../action";
import './manuscript_Card.css';

// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}


const extra = {
    '2017/07/15': {info: 'Disable', disable: true},
};

const now = new Date();
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 5)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 6)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8)] = {info: 'Disable', disable: true};

Object.keys(extra).forEach((key) => {
    const info = extra[key];
    const date = new Date(key);
    if (!Number.isNaN(+date) && !extra[+date]) {
        extra[+date] = info;
    }
});

/**
 * 采访详情页面的，采访内容卡片
 */
class ManuscriptCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            en: false,
            show: false,
            config: {pickTime: true},
            startTime: '',
            chooseAccount: '',
            chooseAccountStr: '',
            chooseMedia: [],
            status: 0,
            canEdite: this.props.appointmentInfo ? this.props.canEdite : true,
            chooseAccountModal: false,
            chooseMediaModel: false,
            selectedIds: [],
            selectIds: [],
            defaulteMediaId: '',
            selectUsers: [],
            users: {},
            replyDate: this.props.appointmentInfo&& this.props.appointmentInfo.replyDate ? this.props.appointmentInfo.replyDate.time : '',
            appointmentInfo: this.props.appointmentInfo,

        }
        this.showModal = this.showModal.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillMount() {
        this.props.onRef(this);
        let param = {
            start: 0,
            size: 1000
        };

        getAllTxy(param, this.updateAllTxy.bind(this))

        loadWebSiteMedia(param, this.loadWebSiteMediaBack.bind(this));
    }

    loadWebSiteMediaBack(data) {
        if (!data) {
            return;
        }
        let mediaData = data.data;
        let mediaList = mediaData.map((item) => {
            return {
                'label': item.name,
                'value': item.code
            }
        })
        let mediaCode = [];
        if (this.state.appointmentInfo) {
            mediaCode = this.state.appointmentInfo.mediaCode;
        }
        this.setState({
            chooseMedia: mediaList,
            defaulteMediaId: mediaCode
        })

    }

    /**
     * 回显所有的通讯员；
     * @param data
     */
    updateAllTxy(data) {
        if (!data) {
            return;
        }
        let selectedIds = [];
        let chooseAccountStr = "";
        let chooseAccount = '';
        let selectUsers = [];
        if (this.state.appointmentInfo) {
            let receiverAcount = this.state.appointmentInfo.receivers;
            for (var j = 0; j < receiverAcount.length; j++) {
                for (var i = 0; i < data.length; i++) {
                    if (receiverAcount[j] === data[i].account) {
                        selectedIds.push(data[i].id);
                        selectUsers.push(data[i]);
                        chooseAccountStr += data[i].name + ';';
                        chooseAccount += data[i].account + ',';
                    }
                }
            }
        }


        //对编辑的数据进行分组
        let firstName2UserList = goupByFirstName(data);
        this.setState({
            users: firstName2UserList,
            selectIds: selectedIds,
            selectUsers: selectUsers,
            chooseAccountStr: chooseAccountStr,
            chooseAccount: chooseAccount,
        })
    }

    onSelectHasDisableDate = (dates) => {
    }

    onConfirm = (startTime, endTime) => {
        startTime = formatDate(startTime?startTime:new Date().getTime());
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            replyDate: startTime,
            endTime,
        });
    }

    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            show: false,
            replyDate: undefined,
            endTime: undefined,
        });
    }

    showModal = key => (e) => {
        if (this.state.canEdite) {

            e.preventDefault(); // 修复 Android 上点击穿透
            this.setState({
                [key]: true,
            });
        }
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    getUserAccountList(val) {
        var index = this.state.selectIds.indexOf(val.id);
        if (index < 0) {
            this.state.selectIds.push(val.id);
            this.state.selectUsers.push(val);
        } else {
            this.state.selectIds.splice(index, 1);
            this.state.selectUsers.splice(index, 1);
        }
        let selectUsers = this.state.selectUsers
        let choserUserName = "";
        let choserAccountStr = "";
        for (var i = 0; i < selectUsers.length; i++) {
            choserUserName += selectUsers[i].name + ';';
            choserAccountStr += selectUsers[i].account + ',';
        }

        this.setState({
            chooseAccountStr: choserUserName,
            chooseAccount: choserAccountStr

        })

    }

    validateFormData() {
        let manuscriptData = this.props.form.getFieldsValue();
        if (!manuscriptData.replyDate || manuscriptData.replyDate.length == 0) {
            Toast.fail('请选择回稿时间!');
            return false;
        }
        if (!this.state.chooseAccount || this.state.chooseAccount.length == 0) {
            Toast.fail('请选择约稿对象!');
            return false;
        }

        if (!manuscriptData.require || manuscriptData.require.length == 0) {
            Toast.fail('请填写稿件要求!');
            return false;
        }

        if(checkContainsEmoji(manuscriptData.require)){
            Toast.fail('稿件要求中不可以包含表情图!');
            return false;
        }

        if (!manuscriptData.chooseMedia||manuscriptData.chooseMedia.length==0) {
            Toast.fail('请选择媒体!');
            return false;
        }
        return true;
    }


    submitData() {
        //数据校验
        var checkResult = this.validateFormData();
        if (!checkResult) {
            return;
        }
        let manuscriptData = this.props.form.getFieldsValue();
        let param = manuscriptData;
        param.status = 1;
        param.chooseMedia = manuscriptData.chooseMedia[0];
        param.chooseAccount = this.state.chooseAccount;
        if (this.state.appointmentInfo) {
            param.appointmentId = this.state.appointmentInfo.id;
            editAppointment(param, this.editAppointmentBack.bind(this));
        } else {

            addAppointment(param, this.addAppointmentBack.bind(this));
        }
    }

    /**
     * 发布约稿回调data
     */
    editAppointmentBack(data) {
        if (data) {
            Toast.success('发布成功！');
            this.props.goBack();
        }
    }

    addAppointmentBack(data) {
        if (data) {
            this.props.goBack();
        }
    }

    render() {
        var titles = <span style={{fontSize: '4vw'}}><span style={{color: 'red'}}>* </span>约稿要求:</span>;
        const {getFieldProps} = this.props.form;
        let replyDate= this.state.replyDate;
        if(typeof replyDate === "string" || replyDate instanceof String ){
            replyDate= replyDate.replace(/-/g,'/');
        }
        return <div>
            <Card style={{paddingTop: '20px'}}>
                <WingBlank size="lg" style={{marginLeft: '0px'}}>
                    <div className={'storyAttribute'}>
                        <Card>
                            <List>
                                <InputItem placeholder={'请选择回稿时间'}
                                           {...getFieldProps('replyDate', {initialValue: formatDate(replyDate)})}
                                           onClick={() => {
                                               document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                                               this.setState({
                                                   show: true
                                               });
                                           }}
                                           editable={false}
                                ><span style={{fontSize: '4vw'}}><span style={{color: 'red'}}>* </span>回稿时间:</span>
                                </InputItem>
                                <Calendar
                                    {...this.state.config}
                                    visible={this.state.show}
                                    onCancel={this.onCancel}
                                    onConfirm={this.onConfirm}
                                    defaultDate={now}
                                    minDate={now}
                                    type={'one'}
                                />

                                <TextareaItem style={{fontSize: '4vw'}}
                                              {...getFieldProps('require', {
                                                  initialValue: this.state.appointmentInfo ? this.state.appointmentInfo.requireContent : ''
                                              })}
                                              rows={5}
                                              title={titles}
                                              editable={this.props.canEdite}
                                />

                                <InputItem placeholder={'请选择约稿对象'}
                                           {...getFieldProps('chooseAccount', {initialValue: this.state.chooseAccountStr})}
                                           onClick={
                                               this.showModal('chooseAccountModal')
                                           }
                                           editable={false}
                                ><spna style={{fontSize: '4vw'}}><span style={{color: 'red'}}>* </span>约稿对象:</spna>
                                </InputItem>
                                <Modal
                                    popup
                                    visible={this.state.chooseAccountModal}
                                    title={<div className={"manusc_txy_title"}>通讯员选择</div>}
                                    onClose={this.onClose('chooseAccountModal')}
                                    animationType="slide-up"
                                    afterClose={() => {
                                    }}
                                    footer={[{
                                        text: '确定', onPress: () => {
                                            this.onClose('chooseAccountModal')();
                                        }
                                    }]}
                                >
                                    <div>
                                        <div style={{height: '350px'}}>
                                            <TxySelectList showSearch={true} data={this.state.users}
                                                           searchCallBack={(res)=>{getAllTxy({start: 0,size: 1000,searchContent:res}, this.updateAllTxy.bind(this))}}
                                                           selectedIds={this.state.selectIds}
                                                           getUserAccountList={this.getUserAccountList.bind(this)}/>
                                        </div>
                                    </div>
                                </Modal>

                                <Picker data={this.state.chooseMedia}
                                        {...getFieldProps('chooseMedia', {initialValue: this.state.defaulteMediaId})}
                                        cols={1}>
                                    <List.Item arrow="horizontal"><span style={{fontSize: '4vw'}}><span
                                        style={{color: 'red'}}>* </span>发布媒体:</span></List.Item>
                                </Picker>
                            </List>
                        </Card>
                    </div>
                </WingBlank>
            </Card>
        </div>
    }
}

const ManuscriptCardForm = createForm()(ManuscriptCard);
export default ManuscriptCardForm
