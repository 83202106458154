import React, {Component} from 'react';
import {uuid} from "../../utils";
import {NavBar} from "antd-mobile";
import MessageList from "../messagePage/MessageList";
import {connect} from "react-redux";
import './nav_title.css'
import {getInfoFromLocalStorage, setReadMessageStatus} from "../../action";

/**
 * 消息导航页
 */
class MessagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: require("history").createHashHistory,
        };
    }

    /**
     * 一键读取所有
     */
    readAll() {

        var paramForm = new FormData();
        setReadMessageStatus(paramForm, this.updateMessageStatus.bind(this));

    }


    updateMessageStatus(data){

    }

    /**
     * 私信设置页面
     */
    toPrivateLetterSetPage() {
        var routeUrl = "/privateLetterSet/privateLetterSet";
        this.state.history().push(routeUrl)
    }


    render() {
        return <div>
            <NavBar
                mode="light"
                rightContent={[
                    <div key={uuid()} onClick={this.readAll.bind(this)} className={'message_readall'} style={{
                        padding: '2px',
                        border: "1px" +
                            "  solid" +
                            " white",
                        borderRadius: '10px',
                        color: '#666666',
                        borderColor: '#666666',
                        marginRight: '22px'
                    }}>一键已读</div>,
                    <div key={uuid()}><img src={'images/set.svg'} style={{width: '20px', cursor: 'pointer'}}
                                           onClick={() => {
                                               this.toPrivateLetterSetPage()
                                           }}></img></div>
                ]}
            ><span className={'nav_title'}>消息列表</span></NavBar>
            <MessageList></MessageList>
        </div>
    }
}

const stateToProps = (state) => {
    return {
        selectedTab: state.navigateReducer.selectedTab
    }
}

export default connect(stateToProps, null)(MessagePage);
