/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TaskListItem from '../../components/taskListItem/TaskListItem';
import {Icon, ListView, NavBar, Toast, WingBlank} from 'antd-mobile';
import add from '../topicList/img/add.png'
import * as Actions from "../../action/topiclist";
import {formatDate, uuid} from "../../utils";
import DropDownList from '../../components/dropDownList/DropDownList'


export default class TaskManagement extends Component {
    rData=[];
    pageIndex = 1;
    static defaultProps = {};
    static propTypes = {

    };

    constructor(props) {
        super(props);
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            allTopics: [ {title: '全部选题',type:'0'},{title: '我的选题',type:'1'}],
            topictStatus:[{title: '全部状态',status: 9},{title: '草稿',status:1}, {title: '已提交',status: 2}, {title: '已撤回',status: 3}, {title: '留用',status: 4}, {title: '已弃用',status: 5},{title: '已采用',status: 6},{title: '已指派',status: 8},{title: '转为任务',status: 7}],
            dataSource:dataSource,
            type:'',
            status:'',
            page:0,
            pageSize:10,
            count:0,
            hasMore: true,
            topTitle:'全部选题',
            statusTitle:'全部状态',
            key:'',
            height: document.documentElement.clientHeight-95,
        }
    }
    componentWillMount() {
    }

    componentDidMount() {
        this.getList();
    }

    render() {
        const row = (rowData,sectionID, rowID) => {
            const obj = rowData;
            return obj ? (
                <div style={{background:'#f2f2f2'}}  key={uuid()}>
                    <TaskListItem
                        refresh={()=>{this.refresh()}}
                        operateAuths={rowData.operateAuths}
                        operation={true}
                        goToPage={()=>{this.goToPage(rowData)}}
                        id={rowData.id} key={rowID} title={rowData.tsName}
                        type={rowData.tsCreateTime} state={rowData.tsStatus}
                        content={rowData.tsContent}>
                    </TaskListItem>
                </div>):<div></div>;
        };
        return (
            <div>
                <NavBar
                    key={'选题管理'}
                    mode={'light'}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'}  onClick={()=>this.props.history.goBack()} key={uuid()}>返回</div>
                    ]}

                ><span  className={'nav_title'}>选题管理</span></NavBar>
                <div style={{display:'flex',height:'12vw'}}>
                    <div style={{width:'50%'}}>
                        <DropDownList list={this.state.allTopics} title={this.state.topTitle} selectClick={(e)=>this.selectType(e)} idKey={'type'}></DropDownList>
                    </div>
                    <div style={{width:'50%'}}>
                        <DropDownList list={this.state.topictStatus} title={this.state.statusTitle} selectClick={(e)=>this.selectStatus(e)}  idKey={'status'}></DropDownList>
                    </div>
                </div>
                {this.state.count >0?
                    <WingBlank style={{margin:'5px'}}>
                    <ListView key={this.state.key}
                    initialListSize={this.state.count}
                    // ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{padding:5,lineHeight:0, textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '加载完成'}
                    </div>)}
                    renderRow={row}
                    style={{
                        height: this.state.height,
                        overflow: 'auto',
                    }}
                    pageSize={this.state.pageSize}
                    onScroll={() => {
                    }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
                    </WingBlank>
                :<div style={{textAlign:'center',marginTop:100,color:'#999999'}}>暂无数据</div>}
                <div style={{width:'13vw',height:'13vw',backgroundColor:'#ffffff', boxShadow:' 0vw 0vw 7vw 0vw rgba(118, 31, 131, 0.15)',borderRadius:'50%',position:'fixed',zIndex:'999',bottom:'6vw',right:'7vw'}} onClick={()=>this.addTopicList()}>
                     <img src={add} style={{position:'absolute',top:'50%',left:'50%',marginLeft:'-10px',marginTop:'-10px'}}/>
                </div>
            </div>
        )
    }
    goToPage(item){
        this.props.history.push('/topicDetails/topicDetails/'+item.id)
    }
    addTopicList(){
        this.props.history.push('/addTopicList/addTopicList/'+0)
    }
    getList(){
            let params={
                start:this.pageIndex,
                size:this.state.pageSize,
                tsListStatus:this.state.type,
                tsStatus:this.state.status,
                tsDirection:0
            };
            Actions.topicList(params,(data) => {
                //根据返回的数据量确定渲染的行数；
                Toast.hide();
                //请求后台异常。
                if (!data) {
                    this.setState({
                        isLoading: false,
                        count: 0,
                        dataStatus: "服务异常"
                    });
                    return;
                }
                //根据返回的数据量确定渲染的行数；
                this.rData = [...this.rData, ...data.data];
                this.setState({
                    data: data.data,
                    count: data.count,
                    dataSource: this.state.dataSource.cloneWithRows(this.rData),
                    isLoading: false,
                    hasMore: true,
                    pageSize: 10,
                    dataStatus: data.data.length > 0 ? '加载完成' : '暂无数据'
                });
                ++this.pageIndex;
            })
        }
    //选择全部选题
    selectType(e){
        this.state.allTopics.forEach((item=>{
            if(item.type === e){
                this.state.topTitle = item.title;
                this.setState({})
            }
        }));
        this.pageIndex = 1;
        this.state.pageSize =10;
        this.state.type = e;
        this.rData =[];
        this.state.key = uuid();
        this.setState({})
        this.getList()
    }
    //选择全部状态
    selectStatus(e){

        this.state.topictStatus.forEach((item=>{
            if(item.status === e){
                this.state.statusTitle = item.title;
                this.setState({})
            }
        }));
        if(e === 9 ){
            this.state.status = '';
        }else{
            this.state.status = e;
        }
        this.state.pageSize =10;
        this.pageIndex = 1;
        this.state.key = uuid();
        this.setState({});
        this.rData =[];
        this.getList()
    }
    onEndReached = (event) => {
        if(this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }
        this.setState({isLoading: true});

        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        this.getList()
    };
    refresh(){
        this.pageIndex = 1;
        this.rData =[];
        this.getList()
    }

}