import ActText from './ActRemark'
import $ from 'jquery'
import ActChoose from "./ActChoose";
import ActData from "./ActData";
import store from "../../store";
import {getInfoFromLocalStorage} from "../../action";
import config from "../../config";
import {checkStory, saveStory} from "./StorySave";
import Toast from "antd-mobile/lib/toast";
import {TO_LIST_PAGE} from "../../store/actions/action-type/action-types";


export const baseUrl = config['baseUrl'];

var loadButtonListUrl = baseUrl + "/cm-sites-activiti/activiti/getButton";
var actSubmitUrl = baseUrl + "/cm-sites-activiti/activiti/submitForm";
var actButtonConfig = baseUrl + "/cm-sites-activiti/activitiButtonConfig/getActButtonConfig";
var actStartFromUrl = baseUrl + "/cm-sites-activiti/activiti/getStartFrom";
var actFromUrl = baseUrl + "/cm-sites-activiti/activiti/getFormUrlById";
var actGetTaskIdUrl = baseUrl + "/cm-sites-activiti/activitiAdmin/getTaskIdByProcessInstanceId";
var actTodoListUrl = baseUrl + "/cm-sites-activiti/activiti/getNeedProcessed";
var actTodoAllListUrl = baseUrl + "/cm-sites-activiti/activiti/getNeedProcessedAll";
var getActServiceDataByIdUrl = baseUrl + "/cm-sites-activiti/activiti/getActServiceDataByIdUrl";
var actExaminedUrl = baseUrl + "/cm-sites-activiti/activiti/examined";
var actHistoryUrl = baseUrl + "/cm-sites-activiti/activiti/getActHistory";
var actTaskFormUrl = baseUrl + "/cm-sites-activiti/activiti/getTaskForm";
var saveStoryUrl = baseUrl + "/cm-sites-app-h5/story/save";
var saveStoryStatusNameUrl = baseUrl + "/cm-sites-app-h5/story/saveStatusName"
let saveWeiBoUrl=baseUrl + "/cm-sites-app-h5/weibo/save";
var saveWeiboStatusNameUrl= baseUrl + "/cm-sites-app-h5/weibo/saveStatusName"
var interviewgetAllManagerUrl = baseUrl + "/cm-sites-app-h5/interview/getAllManager";
var getPublisherByAppointmentUrl = baseUrl + "/cm-sites-app-h5/appointment/getPublisherByAppointmentReceiverId";
var terminateUrl = "/cm-sites-activiti/activitiAdmin/terminateByProcessInstanceId";
var chooseFilterObjectUrl = baseUrl + "/cm-sites-app-h5/material/mediaCheckForAct";

var isStart = false;
var clickButtonId;

var statusNameTemp = "";

var functionNames = "";
var currentStoryId = "";
var weiboId_G="";
var appointmentReceiverId = "";
var interviewId = "";


var currentMediaCode = getInfoFromLocalStorage('currentMediaCode')

var dataSource=0;

//判断是否来自资源库；
var fromZyk_G=false;

window.checkColumnFlag =true;

/**当前稿件*/
var currentStory = {};

let chooseUserArray_G=[];

/** 流程公共表单 **/
var thisForm = {};
/** 用户信息以及入口按钮配置信息，业务主键id  需要业务赋值 **/
var actData = {
    userName: getInfoFromLocalStorage("userName"),
    buttonId: null,
    id: null,
    docId: "",
}


var layerLoadIndexArray = [];

/** 表单数据 **/
var actForm = {
    values: {},
    users: {},
};

var chooseFilterObject;

/** 流程数据 **/
var actProcess = {
    id: actData.id,
    deploymentKey: '',
};

/** 流程数据 **/
var weiBoInfo_G ={};


/** 节点数据 **/
var actTask = {
    lastId: null    //最后一个节点id，也就是当前所流转到的节点id
};

var actButton;
/** 按钮列表 **/
var actButtonList;

/**
 * 按钮列表对象
 *  主要功能：存储按钮配置
 * @param buttonList
 * @constructor
 */

/* global layer */
function ActButtonList(buttonList) {
    //  按钮配置组
    this.buttonConfigList = buttonList || null;
    this.buttonMap = {};
    var that = this;
    $.each(buttonList, function (index, item) {
        that.buttonMap[index] = item;
    })
}

/**
 * 按钮对象
 *  主要功能：解析按钮配置，有对应的公共方法
 * @param button
 * @constructor
 */
function ActButton(button) {
    $.extend(this, button);
    this.style = {
        class: null
    }
     let onlyRevert=false;
}

/**
 * 按钮点击事件
 * @param index
 */
ActButton.prototype.buttonClick = function (index) {
    //从按钮列表中获取按钮配置
    var actButtonJson = actButtonList.buttonMap[parseInt(index)];
    //初始化当前按钮对象
    actButton = new ActButton(actButtonJson);
    //设置表单数据中选择数据为当前按钮名称
    actForm.values.choose = actButton.name;
    console.log(actButton);
    if (actButton && actButton.document && actButton.document.lineJavaScript) {
        var lineJavaScript = actButton.document.lineJavaScript;
        actButton.auditorMap = lineJavaScript.auditorMap;
        $.extend(actButton.auditorMap, actForm.users)
        actForm.users = actButton.auditorMap;
        // $.extend(actForm.users, actButton.auditorMap);
        console.log(lineJavaScript);
        //是否需要调用外部接口
        if (lineJavaScript.url != undefined && lineJavaScript.url.length > 0) {
            actForm.values.url = lineJavaScript.url;
        }
        //需要选人
        if (lineJavaScript.needAuditor == true) {
            actButton.needAuditor = lineJavaScript.needAuditor;
            if(lineJavaScript.needRemarks == true){
                actButton.needRemarks = lineJavaScript.needRemarks;
                var actText = new ActText();
                actText.getText({title: "审批意见"}, "remarks",  function(choose){
                    if(choose.type == "remarks"){
                        actForm.values.otherData = {};
                        actForm.values.otherData = {"remarks":choose.remarks};
                    }
                    actButton.chooseAuditor(lineJavaScript.auditorMap);
                });
            }else{
                actButton.chooseAuditor(lineJavaScript.auditorMap);
            }
        }//需要审核意见
        else if (lineJavaScript.needRemarks == true) {
            actButton.needRemarks = lineJavaScript.needRemarks;
            var actText = new ActText();
            actText.getText({title: "审批意见"}, "remarks", actButton.chooseDone);
        } else if (lineJavaScript.auditorMap != undefined) {
            actButton.chooseDone(lineJavaScript.auditorMap);
        } else {
            //什么都不选
            actButton.onlySubmit();

        }

    }
}

//选择完成后执行,组装参数
ActButton.prototype.chooseDone = function (choose) {

    //开始转圈圈
    Toast.loading('处理中...', 0, () => {
        console.log('Load complete !!!');
    });
    // actButton.load();
    if (choose.type == "activitiUsers" || choose.type == "users") {
        actForm.users = $.extend(actForm.users, choose);
    } else if (choose.type == "remarks") {
        actForm.values.otherData = {};
        actForm.values.otherData = {"remarks": choose.remarks};
    }
    //新流程，先自推一次
    if (actProcess.processInstanceId == undefined || actProcess.processInstanceId == null) {
        //提交开始表单 获取流程id
        try{
            actButton.submitStart(function () {
                actForm.deploymentKey = actProcess.deploymentKey;
                actButton.onlySubmit();
            });
        }catch(e){
            console.info("sdfsdfdfsdf"+e);
        }

    } else {
        actButton.onlySubmit();

    }

}

ActButton.prototype.onlySubmit = function () {
    console.info('onlySubmit ++++++++++++++++++++++');
    if (!isStart) {
        this.afterClickButton(clickButtonId, function (id) {
            if (actProcess.processInstanceId != null) {
                //获取taskId
                actButton.getTaskList(actProcess.processInstanceId, function (taskList) {
                    //设置节点id
                    actTask.lastId = taskList[taskList.length - 1].id;
                    //设置表单id
                    actForm.id = taskList[taskList.length - 1].id;
                    //根据最后一个节点id 获取当前表单
                    actButton.getFrom(actTask.lastId, function (requestData) {
                        //设置表单id
                        actForm.formId = requestData.formId;
                        //设置节点id
                        actTask.lastId = requestData.id;
                        actButton.submit();
                    });
                });
            }
        });
    } else {
        if (actProcess.processInstanceId != null) {
            //获取taskId
            actButton.getTaskList(actProcess.processInstanceId, function (taskList) {
                //设置节点id
                actTask.lastId = taskList[taskList.length - 1].id;
                //设置表单id
                actForm.id = taskList[taskList.length - 1].id;
                //根据最后一个节点id 获取当前表单
                actButton.getFrom(actTask.lastId, function (requestData) {
                    //设置表单id
                    actForm.formId = requestData.formId;
                    //设置节点id
                    actTask.lastId = requestData.id;
                    actButton.submit();
                });
            });
        }
    }

}

ActButton.prototype.getFrom = function (taskId, call) {
    actButton.post(actFromUrl, {id: taskId}, call);
}
ActButton.prototype.getTaskList = function (processInstanceId, call) {
    actButton.post(actGetTaskIdUrl, {
        processInstanceId: processInstanceId
    }, function (requestData) {
        if (typeof call == 'function') {
            call(requestData.data)
        }
    })
}

/**
 * 获取历史
 * @param processInstanceId
 * @returns {*}
 */
ActButton.prototype.getHistory = function (call) {
    actButton.post(actHistoryUrl, {processInstanceId: actProcess.processInstanceId}, function (requestData) {
        if (typeof call == 'function') {
            call(requestData);
        }
    })
}

/**
 * 提供给资源库
 */
ActButton.prototype.getHistoryByServiceId = function (buttonId, userName, serviceId, collback) {
    var data = [];
    actButton = new ActButton();
    actData.buttonId = buttonId;
    actData.userName = userName;
    actButton.getDeploymentKey(buttonId, function (requestData) {
        actButton.getActServiceDataById(serviceId, function (serviceData) {
            if (serviceData && serviceData._ProcessInstanceId) {
                actProcess.processInstanceId = serviceData._ProcessInstanceId;
                actButton.getHistory(function (history) {
                    if (history && history.data) {
                        if (history.data.length > 0) {
                            $.each(history.data, function (index, obj) {
                                if (obj.task && obj.task.values && obj.task.values.jsondata) {
                                    var object = {};
                                    object = JSON.parse(JSON.parse(obj.task.values.jsondata).jsondata);
                                    if (obj.assignee == undefined || obj.assignee == null) {
                                        object.activitiAuditorNames = obj.activitiAuditorNames;
                                    } else {
                                        object.assigneeName = obj.assigneeName;
                                    }
                                    object.statusName = obj.task.values.choose;
                                    object.startTime = actButton.timeFormat(obj.startTime);
                                    data.push(object);
                                }
                            })
                        }
                    }
                });

            }
            collback(data);
        });
    });
}

/**
 * 获取表单内容
 * @param processInstanceId
 * @returns {*}
 */
ActButton.prototype.getTaskForm = function (taskId, call) {
    actButton.post(actTaskFormUrl, {taskId: taskId}, function (data) {
        if (typeof call == 'function') {
            call(data);
        }
    })
}

ActButton.prototype.post = function (url, pram, call) {
    var requestData;
    var contentType = 'application/x-www-form-urlencoded';
    if (typeof pram == 'string') {
        contentType = 'application/json; charset=UTF-8';
    }
    $.ajax({
        url: url,
        data: pram,
        contentType: contentType,
        dataType: "JSON",
        type: "POST",
        success: function (result) {
            if (typeof result == 'string') {
                if (isJsonFormat(result)) {
                    requestData = JSON.parse(result);
                } else {
                    requestData = result;
                }
            } else {
                requestData = result;
            }
            if (typeof call == "function") {
                call(requestData);
            }
        },
        error: function (e) {
            call(false);
            console.log(e);
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader("userName", getInfoFromLocalStorage('userName'));
        }
    });
}

function isJsonFormat(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * 同步ajax
 */
ActButton.prototype.syncPost = function (url, pram, call) {
    var requestData;
    var contentType = 'application/x-www-form-urlencoded';
    if (typeof pram == 'string') {
        contentType = 'application/json; charset=UTF-8';
    }
    $.ajax({
        url: url,
        data: pram,
        async: false,
        contentType: contentType,
        dataType: "JSON",
        type: "POST",
        success: function (result) {
            if (typeof result == 'string') {
                requestData = JSON.parse(result);
            } else {
                requestData = result;
            }
        },
        error: function (e) {
            console.log(e);
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader("userName", actData.userName);
        }
    });
    if (typeof call == "function") {
        call(requestData);
    } else {
        return requestData;
    }
}


ActButton.prototype.postAsync = function (url, pram, callBack) {
    var requestData;
    var contentType = 'application/x-www-form-urlencoded';
    if (typeof pram == 'string') {
        contentType = 'application/json; charset=UTF-8';
    }
    $.ajax({
        url: url,
        data: pram,
        async: true,
        contentType: contentType,
        dataType: "JSON",
        type: "POST",
        success: function (result) {
            if (typeof result == 'string') {
                requestData = JSON.parse(result);
            } else {
                requestData = result;
            }
            callBack(requestData);
        },
        error: function (e) {
            console.log(e);
        },
        beforeSend: function (xhr) {
            xhr.setRequestHeader("userName", actData.userName);
        }
    });
}


ActButton.prototype.submitStart = function (call) {
    isStart = true;
    this.afterClickButton(clickButtonId, function (id) {
        //获取开始表单
        actButton.post(actStartFromUrl, {
            deploymentKey: actProcess.deploymentKey
        }, function (startData) {
            actProcess.processDefinitionId = startData.processDefinitionId;

            //提交开始表单
            actButton.post(actSubmitUrl, JSON.stringify({
                processDefinitionId: actProcess.processDefinitionId,
                deploymentKey: actProcess.deploymentKey,
            }), function (submitData) {
                //流程实例id
                actProcess.processInstanceId = submitData.id;
                actForm.processInstanceId = actProcess.processInstanceId;
                if (typeof call == 'function') {
                    call(submitData.id);
                }
            });
        });
    });
}
ActButton.prototype.submit = function (call) {
    actForm.deploymentKey = actProcess.deploymentKey;
    let that = this;
    if (actForm.users.activitiUsers && actForm.users.activitiUsers.length > 0) {
        actForm.users.FORM_SERVICE_USERS = [];
    }
    actButton.post(actSubmitUrl, JSON.stringify(actForm), function (requestData) {
        if (requestData.status != undefined && requestData.status == "200") {
            if (actButton.end()) {
                if (typeof (statusNameTemp) != "undefined" && statusNameTemp && statusNameTemp.length > 0) {
                    actButton.closeAll();
                    var layerMsg = statusNameTemp + '成功';
                    if (actForm.values.url) {
                        layerMsg = "正在" + statusNameTemp + "中，可以在列表页查看发布状态。"
                    }
                    Toast.info(layerMsg, 2, null, false);
                    that.submitOK();
                } else if (actForm.values.url) {
                    actButton.closeAll();
                    Toast.info("正在发布中，可以在列表页查看发布状态。", 2, null, false);
                    that.submitOK();
                } else {
                    actButton.closeAll();
                    Toast.info(requestData.msg, 2, null, false);
                    that.submitOK();
                }
                //清空按钮
                actButton.reload(actProcess.processInstanceId);
                //清空表单参数
                actForm.users = {};
            }
        } else if (requestData && requestData.status != undefined && requestData.status == "500") {
            Toast.fail("流程推进失败!失败原因：" + requestData.msg, 2)
            actButton.closeAll();
            console.log("流程推进失败!失败原因：" + requestData.msg, {icon: 2, closeBtn: 0});
        } else if (requestData == undefined) {
            Toast.fail("请求超时！内容已保存，请刷新重试", 2)
            actButton.closeAll();
            console.log("请求超时！内容已保存，请刷新重试", {icon: 2, closeBtn: 0});
        } else {
            Toast.fail("流程推进失败!" + requestData.msg, 2, actButton.closeAll());
            // actButton.closeAll();
            console.log("流程推进失败!" + requestData.msg, {icon: 2, closeBtn: 0}, function () {
            })
        }
    });

}

ActButton.prototype.submitError = function (data) {
    if (typeof (eval("advanceError")) == "function") {
        this.advanceError(data);
    } else {
        console.log(data.msg);
    }
}


ActButton.prototype.reload = function (id) {
    $("#testButton").html("");
}

//选择方法： 弹框，选择，返回
ActButton.prototype.end = function () {
    if (typeof (this.endFunction) == "function") {
        //根据函数名得到函数类型
        var func = eval("this.endFunction");
        //创建函数对象，并调用
        new func(function (type) {
            //如果调用的方法返回的不是对象，那么返回值会是方法本身，故无法判断方法是否正确执行，默认放行。
            // 返回对象的默认格式 {status : false || true ,msg: ""}
            if (typeof (type) != "object" && typeof (type) != "function" && type && !type.status) {
                if (type.msg) {
                    actButton.closeAll();
                    console.log("执行流程推进后置处理程序出错。" + ("<br>提示信息:" + type.msg) || ("<br>出错方法:"));
                    return 0;
                } else {
                    actButton.closeAll();
                    console.log("执行流程推进前置处理程序出错!推进中断，当前信息已保存。" + ("<br>错误信息:" + type.msg) || ("<br>出错方法:"));
                    return 0;
                }
            } else {
                return 1;
            }
        });
        return 1;
    }
    return 1;

}
ActButton.prototype.chooseAuditor = function (auditorMap) {
    var actChoose = new ActChoose();
    var actData = new ActData();
    actData.chooseUserArray_G=chooseUserArray_G;
    //如果是指定人中选一个的，则列出人的列表
    if (auditorMap.FORM_SERVICE_USERS.length > 0) {
        return actChoose.getChoose(auditorMap.FORM_SERVICE_USERS, "users", actButton.chooseDone);
    } else if (auditorMap.FORM_SERVICE_GROUP.length > 0 && auditorMap.FORM_SERVICE_ROLE.length < 1) {    //只选择部门
        return actChoose.getChoose(actData.getUser({
            group: auditorMap.FORM_SERVICE_GROUP
        },actData), "activitiUsers", actButton.chooseDone);
    } else if (auditorMap.FORM_SERVICE_ROLE.length > 0 && auditorMap.FORM_SERVICE_GROUP.length < 1) {     //只选择角色
        return actChoose.getChoose(actData.getUser({
            role: auditorMap.FORM_SERVICE_ROLE
        },actData), "activitiUsers", actButton.chooseDone);
    } else if (auditorMap.FORM_SERVICE_GROUP && auditorMap.FORM_SERVICE_ROLE) {      //选择角色和部门
        return actChoose.getChoose(actData.getUser({
            role: auditorMap.FORM_SERVICE_ROLE,
            group: auditorMap.FORM_SERVICE_GROUP
        },actData), "activitiUsers", actButton.chooseDone);
    }
};

//加载按钮配置
ActButton.prototype.loadButtonList = function (pram, call) {
    var that = this;
    actButton.post(loadButtonListUrl, pram, function (data) {
        if (data && data.length > 0) {

            // if(typeof call == 'function'){
            //     call(data);
            // }
            //手机端没有发布按钮，所以剔除发布；
            var buttonDataList = [];
            for (var i = 0, legth = data.length; i < legth; i++) {
                // if (data[i].name == '发布') {
                //     continue;
                // }
                //返回的
                //按钮是否有显示前置方法
                if (data[i].document && data[i].document.lineJavaScript.afterButtonShow) {
                    var funName = data[i].document.lineJavaScript.afterButtonShow;
                    if (that.isFunction(funName)) {
                        var func = eval(funName);
                        new func("", function (res) {
                            if (res == true || res == "true") {
                                buttonDataList.push(data[i]);
                            }
                        });
                    }
                } else {

                    buttonDataList.push(data[i]);
                }

            }
            call(buttonDataList);
            actButtonList = new ActButtonList(buttonDataList);


        } else {
            if (typeof call == 'function') {
                call([]);
            }
        }
    });

}


ActButton.prototype.endFunction = function (call) {
    //保存状态
    if(actData.buttonId==2||actData.buttonId==4){
        actButton.post(saveStoryStatusNameUrl, {
                id: currentStoryId,
                statusName: statusNameTemp,
                appointmentReceiverId: appointmentReceiverId,
                interviewId: interviewId
            },
            function (data) {
                if (data.code == 200) {
                    call({status: true, msg: data.msg});
                } else {
                    call({status: false, msg: "流程推进后状态修改失败。"});
                }
            });
    }else if(actData.buttonId==3){
        actButton.post(saveWeiboStatusNameUrl, {
                weiboId: weiboId_G,
                statusName: statusNameTemp,
            },
            function (data) {
                if (data.code == 200) {
                    call({status: true, msg: data.msg});
                } else {
                    call({status: false, msg: "流程推进后状态修改失败。"});
                }
            });
    }

}


//获取待办列表
ActButton.prototype.todoList = function (prams, call) {
    if (prams == undefined || prams == null) {
        prams = {};
    }
    // prams.deploymentKey = actProcess.deploymentKey;
    this.post(actTodoListUrl, JSON.stringify(prams), function (todoList) {
        if (typeof call == 'function') {
            call(todoList);
        }
    });
}


ActButton.prototype.showButtonList = function (currButtonIndex) {
    try {
        //点击进来先清空。
        chooseUserArray_G=[];
        var isCall = false;
        //获取前置处理js方法集合
        var functionNames = actButtonList.buttonConfigList[currButtonIndex].document.lineJavaScript.otherJs;
        var funArray = functionNames.split(",");
        if (functionNames && functionNames.length > 0 && funArray && funArray.length > 0) {
            //方法返回值状态
            var flag = true;
            for (var i = 0; i < funArray.length; i++) {
                var functionName = funArray[i];
                if (typeof (eval('this.' + functionName)) == "function") {

                    if (functionName.indexOf("call") == 0) {
                        isCall = true;
                    }
                    //根据函数名得到函数类型
                    var func = eval('this.' + functionName);
                    //创建函数对象，并调用
                    new func(function (type) {
                        //如果调用的方法返回的不是对象，那么返回值会是方法本身，故无法判断方法是否正确执行，默认放行。
                        // 返回对象的默认格式 {status : false || true ,msg: ""}
                        if (typeof (type) != "object" && typeof (type) != "function") {
                            if (type == true) {
                                console.log("前置处理程序执行成功：方法名称" + functionName);
                            }
                        } else if (typeof (type) == "object" && (type.status == true || type.status == "true")) {
                            console.log("前置处理程序执行成功：方法名称" + functionName);
                        } else if (typeof (type) == "object" && (type.status != true && type.status != "true" && type.msg)) {
                            this.closeAll();
                            console.log(type.msg, {icon: 2});
                            flag = false;
                            // layer.alert("流程推进中断，当前信息已保存。" + ("<br>提示信息:" + type.msg) || ("<br>出错方法:" + functionName));
                        } else {
                            this.closeAll();
                            console.log("执行流程推进前置处理程序出错!推进中断，当前信息已保存。" + ("<br>错误信息:" + type.msg) || ("<br>出错方法:" + functionName));
                            flag = false;
                        }
                    });
                } else {
                    console.log("未找到方法：" + functionName + "，流程推进可能出错")
                }
            }
            if (!flag) {
                actButton.closeAll();
                return false;
            }
        }
        if (!isCall) {
            if (typeof (eval("this.afterClickButton")) == "function") {
                clickButtonId = currButtonIndex;
                // actButton.load();
                this.buttonClick(clickButtonId);
            }
        }
    } catch (e) {
        console.log(e.stack);
        // actButton.closeAll();
        console.log("推进出错！" + e.message);
        return false;
        //actButton.buttonClick($(this).attr("id"));
    }
    return false;
    // });
}

//获取待办列表
ActButton.prototype.todoAllList = function (prams, callBack) {
    if (prams == undefined || prams == null) {
        prams = {};
    }
    prams.deploymentKey = actProcess.deploymentKey;
    if (callBack != undefined && typeof callBack == "function") {
        this.postAsync(actTodoAllListUrl, JSON.stringify(prams), callBack);
    } else {
        var todoList = this.post(actTodoAllListUrl, JSON.stringify(prams));
        return todoList;
    }
}

//获取我已审核列表
ActButton.prototype.examinedList = function (prams, call) {
    if (prams == undefined || prams == null) {
        prams = {};
    }
    this.post(actExaminedUrl, JSON.stringify(prams), function (examinedList) {
        if (typeof call == 'function') {
            call(examinedList);
        }
    });
}


//根据按钮id 获取deploymentkey
ActButton.prototype.getDeploymentKey = function (buttonId, call) {
    actButton.post(actButtonConfig, {id: buttonId}, function (requestData) {
        //设置流程key
        actProcess.deploymentKey = requestData.deploymentkey;
        if (typeof call == 'function') {
            call(requestData);
        }
    });

}


ActButton.prototype.getActServiceDataById = function (serviceId, call) {
    this.post(getActServiceDataByIdUrl, {
        deploymentKey: actProcess.deploymentKey,
        serviceId: serviceId
    }, function (data) {
        if (typeof call == 'function') {
            call(data);
        }
    });
}

/**
 * 设置微博信息
 * @param currentWeibo
 */
ActButton.prototype.setWeiBoInfo=function(currentWeibo){
    weiBoInfo_G=currentWeibo;
}

/**
 * 初始化流程数据
 * @param params
 * @param call
 */
ActButton.prototype.init = function (params, call) {
    //清空初始化参数；
    delete actProcess.processInstanceId;
    delete actProcess.processDefinitionId;
    //初始化全局变量
    isStart = false;
    actForm = {
        values: {},
        users: {},
    };


    //赋值，初始化参数
    actProcess.deploymentKey = params.deploymentKey;
    dataSource=params.dataSource;
    actTask = {lastId: null};
    actButton = new ActButton();
    actData.userName = params.userName;
    actData.buttonId = params.buttonId;
    currentStory=params.currentStory;
    interviewId = params.interviewId ? params.interviewId : 0;
    appointmentReceiverId = params.appointmentReceiverId ? params.appointmentReceiverId : 0;
    fromZyk_G=params.fromZyk;
    //根据业务配置信息获取流程定义
    if (actData.buttonId != undefined && actData.buttonId != null) {
        actButton.getDeploymentKey(actData.buttonId, function () {
            if (params.serviceId) {
                actButton.getActServiceDataById(params.serviceId, function (data) {
                    if (data) {
                        actProcess.processInstanceId = data._ProcessInstanceId;
                        actData.id = data._ProcessInstanceId;
                        actForm.processInstanceId = data._ProcessInstanceId;
                    }
                    //根据流程定义加载按钮
                    actButton.loadButtonList(actProcess, function (data) {
                        if (data) {
                            if (typeof call == 'function') {
                                call(data);
                            }
                        }

                    })
                })
            } else {
                //根据流程定义加载按钮
                actButton.loadButtonList(actProcess, function (data) {
                    if (data) {
                        if (typeof call == 'function') {
                            call(data);
                        }
                    }
                })
            }
        });
    }
}

ActButton.prototype.getRemarks = function (call) {
    //加载审核意见等信息
    if (!actTask.lastId && actProcess.processInstanceId) {
        var taskList = actButton.getTaskList(actProcess.processInstanceId, function (taskList) {
            if (taskList && taskList instanceof Array && taskList.length > 0)
                actTask.lastId = taskList[taskList.length - 1].id;
            actButton.getRemarksFromActTask(call);
        });
    }
    if (actTask.lastId) {
        actButton.getRemarksFromActTask(call)
    }
}
ActButton.prototype.getRemarksFromActTask = function (call) {
    actButton.getFrom(actTask.lastId, function (thisForm) {
        if (thisForm == undefined || thisForm.fields == undefined || thisForm.fields.length == 0)
            return;
        thisForm.fields.forEach(function (item, index) {
            if (item.id == "otherData" && item.value && item.value.length > 0) {
                var otherData = JSON.parse(item.value);
                if (otherData != null && otherData.hasOwnProperty("remarks")) {
                    if (typeof call == "function") {
                        call(actButton.escape(otherData.remarks));
                    } else if (typeof this.showRemarks == "function") {
                        this.showRemarks(actButton.escape(otherData.remarks));
                    }
                }
            }
        })
    });
}


ActButton.prototype.submitOK = function (data) {
    var toListaction = {
        type: TO_LIST_PAGE,
        toListPage: true,
    }
    //流程调用
    if (data == undefined) {

        //关闭转圈圈
        Toast.hide();
        Toast.success('操作成功', 1);
        store.dispatch(toListaction);

    } else { //只保存调用
        //关闭转圈圈
        Toast.hide();
        Toast.success('操作成功', 1);
        store.dispatch(toListaction);
    }
}


ActButton.prototype.getTaskFormValueById = function (task, id) {
    var value = "";
    task.forEach(function (object, index) {
        if (object.id == id) {
            value = object.value;
        }
    })
    return value;
}


function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}


ActButton.prototype.load = function () {
    actButton.closeAll();
    // layerLoadIndexArray.push(layer.load());
}
ActButton.prototype.closeAll = function () {
    Toast.hide();
    // $.each(layerLoadIndexArray,function(index,object){
    //     layer.close(object);
    // })
}

ActButton.prototype.escape = function (t) {
    return String(t || "").replace(/&([a-zA-Z0-9]+;)/g, "&amp;$1").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/'/g, "&#39;").replace(/"/g, "&quot;")
}

ActButton.prototype.timeFormat = function (time) {
    if (time == null || undefined)
        return "";
    var d = new Date(time);
    var year = d.getFullYear();       //年
    var month = d.getMonth() + 1;     //月
    var day = d.getDate();            //日

    var hh = d.getHours();            //时
    var mm = d.getMinutes();          //分
    var ss = d.getSeconds();           //秒
    var clock = year + "-";
    if (month < 10)
        clock += "0";
    clock += month + "-";
    if (day < 10)
        clock += "0";
    clock += day + " ";
    if (hh < 10)
        clock += "0";
    clock += hh + ":";
    if (mm < 10) clock += '0';
    clock += mm + ":";
    if (ss < 10) clock += '0';
    clock += ss;
    return (clock);
}


/** 触发流程程序前，保存稿件 **/
ActButton.prototype.afterClickButton = function (buttonId, callback) {

    if(actData.buttonId==2||actData.buttonId==4){
        this.saveWriting(buttonId, function (data) {
            if (data && data.id) {
                actForm.values.jsondata = {};
                actForm.values.jsondata.jsondata = encodeBase64(JSON.stringify(data))
                actForm.values.jsondata.serviceId = data.id;
                callback(buttonId);
            }
        });
    }else if(actData.buttonId==3){
        this.saveWeiBo(buttonId,function(data){
            actForm.values.jsondata = {};
            actForm.values.jsondata.jsondata = encodeBase64(JSON.stringify(data));
            actForm.values.jsondata.serviceId = data.id;
            callback(buttonId);
        });
    }

}


//写稿保存按钮
ActButton.prototype.saveWriting = function (buttonId, call) {
    //保存没有  id的；
    // if (buttonId) {
    //     let buttonName = actButtonList.buttonConfigList[buttonId].name;
    // }
    if (!checkStory('')) {
        return;
    }
    let params = saveStory();
    // 先保存原状态名，将新的状态名称保存到js中

    //开始转圈圈
    Toast.loading('处理中...', 0, () => {
        console.log('Load complete !!!');
    });
    params.statusName = params.statusName || "草稿";
    if (buttonId != undefined && buttonId != null) {
        statusNameTemp = actButtonList.buttonConfigList[buttonId].name;
    } else {
        statusNameTemp = params.statusName || "草稿";
    }
    params.interviewId = interviewId;
    //base64加密传输
    //base64加密
    if(params && params.content){
        var content=encodeBase64(params.content);
        params.content=content;
    }
    let that = this;
    $.ajax({
        type: 'post',
        url: saveStoryUrl,
        dataType: 'json',
        //防止深度序列化
        traditional: true,
        data: params,
        success: function (data) {
            //关闭转圈
            currentStoryId = data.data.id;
            currentStory = data;
            //如果是保存按钮就无需走流程了
            if (buttonId == null && !call) {
                that.submitOK(data.data);
            }

            $.extend(true, params, data.data);
            params.statusName = statusNameTemp;
            if (typeof call == 'function') call(params);
        },
        error: function () {
            Toast.offline('操作失败', 1.5);
            // actButton.closeAll();
            if (typeof call == 'function') call(false);
        }
    });
}


//推进出错调用
/** 触发流程程序前，保存稿件 **/
ActButton.prototype.advanceError = function (data) {
}


ActButton.prototype.showRemarks = function (remarks) {
    // instanceRecord = remarks;
    // $("#container").append('<img onclick="showInstanceRecord()" src="/cm-sites/images/fagao/instanceRecord.png" style="width: 25px;margin-left: 10px; cursor: pointer" />');
}


ActButton.prototype.chooseFilter = function () {
    chooseFilterObject = {
        url: chooseFilterObjectUrl,
        queryMap: {
            mediaCode: getInfoFromLocalStorage('currentMediaCode'),
        }
    }
}


//撤回流程
ActButton.prototype.callActRevert = function (call, endCall) {
    actButton.terminate(null, function (res) {
        if (res == true) {
            statusNameTemp = "草稿";
            actButton.endFunction(function (res) {
                if (res.status == true) {
                    Toast.success("流程已撤回，稿件变为草稿状态", {icon: 1, closeBtn: 0}, function () {
                        actButton.submitOK();
                    });
                }
            })
        }
    });
}

/**采访预约审核流用将稿件送至负责人进行审核**/
ActButton.prototype.setInterviewFzr = function (call) {
    actButton.post(interviewgetAllManagerUrl, {
        interviewId: interviewId
    }, function (resultData) {
        if (resultData == null || resultData == undefined || resultData.length < 1) {
            Toast.fail("获取任务负责人失败！请联系负责人")
            call(false);
            return;
        }
        var managerData = [];
        resultData.forEach(function (element) {
            managerData.push(element.account);
        });
        actForm.users.activitiUsers = managerData;
        call(true);
    });
}

/** 约稿审核流用将稿件送至负责人进行审核**/
ActButton.prototype.setAppointmentBJ = function (call) {
    actButton.post(getPublisherByAppointmentUrl, {
        appointmentReceiverId: appointmentReceiverId,
        storyId: currentStoryId
    }, function (resultData) {
        if (resultData) {
            actForm.users.activitiUsers = [resultData.selectuser];
            call(true);
        } else {
            Toast.fail("未查询到约稿负责人信息！")
            call(false);
        }
    });

}

ActButton.prototype.terminate = function (processInstanceId, call) {
    if (!processInstanceId && actForm && actForm.processInstanceId)
        processInstanceId = actForm.processInstanceId;
    var actText = new ActText();
    actText.getText(null, "remarks", function (deleteReason) {
        actButton.post(terminateUrl, {
            processInstanceId: processInstanceId,
            deleteReason: deleteReason.remarks
        }, function (data) {
            if (typeof call == 'function') {
                if (data.code == 200) {
                    call(true);
                } else {
                    Toast.fail("流程提前结束失败：" + data.msg);
                    call(false);
                }
            }
        });
    })
}

function actCheckSJW(htmlTemp, call) {
    if (currentMediaCode == "sjw")
        call(true);
    else
        call(false)
}

function actCheckNotSJW(htmlTemp, call) {
    if (currentMediaCode != "sjw")
        call(true);
    else
        call(false)
}


ActButton.prototype.isFunction = function (funName) {
    if (funName == undefined || funName == "")
        return false;
    if ($.isFunction(funName))
        return true;
    if (typeof funName == "string") {
        try {
            if (typeof (eval(funName)) == "function")
                return true;
        } catch (e) {
            return false;
        }
    }
    return false;
}




//微博保存按钮
ActButton.prototype.saveWeiBo = function (buttonId, call) {
    let params = weiBoInfo_G;
    let content=params.content;
    if(!content||content==''){
        Toast.fail("请输入微博正文内容");
        return;
    }
    //开始转圈圈
    Toast.loading('处理中...', 0, () => {
        console.log('Load complete !!!');
    });
    params.statusName = params.statusName || "草稿";
    if (buttonId != undefined && buttonId != null) {
        statusNameTemp = actButtonList.buttonConfigList[buttonId].name;
    } else {
        statusNameTemp = params.statusName || "草稿";
    }

    params.interviewId = interviewId;
    let that = this;
    $.post({
        dataType:'JSON',
        type: 'post',
        url: saveWeiBoUrl,
        //防止深度序列化
        traditional: true,
        data: params,
        success: function (data) {
            // currentStory = data;
            //如果是保存按钮就无需走流程了
            if (buttonId == null && !call) {
                that.submitOK(data);
            }
            weiboId_G=data.id;
            $.extend(true, params, data);
            params.statusName = statusNameTemp;
            if (typeof call == 'function') call(params);
        },
        error: function () {
            Toast.offline('操作失败', 1.5);
            // actButton.closeAll();
            if (typeof call == 'function') call(false);
        }
    });
}

function isPersonalWrite(htmlTemp,call){
    if(dataSource && dataSource == 11){
        call(true);
        return;
    }
    call(false)
}

function  isCompanyWrite(htmlTemp,call){
    if(dataSource && dataSource == 7){
        call(true);
        return;
    }
    call(false)
}

/**
 * 非资源池
 * @param item
 * @param call
 */
function notActFromZyk(item,call){
    actFromZyk(item,function(res){
        call(!res);
    })
}


function actFromZyk(item, call) {
    if (fromZyk_G) {
        call(true);
    } else if (currentStory.isCopyFlag == true || currentStory.isCopyFlag == "true") {
        $.post("/cm-sites-app-h5/story/getOldStoryId", {storyId: currentStory.id}, function (oldStoryId) {
            if (parseInt(oldStoryId) > 0) {
                $.post("/cm-sites-app-h5/story/getStoryById", {storyId: oldStoryId}, function (oldStory) {
                    if ((oldStory && oldStory.statusName == "提交至宣传部") || currentStory.isCopyFlag == true) {
                        call(true);
                    } else {
                        call(false)
                    }
                }, "json")
            } else {
                call(false)
            }
        }, "json")
    } else {
        call(false);
    }
}


/**
 * 流程引擎调用，设置部门
 */
function callSetDeptId () {
    actForm.users.FORM_SERVICE_GROUP == undefined ?
        actForm.users.FORM_SERVICE_GROUP = [store.getState().storyReducer.story.deptId + '']
        : actForm.users.FORM_SERVICE_GROUP.push(store.getState().storyReducer.story.deptId + '');
}

ActButton.prototype.doNotCheckColum=function(){
    actButton.closeServiceTemp = function(){
        window.checkColumnFlag = true;
    };
    window.checkColumnFlag = false;
}

ActButton.prototype.setAuthor=function(call){
    if(fromZyk_G ||  currentStory.isCopyFlag == true) {
        actForm.users.activitiUsers = [currentStory.initialSubmitter];
    }
}

ActButton.prototype.closeServiceTemp = function(){

}


//撤回流程
function callActRevert(call,endCall){
    actButton.terminate(null,function(res){
        if(res == true){
            statusNameTemp = "草稿";
            actButton.endFunction(function(res){
                if(res.status == true){
                    Toast.success("流程已撤回，稿件变为草稿状态", 1, function () {
                        actButton.submitOK();
                    });
                }
            })
        }
    });
}

function showRevert(htmlTemp,call) {
    // $("#saveButton").hide();
    // $("#previewWeb").hide();
    this.onlyRevert = true;
    call(true);
}

ActButton.prototype.actChooseMe=function(call,endCall){
    chooseUserArray_G=[];
    let userInfo=getInfoFromLocalStorage("userInfo");
    let currentUser={
        email: "",
        englishName: "#",
        first: userInfo.account,
        id: userInfo.account,
        last: "",
        name: userInfo.name,
        pictureId: "",
        rev: 1
    }

    chooseUserArray_G.push(currentUser);
    // chooseFilterObject.otherData = [{
    //     first : .name,
    //     id : top.currentUser.account,
    //     name : top.currentUser.name,
    //     checked : "checked"
    // }]
}

function encodeBase64(str){
    // 对字符串进行编码
    var encode = encodeURIComponent(str);
    // 对编码的字符串转化base64
    var base64 = btoa(encode);
    return base64;
}

export default ActButton;

