import React, {Component} from 'react';
import HomePageIndex from "../homePage/HomePageIndex";

/**
 * 首页导航页
 */
class HomePage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div ><HomePageIndex isContributeRoleFlag={this.props.isContributeRoleFlag}></HomePageIndex></div>
    }
}

export default HomePage;
