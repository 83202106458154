import React, {Component} from 'react'
import {connect} from "react-redux";
import {
    setContent,
    addStoryResource,
    setStoryChanged,
    setCurrentTempStoryId,
    setLinkUrl
} from "../../store/actions/storyAction";
import {createForm} from "rc-form";
import {initLoadConfig} from "./Editor.config"
import { TextareaItem} from "antd-mobile";
import  {renderContent} from './Editor.render'
import {autoSaveStoryData} from "./Editor.history";
import   Editors from"../Editors/Editor"
class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storyId:props.storyId,
            isLinkStory:props.story.linkurl,
            story:props.story,
            addStory:props.addStory,
            resources:props.resources?props.resources:{picture:[],video:[],audio:[]}
        }
        //初始化配置
        // initLoadConfig(this.initEditor.bind(this),this);
    }

    componentDidMount() {
        //定时保存,暂时不需要自动保存功能。
        /*if(!this.state.isLinkStory){
            this.interval = setInterval(() => autoSaveStoryData(this), 1000*60*3);
        }*/
    }

    //在组件接收到一个新的 prop (更新后)时被调用。这个方法在初始化render时不会被调用。
    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.story!=this.state.story){
            this.setState({story:nextProps.story});
            //是否链接稿件
            if(nextProps.story.linkurl){
                this.setState({isLinkStory:true});
            }else{
                this.state.story=nextProps.story;
                // this.renderStory(nextProps.story);
            }
        }
        if(nextProps.storyId){
            this.setState({storyId:nextProps.storyId});
        }
    }
    // 返回一个布尔值。在组件接收到新的props或者state时被调用。在初始化时或者使用forceUpdate时不被调用。
    //可以在你确认不需要更新组件时使用。
    shouldComponentUpdate(nextProps, nextState,snapshot){
        return true;
    }
    //在组件从 DOM 中移除之前立刻被调用。
    componentWillUnmount() {
        if(!this.state.isLinkStory){
            // if(this.state.editor)this.state.editor.destory();
            //定时保存
            clearInterval(this.interval);
        }
    }
    /**
     *
     */
    renderStory(nextStory){
        if(this.state.editor && nextStory){
            // renderContent(nextStory, this.state.editor);
        }else{
        }
    }

    initEditor(config){
        // eslint-disable-next-line
        this.state.editor = new Eleditor(config);
        //this.renderStory(this.state.story);
    }

    //改变后回填
    onLinkInputBlur = (value) => {
        this.props.setLinkUrl(value);
    }

    render() {
        console.log(this.state.story);
        let currentHeight=window.innerHeight-45-45-98-8-10;
        if(this.state.isLinkStory){
            // const {getFieldProps} = this.props.form;
            return  <div style={{height:'70vh',fontSize:'16px',background: "#ffffff",lineHeight:'40px',textAlign:'center'}}>
                当前文章为跳转链接，不需要维护文章内容。</div>
            // return <TextareaItem
            //     prefixListCls={'link-list'}
            //     clear
            //     type={'text'}
            //     placeholder="请输入链接地址"
            //     style={{height:'100%'}}
            //     {...getFieldProps('linkurl',{initialValue: this.props.story.linkurl})}
            //     onBlur={this.onLinkInputBlur}
            // />
        }else{
            return (
                <Editors showLinkBtn={false} customHeight={currentHeight} bindId={this.props.bindId} content={this.state.story&&this.state.story.content } callBackEditorContent={(newContent)=>{
                    this.props.story.content=newContent;
                    // this.setState({});
                    console.log('测试编辑器--------------',newContent)
                }}
                callBackEditorLinkUrl={(linkurl)=>{
                             this.props.story.linkurl=linkurl;
                    console.log('测试linkurl--------------',linkurl)
                         }}
                />
                /* <div className={'editor'}>
                        <div className={'media-eleditor'}></div>
                    </div>*/ )
        }
    }
}

const stateToProps = (state) => {
    return {
        resources:state.resources, //稿件资源信息
        addStory:state.storyReducer.addStory
    }
}

const EditorForm = createForm()(Editor);
/**
 * 组件绑定form表单
 */
const dispatchToProps = (dispatch) => {
    return {
        setContent:function (content) {
            dispatch(setContent(content));
        },
        addStoryResource:function(resource,type){
            dispatch(addStoryResource(resource,type));
        },
        setStoryChanged:function(storyChanged){
            dispatch(setStoryChanged(storyChanged));
        },
        setCurrentTempStoryId:function(tempStoryId){
            dispatch(setCurrentTempStoryId(tempStoryId));
        },
        setLinkUrl(linkurl){
            dispatch(setLinkUrl(linkurl));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditorForm);