import React, {Component} from 'react'
import {Icon, NavBar} from "antd-mobile/lib/index";
import {uuid} from "../../../utils";
import {getInfoFromLocalStorage} from "../../../action";
import NewClueCardForm from "../../../components/NewClueCard";
import './add_offCampusMedia_Page.css';
import ExternalArrangeCardForm from "../../../components/externalArrangemediaComp/ExternalArrangeCard";
import Button from "antd-mobile/es/button";
import {getOffCampusMediaInfo} from "../../../action/extermuraImediaAction";

/**
 * 新增校外媒体预约页面
 */
class AddoffCampusMediaPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            externalMediaInfo: {},
            goBack: getInfoFromLocalStorage('goBack'),
            id:props.match.params.id,
            currentUser:getInfoFromLocalStorage("userInfo"),
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
        }
    }

    componentWillMount() {
    }

    componentDidMount() {

        //获取校外媒体预约信息
        if(this.state.id>0){
            let path="Pms";
            if(this.state.isContributeRoleFlag){
                path="Contri";
            }
            let url="/cm-sites-app-h5/externalArrange"+path+"/getById"
            let param=new FormData();
            param.append("id",this.state.id);
            getOffCampusMediaInfo(param,url,(data)=>{
                this.setState({
                    externalMediaInfo:data
                })
            });
        }
    }

    goBackTimeout;

    goBack() {
        this.goBackTimeout = setTimeout(() => {
            var url = '/offCampusMediaPage/offCampusMediaPage';
            this.state.history.push(url);
        }, 100);
    }

    componentWillUnmount() {
        clearTimeout(this.goBackTimeout);
    }

    //提交审核
    submitAudit=()=> {
        this.child.showAccountModal();
    }

    //暂存
    staging=()=> {
        this.child.submitData();
    }

    render() {
        const {externalMediaInfo,currentUser}=this.state;
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}
                rightContent={[]}
            ><span className={'nav_title'}>校外媒体预约</span></NavBar>
            <ExternalArrangeCardForm externalMediaInfo={this.state.externalMediaInfo} onRef={(child) => {
                this.child = child
            }} newClue={this.state.newClue}></ExternalArrangeCardForm>
            <div style={{textAlign:'center'}}>
                {((!externalMediaInfo.status)||(externalMediaInfo.status== -999 && currentUser.account == externalMediaInfo.submitter) ||
                    (externalMediaInfo.status== -2 && (externalMediaInfo.accounts==null ||
                        externalMediaInfo.accounts.indexOf(currentUser.account)>-1) && (currentUser.roleCodes.indexOf("shr")>-1
                        || currentUser.roleCodes.indexOf("xsjz")>-1) && currentUser.account == externalMediaInfo.submitter))?
                    <div><Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.submitAudit}>提交审核</Button>
                        <Button type="primary"   inline  size="small" style={{ marginRight: '4px',background:'#00CA00',width:'40%'}}  onClick={this.staging}>暂存</Button></div>
                    :''}
                {(externalMediaInfo.status== -2 && currentUser.roleCodes.indexOf("shr")>-1 && currentUser.account != externalMediaInfo.submitter
                    && (externalMediaInfo.accounts==null || externalMediaInfo.accounts.indexOf(currentUser.account)>-1))?
                    <div><Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.auditPass}>通过</Button>
                        <Button  type="primary" inline  size="small" style={{ marginRight: '4px',background:'#108EE9',width:'40%' }} onClick={this.auditRefuse}>不通过</Button>
                    </div>:''
                }
            </div>
        </div>
    }
}


export default AddoffCampusMediaPage;
