import React, {Component} from 'react';
import StoryPage from "./StoryPage";
import {getInfoFromLocalStorage, setInfoToLocalStorage} from "../../action";
import Redirect from "react-router-dom/es/Redirect";

/**
 * 更具type跳转到对应的业务页面；
 */
class RedirectBuinessPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.match.params.type,
            userName: this.props.match.params.userAccount,
            history: require('history').createHashHistory(),
            isContributeFlag: this.props.match.params.isContributeFlag
        };
    }

    componentWillMount() {
        //投稿端角色标识
        let isContributeFlag = false;
        if (this.state.isContributeFlag == 'true') {
            isContributeFlag = true;
        }
        setInfoToLocalStorage('isContributeRoleFlag', isContributeFlag);
        setInfoToLocalStorage('userName', this.state.userName);
        setInfoToLocalStorage('goBack', false);
    }

    render() {
        let entView = "";
        const type = this.state.type;
        if (type === 'xwtg') {
            entView = <StoryPage isContributeRoleFlag={getInfoFromLocalStorage("isContributeRoleFlag")}
                                 userName={getInfoFromLocalStorage("userName")} goBack={false}></StoryPage>
        } else if (type === 'cfrw') {
            entView =
                <Redirect from="/*" to={"/interviewTask/interviewTask/" + this.state.isContributeFlag + '/false'}/>
        } else if (type === 'xwxs') {
            entView = <Redirect from="/*" to={"/newsCluePage/newsCluePage/" + this.state.isContributeFlag + '/false'}/>
        } else if (type === 'ygrw') {
            entView =
                <Redirect from="/*" to={"/manuscriptPage/manuscriptPage/" + this.state.isContributeFlag + '/false'}/>
        } else if (type === 'rmtgl') {
            entView = <div>拼命开发中。。。</div>
        }
        return entView;
    }

}

export default RedirectBuinessPage;
