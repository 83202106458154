/**
 * created by lei lei on 2020/5/6
 * 选择树组件
 * dataSource:数据源必传项,
 * needCheckFile:默认false选传项,非必传项
 * clickOk={(selectData)=>{
                 console.log(selectData)
                 }}//必传项。
 clickCancle={()=>{
                     console.log('点击返回')
                 }}//必传项。
 treeId={'treeId'} //id标识必传项
 subtree={'subtree'}//子节点标识必传项
 treeName={'treeName'}//显示的名称必传项
 defaultData={[]}//默认数据//非必传项
 title={'你的标题'}非必传项
 showMainColumn={true}
 */
import React, {Component} from 'react';
import {Checkbox, Button, Icon} from 'antd-mobile';
import Ifile from './file.jpg';
import {uuid} from '../../utils/index'
import PropTypes from 'prop-types';

const CheckboxItem = Checkbox.CheckboxItem;
export default class SelectTree extends Component {
    static defaultProps = {
        needCheckFile: false,
        showMainColumn:false,
    };
    //isRequired 必传字段，没有的是非必传字段
    static propTypes = {
        title: PropTypes.string,//标题
        dataSource: PropTypes.array.isRequired, //数据源
        defaultData: PropTypes.array,//默认数据源
        needCheckFile: PropTypes.bool,//是否可以点击选择文件夹
        treeId: PropTypes.string.isRequired,//数据源标识独特的id,不可重复
        subtree: PropTypes.string.isRequired,//数据源标识子节点key
        treeName: PropTypes.string.isRequired,//数据源的名称字段
        clickOk: PropTypes.func.isRequired,//点击确定函数
        clickCancle: PropTypes.func.isRequired,//点击返回函数
        showMainColumn:PropTypes.bool,//是否显示主栏目
    }
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],//数据源
            isFirst: true,//是否是第一次进入
            currentDate: [],//当前页面展示的数据
            BreadList: [],//面包屑数据
            checkedData: [],//选中的的数据
            deleteIndex: ''

        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        let subtreeKey = this.props.subtree
        let treeNameKey = this.props.treeName
        this.state.dataSource = this.props.dataSource
        this.checkedData()
        if (this.state.isFirst) {
            this.state.currentDate = this.props.dataSource
        }

        if (this.state.BreadList.length === 0) {
            this.state.BreadList.push({[treeNameKey]: '全部',})
        }
        if (this.state.isFirst && this.props.defaultData) {

            this.state.checkedData = this.props.defaultData
        }


        return (
            <div key={uuid()} style={{background: 'white'}}>
                {/*顶部标题*/}
                {this.props.title ? <div style={{
                    height: 40,
                    lineHeight: '40px',
                    borderBottom: '1px solid #F0F0F0',
                    textAlign: 'center'
                }}>{this.props.title}</div> : null}
                {/* 头部面包屑*/}
                <div style={{
                   /* float: 'left',*/
                  /*  position: 'fixed',*/
                    top: this.props.title ? 41 : 0,
                    background: 'white',
                    width: '-webkit-fill-available',
                    paddingLeft: '10px',
                    zIndex: '999999',
                    overflowX: 'scroll',
                }}>
                    <div style={{width: (this.state.checkedData.length + 1) * 500}}>
                        {this.state.BreadList.map((item, index) => {
                            return (
                                <div key={uuid()} onClick={() => this.onclickBread(item, index)} style={{
                                    float: 'left',
                                    padding: '10px 0'
                                }}>{item[treeNameKey]}{index === this.state.BreadList.length - 1 ? null : '>>'} </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{clear: 'both', marginTop: 38}}/>
                {/*选择树下面写法是避免引用组件页面数据请求延迟造成组件由于数据无法渲染的问题*/}
                {
                    this.state.currentDate.map((item, index) => {
                        return (
                            this.renderItem(item, index)
                        )
                    })
                }
                {/*底部展示部分和确定按钮*/}
                <div style={{
                    height: 135,
                    position: 'fixed',
                    bottom: 0,
                    background: 'white',
                    width: '-webkit-fill-available',
                }}>
                    <div style={{width: window.innerWidth, height: '135px', float: 'left', overflowY: 'scroll'}}>
                        <div style={{height: (this.state.checkedData.length + 1) * 150}}>
                            {this.state.checkedData.map((item, index) => {
                                return (
                                    <div key={uuid()} style={{
                                        height: 45,
                                        lineHeight: '45px',
                                        padding: '0 10px',
                                        float: 'left'
                                    }}> {item[treeNameKey]} <Icon onClick={() => this.cancelChecked(item)}
                                                                  type={'cross'}
                                                                  color={'red'}/></div>
                                )
                            })}
                        </div>
                    </div>
                    <Button type="primary" onClick={() => this.onClickOk()} style={{float: 'right', marginRight: 10,position:'absolute',bottom: 10,right: 10}}
                            size="big"
                            inline>{this.state.checkedData.length > 0 ? '确定' : '返回'}({this.state.checkedData.length})</Button>
                </div>
            </div>
        );

    }

//渲染每一项
    renderItem(item, index) {
       let subtreeKey = this.props.subtree
        let treeNameKey = this.props.treeName
        let LastIndex = this.state.currentDate.length - 1
        let   marginBottom=0
        if(index === LastIndex){
            marginBottom=200
        }

        return (
            <div style={{paddingLeft: '5px', paddingRight: '5px', borderBottom: '1px solid #F0F0F0',margin: '0 5px',marginBottom: marginBottom,  }}
                 key={uuid()}
            >
                <div style={{height: 45, lineHeight: '45px', display: 'inline-flex'}}>
                    <div style={{float: 'left',display: 'inline-flex'}}>

                        {item[subtreeKey].length === 0 ? null : (this.props.needCheckFile ?
                            <div style={{height: 40, width: 40, float: 'left'}}><CheckboxItem key={index}
                                                                                              checked={item.checked}
                                                                                              onChange={() => this.onChangeCheckbox(item)}/>
                            </div> : null)}
                        {item[subtreeKey].length === 0 ?
                            <div style={{height: 40, width: 40}}><CheckboxItem key={uuid()} checked={item.checked}
                                                                               onChange={() => this.onChangeCheckbox(item)}/>
                            </div> :
                            <div style={{float: 'left', marginTop: '5px', marginLeft: '10px'}}><img
                                style={{width: 20, height: 20}} src={Ifile} alt=""/></div>}
                    </div>
                    <div onClick={() => this.itemClick(item)}
                         style={{marginLeft: '10px', float: 'left',width: (window.innerWidth-(this.props.needCheckFile?80:50)),overflow: 'hidden',whiteSpace: 'nowrap',textOverflow: 'ellipsis' }}> {item[treeNameKey]}{this.props.showMainColumn?<span style={{color:'red'}}>{item.mainColumn?'*':null}</span>:null}</div>

                </div>
                <div style={{clear: 'both'}}/>
            </div>
        )
    }

    //每一项点击
    itemClick(item) {
        var treeNameKey = this.props.treeName
        var subtreeKey = this.props.subtree
        if (item[subtreeKey].length === 0) {
            return
        }
        if (this.state.BreadList.length === 0) {
            this.state.BreadList.push({[treeNameKey]: '全部',})
        }
        this.state.BreadList.push(item)
        this.state.currentDate = item[subtreeKey]
        this.state.isFirst = false
        this.setState({})
    }

    //面包屑
    onclickBread(item, index) {
        var treeNameKey = this.props.treeName
        var subtreeKey = this.props.subtree
        if (item[treeNameKey] === '全部') {
            this.state.BreadList.splice(index + 1, this.state.BreadList.length + 1)
            this.state.currentDate = this.props.dataSource
            // this.state.isFirst = true
        } else {
            this.state.currentDate = item[subtreeKey]
            this.state.BreadList.splice(index + 1, this.state.BreadList.length + 1)
            this.state.isFirst = false
        }
        this.setState({})
    }

    //点击复选框选中按钮
    onChangeCheckbox(record) {
        //默认是选中的
        if (record.checked) {
            this.cancelChecked(record)
            this.checkedData()
        } else {
            //默认没有选中
            this.state.checkedData.push(record)
            this.checkedData()
        }
        this.setState({})
    }

    //取消选中的
    cancelChecked(record) {
        var treeIdKey = this.props.treeId;
        var treeId = record[treeIdKey];
        this.state.checkedData.forEach((Item, Index) => {
            if (Item[treeIdKey] === treeId)
                this.state.deleteIndex = Index
        })
        this.state.checkedData.splice(this.state.deleteIndex, 1)//删除当前数据
        this.checkedData()
        this.setState({})
    }

    //核对当前选中数据与上面展示的数据
    checkedData() {
        var treeIdKey = this.props.treeId;
        //全部置为false
        this.state.currentDate.forEach((item, index) => {
            this.state.currentDate[index].checked = false;
            this.state.currentDate[index].mainColumn=false;
        });
        this.state.checkedData.forEach((Item, Index) => {
            this.state.currentDate.forEach((item, index) => {
                if (Item[treeIdKey] === item[treeIdKey]) {
                    this.state.currentDate[index].checked = true;
                    if(Index===0){
                        this.state.currentDate[index].mainColumn=true;
                    }else{
                        this.state.currentDate[index].mainColumn=false;
                    }
                }
            })
        })
    }

    //把组件数据返回到页面
    onClickOk() {
        if (this.state.checkedData.length === 0) {
            this.props.clickCancle && this.props.clickCancle()
        } else {
            this.props.clickOk && this.props.clickOk(this.state.checkedData)
        }

    }
}

