import React, {Component} from 'react';
import {
    getUserInfoByAccount,
    setInfoToLocalStorage, getUserAllAuth, removeInfoFromLocalStorage
} from "../../action";
import Toast from "antd-mobile/lib/toast";
import config from "../../config";
import {setContributeRoleFlag} from "../../store/actions";
import {connect} from "react-redux";

/**
 * 统一身份认证的前台页面，
 */
class CasLogin extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            history: require("history").createHashHistory,
        //     userName: this.props.match.params.userName,
        });
    }

    componentDidMount() {
        this.checkLoginUser();
    }

    /**
     * 登录校验
     */
    checkLoginUser() {
        //先清空 sessoinStorage;
        removeInfoFromLocalStorage("currentMediaCode");
        //登录结束后，需要获取用户的信息
        //把上一个页面压到history中，
        // setInfoToLocalStorage('userName', this.state.userName);

        //登录的有返回到首页的按钮
        setInfoToLocalStorage('goBack', true);
        //获取用户的所有权限；
        getUserAllAuth({}, this.userAllAuthBack.bind(this));
    }

    /**
     * 获取用户权限回调函数；
     * @param data
     */
    userAllAuthBack(data) {
        if (data) {
            let permissions = [];
            for (var i = 0; i < data.length; i++) {
                if (!data[i]) {
                    continue;
                }
                permissions.push(data[i].code);
            }
            setInfoToLocalStorage("permissions", permissions);
            //登录结束后，需要获取用户的信息
            getUserInfoByAccount({}, this.saveUserInfoToLocalStorage.bind(this));
        } else {
            Toast.fail('当前用户没有配置任何权限');
        }
    }


    /**
     * 将用户的信息保存到redux的userName中
     * @param data
     */
    saveUserInfoToLocalStorage(data) {
        let contributeRoleCodes = config.contributeRoleCodes;
        let isContributeflag = false;
        let userRoleCodes = data.roleCodes;
        for (let i = 0, lenth = userRoleCodes.length; i < lenth; i++) {
            if (contributeRoleCodes.indexOf(userRoleCodes[i]) > -1) {
                isContributeflag = true;
                break;
            }
        }

        this.props.setContributeRoleFlag(isContributeflag);

        //投稿端角色标识
        setInfoToLocalStorage('isContributeRoleFlag', isContributeflag);

        let path = '/index/homeTab';
        this.state.history().push(path);

    }

    render() {
        return <div>加载中...</div>
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setContributeRoleFlag: function (contributeRoleFlag) {
            dispatch(setContributeRoleFlag(contributeRoleFlag));
        }
    }
}


export default connect(null, dispatchToProps)(CasLogin);
