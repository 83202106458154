import React, {Component} from "react";
import {StickyContainer, Sticky} from 'react-sticky';
import {ListView, List, Checkbox, SearchBar} from 'antd-mobile';
import './addressBook.css';


const {Item} = List;
const CheckboxItem = Checkbox.CheckboxItem;

function genData(ds, provinceData) {
    const dataBlob = {};
    const sectionIDs = [];
    const rowIDs = [];
    Object.keys(provinceData).forEach((item, index) => {
        sectionIDs.push(item);
        dataBlob[item] = item;
        rowIDs[index] = [];

        provinceData[item].forEach((jj) => {
            rowIDs[index].push(jj.id);
            dataBlob[jj.id] = jj;
        });
    });
    return ds.cloneWithRowsAndSections(dataBlob, sectionIDs, rowIDs);
}

class AddressBook extends Component {
    constructor(props) {
        super(props);
        const getSectionData = (dataBlob, sectionID) => dataBlob[sectionID];
        const getRowData = (dataBlob, sectionID, rowID) => dataBlob[rowID];

        const dataSource = new ListView.DataSource({
            getRowData,
            getSectionHeaderData: getSectionData,
            rowHasChanged: (row1, row2) => row1 !== row2,
            sectionHeaderHasChanged: (s1, s2) => s1 !== s2,
        });

        this.state = {
            inputValue: '',
            dataSource,
            isLoading: true,
            selectData:[],
        };
    }

    componentDidMount() {
        // simulate initial Ajax
        setTimeout(() => {
            this.setState({
                dataSource: genData(this.state.dataSource, this.props.data),
                users: this.props.data,
                isLoading: false,
            });
        }, 600);
    }


    onChange = (val) => {
        this.props.getUserAccountList(val);

        let {selectData}=this.state;
        let index = selectData.indexOf(val);
        if (index > -1) {
            selectData.splice(index, 1);
        } else {
            selectData.push(val);
        }
        this.setState({
            selectData
        })
    }


    onSearch = (val) => {
        const pd = {...this.state.users};
        Object.keys(pd).forEach((item) => {
            const arr = pd[item].filter(jj => jj['name'].toLocaleLowerCase().indexOf(val) > -1);
            if (!arr.length) {
                delete pd[item];
            } else {
                pd[item] = arr;
            }
        });
        this.setState({
            inputValue: val,
            dataSource: genData(this.state.dataSource, pd),
        });
    }

    render() {
        const {value,selectData} = this.state;
        const row = (rowData, sectionID, rowID) => {
            return <Item>
                <CheckboxItem key={rowData.id} defaultChecked={selectData.indexOf(rowData.id)>-1} onChange={() => this.onChange(rowData.id)}>
                    <img
                        src={rowData.photoUrl ? rowData.photoUrl : (rowData.sex === 1 ? 'images/header_boys.png' : 'images/header_girls.png')}></img> {rowData.name}
                </CheckboxItem>
            </Item>

        }

        return (<div style={{paddingTop: '44px', position: 'relative'}}>
            <div style={{position: 'fixed', top: 45, zIndex: '5', left: 0, right: 0}}>
                <SearchBar
                    value={this.state.inputValue}
                    placeholder="请输入拼音搜索"
                    onChange={this.onSearch}
                    onClear={() => {
                        console.log('onClear');
                    }}
                    showCancelButton={false}
                    // onCancel={() => {
                    //     console.log('onCancel');
                    // }}
                />
            </div>
            <div className={'addressList'}>
                <ListView.IndexedList
                    dataSource={this.state.dataSource}
                    className="am-list sticky-list"
                    useBodyScroll={true}

                    renderSectionWrapper={sectionID => (
                        <StickyContainer
                            key={`s_${sectionID}_c`}
                            className="sticky-container"
                            style={{zIndex: 4}}
                        />
                    )}
                    renderSectionHeader={sectionData => (
                        <Sticky>
                            {({
                                  style,
                              }) => (
                                <div
                                    className="sticky"
                                    style={{
                                        ...style,
                                        zIndex: 3,
                                        top: '0px',
                                        left: '0px',
                                        paddingLeft: '15px',
                                        backgroundColor: sectionData.charCodeAt(0) % 2 ? '#F5F5F9' : '#F5F5F9',
                                        color: 'black',
                                    }}
                                >{sectionData}</div>
                            )}
                        </Sticky>
                    )}
                    renderRow={row}
                    quickSearchBarStyle={{
                        top: 85,
                    }}
                    delayTime={10}
                    delayActivityIndicator={<div style={{padding: 25, textAlign: 'center'}}>rendering...</div>}
                />
            </div>
        </div>);
    }
}

export default AddressBook;
