import React, {Component} from 'react';
import {NavBar, Icon} from "antd-mobile";
import {uuid} from "../../utils";
import { setReadMessageStatus} from "../../action";
import MessageListView from "../../components/MessageListView";

/**
 * 消息列表详情
 */
const messageType2Name = {
    5: '公告',
    4: '任务',
    3: '线索',
    2: '约稿',
    1: '稿件',
}

class MessageDetailList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory,
            messageType: this.props.match.params.messageType,
            messageId: this.props.match.params.messageId,
            messageTypeName: messageType2Name[this.props.match.params.messageType],
            allRead: false
        }
    }


    /**
     * 返回按钮
     */
    goBack() {
        var url = "/index/homeTab/";
        this.state.history().push(url);
    }

    /**
     * 一键阅读
     */
    readAll() {

        var paramForm = new FormData();
        paramForm.append("messageType", this.state.messageType);
        setReadMessageStatus(paramForm, this.updateMessageStatus.bind(this));
    }

    /**
     * 刷新页面的读取状态
     * @param data
     */
    updateMessageStatus(data) {
        if (data) {
            this.setState({allRead: true});
        }
    }


    render() {
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div key={uuid()} className={'nav_goback'} onClick={() => this.goBack()}>返回</div>
                ]}
            ><span className={'nav_title'}>{this.state.messageTypeName}消息列表</span></NavBar>
            <MessageListView messageType={this.state.messageType} allRead={this.state.allRead}></MessageListView>
        </div>

    }


}

export default MessageDetailList;
