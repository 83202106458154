import React, {Component} from 'react';
import { WingBlank, WhiteSpace, Flex, Badge} from 'antd-mobile';
import {getMessageType, getUnReadMsgCount} from "../../action";
import { subString, uuid} from "../../utils";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

/**
 * 消息列表
 */
class MessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type2Count: {},
            messageList: []
        }
    }

    componentDidMount() {
        getMessageType({}, this.updateMessageList.bind(this));
        //获取未读消息的数量
        var param = {};
        getUnReadMsgCount(param, this.updateMessageCount.bind(this));
    }

    updateMessageList(data) {
        this.setState({
            messageList: data
        })
    }

    updateMessageCount(data) {
        if (data) {
            this.setState({
                type2Count: data
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContent) {
        console.log('MessageList');
        if (nextProps.selectedTab === 'messageTab') {
            getMessageType({}, this.updateMessageList.bind(this));
        }

    }

    getMessageList(dataList) {
        console.info('dataList',dataList)
        if (!dataList) {
            return "";
        }
        const messageListView = dataList.map((item) => {
            return this.messageItemView(item);
        });
        return messageListView;
    }

    messageItemView(item) {

        var detail = item.noticeDetail[0];
        var icon_type_element = null;
        var messageType = null;
        var backcolor = null;
        switch (detail.type) {
            case 4:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-intervier.png"}/>;
                messageType = '采访';
                backcolor = '#4677F3';
                break;
            case 3:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-newclue.png"}/>;
                messageType = '线索';
                backcolor = '#A652E8';
                break;
            case 2:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-story.png"}/>;
                messageType = '约稿';
                backcolor = '#FF7953';
                break;
            case 5:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-notice.png"}/>;
                messageType = '公告';
                backcolor = '#FF7953';
                break;
            default:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-message.png"}/>;
                messageType = '消息';
                backcolor = '#00BA85';
        }
        return <div key={uuid()}>
            <WhiteSpace size="lg"/>
            <WingBlank style={{margin:'5px'}}>
                <div style={{backgroundColor: '#ffffff', borderRadius: '8px', padding: '17px 16px'}}>
                    <Flex>
                        <Flex.Item style={{flex: 1.5, textAlign: 'center'}}>{icon_type_element}<Badge
                            text={this.state.type2Count[detail.type]}></Badge></Flex.Item>
                        <Flex.Item style={{flex: 5}}>
                            <Flex direction={'column'} style={{width: '100%'}}>
                                <Link
                                    to={'/messageDetailList/' + detail.type}>
                                    <Flex.Item style={{width: '100%'}}><span className={'message_title'} style={{
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        height: '23px',
                                        width:'calc(100% - 50px)',
                                        whiteSpace: 'nowrap',
                                        color: '#000000',
                                        display: 'inline-flex',
                                    }} dangerouslySetInnerHTML={{__html: subString(detail.title, 12)}}></span>
                                        <b style={{
                                            backgroundColor: backcolor,
                                            borderRadius: '4px',
                                            color: '#ffffff',
                                            padding: '2px',
                                            marginLeft: '10px'
                                        }}>{messageType}</b></Flex.Item>
                                    <Flex.Item style={{color: '#A4A4A4', marginTop: '10px'}}>
                                        <div style={{color: '#A6A6A6'}} className={'message_content'}
                                             dangerouslySetInnerHTML={{__html: detail.content}}></div>
                                    </Flex.Item>
                                </Link>
                                <Flex.Item style={{width: '100%', marginTop: '10px', color: '#A4A4A4'}}>
                                    <Flex justify="between">
                                        <div>{detail.sender}</div>
                                        <div>{detail.createTime}</div>
                                    </Flex>
                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                    </Flex>
                </div>
            </WingBlank>
        </div>
    }

    render() {
        return <div>
            {this.getMessageList(this.state.messageList)}
        </div>
    }
}

const stateToProps = (state) => {
    return {
        selectedTab: state.navigateReducer.selectedTab,
    }
}

export default connect(stateToProps, null)(MessageList);
