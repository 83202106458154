import ReactPlayer from 'react-player';
import ReactDOM from "react-dom";
import React from "react";

const MAX_WIDTH ='65vw';

const FIXED_WIDTH =961;

/**
 * 渲染 视频
 * @param _file
 * @param _call
 * @param editor
 */
export const  renderVideo = function(_file,_call,editor,noAdd){
    if(!noAdd) {
        editor.saveState();
        editor.append(`<p style="text-align: center;">
			<embed class="edui-upload-video" 
				src="/system/resource/swf/flowplayer.commercial.swf" 
				vsburl="${_call.url}"
				vsbmp4video="true" 
				ue_src="${_call.url}" 
				title="${_file.name}" 
				width="420" 
				height="280" 
				align="none" 
				style="display: none" 
				autostart="false" 
				vsbautoplay="false" 
				controls="IMAGEWINDOW,ControlPanel,StatusBar" 
				console="Clip1">
        </p>`);
    }
    if(document.getElementById(_file.source.ruid+"_"+_file.id)){
        ReactDOM.render(<ReactPlayer
                style={{zIndex:'1',position:'relative',margin:'auto',textIndent:'0'}}
                url={_call.url}
				//url='http://192.168.120.65:17189/storage/liyuhang/m3u8/liyuhang.m3u8'
                playing={false}
                playsInline={true}
                controls={true}
                width={MAX_WIDTH}
                height={'auto'}
				max-height={'200px'}
                config={
                    {
                        file:{
							forceVideo: true
                        }
                    }
                }/>,
            document.getElementById(_file.source.ruid+"_"+_file.id));
    }
    editor.hideEditorControllerLayer();
}

/**
 * 渲染音频
 */
export const renderAudio =(_file,_call,editor,noAdd) =>{
    if(!noAdd){
        editor.saveState();
        editor.append(`<p style="text-align: center;">
			<embed type="audio/mp3" 
					class="edui-faked-music" 
					src="/system/resource/images/ueditor/musicFlash/player_mp3_maxi.swf" 
					vsbmp3audio="true" 
					vsb_data="${_call.url}" 
					title="${_file.name}"
					musicname="${_file.name}" 
					width="400" 
					height="95" 
					align="none" 
					wmode="transparent" 
					play="true" 
					loop="false" 
					menu="false" 
					allowscriptaccess="never" 
					allowfullscreen="true">
			</p>`);
    }
    if(document.getElementById(_file.source.ruid+"_"+_file.id)) {
        ReactDOM.render(<ReactPlayer
                style={{zIndex: '1', position: 'relative', margin: 'auto',textIndent:'0'}}
                url={_call.url}
                playing={false}
                playsInline={true}
                controls={true}
                width={MAX_WIDTH}
                height={'30px'}
                config={
                    {
                        file: {
                            forceAudio: true
                        }
                    }
                }
            />,
            document.getElementById(_file.source.ruid + "_" + _file.id));
    }
    editor.hideEditorControllerLayer();
}

/**
 * 渲染照片
 */
export const renderImage =(_file,_call,editor) =>{
    // eslint-disable-next-line
    let _hasStyleBarBtn = $('#rt_'+_file.source.ruid).parents('.Eleditor-textStyle-item-upImg').length > 0;
    //详细编辑器中
    editor.saveState();
    if(_hasStyleBarBtn){
        editor.appendTextArea("<p style='text-align: center' class='Eleditor-image-area'><img style=\"max-width:65vw;\" src='"+_call.url+"'/></p>");
    }else{ //最外层编辑器
        editor.append("<p style='text-align: center' class='Eleditor-image-area'><img style=\"max-width:65vw;\" src='"+_call.url+"'/></p>");
    }
    editor.hideEditorControllerLayer();
}

/**
 *
 * @param story
 * @param editor
 */
export  const renderContent =(story,editor) =>{
    if(story.content){
        //editor.quietClear();
       let rendContentObj = storyView();
       editor.setContent(rendContentObj.render(story));
       rendContentObj.audioObject.forEach(item=>{
                renderAudio({source:{ruid:item.ruid},id:item.id},{url:item.src},editor,true);
            }
        );
       rendContentObj.videoObject.forEach(item=>{
                renderVideo({source:{ruid:item.ruid},id:item.id},{url:item.src},editor,true);
            }
        );
    }else{
         if(editor && editor.quietClear)editor.quietClear();
    }
}

const storyView =()=>{
    return {
        options :{
            //渲染类型, / div /default
            renderType:'div',
            getFormat:function(story){
                return story.ZkyStory_format || story.format;
            },
            getConext:function(story){
                return story.ZkyStory_content || story.content;
            }
        },
        videoObject:[],
        audioObject:[],
        // 加载 一键排版 样式
        loadFormatStyle:function(formatNumber){
            let styleLink = document.createElement("link");
            styleLink.rel ="stylesheet";
            styleLink.href="/cm-sites/style/typeset/format"+formatNumber+".css";
            return styleLink;
        },
        //入口
        render:function(story){
            let that = this;
            let panel= document.createElement("div");
            panel.innerHTML = that.options.getConext(story);

            let videos = panel.getElementsByClassName('Eleditor-video-area');
            // eslint-disable-next-line no-undef
            $.each(videos,function(index,item){
                if(item){
                    item.parentNode.removeChild(item);
                }
            });
            let audios = panel.getElementsByClassName('Eleditor-audio-area');
            // eslint-disable-next-line no-undef
            $.each(audios,function(index,item){
                if(item){
                    item.parentNode.removeChild(item);
                }
            });
            //一键排版
            let format = that.options.getFormat(story);
            if(format && panel.getElementsByTagName('link').length > 0){
            }else if(format){
				// eslint-disable-next-line no-undef
				$(".media-eleditor").attr('id','vsb_content_'+format);
                panel.appendChild(that.loadFormatStyle(format));
            }else{
				// eslint-disable-next-line no-undef
				$(".media-eleditor").removeAttr("id");
			}
            //照片
            let imgs = panel.getElementsByTagName('img');
            // eslint-disable-next-line no-undef
            $.each(imgs,function (index,dom) {
				if(dom.src && dom.src.startsWith("file://")){
					dom.src ='/cm-sites/app-h5/images/spacer.gif';
					dom.alt="包含本地图片,请先上传功能图片";
					dom.style='background:url(/cm-sites/app-h5/images/localimage.png) no-repeat center center;border:1px solid #ddd';
					return true;
				}
                //宽度
                if(dom.style.width){
                    dom.setAttribute('stylewidth',dom.style.width);
                    //dom.style.width = (parseInt(dom.style.width)/FIXED_WIDTH*(window.innerWidth));
                }else if(dom.width){
                    dom.setAttribute('widthpc',dom.width);
                    //dom.width = (parseInt(dom.width)/FIXED_WIDTH*(window.innerWidth));
                }else{
                    dom.setAttribute('maxwidthpc',dom.style.maxWidth);
                    dom.style.maxWidth = MAX_WIDTH;
                }
                //高度
                if(dom.style.height){
                    dom.setAttribute('styleheight',dom.style.height);
                    dom.style.height = 'auto';
                }else if(dom.height){
					dom.setAttribute('heightpc',dom.height);
                    dom.style.height = 'auto';
                }
            });
            //写稿 页面 视频 音频 处理
            let embeds = panel.getElementsByTagName('embed');
            let num = 1;
            // eslint-disable-next-line no-undef
            $.each(embeds,function(index,item){
                if(item.attributes.vsbmp4video && item.attributes.vsbmp4video.value == 'true' && item.attributes.ue_src){
					var videoSrc = item.attributes.ue_src.value;
                    let videoDiv = document.createElement("div");
                    videoDiv.id ='story_info_video_'+index;
                    videoDiv.className ='Eleditor-video-area';
                    videoDiv.style = item.style;
                    videoDiv.style.margin ='auto';
                    that.videoObject.push({
                        ruid:'story_info_video',
                        id:index,
                        src:videoSrc
                    });
                    item.style.display = 'none';
                    item.setAttribute('autoStart',false);
                    do{
                        item = item.parentNode;
                    }while (!item.nodeName =='P' || !item.nodeName =='DIV');
                    // eslint-disable-next-line no-undef
                    $(item).after(videoDiv.outerHTML);
                }else //音频类型
                if(item.attributes.vsbmp3audio  && item.attributes.vsbmp3audio.value== 'true' && item.attributes.vsb_data){
					var audioSrc = item.attributes.vsb_data.value;
                    let audioTitle = item.title;
                    let audioDiv = document.createElement("div");
                    audioDiv.style = item.style;
                    audioDiv.style.margin ='auto';
                    audioDiv.id ='story_info_audio_'+num;
                    audioDiv.className ='Eleditor-audio-area aplayer';
                    that.audioObject.push({
                        ruid:'story_info_audio',
                        id:num,
                        src:audioSrc
                    });
                    item.style.display = 'none';
                    item.setAttribute('play',false);
                    do{
                        item = item.parentNode;
                    }while (!item.nodeName =='P' || !item.nodeName =='DIV');
                    // eslint-disable-next-line no-undef
                   $(item).after(audioDiv.outerHTML);
                }
            });
            //渲染到页面内容
            let pizhu = panel.getElementsByClassName("pizhu");
            // eslint-disable-next-line no-undef
            $.each(pizhu,function (index,item) {
                if(item.style){
                    item.style.backgroundColor ='#f2ccd1';
                }
            });
            //let pLabel = panel.getElementsByTagName("p");
            // eslint-disable-next-line no-undef
			/**
            $.each(pLabel,function (index,item) {
                if(item.style){
                    item.style.lineHeight ='normal';
                }
            });**/
            return panel.innerHTML;
        }
    };
}


export const transformMedia =(content) =>{
    let panel = document.createElement("div");
    panel.innerHTML = content;
    //照片
	/**
    let imgs = panel.getElementsByTagName('img');
    // eslint-disable-next-line no-undef
    $.each(imgs, function (index, dom) {
        dom.width = '700px';
        if (dom.attributes.widthpc || dom.attributes.maxwidthpc || dom.attributes.styleheight || dom.attributes.stylewidth) {
            dom.width = dom.attributes.widthpc;
        }
        if (dom.attributes.maxwidthpc) {
            dom.style.maxWidth = dom.attributes.maxwidthpc;
        }
        if (dom.attributes.styleheight) {
            dom.style.height = dom.attributes.styleheight;
        }
        if (dom.attributes.stylewidth) {
            dom.style.width = dom.attributes.stylewidth;
        }
    });**/
    //写稿 页面 视频 音频 处理
    let embeds = panel.getElementsByTagName('embed');
    // eslint-disable-next-line no-undef
    $.each(embeds, function (index, item) {
        item.style.display = 'block';
    });
    let videos = panel.getElementsByClassName('Eleditor-video-area');
    // eslint-disable-next-line no-undef
    $.each(videos, function (index, item) {
        item.parentNode.removeChild(item);
    });
    let audios = panel.getElementsByClassName('Eleditor-audio-area');
    // eslint-disable-next-line no-undef
    $.each(audios, function (index, item) {
        item.parentNode.removeChild(item);
    });
	let links = panel.getElementsByTagName('link')
    // eslint-disable-next-line no-undef
    $.each(links, function (index, item) {
        item.parentNode.removeChild(item);
    });
    return panel.innerHTML;
}
