import React, {Component} from 'react'
import {Icon, InputItem, Modal, NavBar, TextareaItem, Toast, WhiteSpace, WingBlank} from "antd-mobile/lib/index";
import {formatDate, uuid} from "../../../utils";
import {
    getInfoFromLocalStorage,
    getNewClueInfoById,
    getNewCluePicFilesById,
    getNewClueVideoFilesById, retractNewClue, unuseNewsClueAct, useNewsClueAct,
    getNewClueAttachmentFile,
} from "../../../action";
import Button from "antd-mobile/es/button";
import NewClueDetailCardForm from "../../../components/NewClueDetailCard";
import {createForm} from "rc-form";
import './newClue_Detail_Page.css';
import {PIC_TYPE} from "../../../constants";

/**
 * 线索详情页面
 */
class NewClueDetailPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            newClue: {},
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            goBack: getInfoFromLocalStorage('goBack'),
            id: this.props.match.params.id,
            canEdite: false,
            picFiles: [],
            videoFiles: [],
            tsFiles:[],
            toShowRemark: false,
            userOrUnUse: 0,
        }
    }

    componentWillMount() {
        var param = {"id": this.state.id};
        getNewClueInfoById(param, this.updateNewClueInfo.bind(this));

        getNewCluePicFilesById(param, this.updateNewCluePicFile.bind(this));

        getNewClueVideoFilesById(param, this.updateNewVideoInfo.bind(this));

        getNewClueAttachmentFile(param, this.updateNewAttachmentFile.bind(this));
    }

    //附件
    updateNewAttachmentFile(data) {
        let imgAccept = [...PIC_TYPE,].join(",");
        if (!data) {
            return
        }
        data.forEach((item, index) => {
            let nameLength=item.name.split(".").length;
            if (imgAccept.indexOf(item.name.split(".")[nameLength-1]) > -1) {
                item.watch = 1
            }
        });
        for (var i = 0; i < data.length; i++) {
            data[i].url = data[i].path;
        }

        this.setState({
            tsFiles: data
        })
    }


    updateNewCluePicFile(data) {
        if (!data) {
            return
        }
        for (var i = 0; i < data.length; i++) {
            data[i].url = data[i].path;
        }

        this.setState({
            picFiles: data
        })
    }


    updateNewVideoInfo(data) {
        if (!data) {
            return
        }
        for (var i = 0; i < data.length; i++) {
            data[i].url = data[i].path + '/thumbnails/1';
        }

        this.setState({
            videoFiles: data
        })

    }

    updateNewClueInfo(data) {
        if (!data) {
            return;
        }

        let canEdite = false;
        //暂存和撤回的可以编辑。
        if (this.state.isContributeRoleFlag) {
            if (data.status === 3 || data.status === 4) {
                canEdite = true;
            }
        }

        this.setState({
            newClue: data,
            canEdite: canEdite
        })
    }

    goBack() {
        var isContributeRoleFlag = this.state.isContributeRoleFlag;
        var goBack = this.state.goBack;
        var url = '/newsCluePage/newsCluePage/' + isContributeRoleFlag + '/' + goBack;
        this.state.history.push(url);
    }

    //显示线索状态信息
    showNewsClueStatusView(newsClueInfo) {
        if (!newsClueInfo) {
            return;
        }

        let newclueStatusName = '';
        let resionTitle = '';
        if (newsClueInfo.status === 1) {
            newclueStatusName = '已采用';
            resionTitle = '采用原因';
        } else if (newsClueInfo.status === 2) {
            newclueStatusName = '不采用';
            resionTitle = '不用原因';
        } else {
            return "";
        }
        const  newscluetent_titles = <div><div
            style={{fontSize: '15px'}} className={'clue_tittle'}>{resionTitle}</div><div className={'clue_colon'}>:</div></div>;
        return <div>
            <InputItem
                clear
                moneyKeyboardAlign="left"
                type={'text'}
                value={newclueStatusName}
            >线索状态:</InputItem>
            {/*<InputItem*/}
            {/*    clear*/}
            {/*    moneyKeyboardAlign="left"*/}
            {/*    type={'text'}*/}
            {/*    value={newsClueInfo.review_content}*/}
            {/*    multiline={true}*/}
            {/*>{resionTitle}:</InputItem>*/}
            <TextareaItem
                        clear
                          autoHeight
                          title={newscluetent_titles}
                          value={newsClueInfo.review_content}
            />
            <InputItem
                clear
                moneyKeyboardAlign="left"
                type={'text'}
                value={newsClueInfo.review_submitter}
            >操作人:</InputItem>

            <InputItem
                clear
                moneyKeyboardAlign="left"
                value={formatDate(newsClueInfo.review_date.time)}
                type={'text'}
            >操作时间:</InputItem>
        </div>
    }

    /**
     * 撤回线索
     */
    retractNewsClue() {
        let param = {'id': this.state.id};
        retractNewClue(param, this.retractNewClueBack.bind(this));
    }

    /**
     * 采用线索
     */
    useNewsClue() {

        this.setState({
            toShowRemark: true,
            useOrUnuse: 0,
            title: '采用原因'
        })


    }

    //采用，弃用的确定 按钮事件
    useOkButton() {
        let revirew_content = this.props.form.getFieldValue('revirew_content');
        if (!revirew_content || revirew_content.length === 0) {
            Toast.fail('原因不能为空');
            return;
        }
        let param = {
            'id': this.state.id,
            'revirew_content': revirew_content
        };
        if (this.state.useOrUnuse === 0) {
            useNewsClueAct(param, this.useNewsClueActback.bind(this));
        } else {
            unuseNewsClueAct(param, this.unuseNewsClueActBack.bind(this));
        }
        this.setState({
            toShowRemark: false,
        })

        //返回到列表页面；
        this.goBack();
    }

    //采用，弃用的，取消按钮
    useCancelButton() {
        this.setState({
            toShowRemark: false,
        })
    }


    useNewsClueActback(data) {
        if (data) {
            Toast.success('采用成功！');
        }
    }

    /**
     * 弃用线索
     */
    unuseNewsClue() {
        this.setState({
            toShowRemark: true,
            useOrUnuse: 1,
            title: '弃用原因'
        })

    }

    unuseNewsClueActBack(data) {
        if (data) {
            Toast.success('弃用成功！');
        }

    }

    /**
     * 撤回线索的回调
     */
    retractNewClueBack(data) {
        if (data) {
            Toast.success("保存成功");
            //回到线索列表页面
            var isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
            var goBack = getInfoFromLocalStorage('goBack');
            var url = '/newsCluePage/newsCluePage/' + isContributeRoleFlag + '/' + goBack;
            this.state.history.push(url);
        }
    }

    //提交线索
    submitData() {
        this.child.submitData();
    }

    /**
     * 线索转采访任务；
     */
  /*  transferInterview() {
        var url = '/addInterviewPage/addInterviewPage/' + this.state.id;
        this.state.history.push(url);
    }*/

    // 加载 采用，弃用，转为采访任务按钮;
    loadNewsClueBtn(newClueInfo) {
        var listButton = [];
        let useButton = <Button type="primary"  style={{width:'47%'}} inline key={uuid()}
                                onClick={() => {
                                    this.useNewsClue()
                                }}>采用</Button>;
        let unseButton = <Button type="ghost" size={'middle'} style={{marginLeft: '20px',width:'47%', backgroundColor: '#BEDCF5', border:'0px'}} inline key={uuid()}
                                 className="am-button-borderfix"
                                 onClick={() => {
                                     this.unuseNewsClue()
                                 }}>弃用</Button>;

        let clueRetractButton = <Button type="primary"  key={uuid()}
                                        style={{margin: '4px'}} onClick={() => {
            this.retractNewsClue()
        }}>撤回</Button>;

        let clueSumitButton = <Button type="primary"  key={uuid()}
                                      style={{margin: '4px'}} onClick={() => {
            this.submitData()
        }}>提交</Button>;
      /*取消转为采访任务按钮*/
      /*  let transfermButton = <Button type="primary" key={uuid()}
                                      onClick={() => {
                                          this.transferInterview()
                                      }}>转为采访任务</Button>;*/
        if (!this.state.isContributeRoleFlag) {
            if (newClueInfo.status === 1) {
                if (newClueInfo.isinterview === 0) {
                    // listButton.push(transfermButton);
                    listButton.push(<WhiteSpace key={uuid()} size="lg"/>);
                }
                return listButton;
            } else if (newClueInfo.status === 0) {
                listButton.push(useButton);
                listButton.push(unseButton);
                return listButton;
            }
        } else {
            if (newClueInfo.status === 0) {
                listButton.push(clueRetractButton);
                return listButton;
            } else if (newClueInfo.status === 4 || newClueInfo.status === 3) {
                listButton.push(clueSumitButton);
                return listButton;
            }
        }
    }

    render() {
        const {getFieldProps} = this.props.form;
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}
            ><span className={'nav_title'}>线索</span></NavBar>
            <div style={{height: '90vh', overflowY: 'auto',overflowX:'hidden'}}>
                <WhiteSpace size="lg"/>
                <WingBlank style={{margin:'5px'}}>
                    {this.loadNewsClueBtn(this.state.newClue)}
                    {this.showNewsClueStatusView(this.state.newClue)}
                </WingBlank>
                <Modal
                    visible={this.state.toShowRemark}
                    transparent
                    maskClosable={false}
                    // title="备注"
                    footer={[{
                        text: '确定', onPress: () => {
                            this.useOkButton()
                        }
                    }, {
                        text: '取消', onPress: () => {
                            this.useCancelButton()
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}
                >
                    <div style={{height: '150px'}}>
                        <div>{this.state.title}</div>
                        <div style={{height: '120px', overflow: 'auto',}}>
                            <TextareaItem style={{fontSize: '15px'}}
                                          {...getFieldProps('revirew_content')}
                                          title=""
                                          placeholder="请填写原因"
                                          data-seed="logId"
                                          autoHeight
                                          rows={2}
                            />
                        </div>
                    </div>
                </Modal>
                <NewClueDetailCardForm videoFiles={this.state.videoFiles} picFiles={this.state.picFiles} tsFiles={this.state.tsFiles}
                                       newClue={this.state.newClue}
                                       canEdite={this.state.canEdite}
                                       refChild={(child) => this.child = child}></NewClueDetailCardForm>
            </div>
        </div>
    }
}

const NewClueDetailPages = createForm()(NewClueDetailPage);
export default NewClueDetailPages;
