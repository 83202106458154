import React, {Component} from 'react'
import {
    Card,
    ImagePicker,
    InputItem,
    List,
    NavBar,
    Toast,
    WhiteSpace,
    WingBlank,
    TextareaItem,
    Icon
} from "antd-mobile";
import {createForm} from 'rc-form';
import config from "../config";
import './storyPage/storyAtrribute.css';
import {jsToFormData, removeArrayByIndex, uuid} from "../utils";
import {saveKnockOutInfo, uploadimage, uploadvideo} from "../action";

/**
 * 爆料页面的开发
 */

// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

class KnockOut extends Component {

    constructor(props) {
        super(props);
        this.state = {
            picFiles: [],
            multiple: true,
            picUpload: [],
            videoUpload: [],
            videoFiles: [],
        };
    }

    /**
     * 修改图片
     * @param files
     * @param type
     * @param index
     */
    onChangePic = (files, type, index) => {

        if (type === 'add') {
            this.state.picUpload=[];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                data.append('file', files[i].file);
                //调用后台接口上传文件，
                uploadimage(data, this.setImageUrlTopicUrls.bind(this));
            }

            this.setState({
                picFiles: files,
            });

        }

        if (type === 'remove') {
            let picUploads = this.state.picUpload;
            picUploads = removeArrayByIndex(index, picUploads);

            let picFiles = this.state.picFiles;
            picFiles = removeArrayByIndex(index, picFiles);
            this.setState({
                picUpload: picUploads,
                picFiles: picFiles
            })
        }
    }

    setImageUrlTopicUrls(data) {
        let picFiles = this.state.picUpload;
        picFiles.push(data);
        this.setState({
            picUpload: picFiles
        })
    }


    /**
     *变更视频
     * @param files
     * @param type
     * @param index
     */
    onChangeVideo = (files, type, index) => {
        if (type === 'add') {
            this.state.videoUpload=[];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file) {
                    data.append('file', files[i].file);

                    //调用后台接口上传文件，
                    uploadvideo(data, this.setVideoUrlTovideoUrls.bind(this));
                }
            }
        }

        if (type === 'remove') {
            let videoUploads = this.state.videoUpload;
            videoUploads = removeArrayByIndex(index, videoUploads);

            let videoFiles = this.state.videoFiles;
            videoFiles = removeArrayByIndex(index, videoFiles);
            this.setState({
                videoUpload: videoUploads,
                videoFiles: videoFiles
            })
        }


    }


    /**
     * 设置视频文件的展示路径
     * @param data
     */
    setVideoUrlTovideoUrls(data) {
        let videoUpload = this.state.videoUpload;
        videoUpload.push(data);

        let videoFiles = this.state.videoFiles;
        let file = {url: data.thumbnailsURL};
        videoFiles.push(file)
        this.setState({
            videoFiles: videoFiles,
            videoUpload: videoUpload
        })
    }

    /**
     * 提交线索数据
     */
    submitData() {
        //获取稿件的属性数据
        let knockOutInfo = this.props.form.getFieldsValue();
        let picUrls = [];
        let videoUrls = [];

        let picFiles = this.state.picUpload;
        let videoFiles = this.state.videoUpload;
        for (var i = 0; i < picFiles.length; i++) {

            picUrls.push(picFiles[i].url)
        }

        for (var i = 0; i < videoFiles.length; i++) {
            videoUrls.push(videoFiles[i].url)
        }


        knockOutInfo.picUrls = picUrls;
        knockOutInfo.videoUrls = videoUrls;

        var formData = new FormData();
        Object.keys(knockOutInfo).forEach((key) => {
            if(knockOutInfo[key]){

                formData.append(key, knockOutInfo[key]);
            }
        });

        //保存新闻线索到数据库
        saveKnockOutInfo(formData, this.updateKnockOut.bind(this));

    }

    updateKnockOut(data) {
        if (data) {
            Toast.success("保存成功");
        }
    }


    render() {
        const {getFieldProps} = this.props.form;
        return <div>
            <NavBar
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                rightContent={[<div key={uuid()} onClick={() => {
                    this.submitData()
                }}>提交</div>]}
            >新闻线索</NavBar>
            <WhiteSpace size="lg"/>
            <WingBlank size="lg" style={{margin:'5px'}}>
                <div className={'storyAttribute'}>
                    <Card>
                        <List>
                            <InputItem
                                clear
                                {...getFieldProps('title')}
                                placeholder="请输入线索标题"
                                moneyKeyboardAlign="left"
                                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                type={'text'}
                            >线索标题:</InputItem>

                            <InputItem
                                clear
                                {...getFieldProps('contacts')}
                                placeholder="请输入联系人"
                                moneyKeyboardAlign="left"
                                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                type={'text'}
                            >联系人:</InputItem>

                            <InputItem
                                {...getFieldProps('contactsWay')}
                                type={'phone'}
                                clear
                                placeholder="请输入联系电话"
                                moneyKeyboardAlign="left"
                                moneyKeyboardWrapProps={moneyKeyboardWrapProps}

                            >联系电话:</InputItem>


                        </List>
                    </Card>

                    <p>线索内容:</p>
                    <TextareaItem
                        {...getFieldProps('content', {
                            initialValue: '',
                        })}
                        rows={5}
                        // count={100}
                    />

                    <div>
                        <p>上传图片</p>
                        <Card>
                            <ImagePicker
                                onAddImageClick={this.addImage}
                                files={this.state.picFiles}
                                onChange={this.onChangePic}
                                selectable={true}
                                multiple={this.state.multiple}
                            />
                        </Card>

                        <p>上传视频</p>
                        <Card>
                            <div className={"video_pincker"}>
                                <ImagePicker
                                    accept={"video/*"}
                                    onAddImageClick={this.addImage}
                                    files={this.state.videoFiles}
                                    // selectable={false}
                                    onChange={this.onChangeVideo}
                                    selectable={true}
                                    multiple={this.state.multiple}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            </WingBlank>
        </div>
    }
}

const KnockOuts = createForm()(KnockOut);

export default KnockOuts;
