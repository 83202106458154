import React, {Component} from 'react';
import {
    Card, Icon,
    ImagePicker,
    InputItem,
    List,
    TextareaItem,
    Toast,
    WhiteSpace,
    WingBlank
} from "antd-mobile";
import config from "../config";
import {
    addNewsClue,
    addNewsCluePic,
    addNewsClueVideo,
    getInfoFromLocalStorage, updateNewsClue,
    uploadimage,
    uploadvideo,
    uploadAttachmentFile,
} from "../action";
import {removeArrayByIndex, uuid} from "../utils";
import createForm from "rc-form/es/createForm";
import Modal from "antd-mobile/es/modal";
import {Player} from "video-react";
import {PhotoSlider} from "react-photo-view";
import './newclue_card.css'
import './newCluedetail_card.css'
import {ATTCHMENT_TYPE, PIC_TYPE, VIDEO_TYPE} from "../constants";
import * as Actions from "../action/topiclist";


// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}

/**
 * 采访详情页面的，采访内容卡片
 */
class NewClueDetailCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            picFiles: this.props.picFiles,
            multiple: true,
            picUpload: [],
            videoUpload: [],
            attachUpload: [],
            videoFiles: this.props.videoFiles,
            picResourceId: [],
            videoResourceId: [],
            attachResourceId: [],
            tsFileIds:[],
            tsFiles: this.props.tsFiles,
            history: require('history').createHashHistory(),
            showVideoView: false, //视频预览
            showPicView: false, //图片预览
            showAttachView: false, //附件预览
            picIndex: 0,//图片预览的索引
            newClue: this.props.newClue,
            canEdite: false,

        };
    }

    //图片，视频数据回显
    componentWillReceiveProps(nextProps, nextContext) {
        let picFiles = nextProps.picFiles;
        if (picFiles && picFiles.length > 0) {
            let picResourceIds = [];
            for (var i = 0; i < picFiles.length; i++) {
                picResourceIds.push(picFiles[i].resourceId);
            }
            this.setState({
                picFiles: picFiles,
                picResourceId: picResourceIds
            })
        };


        let videoFiles = nextProps.videoFiles
        if (videoFiles && videoFiles.length > 0) {
            let videoResourceIds = [];
            for (var i = 0; i < videoFiles.length; i++) {
                videoResourceIds.push(videoFiles[i].resourceId);
            }
            this.setState({
                videoFiles: videoFiles,
                videoResourceId: videoResourceIds
            })
        };


        //附件回显
        let tsFiles = nextProps.tsFiles
        if (tsFiles && tsFiles.length > 0) {
            let attachResourceIds = [];
            for (var i = 0; i < tsFiles.length; i++) {
                attachResourceIds.push(tsFiles[i].resourceId);
            }

            this.setState({
                tsFiles: tsFiles,
                attachResourceId: attachResourceIds
            })
        };

}


    /**
     * 修改图片
     * @param files
     * @param type
     * @param index
     */
    onChangePic = (files, type, index) => {

        if (type === 'add') {
            this.state.picUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file && files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1
                    data.append('file', files[i].file);
                    //调用后台接口上传文件，
                    uploadimage(data, this.setImageUrlTopicUrls.bind(this));
                }
            }

            this.setState({
                picFiles: files,
            });

        }

        if (type === 'remove') {
            let picResourceIds = this.state.picResourceId;
            picResourceIds.splice(index, 1);

            let picUploads = this.state.picUpload;
            picUploads = removeArrayByIndex(index, picUploads);

            let picFiles = this.state.picFiles;
            picFiles = removeArrayByIndex(index, picFiles);
            this.setState({
                picUpload: picUploads,
                picFiles: picFiles
            })
        }
    }

    setImageUrlTopicUrls(data) {
        if (!data) {
            return;
        }
        let storageFile = data.storageFile;

        var param = {
            imageHeight: storageFile.hegiht,
            imageWidth: storageFile.width,
            name: storageFile.name,
            ext: storageFile.suffix,
            path: data.url,
            size: storageFile.size
        }

        addNewsCluePic(param, this.addNewCluePicBack.bind(this));

        let picFiles = this.state.picUpload;
        picFiles.push(data);
        this.setState({
            picUpload: picFiles
        })
    }

    /**
     * 新增采访图片回调
     * @param data
     */
    addNewCluePicBack(data) {
        if (!data) {
            return;
        }
        let picResourceId = this.state.picResourceId;
        picResourceId.push(data);
    }

    componentWillMount() {
        this.props.refChild(this);
    }


    validateFormData() {
        let newsClueInfo = this.props.form.getFieldsValue();
        if (!newsClueInfo.title || newsClueInfo.title.length == 0) {
            Toast.fail('请填写线索标题!');
            return false;
        }
        if (!newsClueInfo.contacts || newsClueInfo.contacts == 0) {
            Toast.fail('请填写联系人!');
            return false;
        }

        if (!newsClueInfo.content || newsClueInfo.content.length == 0) {
            Toast.fail('请填写线索内容!');
            return false;
        }
        return true;
    }

    /**
     * 提交线索数据
     */
    submitData() {
        //数据校验
        var checkResult = this.validateFormData();
        if (!checkResult) {
            return;
        }

        //获取稿件的属性数据
        let newsClueInfo = this.props.form.getFieldsValue();
        let phone = this.props.form.getFieldValue('contactsWay');
        if (phone) {
            phone = phone.replace(/\s*/g, "");
        }
        newsClueInfo.contactsWay = phone;

        //手机号码校验
        if (phone && !(/^1(3|4|5|6|7|8|9)\d{9}$/).test(phone)) {
            Toast.info('联系方式格式错误', 2, null, false);
            return;
        }

        let resourceIdsArra = this.state.picResourceId.concat(this.state.videoResourceId);
        let attachment = this.state.attachResourceId;
        newsClueInfo.status = 0;
        newsClueInfo.id = this.props.newClue.id;
        newsClueInfo.resourceId = 0;
        newsClueInfo.resourceIdsStr = resourceIdsArra.join(',');
        newsClueInfo.clueType=1;
        newsClueInfo.attachmentResourceIds= attachment.join(',');
        //保存新闻线索到数据库
        updateNewsClue(newsClueInfo, this.updateNewsClueBack.bind(this));

    }

    updateNewsClueBack(data) {
        if (data) {
            Toast.success("保存成功");

            //回到线索列表页面
            var isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
            var url = '/newsCluePage/newsCluePage/' + isContributeRoleFlag + '/true';
            this.state.history.push(url);
        }
    }


    /**
     *变更视频
     * @param files
     * @param type
     * @param index
     */
    onChangeVideo = (files, type, index) => {
        if (type === 'add') {
            this.state.videoUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file) {
                    if (files[i].file.uploaded !== 1) {
                        files[i].file.uploaded = 1
                        data.append('file', files[i].file);

                        //调用后台接口上传文件，
                        uploadvideo(data, this.setVideoUrlTovideoUrls.bind(this));
                    }
                }
            }
        }

        if (type === 'remove') {
            let videoUploads = this.state.videoUpload;
            videoUploads = removeArrayByIndex(index, videoUploads);

            let videoFiles = this.state.videoFiles;
            let videoResouceIds = this.state.videoResourceId;
            videoResouceIds.splice(index, 1);
            videoFiles = removeArrayByIndex(index, videoFiles);
            this.setState({
                videoUpload: videoUploads,
                videoFiles: videoFiles
            })
        }


    }


    /**
     * 设置视频文件的展示路径
     * @param data
     */
    setVideoUrlTovideoUrls(data) {
        if (!data) {
            return;
        }
        let storageFile = data.storageFile;


        var param = {
            frameHeight: storageFile.hegiht,
            frameWidth: storageFile.width,
            name: storageFile.name,
            ext: storageFile.suffix,
            path: data.url,
            allTime: storageFile.duration,
            size: storageFile.size
        }
        //上传线索视频
        addNewsClueVideo(param, this.addNewsClueVideoBack.bind(this));

        let videoUpload = this.state.videoUpload;
        videoUpload.push(data);

        let videoFiles = this.state.videoFiles;
        let file = {url: data.thumbnailsURL};
        videoFiles.push(file)
        this.setState({
            videoFiles: videoFiles,
            videoUpload: videoUpload
        })
    }

    /**
     * 新增采访视频，回调
     * @param data
     */
    addNewsClueVideoBack(data) {
        // if (!data) {
        //     return;
        // }
        let videoResourceIds = this.state.videoResourceId;
        videoResourceIds.push(data);
    }


    updateKnockOut(data) {
        if (data) {
            Toast.success("保存成功");

            //回到线索列表页面
            var isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
            var url = '/newsCluePage/newsCluePage/' + isContributeRoleFlag;
            this.state.history.push(url);
        }
    }

    //关闭视频
    closeVideo() {
        this.setState({
            videoSources: {},
            showVideoView: false,
        })
    }

    closePic() {
        this.setState({
            showPicView: false
        })
    }

    setPicIndex(index) {
        this.setState({
            picIndex: index,
        })
    }

    openPicView(index) {
        this.setState({
            showPicView: true,
            picIndex: index
        })
    }

    /**
     * 视频预览
     */
    openVideoView(index, files) {
        this.setState({
            videoSources: files[index].path,
            showVideoView: true
        })
    }

    //线索附件
    upload = (files) => {
        let array =[];
        for (let i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                    uploadAttachmentFile(data, res => {
                        let imgAccept = "." + [...PIC_TYPE,].join(",.");
                        let videoAccept = "." + [...VIDEO_TYPE].join(",.");
                        let attchmentAccept = "." + [...ATTCHMENT_TYPE].join(",.");
                        if(imgAccept.indexOf(res.storageFile.suffix)>-1){
                            let params ={
                                "pictures": [
                                    {
                                        "dataSource": 4,
                                        "name": res.storageFile.name,
                                        "ext": res.storageFile.suffix,
                                        "path": res.url,
                                        "size": res.storageFile.size,
                                        "storyId": 0,
                                        "imageHeight": res.storageFile.height,
                                        "imageWidth": res.storageFile.width
                                    }
                                ]
                            };
                            Actions.saveStoryFilePicture( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                newData.watch=true;
                                array.push(newData);
                                let dataArray =this.state.tsFileIds.concat(newData)
                                this.state.attachResourceId.push(newData.resourceId)
                                this.setState({tsFileIds:dataArray})
                            })
                        }
                        if(videoAccept.indexOf(res.storageFile.suffix)>-1){
                            let params ={
                                "videos": [
                                    {
                                        "dataSource": 4,
                                        "name": res.storageFile.name,
                                        "ext": res.storageFile.suffix,
                                        "path": res.url,
                                        "size": res.storageFile.size,
                                        "storyId": 0,
                                        "frameHeight":res.storageFile.height,
                                        "frameWidth":res.storageFile.width,
                                        "allTime":res.storageFile.duration,
                                    }
                                ]
                            };

                            Actions.saveStoryFileVideo( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                array.push(newData);
                                let dataArray =this.state.tsFileIds.concat(newData)
                                this.state.attachResourceId.push(newData.resourceId)
                                this.setState({tsFileIds:dataArray})

                            })
                        }
                        if(attchmentAccept.indexOf(res.storageFile.suffix)>-1){
                            let params ={
                                "otherFiles": [
                                    {
                                        "dataSource": 4,
                                        "name": res.storageFile.name,
                                        "ext": res.storageFile.suffix,
                                        "path": res.url,
                                        "size": res.storageFile.size,
                                        "storyId": 0
                                    }
                                ]
                            };
                            Actions.saveStoryFileOtherfile( params,(data) => {
                                let newData=data[0];
                                newData.url=res.url;
                                array.push(newData);
                                let dataArray =this.state.tsFileIds.concat(newData)
                                this.state.attachResourceId.push(newData.resourceId)
                                this.setState({tsFileIds:dataArray})
                            })
                        }
                    });
                }
            }
        }
    };

    //附件预览
    openAttachView(index, files) {
        this.setState({
            attachSources: files[index].path,
            showAttachView: true
        })
    }

    //查看图片
    watch(url){
        this.setState({viewPicture:true,img:url})
    }
    //关闭查看图片的弹框
    onClose = key => () => {
        this.setState({viewPicture: false,});
    };
    //下载附件
    download(data){
        window.location.href = data + "?download=1";
    }


    render() {
        let acceptAll;
        let imgAccept = "." + [...PIC_TYPE,].join(",.");
        let videoAccept = "." + [...VIDEO_TYPE].join(",.");
        let attchmentAccept = "." + [...ATTCHMENT_TYPE].join(",.");
        acceptAll="*";

        const {getFieldProps} = this.props.form;
        const  newscluetent_titles = <div><div
            style={{fontSize: '15px'}} className={'clue_tittle'}>线索内容</div><div className={'clue_colon'}>:</div></div>;
        return <div>
            <WhiteSpace size="lg"/>
            <WingBlank size="lg" style={{margin:'5px'}}>
                <div className={'newclue_detail'}>
                            {/*<InputItem*/}
                            {/*    clear*/}
                            {/*    {...getFieldProps('title', {*/}
                            {/*        initialValue: this.props.newClue.title,*/}
                            {/*    })}*/}
                            {/*    placeholder="请输入线索标题"*/}
                            {/*    moneyKeyboardAlign="left"*/}
                            {/*    moneyKeyboardWrapProps={moneyKeyboardWrapProps}*/}
                            {/*    type={'text'}*/}
                            {/*    editable={this.props.canEdite}*/}
                            {/*>*/}
                            {/*    <div style={{fontSize: '15px'}} className={'clue_tittle'}>线索标题</div>*/}
                            {/*    <div className={'clue_colon'}>:</div>*/}
                            {/*</InputItem>*/}
                            <TextareaItem style={{fontSize: '15px'}}
                                          clear
                                          {...getFieldProps('title', {
                                              initialValue: this.props.newClue.title,
                                          })}
                                          autoHeight
                                          placeholder="请输入线索标题"
                                          title={<div><div style={{fontSize: '15px'}} className={'clue_tittle'}>线索标题</div>
                                              <div className={'clue_colon'}>:</div></div>}
                                          editable={this.props.canEdite}
                            />

                            <InputItem
                                clear
                                {...getFieldProps('contacts', {
                                    initialValue: this.props.newClue.contacts,
                                })}
                                placeholder="请输入联系人"
                                moneyKeyboardAlign="left"
                                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                type={'text'}
                                editable={this.props.canEdite}
                            >
                                <div style={{fontSize: '15px'}} className={'clue_tittle'}>联系人</div>
                                <div className={'clue_colon'}>:</div>
                            </InputItem>

                            <InputItem
                                {...getFieldProps('contactsWay', {
                                    initialValue: this.props.newClue.contactsWay,
                                })}
                                type={'phone'}
                                clear
                                placeholder="请输入联系电话"
                                moneyKeyboardAlign="left"
                                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                editable={this.props.canEdite}
                            >
                                <div style={{fontSize: '15px'}} className={'clue_tittle'}>联系电话</div>
                                <div className={'clue_colon'}>:</div>
                            </InputItem>
                    <TextareaItem style={{fontSize: '15px'}}
                                  {...getFieldProps('content', {
                                      initialValue: this.props.newClue.content,
                                  })}
                                  rows={5}
                                  count={1000}
                                  placeholder="请输入线索内容"
                                  title={newscluetent_titles}
                                  editable={this.props.canEdite}
                    />

                    <WhiteSpace size="lg"/>
                    <Card>
                        <div style={{fontSize: '15px'}} className={'newclue_item_title'}>图片</div>
                        <ImagePicker
                            onAddImageClick={this.addImage}
                            files={this.state.picFiles}
                            onChange={this.onChangePic}
                            selectable={this.props.canEdite}
                            onImageClick={this.openPicView.bind(this)}
                            multiple={this.state.multiple}
                            disableDelete={this.state.canEdite}
                        />
                    </Card>

                    <WhiteSpace size="lg"/>
                    <Card>
                        <div style={{fontSize: '15px'}} className={'newclue_item_title'}>视频</div>
                        <div className={"video_pincker"}>
                            <ImagePicker
                                accept={"video/*"}
                                onAddImageClick={this.addImage}
                                files={this.state.videoFiles}
                                onImageClick={this.openVideoView.bind(this)}
                                onChange={this.onChangeVideo}
                                selectable={this.props.canEdite}
                                multiple={this.state.multiple}
                                disableDelete={this.state.canEdite}
                            />
                        </div>
                    </Card>
                    <Modal
                        popup
                        visible={this.state.showVideoView}
                        animationType="slide-up"
                        afterClose={() => {
                        }}
                    >
                        <div style={{height: '100vh'}}>
                            <div onClick={() => {
                                this.closeVideo()
                            }} style={{backgroundColor: "#000000", color: '#ffffff'}}>关闭
                            </div>
                            <div style={{height: '94vh'}}>
                                <Player fluid={false} width={'100%'} height={'100%'}>
                                    <source src={this.state.videoSources}/>
                                </Player>
                            </div>
                        </div>
                    </Modal>

                    <PhotoSlider
                        images={this.state.picFiles.map(item => ({src: item.url}))}
                        visible={this.state.showPicView}
                        onClose={() => (this.closePic())}
                        index={this.state.picIndex}
                        onIndexChange={this.setPicIndex.bind(this)}
                    />
                    {/*上传附件结束*/}
                    <WhiteSpace size="lg"/>
                    <Card>
                        <div>
                            <div className={'addNews_clue_title'} style={{display:'inline-block',fontSize: '15px'}}>线索附件</div>
                            <div style={{display:'inline-block',color:'#1890fe',marginLeft:'40px'}}>
                                <div style={{display:'inline-block',width:'100vw',height:'8vw', position: 'absolute',top:0,left:0,overflow: 'hidden',opacity:0,marginLeft:130}}>
                                    <ImagePicker
                                        accept={"*/*"}
                                        onAddImageClick={this.addImage}
                                        onImageClick={this.openAttachView.bind(this)}
                                        files={this.state.tsFiles}
                                        onChange={this.upload}
                                        selectable={this.props.canEdite}
                                        multiple={this.state.multiple}
                                        disableDelete={this.state.canEdite}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{
                            background:'white',
                            padding:'0vw 4vw 25vw 4vw',
                            lineHeight:'6vw',color:'#999999',
                            fontSize:'3vw'
                        }}>
                            {
                                this.state.tsFiles.length>0&&this.state.tsFiles.map((item,index)=>{
                                    return(
                                        <div style={{display:'flex',lineHeight:'12vw'}} key={index}>
                                            <div style={{
                                                    width:'70%',
                                                    whiteSpace:'nowrap',
                                                    overflow:'hidden',
                                                }}>{item.name}</div>
                                            {
                                                item.watch? <div style={{width:'10%',color:'#1890fe',textAlign:'center'}} onClick={() => this.watch(item.url)}>查看</div>:<div style={{width:'10%',color:'#1890fe'}}></div>
                                            }
                                            <div style={{width:'15%',color:'#1890fe',textAlign:'center'}}  onClick={() => this.download(item.url)}>下载</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*附件查看图片弹框*/}
                        <Modal
                            visible={this.state.viewPicture}
                            transparent
                            style={{width:'87vw'}}
                            maskClosable={false}
                            onClose={this.onClose('modal')}
                            footer={[{ text: '确定', onPress: () => { this.onClose('modal')(); } }]}
                            wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                        >
                            <div>
                                <img src={this.state.img} style={{width:'80vw',height:'80vw',objectFit:'contain'}}/>
                            </div>
                        </Modal>
                    </Card>
                    {/*上传附件结束*/}
                </div>
            </WingBlank>
        </div>
    }
}

const NewClueDetailCardForm = createForm()(NewClueDetailCard);
export default NewClueDetailCardForm
