import React, {Component} from 'react'
import {getInfoFromLocalStorage, loadColumnByMediaCode, loadCurrentMedia} from "../../action";
import {setColumnOfMediaCodel, setNoRefresh} from "../../store/actions";
import {connect} from "react-redux";
import {Toast} from "antd-mobile";
import ActButton from "../../components/activity/ActButton";
import config from "../../config";
import {uuid} from "../../utils";
import SelectTree from "../../components/SelectTree/SelectTree";
import store from "../../store";

export const baseUrl = config['baseUrl'];
var validateBindAdminUrl = baseUrl + "/cm-sites-app-h5/website/validateBindAdmin";  //

/**
 * 栏目选择组件
 */
class ColumnSelectPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            pageName: '栏目选择',
            history: require("history").createHashHistory,
            currentMediaCode: getInfoFromLocalStorage("currentMediaCode"),
            checkedtreeContentId: [],
            storyId:props.match.params.storyId
        }
    }


    componentDidMount() {
        let chooseColumn = store.getState().storyReducer.story.chooseColumn;
        this.setState({
            checkedtreeContentId: chooseColumn,
        })
        let param = new FormData();
        param.append('type', 1);
        loadCurrentMedia(param, this.loadCurrentMediaBack.bind(this))
    }

    componentWillUnmount() {
        let chooseColumn = store.getState().storyReducer.story.chooseColumn;
        this.setState({
            checkedtreeContentId: chooseColumn,
        })
    }

    //获取媒体信息的回调；
    loadCurrentMediaBack(data) {
        if (data) {
            let userMediaCodes = [];
            for (var i = 0; i < data.length; i++) {
                if(data[i]&&data[i].code){
                    userMediaCodes.push(data[i].code);
                }
            }
            let currentMediaCode = getInfoFromLocalStorage("currentMediaCode")?getInfoFromLocalStorage("currentMediaCode"):"";
            let param = new FormData();
            param.append("mediaCodes[]", userMediaCodes);
            param.append("storyId", this.state.storyId ? this.state.storyId : 0);
            param.append("currentMediaCode", currentMediaCode);
            param.append("treeContentId", 0);
            loadCurrentMedia(param, this.loadColumnByMediaCodeBack.bind(this))
        }
    }

    loadColumnByMediaCodeBack(data) {
        if (data) {
            //数据添加一个唯一主键。
            var treeData = this.adduuIdToData(data);

            console.info("checkedData", this.checkedData);
            this.setState({
                treeData: treeData
            })
        }
    }

    adduuIdToData(data) {
        let treeData = null;
        for (var i = 0; i < data.length; i++) {
            treeData = data[i];

            //可能返回的是空的，所以需要数组中删除掉。
            if(!treeData){
                data.splice(i,1)
                continue;
            }
            treeData.id = uuid();
            data[i].mediaCode = treeData.mediaCode;
            treeData = this.childTree(data[i]);
        }
        return data;
    }

    childTree(subTree) {
        let mediaCode = subTree.mediaCode;
        if (!subTree.subtree || subTree.subtree.length == 0) {
            return subTree;
        } else {
            let data = subTree.subtree;
            let treeData = null;
            for (var i = 0; i < data.length; i++) {
                treeData = data[i];
                treeData.id = uuid();
                treeData.mediaCode = mediaCode;
                this.childTree(treeData);
            }
        }
    }


    //返回按钮
    goBack() {
        this.props.setNoRefresh(true);
        let path = "/storyAtrribute/storyAtrribute";
        this.state.history().goBack(path);
    }


    /**
     * 保存 勾选的栏目到story对象中
     * @param column
     */
    getColumnData(column) {
        if (column) {
            this.props.setColumnOfMediaCodel(column);
        }
        let path = "/storyAtrribute/storyAtrribute";
        this.state.history().goBack(path);
    }

    render() {
        console.info("data+++++++++++++++++++++",this.state.treeData);
        return <div>
            <div>
                <SelectTree
                    showMainColumn={true}
                    title={'选择栏目'}
                    dataSource={this.state.treeData}
                    defaultData={this.state.checkedtreeContentId}
                    subtree={'subtree'}
                    treeName={'treeName'}
                    treeId={'treeContentId'}
                    clickOk={(selectData) => {
                        this.getColumnData(selectData);
                    }}
                    clickCancle={() => {
                        this.getColumnData(null);
                    }}
                /></div>
        </div>;
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setColumnOfMediaCodel: function (selectedTab) {
            dispatch(setColumnOfMediaCodel(selectedTab));
        },
        setNoRefresh: function (noRefresh) {
            dispatch(setNoRefresh(noRefresh));
        }
    }
}

export default connect(null, dispatchToProps)(ColumnSelectPage);