import axios from 'axios';
import {Toast} from 'antd-mobile';
import config from "../config";
import $ from 'jquery'

const headers = {
    "Pragma": 'no-cache',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/x-www-form-urlencoded'
};

const history = require("history").createHashHistory;

const errorMessage = '后台服务请求异常';
export const baseUrl = config.baseUrl;


/**
 * get请求
 * @param url
 * @param data
 */
export const get = (url, data, callBack) => {
    axios.get(url, {
        params: data, headers: headers, credentials: 'include'//浏览器发送cookie,解决的问题就是登录之后被踢了
    })
        .then(responseHandle)
        .then(function (response) {
            callBack(response.data);
        })
        .catch(function (error) {
            Toast.info(error.message, 1);
            // console.log(error);
        });
}

/**
 * post请求
 * @param url
 * @param data
 */
export const post = (url, data, callBack) => {
    axios.post(url, data, {headers: headers})
        .then(responseHandle)
        .then(function (response) {
            callBack(response.data);
        })
        .catch(function (error) {
            Toast.info(error.message, 1);
        });
}


export const getMyTabdata = (data, callBack) => {
    get("data/myTabs.Data.json", data, callBack);
}
/**
 * 获取栏目列表数据
 * @param data
 * @param callBack
 */
export const getColumnData = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/website/getStoryByColumn", param, callBack);
}

/**
 * 获取管理端的我的全部稿件的列表，全部，草稿，发布，
 * @param data
 * @param callBack
 */
export const getAllData = (data, callBack) => {

    post(baseUrl + "/cm-sites-app-h5/story/getMyStory", data, callBack);
}


/**
 * 获取投稿端的我的全部稿件的列表
 * @param data
 * @param callBack
 */
export const getContributeAllData = (data, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/story/all", data, callBack);
}


/**
 * 获取投稿端的 草稿，发布列表，
 * @param data
 * @param callBack
 */
export const getContributeData = (data, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/story/list", data, callBack);
}


export const localLogin = (userName, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/login", userName, callBack);
}


/**获取编辑列表信息
 *
 * @type {Function}
 */
export const getEditorList = ((param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/user/getBianJiList", param, callBack);
});


/**获取部门列表信息
 *
 * @type {Function}
 */
export const getUserDeptList = ((param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/user/getuserDeptList", param, callBack);
});


/**获取上传图片
 *
 * @type {Function}
 */
export const uploadimage = ((param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/ueditor/control?action=uploadimage", param, callBack);
});


/**上传视频
 *
 * @type {Function}
 */
export const uploadvideo = ((param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/ueditor/control?action=uploadvideo", param, callBack);
});

/**上传附件
 *
 * @type {Function}
 */
export const uploadAttachmentFile = ((param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/ueditor/control?action=uploadfile", param, callBack);
});

/**上传音频
 *
 * @type {Function}
 */
export const uploadAudio = ((param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/ueditor/control?action=uploadAudio", param, callBack);
});


export const getUserInfoByAccount = ((param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/user/getUserInfoByAccount", param, callBack);
});

/**
 * 获取当前用户的所有权限；
 * @type {Function}
 */
export const getUserAllAuth = ((param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/user/getAllAuth", param, callBack);
});
/**
 * 保存信息到localStorAge
 */
export const setInfoToLocalStorage = (attr, value) => {
    console.log('setInfoToLocalStorage', attr, value);
    sessionStorage.setItem(attr, JSON.stringify(value));
}


/**
 * 移除信息fromlocalStorAge
 */
export const removeInfoFromLocalStorage = (attr) => {
    sessionStorage.removeItem(attr);
}

export const getAllEmotionsList = (formData, callBack)=> {
    post(baseUrl + "/cm-sites-app-h5/weibo/getEmotions",formData,callBack);
}

/**
 * 获取信息fromlocalStorAge
 */
export const getInfoFromLocalStorage = (attr) => {
    return JSON.parse(sessionStorage.getItem(attr));
}


/**
 * 清空 localStorAge
 */
export const clearLocalStorage = () => {
    sessionStorage.clear();
}


/**
 * 获取用户的媒体code
 * @param param
 * @param callBack
 */
export const getMediaByAccount = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/media/getMediaByAccount", param, callBack);
}

/**
 * 页面的登录功能
 * @param param
 * @param callBack
 */
export const checkLogin = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/login", param, callBack);
}

/**
 * 页面的退出功能
 * @param param
 * @param callBack
 */
export const logout = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/logout", param, callBack);
}

/**
 * 获取验证码
 * @param param
 * @param callBack
 */
export const getVerificationCode = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/login/code?randnum=" + new Date().getTime(), param, callBack);
}


/**
 * 获取消息分类列表
 * @param param
 * @param callBack
 */
export const getMessageType = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/notice/getNoticeGroup", param, callBack);
}

export const jsonPost = (url, data, callBack) => {
    axios.post(url, data)
        .then(function (response) {
            callBack(response.data);
        })
        .catch(function (error) {
            Toast.info(errorMessage, 1);
            console.log(error);
        });
}
/**
 * 获取消息类型的列表数据
 * @param param
 * @param callBack
 */
export const getMessageListByType = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/notice/getNoticeListByType", param, callBack);
}


/**
 * 保存用户私信设置
 * @param param
 * @param callBack
 */
export const saveAppUserNoticeSetting = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/notice/saveAppUserNoticeSetting", param, callBack);
}

/**
 * 获取用户私信设置
 * @param param
 * @param callBack
 */
export const getUserNoticeSetting = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/notice/getUserNoticeSetting", param, callBack);
}


/**
 * 更具消息id,获取消息详情；
 * @param param
 * @param callBack
 */
export const getMessageDetail = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/notice/getNoticebyIdAndType", param, callBack);
}

/**
 * 更具消息id,通知公告信息；
 * @param param
 * @param callBack
 */
export const getNotificationById = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/notice/getNotificationById", param, callBack);
}


/**
 * 设置消息读取状态
 * @param param
 * @param callBack
 */
export const setReadMessageStatus = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/notice/setReadMessageStatus", param, callBack);
}


/**
 * 获取未读邮件的数量
 * @param param
 * @param callBack
 */
export const getUnReadMsgCount = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/notice/getUnReadMsgCount", param, callBack);
}


/**
 * 根据 采访任务id 获取采访任务信息
 * @param param
 * @param callBack
 */
export const getInterviewInfoById = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/interview/getById", param, callBack)
}

/**
 * 保存 新闻线索的数据
 * @param param
 * @param callBack
 */
export const saveKnockOutInfo = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/knockoutCon/addKnockOut", param, callBack)
}

/**
 * 获取新闻新闻线索的数据
 * @param param
 * @param callBack
 */
export const getKnockOut = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/knockoutCon/getKnockOut", param, callBack)
}

const responseHandle = (respons) => {
    if (respons.data && respons.data == 'sys.002') {
        window.location = "index.html#/login/login";
        throw new Error("长时间未操作，身份已过期，请重新登录！");
    } else {
        return respons;
    }
}

/**
 * 获取记者列表
 * @param param
 * @param callBack
 */
export const getReporterList = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/user/reporterList", param, callBack)
}

/**
 * 获取用户的部门信息
 * @param param
 * @param callBack
 */
export const getDepInfoByDepId = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/user/getDeptListByIds", param, callBack)
}

/**
 *
 * @param param
 * @param callBack
 */
export const getRoleInfoByRoleId = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/user/getRoleListByCodes", param, callBack)
}

/**
 * 数据提交
 * @param param
 * @param callback
 */
export const addInterview = (param, callback) => {
    jpost(baseUrl + "/cm-sites-app-h5/interview/publish", param, callback);
}

/**
 *获取采访任务的编辑权限。
 * @param param
 * @param callback
 */
export const interviewHaveOperation = (param, callback) => {
    get(baseUrl + "/cm-sites-app-h5/interview/isHaveOperationAuth", param, callback);
}

/**
 * 获取采访任务的资源数据；
 * @param param
 * @param callBack
 */
export const getInterviewResource = (param, callback) => {
    get(baseUrl + "/cm-sites-app-h5/interview/getResouce", param, callback);
}

/**
 * 获取采访任务的，管理者信息
 * @param param
 * @param callback
 */
export const isInterviewManager = (param, callback) => {
    get(baseUrl + "/cm-sites-app-h5/interview/getAllManager", param, callback);
}

/**
 * 领取采访任务
 * @param param
 * @param callBack
 */
export const takeInterviewTask = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/interview/takeTask", param, callBack);
}

/**
 * 保存图片信息
 * @param param
 * @param callBack
 */
export const saveInterviewPicture = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/interview/saveInterviewPicture", param, callBack);
}

/**
 * 保存采访任务视频信息
 * @param param
 * @param callBack
 */
export const saveInterviewVideo = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/interview/saveInterviewVideo", param, callBack);
}

/**
 * 保存采访任务音频附件
 * @param param
 * @param callBack
 */
export const saveInterviewDetailsAudio = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/interview/saveInterviewDetailsAudio", param, callBack);
}

/**
 * 保存采访任务视频附件
 * @param param
 * @param callBack
 */
export const saveInterviewDetailsVideo = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/interview/saveInterviewDetailsVideo", param, callBack);
}

/**
 * 采访视频删除。
 * @param param
 * @param callBack
 */
export const delResource = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/interview/delResource", param, callBack);
}

/**
 * 新增线索
 * @param param
 * @param callBack
 */
export const addNewsClue = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/newsclue/add", param, callBack);
}

/**
 * 获取线索信息
 * @param param
 * @param callBack
 */
export const getNewClueInfoById = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/newsclue/getById", param, callBack);
}

/**
 * 获取线索信息
 * @param param
 * @param callBack
 */
export const getNewCluePicFilesById = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/newsclue/getPicture", param, callBack);
}

/**
 * 获取线索的
 * @param param
 * @param callBack
 */
export const getNewClueVideoFilesById = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/newsclue/getVideo", param, callBack);
}

/**
 * 获取线索的附件
 * @param param
 * @param callBack
 */
export const getNewClueAttachmentFile = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/newsclue/getAttachmentFile", param, callBack);
}

/**
 * 撤回线索的
 * @param param
 * @param callBack
 */
export const retractNewClue = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/newsclue/retract", param, callBack);
}


/**
 * 新增线索图片
 * @param param
 * @param callBack
 */
export const addNewsCluePic = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/newsclue/addNewPic", param, callBack);
}

/**
 * 更新线索信息
 * @param param
 * @param callBack
 */
export const updateNewsClue = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/newsclue/updateNewsclue", param, callBack);
}

/**
 * 采用线索信息
 * @param param
 * @param callBack
 */
export const useNewsClueAct = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/newsclue/useclue", param, callBack);
}

/**
 * 弃用线索
 * @param param
 * @param callBack
 */
export const unuseNewsClueAct = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/newsclue/unuseclue", param, callBack);
}

/**
 * 获取媒体信息
 * @param param
 * @param callBack
 */
export const loadCurrentMedia = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/website/filterCheckedColumn", param, callBack);
}

/**
 * 获取媒体 栏目树
 * @param param
 * @param callBack
 */
export const loadColumnByMediaCode = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/website/loadColumnByMediaCode", param, callBack);
}
/**
 * 新增线索视频
 * @param param
 * @param callBack
 */
export const addNewsClueVideo = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/newsclue/addNewVideo", param, callBack);
}

/**
 * 通讯员列表
 * @param param
 * @param callBack
 */
export const getAllTxy = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/appointment/getAllTxy", param, callBack);
}

/**
 * 获取媒体信息
 * @param param
 * @param callBack
 */
export const loadWebSiteMedia = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/appointment/loadWebSiteMedia", param, callBack);
}

/**
 * 新增约稿任务；
 * @param param
 * @param callBack
 */
export const addAppointment = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/appointment/addAppointment", param, callBack);
}

/**
 * 获取约稿详情
 * @param param
 * @param callBack
 */
export const getAppointmentInfo = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/appointment/getAppointmentById", param, callBack);
}

/**
 * 获取约稿的接受信息；
 * @param param
 * @param callBack
 */
export const getAppointmentReceiverTableInfoById = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/appointment/getAppointmentReceiverTableInfoById", param, callBack);
}

/**
 * 获取约稿详情 pms端的
 * @param param
 * @param callBack
 */
export const getAppointmentInfoPms = (param, callBack) => {
    get(baseUrl + "/cm-sites-app-h5/appointment/getAppointmentPmsById", param, callBack);
}

/**
 * 获取约稿的状态；
 */
export const getAppointmentReceiverByReceiverId = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/appointment/getAppointmentReceiverByReceiverId", param, callBack);
}

/**
 * 撤回约稿
 */
export const backAppointment = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/appointment/backAppointment", param, callBack);
}


/**
 * 编辑发布约稿
 */
export const editAppointment = (param, callBack) => {
    jpost(baseUrl + "/cm-sites-app-h5/appointment/editAppointment", param, callBack);
}


/**
 * 领取约稿任务
 * @param param
 * @param callBack
 */
export const recivewAppointMentTask = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/appointment/receiveAppointment", param, callBack);
}

/**
 * 拒绝约稿任务
 * @param param
 * @param callBack
 */
export const refuseAppointMentTask = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/appointment/refuseAppointmentReceiver", param, callBack);
}


/**
 * 撤稿
 * @param param
 * @param callBack
 */
export const cancelDraft = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/story/undo", param, callBack);
}


/**
 * 稿件发布
 * @param param
 * @param callBack
 */
export const publishDraft = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/story/publish", param, callBack);
}

/**
 * 稿件发布(撤稿状态下)
 * @param param
 * @param callBack
 */
export const publishDraftNews = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/storyNew/publish", param, callBack);
}

/**
 * 获取投稿稿件
 * @param param
 * @param callBack
 */
export const getResourcePool = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/originalStory/getResourcePool", param, callBack);
}

/**
 * 获取原创资源
 * @param param
 * @param callBack
 */
export const getOrigionalResource = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/originalStory/originalList", param, callBack);
}


/**
 * 获取用户管理的部门信息
 * @param param
 * @param callBack
 */
export const getAccountDepts = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/dept/getCtrl", param, callBack);
}

/**
 * 获取，宣传部的记者
 * @param param
 * @param callBack
 */
export const getxcbDepts = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/dept/xcbAllDept", param, callBack);
}

/**
 * 获取栏目的扩展字段的信息
 * @param param
 * @param callBack
 */
export const getExtendDatasByColumn = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/story/getExtendField", param, callBack);
}


/**
 * 稿件访问
 * @param param
 * @param callBack
 */
export const visitorDraft = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/story/getWebsiteStoryPublishUrl", param, callBack);
}

/**
 * 撤回采访任务
 * @param param
 * @param callBack
 */
export const cancelInterViewRequest = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/interview/backInterview", param, callBack);
}


/**
 * json  post的数据提交
 * @param url
 * @param data
 * @param callBack
 */
export const jpost = (url, params, callBack) => {
    $.post(url, params, function (respons) {
        if (respons && respons == 'sys.002') {

            //如果是投稿端，跳转到统一身份认证页面。
            if (getInfoFromLocalStorage("isContributeRoleFlag")) {
                window.location = "https://cas.hrbeu.edu.cn/cas/login";
            } else {
                //如果是管理端，跳到我们的系统的登录页面，
                window.location = "index.html#/login/login";
            }
            throw new Error("长时间未操作，身份已过期，请重新登录！");
        } else {
            callBack(respons);
        }

    })
}


/**
 * 保存微博
 * @param param
 * @param callBack
 */
export const saveWeibo = (param, callBack) => {
    let picUrlsStr=param.picUrlsStr;
    let tagsStr=param.tagsStr;
    jsonPost(baseUrl + "/cm-sites-app-h5/weibo/save?picUrlsStr=" + picUrlsStr + "&tagsStr=" + tagsStr, param, callBack);
}

/**
 * 获取微博信息
 * @param param
 * @param callBack
 */
export const getWeiBoById = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/weibo/show", param, callBack);
}

/**
 * 删除微博
 * @param param
 * @param callBack
 */
export const deleteWeibo = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/weibo/deleteWeibo", param, callBack);
}

/**
 * 保存微博状态
 * @param param
 * @param callBack
 */
export const saveStatusName = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/weibo/deleteWeibo", param, callBack);
}

/**
 * 撤回微博
 * @param param
 * @param callBack
 */
export const revokeWeibo = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/weibo/getDeleteInfo", param, callBack);
}

/**
 * 撤回微博
 * @param param
 * @param callBack
 */
export const publishWeibo = (param, callBack) => {
    post(baseUrl + "/cm-sites-app-h5/weibo/publish", param, callBack);
}


/**获取当前websocket通道名称*/
export const wsName = (successCallback, eorrorCallBack) => fetch(baseUrl + "/cm-sites-app-h5/index/wsMobile", {
    cache: 'no-cache',
    headers: headers,
    credentials: 'include'//浏览器发送cookie,解决的问题就是登录之后被踢了
})
    .then(response => response.json())
    .then(data => {
        successCallback(data);
    }).catch((error) => {
        eorrorCallBack(error);
    });

export const beginAuthURL = () => fetch("/cm-sites-app-h5/index/beginAuthURL", {
    cache: 'no-cache',
    headers: headers,
    credentials: 'include'//浏览器发送cookie,解决的问题就是登录之后被踢了
});

/**ws监听方法*/
export const websocketListen = (url,successCallback) => fetch(url, {
    cache: 'no-cache',
    headers: headers,
    credentials: 'include'//浏览器发送cookie,解决的问题就是登录之后被踢了
}).then(response => {
    if ((response.url!== "" && response.url.indexOf(url) === -1) || response.status === 503)
    {
        console.log("response==websocketListen=======", response);
        window.location = config.loginUrl;
    } else {
        return response;
    }
})
    .then(response => response.text())
    .then(res =>{
        try {
            let data = JSON.parse(res);
            setTimeout(()=>successCallback(data), 0);
            websocketListen(url,successCallback);
        }catch (error) {
            console.log("error=======websocketListen====", error);
            setTimeout(()=>successCallback(res), 0);
            websocketListen(url,successCallback);
        }
    }).catch((error) =>{
        console.log("error==catch=====websocketListen====", error);
    });

/**校验是否登录*/
export const isLogin = ()=> new Promise((resolve, reject) => {
    window.$.ajax({
        cache: true,
        type: "post",
        url: "/cm-sites-"+process.env.REACT_APP_NAME+"/islogin",
        dataType:"json",
        success:function (result) {
            resolve(result===true);
        },
        error:function (e) {
            console.error(e);
            reject(false);
        }
    });
});







