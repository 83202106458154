import React, {Component} from "react";
import ActButton from "../../../components/activity/ActButton";
import {ListView, Toast} from "antd-mobile";
import {Link} from "react-router-dom";
import {formatDate, isStringEmpty} from "../../../utils";
import {NoDataView} from "../../../components/NoDataView";
import {getInfoFromLocalStorage, post} from "../../../action";

/**
 * 采访任务列表
 */
class InterviewListView extends Component {
    pageIndex = 1;
    rData=[];
    actButton = new ActButton();

    constructor(props) {
        super(props);
        this.initListData();
    }

    initListData = () => {
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            dataStatus: '加载中...',
            statusCode2Name: {1: "未领取", 2: "进行中", 3: "已完成", 4: "已撤回"},

        };
    }


    updateInterviewData(data) {
        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }

        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            pageSize: data.data.length,
            dataStatus: data.data.length > 0 ? '加载完成' : '暂无数据'
        })
        ++this.pageIndex;
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    componentWillMount() {
        this.getPageListData();
    }

    componentWillUnmount() {
    }

    getPageListData() {
        Toast.loading('加载中...', 0, () => {
        });
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        post(this.props.dataUrl, data, this.updateInterviewData.bind(this));
    }

    onEndReached = (event) => {
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }

        Toast.loading('加载中...', 0, () => {
        });
        this.setState({isLoading: true});

        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        post(this.props.dataUrl, data, this.updateInterviewData.bind(this));
    }

    //如果是  撤回状态的稿件，可以跳转到  编辑页面；
    judgeToEditor(interviewInfo){
        let url=null;
        if(interviewInfo.status===4&&interviewInfo.creater === getInfoFromLocalStorage("userName")){
            url='/interviewPage/interviewPage/' + interviewInfo.id
        }else{
             url='/InterviewDetailPage/InterviewDetailPage/' + interviewInfo.id
        }
        return url;
    }


    /**
     * 转换采访任务的状态；
     * @param obj
     */
    formateStatus(obj){
        if(!obj.endDate||!obj.endDate.time){
            return "";
        }
        var time = new Date(obj.endDate.time);
        var now = new Date();

        //已过期，需要通过 时间来比较
        let title=this.props.currentTab?this.props.currentTab.title:'';
        if(title=='已超期'){

            if(now>time){
                return "已超期"
            }
        }
       let statusName= this.state.statusCode2Name[obj.status];
       return statusName;
    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            const obj = rowData;
            return obj ? (
                <div>
                    <Link to={ this.judgeToEditor(obj)  }>
                        <div key={rowID} style={{padding: '15px'}}>
                            <div
                                style={{
                                    fontSize: 14,
                                    color: 'black',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.title}}></div>
                            <div  style={{
                                lineHeight: '30px',
                                color: '#888',
                                fontSize: 12,
                                wordBreak: 'break-all'
                            }}>回稿时间：{formatDate(obj.endDate ? obj.endDate.time : null)}</div>
                            <div
                                style={{
                                    lineHeight: '30px',
                                    color: '#888',
                                    fontSize: 12,
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.content) ? '' : obj.content}}></div>
                            <div>
                                <div className={'cardBootm'}>
                                    <div style={{
                                        textAlign: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                        paddingBottom: '10px'
                                    }}>{formatDate(obj.createDate ? obj.createDate.time : null)}</div>
                                    <div
                                        key={`${sectionID}-${rowID}`}
                                        style={{
                                            backgroundColor: '#F5F5F9',
                                            borderBottom: '1px solid #ECECED',
                                        }}
                                    />
                                    <div style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>
                                        <span>{obj.storWritter}</span><span style={{
                                        float: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>状态: {this.formateStatus(obj)}</span></div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>) : <div></div>;
        };

        return this.state.count > 0 ? (
            <ListView
                initialListSize={this.state.count}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                    {this.state.isLoading ? '加载中...' : '加载完成'}
                </div>)}
                renderRow={row}
                style={{
                    height: this.state.height,
                    overflow: 'auto',
                }}
                pageSize={this.state.pageSize}
                onScroll={() => {
                    console.log('scroll');
                }}
                scrollRenderAheadDistance={500}
                onEndReached={this.onEndReached}
                onEndReachedThreshold={10}
            />
        ) : <NoDataView content={this.state.dataStatus}/>;
    }
}

export default InterviewListView;
