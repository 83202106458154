/**
 * created by yxy
 *
 */
 export const Enumeration = {
    notReceived: {
        statusType: 1,
        statusName: '未领取',
        bgColor: '#ffb558'
    },
    inProgress: {
        statusType: 2,
        statusName: '进行中',
        bgColor: '#5a63e6'
    },
    Submit: {
        statusType: 3,
        statusName: '已提交',
        bgColor: '#35ce84'
    }, complete: {
        statusType: 4,
        statusName: '已完成',
        bgColor: '#999999'
    }, overdue: {
        statusType: 5,
        statusName: '已超期',
        bgColor: 'rgba(255, 59, 49, 1)'
    }
};

export const TOPICSTATUS = {
    draft: {
        statusType: '1',
        statusName: '草稿',
        bgColor: 'rgba(255, 126, 75, 1)'
    },
    submit: {
        statusType:'2',
        statusName: '已提交',
        bgColor: 'rgba(133, 198, 81, 1)'
    },
    withdraw: {
        statusType: '3',
        statusName: '已撤回',
        bgColor: 'rgba(138, 143, 172, 1)'
    },
    retention: {
        statusType:'4',
        statusName: '留用',
        bgColor: 'rgba(216, 33, 9, 1)'
    },
    disuse: {
        statusType: '5',
        statusName: '已弃用',
        bgColor: 'rgba(255, 59, 49, 1)'
    },
    use: {
        statusType: '6',
        statusName: '已采用',
        bgColor: 'rgba(18, 150, 219, 1)'
    },
    task: {
        statusType: '7',
        statusName: '转为任务',
        bgColor: 'rgba(212, 36, 123, 1)'
    },
    collaboration: {
        statusType: '8',
        statusName: '已指派',
        bgColor: 'rgba(28, 235, 205, 1)'
    }


};