import store from "../../store";
import {Toast} from "antd-mobile";
import {checkContainsEmoji, permissionCheck} from "../../utils";
import {getInfoFromLocalStorage} from "../../action";

const MAX_WIDTH = '65vw';

/**
 * 保存 story
 */
export const saveStory = (enCode=true) => {


    let storyOld = store.getState().storyReducer.story;

    let story = JSON.parse(JSON.stringify(storyOld));
    let resources = store.getState().storyReducer.resources;
    let currentMediaCode = store.getState().storyReducer.currentMediaCode;
    let tempStoryId = store.getState().storyReducer.tempStoryId;

    // let currentButton=store.getState().storage
    for (let type in resources) {
        resources[type].forEach((res, num, arr) => {
            for (let attribute in res) {
                story[type + '_' + num + '_' + attribute] = res[attribute];
            }
        });
        story[type + 'Size'] = resources[type].length;
    }
    //摘要提取
    // story.summary = getSummaryNoAlert(story.content, 200);
    story.mediaCode = currentMediaCode;
    story.tempStoryId = tempStoryId ? tempStoryId : 0;

    //story.content = transformMedia(story.content);
    //如果是管理端 需要加  加入栏目

    // if (!getInfoFromLocalStorage('isContributeRoleFlag')) {
    let chooseColumn = story.chooseColumn;
    if (chooseColumn) {
        if (typeof (chooseColumn) == "string") {
            chooseColumn = JSON.parse(chooseColumn);
        }
    }

    if (chooseColumn && chooseColumn.length > 0) {
        chooseColumn[0].mainColumn = 1;
        chooseColumn[0].deliveryWay = 0;
        //如果没有值，就以主栏目的mediaCode作为媒体code,
        story.mediaCode = chooseColumn[0].mediaCode;
    } else {
        delete story.chooseColumn;
    }

    if (chooseColumn&&chooseColumn.length>0) {
        story.chooseColumn = JSON.stringify(chooseColumn);
        story.storySyncColumns = JSON.stringify(chooseColumn);
    } else {
        delete story.chooseColumn;
        delete story.storySyncColumns
    }
    // }

    //把扩展字段的值，放到story中去
    story = Object.assign(story, story.wbextends);

    if (story.news) {
        delete story.news;
    }

    let wbextends = story.wbextends;
    if (wbextends && Object.getOwnPropertyNames(story.wbextends).length > 0) {
        if (typeof (wbextends) == "string") {
            wbextends = JSON.parse(wbextends);
        }
        story.wbextends = JSON.stringify(wbextends);
    } else {
        delete story.wbextends;
    }

    delete story.labelIds;
    delete story.labelNames;
    delete story.collectionAccounts;
    delete story.prevPublishStoryColumn;
    delete story.publishedStoryColumn;
    if (story.storyHistoryId === 0) {
        delete story.storyHistoryId;
    }

    if (!story.publishUrl) {
        delete story.publishUrl;
    }

    delete story.submitDate;
    delete story.changeDate;
    delete story.publishDate;

    if (story.deptId == 0) {
        delete story.deptId;
    }

    if(enCode){
        story.content = encodeBase64(story.content);
    }

    //链接稿件，是没有稿件内容的，
    if (story.linkurl) {
        story.content = "";

    }
    return story;
};

/**
 * 检查 story
 */
export const checkStory = (buttonName) => {

    //校验媒体栏目；
    let check = true;
    let story = store.getState().storyReducer.story;
    if (!story) {
        check = false;
        Toast.info('请输入稿件标题', 2, null, false);
    } else if (!story.title || story.title.trim() === '') {
        check = false;
        Toast.info('请输入稿件标题', 2, null, false);
    } else if (story.title && story.title.length > 255) {
        check = false;
        Toast.info('稿件标题过长,不能超过255字符', 2, null, false);
    } else if (checkContainsEmoji(story.title)) {
        check = false;
        Toast.info('稿件标题中不可以包含表情图!', 2, null, false);
    } else if ((!story.content || story.content.trim() === '') && (!story.linkurl || story.linkurl.trim() === '')) {
        check = false;
        Toast.info('请输入稿件内容或链接地址', 2, null, false);
    } else if ((!story.deptId || story.deptId === null) && getInfoFromLocalStorage('isContributeRoleFlag')) {
        check = false;
        Toast.info('请选择部门信息', 2, null, false);
    }
    var regex = new RegExp(/(http|https):\/\/([\w.]+\/?)\S*/);

    if (story.linkurl && !regex.test(story.linkurl)) {
        Toast.info('请输入正确的链接', 2, null, false);
        return;
    }
    // var regex = new RegExp("");


    // else if (((!story.author || story.author === "")&&getInfoFromLocalStorage('isContributeRoleFlag'))) {
    //     check = false;
    //     Toast.info('请填写作者信息', 2, null, false);
    // } else if (((!story.authorTel || story.authorTel === "")&&getInfoFromLocalStorage('isContributeRoleFlag'))) {
    //     check = false;
    //     Toast.info('请填写联系方式', 2, null, false);
    // }
    // else if (((!story.summary || story.summary === "") &&permissionCheck(":story:summary"))) {
    //     check = false;
    //     Toast.info('请输入内容摘要', 2, null, false);
    // }

    //如果是pms端的角色需要选择栏目
    if (!getInfoFromLocalStorage('isContributeRoleFlag') && window.checkColumnFlag) {
        if (!story.chooseColumn || story.chooseColumn.length === 0) {
            check = false;
            Toast.info('请在"更多信息"选择栏目', 2, null, false);
        }

        if (window.isPublish) {
            let hasYaowen = false;
            let conlumns = story.chooseColumn ? story.chooseColumn : [];
            for (var i = 0; i < conlumns.length; i++) {
                if (conlumns[i] && conlumns[i].treeName == '要闻') {
                    hasYaowen = true;
                    break;
                }
            }
            //只有总编，可以发布要文
            let userInfoCodes = getInfoFromLocalStorage("userInfo").roleCodes;
            if (hasYaowen) {

                if (userInfoCodes.indexOf("zb") < 0 && userInfoCodes.indexOf("bz") < 0 && userInfoCodes.indexOf("fbz") < 0) {
                    check = false;
                    Toast.info('您没有发布要闻的权限', 2, null, false);
                }
            }
        }
    }
    return check;
};


export const getSummaryNoAlert = (content, len) => {
    if (!content) {
        return;
    }
    let str = content.replace(/<style[^<]*<\/style>/g, "").replace(/<[^>]*>/g, "").replace(/\s*/g, "");
    str = encodeBase64(str);
    if (str.length == 0) {
        return;
    }
    if (str.length > 0 && !str.match(/^[a-zA-Z0-9\u4e00-\u9fa5]+$/g)) {
        str = str.substring(0, str.length - 1);
        if (str.length == 0) {
            return;
        }
    }
    return filterEnterStr(fixstringlen(str, len * 2));
}

//过滤字符串中的回车换行
function filterEnterStr(strHtml) {
    strHtml = strHtml.replace(/[\r\n]/g, "");
    return strHtml;
}

function fixstringlen(str, len) {
    if (str.length * 2 <= len || str.length == 0 || len <= 0)
        return str;
    if (len <= 3)
        return "...";

    var count = 0, sublen = -1, i;
    for (i = 0; i < str.length && count < len; i++) {
        var size = str.charCodeAt(i) > 255 ? 2 : 1;
        count += size;

        if (count > len - 3 && sublen == -1) {
            sublen = i;
        }
    }

    if (i < str.length || count > len) {
        str = str.substr(0, sublen) + "...";
    }
    return str;
}

function transformMedia(content) {
    let panel = document.createElement("div");
    panel.innerHTML = content;
    //照片
    let imgs = panel.getElementsByTagName('img');
    // eslint-disable-next-line no-undef
    $.each(imgs, function (index, dom) {
        dom.width = '700px';
        if (dom.attributes.widthpc || dom.attributes.maxwidthpc || dom.attributes.styleheight || dom.attributes.stylewidth) {
            dom.width = dom.attributes.widthpc;
        }
        if (dom.attributes.maxwidthpc) {
            dom.style.maxWidth = dom.attributes.maxwidthpc;
        }
        if (dom.attributes.styleheight) {
            dom.style.height = dom.attributes.styleheight;
        }
        if (dom.attributes.stylewidth) {
            dom.style.width = dom.attributes.stylewidth;
        }
    });
    //写稿 页面 视频 音频 处理
    let embeds = panel.getElementsByTagName('embed');
    // eslint-disable-next-line no-undef
    $.each(embeds, function (index, item) {
        item.style.display = 'block';
    });
    let videos = panel.getElementsByClassName('Eleditor-video-area');
    // eslint-disable-next-line no-undef
    $.each(videos, function (index, item) {
        item.parentNode.removeChild(item);
    });
    let audios = panel.getElementsByClassName('Eleditor-audio-area');
    // eslint-disable-next-line no-undef
    $.each(audios, function (index, item) {
        item.parentNode.removeChild(item);
    });
    return panel.outerHTML;
}

// function utf16toEntities(str) {
//     var patt = /[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
//     if (!str || str == '') {
//         return '';
//     }
//     str = str.replace(patt, function (char) {
//         var H, L, code;
//         if (char.length === 2) {
//             H = char.charCodeAt(0); // 取出高位
//             L = char.charCodeAt(1); // 取出低位
//             code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
//             return "&#" + code + ";";
//         } else {
//             return char;
//         }
//     });
//     // 对字符串进行编码
//     var encodeUrl = encodeURI(str);
//     // 对编码的字符串转化base64
//     str = btoa(encodeUrl);
//     return str;
// }
export function encodeBase64(str){
    var base64Pattern = new RegExp('^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$');
    if(base64Pattern.test(str)){
        return str;
    }else {
        // 对字符串进行编码
        var encode = encodeURIComponent(str);
        // 对编码的字符串转化base64
        var base64 = btoa(encode);
        return base64;
    }
}