//redux存储稿件id,全页面使用的
import {
    STORY_ID,
    CURRENT_MEDIA_CODE,
    STORY_ATTRIBUTE,
    USER_INFO,
    ACT_REMARK,
    ACT_CHOOSE,
    CONTRIBUTE_ROLE_FLAG,
    NO_REFRESH,
    TO_LIST_PAGE,
    SELECTED_TAB,
    COLUMN_OF_MEDIACODEL,
    DATA_SOURCE,
    SET_WEB_EXTENDS, PROCESS_DEPLOYKEY
} from "./action-type/action-types";


//设置稿件id
export const setStoryId = function (storyId) {
    return {type: STORY_ID, storyId};
};

//设置当前的媒体code
export const setCurrentMedia = function (currentMediaCode) {
    return {type: CURRENT_MEDIA_CODE, currentMediaCode};
};

//设置用户名称
export const setUserInfo = function (userInfo) {
    return {type: USER_INFO, userInfo};
};



export const setStoryAttribute=function (storyAttribute){
    return {
        type:STORY_ATTRIBUTE,
        storyAttribute
    }
}

export const setContributeRoleFlag=function (contributeRoleFlag){
    return {
        type:CONTRIBUTE_ROLE_FLAG,
        contributeRoleFlag
    }
}
export const  setNoRefresh = function (noRefresh) {
    return {type: NO_REFRESH, noRefresh};
}


/**
 * 设置候选人列表数据
 * @param actChoose
 * @returns {{type: *, actChoose: *}}
 */
export const setActChoose=function (actChoose){
    return {
        type:ACT_CHOOSE,
        actChoose
    }
}

/**
 * 设置批注数据
 * @param actRemark
 * @returns {{actRemark: *, type: *}}
 */
export const setActRemark=function (actRemark){
    return {
        type:ACT_REMARK,
        actRemark
    }
}

/**
 * 设置批注数据
 * @param actRemark
 * @returns {{actRemark: *, type: *}}
 */
export const setToListPage=function (toListPage){
        return {
            type:TO_LIST_PAGE,
            toListPage
        }
    }

/**
 * 设置鼠标点解的导航页
 * @param actRemark
 * @returns {{actRemark: *, type: *}}
 */
export const setSelectTab=function (selectedTab){
    return {
        type:SELECTED_TAB,
        selectedTab
    }
}

/**
 * 数据来源
 * @param dataSource
 * @returns {{actRemark: *, type: *}}
 */
export const setDataSource=function (dataSource){
    return {
        type:DATA_SOURCE,
        dataSource
    }
}

/**
 * 设置鼠标点击的导航页
 * @param actRemark
 * @returns {{actRemark: *, type: *}}
 */
export const setColumnOfMediaCodel=function (columnOfMediaCodel){
    return {
        type: COLUMN_OF_MEDIACODEL,
        columnOfMediaCodel
    }
}


/**
 * 设置稿件的扩展字段
 * @param actRemark
 * @returns {{actRemark: *, type: *}}
 */
export const setWbextends=function (wbextends){
    return {
        type: SET_WEB_EXTENDS,
        wbextends
    }
}

/**
 * 设置批注数据
 * @param actRemark
 * @returns {{actRemark: *, type: *}}
 */
export const setProcessDeployKey=function (processDeployKey){
    return {
        type:PROCESS_DEPLOYKEY,
        processDeployKey
    }
}

