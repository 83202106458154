import React, {Component} from 'react';
import NavBar from "antd-mobile/es/nav-bar";
import InterViewTabs from "./InterViewTabs";
import {permissionCheck, uuid} from "../../../utils";
import {Icon} from "antd-mobile";

/**
 * 采访任务列表页面
 */

class InterviewTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: '采访',
            isContributeRoleFlag: this.props.match.params.isContributeRoleFlag,
            goBack: this.props.match.params.goBack,
            history: require("history").createHashHistory,
        }
    }

    goBack() {
        if (this.props.match.params.goBack !== 'false') {
            let path = '/index/homeTab/';
            this.state.history().push(path);
        }
    }

    /**
     * 新增采访任务页面
     */
    addInterview() {
        let path = "/addInterviewPage/addInterviewPage/0"
        this.state.history().push(path);
    }

    render() {
        var header = <NavBar key={uuid()}
                             mode={'light'}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={this.props.match.params.goBack === 'false' ? [] : [<span
                                 key={uuid()} className={'nav_goback'} onClick={()=>this.goBack()} >返回</span>]}
                             rightContent={[
                                 this.state.isContributeRoleFlag === 'true' ? '' :
                                     <div key={uuid()} onClick={() => {
                                         this.addInterview();
                                     }} style={{
                                         color: '#2d2d2d',
                                         fontSize: "18px",
                                         width: '80px',
                                         textAlign: 'right',
                                         fontWeight: "500"
                                     }}><img src={'images/tianjia.png'} width={'18px'}/>
                                     </div>
                             ]}
        ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>;

        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            <InterViewTabs isContributeRoleFlag={this.state.isContributeRoleFlag}></InterViewTabs>
        </div>
    }
}

export default InterviewTask;
