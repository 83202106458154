/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon, Tag, TextareaItem, Button, InputItem, NavBar, Toast} from "antd-mobile";
import AddressBook from "../../components/addressBook/AddressBook";
import * as Actions from "../../action/topiclist";
import {ArrayDuplication} from "../../utils";


export default class Assignment extends Component {
    static defaultProps = {};
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            list: [],
            tsRemark: '',
            listArray: []
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.setState({id: id});
        Actions.topicDetails({id: id}, (data) => {
            let newList = [];
            if (data.tsAppointeeName) {
                data.tsAppointeeName.forEach((item, i) => {
                    newList.push({
                        chName: item,
                        account: data.tsAppointee[i],
                    })
                })
            }
            this.setState({list: newList, tsRemark: data.tsRemark, listArray: data.tsAppointee});
        });
    }

    render() {
        return (
            <div>
                <NavBar
                    mode={'light'}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'} onClick={() => this.props.history.goBack()}>返回</div>
                    ]}

                ><span className={'nav_title'}>指派写作</span></NavBar>

                <div style={{margin: '5px 0', padding: '5vw', color: '#999999', backgroundColor: 'white'}}>
                    <div style={{lineHeight: '10vw'}}>已选择</div>
                    <div style={{marginTop: 10}}>
                        {
                            this.state.list && this.state.list.map((item, index) => {
                                return (<Tag key={index} closable style={{margin: '2vw'}} onClose={() => {
                                    this.delete(index)
                                }}>{item.chName}</Tag>)
                            })
                        }
                        <div style={{
                            display: 'inline-block',
                            padding: '2px 20px',
                            background: '#1890fe',
                            color: 'white',
                            borderRadius: '4px'
                        }} onClick={() => {
                            this.AddressBook.show('user')
                        }}>添加
                        </div>
                    </div>
                </div>
                <div className={'addTopic'} style={{
                    margin: '5px 0', color: '#999999', padding: '2vw',
                    backgroundColor: 'white'
                }}>
                    <style>{`.addTopic .am-textarea-control textarea{font-size:4vw}`}</style>
                    <div style={{paddingLeft: '15px', lineHeight: '7vw'}}>备注信息</div>
                    <TextareaItem
                        rows={4}
                        placeholder="输入备注信息"
                        maxLength={300}
                        value={this.state.tsRemark}
                        onChange={(e) => this.onChange(e)}
                    />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '20vw',}}>
                    <Button type="ghost" inline style={{border: '1px solid #108ee9', width: '40%', fontSize: '4vw'}}
                            className="am-button-borderfix" onClick={() => this.cancel()}>取消</Button>
                    <Button type="primary" inline style={{width: '40%', fontSize: '4vw'}}
                            onClick={() => this.sure()}>确定</Button>
                </div>
                <AddressBook ref={AddressBook => {
                    this.AddressBook = AddressBook
                }} onOkClick={(e) => {
                    this.addressBookCallBack(e)
                }} choose={'more'}/>
            </div>
        )
    }

    // 渲染选择好的创建人
    addressBookCallBack(e) {
        let list = this.state.list.concat(e.list);
        let newCreatUserList = ArrayDuplication(list,'account');
        this.setState({list: newCreatUserList});
    }

    //取消
    cancel() {
        this.props.history.goBack();
    }

    //文本框
    onChange(e) {
        this.setState({tsRemark: e})
    }

    delete(index) {
        this.state.list.splice(index, 1);
        this.setState({})
    }

    //点击确定
    sure() {
        let array = [];
        this.state.list.map((item, index) => {
            array.push(item.account)
        });
        if (array.length) {
            let params = {
                id: this.state.id,
                tsAppointee: array,
                tsRemark: this.state.tsRemark
            };
            Actions.getAssignUser(params, (data) => {
                this.props.history.goBack();
            });
        } else {
            Toast.info('请先添加被指派人', 1);
            return;
        }

    }
}