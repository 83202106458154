import React, {Component} from 'react';
import {NavBar, List, Switch, WhiteSpace, WingBlank, Toast, Icon} from "antd-mobile";
import {createForm} from 'rc-form';
import {uuid} from "../../utils";
import { getUserNoticeSetting, saveAppUserNoticeSetting} from "../../action";

/**
 * 私信设置
 */
class PrivateLetterSettPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showView: false,
            userSetting: {
                noticeMessage: true,
                manuscriptsInformation: true,
                newClueMessage: true,
                interviewMessage: true,
                examineMessage: true,
            },
            history: require('history').createHashHistory
        }
    }

    /**
     * 保护用户的设置信息
     */
    saveUserSetting() {
        //获取用户设置的数据
        let userSetting = this.props.form.getFieldsValue();
        let data = new FormData();
        data.append('noticeMessage', userSetting.noticeMessage);
        data.append('manuscriptsInformation', userSetting.manuscriptsInformation);
        data.append('newClueMessage', userSetting.newClueMessage);
        data.append('interviewMessage', userSetting.interviewMessage);
        data.append('examineMessage', userSetting.examineMessage);
        saveAppUserNoticeSetting(data, this.saveResult.bind(this));

    }

    saveResult(data){
       if(data.errorCode===200){

       }else{
           Toast.info("服务异常", 1);
       }
    }

    componentWillMount() {
        Toast.loading("加载中");
        getUserNoticeSetting(null, this.updateUserSetting.bind(this));
    }

    /**
     * 回显用户设置私信的数据
     */
    updateUserSetting(data) {
        Toast.hide();
        if (!data) {
            this.setState({
                showView:true,
            });
            return
        }
        this.setState({
            showView:true,
            userSetting: data,
        })

    }

    setPrivateLetterList() {
        const {getFieldProps} = this.props.form;
        return this.state.showView?(
            <WingBlank style={{margin:'5px'}}>
                <List
                    // renderHeader={() => ''}
                >
                    <WhiteSpace size="lg" style={{backgroundColor: '#F5F5F9', height:'10px'}}/>
                    <List.Item style={{height: '60px'}} className={'person_message_item'}
                               extra={<Switch
                                   {...getFieldProps('noticeMessage', {
                                       initialValue: this.state.userSetting.noticeMessage,
                                       valuePropName: 'checked',
                                       onChange: (val) => {
                                           this.saveUserSetting()
                                       },
                                   })}
                                   onClick={(checked) => {
                                       // set new value
                                       this.props.form.setFieldsValue({
                                           noticeMessage: checked,
                                       });
                                   }}
                               />}
                    >通知公告</List.Item>
                    <WhiteSpace size="lg" style={{backgroundColor: '#F5F5F9', height:'10px'}}/>
                    <List.Item style={{height: '60px'}}
                               extra={<Switch
                                   {...getFieldProps('manuscriptsInformation', {
                                       initialValue: this.state.userSetting.manuscriptsInformation,
                                       valuePropName: 'checked',
                                       onChange: (val) => {
                                           this.saveUserSetting()
                                       },
                                   })}
                                   onClick={(checked) => {
                                       // set new value
                                       this.props.form.setFieldsValue({
                                           manuscriptsInformation: checked,
                                       });
                                   }}
                               />}
                    >约稿信息</List.Item>
                    <WhiteSpace size="lg" style={{backgroundColor: '#F5F5F9', height:'10px'}}/>
                    <List.Item style={{height: '60px'}}
                               extra={<Switch
                                   {...getFieldProps('newClueMessage', {
                                       initialValue: this.state.userSetting.newClueMessage,
                                       valuePropName: 'checked',
                                       onChange: (val) => {
                                           this.saveUserSetting()
                                       },
                                   })}
                                   onClick={(checked) => {
                                       // set new value
                                       this.props.form.setFieldsValue({
                                           newClueMessage: checked,
                                       });
                                   }}
                               />}
                    >线索信息</List.Item>
                    <WhiteSpace size="lg" style={{backgroundColor: '#F5F5F9', height:'10px'}}/>
                    <List.Item style={{height: '60px'}}
                               extra={<Switch
                                   {...getFieldProps('interviewMessage', {
                                       initialValue: this.state.userSetting.interviewMessage,
                                       valuePropName: 'checked',
                                       onChange: (val) => {
                                           this.saveUserSetting()
                                       },
                                   })}
                                   onClick={(checked) => {
                                       // set new value
                                       this.props.form.setFieldsValue({
                                           interviewMessage: checked,
                                       });
                                   }}
                               />}
                    >采访信息</List.Item>
                    <WhiteSpace size="lg" style={{backgroundColor: '#F5F5F9', height:'10px'}}/>
                    <List.Item style={{height: '60px'}}
                               extra={<Switch
                                   {...getFieldProps('examineMessage', {
                                       initialValue: this.state.userSetting.examineMessage,
                                       valuePropName: 'checked',
                                       onChange: (val) => {
                                           this.saveUserSetting()
                                       },
                                   })}
                                   onClick={(checked) => {
                                       // set new value
                                       this.props.form.setFieldsValue({
                                           examineMessage: checked,
                                       });
                                   }}
                               />}
                    >审核信息</List.Item>
                </List>
            </WingBlank>):<div></div>
    }

    /**
     * 返回按钮
     */
    goback() {
        var url = "/index/messageTab";
        this.state.history().push(url);
    }

    render() {
        return <div>
            <NavBar
                icon={<Icon type="left" style={{color:'#2d2d2d'}} />}
                mode={'light'}
                onLeftClick={() => this.goback()}
                leftContent={[<div className={'nav_goback'} key={uuid()} onClick={() => {
                    this.goback()
                }}>返回</div>]}
            ><span className={'nav_title'}>私信设置</span></NavBar>
            {this.setPrivateLetterList()}
        </div>
    }

}

const PrivateLetterSet = createForm()(PrivateLetterSettPage);
export default PrivateLetterSet;
