import React, {Component} from 'react';
import {Player} from 'video-react';
import 'video-react/dist/video-react.css'
import {
    Calendar,
    Card,
    ImagePicker,
    InputItem,
    List,
    TextareaItem,
    Toast,
    WhiteSpace,
    WingBlank,Checkbox
} from "antd-mobile";
import config from "../../config";
import {
    addNewsCluePic,
    addNewsClueVideo,
    getInfoFromLocalStorage, uploadAttachmentFile,
    uploadimage,
    uploadvideo
} from "../../action";
import {checkContainsEmoji, formatDate, goupByFirstName, removeArrayByIndex} from "../../utils";
import Modal from "antd-mobile/es/modal";
import {PhotoSlider} from "react-photo-view";
import 'react-photo-view/dist/index.css';
import './Intramuralmedia_card.css'
import createForm from "rc-form/es/createForm";
import $ from "jquery";
import {addIntramuraImedia} from "../../action/IntramuraImediaAction";
import TxySelectList from "../TxySelectList";
import {getShrInfos} from "../../action/extermuraImediaAction";
import RemarkInfoShow from "../RemarkInfoShow";

//校内媒体预约新增功能
// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;
const videoAllowFiles = config.videoAllowFiles;
const audioAllowFiles = config.audioAllowFiles;
const pictureAllowFiles = config.pictureAllowFiles;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}


const extra = {
    '2017/07/15': {info: 'Disable', disable: true},
};

const now = new Date();
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 5)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 6)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8)] = {info: 'Disable', disable: true};

Object.keys(extra).forEach((key) => {
    const info = extra[key];
    const date = new Date(key);
    if (!Number.isNaN(+date) && !extra[+date]) {
        extra[+date] = info;
    }
});

/**
 * 采访详情页面的，采访内容卡片
 */
const CheckboxItem = Checkbox.CheckboxItem;
class IntramuralmediaCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            picFiles: [],
            multiple: true,
            videoUpload: [],
            videoFiles: [],
            attachemntFiles:[],  //附件组件
            picResourceId: [],
            videoResourceId: [],
            attachemntResourceid:[],
            history: require('history').createHashHistory(),
            showVideoView: false,
            showPicView: false,
            videoSources: {},
            picIndex: 0,
            selectedIds:[],
            selectUsers:[],
            users:[],
            reporterType:props.intramuraMediaInfo.reporter?props.intramuraMediaInfo.reporter.split(","):[],
            isContributeRoleFlag:getInfoFromLocalStorage('isContributeRoleFlag'),
            startTime:formatDate(props.intramuraMediaInfo.starttime?props.intramuraMediaInfo.starttime.time:''),
            endTime:formatDate(props.intramuraMediaInfo.endtime?props.intramuraMediaInfo.endtime.time:''),
            currentUser:getInfoFromLocalStorage("userInfo"),
        };
    }

    /**
     * 修改图片
     * @param files
     * @param type
     * @param index
     */
    onChangePic = (files, type, index) => {

        if (type === 'add') {
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1
                    data.append('file', files[i].file);
                    //调用后台接口上传文件，
                    uploadimage(data, this.setImageUrlTopicUrls.bind(this));
                }
            }

            this.setState({
                picFiles: files,
            });

        }

        if (type === 'remove') {
            let picResourceIds = this.state.picResourceId;
            picResourceIds.splice(index, 1);

            let picFiles = this.state.picFiles;
            picFiles = removeArrayByIndex(index, picFiles);
            this.setState({
                picFiles: picFiles
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    setImageUrlTopicUrls(data) {
        if (!data) {
            return;
        }
        let storageFile = data.storageFile;

        var param = {
            imageHeight: storageFile.hegiht,
            imageWidth: storageFile.width,
            name: storageFile.name,
            ext: storageFile.suffix,
            path: data.url,
            size: storageFile.size
        }

        addNewsCluePic(param, this.addNewCluePicBack.bind(this));
    }

    /**
     * 新增采访图片回调
     * @param data
     */
    addNewCluePicBack(data) {
        if (!data) {
            return;
        }
        let picResourceId = this.state.picResourceId;
        picResourceId.push(data);
    }

    componentWillMount() {
        this.props.onRef(this);
    }

    componentDidMount() {
        var userInfo=getInfoFromLocalStorage("userInfo");
        var param={
            deptIds:userInfo.deptIds.join(",")
        }
        getShrInfos(param,(data)=>{
            //对编辑的数据进行分组
            let users=JSON.parse(data);
            let firstName2UserList = goupByFirstName(users);

            this.setState({
                users: firstName2UserList,
            })
        });
    }

    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            interviewDateshow: false,
            endDateShow: false,
        });
    }


    onConfirm = (startTime, endTime) => {
        startTime = formatDate(startTime);
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            interviewDateshow: false,
            startTime: startTime,
        });
    }

    /**
     * 回稿时间的确认按钮
     */
    onConfirmEndData = (startTime, endTime) => {
        startTime = formatDate(startTime);
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            endDateShow: false,
            endTime: startTime,
        });
    }

    /**
     * 选择记者类型
     * @param value
     */
    onChange=(value)=>{
        let reporterType=this.state.reporterType;
        let index=reporterType.indexOf(value);
        if(index>-1){
            reporterType.splice(index,1);
        }else{
            reporterType.push(value);
        }

        this.setState({
            reporterType
        });
    }

    /**
     *变更视频
     * @param files
     * @param type
     * @param index
     */
    onChangeVideo = (files, type, index) => {
        if (type === 'add') {
            this.state.videoUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file) {
                    if (files[i].file.uploaded !== 1) {
                        files[i].file.uploaded = 1
                        data.append('file', files[i].file);

                        //调用后台接口上传文件，
                        uploadvideo(data, this.setVideoUrlTovideoUrls.bind(this));
                    }
                }
            }
        }

        if (type === 'remove') {
            let videoUploads = this.state.videoUpload;
            videoUploads = removeArrayByIndex(index, videoUploads);

            let videoFiles = this.state.videoFiles;
            let videoResouceIds = this.state.videoResourceId;
            videoResouceIds.splice(index, 1);
            videoFiles = removeArrayByIndex(index, videoFiles);
            this.setState({
                videoUpload: videoUploads,
                videoFiles: videoFiles
            })
        }
    }

    /**
     *变更附件
     * @param files
     * @param type
     * @param index
     */
    onChangeAttachemnt = (files, type, index) => {
        if (type === 'add') {
            this.state.videoUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file) {
                    if (files[i].file.uploaded !== 1) {
                        files[i].file.uploaded = 1
                        data.append('file', files[i].file);

                        //调用后台接口上传文件，
                        uploadAttachmentFile(data, this.setAttachemntUrlAttachmentUrls.bind(this));
                    }
                }
            }
        }

        if (type === 'remove') {
            let attachemntFiles = this.state.attachemntFiles;
            attachemntFiles = removeArrayByIndex(index, attachemntFiles);

            let attachemntResourceid =this.state.attachemntResourceid;
            attachemntResourceid=removeArrayByIndex(index,attachemntFiles);

            this.setState({
                attachemntFiles,
                attachemntResourceid
            })
        }
    }


    /**
     * 设置视频文件的展示路径
     * @param data
     */
    setVideoUrlTovideoUrls(data) {
        if (!data) {
            return;
        }
        let storageFile = data.storageFile;
        var param = {
            frameHeight: storageFile.hegiht,
            frameWidth: storageFile.width,
            name: storageFile.name,
            ext: storageFile.suffix,
            path: data.url,
            allTime: storageFile.duration,
            size: storageFile.size
        }
        //上传线索视频
        addNewsClueVideo(param, this.addNewsClueVideoBack.bind(this));

        let videoUpload = this.state.videoUpload;
        videoUpload.push(data);

        let videoFiles = this.state.videoFiles;
        let file = {url: data.thumbnailsURL, path: data.url};
        videoFiles.push(file)
        this.setState({
            videoFiles: videoFiles,
        })
    }

    /**
     * 设置附件的文件的展示路径
     * @param data
     */
    setAttachemntUrlAttachmentUrls(data) {
        if (!data) {
            return;
        }

        //保存媒体预约的附件
        this.saveAttachmentFile(data);

        //接展示的 附件信息；
        let storageFile = data.storageFile;
        var param = {
            name: storageFile.name,
            ext: storageFile.suffix,
            path: data.url,
            size: storageFile.size,
            url:'images/attachmentfile.png'
        }

        //保存媒体预约的附件
        let attachemntFiles = this.state.attachemntFiles;
        attachemntFiles.push(param);

        //保存媒体预约的附件的名称
        this.setState({
            attachemntFiles: attachemntFiles,
        },()=> (
            this.showAttachmentName()
        ))
    }

    //添加附件文件的文字；
    showAttachmentName() {
        let attachemntFiles = this.state.attachemntFiles;
        $('.attachmentFile_pincker .am-image-picker-item-content').empty();
        if (attachemntFiles && attachemntFiles.length > 0) {
            for(let i=0;i<attachemntFiles.length;i++){
                $('.attachmentFile_pincker .am-image-picker-item-content').eq(i).append(attachemntFiles[i].name);
            }
        }
    }

    openPicView(index) {
        this.setState({
            showPicView: true,
            picIndex: index
        })
    }

    /**
     * 新增采访视频，回调
     * @param data
     */
    addNewsClueVideoBack(data) {
        // if (!data) {
        //     return;
        // }

        let videoResourceIds = this.state.videoResourceId;
        videoResourceIds.push(data);
    }

    validateFormData() {
        let intramuralMedia = this.props.form.getFieldsValue();

        //1,预约标题必填校验
        if (!intramuralMedia.title || intramuralMedia.title.length == 0) {
            Toast.fail('请填写预约标题!');
            return false;
        }
        if(checkContainsEmoji(intramuralMedia.title)){
            Toast.fail('标题中不可以包含表情图!');
            return false;
        }

        //2，开始时间必填
        if (!intramuralMedia.starttime || intramuralMedia.starttime == 0) {
            Toast.fail('请填写开始时间!');
            return false;
        }

        //3，结束时间必填
        if (!intramuralMedia.endtime || intramuralMedia.endtime == 0) {
            Toast.fail('请填写结束时间!');
            return false;
        }

        //4，联系人必填
        if (!intramuralMedia.contacts || intramuralMedia.contacts == 0) {
            Toast.fail('请填写联系人!');
            return false;
        }
        if(checkContainsEmoji(intramuralMedia.contacts)){
            Toast.fail('联系人中不可以包含表情图!');
            return false;
        }

        //5，联系电话必填
        if (!intramuralMedia.contactsWay || intramuralMedia.contactsWay == 0) {
            Toast.fail('请填写联系电话!');
            return false;
        }

        //6，地点必填
        if (!intramuralMedia.venue || intramuralMedia.venue == 0) {
            Toast.fail('请填写地点!');
            return false;
        }

        //7，出席人必填
        if (!intramuralMedia.personsPresent || intramuralMedia.personsPresent == 0) {
            Toast.fail('请填写出席人!');
            return false;
        }

        //8,所需记者类型
        if (!this.state.reporterType || this.state.reporterType.length == 0) {
            Toast.fail('请选择所需记者类型!');
            return false;
        }

        //9,新闻点
        if (!intramuralMedia.content ||intramuralMedia.content == 0) {
            Toast.fail('请输入新闻点');
            return false;
        }

        if(checkContainsEmoji(intramuralMedia.content)){
            Toast.fail('新闻点中不可以包含表情图!');
            return false;
        }

        //10,详细信息
        if (!intramuralMedia.contentDetail || intramuralMedia.contentDetail == 0) {
            Toast.fail('请输入详细信息');
            return false;
        }

        if(checkContainsEmoji(intramuralMedia.contentDetail)){
            Toast.fail('请输入详细信息!');
            return false;
        }
        return true;
    }

    /**
     * 提交给审核人
     */
    submitDataToShr(){
        if(this.state.selectUsers.length==0){
            Toast.fail("请选择审核人");
            return;
        }
        console.info("submitDataToShr",this.state.selectUsers);
        this.submitData();
    }

    /**
     * 提交线索数据
     */
    submitData() {
        //数据校验
        var checkResult = this.validateFormData();
        if (!checkResult) {
            return;
        }

        //获取稿件的属性数据
        let intramuraImediaInfo = this.props.form.getFieldsValue();
        intramuraImediaInfo.reporter=this.state.reporterType.join(',');
        let phone = this.props.form.getFieldValue('contactsWay');
        if (phone) {
            phone = phone.replace(/\s*/g, "");
        }

        //手机号码校验
        if (phone && !(/^1(3|4|5|6|7|8|9)\d{9}$/).test(phone)) {
            Toast.info('联系方式格式错误', 2, null, false);
            return;
        }
        intramuraImediaInfo.contactsWay = phone;
        intramuraImediaInfo.fileidsStr = this.state.attachemntResourceid.join(',');
        intramuraImediaInfo.isSubmit=false;
        if(this.state.selectUsers.length>0){
            intramuraImediaInfo.isSubmit=true;
            let selectAccount=[];
            let chooseUser=this.state.selectUsers;
            for(let i=0;i<chooseUser.length;i++){
                selectAccount.push(chooseUser[i].account);
            }
            intramuraImediaInfo.chooseUser=selectAccount.join(",");
        }

        //保存校内媒体预约到数据库
        let path="Pms";
        if(this.state.isContributeRoleFlag){
            path="Contri";
        }
        let url="/cm-sites-app-h5/internalArrange"+path+"/add"
        addIntramuraImedia(url,intramuraImediaInfo, this.addIntramuraImediaCallBack.bind(this));

    }

    addIntramuraImediaCallBack(data) {
        if (data) {
            Toast.success("提交成功");

            //回到校内媒体预约列表页面
            var url = '/intramuralMediaPage/intramuralMediaPage';
            this.state.history.push(url);
        }
    }

    //关闭视频
    closeVideo() {
        this.setState({
            videoSources: {},
            showVideoView: false,
        })
    }

    /**
     * 视频预览
     */
    openVideoView(index, files) {
        this.setState({
            videoSources: files[index].path,
            showVideoView: true
        })
    }

    closePic() {
        this.setState({
            showPicView: false
        })
    }

    setPicIndex(index) {
        this.setState({
            picIndex: index,
        })
    }

    /***
     * 保存附件的数据
     */
    saveAttachmentFile(result){
        let path="Pms";
        if(this.state.isContributeRoleFlag){
            path="Contri";
        }
        let params = {};
        let type =1;
        let ext = result.storageFile.suffix.toLowerCase();
        let url = "/cm-sites-app-h5/internalArrange"+path+"/saveReserverFileOtherfile";
        if(pictureAllowFiles.indexOf(ext)>-1){
            url = "/cm-sites-app-h5/internalArrange"+path+"/saveReserverFilePicture";
            params.imageHeight =result.storageFile.height;
            params.imageWidth =result.storageFile.width;
        }else if(videoAllowFiles.indexOf(ext)>-1){
            url = "/cm-sites-app-h5/internalArrange"+path+"/saveReserverFileVideo";
            params.frameHeight =result.storageFile.height;
            params.frameWidth =result.storageFile.width;
            params.allTime =result.storageFile.duration;
        }else if(audioAllowFiles.indexOf(ext)>-1){
            url = "/cm-sites-app-h5/internalArrange"+path+"/saveReserverFileAudio";
            params.allTime =result.storageFile.duration;
        }else{
            url = "/cm-sites-app-h5/internalArrange"+path+"/saveReserverFileOtherfile";
        }

        params.reservertiontype = type;
        params.dataSource = 9;
        params.name = result.storageFile.name;
        params.ext = result.storageFile.suffix;
        params.path = result.url;
        params.size = result.storageFile.size?result.storageFile.size:0;
        params.reserverId =0;
        /**此处ajax需要同步，因为所有的ajax执行完毕之后需要关闭页面*/
        $.ajax({
            type: "post",
            url: url,
            cache:false,
            async:false,
            data: params,
            dataType: "json",
            success: (res)=>{
                this.state.attachemntResourceid.push(res.resourceId);
            }
        });
    }

    showAccountModal=()=>{
        this.setState({
            chooseAccountModal:true,
        })
    }

    onClose=()=>{
        this.setState({
            chooseAccountModal:false,
        })
    }

    //获取选中的用户信息
    getUserAccountList=(val)=> {
        var index = this.state.selectedIds.indexOf(val.id);
        if (index < 0) {
            this.state.selectedIds.push(val.id);
            this.state.selectUsers.push(val);
        } else {
            this.state.selectedIds.splice(index, 1);
            this.state.selectUsers.splice(index, 1);
        }
    }

    formStatusName=(data,userInfo)=>{
        let status="";
        if(data.status== -999 && data.lastStatus == -2){
            status ="院系审核驳回-待修改";
        } else if(data.status== -999 && data.lastStatus== -1){
            status ="宣传部审核驳回-待修改";
        } else if(data.status== -2 && data.lastStatus== -1 && (userInfo.roleCodes.indexOf("shr")>-1 ||userInfo.roleCodes.indexOf("xsjz")>-1)){
            status ="宣传部审核驳回-待修改";
        } else if(data.status== -2 && (userInfo.roleCodes.indexOf("shr")>-1 ||userInfo.roleCodes.indexOf("xsjz")>-1) && (data.submitterRole.indexOf("shr")>-1 || data.submitterRole.indexOf("xsjz")>-1 )){
            status ="草稿";
        } else if(data.status== -2){
            status =" 待院系审核";
        } else if(data.status== -999){
            status ="草稿";
        } else if(data.status== -1){
            status ="待宣传部审核";
        } else if(data.status==1){
            status ="已采用";
        }
        return status;
    }

    render() {
        const {getFieldProps,setFieldsValue} = this.props.form;
        const {intramuraMediaInfo} = this.props;
        const {currentUser} = this.state;
        let selectReporter=this.state.reporterType;

        const reviewData={
            title:'最近审批结果',
            statusName:this.formStatusName(intramuraMediaInfo,currentUser),
            reviewDate:formatDate(intramuraMediaInfo.reviewDate?intramuraMediaInfo.reviewDate.time:''),
            reviewSubmitter:intramuraMediaInfo.reviewSubmitter,
            reviewContent:intramuraMediaInfo.reviewContent,
        }

        const news_story = <div>
            <div style={{color: 'red', width: '6px'}} className={'clue_tittle'}>*</div>
            <div
                style={{fontSize: '14px'}} className={'clue_tittle'}>新闻点
            </div>
            <div className={'clue_colon'}>:</div>
        </div>;
        const detailed_information = <div>
            <div style={{color: 'red', width: '6px'}} className={'clue_tittle'}>*</div>
            <div
                style={{fontSize: '14px'}} className={'clue_tittle'}>详细信息
            </div>
            <div className={'clue_colon'}>:</div>
        </div>;
        return <div style={{overflowY: 'auto', height: '90vh'}}>
            {this.props.intramuraMediaInfo.reviewDate?<RemarkInfoShow {...reviewData}/>:''}
            <WingBlank size="lg" style={{margin: '5px'}}>
                <div className={'storyAttribute'}>
                    <List>
                        <InputItem
                            clear
                            {...getFieldProps('title',{initialValue: this.props.intramuraMediaInfo.title})}
                            placeholder="请输入预约标题"
                            moneyKeyboardAlign="left"
                            maxLength={200}
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                            type={'text'}
                        >
                            <div className={'clue_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                            <div className={'clue_tittle'}>预约标题</div>
                            <div className={'clue_colon'}>:</div>
                        </InputItem>
                        <InputItem placeholder={'开始时间'}
                                   {...getFieldProps('starttime', {initialValue:this.state.startTime})}
                                   onClick={() => {
                                       document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                                       this.setState({
                                           interviewDateshow: true,
                                       });
                                   }}
                                   editable={false}
                        >
                            <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                            <div className={'interview_tittle'}>开始时间</div>
                            <div className={'interview_colon'}>:</div>
                        </InputItem>
                        <Calendar
                            {...this.state.interviewDataconfig}
                            visible={this.state.interviewDateshow}
                            onCancel={this.onCancel}
                            onConfirm={this.onConfirm}
                            minDate={now}
                            defaultDate={now}
                            type={'one'}
                        />

                        <InputItem placeholder={'结束时间'}
                                   {...getFieldProps('endtime', {initialValue:this.state.endTime})}
                                   onClick={() => {
                                       document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                                       this.setState({
                                           endDateShow: true,
                                       });
                                   }}
                                   editable={false}
                        >
                            <div className={'interview_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                            <div className={'interview_tittle'}>结束时间</div>
                            <div className={'interview_colon'}>:</div>
                        </InputItem>
                        <Calendar
                            {...this.state.interviewDataconfig}
                            visible={this.state.endDateShow}
                            onCancel={this.onCancel}
                            onConfirm={this.onConfirmEndData}
                            defaultDate={now}
                            minDate={now}
                            type={'one'}
                        />

                        <InputItem
                            clear
                            {...getFieldProps('contacts',{initialValue: this.props.intramuraMediaInfo.contacts})}
                            placeholder="请输入联系人"
                            moneyKeyboardAlign="left"
                            maxLength={50}
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                            type={'text'}
                        >
                            <div className={'clue_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                            <div className={'clue_tittle'}>联系人</div>
                            <div className={'clue_colon'}>:</div>
                        </InputItem>

                        <InputItem
                            {...getFieldProps('contactsWay',{initialValue: this.props.intramuraMediaInfo.contactsWay})}
                            type={'phone'}
                            clear
                            placeholder="请输入联系电话"
                            moneyKeyboardAlign="left"
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}

                        >
                            <div className={'clue_tittle'} style={{color: 'red', width: '6px', height: '15px'}}>*</div>
                            <div className={'clue_tittle'}>联系电话</div>
                            <div className={'clue_colon'}>:</div>
                        </InputItem>

                        <InputItem
                            clear
                            {...getFieldProps('venue',{initialValue: this.props.intramuraMediaInfo.venue})}
                            placeholder="请输入地点"
                            moneyKeyboardAlign="left"
                            maxLength={50}
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                            type={'text'}
                        >
                            <div className={'clue_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                            <div className={'clue_tittle'}>地点</div>
                            <div className={'clue_colon'}>:</div>
                        </InputItem>

                        <InputItem
                            clear
                            {...getFieldProps('personsPresent',{initialValue: this.props.intramuraMediaInfo.personsPresent})}
                            placeholder="请输入出席人"
                            moneyKeyboardAlign="left"
                            maxLength={50}
                            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                            type={'text'}
                        >
                            <div className={'clue_tittle'} style={{color: 'red', width: '6px'}}>*</div>
                            <div className={'clue_tittle'}>出席人</div>
                            <div className={'clue_colon'}>:</div>
                        </InputItem>
                        <div style={{height:'70px',paddingLeft:'15px'}}>
                            <div ><span  style={{color: 'red', width: '6px'}}>*</span>所需记者类型:</div>
                            <div style={{width:'150px',float:'left'}}><CheckboxItem key={"wzjz"} checked={selectReporter.includes('wzjz')} onChange={() => this.onChange("wzjz")}>文字记者</CheckboxItem></div>
                            <div style={{width:'150px',float:'left'}}><CheckboxItem key={"syjz"} checked={selectReporter.includes('syjz')} onChange={() => this.onChange("syjz")}>摄影记者</CheckboxItem></div>
                        </div>
                    </List>
                    <WhiteSpace size="lg"/>
                    <TextareaItem style={{fontSize: '14px'}}
                                  {...getFieldProps('content', {
                                      initialValue: this.props.intramuraMediaInfo.content,
                                  })}
                                  count={1000}
                                  rows={5}
                                  placeholder="请输入新闻点"
                                  title={news_story}
                    />
                    <WhiteSpace size="lg"/>
                    <TextareaItem style={{fontSize: '14px'}}
                                  {...getFieldProps('contentDetail', {
                                      initialValue: this.props.intramuraMediaInfo.contentDetail,
                                  })}
                                  count={1000}
                                  rows={5}
                                  placeholder="请输入详细内容"
                                  title={detailed_information}
                    />
                    <WhiteSpace size="lg"/>
                    <div>
                        <Card>
                            <div className={'addNews_clue_title'}>上传图片</div>
                            <ImagePicker
                                onAddImageClick={this.addImage}
                                files={this.state.picFiles}
                                onImageClick={this.openPicView.bind(this)}
                                onChange={this.onChangePic}
                                selectable={true}
                                multiple={this.state.multiple}
                            />
                        </Card>
                        <WhiteSpace size="lg"/>
                        <Card>
                            <div className={'addNews_clue_title'}>上传视频</div>
                            <div className={"video_pincker"}>
                                <ImagePicker
                                    accept={"video/*"}
                                    onAddImageClick={this.addImage}
                                    onImageClick={this.openVideoView.bind(this)}
                                    files={this.state.videoFiles}
                                    onChange={this.onChangeVideo}
                                    selectable={true}
                                    multiple={this.state.multiple}
                                />
                            </div>
                        </Card>
                        <WhiteSpace size="lg"/>
                        <Card>
                            <div className={'addNews_clue_title'}>上传附件</div>
                            <div className={"attachmentFile_pincker"}>
                                <ImagePicker
                                    accept={"*/*"}
                                    onAddImageClick={this.addImage}
                                    files={this.state.attachemntFiles}
                                    onChange={this.onChangeAttachemnt}
                                    selectable={true}
                                    multiple={this.state.multiple}
                                />
                            </div>
                        </Card>

                        <Modal
                            popup
                            visible={this.state.showVideoView}
                            animationType="slide-up"
                            afterClose={() => {
                            }}
                        >
                            <div style={{height: '100vh'}}>
                                <div onClick={() => {
                                    this.closeVideo()
                                }} style={{backgroundColor: "#000000", color: '#ffffff'}}>关闭
                                </div>
                                <div style={{height: '94vh'}}>
                                    <Player autoPlay={true} fluid={false} width={'100%'} height={'100%'}>
                                        <source src={this.state.videoSources}/>
                                    </Player>
                                </div>
                            </div>
                        </Modal>

                        <PhotoSlider
                            images={this.state.picFiles.map(item => ({src: item.url}))}
                            visible={this.state.showPicView}
                            onClose={() => (this.closePic())}
                            index={this.state.picIndex}
                            onIndexChange={this.setPicIndex.bind(this)}
                        />
                    </div>
                </div>

                <Modal
                    popup
                    visible={this.state.chooseAccountModal}
                    title={<div>
                        <div>审核人选择</div>
                    </div>}
                    onClose={this.onClose}
                    animationType="slide-up"
                    afterClose={() => {
                    }}
                    footer={[{
                        text: '确定', onPress: () => {
                            this.submitDataToShr();
                            this.onClose();
                        }
                    }]}
                >
                    <div>
                        <div style={{height: '350px'}}>
                            <TxySelectList showSearch={'none'} data={this.state.users}
                                           selectedIds={this.state.selectedIds}
                                           getUserAccountList={this.getUserAccountList.bind(this)}/>
                        </div>
                    </div>
                </Modal>
            </WingBlank>
        </div>
    }
}

const IntramuralmediaCardForm = createForm()(IntramuralmediaCard);
export default IntramuralmediaCardForm;
