/**
 * leilei 编辑器 ios 双击聚焦，是因为 index.html 里面的fastclick 影响的，已经注视掉，目前不知道对别的地方有没有影响
 */
import React, {useState, useEffect} from 'react';
import E from 'wangeditor'
import {Button, ImagePicker, Modal, TextareaItem, Toast} from "antd-mobile";
import {uploadimage, uploadvideo} from "../../action";
import $Jq from "jquery"
import PropTypes from 'prop-types';
import {isIOS} from "../../utils";
import MyImgPicker from "../../components/MyImgPicker";

import Upload from "../upload/Upload";
import $j from 'jquery';

import {VIDEO_TYPE, PIC_TYPE} from "../../constants/index"

let editor = null;
const {$, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip} = E;

const originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
const alert = Modal.alert;

export default class Editor extends React.Component {
    static defaultProps = {
        content: '',
        callBackEditorContent: () => {
        },
        customHeight: 400,
        bindId: 0,//任务绑定id
        showLinkBtn: false,
        callBackEditorLinkUrl: () => {

        }
    };
    static propTypes = {
        /**
         * 编辑器回调函数
         */
        callBackEditorContent: PropTypes.func.isRequired,//编辑器回调函数
        /**
         * 传递到编辑器的内容html 标签
         */
        content: PropTypes.string,//传递到编辑器的内容
        /**
         * 自定义编辑器高度
         */
        customHeight: PropTypes.number,//自定义编辑器高度
        /**
         * 任务id
         */
        bindId: PropTypes.number,//任务id
        /**
         * 是否显示链接按钮
         */
        showLinkBtn: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.resize.bind(this);
        this.state = {
            content: "",
            allHeight: window.innerHeight,
            currentHeight: 0,
            positionBottom: 0,
            toolbarVisible: false,
            showLinkInputVisible: false,
            inputValue: '',
            uploadType:0,
            imgDefaultWidth:554,
        }
    }

    componentDidMount() {
        //浏览器切换返回的时候，弹出框不关闭的问题，不能删除
        window.addEventListener("popstate", this.popstate, false);
        // 注：class写法需要在componentDidMount 创建编辑器
        editor = new E("#Editor");
        editor.config.menus = [
            /*'image',*///图片加上，是因为自定义的要借助于之歌部分功能，在下面隐藏起来
            /* 'head',*/
            /* 'bold',*/
            /*  'fontSize',*/
            /* 'fontName',
             'italic',
             'underline',
             'strikeThrough',
             'indent',*/
            /*'lineHeight',
            'foreColor',*/
            /*  'backColor',
              'link',
              'list',*/
            'justify',
            /* 'quote',*/
            /* 'emoticon',*/
            /* 'image',
             'video',*/
            /*  'table',*/
            /*'code',*/
            /* 'splitLine',*/
            /* 'undo',
             'redo',*/
        ];
        //自定义按钮
        let customBtn = [];
        editor.menus.extend('ImgBtn', ImgBtn);
        editor.imgClick = (event) => {
            console.log('增加了按钮，原生js 无法调用，然后用定位到上面，跳过去的');
            this.setState({uploadType:2});
            let alertList = [];
            //是任务的时候才会插入任务图片；
            if (this.props.bindId) {
                alertList.push({
                    text: '插入任务图片', onPress: () => {
                        console.log('插入任务图片');
                        this.Upload.show(2)
                    }
                })
            }
            alert('操作按钮', <div></div>, [...alertList
                ,
                {
                    text: '插入资源库图片', onPress: () => {
                        console.log('插入资源库图片');
                        this.Upload.show(12)
                    }
                },
                {
                    text: '插入本地图片', onPress: () => {
                        //不懂请勿修改；
                        console.log('插入本地图片');
                        $Jq('#imgBtn').find('input').click()
                    }
                },
                {text:  <div className={'modalClose'}>取消</div>, onPress: () => console.log('点击了插入图片取消')},
            ])
            $('.am-modal-mask').css('z-index', 10003);
            $('.am-modal-wrap').css('z-index', 10004);
            //document.getElementById('imgBtn').click()
        };
        customBtn.push('ImgBtn');//添加上传图片按钮

        editor.menus.extend('VideoBtn', VideoBtn);
        editor.videoClick = (event) => {
            console.log('增加了按钮，原生js 无法调用，然后用定位到上面，跳过去的');
            this.setState({uploadType:3});
            let alertList = [];
            //是任务的时候才会插入任务图片；
            if (this.props.bindId) {
                alertList.push({
                    text: '插入任务视频', onPress: () => {
                        console.log('插入任务视频');
                        this.Upload.show(3)
                    }
                })
            }
            alert('操作按钮', <div></div>, [...alertList
                ,
                {
                    text: '插入资源库视频', onPress: () => {
                        console.log('插入资源库视频');
                        this.Upload.show(13)
                    }
                },
                {
                    text: '插入本地视频', onPress: () => {
                        //不懂请勿修改；
                        console.log('插入本地视频');
                        $Jq('#imgBtn').find('input').click()
                    }
                },
                {text: <div className={'modalClose'}>取消</div>, onPress: () => console.log('点击了插入视频取消')},
            ])
            $('.am-modal-mask').css('z-index', 10003);
            $('.am-modal-wrap').css('z-index', 10004);
            //document.getElementById('imgBtn').click()
        };
        customBtn.push('VideoBtn');//添加上传图片按钮

        editor.menus.extend('LinkUrl', LinkUrl);
        let defaultMenu=['undo',
            'redo'];
        editor.LinkUrlClick = (event) => {
            this.setState({showLinkInputVisible: true})
        };
        if (this.props.showLinkBtn) {
            defaultMenu.push('LinkUrl');//添加链接
        }

        //自定义添加图片，隐藏编辑器再带图片上传功能
        editor.config.menus = editor.config.menus.concat(...customBtn, ...defaultMenu);
        editor.config.onchange = (content) => {
            // let newHtml = editor.txt.html();
            let video_res = /<video[^>]*vsbmp4video="true"[^>]*>/g;
            content = content.replace(video_res, function (match) {
                return match.replace(/<video/g,'<embed class="edui-upload-video" width="420" height="280" autostart="false" vsbautoplay="false"').replace(/<\/vidio>/,'</embed>').replace(/ src=("[^"]*")/," ue_src=$1 vsburl=$1").replace("old_src=\"","src=\"");
            });

            let audio_res = /<audio[^>]*vsbmp3audio="true"[^>]*>/g;
            // if(audio_res.test(content)){
                content = content.replace(audio_res, function(embedHTML){
                    embedHTML=embedHTML.replace(/<audio/g,'<embed class="edui-upload-audio" width="400" height="95" autostart="false" vsbautoplay="false"').replace(/<\/audio>/,'</embed>').replace(/ src=("[^"]*")/," ue_src=$1 vsburl=$1").replace("old_src=\"","src=\"");
                    return embedHTML;
                });
            // }
            //newHtml = newHtml.replace(/<video|<audio/g,'<embed class="edui-upload-video" width="420" height="280" autostart="false" vsbautoplay="false"').replace(/<\/vidio>|<\/audio>/,'</embed>').replace(/ src=("[^"]*")/," ue_src=$1 vsburl=$1").replace("old_src=\"","src=\"");
            this.props.callBackEditorContent && this.props.callBackEditorContent(content);
            this.setState({content: content});
        };
        editor.config.zIndex = 500;//设置编辑器的Z-Index;防止页面功能溢出，兼容react，防止react modal至于编辑器下方。
        editor.config.height = this.props.customHeight - 57;//自定义编辑器的高度
        /**
         *
         * 一定要创建 */

        editor.create();
        let content=this.props.content;
        let video_res = /<embed[^>]*vsbmp4video="true"[^>]*>/g;
        if(video_res.test(content)){
            content = content.replace(video_res, function (match) {
                return match.replace("<embed","<video controls poster='' preload='auto' playsinline='true' x-playsinline='true' webkit-playsinline='true' x-webkit-airplay='true' x5-video-player-type='h5' x5-video-orientation='h5' x5-video-player-fullscreen='true'").replace(" src=\""," old_src=\"").replace(/ue_src=\"([^\"]*)/,"src=\"$1?.mp4");
            })
        }

        let audio_res = /<embed[^>]*vsbmp3audio="true"[^>]*>/g;
        if(audio_res.test(content)){
            content = content.replace(audio_res, function(embedHTML){
                let realSrc = embedHTML.replace(/.*vsb_data="([^"]*)".*/, "$1");
                embedHTML = embedHTML.replace("<embed","<audio controls").replace(" src=\""," old_src=\"").replace(/(src=")[^"]*"/, "$1"+realSrc+"?e=.mp3\"");
                let url =$Jq(embedHTML).attr('vsb_data');
                embedHTML = embedHTML.replace("controls", "controls poster='' preload='auto' playsinline='true' x-playsinline='true' webkit-playsinline='true' x-webkit-airplay='true' x5-video-player-type='h5' x5-video-orientation='h5' x5-video-player-fullscreen='true' src='"+url+"'");
                return embedHTML;
            });
        }
        editor.txt.html(content);
        //安卓调用
        window.addEventListener('resize', this.resize);
        //ios 适配。
        window.addEventListener('focusin', () => {
            if (isIOS()) {//软键盘弹出的事件处理
                //this.setState({toolbarVisible:true})
            }
        });
        window.addEventListener('focusout', () => {
            if (isIOS()) {//软键盘收起的事件处理
                //  this.setState({toolbarVisible:false})
            }
        });
       // $Jq('.w-e-menu').eq(0).hide();//隐藏编辑器自带的图片

        $j.post("/cm-sites-app-h5/config/getByConfigKey",{key:"pms.image.default.width"},(res)=>{
            if(res){
                this.setState({imgDefaultWidth:res})
            }
        });

        if(navigator.userAgent.indexOf('Baidu') >= 0||navigator.userAgent.indexOf('QQ') >= 0||navigator.userAgent.indexOf('Quark') >= 0||navigator.userAgent.indexOf('UC') >= 0){
            console.log('部分浏览器不兼容！');
            Toast.info('部分浏览器不兼容！', 1);
        }
        console.log('-----------260------',navigator.userAgent)
    }

    resize = () => {
        //alert('监听')
        var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
        if (resizeHeight - 0 < originalHeight - 0) {
            //当软键盘弹起，在此处操作
            // this.setState({toolbarVisible:true})
        } else {
            //当软键盘收起，在此处操作
            // this.setState({toolbarVisible:false})
        }
    };

    popstate = (e) => {
        e.preventDefault();
        console.log('弹窗是否关闭');
        $Jq('.modalClose').click();
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let content = nextProps.content;
        if (content !== this.state.content) {
            editor.txt.clear();
            let video_res = /<embed[^>]*vsbmp4video="true"[^>]*>/g;
            if(video_res.test(content)){
                content = content.replace(video_res, function (match) {
                    return match.replace("<embed","<video controls poster='' preload='auto' playsinline='true' x-playsinline='true' webkit-playsinline='true' x-webkit-airplay='true' x5-video-player-type='h5' x5-video-orientation='h5' x5-video-player-fullscreen='true'").replace(" src=\""," old_src=\"").replace(/ue_src=\"([^\"]*)/,"src=\"$1?.mp4")+'</video>';
                })
            }

            let audio_res = /<embed[^>]*vsbmp3audio="true"[^>]*>/g;
            if(audio_res.test(content)){
                content = content.replace(audio_res, function(embedHTML){
                    let realSrc = embedHTML.replace(/.*vsb_data="([^"]*)".*/, "$1");
                    embedHTML = embedHTML.replace("<embed","<audio controls").replace(" src=\""," old_src=\"").replace(/(src=")[^"]*"/, "$1"+realSrc+"?e=.mp3\"");
                    let url =$Jq(embedHTML).attr('vsb_data');
                    embedHTML = embedHTML.replace("controls", "controls poster='' preload='auto' playsinline='true' x-playsinline='true' webkit-playsinline='true' x-webkit-airplay='true' x5-video-player-type='h5' x5-video-orientation='h5' x5-video-player-fullscreen='true' src='"+url+"'");
                    return embedHTML+'</audio>';
                });
            }
            editor.txt.html(content);
            this.setState({content})
        }

    }

    componentWillUnmount() {
        // 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
        editor.destroy()
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('focusin', this.resize);
        window.removeEventListener('focusout', this.resize);
        window.removeEventListener('popstate', this.popstate);
    }

    render() {
        console.log(typeof this.props.bindId, this.props.bindId)
        let accept = 'image/*';//是因为微信，其业务为有限制，如果不这样唤醒不出图片相关
        let contentHeight = window.innerHeight;
        return (
            <div className={'customStyle'} style={{position: 'relative',}}>
                <div id="Editor" style={{display: this.state.showLinkInputVisible ? 'none' : "block"}}></div>
                {/* <div style={{
                    width: '100%',
                    position: "fixed",
                    bottom: this.state.positionBottom,
                    zIndex: 10000,
                    display: this.state.toolbarVisible ? 'block' : 'none'
                }}>
                    <div id="toolbar" className="toolbar"/>
                    <style>
                        {`
                      .customStyle .w-e-droplist{
                       margin-top: -140px !important
                       }
                       `
                        }
                    </style>
                </div>*/}
                {/*{this.renderModalAddImg()}*/}
                <Upload ref={Upload => {
                    this.Upload = Upload
                }} isUoLoad={false} bindId={this.props.bindId} choose={'more'} onOkClick={(data) => {
                    this.uploadCallBack(data)
                }}/>
                {this.renderAddImg()}
                {this.state.showLinkInputVisible ?
                    <div>
                    <TextareaItem
                        prefixListCls={'link-list'}
                        value={this.state.inputValue}
                        clear
                        autoHeight
                        type={'text'}
                        placeholder="请输入链接地址"
                        style={{height: '100%'}}
                        onChange={(e) => {
                            this.props.callBackEditorLinkUrl && this.props.callBackEditorLinkUrl(e);
                            this.setState({inputValue: e})
                        }}
                        onBlur={this.onLinkInputBlur}
                    />
                        <Button style={{float:'right',width:67,height:31,lineHeight:'31px',fontSize:17,marginRight:10}} onClick={()=>{this.setState({showLinkInputVisible:false})}}>取消</Button>
                    </div>: null}
            </div>
        );
    }

    renderAddImg() {
       //是因为微信，其业务为有限制，如果不这样唤醒不出图片相关
        let accept;
        switch (this.state.uploadType) {
            case 2:
                // accept = "." + [...PIC_TYPE,].join(",.");
                accept='image/*';
                break;
            case 3:
                //accept = "." + [...VIDEO_TYPE].join(",.");
                accept='video/*';
                break;
            case 4:
                //accept = "." + [...ATTCHMENT_TYPE].join(",.");
                accept="*";
                break;
        }

        return (
            <div id={'imgBtn'} style={{
                /*   position: 'absolute',
                   top: 0,
                   zIndex: 100001,
                   left: 46,*/
                width: 40,
                height: 55,
                /* opacity: 0,*/
                display: 'none'
            }}>
                <MyImgPicker
                    accept={accept}
                    onAddImageClick={this.addImage}
                    files={this.state.videoFiles}
                    length={1}
                    onChange={this.directUpload}
                    selectable={true}
                    multiple={true}
                    disableDelete={true}
                />
            </div>
        )
    }

    uploadCallBack(data) {
        data.forEach(item => {
            let imgStringDome = '';
            switch (this.state.uploadType) {
                case 2:
                    imgStringDome = '<p><img src="' + item.path + '" width="'+this.state.imgDefaultWidth+'"><br></p>';
                    editor.cmd.do('insertHTML', imgStringDome);//图片插入到当前光标位置*/
                    break;
                case 3:
                    //accept = "." + [...VIDEO_TYPE].join(",.");
                    imgStringDome = '<p><video ' +
                        'vsbmp4video="true" '+
                        'poster="" ' +
                        'preload="auto"  ' +
                        'playsinline="true" ' +
                        'x-playsinline="true" ' +
                        'controls ' +
                        'webkit-playsinline="true" ' +
                        'x-webkit-airplay="true" ' +
                        'x5-video-player-type="h5" ' +
                        'x5-video-orientation="h5"' +
                        'x5-video-player-fullscreen="true" ' +
                        'src="' + item.path + '" ' +
                        'ue_src="' + item.path + '" '+
                        'vsburl"' + item.path + '" '+
                        'title="' + item.name + '" '+
                        'width="420" height="280"></video><br></p>';
                    editor.cmd.do('insertHTML', imgStringDome);//图片插入到当前光标位置*/
                    break;
                case 4:
                    break;
            }

        });
    }


    renderModalAddImg() {
        let accept = 'image/*';
        return (
            <div>
                <Modal
                    style={{zIndex: 10002, width: '100%',}}
                    visible={this.state.imgVisible}
                    transparent
                    maskClosable={false}
                    onClose={() => {
                        this.setState({imgVisible: false})
                    }}
                    closable={true}
                    title="插入图片"
                    footer={[{
                        text: '确定', onPress: () => {
                            this.insertImage()
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}
                >
                    <div style={{height: 400, overflow: 'scroll'}}>
                        <ImagePicker
                            id={'imgBtn'}
                            accept={accept}
                            length={4}
                            /* onAddImageClick={this.addImage}*/
                            files={this.state.files}
                            onChange={this.upload}
                            selectable={true}
                            multiple={true}
                            disableDelete={true}
                        />
                    </div>
                </Modal>
            </div>
        )
    }

    directUpload = (files) => {
        for (var i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                    window.loading.show();
                    let type = files[i].file.type.split('/')[1];
                    switch (this.state.uploadType) {
                        case 2:
                            if(PIC_TYPE.indexOf(type) === -1) {
                                window.loading.hide();
                                break;
                            };
                            uploadimage(data, res => {
                                window.loading.hide();
                                let imgStringDome = '';
                                imgStringDome = '<p><img src="' + res.url + '" width="'+this.state.imgDefaultWidth+'"><br></p>';
                                editor.cmd.do('insertHTML', imgStringDome);//图片插入到当前光标位置*/
                            });
                            break;
                        case 3:
                            if(VIDEO_TYPE.indexOf(type) === -1) {
                                window.loading.hide();
                                break;
                            };
                            uploadvideo(data, res => {
                                window.loading.hide();
                                let imgStringDome = '';
                                imgStringDome = '<p><video ' +
                                    'vsbmp4video="true" '+
                                    'poster="" ' +
                                    'preload="auto"  ' +
                                    'playsinline="true" ' +
                                    'x-playsinline="true" ' +
                                    'controls ' +
                                    'webkit-playsinline="true" ' +
                                    'x-webkit-airplay="true" ' +
                                    'x5-video-player-type="h5" ' +
                                    'x5-video-orientation="h5"' +
                                    'x5-video-player-fullscreen="true" ' +
                                    'src="' + res.url + '" ' +
                                    'ue_src="' + res.url + '" '+
                                    'vsburl="' + res.url + '" '+
                                    'title="' + res.original + '" '+
                                    'width="420" height="280"></video><br></p>';
                                editor.cmd.do('insertHTML', imgStringDome);//图片插入到当前光标位置*/
                            });
                            break;
                        case 4:
                            break;
                    }

                }
            }
        }

    };

    //上传
    upload = (files) => {
        console.log(files)
        this.setState({files})

    };

    insertImage() {
        let files = this.state.files
        for (var i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                    uploadimage(data, res => {
                        let imgStringDome = '';
                        imgStringDome = '<p><img src="' + res.url + '" width="'+this.state.imgDefaultWidth+'"><br></p>';
                        editor.cmd.do('insertHTML', imgStringDome);//图片插入到当前光标位置*/
                    })
                }
            }
        }
        this.setState({imgVisible: false})

    }
}

class ImgBtn extends BtnMenu {
    constructor(editor) {
        const $elem = E.$(
            `<div class="customImg" style="width: 40px;text-align: center;cursor: pointer;display: flex"><img style="width:14px;height:14px;margin: auto" src="images/pictureIcon.png" alt=""/></div>`
        );
        super($elem, editor)
    }

    // 菜单点击事件
    clickHandler(event) {
        editor.imgClick(event);
    }

    tryChangeActive() {
        this.active()
    }
}

class VideoBtn extends BtnMenu {
    constructor(editor) {
        const $elem = E.$(
            `<div class="customImg" style="width: 40px;text-align: center;cursor: pointer;display: flex"><img style="width:14px;height:14px;margin: auto" src="images/play.png" alt=""/></div>`
        );
        super($elem, editor)
    }

    // 菜单点击事件
    clickHandler(event) {
        editor.videoClick(event);
    }

    tryChangeActive() {
        this.active()
    }
}

class LinkUrl extends BtnMenu {
    constructor(editor) {
        const $elem = E.$(
            `<div class="customImg" style="width: 80px;text-align: center;cursor: pointer;line-height: 40px"><button>链接稿件</button></div>`
        );
        super($elem, editor)
    }

    // 菜单点击事件
    clickHandler(event) {
        editor.LinkUrlClick(event);
    }

    tryChangeActive() {
        this.active()
    }
}