import React, {Component} from 'react'
import {Flex, NavBar, WhiteSpace, WingBlank, Toast, Icon} from "antd-mobile";
import {formatDate, uuid} from "../../utils";
import {getInfoFromLocalStorage, getMessageDetail, getNotificationById, setReadMessageStatus} from "../../action";
import {Link} from "react-router-dom";

/**
 * 消息详情列表页面
 */
class MessageDetailView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showView: false,
            history: require('history').createHashHistory,
            messageType: this.props.match.params.messageType,
            messageId: this.props.match.params.messageId,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            notice: {},
            goBack: true
        }
    }

    goBack() {
        var url = "/messageDetailList/" + this.state.messageType;
        this.state.history().push(url);

    }

    componentWillMount() {

        let formdata = new FormData();
        Toast.loading("加载中");
        formdata.append("messageId", this.state.messageId);
        getMessageDetail(formdata, this.updateMessageDetail.bind(this));
    }

    updateMessageDetail(data) {
        Toast.hide();
        //表示消息已读取的状态
        var paramForm = new FormData();
        paramForm.append("messageType", this.props.match.params.messageType);
        paramForm.append("messageId", this.props.match.params.messageId);
        setReadMessageStatus(paramForm, this.updateMessageStatus.bind(this));
        if(data.type==5){
            var notificationForm = new FormData();
            notificationForm.append('notificationid', data.noticficationId);
            getNotificationById(notificationForm,(res)=>{
                this.setState({
                    showView: true,
                    notice: res,
                })
            });
        }else{
            this.setState({
                showView: true,
                notice: data,
            })
        }

    }

    /**
     * 刷新页面的读取状态
     * @param data
     */
    updateMessageStatus(data) {
        if (data && data['errorCode'] === 0) {

        } else {
            Toast.fail("服务异常", 2)
        }
    }

    render() {
        const obj = this.state.notice;
        var icon_type_element = null;
        var messageType = null;
        var backcolor = null;
        var detail = "";
        switch (obj.type) {
            case 1:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-story.png"}/>;
                messageType = '稿件';
                backcolor = '#FF7953';
                detail = <Link to={"/auditStoryTabs/auditStoryTabs"}><div style={{textAlign: 'center'}}>详 情</div></Link>;
                break;
            case 2:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-story.png"}/>;
                messageType = '约稿';
                backcolor = '#FF7953';
                detail = <Link
                    to={"/manuscriptPage/manuscriptPage/" + this.state.isContributeRoleFlag + '/' + this.state.goBack}>
                    <div style={{textAlign: 'center'}}>详 情</div>
                </Link>;
                break;
            case 3:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-newclue.png"}/>;
                messageType = '线索';
                backcolor = '#A652E8';
                detail = <Link
                    to={"/newsCluePage/newsCluePage/" + this.state.isContributeRoleFlag + '/' + this.state.goBack}>
                    <div style={{textAlign: 'center'}}>详 情</div>
                </Link>;
                break;
            case 4:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-intervier.png"}/>;
                messageType = '任务';
                backcolor = '#4677F3';
                detail = <Link
                    to={"/taskManagement/taskManagement/"}>
                    <div style={{textAlign: 'center'}}>详 情</div>
                </Link>;
                break;
            case 5:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-notice.png"}/>;
                messageType = '公告';
                backcolor = '#FF7953';
                detail = <div></div>;
                break;
            default:
                icon_type_element = <img style={{height: '40px'}} src={"images/icon-message.png"}/>;
                messageType = '';
                backcolor = '#00BA85';
        }
        return this.state.showView ? <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}
            ><span className={'nav_title'}>{this.state.messageTypeName}{messageType}消息详情</span></NavBar>
            <WhiteSpace size="lg"/>
            <WingBlank style={{margin:'5px'}}>
                <div style={{borderRadius: '8px', padding: '17px 16px', backgroundColor: '#ffffff'}} className={'message_list'}>
                    <Flex>
                        <Flex.Item style={{flex: 4}}>
                            <Flex direction={'column'} style={{width: '100%'}}>
                                <Flex.Item style={{width: '100%'}}>
                                    <span className={'message_title'}
                                          dangerouslySetInnerHTML={{__html: obj.title}}
                                    ></span>
                                   </Flex.Item>
                                <Flex.Item style={{marginTop: '10px'}}>
                                    <div className={'message_content'} style={{color: '#A6A6A6'}}
                                         dangerouslySetInnerHTML={{__html: obj.content}}></div>
                                </Flex.Item>
                                <Flex.Item style={{width: '100%', marginTop: '10px'}}>
                                    <Flex justify="between">
                                        <div>{obj.senderInfo?obj.senderInfo.name:obj.sender}</div>
                                        <div>{obj.createTime ? formatDate(obj.createTime.time) : ''}</div>
                                    </Flex>
                                </Flex.Item>
                                <Flex.Item style={{width: '100%', marginTop: '10px'}}>
                                    {detail}
                                </Flex.Item>
                            </Flex>
                        </Flex.Item>
                    </Flex>
                </div>
            </WingBlank>
        </div> : <div></div>
    }

}

export default MessageDetailView;
