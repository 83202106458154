import React from "react";
import {ListView, Toast,Icon} from "antd-mobile";
import {getTempStoryDataList} from "./Editor.history";
import {isStringEmpty} from "../../utils";
import {connect} from "react-redux";
import './Editor.historyList.css';
import {NoDataView} from "../NoDataView";
import {setStoryResource, setStory, setCurrentTempStoryId} from "../../store/actions/storyAction";

class EditorHistroyList extends React.Component {

    NUM_ROWS = 10;
    pageIndex = 1;

    genData(pIndex = 0) {
        const dataBlob = {};
        for (let i = 0; i < this.NUM_ROWS; i++) {
            const ii = (pIndex * 10) + i;
            dataBlob[`${ii}`] = `row - ${ii}`;
        }
        return dataBlob;
    }


    constructor(props) {
        super(props);

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            dataStatus: '加载中...',

            currentDeleteIndex: -1,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentTab.title == '全部') {
            //处理重复数据展示
            this.pageIndex = 1;
            this.rData = []
            this.getPageListData();
        }
    }

    updateStoryData(data) {
        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        };

        //根据返回的数据量确定渲染的行数；
        this.NUM_ROWS=data.data.length;
        this.rData = {...this.rData, ...this.genData(this.pageIndex)};
        this.genData(this.pageIndex);
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            dataStatus: data.count > 0 ? '加载完成' : '暂无数据'
        })

        //自增起始位置
        this.pageIndex++;
    }

    componentWillMount() {
        this.pageIndex = 1;
        this.getPageListData();
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }

    getPageListData() {
        Toast.loading('加载中...', 0, () => {
            console.log('Load complete !!!');
        });
        let data = new FormData();
        data.append('start', 1);
        data.append('size', this.state.pageSize);
        data.append('mediaCode', this.props.currentMediaCode);
        getTempStoryDataList(data, this.updateStoryData.bind(this));

    }

    onEndReached = (event) => {

        // load new data
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }

        Toast.loading('加载中...', 0, () => {
            console.log('Load complete !!!');
        });
        this.setState({isLoading: true});
        //重新请求后台获取数据
        let data = new FormData();
        data.append('start', this.pageIndex);
        data.append('size', 10);
        data.append('mediaCode', this.props.currentMediaCode);
        getTempStoryDataList(data, this.updateStoryData.bind(this));
    }

    recoveryStory(data){
        this.props.setStory(data.story);
        this.props.setStoryResource(data.resources);
        this.props.setCurrentTempStoryId(data._id);
    }
    render() {
        let index = 0;
        const row = (rowData, sectionID, rowID) => {
            if (!this.state.data) {
                return <div></div>;
            }
            if (index > this.state.data.length - 1) {
                return <div></div>;
            }
            const tempStory = this.state.data[index++];
            const obj = tempStory.story;
            return obj ? (
                <div  className={'history-item'}
                      onTouchStart={() => this.recoveryStory(tempStory)}
                      onDoubleClick={()=>{this.recoveryStory(tempStory)}}>
                        <div key={rowID} style={{padding: '15px',position: 'relative'}}>
                            <div style={{}}>
                                <div
                                    style={{
                                        fontSize: 14,
                                        color: 'black',
                                        wordBreak:'break-all'
                                    }}
                                >{obj.title}</div>
                                <div style={{
                                        color: '#888',
                                        fontSize: 12,
                                        wordBreak:'break-all',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >{isStringEmpty(obj.summary) ? '' : obj.summary}</div>
                             </div>
                            <div>
                            </div>
                        </div>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            borderTop: '1px solid #ECECED',
                            borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>
            ) : <div></div>;
        };

        return this.state.count > 0 ? (
            <div>
                <ListView
                    initialListSize={10}
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (<div style={{textAlign: 'center'}}>
                        {this.state.isLoading ? '加载中...' : '加载完成'}
                    </div>)}

                    renderRow={row}

                    style={{ position: 'relative', overflow: "auto", height: '30vh'}}

                    pageSize={10}
                    // onScroll={() => {
                    // }}
                    // scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                />
            </div>
        ) : <NoDataView content={this.state.dataStatus}/>;
    }
}

/**
 * 将state属性值映射到props
 * @param state
 * @returns {{storyId: *}}
 */
const stateToProps = (state) => {
    return {
        storyId: state.storyReducer.storyId,
        currentMediaCode: state.storyReducer.currentMediaCode
    }

}

const dispatchToProps = (dispatch) => {
    return {
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryResource:function (resources) {
            dispatch(setStoryResource(resources));
        },
        setCurrentTempStoryId:function(tempStoryId){
            dispatch(setCurrentTempStoryId(tempStoryId));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditorHistroyList);
