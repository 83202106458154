import React, {Component} from 'react'
import {Tabs, WingBlank, NavBar, Icon} from 'antd-mobile';
import {getInfoFromLocalStorage, getMyTabdata} from "../action";
import ReviewListViewData from "./ReviewListViewData";
import HavereviewedDataList from "./HavereviewedDataList";
import {uuid} from "../utils";

/**
 * 稿件审核页面
 */
class AuditStoryTabs extends Component {

    constructor(props) {
        super(props);
        let mediaCode = getInfoFromLocalStorage('currentMediaCode');
        let isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
        this.state = {
            tabsTitle: [],
            pageName: '稿件审核',
            history: require("history").createHashHistory,
            currentTabIndex: 0,
            isContributeRoleFlag: isContributeRoleFlag,
        }
    }

    componentDidMount() {
        getMyTabdata({}, this.updateTabsTittle.bind(this));
    }

    updateTabsTittle(data) {
        let tablesTab = data.audit.storyTab;
        this.setState({
            tabsTitle: tablesTab
        });
    }

    /**
     * tab页面切换，重新加载数据
     * @param tab
     * @param index
     */
    onTabClickTo(tab, index) {

        //重新复制一个，否则点击同一个tab页签子组件监听不到属性的变化，
        var tab1 = {...tab};
        this.setState({
            currentTab: tab1,
            currentTabIndex: index
        })
    }

    /**
     * 返回按钮事件
     */
    goBack() {
        let path = '/index/homeTab';
        this.state.history().push(path);
    }


    render() {
        return <div className={'myTabs'}>
            <NavBar
                mode="light"
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[<span key={uuid()} className={'nav_goback'}>返回</span>]
                }
                onLeftClick={() => this.goBack()}
                rightContent={[]}
            ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>
            <Tabs tabs={this.state.tabsTitle}
                  initialPage={0}
                  onChange={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
                  onTabClick={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
            >
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{margin:'5px'}}>
                        {this.state.currentTabIndex === 0 ?
                            <ReviewListViewData mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                          storyAudit={true}      isContributeRoleFlag={this.state.isContributeRoleFlag}/> : <div></div>}
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{margin:'5px'}}>
                        {this.state.currentTabIndex === 1 ?
                            <HavereviewedDataList mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                                  isContributeRoleFlag={this.state.isContributeRoleFlag}/> :
                            <div></div>}
                    </WingBlank>
                </div>

            </Tabs>

        </div>
    }

}

export default AuditStoryTabs;

