import React, {Component} from 'react';
import {
    Card, List,
    Modal,
    TextareaItem, WingBlank
} from "antd-mobile";
import {connect} from "react-redux";
import {createForm} from 'rc-form';
import Toast from "antd-mobile/lib/toast";
import { setActRemark,} from "../store/actions";

/**
 * 审核流备注信息组件
 */
function closest(el, selector) {
    const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
    while (el) {
        if (matchesSelector.call(el, selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

class Remark extends Component {

    constructor(props) {
        super(props)
        this.state = {
            toShowRemark: false,
        }
    }


    componentWillReceiveProps(nextProps, nextContext) {
        //代表需要写批注
        if (nextProps.actRemark.show) {
            this.setState({
                toShowRemark: true,
            })
        }
    }

    /***
     * 确定按钮的事件，校验数据
     */
    remarkOkFunction() {
        //获取稿件的属性数据
        let type = this.props.actRemark.type;
        let remarkValue = this.props.form.getFieldValue('remark');
        if (!remarkValue||remarkValue.length < 1) {
            Toast.info('请填写审批意见!', 2, null, false);
            return;
        } else if (remarkValue.length > 100) {
            Toast.info('备注请勿超过100字!', 2, null, false);
            return;
        } else {
            var callbackValue = {};
            if (type == "activitiUsers" || type == "users") {
                callbackValue.activitiUsers = remarkValue;
            } else if (type == "remarks") {
                callbackValue.remarks = remarkValue;
            }
            callbackValue.type = type;
            this.props.actRemark.callback(callbackValue);
        }

        this.setState({
            toShowRemark: false
        })
    }

    /**
     * 处理控制台标错信息的
     * @param e
     */
    onWrapTouchStart = (e) => {
        // fix touch to scroll background page on iOS
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.am-modal-content');
        if (!pNode) {
            e.preventDefault();
        }
    }

    componentWillUnmount() {
        let remarkAction={
            show:false,
            data:[],type:'',callback:null,
        }
        this.props.setActRemark(remarkAction);
    }

    remarkCancelFunction(){
        this.setState({
            toShowRemark: false
        })
    }

    render() {
        const {getFieldProps} = this.props.form;
        const title=<div style={{fontsize:'10px', textAlign:'center'}}>审批意见</div>
        return <div>
            <Modal
                visible={this.state.toShowRemark}
                transparent
                maskClosable={false}
                // title="备注"
                footer={[{
                    text: '确定', onPress: () => {
                        this.remarkOkFunction()
                    }
                },
                    {
                        text: '取消', onPress: () => {
                            this.remarkCancelFunction()
                        }
                    }]}
                wrapProps={{onTouchStart: this.onWrapTouchStart}}
            >
                <div >
                    <div>{title}</div>
                    <div>
                            <TextareaItem style={{fontSize:'15px'}}
                                {...getFieldProps('remark')}
                                title=""
                                placeholder="请填写审批意见"
                                // data-seed="logId"
                                // autoHeight
                                rows={5}
                                maxLength={100}
                            />
                    </div>
                </div>
            </Modal>
        </div>
    }
}

const stateToProps = (state) => {
    return {
        actRemark: state.storyReducer.actRemark
    }
}

const dispatchToProps=(dispatch)=>{
    return {
        setActRemark: function (actRemark) {
            dispatch(setActRemark(actRemark));
        }
    }

}

const Remarks = createForm()(Remark);

export default connect(stateToProps, dispatchToProps)(Remarks);
