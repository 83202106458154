/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './TaskDetails.css'
import {Icon, WhiteSpace, TextareaItem, Popover, Modal, NavBar} from 'antd-mobile';
import {formatDate, uuid} from "../../utils";

import task from '../taskManagement/img/task.png'
import bg from '../taskManagement/img/bg.png'

import Upload from "../../components/upload/Upload";

import * as Actions from "../../action/taskManagement";
import {Enumeration} from "../../utils/Enumeration";
import {PIC_TYPE} from "../../constants";
import {removeInfoFromLocalStorage} from "../../action";
const Item = Popover.Item;
export default class TaskDetails extends Component {
    static defaultProps = {};
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            PopoverVisible: false,
            showList:false,
            viewPicture:false,
            id:'',
            dataSource:{},
            roleName:[],
            eventItem:[],
            reporterType:[],
            moreRol:false,
            moreMediaNameList:false,
            mediaNameList:[],
            drawBtn:false,
            authorityBtn:[],
            isCreat:false,
            attachmentsList:[],
            img:'',
            deleteAuth:false,
            leftbtn:false,
            btnAdd:'',
            lookStatus:false,
        }
    }

    componentWillMount() {
    }

    componentDidMount() {

        //任务稿件是没有媒体的code
        removeInfoFromLocalStorage("currentMediaCode");
        let id = this.props.match.params.storyId;
        this.setState({id:id},()=>{
            this.getDetsils();
            this.isDrawBtn();
            this.isDelete()
        });
    }


    render() {
        let num=this.state.eventItem?this.state.eventItem.length:0;
        let operateBth=<div style={{color: '#7c53c9'}}></div>;
        if(this.state.deleteAuth){
            operateBth=<div style={{color: '#7c53c9'}}>操作</div>;
        }
        let bgColor,statusName;
            switch(this.state.dataSource.interview &&this.state.dataSource.interview.status){
                case Enumeration.notReceived.statusType : statusName= Enumeration.notReceived.statusName;bgColor=Enumeration.notReceived.bgColor; break;
                case Enumeration.inProgress.statusType : statusName= Enumeration.inProgress.statusName;bgColor=Enumeration.inProgress.bgColor; break;
                case Enumeration.Submit.statusType : statusName= Enumeration.Submit.statusName;bgColor=Enumeration.Submit.bgColor; break;
                case Enumeration.complete.statusType : statusName= Enumeration.complete.statusName;bgColor=Enumeration.complete.bgColor; break;
                case Enumeration.overdue.statusType : statusName= Enumeration.overdue.statusName;bgColor=Enumeration.overdue.bgColor; break;
            }
        return (
            <div className={'TaskDetailsBox'}>
                <NavBar
                    mode={'light'}
                    style={{position:'fixed', width: '100%', lineHeight: '45px', zIndex:'999'}}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'}  onClick={() => this.goBack()} key={uuid()}>返回</div>
                    ]}

                    rightContent={[
                        <Popover mask
                                 visible={this.state.PopoverVisible}
                                 overlay={[
                                     (<Item key="4" value="scan" data-seed="logId">删除任务</Item>),
                                 ]}
                                 onSelect={this.onSelect}
                        >
                            {operateBth}
                        </Popover>
                    ]}
                ><span  className={'nav_title'}>任务管理</span></NavBar>
                <div style={{
                    lineHeight: '16vw',
                    backgroundColor: '#1890fe',
                    padding: "0 4vw",
                    color: 'white',
                    position: 'relative',
                    overflow: 'hidden',
                    top:'50px'
                }}>
                    <img src={task} style={{width: '6vw', height: '7vw', position: 'absolute', top: '5vw'}}/>
                    <img src={bg}
                         style={{position: 'absolute', height: '150%', right: 0, objectFit: 'cover', bottom: 0,}}/>
                    <span
                        style={{marginLeft: '9vw'}}>{this.state.dataSource.interview && this.state.dataSource.interview.leixing}</span>
                    <span style={{
                        padding: '1px 5px',
                        backgroundColor: bgColor,
                        borderRadius: '25px',
                        fontSize: '3vw',
                        marginLeft: 5
                    }}>{statusName}</span>
                </div>
                <div className={'TaskDetailsNormalBox border'} style={{marginTop:'14vw'}}>
                    <div>任务名称</div>
                    <div style={{wordBreak:'keep-all', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'60%',textAlign: 'right'}}>{this.state.dataSource.interview && this.state.dataSource.interview.title}</div>
                </div>
                <div className={'TaskDetailsNormalBox border'}>
                    <div>所属项目</div>
                    <div style={{width:'70vw', overflow:'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap',textAlign: 'right'}}>
                        {this.state.eventItem && this.state.eventItem.length > 1 ?<div style={{display:'flex', justifyContent:'space-between'}}>
                            <div >{(this.state.eventItem.length&&this.state.eventItem[0].name)}</div>
                            <div style={{display:'inline-block'}}>等{num}个项目</div></div>
                            :(this.state.eventItem&&this.state.eventItem.length===1&&this.state.eventItem[0].name)}
                    </div>
                </div>
                <div className={'TaskDetailsNormalBox'}>
                    <div>我的角色</div>
                    <div>
                        {
                            this.state.dataSource && this.state.dataSource.roleName && this.state.dataSource.roleName.map((item, index) => {
                                let border, color, statusName;
                                //根据返回的数字判断角色
                                switch (item) {
                                    case 1:
                                        statusName = '文字记者';
                                        border = 'solid 1px #ffa82b';
                                        color = '#ffa82b';
                                        break;
                                    case 2:
                                        statusName = '摄影记者';
                                        border = 'solid 1px #35ce84';
                                        color = '#35ce84';
                                        break;
                                    case 3:
                                        statusName = '摄像记者';
                                        border = 'solid 1px #5a63e6';
                                        color = '#5a63e6';
                                        break;
                                    case 4:
                                        statusName = '美工包装';
                                        border = 'solid 1px #fb6dd5';
                                        color = '#fb6dd5';
                                        break;
                                }
                                return (<span key={index} style={{
                                    width: '15vw',
                                    height: '5vw',
                                    border: border,
                                    color: color,
                                    fontSize: '3vw',
                                    padding: '2px',
                                    margin: '0 2px'
                                }}>{statusName}</span>)
                            })
                        }
                    </div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox border'}>
                        <div>活动地点</div>
                        <div style={{overflow:'hidden',textOverflow: 'ellipsis',whiteSpace:'nowrap',width:'60%',textAlign: 'right'}}>{this.state.dataSource.interview && this.state.dataSource.interview.place}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox border'}>
                        <div>活动时间</div>

                        <div>{this.state.dataSource&&this.state.dataSource.interview && this.state.dataSource.interview.interviewDate}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox'}>
                        <div>回稿时间</div>
                        <div>{this.state.dataSource&&this.state.dataSource.interview && this.state.dataSource.interview.endDate}</div>
                    </div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox border'}>
                        <div>主办单位</div>
                        <div style={{overflow:'hidden',textOverflow: 'ellipsis',whiteSpace:'nowrap',width:'60%',textAlign: 'right'}}>{this.state.dataSource.interview && this.state.dataSource.interview.sponsor}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox border'}>
                        <div>活动联系人</div>
                        <div style={{overflow:'hidden',textOverflow: 'ellipsis',whiteSpace:'nowrap',width:'60%',textAlign: 'right'}}>{this.state.dataSource.interview && this.state.dataSource.interview.contacts}</div>
                    </div>
                    <div className={'TaskDetailsNormalBox'}>
                        <div>联系方式</div>
                        <div>{this.state.dataSource.interview && this.state.dataSource.interview.contactsWay}</div>
                    </div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox'}>
                        <div>任务内容</div>
                    </div>
                    <div className={'inputBox'}>
                        <style>{`.inputBox .am-textarea-control textarea{font-size:4vw}`}</style>
                        <TextareaItem
                            rows={3}
                            disabled={true}
                            value={this.state.dataSource.interview && this.state.dataSource.interview.content}
                            placeholder="请输入您的意见或建议！我们的成长离不开您的帮助与支持，再次感谢。"
                        />
                    </div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>拟发平台</div>
                        {
                            this.state.mediaNameList&&this.state.mediaNameList.length>3?<div style={{color: '#1890fe'}} onClick={() => {this.setState({moreMediaNameList:!this.state.moreMediaNameList})}}>查看更多</div>:null
                        }

                    </div>
                    <div style={{
                        display: 'flex',
                        background: "white",
                        padding: '0vw 4vw 4vw 4vw',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start'
                    }}>
                        {
                            this.state.mediaNameList&&this.state.mediaNameList.map((item,index)=>{
                                if(!this.state.moreMediaNameList){
                                    if(index>2){
                                        return
                                    }
                                }
                                let bg;
                                switch (item.type) {
                                    case 1:
                                        bg ='#5073d5';
                                        break;
                                    case 2:
                                        bg ='#4ec78a';
                                        break;
                                    case 3:
                                        bg ='rgb(255, 104, 104)';
                                        break;
                                    case 4:
                                        bg ='#7c53c9';
                                        break;
                                    case 5:
                                        bg ='#fb6dd5';
                                        break;
                                    case 6:
                                        bg ='#fbce02';
                                        break;
                                }
                               return( <div key={index} style={{
                                   padding: '2vw 3vw',
                                   width:'20vw',
                                   textAlign:'center',
                                   borderRadius: '25px',
                                   backgroundColor: bg,
                                   color: 'white',
                                   overflow:'hidden',textOverflow: 'ellipsis',whiteSpace:'nowrap',
                                   margin: '2vw'
                               }}>{item.name}
                               </div>)
                            })
                        }
                    </div>
                </div>
                <div>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox '}>
                        <div>分派记者</div>
                        {
                            this.state.reporterType.length?<div style={{color: '#1890fe'}} onClick={() => {this.setState({moreRol:!this.state.moreRol})}}>查看更多</div>:null
                        }
                    </div>
                    {
                        this.state.reporterType && this.state.reporterType.length && this.state.reporterType.map((item, index) => {
                            if(!this.state.moreRol){
                               if(index>0){
                                  return
                               }
                            }
                            let border, color, statusName;
                            //根据返回的数字判断角色
                            switch (item.type) {
                                case '1':
                                    statusName = '文字记者';
                                    border = 'solid 1px #ffa82b';
                                    color = '#ffa82b';
                                    break;
                                case '2':
                                    statusName = '摄影记者';
                                    border = 'solid 1px #35ce84';
                                    color = '#35ce84';
                                    break;
                                case '3':
                                    statusName = '摄像记者';
                                    border = 'solid 1px #5a63e6';
                                    color = '#5a63e6';
                                    break;
                                case '4':
                                    statusName = '美工包装';
                                    border = 'solid 1px #fb6dd5';
                                    color = '#fb6dd5';
                                    break;
                            }
                            return (
                                <div className={'TaskDetailsNormalBox'} style={{lineHeight: '10vw'}} key={index}>
                                    <div style={{width:'30vw'}}><span style={{
                                        width: '16vw',
                                        height: '7vw',
                                        border: border,
                                        color: color,
                                        fontSize: '3vw',
                                        padding: '2px',
                                        margin: '0 2px'
                                    }}>{statusName}</span></div>
                                    <div style={{ overflow:'hidden',textOverflow: 'ellipsis',whiteSpace:'nowrap'}}>
                                        {
                                            item.valList&&item.valList.length&&item.valList.length>0?item.valList.toString().replace(/,/g,'、'):''
                                    }</div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{marginBottom: '15vw'}}>
                    <WhiteSpace size="lg"/>
                    <div className={'TaskDetailsNormalBox border'}>
                        <div>任务附件</div>
                    </div>
                    <div style={{
                        background: 'white',
                        padding: '0vw 4vw 0vw 4vw',
                        lineHeight: '6vw',
                        color: '#999999',
                        fontSize: '3vw'
                    }}>
                    {this.state.attachmentsList&&this.state.attachmentsList.map((item,index)=>{
                        return(
                            <div style={{display: 'flex', lineHeight: '12vw'}} className={'border'} key={index}>
                                <div style={{width: '70%',overflow:'hidden',textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{item.name}</div>
                                {
                                    item.type? <div style={{width: '15%',textAlign: 'center'}} onClick={() => this.watch(item.url)} className={'color'}>查看</div>:<div style={{width: '15%'}}></div>
                                }
                                <div style={{width: '15%',textAlign: 'center'}} className={'color'} onClick={() => this.download(item.url)}>下载</div>
                            </div>
                        )
                    })}
                   </div>
                </div>
                {this.renderBottomBtn()}
                <Upload isUoLoad={this.state.btnAdd}  ref={Upload=>this.upload=Upload} isShowDeleteBtn={true} bindId={this.props.match.params.storyId}/>
                <Modal
                    visible={this.state.viewPicture}
                    transparent
                   style={{width:'87vw'}}
                    maskClosable={false}
                    onClose={this.onClose('modal1')}
                    footer={[{
                        text: '确定', onPress: () => {
                            this.onClose('modal1')();
                        }
                    }]}
                    wrapProps={{onTouchStart: this.onWrapTouchStart}}
                >
                    <div>
                        <img src={this.state.img} style={{width:'80vw',height:'80vw',objectFit: 'contain'}}/>
                    </div>
                </Modal>
            </div>
        );
    }

    //渲染底部按钮
    renderBottomBtn(){

       let lefBtnText='';
       if(this.state.leftbtn){
           lefBtnText= this.state.isCreat?'稿件列表':'创建稿件'
       }else{
           switch (this.state.authorityBtn[0]) {
               case 2:lefBtnText = '任务图片';break;
               case 3:lefBtnText = '任务视频';break;
               case 4:lefBtnText = '任务文件';break;
           }
       }
        return(
            <div>
                {
                    this.state.drawBtn?<div style={{
                        lineHeight: '12vw',
                        backgroundColor: '#1890fe',
                        textAlign: 'center',
                        color: 'white',
                        position: 'fixed',

                        width: '100%',
                        bottom: '0'
                    }} onClick={() =>{this.draw()}}>领取任务
                    </div>:null
                }
                {
                    this.state.authorityBtn&&this.state.authorityBtn.length<2?<div style={{
                        lineHeight: '12vw',
                        backgroundColor: '#1890fe',
                        textAlign: 'center',
                        color: 'white',
                        position: 'fixed',
                        width: '100%',
                        bottom: '0'
                    }} onClick={()=>this.contList()}>{lefBtnText}
                    </div>:null
                }
                {
                    this.state.authorityBtn&&this.state.authorityBtn.length>1?<div style={{display:'flex',lineHeight:'12vw',textAlign:'center',color:'white',background:'white',position:'fixed',width:'100%',bottom:'0'}}>
                        <div style={{width:'85%',backgroundColor:'#1890fe'}} onClick={()=>this.contList()}>{lefBtnText}</div>
                        <div style={{width:'15%',backgroundColor:'#1890fe',marginLeft:5}}>
                            <Popover
                                placement ={'topRight'}
                                visible={this.state.visible}
                                overlay={[
                                    <div className={'footer'} style={{width:'50vw',position:'fixed',bottom:'50px',right:10,background:'white',zIndex:'2',boxShadow:' 0vw 2vw 4vw 0vw rgba(124, 83, 201, 0.09)'}}>
                                        <style>{` .am-popover-arrow{display:none} 
                                   .am-popover-item-content{display: inline-block;width: 100%;text-align: center;}`}</style>
                                        {this.state.authorityBtn.map((item,index)=>{
                                            let name;
                                            //根据返回的数字判断角色
                                            switch (item) {
                                                case 1:name = this.state.isCreat?'稿件列表':'创建稿件';break;
                                                case 2:name = '任务图片';break;
                                                case 3:name = '任务视频';break;
                                                case 4:name = '任务文件';break;
                                            }
                                            return( <Item key={index} data-seed="logId">{name}</Item>)
                                        })}
                                    </div>
                                ]}
                                onVisibleChange={this.handleVisibleChange}
                                onSelect={this.onSelectItem}
                            >
                                <div style={{
                                    height: '100%',
                                    padding: '0 15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                >
                                    <Icon type="ellipsis" />
                                </div>
                            </Popover>
                        </div>

                    </div>:null
                }
            </div>
        )
    }
    //选择上传视频  还是图片 附件
    onSelectItem = (opt) => {
      if(opt.props.children === '稿件列表'){
          this.props.history.push('/contributions/contributions/'+ this.state.id+'/'+ this.state.btnAdd)
      }else if(opt.props.children === '创建稿件'){
          this.props.history.push('/taskInterview/writeStory/'+ 0 +'/'+this.state.id+'/5')
      }else if(opt.props.children === '任务图片'){
          this.upload.show(2)
      }else if(opt.props.children === '任务视频'){
          this.upload.show(3)
      }else if(opt.props.children === '任务文件'){
          this.upload.show(4)
      }
        this.setState({visible:false});
    };
    contList(){
        if(this.state.authorityBtn[0] === 1){
            if(this.state.isCreat === true){
                this.props.history.push('/contributions/contributions/'+ this.state.id+'/'+this.state.btnAdd)
            }else{
                this.props.history.push('/taskInterview/writeStory/'+ 0 +'/'+this.state.id+'/5')
            }
        }else if(this.state.authorityBtn[0] === 2){
            this.upload.show(2)
        }else if(this.state.authorityBtn[0] === 3){
            this.upload.show(3)
        }else if(this.state.authorityBtn[0] === 4){
            this.upload.show(4)
        }
    }
    //获取详情页数据
    getDetsils(id){
        let imgAccept = [...PIC_TYPE,].join(",");
        Actions.taskListDetails({interviewId:this.state.id}, (data) => {
            let reporterType=[];
            if(data){
                for (let i in data.reporterType) {
                    reporterType.push({
                        type:i,
                        valList:data.reporterType[i]
                    });
                }
            }
            data.reporterType=reporterType;
            if(data.interview && data.interview.attachmentResourceIds){
                Actions.enclosure({attachmentIds:data.interview.attachmentResourceIds}, (data) => {
                 if(data){
                     data.forEach((item,index)=>{
                         let nameLength=item.name.split(".").length;
                         if(imgAccept.indexOf(item.name.split(".")[nameLength-1])>-1){
                             item.type= 1
                         }});
                     this.setState({attachmentsList:data})
                 }

                });
            }
            this.setState({dataSource:data,eventItem:data.eventItem,reporterType:reporterType,mediaNameList:data.mediaNameList})

        });
    }
    //判断是否有删除的权限
    isDelete(){
        Actions.isHaveDeleteAuth({interviewId:this.state.id}, (data) => {
          this.setState({deleteAuth:data})
        });
    }

    //判断是否有领取任务的权限(第一步)
    isDrawBtn(){

        Actions.receive({interviewId:this.state.id}, (data) => {
            if(data === true){
                this.isReceived()
            }else{
              //如果是false的时候，验证他有没有查看权限
                Actions.isCheckAuth(res=>{
                   if(res){
                       this.state.lookStatus=true;
                       this.isReceived()
                   }
                })
            }
        });
    }
    //判断是否领取过（第二步）
    isReceived(){
        Actions.isReceived({interviewId:this.state.id}, (data) => {
            if(data === true){
                this.isJurisdiction()
            }else{
                if(this.state.lookStatus){
                    this.isJurisdiction()
                }else{
                    this.setState({drawBtn:true})
                }
            }
        });
    }
    //判断权限
    isJurisdiction(){
        Actions.isJurisdiction({interviewId:this.state.id}, (data) => {
            if(data){
                if(data.list.indexOf(1)>-1){
                    this.setState({leftbtn:true})
                }
               if(data.interviewStatus<4){
                   this.setState({btnAdd:true})
               }else if(data.interviewStatus>=4){
                   this.setState({btnAdd:false})
               }
                this.setState({authorityBtn:data.list,isCreat:data.iscreat,drawBtn:false});
            }
        });
    }
    //领取任务
    draw(){
        Actions.draw({id:this.state.id}, (data) => {
            if(data === true){
                this.getDetsils();
                this.isJurisdiction()
            }
        });
    }
    //返回按钮
    goBack(){
        this.props.history.goBack();
    }
    //下载
    download(data){
        /*  console.log(data)
          let a = document.createElement('a');
          //需要下载的数据内容,我这里放的就是BLOB，如果你有下载链接就不需要了
          let url = data;
          console.log(url)
          let filename =url;
          a.href = url;
          a.download = filename;
          a.click();*/
        // window.URL.revokeObjectURL(url);
        window.location.href = data + "?download=1";
    }
    //删除
    onSelect=(e)=>{
       if(e.key === '4'){
           Actions.deleteTask({id:this.state.id}, (data) => {
                if(data){
                   this.props.history.replace('/taskManagement/taskManagement')
               }
           });
       }
};
    //查看图片
    watch(url){
       this.setState({viewPicture:true,img:url})
    }
    //关闭查看图片的弹框
    onClose = key => () => {
        this.setState({viewPicture: false,});
    };
}