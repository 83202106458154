/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as Actions from "../../action/topiclist";
import {
    Icon,
    InputItem,
    Tag,
    TextareaItem,
    Progress,
    Button,
    WingBlank,
    WhiteSpace,
    Toast,
    NavBar,
    ImagePicker, Modal
} from "antd-mobile";
import './AddTopicList.css'
import {ATTCHMENT_TYPE, PIC_TYPE, VIDEO_TYPE} from "../../constants/index"
import AddressBook from "../../components/addressBook/AddressBook";
import {getInfoFromLocalStorage, uploadAttachmentFile, uploadimage, uploadvideo} from "../../action";
import {ArrayDuplication} from "../../utils";
import add from "./img/add.png";
import MyImgPicker from "../../components/MyImgPicker";


export default class AddTopicList extends Component {
    static defaultProps = {};
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            id:'',
            tsFileIds:[],
            viewPicture:false,
            param:{
                tsName:"",
                tsContactsName:"",
                tsContactsPhone:"",
                tsContent:"",
                creatUserList:[],
                departList:{},
                chooseType:'more',
        }
        }
    }
    componentWillMount() {
    }

    componentDidMount() {
         let id = this.props.match.params.id;
         this.setState({id:id},()=>{
            if(id ==='0'){
                let usrInfo=getInfoFromLocalStorage("userInfo");
                this.setState({
                    creatUserList:[{chName:usrInfo.name,account:usrInfo.account,checked:false}]
                });
            }else{
                this.getData(id)
            }
        });
    }
    render() {
        return (
            <div className={'addTopicList'}>
                <NavBar
                    mode={'light'}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    style={{position:'fixed', width: '100%', lineHeight: '45px',zIndex:999}}
                    leftContent={[
                        <div className={'nav_goback'}  onClick={()=>this.props.history.goBack()}>返回</div>
                    ]}
                ><span  className={'nav_title'}>添加选题</span></NavBar>

                <div className={'addTopic'} >
                   <div className={'selectedTopic border'} style={{marginTop:45}}>
                       <div style={{fontSize:'12px'}}><InputItem
                           placeholder="输入选题名称(必填)"
                           clear
                           value={this.state.param.tsName}
                           onChange={(e)=>this.onChange(e,'tsName')}
                       ><style>{`.am-list-item .am-input-control input{font-size:15px,color:#999999;} `}
                       </style>
                           选题名称</InputItem>
                       </div>
                   </div>
                    <div className={'selectedTopic border'}>
                        <div style={{fontSize:'12px'}}><InputItem
                            placeholder="选择涉及单位"
                            editable ={false}
                            value={this.state.param&&this.state.param.departList&&this.state.param.departList.chName}
                            onClick={()=>{this.setState({chooseType:'one'},()=>{
                                this.AddressBook.show('depart',[ this.state.param.departList],'chName')}
                                )}}
                        ><style>{`.am-list-item .am-input-control input{color:#999999}`}
                        </style>
                            涉及单位</InputItem>
                        </div>
                    </div>
                    <div className={'selectedTopic border'}>
                        <div style={{fontSize:'12px'}}><InputItem
                            placeholder="输入活动联系人"
                            clear
                            value={this.state.param.tsContactsName}
                            onChange={(e)=>this.onChange(e,'tsContactsName')}
                        ><style>{`.am-list-item .am-input-control input{font-size:15px}.am-list-item .am-input-label{font-size:15px;color:#999999;}                          `}
                        </style>
                            联系人</InputItem>
                        </div>
                    </div>
                    <div className={'selectedTopic'}>
                        <div style={{fontSize:'12px'}}><InputItem
                            placeholder="输入联系方式"
                            clear
                            type={'phone'}
                            value={this.state.param.tsContactsPhone}
                            onChange={(e)=>this.onChange(e,'tsContactsPhone')}
                        ><style>{`.am-list-item .am-input-control input{font-size:15px}.am-list-item .am-input-label{font-size:15px;color:#999999;}                          `}
                        </style>
                            联系方式</InputItem>
                        </div>
                    </div>
                </div>
                <div className={'addTopic'} style={{margin:'5px 0',padding:'5vw',color:'#999999'}}>
                    <div style={{lineHeight:'10vw'}}>创建人</div>
                    <div>
                        {
                            this.state.creatUserList&&this.state.creatUserList.map((item,index)=>{
                              return(<Tag  closable={item.checked} style={{margin:'2vw'}} key={index}  onClose={() => {this.delete(index)}}>
                                  {item.chName}
                              </Tag>)
                            })
                        }
                        <div style={{display:'inline-block',padding:'2px 20px',background:'#1890fe',color:'white',borderRadius:'4px',}} onClick={()=>{this.setState({chooseType:'more'},()=>{this.AddressBook.show('user',[],'chName')})}}>添加</div>
                    </div>
                </div>
                <div className={'addTopic'} style={{margin:'5px 0',color:'#999999'}}>
                    <style>{`.addTopic .am-textarea-control textarea{font-size:4vw}`}</style>
                    <div style={{paddingLeft:'15px',lineHeight:'7vw'}}>选题内容</div>
                    <TextareaItem
                        rows={4}
                        placeholder="输入选题内容"
                        value={this.state.param.tsContent}
                        onChange={(e)=>this.onChange(e,'tsContent')}
                    />
                </div>

                    <div style={{background:'white',padding:'0vw 4vw 25vw 4vw',lineHeight:'6vw',color:'#999999',fontSize:'3vw'}}>
                        {
                            this.state.tsFileIds.length>0&&this.state.tsFileIds.map((item,index)=>{
                                return(
                                    <div style={{display:'flex',lineHeight:'12vw'}} >
                                        <div style={{width:'70%', whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.name}</div>
                                        {
                                           item.watch? <div style={{width:'10%',color:'#1890fe',textAlign:'center'}} onClick={() => this.watch(item.url)} >查看</div>:<div style={{width:'10%',color:'#1890fe'}}></div>
                                        }
                                        <div style={{width:'10%',color:'#1890fe',textAlign:'center'}}  onClick={() => this.download(item.url)}>下载</div>
                                        <div style={{width:'10%'}} onClick={()=>this.deleteFileIds(index)}><Icon type='cross-circle' size={'xxs'} color={'#1890fe'}/></div>
                                    </div>
                                )
                        })
                        }
                        {this.renderAddBtn()}

                    </div>
                <div style={{position: 'fixed',width:'100%',bottom:'0',background:'white',zIndex:999,height:'22vw'}}>
                    <WingBlank><Button type="primary" size="small" onClick={()=>this.submitTopic(2)}>提交选题</Button><WhiteSpace/></WingBlank>
                    <WingBlank>
                        <div style={{display:'flex',justifyContent:'space-between',}}>
                            <Button type="ghost" inline style={{ marginRight: '5%',border:'1px solid #108ee9',width:'45%' }} size="small" className="am-button-borderfix" onClick={()=>this.close()}>关闭</Button>
                            <Button type="primary" inline style={{width:'45%'}} size="small" onClick={()=>this.submitTopic(1)}>保存</Button>
                        </div>
                    </WingBlank>
                </div>
                <Modal
                    visible={this.state.viewPicture}
                    transparent
                    style={{width:'87vw'}}
                    maskClosable={false}
                    onClose={this.onClose('modal1')}
                    footer={[{ text: '确定', onPress: () => { this.onClose('modal1')(); } }]}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                >
                    <div>
                        <img src={this.state.img} style={{width:'80vw',height:'80vw',objectFit:'contain'}}/>
                    </div>
                </Modal>
                <AddressBook ref={AddressBook=>{this.AddressBook=AddressBook}}  onOkClick={(e)=>{this.addressBookCallBack(e)}} choose={this.state.chooseType}/>
            </div>
        )
    }
    //下载
    download(data){
      /*  console.log(data)
        let a = document.createElement('a');
        //需要下载的数据内容,我这里放的就是BLOB，如果你有下载链接就不需要了
        let url = data;
        console.log(url)
        let filename =url;
        a.href = url;
        a.download = filename;
        a.click();*/
        // window.URL.revokeObjectURL(url);
        window.location.href = data + "?download=1";
    }
    //上传
    upload = (files) => {
        let array =[];
        for (let i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                        uploadAttachmentFile(data, res => {
                            let imgAccept = "." + [...PIC_TYPE,].join(",.");
                            let videoAccept = "." + [...VIDEO_TYPE].join(",.");
                            let attchmentAccept = "." + [...ATTCHMENT_TYPE].join(",.");
                            if(imgAccept.indexOf(res.storageFile.suffix)>-1){
                                let params ={
                                    "pictures": [
                                        {
                                            "dataSource": 4,
                                            "name": res.storageFile.name,
                                            "ext": res.storageFile.suffix,
                                            "path": res.url,
                                            "size": res.storageFile.size,
                                            "storyId": 0,
                                            "imageHeight": res.storageFile.height,
                                            "imageWidth": res.storageFile.width
                                        }
                                    ]
                                };
                                Actions.saveStoryFilePicture( params,(data) => {
                                    let newData=data[0];
                                    newData.url=res.url;
                                    newData.watch=true;
                                    array.push(newData);
                                    let dataArray =this.state.tsFileIds.concat(newData)
                                    this.setState({tsFileIds:dataArray})
                                })
                            }
                            if(videoAccept.indexOf(res.storageFile.suffix)>-1){
                                let params ={
                                    "videos": [
                                        {
                                            "dataSource": 4,
                                            "name": res.storageFile.name,
                                            "ext": res.storageFile.suffix,
                                            "path": res.url,
                                            "size": res.storageFile.size,
                                            "storyId": 0,
                                            "frameHeight":res.storageFile.height,
                                            "frameWidth":res.storageFile.width,
                                            "allTime":res.storageFile.duration,
                                        }
                                    ]
                                };

                                Actions.saveStoryFileVideo( params,(data) => {
                                    let newData=data[0];
                                    newData.url=res.url;
                                    array.push(newData);
                                    let dataArray =this.state.tsFileIds.concat(newData)
                                    this.setState({tsFileIds:dataArray})

                                })
                            }
                            if(attchmentAccept.indexOf(res.storageFile.suffix)>-1){
                                let params ={
                                    "otherFiles": [
                                        {
                                            "dataSource": 4,
                                            "name": res.storageFile.name,
                                            "ext": res.storageFile.suffix,
                                            "path": res.url,
                                            "size": res.storageFile.size,
                                            "storyId": 0
                                        }
                                    ]
                                };
                                Actions.saveStoryFileOtherfile( params,(data) => {
                                    let newData=data[0];
                                    newData.url=res.url;
                                    array.push(newData);
                                    let dataArray =this.state.tsFileIds.concat(newData)
                                    this.setState({tsFileIds:dataArray})
                                })
                            }
                        });
                }
            }
        }
    };
    //删除上传的附件
    deleteFileIds(index){
        this.state.tsFileIds.splice(index,1);
        this.setState({});
    }
    //查看
    watch(url){
        this.setState({viewPicture:true,img:url})
    }
    //关闭查看图片的弹框
    onClose = key => () => {
        this.setState({viewPicture: false,});
    };
    //渲染添加按钮
    renderAddBtn() {
        let acceptAll;
               let imgAccept = "." + [...PIC_TYPE,].join(",.");
               let videoAccept = "." + [...VIDEO_TYPE].join(",.");
                let attchmentAccept = "." + [...ATTCHMENT_TYPE].join(",.");
                 acceptAll="*";
        return (
            <div style={{width:'25vw',border:'1px solid #1890fe',lineHeight:'8vw',textAlign:'center',marginLeft:'70%',top:'10px',color:'#1890fe',position:'relative'}}>
                上传附件文件
                <div style={{width:'100vw',height:'8vw', opacity:0,position: 'absolute',top:0,left:0,overflow: 'hidden'}}>
                    <MyImgPicker
                        accept={acceptAll}
                        onAddImageClick={this.addImage}
                        files={this.state.videoFiles}
                        onChange={this.upload}
                        selectable={true}
                        multiple={true}
                        disableDelete={true}
                    />
                </div>
            </div>
        )
    }
    //删除创建人
    delete(index){
        this.state.creatUserList.splice(index,1);
        this.setState({})
    }
    //添加创建人
    onChange(e,key){
        this.state.param[key]= e;
        this.setState({})
    }
    //提交选题
    submitTopic(status){
        let array =[];
        let tsFileIds =[];
        this.state.creatUserList.map((item,index)=>{
            array.push(item.account)
        });
        if(this.state.param.tsName===''){
            Toast.info('选题名称不能为空', 1);
            return
        }

        this.state.tsFileIds.map((item,index)=>{
            tsFileIds.push(item.resourceId?item.resourceId:item.id)
        });
        let params ={
            tsName:this.state.param.tsName,
            tsContactsName:this.state.param.tsContactsName,
            tsContactsPhone:this.state.param.tsContactsPhone,
            tsContent:this.state.param.tsContent,
            tsCreateAccount:array[0],
            tsCompany: this.state.param.departList.chName,
            tsFileIds:tsFileIds,
            tsCreateBy:array,
            tsStatus:status
        };
        if(this.state.id === '0'){
            Actions.add( params,(data) => {
                this.props.history.goBack()
            })
        }else{
            params.id = this.state.id;
            Actions.edit( params,(data) => {
                this.props.history.goBack()
            })

        }
    }
 // 渲染选择好的创建人
 addressBookCallBack(e){
       if(e.type === 'depart'){
           this.state.chooseType = 'one';
           this.state.param.departList = e.list[0];
           this.setState({});
           e.list =[]
      }else if(e.type === 'user'){
           this.state.creatUserList=[...this.state.creatUserList,... e.list];
          let newCreatUserList = ArrayDuplication(this.state.creatUserList,'account');
          this.setState({creatUserList:newCreatUserList,chooseType:'more'})
       }
}
    //关闭
    close(){
        this.props.history.goBack();
    }
     // 修改时获取数据
     getData(){
         Actions.topicDetails({id:this.state.id}, (data) => {
                 this.state.param.tsName = data.tsName;
                 this.state.param.tsContactsName = data.tsContactsName;
                 this.state.param.tsContactsPhone = data.tsContactsPhone;
                 this.state.param.tsContent =data.tsContent;
                 this.state.param.departList.chName =data.tsCompany;
                 Actions.getEnclosure({attachmentIds:data.tsFileIds.join(",")},(resFiles)=>{
                     this.setState({
                         tsFileIds: resFiles
                     })
                 });

                 let usrInfo=getInfoFromLocalStorage("userInfo");
                 let dataArray=[];
                data.tsCreateByName.forEach((item,index)=>{
                    if(data.tsCreateBy[index] ===usrInfo.account ){
                    }else{
                        dataArray.push({chName:item,account:data.tsCreateBy[index],checked:true})
                    }
                });
                let creatUserList=[{chName:usrInfo.name,account:usrInfo.account,checked:false}].concat(dataArray);
                this.state.creatUserList=creatUserList;
                this.setState({})
         });
     }
}