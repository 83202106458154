import React, {Component} from 'react';
import './my_head.css';
import {Link} from "react-router-dom";
import {permissionCheck} from "../../utils";
import ActButton from "../../components/activity/ActButton";
import {getInfoFromLocalStorage} from "../../action";

/**
 * 首页的 消息列表
 */
class HomeList extends Component {
    constructor(props) {
        super(props);
        this.state = {needTodoCount:0}
    }

    actButton = new ActButton();

    componentDidMount() {
        //重新请求后台获取数据
        var currentMediaCode = getInfoFromLocalStorage("currentMediaCode");
        var iniparams = {userName: getInfoFromLocalStorage('userName'), deploymentKey: 'story'};
        this.actButton.init(iniparams, null);
        let param = {deploymentKey: "story"}
        this.actButton.todoList(param,(data)=>{
            if(data){
                this.setState({
                    needTodoCount:data.total
                })
            }
        });
    }
    render() {
        return( <div>
            <div  className={'homeHead'} style={{display: 'flow-root',width:'92vw'}}>
                <ul style={{listStyle:'none',padding:'4vw',margin:'auto',}}>
                    {
                        permissionCheck(":index:tougao") ?
                            <li style={{float:'left'}}>
                                <Link to={'/writeStory/0/0/2/6'}>
                                    <img className="fonction_img" src="images/contribution.png"/>
                                    {this.state.needTodoCount>0?
                                        <div className="red_news paper" style={{left: '5.4em',top: '0.6em'}}>{this.state.needTodoCount}</div> :
                                        ""}
                                    <h3 className={'pageTile'}>投稿</h3>
                                </Link>
                            </li> : ''}
                    {
                        permissionCheck(":index:resourcepool")?
                            <li  style={{float:'left'}}>
                                <Link to={'/resoucePoolPage/resoucePoolPage'}>
                                    <img className="fonction_img" src="images/resource_icon.png"/>
                                    <h3 className={'pageTile'}>投稿稿件</h3>
                                </Link>
                            </li> : ''}

                    {
                        permissionCheck(":index:story") ?
                            <li  style={{float:'left'}}>
                                <Link to={'/listTabPage/listTabPage'}>
                                    <img className="fonction_img" src="images/media .png"/>
                                    <h3 className={'pageTile'}>{this.props.isContributeRoleFlag ? '稿件' : '媒体稿件'} </h3>
                                </Link>
                            </li> : ''}
                    {
                        permissionCheck(":index:newsclue") ?
                            <li  style={{float:'left'}}>
                                <Link
                                    to={"/newsCluePage/newsCluePage/" + this.props.isContributeRoleFlag + '/' + this.state.goBack}>
                                    <img className="fonction_img" src="images/clue.png"/>
                                    <h3 className={'pageTile'}>线索</h3>
                                </Link>
                            </li> : ""}
                    {
                        permissionCheck(":index:manuscripts") ?
                            <li  style={{float:'left'}}>
                                <Link
                                    to={"/manuscriptPage/manuscriptPage/" + this.props.isContributeRoleFlag + '/' + this.state.goBack}>
                                    <img className="fonction_img" src="images/soliciting_contributions.png"/>
                                    <h3 className={'pageTile'}>约稿</h3>
                                </Link>
                            </li> : ''}
                    {
                        permissionCheck(":index:topic:manager") ?
                            <li  style={{float:'left'}}>
                                <Link
                                    to={"/topicList/topicList"}>
                                    <img className="fonction_img" src="images/selected_topic.png"/>
                                    <h3 className={'pageTile'}>选题</h3>
                                </Link>
                            </li> : ""}
                    {
                        permissionCheck(":index:task:manager")?
                            <li  style={{float:'left'}}>
                                <Link to={'/taskManagement/taskManagement'}>
                                    <img className="fonction_img" src="images/task.png"/>
                                    <h3 className={'pageTile'}>任务</h3>
                                </Link>
                            </li> : ''}
                            <li  style={{float:'left'}}>
                                <Link to={'/news/news'}>
                                    <img className="fonction_img" src="images/news.png"/>
                                    <h3 className={'pageTile'}>消息</h3>
                                </Link>
                            </li>

                            <li  style={{float:'left'}}>
                                <Link to={'/mine/mine'}>
                                    <img className="fonction_img" src="images/my.png"/>
                                    <h3 className={'pageTile'}>我的</h3>
                                </Link>
                            </li>
                </ul>
            </div>
            <div style={{clear:'both'}}/>
        </div>)

    }
}

export default HomeList