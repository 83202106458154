import React, {Component} from 'react';
import MediaSelect from "../storyPage/MediaSelect";
import StoryPage from "./StoryPage";
import {getInfoFromLocalStorage} from "../../action";

/**
 * 稿件导航页
 */
class LinkToStoryPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <StoryPage isContributeRoleFlag={getInfoFromLocalStorage("isContributeRoleFlag")}
                          userName={getInfoFromLocalStorage("userName")}></StoryPage>;
    }
}

export default LinkToStoryPage;
