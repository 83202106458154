/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../manuscriptList/ManuscriptList.css'
import { WingBlank, WhiteSpace} from 'antd-mobile';
import picture  from '../../backimages/logo_title.png'
export default class ManuscriptList extends Component {
    static defaultProps = {
        title: '姚小妍',//传入标题

        content: '姚小妍bfsdfsfsdf的方式的开发速度放缓士大夫大师傅的发顺丰门槛较高的法国公司的归属感',//任务内容
        type: '任务来源',//任务来源
        img:picture,//图片,
        name:'姚小妍',//姓名
        createTime:'2020-01-32 12:12:12',
        manuscriptDetails:()=>{
           alert(1)
        }
    };
    static propTypes = {
        title: PropTypes.string.isRequired,//传入标题
        content: PropTypes.string.isRequired,//任务内容
        type: PropTypes.string.isRequired,//任务来源
        name:PropTypes.string.isRequired,//姓名
        createTime:PropTypes.string.isRequired,
        img:PropTypes.string.isRequired,
        manuscriptDetails:PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        const WingBlankExample = () => {
            return (
                <div>
                    <WhiteSpace size="sm"/>
                    <WingBlank size="lg">
                        <div className={'ManuscriptListBox'} onClick={()=>this.props.manuscriptDetails()}>
                            <div className={'flex'}>
                                <img src={this.props.img} style={{width:'15vw',height:'15vw',objectFit:'cover'}}/>
                                <div style={{marginLeft:'4vw',width: '67vw'}}>
                                    <div className={'title textOverflow'}>{this.props.title}</div>
                                    <div className={'content'}>
                                        <div className={'taskContent textOverflow'}  style={{height:'50px',lineHeight:'25px'}} dangerouslySetInnerHTML={{__html: this.props.content}}></div>
                                    </div>
                                </div>
                            </div>
                            <div  className={'flex'} style={{marginTop:'4vw',color:'#999999',fontSize:'3vw'}}>
                                <div><span style={{marginRight:11}}>{this.props.name}</span><span>{this.props.createTime}</span></div>
                                <div>{this.props.type}</div>
                            </div>
                        </div>
                    </WingBlank>
                </div>
            );
        }
        return (
            <WingBlankExample/>
        )
    }
}