import {beginAuthURL, logout, websocketListen, wsName} from "../action";
import config from "../config";

let expireTime = 0;
const detectionTime = 1000;//间隔多少时间检测一次
const maxPireTime = 1000 * 60 * 60 * 3;
// const maxPireTime = 1000 * 10;
let authTimer = null;
let singleLoginUrl = null;
let msg = "长时间未操作！";

export default function myWebSocket(callback){
    if(!authTimer){
        clearInterval(authTimer);
    }
    wsName(function(res){//res: {account:"zx", wsid:"0318ef96fb984b4f82db7e30ee1e8d00"}
        begin();//开始计时检测
        callback();
        singleLoginUrl = "/cm-sites-app-h5"+"/wsMobile/"+ res.account + "/" + res.wsid;//监听单一登陆地址

        try {
            longPollingWebSocket(singleLoginUrl, data => {
                if(data.status === "zeroing"){
                    expireTime = 0;
                }else if(data.status === "expire"){
                    expireTime = maxPireTime;
                    msg = data.msg;
                }
            });
        }catch (e) {
            console.error("长连接执行错误！", e);
        }

        beginAuthURL();//开始校验是否异地登录
    }, (error)=>{
        if(!authTimer){
            clearInterval(authTimer);
        }
        console.log("error=========",error);
        window.location = config.loginUrl;
    });
}

let longPollingWebSocket = (url, callBack) => {
    websocketListen(url,callBack);
};

const begin  = () => {
    if(!authTimer){
        authTimer = setInterval(function(){
            expireTime += detectionTime;
            if(expireTime >= maxPireTime){
                clearInterval(authTimer);
                logout({}, ()=>{
                    window.location = config.loginUrl;
                    window.location.search = "";
                });
            }
        }, detectionTime)
    }
};
