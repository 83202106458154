import React, {Component} from 'react';
import {Icon, NavBar} from "antd-mobile";
import {permissionCheck, uuid} from "../../../utils";
import NewsClueTabs from "./NewsClueTabs";

/**
 * 线索列表页面
 */

class NewsCluePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: '线索',
            isContributeRoleFlag: this.props.match.params.isContributeRoleFlag,
            goBack: this.props.match.params.goBack,
            history: require("history").createHashHistory,
        }
    }

    /**
     * 返回
     */
    goBack() {
        if (this.state.goBack !== 'false') {
            let path = '/index/homeTab/';
            this.state.history().push(path);
        }
    }

    /**
     * 新增线索任务页面
     */
    addNewsClue() {
        let path = "/addNewCluePage/addNewCluePage"
        this.state.history().push(path);
    }


    render() {
        var header = <NavBar key={uuid()}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={this.state.goBack === 'false' ? [] : [<span className={'nav_goback'}  key={uuid()} onClick={() => this.goBack()}>返回</span>]}
                             mode="light"
                             rightContent={[this.state.isContributeRoleFlag === 'true' ?
                                 <div key={uuid()} onClick={() => {
                                     this.addNewsClue();
                                 }} style={{
                                     color: '#2d2d2d',
                                     fontSize: "18px",
                                     width: '80px',
                                     textAlign: 'right',
                                     fontWeight: "500",
                                     marginRight: 6
                                 }}><img src={'images/tianjia.png'} width={'18px'}/>
                                 </div> : ''
                             ]}
        ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>;

        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            <NewsClueTabs isContributeRoleFlag={this.state.isContributeRoleFlag}></NewsClueTabs>
        </div>
    }
}

export default NewsCluePage;
