/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import '../contributionsItem/contributionsItem.css'
import {WingBlank, WhiteSpace} from 'antd-mobile';


export default class ContributionsItem extends Component {
    static defaultProps = {
        content: '刘同，湖南郴州人，1981年2月27日生，毕业于湖南师范大学中文系，青年作家，光线影业副总裁。2020年出版全新散文作品《一个人就一个人》。2019年由其小说改编并担任联合出品人和监制的的同名电视剧《我在未来等你》播出，入选广电总局2019年度优秀网络视听优秀作品，荣获2020上海国际电影电视节互联网影视峰会年度十佳网络剧。2018年出版《别做那只迷途的候鸟》，荣获亚洲好书榜2018年度十大热书',//任务内容
        type: '稿件',//
        name: '姚小妍',//姓名
        color:'#5073d5',//颜色
        createTime: '2020-01-32 12:12:12',
        manuscriptDetails: () => {
           console.log('yxy')
        }
    };
    static propTypes = {
        content: PropTypes.string.isRequired,//任务内容
        type: PropTypes.string.isRequired,//任务来源
        name: PropTypes.string.isRequired,//姓名
        createTime: PropTypes.string.isRequired,
        manuscriptDetails: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        // const WingBlankExample = () => {
            return (
                    <div className={'contributions'} style={{height: '28vw'}} onClick={() => {
                        this.props.manuscriptDetails()}}>

                        <div className={'contributionsBox'}>
                            <span style={{
                                width: '10vw',
                                height:'4.5vw',
                                background:this.props.color,
                                fontSize:'10px',
                                borderRadius: '3vw',
                                color:'white',
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                                marginTop:'23px',
                                marginRight:'0px',
                                marginLeft:'15px'
                            }}>{this.props.type}</span>
                            <span className={'contributionsContent'}>{this.props.content}</span>
                        </div>
                        <div className={'flex'} style={{margin: '3vw 6vw 5vw 4vw'}}>
                            <div>
                                <span className={'gery'} style={{
                                    // marginLeft: '3vw',
                                    display: 'inline-block',
                                    marginTop:'8px',
                                }}>{this.props.createTime}</span>
                            </div>

                            <div className={'gery'}>{this.props.name}</div>
                        </div>
                    </div>
            );
        // };
        // return (
        //     <WingBlankExample/>
        // )
    }
}