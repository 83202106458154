import React, {Component} from 'react';
import {Icon, NavBar} from "antd-mobile";
import {permissionCheck, uuid} from "../../../utils";
import OffCampusMediaTabs from "./OffCampusMediaTabs";
import {getInfoFromLocalStorage} from "../../../action";

/**
 * 校外媒体预约列表页面
 */

class OffCampusMediaPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: '校外媒体预约',
            isContributeRoleFlag: getInfoFromLocalStorage("isContributeRoleFlag"),
            goBack: this.props.match.params.goBack,
            history: require("history").createHashHistory,
        }
    }

    /**
     * 返回
     */
    goBack() {
        if (this.state.goBack !== 'false') {
            let path = '/index/homeTab/';
            this.state.history().push(path);
        }
    }

    /**
     * 新增校外媒体预约页面
     */
    addOffCampusMedia() {
        let path = "/addoffCampusMediaPage/addoffCampusMediaPage"
        this.state.history().push(path);
    }


    render() {
        var header = <NavBar key={uuid()}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={this.state.goBack === 'false' ? [] : [<span className={'nav_goback'}  key={uuid()} onClick={() => this.goBack()}>返回</span>]}
                             mode="light"
                             rightContent={[
                                 <div key={uuid()} onClick={() => {
                                     this.addOffCampusMedia();
                                 }} style={{
                                     color: '#2d2d2d',
                                     fontSize: "18px",
                                     width: '80px',
                                     textAlign: 'right',
                                     fontWeight: "500"
                                 }}><img src={'images/tianjia.png'} width={'18px'}/>
                                 </div>
                             ]}
        ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>;

        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            <OffCampusMediaTabs isContributeRoleFlag={this.state.isContributeRoleFlag}></OffCampusMediaTabs>
        </div>
    }
}

export default OffCampusMediaPage;
