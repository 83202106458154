import React, {Component} from 'react';
import {
    Button,
    InputItem,
    Flex,
} from "antd-mobile";
import {createForm} from "rc-form";

import {
    baseUrl,
    setInfoToLocalStorage, getUserAllAuth, removeInfoFromLocalStorage
} from "../../action";
import Toast from "antd-mobile/lib/toast";
import {hex_md5} from "../../utils/md5";
import $ from 'jquery';
import {setContributeRoleFlag} from "../../store/actions";
import {connect} from "react-redux";

import login_background  from '../../backimages/login_background.png';
import logo_title  from '../../backimages/logo_title.png'
import myWebSocket from "../../auth";

require('./login_View.css');

class LoginView extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            history: require("history").createHashHistory,
            userInfo: {},
            loginBackground:'',
            logoTitleImg:'',
            logoTitleText:'',
            copyrightText:'',
        });
        //先清空 sessoinStorage;
        removeInfoFromLocalStorage("currentMediaCode");

        Toast.loading("加载中...")
        this.loadCodeImg = this.loadCodeImg.bind(this);
        this.checkLoginUser = this.checkLoginUser.bind(this);
        this.changeCodeImg = this.changeCodeImg.bind(this);

    }

    height;

    /**
     * 登录
     */
    loginTo() {
        //获取稿件的属性数据
        let userInfo = this.props.form.getFieldsValue();

        if (userInfo.userName === "" || !userInfo.userName) {
            Toast.offline('请输入帐号', 1);
            return;
        }
        if (userInfo.password === "" || !userInfo.password) {
            Toast.offline('请输入密码', 1);
            return;
        }
        if (userInfo.logincode === "" || !userInfo.logincode) {
            Toast.offline('请输入验证码', 1);
            this.changeCodeImg();
            return;
        }

        this.setState({
            userInfo: userInfo
        });


        //调用后台登录页面
        let params = {
            account: userInfo.userName,
            password: hex_md5(hex_md5(userInfo.password).toLowerCase() + userInfo.logincode),
            logincode: userInfo.logincode
        }

        Toast.loading('登录中...', 0, () => {
        });
        let url = baseUrl + "/cm-sites-app-h5/login/checkCode";
        let that = this;
        $.post(url, params, function (data) {
            if (!data) {
                Toast.hide();
                Toast.offline('验证码不正确', 1);
                that.changeCodeImg();
            } else {
                Toast.loading('登录中...', 0, () => {
                });
                let loginUrl = baseUrl + "/cm-sites-app-h5/login";
                $.post(loginUrl, params, function (data) {
                    Toast.hide();
                    that.checkLoginUser(data);
                });
            }
        });
    }

    componentDidMount() {
        Toast.hide();
        this.changeCodeImg();

        //获取手机登录页的背景图片图片，
        $.post("/cm-sites-app-h5/config/getByConfigKey",{key:"app.h5.loginImg"},(res)=>{
            if(res){
                this.setState({
                    loginBackground:res
                });
            }else{
                this.setState({
                    loginBackground:login_background
                });
            }
        });

        //获取手机登录页的标题logo图片，
        $.post("/cm-sites-app-h5/config/getByConfigKey",{key:"app.h5.loginTitleImg"},(res)=>{
            if(res){
                this.setState({
                    logoTitleImg:res
                });
            }else{
                this.setState({
                    logoTitleImg:logo_title
                });
            }
        });

        //获取手机登录页的标题问题，
        $.post("/cm-sites-app-h5/config/getByConfigKey",{key:"app.h5.loginTitleText"},(res)=>{
            if(res){
                this.setState({
                    logoTitleText:res
                });
            }else{
                this.setState({
                    logoTitleText:"移动采编平台"
                });
            }
        });

        //获取手机登录页的下方的版权文字，
        $.post("/cm-sites-app-h5/config/getByConfigKey",{key:"app.h5.copyrightText"},(res)=>{
            if(res){
                this.setState({
                    copyrightText:res
                });
            }else{
                this.setState({
                    copyrightText:""
                });
            }
        });
    }

    //稿件销毁，清除定时器
    componentWillUnmount() {
        clearInterval(this.t);
    }

    //定时器的清除和重启
    t = setInterval(this.loadCodeImg, 1000 * 60 * 5) //fun1是你的函数

    changeCodeImg() {
        $("#codeimg").attr("src", baseUrl + "/cm-sites-app-h5/login/code?randnum=" + new Date().getTime());
        $("#logincode").focus();
        clearInterval(this.t)//清除定时器
        this.t = setInterval(this.loadCodeImg, 1000 * 60 * 5);//重新开始定时器
    }

    /**
     * 加载验证码
     */
    loadCodeImg() {
        $("#codeimg").attr("src", baseUrl + "/cm-sites-app-h5/login/code?randnum=" + new Date().getTime());
    }

    /**
     * 登录校验
     */
    checkLoginUser(hasPermission) {
        if (hasPermission === 'sys.001') {
            //把上一个页面压到history中，
            setInfoToLocalStorage('userName', this.state.userInfo.userName);

            //登录成功，跳到首页；
            let path = '/index/homeTab';
            this.state.history().push(path);
            myWebSocket(()=>{
            });
        } else {
            this.changeCodeImg();
            Toast.offline('用户名或密码错误', 1);
        }
    }

    render() {
        const {loginBackground,logoTitleImg,logoTitleText,copyrightText}=this.state;
        const {getFieldProps} = this.props.form;
        this.height=this.height?this.height:window.innerHeight;
        return (<div className={'login-container'} style={{height:this.height+'px',backgroundImage: `url(${loginBackground})`}}>
            <Flex direction={'column'} justify={'center'}
                  align={'center'}
                  alignContent={'center'}>
                <Flex.Item>
                    <div className={'login_title'} style={{color:'#000000'}}>
                        <img width={200} src={logoTitleImg}/>
                        {/*<h1 className={'login_title_text'} style={{color:'#000000'}}>陕煤集团*/}
                        {/* </h1>*/}
                        <h1 className={'login_title_text'} style={{color:'#000000'}}   dangerouslySetInnerHTML={{__html: logoTitleText}}>
                            </h1>
                    </div>

                </Flex.Item>
                <Flex.Item>
                        <InputItem
                            {...getFieldProps('userName')}
                            type={'text'}
                            clear
                            placeholder="请输入帐号"
                            moneyKeyboardAlign="left"
                            style={{fontSize: '14px'}}
                        >
                            <div style={{
                                backgroundImage: 'url("images/user_name_hagc.png")',
                                backgroundSize: 'cover',
                                padding:'3px',
                                width: '20px',
                                height: '20px'
                            }}/>
                        </InputItem>
                </Flex.Item>
                <Flex.Item>
                        <InputItem
                            {...getFieldProps('password')}
                            type={'password'}
                            clear
                            placeholder="请输入密码"
                            style={{fontSize: '14px'}}
                        >
                            <div style={{
                                backgroundImage: 'url("images/pass_word_hagc.png")',
                                backgroundSize: 'cover',
                                width: '20px',
                                height: '20px'
                            }}/>
                        </InputItem>
                </Flex.Item>
                <Flex.Item>
                        <div className={'loginCode'}>
                            <Flex direction={'row'} alignContent={'around'}>
                                <Flex.Item  style={{flex:2}}>
                                    <InputItem
                                        {...getFieldProps('logincode')}
                                        type={'logincode'}
                                        clear
                                        placeholder="请输入验证码"
                                        style={{fontSize: '14px'}}
                                    >
                                        <div style={{
                                            backgroundImage: 'url("images/yan_zhen.png")',
                                            backgroundSize: 'cover',
                                            width: '20px',
                                            height: '20px'
                                        }}/>
                                    </InputItem>

                                </Flex.Item>
                                <Flex.Item style={{flex:1}}>
                                    <div style={{marginLeft: '20px'}}>
                                        <img src="images/dlk_stoo.jpg" id="codeimg" width="100%" height="42"
                                             onClick={() => this.changeCodeImg()} title="看不清点我"/>
                                    </div>
                                </Flex.Item>
                            </Flex>
                        </div>
                </Flex.Item>
                <Flex.Item>
                        <Button className={'loginButton'} onClick={() => {
                            this.loginTo()
                        }}><span style={{color: '#ffffff'}}>登录</span></Button>
                </Flex.Item>
            </Flex>
            <div style={{
                textAlign: 'center',
                color: 'rgba(255,255,255,0.4)',
                fontSize: '10px',
                marginTop:'20px',
                bottom:'5px',
                left:'0'
            }}>
                <div style={{lineHeight: 2}}>

                </div>
                <div style={{lineHeight: 2}}  dangerouslySetInnerHTML={{__html: copyrightText}}></div>
                <div style={{lineHeight: 2}}></div>
            </div>
        </div>);
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setContributeRoleFlag: function (contributeRoleFlag) {
            dispatch(setContributeRoleFlag(contributeRoleFlag));
        }
    }
}


const LoginViews = createForm()(LoginView);
export default connect(null, dispatchToProps)(LoginViews);
