import React from "react";
import {ListView, Toast} from "antd-mobile";
import {Link} from "react-router-dom";
import {formatDate, isStringEmpty} from "../utils";
import {NoDataView} from "./NoDataView";
import config from "../config";
import {connect} from "react-redux";
import {setStoryId} from "../store/actions";
import ActButton from "./activity/ActButton";
import $ from 'jquery';
import {getAllData, getInfoFromLocalStorage} from "../action";
import SearchBar from "antd-mobile/es/search-bar";


class ReviewListViewData extends React.Component {

    pageIndex = 0;

    deploymentKey2ActButtonId = config['deploymentKey2ActButtonId'];
    rData = [];

    constructor(props) {
        super(props);
        this.initListData();
    }

    initListData = () => {

        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            mediaCode:this.props.mediaCode,
            count: 0,
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            dataStatus: '加载中...',
            treeContentId:this.props.treeContentId,
        };
    }


    updateStoryData(data) {

        Toast.hide();

        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }
        ;

        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            // pageSize: data.count,
            dataStatus: data.count > 0 ? '加载完成' : '暂无数据'
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.pageIndex = 0;
        this.rData = [];
        this.NUM_ROWS=0;
        this.getPageListData(nextProps.mediaCode);
    }

    componentDidMount() {
        this.getPageListData(this.props.mediaCode);
    }

    componentWillUnmount() {
    }

    getPageListData(mediaCode) {
        console.log('--------------',this.props.treeContentId)
        let that=this;
        //重新请求后台获取数据
        let param = {
            page: this.pageIndex, size: 10, query: {
                equalsMap: {pojoName: 'story'},
                likeMap:{
                    "data.title":this.state.search
                }
            }
        };
        if(this.props.treeContentId){
            param.query.equalsMap['data.news.treeContentId'] =this.props.treeContentId
        }

        $.ajax({
            url: "/cm-sites-activiti/activiti/getNeedProcessed",
            data: JSON.stringify(param),
            contentType: 'application/json; charset=UTF-8',
            dataType:"JSON",
            traditional:true,
            type:"POST",
            success: function (todoList) {
                that.updateStoryData(todoList);
            }
        });
    }

    onEndReached = (event) => {
        let that=this;
        ++this.pageIndex;
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        console.log('reach end', event);
        this.setState({isLoading: true});
        //流程没有加载更多的
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
                isLoading: false
            })
            return;
        }
        let param = {
            page: this.pageIndex, size: this.state.pageSize, query: {
                equalsMap: {pojoName: 'story'},
                likeMap:{
                    "data.title":this.state.search
                }
            }
        };
        if(this.props.treeContentId){
            param.query.equalsMap['data.news.treeContentId'] = this.props.treeContentId
        }
        if (!this.props.storyAudit && this.props.mediaCode) {
            param.query.equalsMap['data.mediaCode'] = this.props.mediaCode
        }
        $.ajax({
            url: "/cm-sites-activiti/activiti/getNeedProcessed",
            data: JSON.stringify(param),
            contentType: 'application/json; charset=UTF-8',
            dataType:"JSON",
            traditional:true,
            type:"POST",
            success: function (todoList) {
                that.updateStoryData(todoList);
            }
        });
    }

    submitSearch=(value)=>{
        //重新请求后台获取数据
        this.rData =[];
        let that=this;
        this.pageIndex=0;
        //重新请求后台获取数据
        let param = {
            page: this.pageIndex, size: this.state.pageSize, query: {
                equalsMap: {pojoName: 'story'},
                likeMap:{
                    "data.title":value
                }
            }
        };
        if(this.props.treeContentId){
            param.query.equalsMap['data.news.treeContentId'] = this.props.treeContentId
        }
        // if (!this.props.storyAudit && this.props.mediaCode) {
        //     param.query.equalsMap["data.mediaCode"] = this.props.mediaCode
        // }

        $.ajax({
            url: "/cm-sites-activiti/activiti/getNeedProcessed",
            data: JSON.stringify(param),
            contentType: 'application/json; charset=UTF-8',
            dataType:"JSON",
            traditional:true,
            type:"POST",
            success: function (todoList) {
                that.updateStoryData(todoList);
            }
        });
    }

    onChange=(value)=>{
        this.setState({ search:value });
    };
    onCancel=()=>{
        this.setState({ search:'' },()=>{
            this.submitSearch('')
        });
    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            let actButtonId = this.deploymentKey2ActButtonId[rowData.pojoName];
            const obj = rowData;
            return obj ? (
                <div>
                    <Link
                        to={'/writeStory/' + obj.data.id + '/' + obj.processInstanceId + '/' + actButtonId + '/' + 1}>
                        <div key={rowID} style={{padding: '15px'}}>
                            <div
                                style={{
                                    fontSize: '4vw',
                                    // fontWeight: 700,
                                    color: 'black',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.data.title}}
                            ></div>
                            <div
                                style={{
                                    lineHeight: '20px',
                                    color: '#888',
                                    fontSize: '3.5vw',
                                    wordBreak: 'break-all',
                                    marginTop: 8
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.data.summary) ? '' : obj.data.summary}}></div>
                            <div>
                                <div className={'cardBootm'}>
                                    <div
                                        key={`${sectionID}-${rowID}`}
                                        style={{
                                            backgroundColor: '#F5F5F9',
                                            height: 0.5,
                                            borderTop: '1px solid #ECECED',
                                            marginTop: 15,
                                            marginBottom: 11,
                                            // borderBottom: '1px solid #ECECED',
                                        }}
                                    />
                                    <div style={{display:'inline-block'}}>
                                        <span style={{
                                            textAlign: 'right',
                                            color: '#888',
                                            fontSize: '12px',
                                            paddingBottom: '10px'
                                        }}>{formatDate(obj.data.changeDate?obj.data.changeDate.time:'')}</span>
                                        <span style={{paddingTop: '10px', color: '#888', fontSize: '12px',}}>
                                            <span>{}</span><span style={{
                                            float: 'right',
                                            color: '#888',
                                            fontSize: '12px',
                                            marginLeft:'27vw'
                                        }}>状态: {obj.data.statusName}</span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>) : <div></div>;
        };

        return <div><SearchBar
            value={this.state.search}
            placeholder="输入稿件名称"
            onSubmit={this.submitSearch}
            onClear={value => console.log(value, 'onClear')}
            onFocus={() => console.log('onFocus')}
            onBlur={() => console.log('onBlur')}
            onCancel={this.onCancel}
            onChange={this.onChange}
        />{this.state.count > 0 ? (
            <ListView
                initialListSize={this.state.count}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                    {this.state.isLoading ? '加载中...' : '加载完成'}
                </div>)}
                renderRow={row}
                style={{
                    height: this.state.height,
                    overflow: 'auto',
                }}
                pageSize={this.state.pageSize}
                onScroll={() => {
                    console.log('scroll');
                }}
                scrollRenderAheadDistance={500}
                onEndReached={this.onEndReached}
                onEndReachedThreshold={this.state.pageSize}
            />
        ) : <NoDataView content={this.state.dataStatus}/>}</div>;
    }


}


/**
 * 将state属性值映射到props
 * @param state
 * @returns {{storyId: *}}
 */
const stateToProps = (state) => {
    return {
        storyId: state.storyReducer.storyId,
        currentMediaCode: state.storyReducer.currentMediaCode
    }

}

const dispatchToProps = (dispatch) => {
    return {
        setStoryId: (storyId) => {
            dispatch(setStoryId(storyId))
        }
    }
}


export default connect(stateToProps, dispatchToProps)(ReviewListViewData);
