import {jpost, post} from "./index";
import {baseUrl} from "../components/activity/ActButton";
/**
 * 新增 校外媒体预约
 * @param param
 * @param callBack
 */
export const addExtertramuraImedia =(url, param, callBack) =>{
    jpost(baseUrl + url, param, callBack);
}

/**
 * 获取审核人信息
 * @param param
 * @param callBack
 */
export const getShrInfos =(param, callBack) =>{
    jpost(baseUrl +  "/cm-sites-app-h5/user/getShrByDept", param, callBack);
}


/**
 * 获取校外媒体预约信息
 * @param param
 * @param callBack
 */
export const getOffCampusMediaInfo =(param,url, callBack) =>{
    post(baseUrl + url, param, callBack);
}