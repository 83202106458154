import React, {Component} from "react";
import {Modal} from "antd-mobile";


class SelectPicListview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            dataSource: this.props.dataSource,
            selectImg: "",
        }
    }

    openPicShow() {
        this.setState({
            show: true
        })
    }

    componentWillMount() {

        //把自己传递给父容器的 一个属性
        this.props.onRef(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            dataSource: nextProps.dataSource
        })
    }

    selectImag(img) {
        this.setState({
            selectImg: img.src
        })
    }

    /**
     * 点击确定事件
     */
    clickOk() {
        this.props.clickOk(this.state.selectImg);
        this.setState({
            show: false
        })
    }

    renderImagList(pics) {
        if (!pics || pics.length == 0) {
            return <div key={0}>暂无图片</div>
        }
        let picList = pics.map((items, index) => {
            let imgstyle = {
                objectFit: 'cover'
            };
            if (this.state.selectImg == items.src) {
                imgstyle = {
                    border: '2px solid blue',
                    objectFit: 'cover'
                }
            }
            return <div key={index}
                        style={{float: 'left', margin: '10px', width: '180px', height: '180px', marginRight: '0px'}}>
                <img style={imgstyle}
                     onClick={() => {
                         this.selectImag(items)
                     }} src={items.src}
                     width={'180px'} height={'180px'}
                />
            </div>
        })
        return picList;
    }

    render() {
        let width = this.state.dataSource.length * 200+'px';
        if(width==='0px'){
            width='100%';
        }
        return <div>
            <Modal
                popup
                visible={this.state.show}
                animationType="slide-up"
                onClose={() => {
                    this.setState({show: false})
                }}
                title={<div>
                    <div style={{fontSize: '14px', color: 'rgb(24, 144, 255)', float: "left"}} onClick={() => {
                        this.setState({show: false})
                    }}>取消
                    </div>
                    <div style={{fontSize: '14px', color: 'rgb(24, 144, 255)', float: "right"}} onClick={() => {
                        this.clickOk()
                    }}>确定
                    </div>
                </div>}
                afterClose={() => {
                }}>

                <div style={{width: width, height: '180px'}}>{this.renderImagList(this.state.dataSource)}</div>
            </Modal>
        </div>
    }
}

export default SelectPicListview;