import React, {Component} from 'react'
import * as PropTypes from "prop-types";
import {Card} from "antd-mobile";

/**
 * 批注信息的展示
 */
class RemarkInfoShow  extends Component{
    constructor(props){
        super(props);
    }

    static defaultProps = {
        title:'审批意见',
        statusName: "",
        reviewDate: "",
        reviewSubmitter: "",
        reviewContent: '',
    }

    static propTypes = {
        title:PropTypes.string,//审批的标题
        statusName: PropTypes.string, //预约状态的名称
        reviewDate: PropTypes.string,//批注时间
        reviewSubmitter: PropTypes.string,//审批人信息
        reviewContent: PropTypes.string,//审批意见
    }

    render() {
        return <div >
            <Card>
             <div style={{textAlign:'center'}}><h3>{this.props.title}</h3></div>
            <div style={{margin:'auto'}}>
                <div>预约状态：{this.props.statusName}</div>
                <div>批注时间：{this.props.reviewDate}</div>
                <div>审批人：{this.props.reviewSubmitter}</div>
                <div>审核意见：{this.props.reviewContent}</div>
            </div>
            </Card>
        </div>
    }
}

export default RemarkInfoShow;

