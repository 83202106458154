import React, {Component} from 'react';
import {ListView, Toast} from "antd-mobile";
import {formatDate, isStringEmpty, uuid} from "../../../utils";
import {getInfoFromLocalStorage, post} from "../../../action";
import {Link} from "react-router-dom";
import {NoDataView} from "../../../components/NoDataView";

/**
 * 线索列表页面
 */

class OffCampusMediaList extends Component {
    pageIndex = 1;
    rData=[];

    constructor(props) {
        super(props);
        this.initListData();
    }

    initListData = () => {
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });

        this.state = {
            dataSource,
            isLoading: true,
            data: [],
            count: 0,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            pageSize: 10,
            height: document.documentElement.clientHeight * 5 / 6,
            dataStatus: '加载中...',
            statusCode2Name: {0: "已提交", 1: "采用", 2: "弃用", 3: '已撤回', 4: '暂存'},

        };
    }


    updateInterviewData(data) {
        Toast.hide();
        //请求后台异常。
        if (!data || !data.data) {
            this.setState({
                isLoading: false,
                count: 0,
                dataStatus: "服务异常"
            })
            return;
        }

        //根据返回的数据量确定渲染的行数；
        this.rData = [...this.rData, ...data.data];
        this.setState({
            data: data.data,
            count: data.count,
            dataSource: this.state.dataSource.cloneWithRows(this.rData),
            isLoading: false,
            hasMore: true,
            pageSize: data.data.length,
            dataStatus: data.data.length > 0 ? '加载完成' : '暂无数据'
        })
        ++this.pageIndex;
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    componentWillMount() {
        this.getPageListData();
    }

    componentWillUnmount() {
    }

    getPageListData() {
        Toast.loading('加载中...', 0, () => {
        });
        let data = this.getFormData(1);
        post(this.props.dataUrl, data, this.updateInterviewData.bind(this));
    }

    onEndReached = (event) => {
        // hasMore: from backend data, indicates whether it is the last page, here is false
        if (this.state.isLoading && !this.state.hasMore) {
            return;
        }
        if (this.pageIndex >= (this.state.count / this.state.pageSize + 1)) {
            this.setState({
                hasMore: false,
            });
            return;
        }

        Toast.loading('加载中...', 0, () => {
        });
        this.setState({isLoading: true});

        //重新请求后台获取数据
        let data = this.getFormData(this.pageIndex);
        post(this.props.dataUrl, data, this.updateInterviewData.bind(this));
    }

    /**
     * 封装请求后台的参数
     * @param start
     * @returns {FormData}
     */
    getFormData(start) {
        let data = new FormData();
        switch (this.props.currentTabIndex) {
            case 0:
                data.append('status', -100);
                break;
            case 1:
                data.append('status', -999);
                break;
            case 2:
                data.append('status', 1);
                break;
            default:
                data.append('status', -100);

        }
        data.append('start', start);
        data.append('size', this.state.pageSize);
        return data;
    }

    /**
     * 状态展示
     * @param d
     */
    formateStatusName(d){
       if(d.status=== -999 && d.lastStatus === -2){
           return "院系审核驳回-待修改";
       }else if(d.status=== -999 && d.lastStatus=== -1){
           return "宣传部审核驳回-待修改";
       }else if(d.status=== -2){
           return "待院系审核";
       }else if(d.status=== -999){
           return "草稿";
       }else if(d.status=== -1){
           return "待宣传部审核";
       }else if(d.status=== 1){
           return "已采用";
       }
    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            const obj = rowData;
            return obj ? (
                <div>
                    <Link to={'/addoffCampusMediaPage/addoffCampusMediaPage/' + obj.id}>
                        <div key={rowID} style={{padding: '15px'}}>
                            <div
                                style={{
                                    fontSize: 14,
                                    color: 'black',
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: obj.title}}></div>
                            <div
                                style={{
                                    lineHeight: '30px',
                                    color: '#888',
                                    fontSize: 12,
                                    wordBreak: 'break-all'
                                }}
                                dangerouslySetInnerHTML={{__html: isStringEmpty(obj.content) ? '' : obj.content}}></div>
                            <div>
                                <div className={'cardBootm'}>
                                    <div style={{
                                        textAlign: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                        paddingBottom: '10px'
                                    }}>{formatDate(obj.submitDate ? obj.submitDate.time : null)}</div>
                                    <div
                                        key={`${sectionID}-${rowID}`}
                                        style={{
                                            backgroundColor: '#F5F5F9',
                                            borderBottom: '1px solid #ECECED',
                                        }}
                                    />
                                    <div style={{
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>
                                        <span>{obj.submitter}</span><span style={{
                                        float: 'right',
                                        color: '#888',
                                        fontSize: '12px',
                                    }}>状态: {this.formateStatusName(obj)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        key={`${sectionID}-${rowID}`}
                        style={{
                            backgroundColor: '#F5F5F9',
                            height: 8,
                            // borderTop: '1px solid #ECECED',
                            // borderBottom: '1px solid #ECECED',
                        }}
                    />
                </div>) : <div></div>;
        };

        return this.state.count > 0 ? (
            <ListView
                initialListSize={this.state.count}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{padding: 30, textAlign: 'center'}}>
                    {this.state.isLoading ? '加载中...' : '加载完成'}
                </div>)}
                renderRow={row}
                style={{
                    height: this.state.height,
                    overflow: 'auto',
                }}
                pageSize={this.state.pageSize}
                onScroll={() => {
                }}
                scrollRenderAheadDistance={500}
                onEndReached={this.onEndReached}
                onEndReachedThreshold={10}
            />
        ) : <NoDataView content={this.state.dataStatus}/>;
    }
}

export default OffCampusMediaList;
