import React, {Component} from 'react'
import {connect} from "react-redux";
import './EditorView.css';
import './Editor.historyList.css';
import {setTitle,setHasShowHistoryStory} from "../../store/actions/storyAction";
import {Button, List, Modal, TextareaItem,WhiteSpace} from "antd-mobile";
import {createForm} from "rc-form";
import Editor from "./Editor";
import {checkUserTempStoryData, deleteAllUserTempStoryData} from "./Editor.history";
import EditorHistroyList from "./Editor.histroyList";

class EditorView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storyId: props.storyId,
            story: props.story,
            showHistoryStory:true,
            addStory:props.addStory,
            historyStory:false,
            hasShowHistoryStory:props.hasShowHistoryStory
        }
    }
    componentDidMount() {
        if(this.state.hasShowHistoryStory){
            checkUserTempStoryData(this);
       }
    }
    //在组件接收到一个新的 prop (更新后)时被调用。这个方法在初始化render时不会被调用。
    componentWillReceiveProps(nextProps,nextState){
        if(nextProps.story){
            this.setState({story:nextProps.story});
        }
        if(nextProps.storyId != this.state.storyId){
            this.setState({storyId:nextProps.storyId});
            if(this.state.hasShowHistoryStory){
                checkUserTempStoryData(this);
            }
        }
    }
    // 返回一个布尔值。在组件接收到新的props或者state时被调用。在初始化时或者使用forceUpdate时不被调用。
    //可以在你确认不需要更新组件时使用。
    shouldComponentUpdate(nextProps, nextState,snapshot){
        return true;
    }
    //改变后回填
    onInputBlur = (value) => {
        this.props.setTitle(value);
    }

    deleteAllTemp(){
        deleteAllUserTempStoryData(this);
    }
    closeHistoryModal(){
        this.setState({
            historyStory: false,
            hasShowHistoryStory:false
        });
        this.props.setHasShowHistoryStory(false);
    }
    render() {
        const {getFieldProps} = this.props.form;
        return <div id="editorView">
                <List>
                    <TextareaItem
                        clear
                        placeholder="请输入稿件标题"
                        type={'text'}
						autoHeight
                        {...getFieldProps('title',{initialValue: this.props.story?this.props.story.title:''})}
                        onBlur={this.onInputBlur}
                    />
                </List>
            <WhiteSpace size="xs" />
            {this.state.story&&(this.state.story.storyType === 1 || this.state.story.storyTypeStr === 'PIC')? <div style={{height:'70vh',fontSize:'16px',background: "#ffffff",lineHeight:'40px',textAlign:'center'}}>
                暂不支持组图</div>: <Editor storyId={this.state.storyId} bindId={this.props.bindId} story={this.state.story} ></Editor>}

                {/*<Modal*/}
                {/*    popup*/}
                {/*    visible={this.state.historyStory}*/}
                {/*    onClose={() => {this.closeHistoryModal()}}*/}
                {/*    animationType="slide-up"*/}
                {/*    maskClosable={true}*/}
                {/*    afterClose={() => {this.closeHistoryModal()}}>*/}
                {/*    <List  renderHeader={() =>*/}
                {/*        <div className='history-header'>*/}
                {/*                <span onTouchEnd={()=>{this.deleteAllTemp()}} className="iconfont icon-shanchu1">全部删除</span>*/}
                {/*                <span>历史草稿</span>*/}
                {/*                <span onTouchEnd={()=>{this.closeHistoryModal()}} className="iconfont icon-enter">确定</span>*/}
                {/*        </div>*/}
                {/*    } className="popup-list">*/}
                {/*        <EditorHistroyList/>*/}
                {/*        <List.Item>*/}
                {/*            <Button type="primary" onClick={() => {this.closeHistoryModal()}}>确定</Button>*/}
                {/*        </List.Item>*/}
                {/*    </List>*/}
                {/*</Modal>*/}
            </div>
    }
}

const stateToProps = (state) => {
    return {
        resources:state.storyReducer.resources, //稿件资源信息
        addStory:state.storyReducer.addStory,
        story:state.storyReducer.story,
        storyId: state.storyReducer.storyId,
        hasShowHistoryStory:state.storyReducer.hasShowHistoryStory
    }
}

/**
 * 组件绑定form表单
 */
const EditorViewForm = createForm()(EditorView);
const dispatchToProps = (dispatch) => {
    return {
        setTitle:function(title){
            dispatch(setTitle(title));
        },
        setHasShowHistoryStory(hasShowHistoryStory){
            dispatch(setHasShowHistoryStory(hasShowHistoryStory));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditorViewForm);