import React, {Component} from 'react';
import {HashRouter, Route, Switch} from "react-router-dom";
import Routes from "./route";
import {uuid} from "./utils";
import myWebSocket from "./auth";


class App extends Component {
    componentDidMount() {
        myWebSocket(()=>{
            console.info("app  websocket 启动开始")
        });
    }


    componentWillUnmount() {


    }

    render() {
        return <HashRouter>
            <Switch>
                {
                    Routes.map((route) => (
                        <Route key={uuid()}  exact
                               path={route.path}
                               render={props => (
                                   <route.component {...props} routes={route.routes}/>
                               )}
                        />
                    ))
                }
            </Switch>
        </HashRouter>
    }
}
export default App;