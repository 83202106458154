import React from "react";

/***
 * 后台没有列表数据展示此组件
 * @param props
 * @returns {*}
 * @constructor
 */
export const NoDataView = (props) => {

    return (<div style={{textAlign: 'center', marginTop:'10px',top:'50%',height:'400px'}}> <img src={'images/no_data.png'} style={{position:"relative",marginTop:'100px',width:"50px",height:"50px",zIndex:"0"}} />
            <div>暂无数据</div>
    </div>

    );
}