import React, {Component} from 'react';
import {Button, Flex, InputItem, WingBlank} from "antd-mobile";
import {logout} from "../../action";

/**
 * 我的导航页
 */
class MyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: require("history").createHashHistory,
        }
    }

    loginOut() {
        //调用后台退出；
        logout({}, this.logoutBack.bind(this));


    }

    logoutBack(data) {
        if (data) {
            let path = '/login/login';
            this.state.history().push(path);
        }
    }

    render() {
        return <div>我的信息
            <Flex direction={'column'} justify={'center'}
                  align={'center'}
                  alignContent={'center'}>
                <Flex.Item>
                    <WingBlank style={{margin:'5px'}} size="md">
                        <Button onClick={() => {
                            this.loginOut()
                        }}><span>退出当前用户</span></Button>
                    </WingBlank>
                </Flex.Item>
            </Flex>
        </div>
    }
}

export default MyPage;
