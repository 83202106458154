/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Icon,NavBar,} from "antd-mobile";
import {formatDate, uuid} from "../../utils";
import News from "../../components/contributionsItem/news";
import MessageListView from "../../components/MessageListView";

export default class NewsPage extends Component {
    static defaultProps = {};
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            id: '',
        }
    }
    componentWillMount() {
    }

    componentDidMount() {


    }

    render() {
        return (
            <div>
                <NavBar
                    key={'消息'}
                    mode={'light'}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'} onClick={() => this.props.history.goBack()} key={uuid()}>返回</div>
                    ]}
                ><span className={'nav_title'}>消息</span></NavBar>
                <div>
                    <MessageListView messageType={this.state.messageType} allRead={this.state.allRead}></MessageListView>
                </div>

            </div>
        )
    }
    goBack() {
        this.props.history.goBack();
    }
}

