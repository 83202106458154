import React, {Component} from 'react'
import {Tabs, WingBlank} from 'antd-mobile';
import {getInfoFromLocalStorage, getMyTabdata} from "../../action";
import OriginalResourceDataList from "../../components/OriginalResourceDataList";

/**
 * 原创稿件 列表数据
 */
class OriginalStoryTabs extends Component {

    constructor(props) {
        super(props);
        let mediaCode = getInfoFromLocalStorage('currentMediaCode');
        let isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
        this.state = {
            tabsTitle: [],
            mediaCode: mediaCode,
            currentTabIndex: 2,
            isContributeRoleFlag: isContributeRoleFlag,
        }
    }

    componentDidMount() {
        getMyTabdata({}, this.updateTabsTittle.bind(this));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            mediaCode: nextProps.mediaCode
        })
    }

    updateTabsTittle(data) {
        let tablesTab;
        if (this.state.isContributeRoleFlag) {
            tablesTab = data.contribute.originalStoryTabs;
        } else {
            tablesTab = data.pms.originalStoryTabs;
        }
        this.setState({
            tabsTitle: tablesTab
        });
    }

    /**
     * tab页面切换，重新加载数据
     * @param tab
     * @param index
     */
    onTabClickTo(tab, index) {

        //重新复制一个，否则点击同一个tab页签子组件监听不到属性的变化，
        var tab1 = {...tab};
        this.setState({
            currentTab: tab1,
            currentTabIndex: index
        })
    }


    render() {
        return <div className={'myTabs'}>
            <Tabs tabs={this.state.tabsTitle}
                  initialPage={this.state.currentTabIndex}
                  onChange={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
                  swipeable={true}
                  distanceToChangeTab={0.3}
                  onTabClick={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
            >
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 0 ?
                            <OriginalResourceDataList
                            mediaCode={this.state.mediaCode}/>: <div></div>
                        }
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 1 ?
                            <OriginalResourceDataList  type={"toDoList"}
                                mediaCode={this.state.mediaCode}/>: <div></div>
                        }
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 2 ?
                            <OriginalResourceDataList type={"process"}
                                mediaCode={this.state.mediaCode}/>: <div></div>}
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 3 ?
                            <OriginalResourceDataList   statusName={"审核通过"}
                                mediaCode={this.state.mediaCode}/>: <div></div>}
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 4 ?
                            <OriginalResourceDataList  statusName={"发布"}
                                mediaCode={this.state.mediaCode}/>: <div></div>}
                    </WingBlank>
                </div>
            </Tabs>
        </div>
    }

}

export default OriginalStoryTabs;

