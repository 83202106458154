/**
 * created by lei lei
 *下拉栏目树组件
 *   <DropDownListTree  ref={Upload=>this.DropDownListTree=DropDownListTree} />  上传属性
 *  this.DropDownListTree.show()
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InputItem, Checkbox, Modal, List, Button, Toast, Popover, ImagePicker,} from "antd-mobile";
import folder from "./img/folder.png"
import {loadCurrentMedia, setInfoToLocalStorage} from "../../action";


const CheckboxItem = Checkbox.CheckboxItem;
export default class DropDownListTree extends Component {
    static defaultProps = {
        itemTitle: 'title',
        subtree: 'subtree',
        treeName: 'treeName',
        treeId: 'treeContentId',
        choose: 'one',
    };
    static propTypes = {
        itemTitle: PropTypes.string.isRequired,//头部标题,
        choose: PropTypes.string,//可以选择的个数
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            userChoose: [],
            currentDate: [{
                title: '新闻网', id: '11', fileType: 'file', child: [{
                    title: '栏目一', id: '22'
                }]
            }, {title: '新闻网', fileType: 'file', id: '11',}, {title: '新闻网', id: '11',}],
            menuList: [],
            allData: [],
            start:true,
            size:40,
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        let param = new FormData();
        param.append('type', 1);
        loadCurrentMedia(param, res => {
            let currentDate = [];
            let allData = [];
            if (res && res.length) {
                currentDate = res;
                allData = res;
            }
            this.setState({currentDate, allData})
        })
    }

    //获取涉及单位列表

    show() {
        this.setState({currentDate: this.state.allData, visible: true, menuList: [], userChoose: []})
    }


    render() {
        let contentWidth = document.body.clientWidth;
        return (
            <div style={{position: 'relative'}}>
                <Modal
                    visible={this.state.visible}
                    onClose={() => {
                        this.setState({visible: false})
                    }}
                    animationType="slide-up"
                >
                    <div>
                        <div style={{display: 'flex', background: "white"}}><div style={{
                                width: 100,
                                height: 45,
                                lineHeight: '45px',
                                textAlign: "center",
                                fontSize: 13,
                            }} onClick={() => {
                              //  this.setState({visible: false})
                            }}>
                            </div>
                            <div style={{
                                width: contentWidth,// - 100,
                                height: 45,
                                lineHeight: '45px',
                                textAlign: "center",
                                fontWeight:800,
                                color:'#000',
                                fontSize: 18,
                            }}>栏目树
                            </div>
                            <div style={{width: 100}}></div>
                            {/* <div
                                style={{
                                    width: 100,
                                    height: 45,
                                    lineHeight: '45px',
                                    textAlign: "center",
                                    fontSize: 13,
                                }}
                                onClick={() => {
                                    this.onOkClick()
                                }}>确定
                            </div>*/}

                        </div>
                        {this.renderNavigate()}
                        <div style={{display: 'flex', background: "white"}}>
                            <div
                                style={{
                                    width: contentWidth - 10,
                                    height: document.documentElement.clientHeight - 205,
                                    overflow: 'auto',
                                }}>
                                {this.state.currentDate && this.state.currentDate.map((item, index) => {
                                    if (item[this.props.subtree] && item[this.props.subtree].length) {
                                        item.fileType = 'file';
                                    }
                                        if (item.fileType === 'file') {
                                            item.imgUrl = this.state.start?'images/site.png':folder;
                                            this.state.size = 40
                                        }else{
                                            item.imgUrl ='images/icon_column .png';
                                            this.state.size = 25

                                        }

                                    return (
                                        <div key={index}>
                                            <TreeItem item={item}  {...this.props} fileClick={() => {
                                                if (item.fileType === 'file') {
                                                    this.fileClick(item, index)
                                                } else {
                                                    this.userClick(item, index)
                                                }

                                            }}/>
                                        </div>
                                    )
                                })}
                                <div style={{height: 50}}/>
                            </div>
                        </div>
                        {this.renderBottomBtn()}
                    </div>
                </Modal>
            </div>
        )
    }

    //渲染面包削导航
    renderNavigate() {
        if (this.state.menuList.length == 0) {
            this.state.menuList.push({[this.props.treeName]: '全部',})
        }
        return (
            <div>
                <div style={{
                    textAlign: 'left',
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: document.body.clientWidth - 20,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{
                    this.state.menuList && this.state.menuList.map((item, index) => {
                        if (index === this.state.menuList.length - 1) {
                            return (<span key={index}
                                          style={{
                                              height: 45,
                                              lineHeight: '45px'
                                          }}>&nbsp;{item[this.props.treeName]}&nbsp;</span>)
                        }
                        return (<span onClick={() => {
                            this.navigationClick(item, index)
                        }} key={index} style={{
                            color: '#1890fe',
                            height: 45,
                            lineHeight: '45px'
                        }}>&nbsp;{item[this.props.treeName]}&nbsp;/</span>)
                    })
                }
                    <div style={{clear: 'both'}}/>
                </div>
                {this.state.menuList.length > 1 ? <div
                    style={{paddingLeft: 10, textAlign: 'left', color: this.state.menuList.length > 1 ? '#1890fe' : ''}}
                    onClick={() => {
                        this.backOne()
                    }}>&nbsp;返回上一级
                </div> : null}
            </div>
        )
    }

    //渲染底部按钮
    renderBottomBtn() {
        return (
            <div style={{width: '100%', height: 47, display: 'flex', position: 'absolute', bottom: 0}}>
                <div style={{width: '50%'}} onClick={() => {
                    this.setState({visible: false})
                }}><Button>取消</Button></div>
                <div style={{width: '50%'}}><Button type="primary" onClick={() => {
                    this.onOkClick()
                }}>确定</Button></div>
            </div>
        )
    }

    //点击上面的导航
    navigationClick(item, index) {//任务||资源库_id
        var treeNameKey = this.props.treeName;
        var subtreeKey = this.props.subtree;
        if (item[treeNameKey] === '全部') {
            this.setState({start:true});
            this.state.menuList.splice(index + 1, this.state.menuList.length + 1);
            this.state.currentDate = this.state.allData
        } else {
            this.state.currentDate = item[subtreeKey];
            this.state.menuList.splice(index + 1, this.state.menuList.length + 1)
        }
        this.backChoose();
        this.setState({})
    }

    //返回上一级
    backOne() {
        let treeNameKey = this.props.treeName;
        let subtreeKey = this.props.subtree;
        this.state.menuList.splice(this.state.menuList.length - 1, 1);
        if (this.state.menuList) {
            let currentTitle = this.state.menuList[this.state.menuList.length - 1][treeNameKey];
            if (currentTitle === '全部') {
                this.setState({start:true});
                this.state.currentDate = this.state.allData
            } else {
                this.state.currentDate = this.state.menuList[this.state.menuList.length - 1][subtreeKey];
            }
            this.backChoose()
        }
        this.setState({})
    }

    //用户每一项点击
    userClick(record, parentIndex) {
        if (this.props.choose === 'one') {
            let currentChecked = record.checked;
            this.state.currentDate.forEach((item, index) => {
                item.checked = false;
            });
            this.state.currentDate[parentIndex].checked = !currentChecked;
        }

        if (this.props.choose === 'more') {
            this.state.currentDate[parentIndex].checked = !record.checked;
        }

        let userChoose = [];
        this.state.currentDate && this.state.currentDate.forEach(item => {
            if (item.checked) {
                userChoose.push(item)
            }
        });

        this.setState({userChoose})
    }

    //点击确定返回
    onOkClick() {
        if (!this.state.userChoose.length) {
            Toast.info('请选择栏目');
            return;
        }
        let currentWeb=[];
        if(this.state.menuList.length>1){
            currentWeb=this.state.menuList[1]
        }
        this.props.currentWebCallback&&this.props.currentWebCallback(currentWeb);
        this.props.onOkClick && this.props.onOkClick(this.state.userChoose);
        setInfoToLocalStorage("currentMediaColumn",this.state.userChoose);
        setInfoToLocalStorage("currentMediaCode",this.state.userChoose[0]._mediaCode);
        this.setState({visible: false});
    }


    //点击每一行
    fileClick(item, index) {
        let treeNameKey = this.props.treeName;
        let subtreeKey = this.props.subtree;
        if (item.fileType === 'file') {
            this.state.menuList.push(item);
            this.state.currentDate = item[subtreeKey];
            this.backChoose();
            this.setState({start:false})
        }
    }

    //更新当前数据选中回选
    backChoose(){
        this.state.currentDate.forEach(Item=>{
            Item.checked=false;
            this.state.userChoose.forEach(item=>{
                if(Item[this.props.treeId]===item[this.props.treeId]){
                    Item.checked=true;
                }
            })
        })
    }


}

class TreeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let size,left,top;
        switch (this.props.item.fileType) {
            case 'file':
              size = 40;
                break;
            default:
                size = 25;
                top = 8;
                left= 13;
        }
        const item = this.props.item;
        return (
            <div style={{
                height: 45,
                display: 'flex',
                borderBottom: '1px solid #e5e5e5',
                marginTop: 5
            }}>
                <div style={{width: 40, height: 40, marginLeft: 20, position: 'relative'}} onClick={() => {
                    this.props.fileClick()
                }}>
                    {this.props.item.imgUrl ? <img style={{width:size, height:size, objectFit: 'cover',marginTop:top,marginLeft:left}}
                                                   src={this.props.item.imgUrl} alt=""/> : null}
                </div>
                <div style={{marginLeft: 20, width: 300}} onClick={() => {
                    this.props.fileClick()
                }}>
                    <div style={{
                        height: 40,
                        lineHeight: '40px',
                        color: '#999999',
                        fontSize: 14, textAlign: 'left', fontWeight: 400,
                        width: 200, overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }} title={item[this.props[this.props.treeName]]}>{item[this.props[this.props.treeName]]}</div>
                </div>
                {
                    item.fileType === 'file' ? null : <div>
                        <CheckboxItem
                            checked={item.checked} onChange={() => {
                            this.props.fileClick()
                        }}/>
                    </div>
                }
            </div>
        )
    }

}


