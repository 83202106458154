import React, {Component} from 'react'
import {Icon, NavBar, Toast, Menu, ActivityIndicator} from 'antd-mobile';
import {uuid} from "../../utils";
import {getInfoFromLocalStorage, getMediaByAccount, setInfoToLocalStorage} from "../../action";
import OriginalStoryTabs from "./OriginalStoryTabs";
import DropDownList from "../../components/dropDownList/DropDownList";

/**
 * 稿件列表页
 */
class OriginalResourcePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: "稿件",
            history: require("history").createHashHistory,
            toWriteStoryPage: false,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            toMediaPage: false,
            currentMediaCode: getInfoFromLocalStorage("currentMediaCode"),
            show: false,
            iconUpOrDown: 'down',
            defaultMediaValue: [],
            initData: [],
            chooseDeptModal: false,
            checkedDeptData: [],
            dataSource: [],
            depIds: [],
            mediaCode2Name:{},
        }

        this.showModal = this.showModal.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillMount() {
        if (!this.state.isContributeRoleFlag) {
            let param = {account: getInfoFromLocalStorage("userName")};
            getMediaByAccount(param, this.updateMediaList.bind(this));
        }
    }

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    componentDidMount() {
        this.setState({
            isContributeRoleFlag: this.state.isContributeFlag,
        });
    }

    /**
     * 返回按钮事件
     */
    goBack() {
        let path = '/index/homeTab';
        this.state.history().push(path);
    }

    /**
     * 更新媒体的列表到页面；
     * @param dataList
     */
    updateMediaList(dataList) {
        Toast.hide();
        //当前管理端的角色没有配置管媒体
        if (!dataList || dataList.length == 0) {
            Toast.info('您好，请给当前用户添加媒体', 2, null, false);
            return;
        }
        /**
         * 过滤出网站的媒体
         */
        let initData = [];
        let pageName = null;
        let mediaCode2Name={};
        for (var i = 0, length = dataList.length; i < length; i++) {
            var data = dataList[i];
            if (data.type == '1') {
                if (data.code == this.state.currentMediaCode) {
                    pageName = data.name;
                }
                var mediaDatad = {"title": data.name, "value": data.code};
                mediaCode2Name[data.code]=data.name;
                initData.push(mediaDatad);
            }
        }

        /**
         * 默认第一个媒体选中
         */
            //把媒体code放到sessionStory中
        let currentMediaCode = this.state.currentMediaCode;
        if (!this.state.currentMediaCode) {
            setInfoToLocalStorage('currentMediaCode', initData[0].value);
            currentMediaCode = initData[0].value;
        }
        this.setState({
            currentMediaCode: currentMediaCode,
            pageName: pageName ? pageName : initData[0].title,
            mediaCode2Name:mediaCode2Name,
            initData: initData,
            defaultMediaValue: [currentMediaCode]
        })
    }


    onChange = (value) => {
        let data = this.state.initData;
        data.forEach((dataItem) => {
            if (dataItem.value === value[0]) {
                let defaultMediaValues = value[0];
                setInfoToLocalStorage('currentMediaCode', value[0]);
                this.state.pageName = dataItem.label;
                this.state.currentMediaCode = value[0];
                this.state.defaultMediaValue = [defaultMediaValues];
            }
        });
        // console.log(label);
    }
    handleClick = (e) => {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            show: !this.state.show,
        });
        // mock for async data loading
        if (!this.state.initData) {
            setTimeout(() => {
                this.setState({
                    initData: this.state.initData,
                });
            }, 500);
        }
    }

    onMaskClick = () => {
        this.setState({
            show: false,
        });
    }

    /**
     * 获取选中的部门信息
     */
    getCheckedData(datas) {
        this.state.checkedDeptData = datas;
    }

    render() {
        const {initData, show} = this.state;
        var header = <NavBar key={uuid()}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={[<span key={uuid()} className={'nav_goback'}
                                                 onClick={() => this.goBack()}>返回</span>]}
                             mode="light"
                             rightContent={[]}
        ><div style={{width:'60vw'}} className={"story_list"}>
            <DropDownList list={initData} title={this.state.pageName} idKey={"value"} selectClick={(e)=>{
                console.info("dddddddddddd"+e);
                this.setState({currentMediaCode:e,pageName:this.state.mediaCode2Name[e]});
                setInfoToLocalStorage("currentMediaCode",e);
            }} ></DropDownList>
        </div></NavBar>;
        const menuEl = (
            <Menu
                className="single-foo-menu"
                style={{position: 'absolute', zIndex: '9999', width: '100%'}}
                data={initData}
                value={this.state.defaultMediaValue}
                level={1}
                onChange={this.onChange}
                height={document.documentElement.clientHeight * 0.3}
            />
        );
        const loadingEl = (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: document.documentElement.clientHeight * 0.5,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <ActivityIndicator size="large"/>
            </div>
        );
        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            {show ? initData ? menuEl : loadingEl : null}
            {show ? <div className="menu-mask" onClick={this.onMaskClick}/> : null}
            {this.state.currentMediaCode ? <OriginalStoryTabs mediaCode={this.state.currentMediaCode}/> : ''}
        </div>
    }
}
export default OriginalResourcePage;
