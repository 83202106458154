import store from "../../store";
import {getInfoFromLocalStorage} from "../../action";
import {checkUserTempStory,deleteUserTempStory,getTempStoryData,autoSaveTempStory} from "../../action/storyAction";
import {getSummaryNoAlert} from "../activity/StorySave";

export const autoSaveStoryData = function (editor) {
    let story = store.getState().storyReducer.story;
    let storyId = store.getState().storyReducer.storyId;
    let storyChanged = store.getState().storyReducer.storyChanged;
    let resources = store.getState().storyReducer.resources;
    let currentMediaCode = store.getState().storyReducer.currentMediaCode ||  getInfoFromLocalStorage('currentMediaCode');
    if(story && story.content && storyChanged){

        //避免修改原始的 story中的 chooseColumn， wbextends所以复制一份
        let copyStory=JSON.parse(JSON.stringify(story));

        //清除掉时间，处理 后台接收时间类型报错。
        delete copyStory.submitDate;
        delete copyStory.changeDate;

        copyStory.summary = getSummaryNoAlert(copyStory.content,200);
        copyStory.chooseColumn=JSON.stringify(copyStory.chooseColumn);
        copyStory.wbextends=JSON.stringify(copyStory.wbextends);
        autoSaveTempStory({story:copyStory,resources:resources,mediaCode:currentMediaCode,storyId:story.id||storyId},function(data){
            editor.props.setCurrentTempStoryId(data);
        });
    }
}

export const checkUserTempStoryData = function (editor) {
    let story = store.getState().storyReducer.story;
    let addStory = store.getState().storyReducer.addStory;
    let storyId = store.getState().storyReducer.storyId;
    let currentMediaCode = store.getState().storyReducer.currentMediaCode ||  getInfoFromLocalStorage('currentMediaCode');
    let data = new FormData();
    data.append('mediaCode', currentMediaCode);
    if((story.id||storyId) && !addStory ){
        data.append('storyId', storyId||story.id);
    }
    checkUserTempStory(data,function (data) {
        if(data == true){
            editor.setState({
                historyStory:data
            });
        }
    });
}

export const deleteAllUserTempStoryData = function (editor) {
    let currentMediaCode = store.getState().storyReducer.currentMediaCode ||  getInfoFromLocalStorage('currentMediaCode');
    let story = store.getState().storyReducer.story;
    let addStory = store.getState().storyReducer.addStory;
    let data = new FormData();
    data.append('mediaCode', currentMediaCode);
    if(story.id && !addStory){
        data.append('storyId', story.id);
    }
    deleteUserTempStory(data,function (data) {
            editor.setState({
                historyStory:false
            });
    });
}
export const getTempStoryDataList = function(data,callBack){
    let story = store.getState().storyReducer.story;
    let addStory = store.getState().storyReducer.addStory;
    let storyId = store.getState().storyReducer.storyId;
    if((story.id||storyId) && !addStory ){
        data.append('storyId', storyId||story.id);
    }
    getTempStoryData(data,callBack);
}

