import React, {Component} from 'react';
import {ActivityIndicator, Button, Card, Icon, List, Menu, NavBar, Radio, WingBlank} from "antd-mobile";
import {
    getInfoFromLocalStorage,
    getMediaByAccount,
    localLogin,
    setInfoToLocalStorage
} from "../../action";
import {uuid} from "../../utils";
import {setContributeRoleFlag, setCurrentMedia, setStoryId, setUserInfo} from "../../store/actions";
import {connect} from "react-redux";
import Toast from "antd-mobile/lib/toast";
import './mediaSelect.css'
import ListTabPage from "./ListTabPage";

const RadioItem = Radio.RadioItem;

/**
 * 媒体选择页面
 */
class MediaSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initData: '',
            show: false,
            pageName: "媒体选择",
            mediaList: [],
            history: require("history").createHashHistory,
            childMediaList: [],
            buttonValue: '',
            loginStatus: '',
            isContributeflag: false,
        };
        this.showSelectMediaPanel = this.showSelectMediaPanel.bind(this);

    }


    componentDidMount() {
        let firstPagePame
        if (this.props.userName) {
            firstPagePame = this.props;
        } else {

            firstPagePame = JSON.parse(this.props.match.params.userName);
        }
        this.userName = firstPagePame.userName;
        let param = {'account': firstPagePame.userName}
        let pageLogin = firstPagePame.pageLogin;
        Toast.loading('加载中...', 0, () => {
        });
        if (pageLogin) {

            //页面登陆
            this.toShowPage('sys.001');

        } else {

            //嵌套路径登陆
            localLogin(param, this.toShowPage.bind(this));
        }

    }

    /**
     * 更新媒体的列表到页面；
     * @param dataList
     */
    updateMediaList(dataList) {
        Toast.hide();
        //当前管理端的角色没有配置管媒体
        if (!dataList || dataList.length == 0) {
            Toast.info('您好，请给当前用户添加媒体', 2, null, false);
            return;
        }

        /**
         * 过滤出网站的媒体
         */
        let websiteMedia = [];
        for (var i = 0, length = dataList.length; i < length; i++) {
            var data = dataList[i];
            if (data.type == '1') {
                var mediaDatad = {"label": data.name, "value": data.code};
                websiteMedia.push(mediaDatad);
            }
        }

        /**
         * 默认第一个媒体选中
         */
        this.setState({defaultSelectMediaCode: websiteMedia[0].value});
        this.props.setCurrentMedia(websiteMedia[0].value);
        //把媒体code放到sessionStory中
        setInfoToLocalStorage('currentMediaCode', websiteMedia[0].value);

        let webSite = {
            'label': '网 站',
            'value': '1',
            'children': websiteMedia,
        }

        let mediaData = [webSite];
        this.setState({
            mediaList: mediaData,
            childMediaList: websiteMedia,
            value: websiteMedia[0].value,
            buttonValue: '网 站'
        })
        this.showSelectMediaPanel();
    }


    /**
     * 单选列表
     * @param value
     */
    onChangeChild = (value) => {
        //把媒体code放到sessionStory中
        setInfoToLocalStorage('currentMediaCode', value);
        this.props.setCurrentMedia(value);
        this.setState({
            value,
        });
    };

    componentWillUnmount() {
        this.setState({
            isContributeRoleFlag: false
        })
    }


    //如果有权限展示页面，
    toShowPage(hasPermission) {
        let hasPermi = false;
        if (hasPermission === 'sys.001') {
            hasPermi = true;
            //登录结束后，需要获取用户的信息

            if (!this.props.isContributeRoleFlag) {
                let param = {account: this.userName};
                getMediaByAccount(param, this.updateMediaList.bind(this));

            } else {
            }

        } else {
            hasPermi = false;
        }
        this.setState({
            hasPermission: hasPermi,
            loginStatus: '登录失败'
        })
    }


    onChange = (value) => {
        let label = '';
        this.state.mediaList.forEach((dataItem) => {
            if (dataItem.value === value[0]) {
                label = dataItem.label;
                if (dataItem.children && value[1]) {
                    dataItem.children.forEach((cItem) => {
                        if (cItem.value === value[1]) {
                            label += ` ${cItem.label}`;
                        }
                    });
                }
            }
        });

        //把媒体code放到sessionStory中
        setInfoToLocalStorage('currentMediaCode', value[1]);
        this.props.setCurrentMedia(value[1]);
    }


    /**
     * 显示媒体选择内容页面
     */
    showSelectMediaPanel = () => {
        this.setState({
            show: !this.state.show,
        });
        // mock for async data loading
        if (!this.state.initData) {
            setTimeout(() => {
                this.setState({
                    initData: this.state.mediaList,
                });
            }, 500);
        }
    }


    /**
     * 媒体选择确定按钮
     */
    okSelectMedia() {
        //把上一个页面压到history中，
        let path = '/listTabPage/listTabPage';
        this.state.history().push(path);
    }

    /**
     * 返回按钮事件
     */
    goBack() {
        let path = '/index/homeTab';
        this.state.history().push(path);
    }

    /**
     * 选择不同的按钮，渲染不同的 子媒体
     * @param buttonData
     */
    clickButton = (buttonData) => {
        let childMediaList = [];
        let mediaList = this.state.mediaList
        for (var i = 0, length = mediaList.length; i < length; i++) {
            if (buttonData.label === mediaList[i].label) {
                childMediaList = mediaList[i].children;
                break;
            }
        }

        this.setState({
            buttonValue: buttonData.label,
            childMediaList: childMediaList,
        })
    }

    render() {
        const {value, buttonValue} = this.state;
        if (!this.state.hasPermission) {
            return <div>
                <NavBar
                    mode="light"
                    leftContent={[<span key={uuid()}  className={'nav_goback'} onClick={() => this.goBack()}>返回</span>]
                    }
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    rightContent={[
                        <div key={uuid()} onClick={this.okSelectMedia.bind(this)}  className={'nav_goback'}>确定</div>
                    ]}
                ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>
                <div style={{textAlign: 'center'}}>{this.state.loginStatus}</div>
            </div>
        }
        return this.props.isContributeRoleFlag ?
            <ListTabPage isContributeFlag={this.props.isContributeRoleFlag}/> : (
                <div>
                    <NavBar
                        icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                        mode="light"
                        leftContent={this.state.isContributeflag ? [] : [<span key={uuid()} className={'nav_goback'} onClick={() => this.goBack()}>返回</span>]
                        }
                        rightContent={[
                            <div key={uuid()} onClick={this.okSelectMedia.bind(this)}
                                 className={'nav_goback'}>确定</div>
                        ]}
                    ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>
                    <WingBlank size="lg" style={{margin:'5px'}}>
                        <Card style={{marginTop: '15px'}}>
                            <Card.Header
                                title="媒体选择"
                                thumb="images/shuxian.png"
                            />
                            <Card.Body>
                                {this.state.mediaList.map((item) => {
                                    if (item.label === buttonValue) {
                                        return <Button key={uuid()} onClick={() => {
                                            this.clickButton(item)
                                        }} type="primary" size={"small"} inline style={{
                                            marginRight: '4px'
                                        }}>{item.label}</Button>
                                    } else {
                                        return <Button key={uuid()} onClick={() => {
                                            this.clickButton(item)
                                        }} type="ghost" size={"small"} inline style={{
                                            marginRight: '4px'
                                        }}>{item.label}</Button>
                                    }

                                })}
                            </Card.Body>
                        </Card>

                        <Card style={{marginTop: '15px'}}>
                            <Card.Header
                                title="子媒体选择"
                                thumb="images/shuxian.png"
                            />
                            <Card.Body>
                                <List>
                                    {this.state.childMediaList.map(i => (
                                        <RadioItem key={i.value} checked={value === i.value}
                                                   onChange={() => this.onChangeChild(i.value)}>
                                            <span style={{fontSize: '15px'}}>{i.label}</span>
                                        </RadioItem>
                                    ))}
                                </List>
                            </Card.Body>
                        </Card>
                    </WingBlank>
                </div>
            );
    }
}


const dispatchToProps = (dispatch) => {
    return {
        setCurrentMedia: function (mediaCode) {
            dispatch(setCurrentMedia(mediaCode));
        },
        setUserInfo: function (userInfo) {
            dispatch(setUserInfo(userInfo));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setContributeRoleFlag: function (contributeRoleFlag) {
            dispatch(setContributeRoleFlag(contributeRoleFlag));
        }
    }
}

export default connect(null, dispatchToProps)(MediaSelect);

