/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import Contributions from '../../components/manuscriptList/ManuscriptList'
import {Icon, NavBar} from "antd-mobile";
import * as Actions from "../../action/taskManagement";
import {formatDate, uuid} from "../../utils";
import none from './img/null.png'
import {connect} from "react-redux";
import {
    setAddStory, setCurrentTempStoryId,
    setHasShowHistoryStory,
    setStory,
    setStoryId,
    setStoryResource
} from "../../store/actions/storyAction";
import {setNoRefresh, setStoryAttribute} from "../../store/actions";

 class Contribution extends Component {
    static defaultProps = {};
    static propTypes = {};

    constructor(props) {
        super(props);
        this.state = {
            dataList:[],
            id:'',
            status:'',
        }
    }

    componentWillMount() {
    }
    componentDidMount() {
      console.log(this)
       let id = this.props.match.params.id;
        let status = this.props.match.params.status;
       console.log(typeof(status))
       this.setState({id:id,status:status});

        Actions.getResource({interviewId:id}, (data) => {
            data.storyList.forEach((item=>{
                let text = item.content.replace(/<[^<>]+>/g,"");

               item.contentNew = text;
                let titleCover;
                if(item.titlePic){
                    titleCover = item.titlePic
                }else if(item.content.indexOf('<img')>-1){
                    let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
                    let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
                    let arr = item.content.match(imgReg)  //筛选出所有的img
                    console.log(arr)
                    let srcArr = []
                    for (let i = 0; i < arr.length; i++) {
                        let src = arr[i].match(srcReg)
                        // 获取图片地址
                        srcArr.push(src[1])
                    }
                    titleCover = srcArr[0]
                }else{
                    titleCover= none
                }
              item.titleCover =titleCover
            }));
            this.setState({dataList:data.storyList});
        });

    }
    render() {
        return (
            <div>
                <NavBar
                    key={'稿件列表'}
                    mode={'light'}
                    icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                    leftContent={[
                        <div className={'nav_goback'}  onClick={()=>this.props.history.goBack()} key={uuid()}>返回</div>
                    ]}

                ><span className={'nav_title'}>稿件列表</span></NavBar>
                <div style={{paddingBottom:'13vw'}}>
                    {this.state.dataList&&this.state.dataList.length>0&&this.state.dataList.map((item,index)=>{
                        return(  <Contributions title={item.title} img={item.titleCover}  content={item.contentNew}
                                                name={item.user.name} key={index} createTime={formatDate(item.changeDate.time)} type={item.statusName}
                                                manuscriptDetails={()=>this.manuscriptDetails(item)}></Contributions>)
                    })}
                </div>
                {/*{this.state.dataList&&this.state.dataList.length?null:<div style={{textAlign:"center",color:'#999999',marginTop:200}}>暂无数据</div>}*/}
                {this.state.status==='true'?<div style={{
                    lineHeight: '12vw',
                    backgroundColor: '#1890fe',
                    textAlign: 'center',
                    color: 'white',
                    position: 'fixed',
                    width: '100%',
                    bottom: '0'
                }} onClick={() =>{this.establish()}}>创建稿件
                </div>:null}

            </div>
        )
    }
    establish(){
        this.props.history.push('/taskInterview/writeStory/'+ 0 +'/'+this.state.id+'/5')
        this.props.setAddStory(false);
        this.props.setStory({});
    }
    goBack(){
        this.props.history.goBack();
    }
    manuscriptDetails(item){
        this.props.setAddStory(false);
        this.props.history.push('/taskInterview/writeStory/'+ item.id +'/'+this.state.id+'/5')
    }
    getDetsils(){
        Actions.taskListDetails({interviewId:'267'}, (data) => {
            let reporterType=[];
            if(data){
                for (let i in data.reporterType) {
                    reporterType.push({
                        type:i,
                        valList:data.reporterType[i]
                    });
                }
            }
            data.reporterType=reporterType;
            if(data.interview && data.interview.attachmentResourceIds){
                Actions.enclosure({attachmentIds:data.interview.attachmentResourceIds}, (data) => {
                    this.setState({attachmentsList:data})
                });
            }
            this.setState({dataSource:data,eventItem:data.eventItem,reporterType:reporterType,mediaNameList:data.mediaNameList})
        });
    }
}
const state2Props = (state) => {
    return {
        contributeRoleFlag: state.storyReducer.contributeRoleFlag,
        userInfo: state.storyReducer.userInfo,
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttribute) {
            dispatch(setStoryAttribute(storyAttribute));
        },
        setStoryResource: function (resource) {
            dispatch(setStoryResource(resource));
        },
        setAddStory: function (addStory) {
            dispatch(setAddStory(addStory));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setHasShowHistoryStory(hasShowHistoryStory) {
            dispatch(setHasShowHistoryStory(hasShowHistoryStory));
        },
        setCurrentTempStoryId: function (tempStoryId) {
            dispatch(setCurrentTempStoryId(tempStoryId));
        },
        setNoRefresh: function (noRefresh) {
            dispatch(setNoRefresh(noRefresh));
        },
    }
}
export default connect(state2Props, dispatchToProps)(Contribution);