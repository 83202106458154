/**
 * created by yxy
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './taskList.css'
import { WingBlank, WhiteSpace,Popover,Modal} from 'antd-mobile';
import { Enumeration,TOPICSTATUS} from '../../utils/Enumeration';
import * as Actions from "../../action/topiclist";
const Item = Popover.Item;
export default class TaskListItem extends Component {
    static defaultProps = {
        title: '刘同，湖南郴州人，1981年2月27日生，毕业于湖南师范大学中文系，青年作家',//传入标题
        state:1,//未领取 进行中
        content:'2020年出版全新散文作品《一个人就一个人》。2019年由其小说改编并担任联合出品人和监制的的同名电视剧《我在未来等你》播出，',//任务内容
        type:'任务来源',//任务来源
        operation:false,//是否显示操作
        goToPage:()=>{

        },
        refresh:()=>{

        }
    };
    static propTypes = {
        title:PropTypes.string.isRequired,//传入标题
        state:PropTypes.number.isRequired,//未领取 进行中
        content:PropTypes.string.isRequired,//任务内容
        type:PropTypes.string.isRequired,//任务来源
        operation:PropTypes.bool.isRequired,//是否显示操作
        goToPage:PropTypes.func.isRequired,//点击的方法
        id:PropTypes.number,//选题列表使用id
        operateAuths:PropTypes.array,//获取权限
        refresh:PropTypes.func//刷新的方法
    };
    constructor(props) {
        super(props);
        this.state = {
            visible:false,
            remarks:false,
            remark:'',
            list:[],
        }
    }
    componentWillMount() {
    }
    componentDidMount() {

    }
    render() {
        const WingBlankExample = () => {
             let bgColor;
             let statusName;
             if(this.props.operation===false){
                 switch(this.props.state){
                     case Enumeration.notReceived.statusType : statusName= Enumeration.notReceived.statusName;bgColor=Enumeration.notReceived.bgColor; break;
                     case Enumeration.inProgress.statusType : statusName= Enumeration.inProgress.statusName;bgColor=Enumeration.inProgress.bgColor; break;
                     case Enumeration.Submit.statusType : statusName= Enumeration.Submit.statusName;bgColor=Enumeration.Submit.bgColor; break;
                     case Enumeration.complete.statusType : statusName= Enumeration.complete.statusName;bgColor=Enumeration.complete.bgColor; break;
                     case Enumeration.overdue.statusType : statusName= Enumeration.overdue.statusName;bgColor=Enumeration.overdue.bgColor; break;
                 }
             }else{
                 switch(this.props.state ){
                     case TOPICSTATUS.draft.statusType: statusName=  TOPICSTATUS.draft.statusName;bgColor= TOPICSTATUS.draft.bgColor; break;
                     case  TOPICSTATUS.submit.statusType: statusName=  TOPICSTATUS.submit.statusName;bgColor= TOPICSTATUS.submit.bgColor; break;
                     case  TOPICSTATUS.withdraw.statusType: statusName=  TOPICSTATUS.withdraw.statusName;bgColor= TOPICSTATUS.withdraw.bgColor; break;
                     case  TOPICSTATUS.retention.statusType: statusName=  TOPICSTATUS.retention.statusName;bgColor= TOPICSTATUS.retention.bgColor; break;
                     case  TOPICSTATUS.disuse.statusType: statusName=  TOPICSTATUS.disuse.statusName;bgColor= TOPICSTATUS.disuse.bgColor; break;
                     case  TOPICSTATUS.use.statusType: statusName=  TOPICSTATUS.use.statusName;bgColor= TOPICSTATUS.use.bgColor; break;
                     case  TOPICSTATUS.task.statusType: statusName=  TOPICSTATUS.task.statusName;bgColor= TOPICSTATUS.task.bgColor; break;
                     case  TOPICSTATUS.collaboration.statusType: statusName=  TOPICSTATUS.collaboration.statusName;bgColor= TOPICSTATUS.collaboration.bgColor; break;
                 }
             }
            let paddingTop,borderTop,borderBoxTop;
            if(this.props.operation === false){
                paddingTop= 0;borderTop =null;borderBoxTop=null;
            }else{
                paddingTop= 10;borderTop='1px solid #f2f2f2'
             }
            return(
                <div >
                    <WhiteSpace size="lg" />
                        <div className={'TaskListBox'}>{
                            this.props.operation === true? <style>
                                {`
                               .am-wingblank.am-wingblank-lg{margin-left: 0px; margin-right: 0px;}
                                 .am-wingblank-lg{margin-left: 0px; margin-right: 0px;} 
                                 .am-wingblank{margin-left: 0px; margin-right: 0px;}
                               
                                `}
                            </style>:null
                        }
                        <div>
                            <div onClick={()=>{this.props.goToPage()}}>
                                <div className={'flex'}  >
                                    <div className={'title textOverflow'}>{this.props.title}</div>

                                        <div className={'TaskListStatus'} >
                                            <span style={{backgroundColor:bgColor}}></span>
                                            <span style={{color:bgColor}}>{statusName}</span></div>

                                </div>
                                <div className={'content'} >
                                    <div className={'taskContent textOverflow'}>{this.props.content}</div>
                                </div>
                            </div>

                            <div className={'flex content'}  style={{borderTop:borderTop,paddingTop:paddingTop}}>
                                <div >{this.props.type}</div>
                                {
                                    this.props.operation&&this.props.operateAuths.length>0? <Popover mask
                                                                                                     overlayClassName="fortest"
                                                                                                     overlayStyle={{ color: 'currentColor' }}
                                                                                                     visible={this.state.visible}
                                                                                                     overlay={[this.props.operateAuths&&this.props.operateAuths.map((item,index)=>{
                                                                                                         let name,key;
                                                                                                         switch (item) {
                                                                                                             case 1:
                                                                                                                 name = '修改';
                                                                                                                 key = '1';
                                                                                                                 break;
                                                                                                             case 2:
                                                                                                                 name = '删除';
                                                                                                                 key = '2';
                                                                                                                 break;
                                                                                                             case 3:
                                                                                                                 name = '撤回';
                                                                                                                 key = '3';
                                                                                                                 break;
                                                                                                             case 4:
                                                                                                                 name = '留用';
                                                                                                                 key = '4';
                                                                                                                 break;
                                                                                                             case 5:
                                                                                                                 name = '弃用';
                                                                                                                 key = '5';
                                                                                                                 break;
                                                                                                             case 6:
                                                                                                                 name = '采用';
                                                                                                                 key = '6';
                                                                                                                 break;
                                                                                                             case 7:
                                                                                                                 name = '查看备注';
                                                                                                                 key = '7';
                                                                                                                 break;
                                                                                                             case 8:
                                                                                                                 name = '指派写作';
                                                                                                                 key = '8';
                                                                                                                 break;
                                                                                                         }
                                                                                                         return  (<Item key={key}>{name}</Item>)
                                                                                                     })]}
                                                                                                     align={{
                                                                                                         overflow: { adjustY: 0, adjustX: 0 },
                                                                                                         offset: [-10, 0],
                                                                                                     }}
                                                                                                     onVisibleChange={this.handleVisibleChange}
                                                                                                     onSelect={this.onSelect}
                                    >
                                        <div style={{color:'#7c53c9',position:'relative',zIndex:'999',width:'20vw',textAlign:'right'}}>操作</div>
                                    </Popover>:null
                                }
                            </div>
                        </div>

                        </div>
                    <Modal
                        visible={this.state.remarks}
                        transparent
                        maskClosable={false}
                        onClose={this.onClose('remarks')}
                        footer={[{ text: 'Ok', onPress: () => { console.log('ok'); this.onClose('modal1')(); } }]}
                        wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                    >
                        <div style={{padding:'10px',wordWrap:'break-word',maxHeight:'80px',lineHight:'15px'}}>
                            {this.state.remark === 'null'?'':this.state.remark}
                        </div>
                    </Modal>
                </div>
            );
        };
        return (
            <WingBlankExample />
        )
    }
    onSelect = (opt) => {
        if(opt.key === '3'||opt.key === '4'||opt.key === '5'||opt.key === '6'){
            Actions.editStatus( {id:this.props.id,tsStatus:opt.key},(data) => {
                this.props.refresh&&this.props.refresh();
                this.setState({visible:false});
            });
        }
        if(opt.key === '8'){
           window.location.hash='/Assignment/Assignment/'+this.props.id
        }
       if(opt.key === '7'){
           Actions.takeRemark( {id:this.props.id},(data) => {
             this.setState({remark:data.tsRemark})
           });
           this.setState({remarks:true,visible:false})
       }
        if(opt.key === '1'){
            window.location.hash='addTopicList/addTopicList/'+this.props.id
        }
        if(opt.key === '2'){
            Actions.deleteList( {id:this.props.id},(data) => {
                this.props.refresh&&this.props.refresh();
                this.setState({visible:false});
            })
        }
    };
    handleVisibleChange = (visible) => {
        this.setState({visible});
    };
    onClose = key => () => {
        this.setState({remarks: false});
    }
}