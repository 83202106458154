import React, {Component} from 'react'
import {Icon, NavBar, Toast, Menu, ActivityIndicator, List} from 'antd-mobile';
import './MyFirstPage.css';
import './resourcepool_page.css';
import {uuid} from "../../utils";
import {connect} from "react-redux";
import {
    setStory,
    setStoryResource,
    setAddStory,
    setStoryId,
    setHasShowHistoryStory, setCurrentTempStoryId
} from "../../store/actions/storyAction";
import {setStoryAttribute} from "../../store/actions";
import {getAccountDepts, getInfoFromLocalStorage, getMediaByAccount, setInfoToLocalStorage} from "../../action";
import ResourceDataList from "../../components/ResourceDataList";
import Modal from "antd-mobile/es/modal";
import SelectListView from "../../components/SelectListView/SelectListView";
import DropDownList from "../../components/dropDownList/DropDownList";

/**
 * 稿件列表页
 */
class ResoucePoolPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: "稿件",
            history: require("history").createHashHistory,
            toWriteStoryPage: false,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            toMediaPage: false,
            currentMediaCode: getInfoFromLocalStorage("currentMediaCode"),
            show: false,
            iconUpOrDown: 'down',
            defaultMediaValue: [],
            initData: [],
            chooseDeptModal: false,
            checkedDeptData: [],
            dataSource: [],
            depIds:[],
            mediaCode2Name:{},

        }

        this.showModal = this.showModal.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillMount() {
        if (!this.state.isContributeRoleFlag) {
            let param = {account: getInfoFromLocalStorage("userName")};
            getMediaByAccount(param, this.updateMediaList.bind(this));

            //获取部门信息；
            getAccountDepts({}, this.callGetAccountDepts.bind(this))

        }
    }

    callGetAccountDepts(data) {
        if (data) {
            let checkedData = JSON.parse(JSON.stringify(data));
            this.setState({
                dataSource: data,
                checkedDeptData: checkedData,
            })
        }

    }

    showModal = key => (e) => {
        e.preventDefault(); // 修复 Android 上点击穿透
        this.setState({
            [key]: true,
        });
    }

    onClose = key => () => {
        this.setState({
            [key]: false,
        });
    }

    componentDidMount() {
        this.setState({
            isContributeRoleFlag: this.state.isContributeFlag,
        });
        this.props.setStory({});
        this.props.setStoryId(0);
        this.props.setStoryAttribute({});
        this.props.setStoryResource({picture: [], video: [], audio: []});
        this.props.setAddStory(false);
        this.props.setHasShowHistoryStory(true);
        this.props.setCurrentTempStoryId(0);
    }

    /**
     * 返回按钮事件
     */
    goBack() {
        setInfoToLocalStorage("currentMediaCode","");
        let path = '/index/homeTab';
        this.state.history().push(path);
    }

    /**
     * 更新媒体的列表到页面；
     * @param dataList
     */
    updateMediaList(dataList) {
        Toast.hide();
        //当前管理端的角色没有配置管媒体
        if (!dataList || dataList.length == 0) {
            Toast.info('您好，请给当前用户添加媒体', 2, null, false);
            return;
        }
        /**
         * 过滤出网站的媒体
         */
        let initData = [];
        let pageName = null;
        let mediaCode2Name={};
        for (var i = 0, length = dataList.length; i < length; i++) {
            var data = dataList[i];
            if (data.type == '1') {
                if (data.code == this.state.currentMediaCode) {
                    pageName = data.name;
                }
                var mediaDatad = {"title": data.name, "value": data.code};
                mediaCode2Name[data.code]=data.name;
                initData.push(mediaDatad);
            }
        }

        /**
         * 默认第一个媒体选中
         */
            //把媒体code放到sessionStory中
        let currentMediaCode = this.state.currentMediaCode;
        if (!this.state.currentMediaCode) {
            setInfoToLocalStorage('currentMediaCode', initData[0].value);
            currentMediaCode = initData[0].value;
        }
        this.setState({
            currentMediaCode: currentMediaCode,
            pageName: pageName ? pageName : initData[0].title,
            initData: initData,
            mediaCode2Name:mediaCode2Name,
            defaultMediaValue: [currentMediaCode]
        })
    }


    /**
     * 调到写稿页面
     */
    toWritePage() {
        var writePath = "/writeStory/0/0/2/1";
        this.state.history().push(writePath);
    }

    onChange = (value) => {
        let data = this.state.initData;
        data.forEach((dataItem) => {
            if (dataItem.value === value[0]) {
                let defaultMediaValues = value[0];
                setInfoToLocalStorage('currentMediaCode', value[0]);
                this.state.pageName = dataItem.label;
                this.state.currentMediaCode = value[0];
                this.state.defaultMediaValue = [defaultMediaValues];
            }
        });
        // console.log(label);
    }
    handleClick = (e) => {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            show: !this.state.show,
        });
        // mock for async data loading
        if (!this.state.initData) {
            setTimeout(() => {
                this.setState({
                    initData: this.state.initData,
                });
            }, 500);
        }
    }

    onMaskClick = () => {
        this.setState({
            show: false,
        });
    }


    /**
     * 修改标题的  下拉  icon
     */
    changeIcon = () => {
        var icon = 'up';
        if (this.state.iconUpOrDown === 'up') {
            icon = 'down';
        }
        this.setState({
            iconUpOrDown: icon
        })
    }

    /**
     * 获取选中的部门信息
     */
    getCheckedData(datas) {
        this.state.checkedDeptData = datas;
    }

    /**
     * 设置过滤部门信息；
     */
    setDetpId() {
        let checkedDeptInfos=this.state.checkedDeptData;
        let depIds=[];
        if(checkedDeptInfos&&checkedDeptInfos.length>0){
            for(var i=0;i<checkedDeptInfos.length;i++){
                depIds.push(checkedDeptInfos[i].id);
            }
        }
        this.setState({depIds:depIds});
    }

    render() {
        const {initData, show} = this.state;
        var header = <NavBar key={uuid()}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={[<span key={uuid()} className={'nav_goback'} onClick={() => this.goBack()}>返回</span>]}
                             mode="light"
                             rightContent={[
                             ]}
        ><div style={{width:'60vw'}} className={"story_list"}>
                <DropDownList list={initData} title={this.state.pageName} idKey={"value"} selectClick={(e)=>{
                   this.setState({currentMediaCode:e,pageName:this.state.mediaCode2Name[e]});
                    setInfoToLocalStorage("currentMediaCode",e);
                }} ></DropDownList>
        </div>
            </NavBar>;

        const menuEl = (
            <Menu
                className="single-foo-menu"
                style={{position: 'absolute', zIndex: '9999', width: '100%'}}
                data={initData}
                value={this.state.defaultMediaValue}
                level={1}
                onChange={this.onChange}
                height={document.documentElement.clientHeight * 0.3}
            />
        );
        const loadingEl = (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: document.documentElement.clientHeight * 0.5,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <ActivityIndicator size="large"/>
            </div>
        );


        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            {/*{show ? initData ? menuEl : loadingEl : null}*/}
            {/*{show ? <div className="menu-mask" onClick={this.onMaskClick}/> : null}*/}
            {this.state.currentMediaCode ? <ResourceDataList depId={this.state.depIds}
                                                             mediaCode={this.state.currentMediaCode}/> : ''}
            <Modal
                popup
                visible={this.state.chooseDeptModal}
                title={'部门选择'}
                onClose={this.onClose('chooseDeptModal')}
                animationType="slide-up"
                afterClose={() => {
                }}
                footer={[{
                    text: '确定', onPress: () => {
                        // this.showCheckReporter();
                        this.setDetpId();

                        this.onClose('chooseDeptModal')();
                    }
                }]}
            >
                <div>
                    <div style={{height: '350px'}}>
                        <SelectListView defaultCheckedDatas={this.state.checkedDeptData}
                                        dataSource={this.state.dataSource}
                                 singleSelect={true}       getCheckedData={this.getCheckedData.bind(this)}/>
                    </div>
                </div>
            </Modal>
        </div>
    }
}

/**
 *
 * @param state
 */
const state2Props = (state) => {
    return {
        contributeRoleFlag: state.storyReducer.contributeRoleFlag,
        userInfo: state.storyReducer.userInfo,
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttribute) {
            dispatch(setStoryAttribute(storyAttribute));
        },
        setStoryResource: function (resource) {
            dispatch(setStoryResource(resource));
        },
        setAddStory: function (addStory) {
            dispatch(setAddStory(addStory));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setHasShowHistoryStory(hasShowHistoryStory) {
            dispatch(setHasShowHistoryStory(hasShowHistoryStory));
        },
        setCurrentTempStoryId: function (tempStoryId) {
            dispatch(setCurrentTempStoryId(tempStoryId));
        }
    }
}

export default connect(state2Props, dispatchToProps)(ResoucePoolPage);
