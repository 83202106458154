import React, {Component} from 'react';
import {Icon, NavBar} from "antd-mobile";
import {uuid} from "../../../utils";
import ManuscriptTabs from "./ManuscriptTabs";

/**
 * 约稿列表页面
 */

class ManuscriptPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageName: '约稿',
            isContributeRoleFlag: this.props.match.params.isContributeRoleFlag,
            history: require("history").createHashHistory,
            goBack: this.props.match.params.goBack,
        }
    }

    goBack() {
        if (this.state.goBack !== 'false') {
            let path = '/index/homeTab/';
            this.state.history().push(path);
        }
    }

    /**
     * 新增约稿
     */
    addManuscript() {
        let path = '/addManuscriptPage/addManuscriptPage';
        this.state.history().push(path);
    }


    render() {
        var header = <NavBar key={uuid()}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={this.state.goBack === 'false' ? [] : [<span className={'nav_goback'} onClick={() => this.goBack()} key={uuid()}>返回</span>]}
                             mode="light"
                             rightContent={[this.state.isContributeRoleFlag === 'true' ? '' :
                                 <div key={uuid()} style={{marginRight:10}} onClick={() => {
                                     this.addManuscript();
                                 }} className={'nav_goback'}><img src={'images/tianjia.png'} width={'18px'}/>
                                 </div>
                             ]}
        ><span className={'nav_title'}>{this.state.pageName}</span></NavBar>;

        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            <ManuscriptTabs isContributeRoleFlag={this.state.isContributeRoleFlag}></ManuscriptTabs>
        </div>
    }
}

export default ManuscriptPage;
