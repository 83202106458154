export const STORY_ID = "STORY_ID";

export const CURRENT_MEDIA_CODE = "CURRENT_MEDIA_CODE";

export const USER_INFO = "USER_INFO";

export const STORY_ATTRIBUTE = "STORY_ATTRIBUTE"  //稿件属性的数据

export const STORY = "STORY"  //稿件属性

export const STORY_CONTENT = "STORY_CONTENT"  //稿件属性

export const STORY_FORMAT = "STORY_FORMAT"  //稿件属性--一键排版

export const STORY_TITLE = "STORY_TITLE"  //稿件属性

export const DATA_SOURCE = "DATA_SOURCE"  //稿件数据来源

export const ACT_CHOOSE = "ACT_CHOOSE"   //候选人列表数据

export const ACT_REMARK = "ACT_REMARK"   //   批注数据

export const STORY_STATUS = "STORY_STATUS"  //稿件属性

export const CONTRIBUTE_ROLE_FLAG = "CONTRIBUTE_ROLE_FLAG"  //投稿端角色标识

export const NO_REFRESH = "NO_REFRESH"; //页面是否从新加载story

export const ADD_RESOURCE = "ADD_RESOURCE"; //增加稿件上传资源，save需要

export const ADD_RESOURCE_PICTURE = "PICTURE";

export const ADD_RESOURCE_VIDEO = "VIDEO";

export const ADD_RESOURCE_AUDIO = "AUDIO";

export const SET_RESOURCE = "SET_RESOURCE";//增加稿件上传资源，save需要 重置

export const TO_LIST_PAGE = "TO_LIST_PAGE";  //跳到列表页面；

export const AUDIT_OPINIONS = "AUDIT_OPINIONS"; //是否弹出审核意见

export const ADD_STORY = "ADD_STORY"; //是否新增稿件

export const SELECTED_TAB = "SELECTED_TAB"  //导航栏选择 栏目

export const ADD_TEMP_STORY_ID = "ADD_TEMP_STORY_ID"  //导航栏选择 栏目

export const HAS_SHOW_HISTORY_STORY = "HAS_SHOW_HISTORY_STORY"; //是否展示历史恢复

export const STORY_CONTENT_CHANGED = "STORY_CONTENT_CHANGED"; //稿件内容变更

export const COLUMN_OF_MEDIACODEL = "COLUMN_OF_MEDIACODEL";  //媒体的栏目

export const SET_WEB_EXTENDS = "SET_WEB_EXTENDS";  //稿件的扩展字段

export const STORY_LINKURL = "STORY_LINKURL"; //稿件链接地址

export const PROCESS_DEPLOYKEY="PROCESS_DEPLOYKEY";  //流程部署的key, 用来判断流程代码走完到那个页面；





