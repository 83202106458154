//redux存储稿件id,全页面使用的
import {
    STORY_ID,
    CURRENT_MEDIA_CODE,
    STORY_ATTRIBUTE,
    STORY_CONTENT,
    STORY_TITLE,
    STORY_FORMAT,
    STORY,
    USER_INFO,
    ACT_CHOOSE,
    ACT_REMARK,
    STORY_STATUS,
    CONTRIBUTE_ROLE_FLAG,
    NO_REFRESH,
    ADD_RESOURCE,
    SET_RESOURCE,
    TO_LIST_PAGE,
    AUDIT_OPINIONS,
    ADD_STORY,
    ADD_RESOURCE_PICTURE,
    ADD_RESOURCE_AUDIO,
    ADD_RESOURCE_VIDEO,
    ADD_TEMP_STORY_ID,
    HAS_SHOW_HISTORY_STORY,
    STORY_CONTENT_CHANGED, COLUMN_OF_MEDIACODEL, DATA_SOURCE, SET_WEB_EXTENDS, STORY_LINKURL, PROCESS_DEPLOYKEY
} from "../actions/action-type/action-types";
import {combineReducers} from "redux";
import navigateReducer from "./navigation";

/**
 * story的基本信息
 * @type {{storyId: number, phone: string, author: string}}
 */
let initSotryInfo = {
    storyId: 0,
    author: "",
    phone: "",
    currentMediaCode: "",
    userName: "",
    storyAttribute: {},
    story: {},
    actChoose: {},
    actRemark: {},
    contributeRoleFlag: false,
    resources: {picture: [], video: [], audio: []},  //稿件上传资源信息
    toListPage: false,
    hasShowHistoryStory: true,
    storyChanged: false,
    columnOfMediaCodel: [],
    processDeployKey:'',
}


/**
 * 通过redux来管理页面关于story的信息
 * @param state
 * @param action
 * @returns {{storyId: number}|{storyId: *}}
 */
function storyReducer(state = initSotryInfo, action) {
    switch (action.type) {
        case NO_REFRESH:
            return {
                ...state,
                noRefresh: action.noRefresh,
            }
            break;
        case STORY_ID:
            return {
                ...state,
                storyId: action.storyId,
            }
            break;
        case CURRENT_MEDIA_CODE:
            return {
                ...state,
                currentMediaCode: action.currentMediaCode,
            }
            break;
        case  USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo,
            }
            break;
        case  STORY_ATTRIBUTE:
            return {
                ...state,
                storyAttribute: action.storyAttribute,
                story: {
                    ...state.story,
                    author: action.storyAttribute.author,
                    authorTel: action.storyAttribute.authorTel,
                    photography: action.storyAttribute.photography,
                    editer: action.storyAttribute.editer,
                    source: action.storyAttribute.source,
                    deptId: action.storyAttribute.deptId,
                    titlePic: action.storyAttribute.titlePic,
                    linkurl: action.storyAttribute.linkurl,
                }
            }
            break;
        // eslint-disable-next-line no-undef
        case  STORY:
            return {
                ...state,
                story: action.story,
            }
            break;
        case  STORY_FORMAT:
            return {
                ...state,
                story: {
                    ...state.story,
                    format: action.format
                }
            }
            break;
        case  STORY_LINKURL:
            return {
                ...state,
                story: {
                    ...state.story,
                    linkurl: action.linkurl
                }
            }
            break;
        case  STORY_CONTENT:
            return {
                ...state,
                story: {
                    ...state.story,
                    content: action.content
                }
            }
            break;
        case  STORY_TITLE:
            return {
                ...state,
                story: {
                    ...state.story,
                    title: action.title
                }
            }
            break;
        case  DATA_SOURCE:
            return {
                ...state,
                story: {
                    ...state.story,
                    dataSource: action.dataSource
                }
            }
            break;
        case  ACT_CHOOSE:
            return {
                ...state,
                actChoose: action.actChoose,
            }
            break;
        case  ACT_REMARK:
            return {
                ...state,
                actRemark: action.actRemark,
            }
            break;
        case  PROCESS_DEPLOYKEY:
            return {
                ...state,
                processDeployKey: action.processDeployKey,
            }
            break;
        case  STORY_STATUS:
            return {
                ...state,
                status: action.status,
            }
            break;
        case CONTRIBUTE_ROLE_FLAG:
            return {
                ...state,
                contributeRoleFlag: action.contributeRoleFlag
            }
            break;
        case ADD_RESOURCE:
            if (action.resourceType == ADD_RESOURCE_PICTURE) {
                return {
                    ...state,
                    resources: {
                        picture: [...state.resources.picture, action.resource],
                        audio: [...state.resources.audio],
                        video: [...state.resources.video]
                    }
                }
            } else if (action.resourceType == ADD_RESOURCE_AUDIO) {
                return {
                    ...state,
                    resources: {
                        picture: [...state.resources.picture],
                        audio: [...[], ...state.resources.audio, action.resource],
                        video: [...state.resources.video]
                    }
                }
            } else if (action.resourceType == ADD_RESOURCE_VIDEO) {
                return {
                    ...state,
                    resources: {
                        picture: [...state.resources.picture],
                        audio: [...state.resources.audio],
                        video: [...[], ...state.resources.video, action.resource],
                    }
                }
            }
            break;
        case SET_RESOURCE:
            return {
                ...state,
                resources: action.resource
            }
            break;
        case TO_LIST_PAGE:
            return {
                ...state,
                toListPage: action.toListPage
            }
            break;
        case AUDIT_OPINIONS:
            return {
                ...state,
                auditOpinions: action.auditOpinions
            }
            break;
        case ADD_STORY:
            return {
                ...state,
                addStory: action.addStory
            }
            break;
        case ADD_TEMP_STORY_ID:
            return {
                ...state,
                tempStoryId: action.tempStoryId
            }
            break;
        case HAS_SHOW_HISTORY_STORY:
            return {
                ...state,
                hasShowHistoryStory: action.hasShowHistoryStory
            }
            break;
        case STORY_CONTENT_CHANGED:
            return {
                ...state,
                storyChanged: action.storyChanged
            }
            break;
        case COLUMN_OF_MEDIACODEL:
            return {
                ...state,
                columnOfMediaCodel: action.columnOfMediaCodel,
                story: {
                    ...state.story,
                    chooseColumn: action.columnOfMediaCodel
                }

            }
            break;
        case SET_WEB_EXTENDS:
            return {
                ...state,
                wbextends: action.wbextends,
                story: {
                    ...state.story,
                    wbextends: action.wbextends
                }

            }
        default:
            return state;
    }
}

export default combineReducers({storyReducer, navigateReducer});
