import React, {Component} from 'react'
import {Tabs, WhiteSpace, Badge, WingBlank} from 'antd-mobile';
import {getInfoFromLocalStorage, getMyTabdata} from "../action";
import AllListViewData from './AllListViewData'
import DraftListViewData from "./DraftListViewData";
import ReviewListViewData from "./ReviewListViewData";
import PublishListViewData from "./PublishListViewData";
import HavereviewedDataList from "./HavereviewedDataList";
import ResourceDataList from "./ResourceDataList";
import {uuid} from "../utils";

class MyTabs extends Component {

    constructor(props) {
        super(props);
        let mediaCode = getInfoFromLocalStorage('currentMediaCode');
        let isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
        this.state = {
            tabsTitle: [],
            mediaCode: mediaCode,
            currentTabIndex: 3,
            isContributeRoleFlag: isContributeRoleFlag,
        }
    }

    componentDidMount() {
        getMyTabdata({}, this.updateTabsTittle.bind(this));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            mediaCode: nextProps.mediaCode
        })
    }

    updateTabsTittle(data) {
        let tablesTab;
        if (this.state.isContributeRoleFlag) {
            tablesTab = data.contribute.storyTab;
        } else {
            tablesTab = data.pms.storyTab;
        }
        this.setState({
            tabsTitle: tablesTab
        });
    }

    /**
     * tab页面切换，重新加载数据
     * @param tab
     * @param index
     */
    onTabClickTo(tab, index) {

        //重新复制一个，否则点击同一个tab页签子组件监听不到属性的变化，
        var tab1 = {...tab};
        this.setState({
            currentTab: tab1,
            currentTabIndex: index
        })
    }


    render() {
        return <div className={'myTabs'}  key={uuid()}>
            <Tabs tabs={this.state.tabsTitle}
                  initialPage={this.state.currentTabIndex}
                  onChange={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
                  swipeable={true}
                  distanceToChangeTab={0.3}
                  onTabClick={(tab, index) => {
                      this.onTabClickTo(tab, index);
                  }}
            >
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 0 ?
                            <AllListViewData mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                             isContributeRoleFlag={this.state.isContributeRoleFlag} _mediaCode={this.props._mediaCode} treeId={this.props.treeId} treeContentId={this.props.treeContentId}/> : <div></div>
                        }
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 1 ?
                            <DraftListViewData mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                               isContributeRoleFlag={this.state.isContributeRoleFlag} _mediaCode={this.props._mediaCode} treeId={this.props.treeId} treeContentId={this.props.treeContentId}/> : <div></div>
                        }
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 2 ?
                            <PublishListViewData mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                                 isContributeRoleFlag={this.state.isContributeRoleFlag} _mediaCode={this.props._mediaCode} treeId={this.props.treeId} treeContentId={this.props.treeContentId}/> : ''}
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 3 ?
                            <ReviewListViewData mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                                isContributeRoleFlag={this.state.isContributeRoleFlag} treeContentId={this.props.treeContentId}/> : <div></div>}
                    </WingBlank>
                </div>
                <div style={{height: '100%', marginTop: '8px'}}>
                    <WingBlank size="lg" style={{
                        margin:'auto 5px'
                    }}>
                        {this.state.currentTabIndex === 4 ?
                            <HavereviewedDataList mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}
                                                  isContributeRoleFlag={this.state.isContributeRoleFlag} treeContentId={this.props.treeContentId}/> : ''}
                    </WingBlank>
                </div>

                {/*<div style={{height: '100%', marginTop: '8px'}}>*/}
                {/*    <WingBlank size="lg" style={{*/}
                {/*        margin:'auto 5px'*/}
                {/*    }}>*/}
                {/*        {this.state.currentTabIndex === 5 ?*/}
                {/*            <ResourceDataList mediaCode={this.state.mediaCode} currentTab={this.state.currentTab}*/}
                {/*                                  isContributeRoleFlag={this.state.isContributeRoleFlag}/> : ''}*/}
                {/*    </WingBlank>*/}
                {/*</div>*/}

            </Tabs>

        </div>
    }

}

export default MyTabs;

