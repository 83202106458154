import React, {Component} from 'react'
import {Card, Icon, ImagePicker, NavBar, Toast, WhiteSpace} from "antd-mobile/lib/index";
import {formatDate, isStringEmpty, removeArrayByIndex, uuid} from "../../../utils";
import {
    cancelInterViewRequest,
    delResource,
    getInfoFromLocalStorage,
    getInterviewInfoById,
    getInterviewResource,
    interviewHaveOperation,
    isInterviewManager,
    saveInterviewPicture,
    saveInterviewVideo,
    takeInterviewTask,
    uploadimage,
    uploadvideo
} from "../../../action";
import WingBlank from "antd-mobile/es/wing-blank/index";
import InterviewDetailCard from "../../../components/InterviewDetailCard";
import ActButton from "../../../components/activity/ActButton";
import Button from "antd-mobile/es/button";
import {Link} from "react-router-dom";
import {setNoRefresh, setStoryAttribute} from "../../../store/actions";
import {connect} from "react-redux";
import {
    setAddStory, setCurrentTempStoryId,
    setHasShowHistoryStory,
    setStory,
    setStoryId,
    setStoryResource
} from "../../../store/actions/storyAction";
import {PhotoSlider} from "react-photo-view";
import Modal from "antd-mobile/es/modal";
import {Player} from "video-react";
import './interviewDetail_Page.css';

const alert = Modal.alert;

/**
 * 采访任务详情查看页面
 */
class InterviewDetailPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            interviewInfo: {},
            isHaveOperationAuth: false,
            storyList: [],
            videoList: [],
            pictureList: [],
            isShowButton: false,
            ishiddenResource: false,
            picNumber: 0,
            videoNumber: 0,
            isWriter: false,
            isTaken: false,
            interviewId: this.props.match.params.interviewId,
            currentAccount: getInfoFromLocalStorage("userName"),
            isManagerFlag: 'empty',
            picFiles: [],
            multiple: true,
            videoFiles: [],
            showpicVideoUpload: false,
            interviewStoryId: 0,
            disableDelete: false,  //图片视频的删除按钮，是否展示
            processInstanceId: 0,   //当前流程实例ID
            actButtonId: 5,  //采访任务的 流程  非负责人
            managerAccount: '',
            showVideoView: false, //视频预览
            showPicView: false, //图片预览
            picIndex: 0,//图片预览的索引

        }
    }

    actButton = new ActButton()

//初始化流程数据
    actInit() {
        let param = {
            userName: getInfoFromLocalStorage('userName'),
            buttonId: 2,
            serviceId: '',
            deploymentKey: 'interview',
        }
        // this.actButton.init(param);
    }

    goBack() {
        var isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
        var goBack = getInfoFromLocalStorage('goBack');
        var url = '/interviewTask/interviewTask/' + isContributeRoleFlag + '/' + goBack;
        this.state.history.push(url);
    }

    componentWillMount() {
        var interviewId = this.state.interviewId;
        var param = {"interviewId": interviewId};
        getInterviewInfoById(param, this.updateInterViewInfo.bind(this));
        this.actInit();

        //进入写稿页面，需要刷新页面
        this.props.setNoRefresh(false);
        this.props.setStory({});
        this.props.setStoryId(0);
        this.props.setStoryAttribute({});
        this.props.setStoryResource({picture: [], video: [], audio: []});
        this.props.setAddStory(false);
        this.props.setHasShowHistoryStory(true);
        this.props.setCurrentTempStoryId(0);
    }

    updateInterViewInfo(data) {
        if (!data) {
            Toast.fail("数据异常");
        }
        this.setState({
            interviewInfo: data
        })

        let joiners = data.interviewJointers;

        if (!joiners || joiners.length == 0) {
            return;
        }

        //判断当前用户，是否是文字记者， 是否领取了任务；
        for (var i = 0; i < joiners.length; i++) {
            if (joiners[i].account == this.state.currentAccount) {
                if (joiners[i].interviewRole === 1) {
                    this.state.isWriter = true;
                }
                if (joiners[i].status !== 1) {
                    this.state.isTaken = true
                }
            }
        }

        //判断有没有采访图片，视频的 查看权限。
        var interviewId = this.state.interviewId;
        var param = {"interviewId": interviewId};
        interviewHaveOperation(param, this.updateHaveOperationAuth.bind(this));
    }


    updateHaveOperationAuth(data) {
        this.setState({
            isShowButton: data,
            ishiddenResource: data,
            disableDelete: data
        })

        this.loadInterviewResource();
    }

//加载视频，图片资源
    loadInterviewResource() {
        var params = {
            "interviewId": this.state.interviewId
        };
        //获取采访的图片，视频资源；
        getInterviewResource(params, this.updateInterviewResource.bind(this));
    }

//更新图片视频资源
    updateInterviewResource(resources) {
        var storyList = resources.storyList
        var pictureList = resources.pictureList;
        var videoList = resources.videoList

        for (var i = 0; i < pictureList.length; i++) {
            pictureList[i].url = pictureList[i].path;
        }

        for (var i = 0; i < videoList.length; i++) {
            videoList[i].url = videoList[i].path + '/thumbnails/1';
        }
        this.renderPicture(pictureList);
        this.renderVideo(videoList);
        let currentStoryId = 0;
        if (storyList && storyList.length > 0) {
            currentStoryId = storyList[0].id;
        }


        this.setState({
            storyList: storyList,
            interviewStoryId: currentStoryId,
        })


        //当前用户是否是 参与者；
        var params = {
            "interviewId": this.state.interviewId
        };
        isInterviewManager(params, this.updateisInterviewManager.bind(this));
    }

//渲染sotry信息
    renderStory(storyList) {
        if (!storyList || storyList.length === 0) {
            return;
        }

        let obj = storyList[0];
        let submitter = obj.submitter;
        let actButtonId = 5;
        if (submitter === this.state.managerAccount) {
            actButtonId = 6
        }
        return <div>
            <Link to={'/interview/writeStory/' + this.state.interviewStoryId + '/'
            + this.state.processInstanceId + '/' + this.state.interviewId + '/' + actButtonId + '/2'}>
                <div style={{padding: '20px'}}>
                    <div
                        style={{
                            fontSize: 14,
                            color: 'black',
                            wordBreak: 'break-all'
                        }}
                        dangerouslySetInnerHTML={{__html: obj.title}}></div>
                    <div
                        style={{
                            lineHeight: '30px',
                            color: '#888',
                            fontSize: 12,
                            wordBreak: 'break-all'
                        }}
                        dangerouslySetInnerHTML={{__html: isStringEmpty(obj.summary) ? '' : obj.summary}}></div>
                    <div>
                        <div className={'cardBootm'}>
                            <div style={{
                                textAlign: 'right',
                                color: '#888',
                                fontSize: '12px',
                                paddingBottom: '10px'
                            }}>{formatDate(obj.submitDate.time)}</div>
                            <div
                                style={{
                                    backgroundColor: '#F5F5F9',
                                    borderBottom: '1px solid #ECECED',
                                }}
                            />
                            <div style={{paddingTop: '10px', color: '#888', fontSize: '12px',}}>
                                <span>{obj.submitter}</span><span style={{
                                float: 'right',
                                color: '#888',
                                fontSize: '12px',
                            }}>状态: {obj.statusName}</span></div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    }

//渲染picture信息
    renderPicture(pictureList) {
        if (!pictureList || pictureList.length === 0) {
            return;
        }
        this.setState({
            picFiles: pictureList
        })
    }

//渲染视频信息
    renderVideo(videoList) {
        if (!videoList || videoList.length === 0) {
            return;
        }
        this.setState({
            videoFiles: videoList
        })
    }

// 加载 领取任务按钮和写稿按钮
    loadInterviewBtn(isManagerFlag) {

        let listButton = [];
        let reviveButton = <Button type="primary" key={uuid()}
                                   style={{margin: '4px'}} onClick={() => {
            this.takeTask()
        }}>领取任务</Button>;
        let writeStoryButton = <Button type="primary" key={uuid()}
                                       style={{margin: '4px'}} onClick={() => {
            this.writeStory()
        }}>写稿</Button>;
        if (this.state.isShowButton) {
            if (isManagerFlag) {
                if (!this.state.isTaken) {
                    listButton.push(reviveButton);
                } else {
                    if (this.state.interviewStoryId == 0 && this.state.isWriter) {
                        listButton.push(writeStoryButton);
                    }
                }
            } else {
                if (!this.state.isTaken) {
                    listButton.push(reviveButton);
                } else {
                    if (this.state.interviewStoryId === 0 && this.state.isWriter) {
                        listButton.push(writeStoryButton);
                    }
                }
            }

        } else {
        }
        return listButton;
    }

    //领取采访任务
    takeTask() {
        var params = {
            "interviewId": this.state.interviewId
        };
        takeInterviewTask(params, this.takeInterviewBack.bind(this));
    }

    /**
     * 撤回采访任务
     */
    cancelInterView() {
        alert("采访任务",'确定要撤回吗？', [
            { text: '否', onPress: () => console.log('cancel') },
            {
                text: '是',
                onPress: () =>
                {
                    let param = new FormData();
                    param.append("interviewId", this.state.interviewId);
                    cancelInterViewRequest(param, this.callcancelInterViewRequest.bind(this));
                }
            },
        ]);
    }

    /**
     * 撤回的回调
     */
    callcancelInterViewRequest(data) {
        if (data) {
            Toast.success("撤回成功");
            this.goBack();
        } else {
            Toast.fail("撤回失败");
        }
    }

    /**
     * 采访任务领取后的回调函数；
     * @param data
     */
    takeInterviewBack(data) {
        if (data) {
            var interviewId = this.state.interviewId;
            var param = {"interviewId": interviewId};
            Toast.success('领取成功', 2);
            getInterviewInfoById(param, this.updateInterViewInfo.bind(this))
        } else {
            Toast.fail("领取失败，请稍后再试");
        }
    }

    /**
     * 写稿页面
     */
    writeStory() {
        if (this.state.isManagerFlag === 'empty') {
            Toast.fail('获取负责人失败');
            //警告，获取负责人失败
            return;
        }

        var url = '/interview/writeStory/' + this.state.interviewStoryId + '/'
            + this.state.processInstanceId + '/' + this.state.interviewId + '/' + this.state.actButtonId + '/2';
        this.state.history.push(url);
    }

    //判断当前用户是否是采访任务的参与者；
    updateisInterviewManager(data) {
        if (!data) {
            return;
        }
        var isManagerFlag = false;
        let managerAccount = data[0].account;
        for (var i = 0; i < data.length; i++) {

            if (data[i].account == this.state.currentAccount) {
                isManagerFlag = true;
                break;
            }
        }

        let actButtonId = 5;  //写稿人 非负责人
        if (isManagerFlag) {
            actButtonId = 6;
        }
        this.setState({
            isManagerFlag: isManagerFlag,
            actButtonId: actButtonId,
            managerAccount: managerAccount
        })
    }

    /**
     *变更视频
     * @param files
     * @param type
     * @param index
     */
    onChangeVideo = (files, type, index) => {
        if (type === 'add') {
            this.state.videoUpload = [];
            for (var i = 0; i < files.length; i++) {
                let data = new FormData();
                if (files[i].file && files[i].orientation === 1) {
                    data.append('file', files[i].file);
                    //调用后台接口上传文件，
                    uploadvideo(data, this.uploadInterviewVideback.bind(this));
                }
            }
        }

        if (type === 'remove') {
            let videoFiles = this.state.videoFiles;
            let removeFiles = videoFiles[index];
            let param = {
                'resourceId': removeFiles.resourceId,
                "interviewId": this.state.interviewId
            }
            delResource(param, this.delResourceBack.bind(this));
            videoFiles = removeArrayByIndex(index, videoFiles);
            this.setState({
                videoFiles: videoFiles
            })
        }
    }

    /**
     * 采访视频图片删除
     * @param data
     */
    delResourceBack(data) {

    }

    /**
     * 保存采访任务图片
     * @param data
     */
    uploadInterviewVideback(data) {
        if (!data) {
            Toast.fail('上传失败');
        }

        let storageFile = data.storageFile;
        let param = {
            'interviewId': this.state.interviewId,
            'name': storageFile.name,
            'ext': storageFile.suffix,
            'path': data.url,
            'size': storageFile.size,
            'allTime': storageFile.time
        };
        saveInterviewVideo(param, this.saveInterviewVideoBack.bind(this));

    }

    /**
     * 保存采访任务视频的回调
     * @param data
     */
    saveInterviewVideoBack(data) {
        let videoFiles = this.state.videoFiles;
        data.url = data.path + '/thumbnails/1'
        videoFiles.push(data)
        this.setState({
            videoFiles: videoFiles,
        })
    }

    openPicView(index) {
        this.setState({
            showPicView: true,
            picIndex: index
        })
    }

    /**
     * 修改图片
     * @param files
     * @param type
     * @param index
     */
    onChangePic = (files, type, index) => {

        if (type === 'add') {
            for (var i = 0; i < files.length; i++) {
                if (files[i].file && files[i].orientation === 1) {

                    //修改状态为已经上传
                    files[i].orientation = 0;
                    //从picFiles删除上传的图片
                    let data = new FormData();
                    data.append('file', files[i].file);
                    //调用后台接口上传文件，
                    uploadimage(data, this.saveInterviewPic.bind(this));
                }

            }
        }

        if (type === 'remove') {
            let picFiles = this.state.picFiles;
            var removeFiles = picFiles[index];
            let param = {
                'resourceId': removeFiles.resourceId,
                "interviewId": this.state.interviewId
            }
            delResource(param, this.delResourceBack.bind(this));
            picFiles = removeArrayByIndex(index, picFiles);
            this.setState({
                picFiles: picFiles
            })
        }
    }

    //保存采访任务的图片
    saveInterviewPic(data) {
        let storageFile = data.storageFile;
        let param = {
            'interviewId': this.state.interviewId,
            'name': storageFile.name,
            'ext': storageFile.suffix,
            'path': data.url,
            'size': storageFile.size,
            'imageWidth': storageFile.width,
            'imageHeight': storageFile.height
        };
        saveInterviewPicture(param, this.saveInterviewPicBack.bind(this))
    }

    saveInterviewPicBack(data) {
        let picFiles = this.state.picFiles;
        data.url = data.path;
        picFiles.push(data)
        this.setState({
            picFiles: picFiles,
        })
    }

    closePic() {
        this.setState({
            showPicView: false
        })
    }

    /**
     * 视频预览
     */
    openVideoView(index, files) {
        this.setState({
            videoSources: files[index].path,
            showVideoView: true
        })
    }

    //关闭视频
    closeVideo() {
        this.setState({
            videoSources: {},
            showVideoView: false,
        })
    }

    setPicIndex(index) {
        this.setState({
            picIndex: index,
        })
    }

    render() {
        //加入撤回按钮
        var cancelOrSubmit = "";
        let interviewInfo = this.state.interviewInfo;
        if (interviewInfo) {
            if (interviewInfo.status === 1 && interviewInfo.creater === getInfoFromLocalStorage("userName")) {
                cancelOrSubmit = <div style={{color: '#2d2d2d'}} key={uuid()} onClick={() => {
                    this.cancelInterView()
                }}>撤回</div>;
            }
        }

        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div key={uuid()} className={'nav_goback'} onClick={() => this.goBack()}>返回</div>
                ]}

                rightContent={[
                    cancelOrSubmit
                ]}

            ><span className={'nav_title'}>采访</span></NavBar>
            <div style={{overflowY: 'auto', height: '90vh'}}>
                <WingBlank style={{margin: '5px'}}>
                    {this.loadInterviewBtn(this.state.isManagerFlag)}
                    <Card>
                        <div className={'intervidew_detail_title'}>回稿信息</div>
                        {this.renderStory(this.state.storyList)}
                    </Card>
                    <WhiteSpace size="lg"/>
                    <Card>
                        <div className={'intervidew_detail_title'}>采访图片</div>
                        {<ImagePicker
                            onAddImageClick={this.addImage}
                            files={this.state.picFiles}
                            onImageClick={this.openPicView.bind(this)}
                            onChange={this.onChangePic}
                            selectable={(this.state.disableDelete && this.state.isTaken)}
                            multiple={this.state.multiple}
                            disableDelete={!this.state.disableDelete}
                        />}
                    </Card>
                    <WhiteSpace size="lg"/>
                    <Card>
                        <div className={'intervidew_detail_title'}>采访视频</div>
                        {<ImagePicker
                            accept={"video/*"}
                            onAddImageClick={this.addImage}
                            onImageClick={this.openVideoView.bind(this)}
                            files={this.state.videoFiles}
                            onChange={this.onChangeVideo}
                            selectable={(this.state.disableDelete && this.state.isTaken)}
                            multiple={this.state.multiple}
                            disableDelete={!this.state.disableDelete}
                        />}
                    </Card>
                    <WhiteSpace size="lg"/>
                    {this.state.interviewInfo.title ? <InterviewDetailCard goBack={this.goBack.bind(this)}
                                                                           interviewInfo={this.state.interviewInfo}></InterviewDetailCard> : ""}
                </WingBlank>
                <PhotoSlider
                    images={this.state.picFiles.map(item => ({src: item.url}))}
                    visible={this.state.showPicView}
                    onClose={() => (this.closePic())}
                    index={this.state.picIndex}
                    onIndexChange={this.setPicIndex.bind(this)}
                />

                <Modal
                    popup
                    visible={this.state.showVideoView}
                    animationType="slide-up"
                    afterClose={() => {
                    }}
                >
                    <div style={{height: '100vh'}}>
                        <div onClick={() => {
                            this.closeVideo()
                        }} style={{backgroundColor: "#000000", color: '#ffffff'}}>关闭
                        </div>
                        <div style={{height: '94vh'}}>
                            <Player autoPlay={true} fluid={false} width={'100%'} height={'100%'}>
                                <source src={this.state.videoSources}/>
                            </Player>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setNoRefresh: function (noRefresh) {
            dispatch(setNoRefresh(noRefresh));
        },
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttribute) {
            dispatch(setStoryAttribute(storyAttribute));
        },
        setStoryResource: function (resource) {
            dispatch(setStoryResource(resource));
        },
        setAddStory: function (addStory) {
            dispatch(setAddStory(addStory));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setHasShowHistoryStory(hasShowHistoryStory) {
            dispatch(setHasShowHistoryStory(hasShowHistoryStory));
        },
        setCurrentTempStoryId: function (tempStoryId) {
            dispatch(setCurrentTempStoryId(tempStoryId));
        }
    }
}

export default connect(null, dispatchToProps)(InterviewDetailPage);
