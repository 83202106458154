import React, {Component} from 'react';
import {Card, Calendar, InputItem, List, TextareaItem, WingBlank, Toast, Picker} from "antd-mobile";
import './interview_card.css'
import {formatDate, goupByFirstName} from "../utils";
import {createForm} from "rc-form";
import config from "../config";
import Modal from "antd-mobile/es/modal";
import TxySelectList from "./TxySelectList";
import {addAppointment, getAllTxy, loadWebSiteMedia} from "../action";


// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

const appUploadFlag = config.appUploadFlag;

let moneyKeyboardWrapProps;
if (isIPhone) {
    moneyKeyboardWrapProps = {
        onTouchStart: e => e.preventDefault(),
    };
}


const extra = {
    '2017/07/15': {info: 'Disable', disable: true},
};

const now = new Date();
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 5)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 6)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)] = {info: 'Disable', disable: true};
extra[+new Date(now.getFullYear(), now.getMonth(), now.getDate() + 8)] = {info: 'Disable', disable: true};

Object.keys(extra).forEach((key) => {
    const info = extra[key];
    const date = new Date(key);
    if (!Number.isNaN(+date) && !extra[+date]) {
        extra[+date] = info;
    }
});

/**
 * 采访详情页面的，采访内容卡片
 */
class ManuscriptDetailCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addAppointmentInfo: this.props.appointmentInfo
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            addAppointmentInfo: nextProps.appointmentInfo
        })
    }

    render() {
        var titles = <span style={{fontSize: '15px'}}>约稿要求:</span>;
        const {getFieldProps} = this.props.form;
        const mediaNames=this.state.addAppointmentInfo.mediaNames;
        return <div>
            <Card style={{paddingTop: '20px', paddingLeft: '19px'}}>
                <WingBlank size="lg" style={{margin:'5px'}}>
                    <div className={'storyAttribute'}>
                        <Card>
                            <List>
                                <InputItem
                                    value={formatDate(this.state.addAppointmentInfo.replyDate ? this.state.addAppointmentInfo.replyDate.time : '')}
                                    style={{marginLeft:10}}
                                >回稿时间:
                                </InputItem>
                                <TextareaItem style={{fontSize: '15px'}}
                                              value={this.state.addAppointmentInfo.requireContent}
                                              rows={5}
                                              title={titles}
                                />
                                <InputItem
                                    value={formatDate(this.state.addAppointmentInfo.createDate ? this.state.addAppointmentInfo.createDate.time : '')}
                                >创建时间:
                                </InputItem>

                                <InputItem value={mediaNames}

                                >发布媒体:
                                </InputItem>

                                <InputItem
                                    value={this.state.addAppointmentInfo.publisherName}
                                >发布者:
                                </InputItem>
                            </List>
                        </Card>
                    </div>
                </WingBlank>
            </Card>
        </div>
    }
}

const ManuscriptDetailCardForm = createForm()(ManuscriptDetailCard);
export default ManuscriptDetailCardForm
