import React, {Component} from 'react'
import {Icon, NavBar, Toast, Menu, Dropdown, ActivityIndicator} from 'antd-mobile';
import MyTabs from "../../components/MyTabs";
import './MyFirstPage.css';
import {uuid} from "../../utils";
import {connect} from "react-redux";
import {
    setStory,
    setStoryResource,
    setAddStory,
    setStoryId,
    setHasShowHistoryStory, setCurrentTempStoryId
} from "../../store/actions/storyAction";
import {setStoryAttribute} from "../../store/actions";
import {getInfoFromLocalStorage, getMediaByAccount, loadCurrentMedia, setInfoToLocalStorage} from "../../action";
import DropDownListTree from "../../components/dropDownListTree/DropDownListTree";

const data = [
    {
        value: '1',
        label: 'Food',
    }, {
        value: '2',
        label: 'Supermarket',
    },
    {
        value: '3',
        label: 'Extra',
        isLeaf: true,
    },
];

/**
 * 稿件列表页
 */
class ListTabPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: "稿件",
            history: require("history").createHashHistory,
            toWriteStoryPage: false,
            isContributeRoleFlag: getInfoFromLocalStorage('isContributeRoleFlag'),
            toMediaPage: false,
            currentMediaCode: getInfoFromLocalStorage("currentMediaCode"),
            show: false,
            iconUpOrDown: 'down',
            defaultMediaValue:[],
            initData: [],
            mediaCode2Name:{},
            data:{treeName:'请选择',treeId:'',treeContentId:'',_mediaCode:''},
            webData:{}
        }
    }

    componentWillMount() {
        if (!this.state.isContributeRoleFlag) {
            let param = {account: getInfoFromLocalStorage("userName")};
            getMediaByAccount(param, this.updateMediaList.bind(this));

        }
    }

    componentDidMount() {
        // console.log('this.props.history',this.state.history().push);
        this.setState({
            isContributeRoleFlag: this.state.isContributeFlag,
        });
        this.props.setStory({});
        this.props.setStoryId(0);
        this.props.setStoryAttribute({});
        this.props.setStoryResource({picture: [], video: [], audio: []});
        this.props.setAddStory(false);
        this.props.setHasShowHistoryStory(true);
        this.props.setCurrentTempStoryId(0);
        //获取栏目
        let param = new FormData();
        param.append('type', 1);
        /*loadCurrentMedia(param, res => {
            let data = {};
            if (res && res.length) {
                //获取当前数组最底层的一个栏目
               this.getLastColumn(res)
            }
        })*/
    }
    getLastColumn(list){
        if(list[0].subtree&&list[0].subtree.length){
            console.log(list[0])
                this.getLastColumn(list[0].subtree)
        }else{
            console.log('返回',list[0]);
           this.state.data=list[0];
           this.setState({})
        }
    }

    /**
     * 返回按钮事件
     */
    goBack() {
        setInfoToLocalStorage("currentMediaCode","");
        let path = '/index/homeTab';
        this.state.history().push(path);
    }

    /**
     * 更新媒体的列表到页面；
     * @param dataList
     */
    updateMediaList(dataList) {
        Toast.hide();
        //当前管理端的角色没有配置管媒体
        if (!dataList || dataList.length == 0) {
            // Toast.info('您好，请给当前用户添加媒体', 2, null, false);
            return;
        }
        /**
         * 过滤出网站的媒体
         */
        let initData = [];
        let pageName=null;
        let mediaCode2Name={};
        for (var i = 0, length = dataList.length; i < length; i++) {
            var data = dataList[i];
            if (data.type == '1') {
                if(data.code==this.state.currentMediaCode){
                    pageName= data.name;
                }
                var mediaDatad = {"title": data.name, "value": data.code};
                mediaCode2Name[data.code]=data.name;
                initData.push(mediaDatad);
            }
        }

        /**
         * 默认第一个媒体选中
         */
        //把媒体code放到sessionStory中
        let currentMediaCode=this.state.currentMediaCode;
        if(!this.state.currentMediaCode){
            setInfoToLocalStorage('currentMediaCode', initData[0].value);
            currentMediaCode=initData[0].value;
        }
        console.info("setInfoToLocalStorage++++",this.state.currentMediaCode);
        this.setState({
            currentMediaCode: currentMediaCode,
            pageName: pageName?pageName:initData[0].title,
            mediaCode2Name:mediaCode2Name,
            initData: initData,
            defaultMediaValue:[currentMediaCode]
        })
    }


    /**
     * 调到写稿页面
     */
    toWritePage() {
        var writePath = "/writeStory/0/0/2/1";
        this.state.history().push(writePath);
    }

    onChange = (value) => {
        let data = this.state.initData;
        data.forEach((dataItem) => {
            if (dataItem.value === value[0]) {
                let defaultMediaValues=value[0];
                setInfoToLocalStorage('currentMediaCode', value[0]);
                this.state.pageName = dataItem.label;
                this.state.currentMediaCode = value[0];
                this.state.defaultMediaValue=[defaultMediaValues];
            }
        });
        // console.log(label);
    }
    handleClick = (e) => {
        e.preventDefault(); // Fix event propagation on Android
        this.setState({
            show: !this.state.show,
        });
        // mock for async data loading
        if (!this.state.initData) {
            setTimeout(() => {
                this.setState({
                    initData: this.state.initData,
                });
            }, 500);
        }
    }

    onMaskClick = () => {
        this.setState({
            show: false,
        });
    }

    /**
     * 修改标题的  下拉  icon
     */
    changeIcon = () => {
        var icon = 'up';
        if (this.state.iconUpOrDown === 'up') {
            icon = 'down';
        }
        this.setState({
            iconUpOrDown: icon
        })
    }

    toWriteStory=()=>{
        this.toWritePage();
        this.props.setAddStory(true);
    }

    render() {
        const {initData, show} = this.state;
        let isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
        console.info("render+++++++++++++++",)
        var header = <NavBar key={uuid()}
                             icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                             leftContent={[<span key={uuid()} className={'nav_goback'} style={{width:'36px'}} onClick={() => this.goBack()}>返回</span>]}
                             mode="light"
                             rightContent={[
                                 <span key={uuid()} className={'nav_goback'} style={{paddingRight:20}} >{this.state.webData.name}</span>
                             ]}
        >
            {/*<div className={'nav_title'} onClick={this.handleClick}>{this.state.pageName}</div>*/}
            {/*栏目树*/}
            {
                isContributeRoleFlag?<div>稿件</div>:
                    <div>
                        <div  onClick={()=>this.DropDownListTree.show()} className={'nav_title'}>
                           {this.state.data&&this.state.data.treeName}
                        </div>
                        <DropDownListTree ref={DropDownListTree=>this.DropDownListTree=DropDownListTree}  currentWebCallback={(data)=>{
                            this.setState({webData:data})
                            console.log('-----------------',data)
                        }} onOkClick={(list)=>{
                            console.log(list)
                            let data=list[0]
                            this.setState({data})
                        }}/>
                    </div>
            }
            {  isContributeRoleFlag?null:<img src={'images/down_01.png'} width={'18px'} style={{marginLeft:'8px'}} />}
                </NavBar>

        const menuEl = (
            <Menu
                className="single-foo-menu"
                style={{position: 'absolute', zIndex: '9999', width: '100%'}}
                data={initData}
                value={this.state.defaultMediaValue}
                level={1}
                onChange={this.onChange}
                height={document.documentElement.clientHeight * 0.3}
            />
        );
        const loadingEl = (
            <div style={{
                position: 'absolute',
                width: '100%',
                height: document.documentElement.clientHeight * 0.6,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <ActivityIndicator size="large"/>
            </div>
        );

        return <div>
            <div className={'myFirstContainer'}>
                {header}
            </div>
            {show ? initData ? menuEl : loadingEl : null}
            {show ? <div className="menu-mask" onClick={this.onMaskClick}/> : null}
            <MyTabs isContributeRoleFlag={this.state.isContributeRoleFlag}
                    mediaCode={this.state.currentMediaCode} _mediaCode={this.state.data._mediaCode} treeId={this.state.data.treeId} treeContentId={this.state.data.treeContentId}></MyTabs>
            <div style={{ position: 'fixed',right:'6vw',bottom:'2vh',alignItems: 'center', borderRadius: '50%',width:'50px',height:'50px',background:'#1890ff',
                backgroundSize:'auto',backgroundImage:'url(images/icon_add_sx.png)',backgroundPosition:'center',backgroundRepeat: 'no-repeat'
            }} onClick={this.toWriteStory}></div>
        </div>
    }
}

/**
 *
 * @param state
 */
const state2Props = (state) => {
    return {
        contributeRoleFlag: state.storyReducer.contributeRoleFlag,
        userInfo: state.storyReducer.userInfo,
    }
}

const dispatchToProps = (dispatch) => {
    return {
        setStory: function (story) {
            dispatch(setStory(story));
        },
        setStoryAttribute: function (storyAttribute) {
            dispatch(setStoryAttribute(storyAttribute));
        },
        setStoryResource: function (resource) {
            dispatch(setStoryResource(resource));
        },
        setAddStory: function (addStory) {
            dispatch(setAddStory(addStory));
        },
        setStoryId: function (storyId) {
            dispatch(setStoryId(storyId));
        },
        setHasShowHistoryStory(hasShowHistoryStory) {
            dispatch(setHasShowHistoryStory(hasShowHistoryStory));
        },
        setCurrentTempStoryId: function (tempStoryId) {
            dispatch(setCurrentTempStoryId(tempStoryId));
        }
    }
}

export default connect(state2Props, dispatchToProps)(ListTabPage);
