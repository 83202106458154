import React, {Component} from "react";
import {StickyContainer, Sticky} from 'react-sticky';
import {ListView, List, Checkbox, SearchBar} from 'antd-mobile';
import './addressBook.css';
import './txySelect_list.css';


const {Item} = List;
const CheckboxItem = Checkbox.CheckboxItem;

/**
 * 采访任务，通选员选择；
 * @param ds
 * @param provinceData
 * @returns {*}
 */

function genData(ds, provinceData) {
    const dataBlob = {};
    const sectionIDs = [];
    const rowIDs = [];
    Object.keys(provinceData).forEach((item, index) => {
        sectionIDs.push(item);
        dataBlob[item] = item;
        rowIDs[index] = [];

        provinceData[item].forEach((jj) => {
            rowIDs[index].push(jj.id);
            dataBlob[jj.id] = jj;
        });
    });
    return ds.cloneWithRowsAndSections(dataBlob, sectionIDs, rowIDs);
}

class TxySelectList extends Component {
    constructor(props) {
        super(props);
        const getSectionData = (dataBlob, sectionID) => dataBlob[sectionID];
        const getRowData = (dataBlob, sectionID, rowID) => dataBlob[rowID];

        const dataSource = new ListView.DataSource({
            getRowData,
            getSectionHeaderData: getSectionData,
            rowHasChanged: (row1, row2) => row1 !== row2,
            sectionHeaderHasChanged: (s1, s2) => s1 !== s2,
        });

        this.state = {
            inputValue: '',
            dataSource,
            isLoading: true,
            value:'',
        };
    }

    componentDidMount() {
        // simulate initial Ajax
        setTimeout(() => {
            this.setState({
                dataSource: genData(this.state.dataSource, this.props.data),
                users: this.props.data,
                isLoading: false,
            });
        }, 600);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // simulate initial Ajax
        setTimeout(() => {
            this.setState({
                dataSource: genData(this.state.dataSource, nextProps.data),
                users: this.props.data,
                isLoading: false,
            });
        }, 100);
    }


    onChange = (val) => {
        this.props.getUserAccountList(val);

    }

    render() {
        const row = (rowData, sectionID, rowID) => {
            return <Item>
                <CheckboxItem key={rowData.id} defaultChecked={this.props.selectedIds.includes(rowData.id)}
                              onChange={() => this.onChange(rowData)}>
                    <img
                        src={rowData.photoUrl ? rowData.photoUrl : (rowData.sex === 1 ? 'images/header_boys.png' : 'images/header_girls.png')}></img> {rowData.name}{rowData.deptContent ? '(' + rowData.deptContent + ')' : ''}
                </CheckboxItem>
            </Item>

        };
        return (<div className={'addressList'}>
                {this.props.showSearch && this.props.showSearch!=='none' && this.props.searchCallBack ?
                    <SearchBar
                        placeholder={'搜索通讯员'}
                        value={this.state.value}
                        onSubmit={(str)=>{this.setState({value:str});this.props.searchCallBack(str)}}
                        onChange={(str)=>{this.setState({value:str});this.props.searchCallBack(str)}}
                        showCancelButton>
                    </SearchBar>
                    : null}
                <ListView.IndexedList
                    dataSource={this.state.dataSource}
                    className="am-list sticky-list"
                    useBodyScroll={true}
                    quickSearchBarStyle={{display: 'none'}}
                    renderSectionWrapper={sectionID => (
                        <StickyContainer
                            key={`s_${sectionID}_c`}
                            className="sticky-container"
                            style={{zIndex: 4}}
                        />
                    )}
                    renderSectionHeader={sectionData => (
                        <Sticky>
                            {({
                                  style,
                              }) => (
                                <div
                                    className="sticky"
                                    style={{
                                        ...style,
                                        zIndex: 3,
                                        top: '0px',
                                        left: '0px',
                                        paddingLeft: '15px',
                                        backgroundColor: sectionData.charCodeAt(0) % 2 ? '#F5F5F9' : '#F5F5F9',
                                        color: 'black',
                                    }}
                                >{sectionData}</div>
                            )}
                        </Sticky>
                    )}
                    renderRow={row}
                    delayTime={10}
                    delayActivityIndicator={<div style={{padding: 25, textAlign: 'center'}}>rendering...</div>}
                />
            </div>
        );
    }
}

export default TxySelectList;
