/**
 * created by lei lei
 * 上传组件  2 图片，3,//视频，4附件
 *   <Upload isUoLoad={true} ref={Upload=>this.upload=Upload} bindId={this.props.match.params.storyId}/>  上传属性
 *  this.Upload.show(2)//任务图片
 *  this.Upload.show(3)//任务视频
 *  this.Upload.show(4)//任务附加
 *
 *  选择使用
 *   <Upload  ref={Upload=>{this.Upload=Upload}} isUoLoad={false} bindId={this.state.interviewId} choose={'more'} onOkClick={(data)=>{this.uploadCallBack(data)}}/>
 *this.Upload.show(2)//任务图片
 *  this.Upload.show(3)//任务视频
 *  this.Upload.show(4)//任务附加
 *---------------------------------------------------------------------
 *选择资源库图片资源库没有移动端上传，只有选择功能，isUoLoad只能为false;
 * 12 资源库图片，13,//资源库视频
 *
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {InputItem, Checkbox, Modal, List, Button, Toast, Popover, ImagePicker,} from "antd-mobile";
import {request} from "../../utils";
import folder from "./img/folder.png"
import noImg from "./img/noImg.png"
import audioImg from "./img/AUDIO.png"
import add from "../../containers/topicList/img/add.png";
import DropDownList from "../dropDownList/DropDownList";
import {PIC_TYPE, VIDEO_TYPE, ATTCHMENT_TYPE, AUDIO_TYPE, FILE_TYPE} from "../../constants/index"
import {uploadimage, uploadvideo, uploadAttachmentFile, uploadAudio} from "../../action";
import play from "./img/play.png"
import PDF from './img/PDF.png'
import RAR from './img/RAR.png'
import TXT from './img/TXT.png'
import WORD from './img/Word.png'
import XLXS from './img/XLXS.png'
import ZIP from './img/zip.png'
import PPT from './img/ppt.png'
import other from './img/other.png'
import FILE from "./img/file.png";
import * as Actions from "../../action/topiclist";
import $ from 'jquery'
import MyImgPicker from "../../components/MyImgPicker";

const CheckboxItem = Checkbox.CheckboxItem;
const Item = Popover.Item;
const alert = Modal.alert;
export default class Upload extends Component {
    static defaultProps = {
        title: '上传',
        itemTitle: 'name',
        itemBottomTile: 'createDate',
        cover: 'path',
        onOkClick: (e) => {
            console.log(e)
        },
        choose: 'more',//one 单选，more 多选
        // type: 2,//2 图片，3,//视频，4附件,
        isUoLoad: true,//true 是上传，false 是选择
        bindId: '',
        isShowDeleteBtn:false,//如果是false  显示的是选择按钮。

    };
    static propTypes = {
        title: PropTypes.string.isRequired,//头部标题,
        onOkClick: PropTypes.func.isRequired,//点击确定后的返回选中的数据
        choose: PropTypes.string,//单选还是多选
        bindId: PropTypes.number,//绑定的id上传的时候必须填写,
        isUoLoad:PropTypes.bool,//是否显示上传按钮
        isShowDeleteBtn:PropTypes.bool,//是否显示删除按钮
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            userChoose: [],
            name: '',//搜索字段
            downStatus: false,
            type: '',//当前操作类型，2 图片，3,//视频，4附件,
            menuId: '',//当前选中面包削的id
            menuList: [],//上面菜单,
            dataSources: [],//下面的展示数据
            zykInit: {},
            reviewStatus:false, // 图片/视频预览开关
            reviewContent:'', // 图片/视频预览内容
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }


    //获取涉及单位列表

    show(type) {
        this.state.type = type;
        this.state.visible = true;
        /* this.setState({visible: true})*/
        if (this.props.isUoLoad && (type === 2 || type === 3 || type === 4)) {
            if (!this.props.bindId) {
                alert('上传请添加属性bindId');
                return;
            }
        }
        //任务 任务图片，任务视频，任务附件 调用方法
        if (type === 2 || type === 3 || type === 4) {
            this.getMeauId()
        } else if (type === 12 || type === 13) {
            //获取资源库初始菜单
            this.getZykMenuId()
        }
        this.setState({},()=>{
            $('.am-modal-mask').css('z-index', 10003);
            $('.am-modal-wrap').css('z-index', 10004);
        })
    }

    //获取初始菜单id
    getMeauId() {
        let params = {
            taskId: this.props.bindId,
            type: this.state.type
        };
        getMeauId(params, res => {
            if (res) {
                this.state.menuId = res;
                this.clickByType();
                this.setState({menuId: res})
            }
        })
    }
    //获取上部分菜单
    getTaskMenuNavigation() {
        let params = {
            taskId: this.props.bindId,
            type: this.state.type,
            taskMenuId: this.state.menuId
        };
        getTaskMenuNavigation(params, res => {
            let menu = [];
            if (res) {
                menu = res;
            }
            this.setState({menuList: menu})
        })
    }

    //获取菜单下面的内容
    getTaskMenuResourceList() {
        let params = {
            type: this.state.type,
            taskMenuId: this.state.menuId
        };
        if (this.state.name && this.state.name.trim().length > 0) {
            params.name = this.state.name;
        }
        getTaskMenuResourceList(params, res => {
            let dataSources = [];
            if (res) {
                //格式化文件
                if (res.menus && res.menus.length > 0) {
                    res.menus.map(item => {
                        item.fileType = 'file';
                        dataSources.push(item)
                    })
                }
                //格式化展示内容
                if (res.resources && res.resources.length > 0) {
                    res.resources.map(item => {
                        if (this.state.type === 2) {
                            item.fileType = 'img';
                        } else if (this.state.type === 3) {
                            item.fileType = 'video';
                        }
                        dataSources.push(item)
                    })
                }
                //dataSources=res.resources
            }
            this.setState({dataSources});
        })
    }

    //获取资源库菜单id
    getZykMenuId() {
        let params = {
            name: '全部文件',
            fid: '',
        };
        createStorageDirectory(params, res => {
            let init_id = '';
            if (res && res._id) {
                let zykListParams = {
                    storageDirectoryId: res._id,//res._id,//
                };
                //获取 资源库列表 全部资源下的文件夹 全部图片，全部视频，等初始id；
                storageList(zykListParams, res => {
                    let menuId = '';
                    if (res.directorys && res.directorys.length > 0) {
                        res.directorys.forEach(item => {
                            if (this.state.type === 12 && item.name === '全部图片') {
                                menuId = item._id
                            } else if (this.state.type === 13 && item.name === '全部视频') {
                                menuId = item._id
                            }
                        })
                    }
                    if (!menuId) {
                        console.log('没有获取到相关资源id');
                        return;
                    }
                    console.log(menuId);
                    this.state.menuId = menuId;
                    this.clickByType();
                    this.setState({});
                    console.log(res)
                })
            } else {
                console.log('没有查询到资源库id，请联系后台人员')
            }
        })
    }

    //获取资源库对应资源的上部分菜单
    getZykMenuList() {
        let params = {
            id: this.state.menuId
        };
        getStoragefids(params, res => {
            let menu = [];
            if (res) {
                res.splice(0, 1);
                menu = res;
            }
            this.setState({menuList: menu})
        })
    }

    //获取资源库下面的资源
    getZykList() {
        let params = {
            storageDirectoryId: this.state.menuId,//res._id,
        };
        if (this.state.name && this.state.name.trim().length > 0) {
            params.name = this.state.name;
        }
        //获取 资源库列表 全部资源下的文件夹 全部图片，全部视频，等初始id；
        storageList(params, res => {

            let dataSources = [];
            if (res) {
                //格式化文件
                if (res.directorys && res.directorys.length > 0) {
                    res.directorys.map(item => {
                        item.fileType = 'file';
                        dataSources.push(item)
                    })
                }
                //格式化展示内容
                if (res.files && res.files.length > 0) {
                    res.files.map(item => {
                        if (this.state.type === 12) {
                            item.fileType = 'img';
                        } else if (this.state.type === 13) {
                            item.fileType = 'video';
                        }
                        dataSources.push(item)
                    })
                }
            }
            this.setState({dataSources});
        })
    }


    render() {
        let contentWidth = document.body.clientWidth;
        let title;
        if(this.state.type===2){
            title='图片列表'
        }else if(this.state.type===3){
            title='视频列表'
        }else if(this.state.type===4){
            title='附件列表'
        }
        return (
            <div style={{position: 'relative'}}>
                <Modal
                    visible={this.state.visible}
                    onClose={() => {
                        this.setState({visible: false,name:''})
                    }}
                    animationType="slide-up"
                >
                    <div>
                        <div style={{display: 'flex', background: "white"}}>
                            <div style={{
                                width: 100,
                                height: 45,
                                lineHeight: '45px',
                                textAlign: "center",
                                fontSize: 13,
                            }} onClick={() => {
                                this.setState({visible: false,name:''})
                            }}>返回
                            </div>
                            <div style={{
                                width: contentWidth - 100,
                                height: 45,
                                lineHeight: '45px',
                                textAlign: "center",
                                fontSize: 13,
                            }}>{title}</div>
                            {
                                this.props.isUoLoad ?
                                    <div style={{width: 100}}></div> :
                                    (this.props.isShowDeleteBtn?<div style={{width: 100}}></div>:
                                        <div
                                            style={{
                                                width: 100,
                                                height: 45,
                                                lineHeight: '45px',
                                                textAlign: "center",
                                                fontSize: 13,
                                            }}
                                            onClick={() => {
                                                this.onOkClick()
                                            }}>确定
                                        </div>)
                            }
                        </div>
                        <div style={{}}>

                            <div style={{
                                paddingLeft: 40,
                                paddingRight: 40,
                                paddingTop: 20,
                                paddingBottom: 20,
                                background: '#f5f5f9'
                            }}>
                                <InputItem
                                    clear
                                    placeholder="搜索"
                                    moneyKeyboardAlign="left"
                                    type={'text'}
                                    style={{fontSize: '14px'}}
                                    onChange={(e) => {
                                        this.onSearch(e)
                                    }}
                                />
                            </div>

                        </div>
                        {this.renderNavigate()}
                        <div style={{display: 'flex', background: "white"}}>
                            <div
                                style={{
                                    width: contentWidth - 10,
                                    height: document.documentElement.clientHeight - 205,
                                    overflow: 'auto',
                                }}>
                                {this.state.dataSources && this.state.dataSources.map((item, index) => {
                                    let imgUrl = '';
                                  
                                    if (item.fileType === 'file') {
                                        imgUrl = folder;
                                    } else if (item.fileType === 'img') {
                                        if (item[this.props.cover]) {
                                            imgUrl = item[this.props.cover];
                                        } else {
                                            imgUrl = noImg
                                        }

                                    } else if (item.fileType === 'video') {
                                        if (item.coverUrl) {
                                            imgUrl = item.coverUrl
                                        } else if(item.path){
                                            imgUrl = item.path+'/thumbnails/1'
                                        }else {
                                            imgUrl = noImg;
                                        }
                                    }
                                  var  imgAccept = "." + [...PIC_TYPE,].join(",.");
                                    var videoAccept = "." + [...VIDEO_TYPE].join(",.");
                                    var audioAccept = '.' + [...AUDIO_TYPE].join(',.');
                                    if (this.state.type === 4) {
                                        if (item.ext === 'pdf') {
                                            imgUrl = PDF
                                        } else if (item.ext === 'rar') {
                                            imgUrl = RAR
                                        } else if (item.ext === 'txt') {
                                            imgUrl = TXT
                                        } else if (item.ext === 'docx' || item.ext === 'doc') {
                                            imgUrl = WORD
                                        } else if (item.ext === 'xlsx' || item.ext === 'xls') {
                                            imgUrl = XLXS
                                        } else if (item.ext === 'zip') {
                                            imgUrl = ZIP
                                        } else if (item.ext === 'ppt' || item.ext === 'pptx') {
                                            imgUrl = PPT
                                        } else if(imgAccept.indexOf(item.ext)>-1){
                                            item.fileType = 'img';
                                            if (item[this.props.cover]) {
                                                imgUrl = item[this.props.cover];
                                            } else {
                                                imgUrl = noImg
                                            }
                                        }else if(videoAccept.indexOf(item.ext)>-1){
                                            item.fileType= 'video';
                                            if (item.coverUrl) {
                                                imgUrl = item.coverUrl
                                            } else if(item.path){
                                                imgUrl = item.path+'/thumbnails/1'
                                            }else {
                                                imgUrl = noImg;
                                            }
                                        } else if(audioAccept.indexOf(item.ext)>-1){
                                            item.fileType= 'audio';
                                            imgUrl = audioImg;
                                        } else {
                                            imgUrl = other;
                                        }
                                        if (item.fileType === 'file') {
                                            imgUrl = folder;
                                        }
                                    }
                                    return (
                                        <div key={index}>
                                            <UploadItem imgUrl={imgUrl} item={item}  {...this.props} userClick={() => {
                                                this.userClick(item, index)
                                            }} fileClick={() => {
                                                this.fileClick(item, index)
                                            }} operation={() => this.operation(item)}
                                                        review={()=>{this.review(item)}}
                                            />
                                            <style>{`.am-modal-wrap{z-index:100000}
                                                    .am-modal-mask{z-index:100000}
                                                     
                                            `}</style>
                                        </div>

                                    )
                                })}
                                <div style={{height:50}}/>
                            </div>
                        </div>
                        {this.props.isUoLoad ? this.renderAddBtn() : null}
                    </div>
                </Modal>
                <Modal
                    visible={this.state.reviewStatus}
                    transparent
                    style={{width:'87vw'}}
                    maskClosable={false}
                    onClose={this.onClose('modal1')}
                    footer={[{ text: '确定', onPress: () => { this.onClose('modal1')(); } }]}
                    wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                >
                    <div>
                        {this.state.reviewContent
                            ? (
                                this.state.reviewContent.fileType === 'img'
                                    ? <img src={this.state.reviewContent.path} style={{width:'80vw',height:'80vw',objectFit:'contain'}}/>
                                    : this.state.reviewContent.fileType === 'video'
                                        ?<video id={'myVideo'} controls loop autoPlay type={'video/mp4'} src={this.state.reviewContent.path} height={'100%'} width={'100%'}
                                           muted >你的浏览器还不支持哦</video>
                                        : this.state.reviewContent.fileType === 'audio'
                                            ?<audio  id={'myAudio'} controls loop autoPlay type={'audio/mp3'} src={this.state.reviewContent.path} height={'100%'} width={'100%'}
                                                     muted >你的浏览器还不支持哦</audio>
                                            : null
                            )
                            : null
                        }


                    </div>
                </Modal>
            </div>
        )
    }

    //关闭查看图片的弹框
    onClose = key => () => {
        this.setState({reviewStatus: false,});
    };

    //渲染面包削导航
    renderNavigate() {
        return (
            <div>
                <div style={{
                    textAlign: 'left',
                    paddingLeft: 10,
                    paddingRight: 10,
                    width: document.body.clientWidth - 20,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }}>{
                    this.state.menuList && this.state.menuList.map((item, index) => {
                        if (index === this.state.menuList.length - 1) {
                            return (<span key={index}
                                          style={{height: 45, lineHeight: '45px'}}>&nbsp;{item.name}&nbsp;</span>)
                        }
                        return (<span onClick={() => {
                            this.navigationClick(item)
                        }} key={index} style={{
                            color: '#1890fe',
                            height: 45,
                            lineHeight: '45px'
                        }}>&nbsp;{item.name}&nbsp;/</span>)
                    })
                }
                    <div style={{clear: 'both'}}/>
                </div>
                {this.state.menuList.length>1? <div
                    style={{paddingLeft: 10, textAlign: 'left', color: this.state.menuList.length > 1 ? '#1890fe' : ''}}
                    onClick={() => {
                        this.backOne()
                    }}>&nbsp;返回上一级
                </div>:null}
            </div>
        )
    }

    //返回上一级
    backOne() {
        if (this.state.menuList && this.state.menuList.length > 1) {
            this.navigationClick(this.state.menuList[this.state.menuList.length - 2]);
        }
    }

    //渲染添加按钮
    renderAddBtn() {
        let accept;
        switch (this.state.type) {
            case 2:
               // accept = "." + [...PIC_TYPE,].join(",.");
                accept='image/*';
                break;
            case 3:
                //accept = "." + [...VIDEO_TYPE].join(",.");
                accept='video/*';
                break;
            case 4:
                //accept = "." + [...ATTCHMENT_TYPE].join(",.");
                accept="*";
                break;
        }
        return (
            <div style={{
                width: '13vw',
                height: '13vw',
                backgroundColor: '#ffffff',
                boxShadow: ' 0vw 0vw 7vw 0vw rgba(118, 31, 131, 0.15)',
                borderRadius: '50%',
                position: 'fixed',
                zIndex: '2',
                bottom: '6vw',
                right: '7vw',
                overflow: 'hidden'
            }}>
                <img src={add} style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginLeft: '-10px',
                    marginTop: '-10px'
                }}/>
                <div style={{width: 200, height: 200, opacity: 0, overflow: 'hidden'}}>
                    <MyImgPicker
                        accept={accept}
                        onAddImageClick={this.addImage}
                        files={this.state.videoFiles}
                        onChange={this.upload}
                        selectable={true}
                        multiple={true}
                        disableDelete={true}
                    />
                </div>
                <style>{`.am-toast-text-info{word-wrap: break-word;}
                
                `}</style>

            </div>
        )
    }

    onSearch(e) {
        this.state.name = e;
        const type = this.state.type;
        if (type === 2 || type === 3 || type === 4) {
            this.getTaskMenuResourceList()
        } else if (type === 12 || type === 13) {
            this.getZykList()
        }
    }

    //用户每一项点击
    userClick(record, parentIndex) {

        if (this.props.choose === 'one') {
            if (this.state.userChoose.length === 1) {
                Toast.info('最多只能选择一个', 1);
                return;
            } else {
                this.state.dataSources[parentIndex].checked = !record.checked;
            }

        }

        if (this.props.choose === 'more') {
            this.state.dataSources[parentIndex].checked = !record.checked;
        }

        let userChoose = [];
        this.state.dataSources && this.state.dataSources.forEach(item => {
            if (item.checked) {
                userChoose.push(item)
            }
        });
        this.setState({userChoose})
    }

    //点击确定返回
    onOkClick() {
        this.props.onOkClick && this.props.onOkClick(this.state.userChoose);
        this.setState({visible: false,name:''});
    }

    //点击后面操作按钮目前是删除
    operation(item) {
        this.deleteItem(item)
    }

    //点击删除
    deleteItem(item) {

        let params,text;
       if(item.fileType === 'file'){
           params = {
               "taskMenuId":item.id,
               "type": this.state.type,
               "taskId": parseInt(this.props.bindId)
           };
           text = '同时会删除文件夹内所有内容，请问是否删除？'
       }else{
           params = {
               "taskMenuId":this.state.menuId,
               "type": this.state.type,
               "taskId": parseInt(this.props.bindId),
               "resourceId": item.resourceId
           };
           text= '确认删除？'
       }
        if (this.state.type === 2 || this.state.type === 3 || this.state.type === 4 ) {
            alert('', text, [
                {
                    text: '取消', onPress: () => {

                    }
                },
                {
                    text: '确认', onPress: () => {
                        delTaskMenuResource(params, res => {
                            if (res) {
                                Toast.info('删除成功', 1);
                                this.getTaskMenuResourceList()
                            }
                        })
                    }
                },
            ]);
        }


    }

    //上传s
    upload = (files) => {
        let file = [...FILE_TYPE].join(",");
        window.loading.show();
        for (var i = 0; i < files.length; i++) {
            let data = new FormData();
            if (files[i].file) {
                if (files[i].file.uploaded !== 1) {
                    console.log(files[i].file,111111)
                    let type = files[i].file.type.split('/')[1];
                    let name = files[i].file.name.split('.')[1];
                    if(type==='quicktime'){
                        type = 'mov'
                    }
                    if(type==='vnd.openxmlformats-officedocument.wordprocessingml.document'){
                        type = 'docx'
                    }
                    if(type==='msword'){
                        type = 'doc'
                    }
                    if(type==='vnd.openxmlformats-officedocument.presentationml.presentation'){
                        type = 'pptx'
                    }
                    if(type==='vnd.ms-powerpoint'){
                        type = 'ppt'
                    }
                    if(type==='vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                        type = 'xlsx'
                    }
                    if(type==='plain'){
                        type = 'txt'
                    }
                    if(type==='x-matroska'){
                        type = 'mkv'
                    }
                    if(type==='vnd.ms-excel'){
                        type = 'xls'
                    }
                    if(type==='x-zip-compressed'){
                        type = 'zip'
                    }
                    if(!type){
                        if(name === 'rmvb' || name === 'RMVB') {
                            type = 'rmvb'
                        }
                        if(name === 'psd' || name === 'PSD') {
                            type = 'psd'
                        }
                        if(name === 'rar' || name === 'RAR') {
                            type = 'rar'
                        }
                    }
                    console.log(files[i].file.type,'类型',type);
                    files[i].file.uploaded = 1;
                    data.append('file', files[i].file);
                    switch (this.state.type) {
                        case 2:
                            if(PIC_TYPE.indexOf(type) === -1) {
                                window.loading.hide();
                                break;
                            };
                            uploadimage(data, res => {
                                this.fileBindTask(res)
                            });
                            break;
                        case 3:
                            if(VIDEO_TYPE.indexOf(type) === -1) {
                                window.loading.hide();
                                break;
                            };
                            //调用后台接口上传文件，
                            uploadvideo(data, res => {
                                this.fileBindTask(res)
                            });
                            break;
                        case 4:
                            console.log(1)
                            if(PIC_TYPE.indexOf(type) > -1){
                                window.loading.hide();
                                uploadimage(data, res => {
                                    this.fileBindTask(res)
                                });
                            } else if(VIDEO_TYPE.indexOf(type) > -1) {
                                window.loading.hide();
                                uploadvideo(data, res => {
                                    this.fileBindTask(res)
                                });
                                return;
                            }else if(AUDIO_TYPE.indexOf(type)>-1){
                                window.loading.hide();
                                uploadAudio(data, res => {
                                    this.fileBindTask(res)
                                });
                                return;
                            } else  if(ATTCHMENT_TYPE.indexOf(type)>-1){
                                window.loading.hide();
                                uploadAttachmentFile(data, res => {
                                    this.fileBindTask(res)
                                });
                                return;
                            }else{

                                window.loading.hide();
                                Toast.fail('只支持'+file +'格式',1);
                                $('.am-toast').css('z-index',100000)
                            }
                    }
                }
            }
        }

    };
    fileBindTask(res) {
        if (this.state.type === 2) {
            this.saveInterviewPicture(res);
        } else if (this.state.type === 3) {
            this.saveInterviewVideo(res)
        } else if (this.state.type === 4) {
            if (PIC_TYPE.indexOf(res.storageFile.suffix) > -1) {
                this.saveInterviewPicture(res);
            } else if (VIDEO_TYPE.indexOf(res.storageFile.suffix) > -1) {
                this.saveInterviewVideo(res)
            }else if(AUDIO_TYPE.indexOf(res.storageFile.suffix)> -1){
                this.saveInterviewAudio(res)
            }

            else {
                this.saveInterviewAttachment(res)
            }
        }

    }
    //保存音频
    saveInterviewAudio(res){
        let params ={
            name: res.storageFile.name,
            ext: res.storageFile.suffix,
            path: res.storageFile.path,
            size: res.storageFile.size,
            interviewId: this.props.bindId,//绑定任务的id
            taskMenuId: this.state.menuId,//当前菜单选中的id
            duration: res.storageFile.duration
    };
        saveInterviewAudio(params, res => {
            console.log('附件绑定', res);
            this.getTaskMenuResourceList()
        })
    }

    //保存任务附件
    saveInterviewAttachment(res){
     let params = {
            name: res.storageFile.name,
            ext: res.storageFile.suffix,
            path: res.storageFile.path,
            size: res.storageFile.size,
            interviewId: this.props.bindId,//绑定任务的id
            taskMenuId: this.state.menuId,//当前菜单选中的id
        };
     saveInterviewAttachment(params, res => {
            console.log('附件绑定', res);
            this.getTaskMenuResourceList()
        })

    }
    //保存任务视频
    saveInterviewVideo(res){
     let params = {
            name: res.storageFile.name,
            ext: res.storageFile.suffix,
            path: res.storageFile.path,
            size: res.storageFile.size,
            interviewId: this.props.bindId,//绑定任务的id
            taskMenuId: this.state.menuId,//当前菜单选中的id
            duration: res.storageFile.duration
        };
        saveInterviewVideo(params, res => {

            this.getTaskMenuResourceList()
        })
    }

    //保存任务图片
    saveInterviewPicture(res){
     let params = {
            name: res.storageFile.name,
            ext: res.storageFile.suffix,
            path: res.storageFile.path,
            size: res.storageFile.size,
            imageWidth: res.storageFile.width,
            imageHeight: res.storageFile.height,
            interviewId: this.props.bindId,//绑定任务的id
            type: 2,
            taskMenuId: this.state.menuId,//当前菜单选中的id
        };
        saveInterviewPicture(params, res => {
            console.log('图片绑定', res);
            this.getTaskMenuResourceList()
        })
    }

    //点击每一行
    fileClick(item, index) {
        if (item.fileType === 'file') {
            this.state.menuId = item.id || item._id;
            this.clickByType()
        } else {
            //这是选择
           if (this.props.isUoLoad === false) {
                this.userClick(item, index)
            }
        }
    }


    //点击预览
    review(item){
        switch (item.fileType) {
            case "file":
                this.state.menuId = item.id || item._id;
                this.clickByType();
                break;
            case "img":
                this.setState({reviewStatus:true,reviewContent:item},()=>{
                    $('.am-modal-mask').css('z-index', 10004);
                    $('.am-modal-wrap').css('z-index', 10004);
                });
                break;
            case "video":
                this.setState({reviewStatus:true,reviewContent:item},()=>{
                    $('.am-modal-mask').css('z-index', 10004);
                    $('.am-modal-wrap').css('z-index', 10004);
                });
                break;
            case "audio":
                this.setState({reviewStatus:true,reviewContent:item},()=>{
                    $('.am-modal-mask').css('z-index', 10004);
                    $('.am-modal-wrap').css('z-index', 10004);
                });
                break;
        }
    }

    //点击上面的导航
    navigationClick(item) {//任务||资源库_id
        this.state.menuId = item.id || item._id;
        this.clickByType()
    }

    clickByType() {
        if (this.state.type === 2 || this.state.type === 3 || this.state.type === 4) {
            this.getTaskMenuNavigation();
            this.getTaskMenuResourceList();
        } else if (this.state.type === 12 || this.state.type === 13) {
            this.getZykMenuList();
            this.getZykList();
        }
    }


}

class UploadItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const item = this.props.item;
        return (
            <div style={{
                height: 45,
                display: 'flex',
                borderBottom: '1px solid #e5e5e5',
                marginTop: 5
            }}>
                <div style={{width: 40, height: 40, marginLeft: 20, position: 'relative'}} onClick={() => {
                    this.props.review()
                }}>
                    <img style={{width: 40, height: 40,objectFit:'cover'}}
                         src={this.props.imgUrl} alt=""/>
                    {/*视频专用*/}
                    {item.fileType === 'video' ? <img src={play} alt="" style={{
                        width: 20,
                        height: 20,
                        position: 'absolute',
                        top: 10,
                        left: 10
                    }}/> : null}
                </div>
                <div style={{marginLeft: 20, width: 300}} onClick={() => {
                    this.props.fileClick()
                }}>
                    <div style={{
                        height: item.fileType === 'file' ? 40 : 20,
                        lineHeight: item.fileType === 'file' ? '40px' : '20px',
                        color: '#999999',
                        fontSize: 14, textAlign: 'left', fontWeight: 400,
                        width: 200, overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }} title={item[this.props.itemTitle]}>{item[this.props.itemTitle]}</div>
                    <div style={{
                        height: 20,
                        lineHeight: '20px',
                        color: '#cccccc',
                        fontSize: 12, textAlign: 'left', fontWeight: 400,
                        display: item.fileType === 'file' ? 'none' : 'block'
                    }}>{item[this.props.itemBottomTile]}</div>
                </div>
                {this.props.isUoLoad ?
                    <div style={{width: 50, lineHeight: '45px', fontsize: 14}} onClick={() => {
                        this.props.operation()
                    }}>
                        删除
                    </div>
                    :
                    (this.props.isShowDeleteBtn ?
                        <div style={{width: 50, lineHeight: '45px', fontsize: 14}} onClick={() => {
                            this.props.operation()
                        }}> 删除
                        </div> : (item.fileType === 'file' ? null : <div>
                            <CheckboxItem
                                checked={item.checked} onChange={() => {
                                this.props.fileClick()
                            }}/>
                        </div>)
                    )
                }
            </div>
        )
    }

}

const host = '/cm-sites-app-h5/';
export const getMeauId = (params, callBack) => {  //获取当前菜单id;
    request(host + 'taskMenu/getMeauId', 'get', params, callBack)
};
export const getTaskMenuNavigation = (params, callBack) => {  //获取上部分的面包削菜单
    request(host + 'taskMenu/getTaskMenuNavigation', 'get', params, callBack)
};
export const getTaskMenuResourceList = (params, callBack) => {  //获取上部分的面包削菜单
    request(host + 'QhtaskMenuResource/getTaskMenuResourceList', 'get', params, callBack)
};
export const saveInterviewPicture = (params, callBack) => {  //绑定图片
    request(host + 'mobileInterview/saveInterviewPicture', 'post', params, callBack)
};
export const saveInterviewVideo = (params, callBack) => {  //绑定视频
    request(host + 'mobileInterview/saveInterviewVideo', 'post', params, callBack)
};
export const saveInterviewAudio = (params, callBack) => {  //绑定附件
    request(host + 'mobileInterview/saveInterviewAudio', 'post', params, callBack)
};


export const saveInterviewAttachment = (params, callBack) => {  //绑定附件
    request(host + 'mobileInterview/saveInterviewAttachment', 'post', params, callBack)
};
export const delTaskMenuResource = (params, callBack) => {  //删除任务相关资源资源
    request(host + 'QhtaskMenuResource/delTaskMenuResource', 'post', params, callBack)
};

export const createStorageDirectory = (params, callBack) => {  //获取资源库初始菜单
    request(host + 'storage/createStorageDirectory', 'get', params, callBack)
};
export const getStoragefids = (params, callBack) => {  //获取资源库菜单
    request(host + 'mobileInterview/getStoragefids', 'get', params, callBack)
};
export const storageList = (params, callBack) => {  //获取资源库资源
    request(host + 'storage/storageList', 'get', params, callBack)
};
