import React, {Component} from 'react'
import {Icon, NavBar, Toast, WhiteSpace} from "antd-mobile/lib/index";
import {uuid} from "../../../utils";
import {getInfoFromLocalStorage, getInterviewInfoById} from "../../../action";
import WingBlank from "antd-mobile/es/wing-blank/index";
import ManuscriptCardForm from "../../../components/ManuscriptCard";
import InterviewCardForm from "../../../components/InterviewCard";

/**
 * 新增约稿页面
 */
class AddManuscriptPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            history: require('history').createHashHistory(),
            interviewInfo: {},
        }
    }

    goBackTimeout;

    goBack() {
        this.goBackTimeout=  setTimeout(()=> {
        let isContributeRoleFlag = getInfoFromLocalStorage('isContributeRoleFlag');
        let goBack = getInfoFromLocalStorage('goBack');
        var url = "/manuscriptPage/manuscriptPage/" + isContributeRoleFlag + '/' + goBack;
        this.state.history.push(url);
        },100);
    }

    componentWillMount() {
    }

    componentWillUnmount() {
        clearTimeout(this.goBackTimeout);
    }


    updateInterViewInfo(data) {
        if (!data) {
            Toast.fail("数据异常");
        }
        this.setState({
            interviewInfo: data
        })
    }

//提交数据
    submitData() {
        this.child.submitData();
    }

    render() {
        return <div>
            <NavBar
                mode={'light'}
                icon={<Icon type="left" style={{color: '#2d2d2d'}}/>}
                leftContent={[
                    <div className={'nav_goback'} key={uuid()} onClick={() => this.goBack()}>返回</div>
                ]}

                rightContent={[
                    <div className={'nav_goback'} key={uuid()} style={{marginRight:10}} onClick={() => {
                        this.submitData()
                    }}>提交</div>
                ]}
            ><span className={'nav_title'} >约稿</span></NavBar>
            <WingBlank style={{margin:'5px'}}>
                <ManuscriptCardForm onRef={(child) => {
                    this.child = child
                }} goBack={this.goBack.bind(this)}></ManuscriptCardForm>
            </WingBlank>
        </div>
    }
}

export default AddManuscriptPage;
