/**
 * created by yxy
 *
 */
import { request } from '../utils';
let host = '/cm-sites-app-h5/';

const Apis = {
    taskList: host + 'mobileInterview/getTask',//获取任务列表
    taskListDetails:host +'mobileInterview/getDetails',//获取详情
    delete:host +'mobileInterview/delete',//删除任务
    receive:host +'mobileInterview/isHavepickAuth',//判断是否有领取按钮
    draw:host+'mobileInterview/pickTask',//领取任务
    isJurisdiction:host+'mobileInterview/getAuth',//领取任务
    enclosure:host+'mobileInterview/getAttachmentFiles',//领取附件
    isReceived:host+'mobileInterview/isTakeTask',//是否领取
    getResource:host+'mobileInterview/getResource',//稿件列表
    isHaveDeleteAuth:host+'mobileInterview/isHaveDeleteAuth',//删除权限
    isCheckAuth:host+'mobileInterview/isCheckAuth',//是否有查看权限

};
export const isHaveDeleteAuth = (params, callBack) => {  //获取媒体管理的列表
    request(Apis.isHaveDeleteAuth,'get', params, callBack)
};

export const taskList = (params, callBack) => {  //获取媒体管理的列表
    request(Apis.taskList,'get', params, callBack)
};
export const taskListDetails = (params, callBack) => {  //获取媒体管理的列表详情
    request(Apis.taskListDetails,'get', params, callBack)
};
export const deleteTask = (params, callBack) => {  //删除任务
    request(Apis.delete,'post', params, callBack)
};
export const receive = (params, callBack) => {  //判断是否有领取按钮
    request(Apis.receive,'get', params, callBack)
};
export const draw = (params, callBack) => {  //判断是否有领取按钮
    request(Apis.draw,'post', params, callBack)
};
export const isJurisdiction = (params, callBack) => {  //判断是否有领取按钮
    request(Apis.isJurisdiction,'get', params, callBack)
};
export const enclosure = (params, callBack) => {  //领取附件
    request(Apis.enclosure,'get', params, callBack)
};
export const isReceived = (params, callBack) => {  //领取附件
    request(Apis.isReceived,'get', params, callBack)
};
export const getResource = (params, callBack) => {  //稿件列表
    request(Apis.getResource,'get', params, callBack)
};
export const isCheckAuth = ( callBack) => {  //验证他有没有查看权限
    request(Apis.isCheckAuth,'get', {}, callBack)
};

